import { SimpleListView } from '~/shared/components/ui';
import {
  ViewId,
  convertBaseDigitOnceTo,
  BASE_DIGIT_MILLION,
  mergeAmountNumberWithRounding,
  toCommaTypeString,
  formatIDateToString,
  convertLongToString,
  getDateFormatWithTimezone,
} from '~/shared/utils';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import Long from 'long';

interface ViewCertificateType {
  recordId: string; // Key
  displayName: string; // 認証名
  status: string; // 取得
  expirationDt: string; // 有効期限
}
interface CompanyConfirmationBasicProps {
  viewId: ViewId;
  profile?: mtechnavi.api.analysis.IProfile;
  certificates?: mtechnavi.api.analysis.ICertificate[];
  financials?: mtechnavi.api.analysis.IFinancial[];
}
export const CompanyConfirmationBasic = ({
  viewId,
  profile,
  certificates,
  financials,
}: CompanyConfirmationBasicProps) => {
  // 決算マスタ（最新の１件）
  const financial = financials
    ?.sort((a, b) => {
      const start = Long.fromValue(a.periodStartDt?.timestamp || 0).toNumber();
      const end = Long.fromValue(b.periodStartDt?.timestamp || 0).toNumber();
      return start < end ? 1 : -1;
    })
    .at(0);

  // 認証情報
  const certificateItems = (): ViewCertificateType[] => {
    if (!certificates) {
      return [];
    }
    const vals: ViewCertificateType[] = [];
    certificates.map((v) => {
      vals.push({
        recordId: v.recordId ?? '',
        displayName: v.displayName
          ? v.displayName
          : v.certificate!.displayNameLangJa ?? '',
        status:
          (v.obtained ? 'あり' : 'なし') +
          (v.obtainPlan
            ? '（取得予定日：' +
              getDateFormatWithTimezone(v.obtainPlanDt as sharelib.IDatetime) +
              '）'
            : ''),
        expirationDt: getDateFormatWithTimezone(
          v.expirationDt as sharelib.IDatetime
        ),
      });
    });
    return vals;
  };

  return (
    <>
      <table className="basic-info-table">
        <tbody>
          <tr>
            <th className="root-header" rowSpan={8}>
              企業情報
            </th>
            <th>ふりがな</th>
            <td>{profile?.displayNameLangJaKana}</td>
            <th>英語名</th>
            <td>{profile?.displayNameLangEn}</td>
          </tr>

          <tr>
            <th>ホームページURL</th>
            <td colSpan={3}>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${profile?.websiteUrl}`}
              >
                {profile?.websiteUrl}
              </a>
            </td>
          </tr>

          <tr>
            <th>事業内容</th>
            <td colSpan={3}>{profile?.mainBusiness}</td>
          </tr>

          <tr>
            <th>資本金(百万円)</th>
            <td>
              {mergeAmountNumberWithRounding(
                convertBaseDigitOnceTo(
                  profile?.capitalStock,
                  BASE_DIGIT_MILLION
                ),
                0,
                {
                  rounding: 'RoundDown',
                  digits: 0,
                }
              )}
            </td>
            <th>売上金額(百万円)</th>
            <td>
              {mergeAmountNumberWithRounding(
                convertBaseDigitOnceTo(financial?.netSales, BASE_DIGIT_MILLION),
                0,
                {
                  rounding: 'RoundDown',
                  digits: 0,
                }
              )}
            </td>
          </tr>

          <tr>
            <th>従業員(人数)</th>
            <td>
              {toCommaTypeString(convertLongToString(profile?.employees?.size))}
            </td>
            <th>従業員(平均年齢)</th>
            <td>
              {mergeAmountNumberWithRounding(
                profile?.employees?.averageAge,
                0,
                {
                  rounding: 'RoundDown',
                  digits: 1,
                }
              )}
            </td>
          </tr>

          <tr>
            <th>主要株主</th>
            <td>{profile?.mainShareholder}</td>
            <th>主要取引銀行</th>
            <td>{profile?.mainBank}</td>
          </tr>

          <tr>
            <th>主要仕入先</th>
            <td>{profile?.mainSupplier}</td>
            <th>主要納入先</th>
            <td>{profile?.mainCustomer}</td>
          </tr>

          <tr>
            <th>法人番号</th>
            <td>{profile?.companyLegalProperties?.companyNumber}</td>
            <th>適格請求書番号</th>
            <td>
              {
                profile?.companyLegalProperties
                  ?.eligibleInvoiceAuthorizationNumber
              }
            </td>
          </tr>

          <tr>
            <th className="root-header" rowSpan={2}>
              代表者
            </th>
            <th>氏名</th>
            <td>{profile?.representative?.displayNameLangJa}</td>
            <th>ふりがな</th>
            <td>{profile?.representative?.displayNameLangJaKana}</td>
          </tr>

          <tr>
            <th>役職</th>
            <td>{profile?.representative?.position}</td>
            <th>生年月日</th>
            <td>
              {formatIDateToString(
                profile?.representative?.birthDate,
                'YYYY/MM/DD'
              )}
            </td>
          </tr>

          <tr>
            <th className="root-header" rowSpan={3}>
              本社情報
            </th>
            <th>都道府県</th>
            <td colSpan={3}>{profile?.address?.region?.displayNameLangJa}</td>
          </tr>

          <tr>
            <th>住所</th>
            <td colSpan={3}>{profile?.address?.addressLines1}</td>
          </tr>

          <tr>
            <th>電話番号</th>
            <td colSpan={3}>{profile?.phoneNumber}</td>
          </tr>
        </tbody>
      </table>

      <div className="input-line">
        <div className="item-group-100">
          <div className="w-50">
            <div className="certificates">
              <span>認証</span>
              <SimpleListView
                data={certificateItems()}
                viewOptions={{
                  omitFooter: true,
                  keyColumn: 'id',
                  columns: [
                    {
                      header: { viewId: viewId, id: 'displayName' },
                      propertyName: 'displayName',
                      width: '41%',
                    },
                    {
                      header: { viewId: viewId, id: 'obtained' },
                      propertyName: 'status',
                      width: '41%',
                    },
                    {
                      header: { viewId: viewId, id: 'expirationDt' },
                      propertyName: 'expirationDt',
                      width: '18%',
                    },
                  ],
                }}
                actionOptions={{}}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
