import { FullMethodName_DeleteBlueprintWithRelatedItems } from 'worker/fullMethodName/crud';
import { AppSaveWorker } from '..';
import { mtechnavi } from '~/shared/libs/clientsdk';

/** 図面の新規登録 */
export const deleteBlueprint = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.blueprint.IDeleteBlueprintWithRelatedItemsRequest
) => {
  return worker.invokeListRequest<
    mtechnavi.api.blueprint.IDeleteBlueprintWithRelatedItemsRequest,
    void
  >(FullMethodName_DeleteBlueprintWithRelatedItems, req);
};
