import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessage,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
  success,
} from '~/shared/components';
import {
  ApprovalRequestDialog,
  Checkbox,
  ConfirmationDialog,
  DateSuggest,
  FileUploadDialog,
  FileUploadDialogResult,
  Filterbox,
  FilterboxItem,
  OutputOption,
  SimpleListView,
  PageNavigation,
  Textarea,
  Textbox,
  DataFilterbox,
  DataFilterboxItem,
} from '~/shared/components/ui';
import { CaptionButton, IconButton } from '~/shared/components/ui/Button';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  AttachmentItems,
  FullMethodName_ListUserAttributes,
  FullMethodName_ListWorkTaskCatalogs,
  PageState,
  ViewId,
  autoBulkDownload,
  autoDownloadFileOnlyName,
  convertDate,
  convertDatetime,
  formatUserWIthEmail,
  convertOrganizationToDataFilterBox,
  convertOrganizationStructureReference,
  convertOrganizationStructureReferenceToFilterboxItem,
  convertUserReference,
  convertUserReferenceToFilterboxItem,
  createWorkTaskRelation,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getWorkerExceptionMessage,
  handleCommonFIleUpload,
  includeInputValidateError,
  getPathnameByViewId,
} from '~/shared/utils';
import { FilterExpression } from '~/worker';
import { validateFreeDate } from '~/shared/components/parts/validator';
import './WtWorkTaskInput.css';
import { OrganizationTreeSearchDialog } from '~/shared/components/ui/Dialog/OrganizationTreeSearchDialog';

const VIEW_ID: ViewId = 'WT_WORKTASK_INPUT';
const AttachFileCountLimit = 10;
export const WtWorkTaskInput = () => {
  const sourcePageInfo = useRef((useLocation().state as PageState) ?? []);
  const [actionType, setActionType] = useState(
    sourcePageInfo.current.actionType || 'add'
  );
  const intl = useIntl();
  const navi = useNavigate();
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  const backPageUrl = useMemo(
    () => getPathnameByViewId(sourcePageInfo.current.sourceViewId)?.path ?? '/',
    [sourcePageInfo]
  );

  // ラベル
  const labelTemporarySave = GetMessageWithIntl(intl, {
    id: 'temporarySave',
  });
  const labelTaskActivate = GetMessageWithIntl(intl, {
    id: 'activate',
    prefixId: VIEW_ID,
  });
  const labelApprovalRequest = GetMessageWithIntl(intl, {
    id: 'approvalRequest',
    prefixId: VIEW_ID,
  });
  const labelAttachFile = GetMessageWithIntl(intl, {
    id: 'attachFile',
    prefixId: VIEW_ID,
  });

  // メッセージ
  const successMessage = GetMessage({ id: 'I0000001' });
  const duplicated = GetMessage({ id: 'E0000073' });
  const viewMessageCancel: MessageProps = {
    id: 'confirmationDialogMessage',
    prefixId: VIEW_ID,
  };
  const viewMessageTemporarySave: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'temporarySave' }),
    },
  };
  const viewMessageTaskActivate: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, {
        id: 'activate',
        prefixId: VIEW_ID,
      }),
    },
  };
  const viewMessageDelete: MessageProps = {
    id: 'confirmationDialogMessageDelete',
    prefixId: VIEW_ID,
  };
  const viewMessageDeleteAll: MessageProps = {
    id: 'confirmationDialogMessageDeleteAll',
    prefixId: VIEW_ID,
  };
  const viewMessageAttachFileLimit = GetMessageWithIntl(intl, {
    id: 'E0000077',
    value: { $1: AttachFileCountLimit },
  });

  //フィルターボックスType
  const catalogItemType = {
    value: 'workTaskCatalogId',
    displayName: 'displayName',
  };
  const userItemType = {
    value: 'user.email',
    displayName: 'user.displayName',
  };

  const [isLoading, setLoading] = useState(false);

  const [isManagementInitialLoad, setManagementInitialLoad] = useState(false);
  const [isWorkInitialLoad, setWorkInitialLoad] = useState(false);

  // 画面レイアウト
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);

  // アコーディオン
  const [accordionState, setAccordionState] = useState<{
    [k: string]: boolean;
  }>({
    request: true,
    businessUnit: true,
    reference: true,
  });
  const accordionAction = (type: string) => {
    setAccordionState({ ...accordionState, [type]: !accordionState[type] });
  };

  // マスタデータ
  const [catalogList, setCatalogList] =
    useState<mtechnavi.api.worktask.ListWorkTaskCatalogResponse>();
  const [userList, setUserList] = useState<
    mtechnavi.api.company.IOrganizationRelation[]
  >([]);
  const [organizationList, setOrganizationList] = useState<
    mtechnavi.api.company.IOrganization[]
  >([]);
  const [userAttributeList, setUserAttributeList] = useState<
    mtechnavi.api.tenantadmin.IUserAttribute[]
  >([]);

  // 編集元データ格納用
  const workTaskData = useRef<mtechnavi.api.worktask.IWorkTask>();
  // 項目セット用
  const [workTask, setWorkTask] = useState<mtechnavi.api.worktask.IWorkTask>();
  const [isSuspendCatalogChange, setSuspendCatalogChange] = useState(false);
  // 画面項目
  const [workTaskCatalogItem, setWorkTaskCatalogItem] = useState<
    FilterboxItem[]
  >([]);
  const [workTaskCatalog, setWorkTaskCatalog] =
    useState<mtechnavi.api.worktask.IWorkTaskCatalog>();
  const [
    managementOrganizationFilterBoxItems,
    setManagementOrganizationFilterBoxItems,
  ] = useState<DataFilterboxItem[]>([]);
  const [managementOrganizationItem, setManagementOrganizationItem] = useState<
    DataFilterboxItem[]
  >([]);
  const [managementStaffItem, setManagementStaffItem] = useState<
    FilterboxItem[]
  >([]);
  const [managementStaffUser, setManagementStaffUser] =
    useState<sharelib.IUserReference>();
  const [displayName, setDisplayName] = useState('');
  const [worktaskDueDateDt, setWorktaskDueDateDt] = useState<Date | null>();
  const [workOrganizationFilterBoxItems, setWorkOrganizationFilterBoxItems] =
    useState<DataFilterboxItem[]>([]);
  const [workOrganizationItem, setWorkOrganizationItem] = useState<
    DataFilterboxItem[]
  >([]);
  const [workStaffItem, setWorkStaffItem] = useState<FilterboxItem[]>([]);
  const [workStaffUser, setWorkStaffUser] = useState<sharelib.IUserReference>();
  const [worktaskTicketDueDateDt, setWorktaskTicketDueDateDt] =
    useState<Date | null>();
  const [asapCheck, setAsapCheck] = useState<string[]>([]);
  const [asapComment, setAsapComment] = useState('');
  const [contents, setContents] = useState('');
  const [approval, setApproval] = useState(false);
  const [systemNotificationUsersItem, setSystemNotificationUsersItem] =
    useState<FilterboxItem[]>([]);
  const [requestRemarks, setRequestRemarks] = useState('');
  const [requestAttachments, setRequestAttachments] = useState<
    AttachmentItems[]
  >([]);
  // 指図案件紐付け制御
  const executeCreateWorkTaskRelation = useRef(true);
  const [managementStaffCustomQuery, setManagementStaffCustomQuery] =
    useState<FilterExpression>({});
  const [workStaffCustomQuery, setWorkStaffCustomQuery] =
    useState<FilterExpression>({});

  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 確認ダイアログInfo
  const [confirmDialogInfo, setConfirmDialogInfo] = useState({
    viewMessage: viewMessageCancel,
    onDecision: () => {},
  });
  // 承認依頼ダイアログ
  const [isOpenApprovalRequestDialog, setOpenApprovalRequestDialog] =
    useState(false);
  // ファイル追加ダイアログ
  const [isOpenFileUploadDialog, setOpenFileUploadDialog] = useState(false);

  // 組織検索ダイアログ
  const [isOpenManagementOrgSearchDialog, setOpenManagementOrgSearchDialog] =
    useState(false);
  const [isOpenWorkOrgSearchDialog, setOpenWorkOrgSearchDialog] =
    useState(false);

  // 入力チェック
  const requireRequest = useRef(null);
  const [workingBlurWorktaskCatalog, setWorkingBlurWorktaskCatalog] =
    useState<Date>();
  const [
    workingBlurManagementOrganizationUnit,
    setWorkingBlurManagementOrganizationUnit,
  ] = useState<Date>();
  const [workingBlurWorkOrganizationUnit, setWorkingBlurWorkOrganizationUnit] =
    useState<Date>();
  const formAreaRef = useRef(null);

  const isInputError = (): boolean => {
    setWorkingBlurWorktaskCatalog(new Date());
    setWorkingBlurManagementOrganizationUnit(new Date());
    setWorkingBlurWorkOrganizationUnit(new Date());
    return includeInputValidateError(document, intl, [
      {
        value:
          workTaskCatalogItem.length > 0 ? workTaskCatalogItem[0].value : '',
        ref: formAreaRef,
      },
      {
        value:
          managementOrganizationItem.length > 0
            ? managementOrganizationItem[0].value
            : '',
        ref: formAreaRef,
      },
      {
        value:
          workOrganizationItem.length > 0 ? workOrganizationItem[0].value : '',
        ref: formAreaRef,
      },
      {
        value: displayName ?? '',
        ref: formAreaRef,
      },
    ]);
  };

  const handleConfirmTempSave = () => {
    if (isInputError()) {
      return;
    }
    setConfirmDialogInfo({
      viewMessage: viewMessageTemporarySave,
      onDecision: handleTempSave,
    });
    setOpenConfirmDialog(true);
  };

  const handleTempSave = async () => {
    setLoading(true);
    try {
      const workTask = await saveWorkTask();
      if (
        workTask.length > 0 &&
        workTask[0].workTaskId &&
        sourcePageInfo?.current.actionType === 'add' &&
        executeCreateWorkTaskRelation.current
      ) {
        await createWorkTaskRelation(
          [workTask[0].workTaskId],
          sourcePageInfo.current.beforeStateIds ?? [],
          sourcePageInfo.current.baseViewOption?.sourceViewId ?? null,
          'worktask'
        );
        executeCreateWorkTaskRelation.current = false;
      }
      success([successMessage]);
      setOpenConfirmDialog(false);
      // 編集モードへ移行
      changeEditMode(workTask[0]);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      // reload処理
      setLoading(false);
    }
    setLoading(false);
  };

  const saveWorkTask = async () => {
    const managementOrganization = await filterboxItemToOrg(
      managementOrganizationItem
    );
    const workOrganization = await filterboxItemToOrg(workOrganizationItem);
    const managementStaff = await filterboxItemToStaff(managementStaffItem);
    const workStaff = await filterboxItemToStaff(workStaffItem);

    const systemNotificationUsers: sharelib.IUserReference[] = [];
    managementStaffUser && systemNotificationUsers.push(managementStaffUser);
    if (workStaffUser && workStaffUser.email !== managementStaffUser?.email) {
      systemNotificationUsers.push(workStaffUser);
    }
    const otherNoticeUsers = await filterboxItemToUser(
      systemNotificationUsersItem
    );
    otherNoticeUsers.forEach((user) => {
      if (
        user &&
        user.email !== managementStaffUser?.email &&
        user.email !== workStaffUser?.email
      ) {
        systemNotificationUsers.push(user);
      }
    });
    const request: mtechnavi.api.worktask.IWorkTask = {
      systemNotificationUsers,
      displayName,
      asap: asapCheck.length > 0,
      workTaskCatalog,
      contents,
      approval,
      managementOrganization,
      managementStaff,
      workOrganization,
      workStaff,
      requestRemarks,
      requestAttachments,
    };
    if (request.asap) {
      request.asapComment = asapComment;
    }
    if (worktaskDueDateDt) {
      request.worktaskDueDateDt = convertDatetime(
        worktaskDueDateDt,
        'YYYY/MM/DD'
      );
    }
    if (worktaskTicketDueDateDt) {
      request.worktaskTicketDueDateDt = convertDatetime(
        worktaskTicketDueDateDt,
        'YYYY/MM/DD'
      );
    }

    if (workTask?.workTaskId && actionType === 'edit') {
      request.workTaskId = workTask.workTaskId;
    }
    if (workTask?.updatedAt && actionType === 'edit') {
      request.updatedAt = workTask.updatedAt;
    }

    return window.App.services.ui.worker.apiCall({
      actionName: 'saveWorkTask',
      request,
    });
  };

  const filterboxItemToOrg = async (items: FilterboxItem[]) => {
    return (
      (await convertOrganizationStructureReference(items[0]?.value || '')) || {}
    );
  };

  const filterboxItemToStaff = async (items: FilterboxItem[]) => {
    return (await convertUserReference(items[0]?.value || '')) || {};
  };

  const filterboxItemToUser = async (items: FilterboxItem[]) => {
    return await Promise.all(
      items.map((item) => convertUserReference(item?.value || ''))
    );
  };

  const handleConfirmApprovalRequest = () => {
    if (isInputError()) {
      return;
    }
    setOpenApprovalRequestDialog(true);
  };

  const handleApprovalRequest = async (requestInfo: OutputOption) => {
    setLoading(true);
    setOpenApprovalRequestDialog(false);
    try {
      const workTask = await saveWorkTask();
      if (
        workTask.length > 0 &&
        workTask[0].workTaskId &&
        sourcePageInfo?.current.actionType == 'add' &&
        executeCreateWorkTaskRelation.current
      ) {
        await createWorkTaskRelation(
          [workTask[0].workTaskId],
          sourcePageInfo.current.beforeStateIds ?? [],
          sourcePageInfo.current.baseViewOption?.sourceViewId ?? null,
          'worktask'
        );
        executeCreateWorkTaskRelation.current = false;
      }
      await approvalRequestWorkTask(workTask[0], requestInfo);
      success([successMessage]);
      setOpenConfirmDialog(false);
      // 編集モードへ移行
      changeEditMode(workTask[0]);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      // reload処理
      setLoading(false);
    }
    setLoading(false);
    backToBaseViewPage();
  };

  const approvalRequestWorkTask = async (
    wt: mtechnavi.api.worktask.IWorkTask,
    requestInfo: OutputOption
  ) => {
    // 選択値を担当者情報に変換
    const selectedStaff =
      (await convertUserReference(
        requestInfo?.approvalOrganizationStaff[0]?.value || ''
      )) || {};

    // 選択値を管理場所情報に変換
    const selectedComponentUnit =
      (await convertOrganizationStructureReference(
        requestInfo?.approvalOrganizationUnit[0]?.value || ''
      )) || {};
    return window.App.services.ui.worker.apiCall({
      actionName: 'approvalRequestWorkTask',
      request: {
        workTaskId: wt.workTaskId,
        approvalPlanOrganization: selectedComponentUnit,
        approvalPlanStaff: selectedStaff,
        comment: requestInfo.comment,
      },
    });
  };

  const handleConfirmActivateWorkTask = () => {
    if (isInputError()) {
      return;
    }
    setConfirmDialogInfo({
      viewMessage: viewMessageTaskActivate,
      onDecision: handleActivateWorkTask,
    });
    setOpenConfirmDialog(true);
  };

  const handleActivateWorkTask = async () => {
    setLoading(true);
    try {
      const workTask = await saveWorkTask();
      await activateWorkTask(workTask[0]);
      if (
        workTask.length > 0 &&
        workTask[0].workTaskId &&
        sourcePageInfo?.current.actionType == 'add' &&
        executeCreateWorkTaskRelation.current
      ) {
        await createWorkTaskRelation(
          [workTask[0].workTaskId],
          sourcePageInfo.current.beforeStateIds ?? [],
          sourcePageInfo.current.baseViewOption?.sourceViewId ?? null,
          'worktask'
        );
        executeCreateWorkTaskRelation.current = false;
      }
      success([successMessage]);
      setOpenConfirmDialog(false);
      // 編集モードへ移行
      changeEditMode(workTask[0]);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      // reload処理
      setLoading(false);
    }
    setLoading(false);
    backToBaseViewPage();
  };

  const activateWorkTask = async (wt: mtechnavi.api.worktask.IWorkTask) => {
    return window.App.services.ui.worker.apiCall({
      actionName: 'activateWorkTask',
      request: {
        workTaskId: wt.workTaskId,
      },
    });
  };

  const changeEditMode = (wt: mtechnavi.api.worktask.IWorkTask) => {
    workTaskData.current = wt;
    setSuspendCatalogChange(true);
    setWorkTask(wt);
    setActionType('edit');
  };

  // 添付ファイル追加
  const handleAttachFile = () => {
    if (
      requestAttachments &&
      requestAttachments.length >= AttachFileCountLimit
    ) {
      error([viewMessageAttachFileLimit]);
      return;
    }
    setOpenFileUploadDialog(true);
  };

  const uploadFiles = async (result: FileUploadDialogResult) => {
    const files = await handleCommonFIleUpload([], result, 'B06', intl);
    if (requestAttachments.length + files.length > AttachFileCountLimit) {
      error([viewMessageAttachFileLimit]);
      return;
    }
    setRequestAttachments([...requestAttachments, ...files]);
    setOpenFileUploadDialog(false);
  };

  // 添付ファイル削除
  const handleDeleteAttachFile = (item: AttachmentItems) => {
    setOpenConfirmDialog(true);
    setConfirmDialogInfo({
      viewMessage: viewMessageDelete,
      onDecision: () => {
        setRequestAttachments(
          requestAttachments?.filter((v) => v.assetId !== item.assetId)
        );
        setOpenConfirmDialog(false);
      },
    });
  };
  // 添付ファイル全削除
  const handleDeleteAllAttachFile = () => {
    setConfirmDialogInfo({
      viewMessage: viewMessageDeleteAll,
      onDecision: () => {
        setRequestAttachments([]);
        setOpenConfirmDialog(false);
      },
    });
    setOpenConfirmDialog(true);
  };
  // 添付ファイルダウンロード
  const handleDownload = (item: AttachmentItems) => {
    autoDownloadFileOnlyName(item.filename ?? '', item.assetId ?? '');
  };
  // 添付ファイル全ダウンロード
  const handleFullDownload = () => {
    autoBulkDownload(
      (requestAttachments ?? []).map((item) => item.assetId ?? ''),
      intl,
      VIEW_ID
    );
  };

  // 戻るページ
  const backToPreviewPage = () => {
    const ids = [] as string[];
    sourcePageInfo.current.ids?.map((v) => ids.push(v));
    const state: PageState = {
      ids: sourcePageInfo.current.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.current.naviFilters,
      beforeStateIds: sourcePageInfo.current.beforeStateIds,
      baseViewOption: sourcePageInfo.current.baseViewOption,
      confirmationViewOption: sourcePageInfo.current.confirmationViewOption,
    };
    navi(backPageUrl, { state });
  };

  // 一覧に戻る
  const backToBaseViewPage = () => {
    const state: PageState = {
      ids: sourcePageInfo.current.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.current.naviFilters,
      beforeStateIds: sourcePageInfo.current.beforeStateIds,
      baseViewOption: sourcePageInfo.current.baseViewOption,
    };

    // 戻り先に遷移元(一覧)情報をセット
    //  遷移先不明の場合は依頼管理一覧に戻る
    const backPath =
      getPathnameByViewId(sourcePageInfo.current.baseViewOption?.sourceViewId)
        ?.path ?? '/work-task/wk-worktask-list';

    navi(backPath, { state });
  };

  // 保持したデータから OrganizationRelation を取得
  const getOrganizationRelationById = useCallback(
    async (
      userId: string | undefined
    ): Promise<mtechnavi.api.company.IOrganizationRelation[] | null> => {
      return userList.filter((item) => item.userId === userId) || null;
    },
    [userList]
  );

  // 保持したデータから ComponentUnit を取得
  const getOrganizationById = useCallback(
    async (
      organizationId: string | undefined
    ): Promise<mtechnavi.api.company.IOrganization | null> => {
      return (
        organizationList.find(
          (item) => item.organizationId === organizationId
        ) || null
      );
    },
    [organizationList]
  );

  // 保持したデータから UserAttribute を取得
  const getUserById = useCallback(
    async (
      userId: string | undefined
    ): Promise<sharelib.IUserReference | null> => {
      const attr = userAttributeList.find(
        (item) => item?.user?.userId === userId
      )?.user;
      return attr
        ? {
            userId: attr!.userId,
            email: attr!.email,
            displayName: attr!.displayName,
          }
        : null;
    },
    [userAttributeList]
  );

  // 保持したデータから UserAttribute を取得
  const getUserAttributeByEmail = useCallback(
    async (
      email: string | undefined
    ): Promise<mtechnavi.api.tenantadmin.IUserAttribute | null> => {
      return (
        userAttributeList.find((item) => item?.user?.email === email) || null
      );
    },
    [userAttributeList]
  );

  // 保持したデータから UserReference を取得
  const getUserReferenceByEmail = useCallback(
    async (
      email: string | undefined
    ): Promise<sharelib.IUserReference | null> => {
      const attr = await getUserAttributeByEmail(email);
      return attr
        ? {
            userId: attr!.userAttributeId,
            email: attr!.user!.email,
            displayName: attr!.user!.displayName,
          }
        : null;
    },
    [getUserAttributeByEmail]
  );

  // 画面データ取得
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        // プリセット取得
        const { presetItem } = await window.App.services.ui.getViewIdPreset(
          VIEW_ID
        );
        setPreset(presetItem);
      })();

      (async () => {
        const catalogList = (await window.App.services.ui.worker.filter({
          action: 'reload',
          fullMethodName: FullMethodName_ListWorkTaskCatalogs,
          filter: {},
          sort: [],
        })) as mtechnavi.api.worktask.ListWorkTaskCatalogResponse;
        setCatalogList(catalogList);

        const userList =
          await window.App.services.ui.listOrganizationRelation();
        setUserList(userList);

        const organizationList =
          await window.App.services.ui.listOrganization();
        setOrganizationList(organizationList);

        const userAttributeList =
          await window.App.services.ui.listUserAttributes();
        setUserAttributeList(userAttributeList);

        // データフィルターボックスのアイテム取得
        setManagementOrganizationFilterBoxItems(
          convertOrganizationToDataFilterBox(organizationList)
        );
        setWorkOrganizationFilterBoxItems(
          convertOrganizationToDataFilterBox(organizationList)
        );

        // カタログ指定での登録モード
        if (
          actionType === 'add' &&
          sourcePageInfo.current.sourceViewId === 'WT_REQUEST_CATALOG_LIST'
        ) {
          const catalog: mtechnavi.api.worktask.IWorkTaskCatalog[] = (
            catalogList.items ?? []
          ).filter((v) =>
            (sourcePageInfo.current.ids || []).length > 0
              ? v.workTaskCatalogId === sourcePageInfo.current.ids![0] ?? ''
              : ''
          );
          setWorkTaskCatalogItem(
            catalog.length > 0
              ? [
                  {
                    value: catalog[0].workTaskCatalogId ?? '',
                    displayName: catalog[0].displayName ?? '',
                  },
                ]
              : []
          );
        }

        // 編集モード
        if (actionType !== 'add') {
          setSuspendCatalogChange(true);
          if (!workTaskData.current) {
            const workTask =
              await window.App.services.workTaskService.getWorkTask({
                workTaskId:
                  (sourcePageInfo.current.ids || []).length > 0
                    ? sourcePageInfo.current.ids![0] ?? ''
                    : '',
              });
            workTaskData.current = workTask;
            setWorkTask(workTaskData.current);
          }
        }

        setLoading(false);
      })();
    } catch (err) {
      error(getExceptionMessage(intl, err));
    }
  }, [intl, actionType]);

  // 画面データ変更時
  useEffect(() => {
    if (!workTask) {
      return;
    }
    setManagementInitialLoad(true);
    setWorkInitialLoad(true);
    (async () => {
      setWorkTaskCatalogItem(
        workTask.workTaskCatalog
          ? [
              {
                value: workTask.workTaskCatalog.workTaskCatalogId || '',
                displayName: workTask.workTaskCatalog.displayName || '',
              },
            ]
          : []
      );
      setWorkTaskCatalog(workTask.workTaskCatalog || undefined);
      setManagementOrganizationItem([
        convertOrganizationStructureReferenceToFilterboxItem(
          workTask.managementOrganization
        ) ?? { value: '', displayName: '' },
      ]);
      setManagementStaffItem([
        convertUserReferenceToFilterboxItem(workTask.managementStaff) ?? {
          value: '',
          displayName: '',
        },
      ]);
      setDisplayName(
        actionType !== 'copy'
          ? workTask.displayName || ''
          : `コピー${workTask.displayName || ''}`
      );
      setWorktaskDueDateDt(
        actionType !== 'copy'
          ? convertDate(workTask.worktaskDueDateDt || null)
          : null
      );
      setWorkOrganizationItem([
        convertOrganizationStructureReferenceToFilterboxItem(
          workTask.workOrganization
        ) ?? { value: '', displayName: '' },
      ]);
      setWorkStaffItem([
        convertUserReferenceToFilterboxItem(workTask.workStaff) ?? {
          value: '',
          displayName: '',
        },
      ]);
      setWorktaskTicketDueDateDt(
        convertDate(
          actionType !== 'copy'
            ? workTask.worktaskTicketDueDateDt || null
            : null
        )
      );
      setAsapCheck(workTask.asap ? ['1'] : []);
      setAsapComment(workTask.asapComment || '');
      setContents(workTask.contents ?? '');
      setApproval(!!workTask.approval);
      const managementStaff = await getUserById(
        workTask.managementStaff?.userId || ''
      );
      const workStaff = await getUserById(workTask.workStaff?.userId || '');
      const otherUsers =
        workTask.systemNotificationUsers?.filter(
          (user) =>
            user.email !== managementStaff?.email &&
            user.email !== workStaff?.email
        ) || [];
      setManagementStaffUser(
        workTask.systemNotificationUsers?.find(
          (user) => user.email === managementStaff?.email
        )
      );
      setWorkStaffUser(
        workTask.systemNotificationUsers?.find(
          (user) => user.email === workStaff?.email
        )
      );
      setSystemNotificationUsersItem(
        otherUsers.map((user) => ({
          displayName: user.displayName || '',
          value: user.email || '',
        }))
      );
      setRequestRemarks(workTask.requestRemarks || '');
      setRequestAttachments(
        (workTask.requestAttachments ?? []) as AttachmentItems[]
      );
    })();
    // workTask, getUserById変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workTask, getUserById]);

  // カタログ変更時
  useEffect(() => {
    if (workTaskCatalogItem.length <= 0 || isSuspendCatalogChange) {
      return;
    }
    (async () => {
      const catalog = catalogList?.items.find(
        (item) => item.workTaskCatalogId === workTaskCatalogItem[0].value
      );
      if (!catalog) {
        setWorkTaskCatalog(undefined);
        return;
      }
      setWorkTaskCatalog(catalog);
      setManagementOrganizationItem([
        convertOrganizationStructureReferenceToFilterboxItem(
          catalog.managementOrganization
        ) ?? { value: '', displayName: '' },
      ]);
      setWorkOrganizationItem([
        convertOrganizationStructureReferenceToFilterboxItem(
          catalog.workOrganization
        ) ?? { value: '', displayName: '' },
      ]);
      setContents(catalog.contents ?? '');
      setApproval(!!catalog.approval);
      setRequestAttachments(
        (catalog.commonAttachments ?? []) as AttachmentItems[]
      );

      // 値のセット直後にblurしても値が評価されないため、後からblurをかける
      setTimeout(() => {
        setWorkingBlurManagementOrganizationUnit(new Date());
        setWorkingBlurWorkOrganizationUnit(new Date());
      }, 100);
    })();
  }, [workTaskCatalogItem, isSuspendCatalogChange, catalogList?.items]);

  // 管理組織の選択時
  useEffect(() => {
    // 組織が空の場合
    if (managementOrganizationItem.length === 0) {
      setManagementStaffCustomQuery({});
      setManagementStaffItem([]);
      return;
    }

    // 組織に所属しているユーザー一覧を抽出
    const users = userList.filter(
      (item) =>
        item.organizationId ===
        (managementOrganizationItem.length
          ? managementOrganizationItem[0].value
          : '')
    );
    const query: FilterExpression = users
      ? {
          'user.userId': {
            $in:
              users.map((item) => {
                return item.userId ?? '';
              }) ?? [],
          },
        }
      : {};
    setManagementStaffCustomQuery(query);
    // 初回画面表示時は先の処理を行わない
    if (isManagementInitialLoad) {
      setManagementInitialLoad(false);
      return;
    }
    (async () => {
      const userRepresentativeOrg =
        await window.App.services.ui.getUserRepresentativeOrganization(
          managementStaffItem.length > 0 ? managementStaffItem[0].value : ''
        );
      // 現在の管理担当の代表所属組織と管理組織が一致しない場合、管理担当を空にする
      if (
        userRepresentativeOrg &&
        userRepresentativeOrg.organizationId !==
          managementOrganizationItem[0].value
      ) {
        setManagementStaffItem([]);
      }
    })();
    // managementOrganizationItem変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managementOrganizationItem]);

  // 作業組織の選択時
  useEffect(() => {
    if (workOrganizationItem.length === 0) {
      setWorkStaffCustomQuery({});
      setWorkStaffItem([]);
      return;
    }
    const users = userList.filter(
      (item) =>
        item.organizationId ===
        (workOrganizationItem.length ? workOrganizationItem[0].value : '')
    );
    const query: FilterExpression = users
      ? {
          'user.userId': {
            $in:
              users.map((item) => {
                return item.userId ?? '';
              }) ?? [],
          },
        }
      : {};
    setWorkStaffCustomQuery(query);
    // 初回画面表示時は先の処理を行わない
    if (isWorkInitialLoad) {
      setWorkInitialLoad(false);
      return;
    }
    (async () => {
      const userRepresentativeOrg =
        await window.App.services.ui.getUserRepresentativeOrganization(
          workStaffItem.length > 0 ? workStaffItem[0].value : ''
        );
      if (
        userRepresentativeOrg &&
        userRepresentativeOrg?.organizationId !== workOrganizationItem[0].value
      ) {
        setWorkStaffItem([]);
      }
    })();
    // workOrganizationItem変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrganizationItem]);

  // 管理担当の選択時
  useEffect(() => {
    (async () => {
      if (managementStaffItem.length === 0) {
        setManagementStaffUser(undefined);
        return;
      }
      const user = await getUserReferenceByEmail(managementStaffItem[0]?.value);
      if (!user || !user.userId) {
        setManagementStaffUser(undefined);
        return;
      }
      const staff = await getOrganizationRelationById(user?.userId);
      if (!staff) {
        setManagementStaffUser(undefined);
        return;
      }
      setManagementStaffUser(user || undefined);

      if (managementOrganizationItem.length === 0) {
        // 所属組織が空かつ、管理担当の代表所属組織が存在する場合、管理組織にセットする
        const org =
          await window.App.services.ui.getUserRepresentativeOrganization(
            managementStaffItem[0].value || ''
          );
        if (org?.organizationId) {
          setManagementOrganizationItem(
            managementOrganizationFilterBoxItems.filter(
              (item) => item.value === org.organizationId
            )
          );
        }
      }
      // システム通知先の他担当更新
      if (systemNotificationUsersItem.length) {
        setSystemNotificationUsersItem(
          systemNotificationUsersItem.filter((v) => v.value !== user.email)
        );
      }
      // 値のセット直後にblurしても値が評価されないため、後からblurをかける
      setTimeout(() => {
        setWorkingBlurManagementOrganizationUnit(new Date());
      }, 100);
    })();
    // 下記変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    managementStaffItem,
    getOrganizationById,
    getOrganizationRelationById,
    getUserReferenceByEmail,
  ]);

  // 作業担当の選択時
  useEffect(() => {
    (async () => {
      if (workStaffItem.length === 0) {
        setWorkStaffUser(undefined);
        return;
      }
      const user = await getUserReferenceByEmail(workStaffItem[0]?.value);
      if (!user || !user.userId) {
        setWorkStaffUser(undefined);
        return;
      }
      const staff = await getOrganizationRelationById(user.userId);
      if (!staff) {
        setWorkStaffUser(undefined);
        return;
      }
      setWorkStaffUser(user || undefined);
      if (workOrganizationItem.length === 0) {
        // 作業組織が空かつ、作業担当の代表所属組織が存在する場合、作業組織にセットする
        const org =
          await window.App.services.ui.getUserRepresentativeOrganization(
            workStaffItem[0].value || ''
          );
        if (org?.organizationId) {
          setWorkOrganizationItem(
            workOrganizationFilterBoxItems.filter(
              (item) => item.value === org.organizationId
            )
          );
        }
      }

      // システム通知先の他担当更新
      if (systemNotificationUsersItem.length) {
        setSystemNotificationUsersItem(
          systemNotificationUsersItem.filter((v) => v.value !== user.email)
        );
      }
      // 値のセット直後にblurしても値が評価されないため、後からblurをかける
      setTimeout(() => {
        setWorkingBlurWorkOrganizationUnit(new Date());
      }, 100);
    })();
    // 下記変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    workStaffItem,
    getOrganizationById,
    getOrganizationRelationById,
    getUserReferenceByEmail,
  ]);

  // ASAP
  useEffect(() => {
    if (asapCheck.length > 0) {
      setWorktaskTicketDueDateDt(null);
    } else {
      setAsapComment('');
    }
  }, [asapCheck]);

  // リサイズ
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="WtWorkTaskInput">
          <div className="header">
            <PageNavigation
              backpagePath="/work-task/wk-worktask-list"
              pageInfo={{
                isVisibleMoveNavi: false,
              }}
              infoOption={{
                lastUpdateInfo: { isVisibleUpdateInfo: false },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => {
                setConfirmDialogInfo({
                  viewMessage: viewMessageCancel,
                  onDecision: backToPreviewPage,
                });
                setOpenConfirmDialog(true);
              }}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
            ref={formAreaRef}
          >
            <div
              className={`input-blocktitle-outer ${
                accordionState.request ? '' : 'close'
              }`}
              ref={requireRequest}
            >
              <h3
                className="input-blocktitle"
                onClick={() => accordionAction('request')}
              >
                {GetMessage({ id: 'request', prefixId: VIEW_ID })}
              </h3>
            </div>
            <div
              className={`input-blockbody indent ${
                accordionState.request ? '' : 'close'
              }`}
            >
              <div className="input-line worktask-form">
                <div className="item-group-100">
                  <div className="w-25">
                    <Filterbox
                      name="workTaskCatalog"
                      fullMethodName={FullMethodName_ListWorkTaskCatalogs}
                      labelId="WT_WORKTASK_INPUT.workTaskCatalog"
                      columns={preset.columns}
                      itemType={catalogItemType}
                      value={workTaskCatalogItem}
                      validateOption={{ required: true }}
                      onChangeState={setWorkTaskCatalogItem}
                      searchOption={{
                        targets: 'displayName',
                        isLatestData: true,
                      }}
                      workingBlur={workingBlurWorktaskCatalog}
                      disabled={actionType === 'edit'}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-50">
                    <div className="input-line no-space">
                      <div className="item-group-100 no-space">
                        <div className="w-100">
                          <DataFilterbox
                            name="managementOrganization"
                            properties={[
                              {
                                name: 'managementOrganization',
                                propertyName: 'width',
                                propertyValue: '100%',
                              },
                            ]}
                            columns={preset.columns}
                            labelId="WT_WORKTASK_INPUT.managementOrganization"
                            validateOption={{ required: true }}
                            workingBlur={workingBlurManagementOrganizationUnit}
                            data={managementOrganizationFilterBoxItems}
                            value={
                              managementOrganizationItem ?? [
                                { value: '', displayName: '' },
                              ]
                            }
                            onChangeState={(item) => {
                              setManagementOrganizationItem(item);
                            }}
                          />
                        </div>
                        <div className="w-12">
                          <div className="side-search-icon">
                            <IconButton
                              name="search"
                              buttonType="basic"
                              className="button"
                              iconType={'search'}
                              onClick={() =>
                                setOpenManagementOrgSearchDialog(true)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-25">
                    <Filterbox
                      name="managementStaff"
                      columns={preset.columns}
                      fullMethodName={FullMethodName_ListUserAttributes}
                      labelId="WT_WORKTASK_INPUT.managementStaff"
                      itemType={userItemType}
                      value={
                        managementStaffItem ?? [{ value: '', displayName: '' }]
                      }
                      searchOption={{
                        targets: 'displayName',
                        customQuery: managementStaffCustomQuery,
                      }}
                      onChangeState={setManagementStaffItem}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-50">
                    <Textbox
                      name="displayName"
                      columns={preset.columns}
                      className="field"
                      value={displayName}
                      type="text"
                      validateOption={{ required: true }}
                      labelId="WT_WORKTASK_INPUT.displayName"
                      onChangeState={setDisplayName}
                    />
                  </div>
                  <div className="w-25">
                    <DateSuggest
                      name="worktaskDueDateDt"
                      columns={preset.columns}
                      labelId="WT_WORKTASK_INPUT.worktaskDueDateDt"
                      validator={validateFreeDate(intl)}
                      onChangeState={setWorktaskDueDateDt}
                      value={worktaskDueDateDt}
                    />
                  </div>
                </div>
              </div>

              <div className="input-line mt-3">
                <div className="item-group-100">
                  <div className="w-50">
                    <div className="input-line no-space">
                      <div className="item-group-100 no-space">
                        <div className="w-100">
                          <DataFilterbox
                            name="workOrganizationUnits"
                            labelId="WT_WORKTASK_INPUT.workOrganization"
                            columns={['workOrganizationUnits']}
                            validateOption={{ required: true }}
                            workingBlur={workingBlurWorkOrganizationUnit}
                            data={workOrganizationFilterBoxItems}
                            value={
                              workOrganizationItem ?? [
                                { value: '', displayName: '' },
                              ]
                            }
                            onChangeState={(item) => {
                              setWorkOrganizationItem(item);
                            }}
                          />
                        </div>
                        <div className="w-12">
                          <div className="side-search-icon">
                            <IconButton
                              name="search"
                              buttonType="basic"
                              className="button"
                              iconType={'search'}
                              onClick={() => setOpenWorkOrgSearchDialog(true)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-25">
                    <Filterbox
                      name="workStaff"
                      fullMethodName={FullMethodName_ListUserAttributes}
                      labelId="WT_WORKTASK_INPUT.workStaff"
                      columns={['workStaff']}
                      itemType={userItemType}
                      value={workStaffItem ?? [{ value: '', displayName: '' }]}
                      searchOption={{
                        targets: 'displayName',
                        customQuery: workStaffCustomQuery,
                      }}
                      onChangeState={setWorkStaffItem}
                    />
                  </div>
                </div>
              </div>

              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-25">
                    <DateSuggest
                      name="worktaskTicketDueDateDt"
                      columns={preset.columns}
                      labelId="WT_WORKTASK_INPUT.worktaskTicketDueDateDt"
                      validator={validateFreeDate(intl)}
                      onChangeState={setWorktaskTicketDueDateDt}
                      value={worktaskTicketDueDateDt}
                      disabled={asapCheck.length > 0}
                    />
                  </div>
                  <div className="w-25 line-height-4rem">
                    <Checkbox
                      name="checkbox"
                      className="group"
                      items={[
                        {
                          value: '1',
                          displayName: GetMessage({
                            prefixId: 'WT_WORKTASK_INPUT',
                            id: 'asap',
                          }),
                        },
                      ]}
                      value={asapCheck}
                      onChangeState={setAsapCheck}
                      columns={['checkbox']}
                    />
                  </div>
                  <div className="w-50">
                    <Textbox
                      name="asapComment"
                      columns={preset.columns}
                      className="field"
                      value={asapComment}
                      type="text"
                      labelId="WT_WORKTASK_INPUT.asapComment"
                      onChangeState={setAsapComment}
                      disabled={asapCheck.length <= 0}
                    />
                  </div>
                </div>
              </div>

              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-75">
                    <Textarea
                      name="contents"
                      columns={preset.columns}
                      className="w-100 mh-high"
                      labelId="WT_WORKTASK_INPUT.contents"
                      value={contents}
                      onChangeState={setContents}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`input-blocktitle-outer ${
                accordionState.businessUnit ? '' : 'close'
              }`}
            >
              <h3
                className="input-blocktitle"
                onClick={() => accordionAction('businessUnit')}
              >
                {GetMessage({ id: 'businessUnit', prefixId: VIEW_ID })}
              </h3>
            </div>
            <div
              className={`input-blockbody indent ${
                accordionState.businessUnit ? '' : 'close'
              }`}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-25">
                    <Textbox
                      name="managementStaff"
                      columns={preset.columns}
                      className="field"
                      value={formatUserWIthEmail(
                        managementStaffUser?.displayName,
                        managementStaffUser?.email
                      )}
                      type="text"
                      labelId="WT_WORKTASK_INPUT.managementStaff"
                      disabled={true}
                    />
                  </div>
                  <div className="w-25">
                    <Textbox
                      name="workStaff"
                      columns={preset.columns}
                      className="field"
                      value={formatUserWIthEmail(
                        workStaffUser?.displayName,
                        workStaffUser?.email
                      )}
                      type="text"
                      labelId="WT_WORKTASK_INPUT.workStaff"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-50">
                    <Filterbox
                      name="systemNotificationUsers"
                      columns={preset.columns}
                      fullMethodName={FullMethodName_ListUserAttributes}
                      labelId="WT_WORKTASK_INPUT.systemNotificationUsers"
                      itemType={userItemType}
                      value={
                        systemNotificationUsersItem ?? [
                          { value: '', displayName: '' },
                        ]
                      }
                      multiple={true}
                      searchOption={{
                        targets: 'displayName',
                        isLatestData: true,
                      }}
                      onChangeState={(v) => {
                        // 管理担当,作業担当と一致しないユーザーのみセット
                        const excludedUsers: string[] = [];
                        if (managementStaffItem.length) {
                          excludedUsers.push(managementStaffItem[0].value);
                        }
                        if (workStaffItem.length) {
                          excludedUsers.push(workStaffItem[0].value);
                        }
                        if (!excludedUsers.length) {
                          setSystemNotificationUsersItem(v);
                          return;
                        }
                        setSystemNotificationUsersItem(
                          v.filter(
                            (user) =>
                              !excludedUsers.some(
                                (excludedUser) => excludedUser === user.value
                              )
                          )
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`input-blocktitle-outer ${
                accordionState.reference ? '' : 'close'
              }`}
            >
              <h3
                className="input-blocktitle"
                onClick={() => accordionAction('reference')}
              >
                {GetMessage({ id: 'reference', prefixId: VIEW_ID })}
              </h3>
            </div>
            <div
              className={`input-blockbody indent ${
                accordionState.reference ? '' : 'close'
              }`}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-75">
                    <Textarea
                      name="requestRemarks"
                      columns={preset.columns}
                      className="w-100 mh-high"
                      labelId="WT_WORKTASK_INPUT.requestRemarks"
                      value={requestRemarks}
                      onChangeState={setRequestRemarks}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line ">
                <div className="item-group-100 file-area">
                  <div className="w-50">
                    <SimpleListView
                      data={requestAttachments}
                      viewOptions={{
                        previewRowCount: 5,
                        columns: [
                          {
                            header: { id: 'attachmentList', viewId: VIEW_ID },
                            propertyName: 'filename',
                          },
                        ],
                      }}
                      actionOptions={{
                        onDelete: handleDeleteAttachFile,
                        onDeleteAll: handleDeleteAllAttachFile,
                        onRowClick: handleDownload,
                        onFullDownLoad: handleFullDownload,
                      }}
                    />
                  </div>
                  <div className="w-20">
                    <CaptionButton
                      name="attachFileButton"
                      buttonType="basic"
                      caption={labelAttachFile}
                      onClick={handleAttachFile}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                <CaptionButton
                  name="tempSaveButton"
                  buttonType="basic"
                  caption={labelTemporarySave}
                  onClick={handleConfirmTempSave}
                />
                {approval && (
                  <CaptionButton
                    name="approvalRequestButton"
                    buttonType="basic"
                    caption={labelApprovalRequest}
                    onClick={handleConfirmApprovalRequest}
                  />
                )}
                {!approval && (
                  <CaptionButton
                    name="taskActivateButton"
                    buttonType="basic"
                    caption={labelTaskActivate}
                    onClick={handleConfirmActivateWorkTask}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Toast />
      </Container>
      <ConfirmationDialog
        isOpen={isOpenConfirmDialog}
        viewMessage={confirmDialogInfo.viewMessage}
        onDecision={confirmDialogInfo.onDecision}
        onCancel={() => setOpenConfirmDialog(false)}
      />
      <ApprovalRequestDialog
        isOpen={isOpenApprovalRequestDialog}
        inputOption={{
          FullMethodName_ListUserAttributes: FullMethodName_ListUserAttributes,
          organizationRelations: userList,
          organizations: organizationList,
          requestReciptId: '',
        }}
        onDecision={handleApprovalRequest}
        onCancel={() => setOpenApprovalRequestDialog(false)}
      />
      <FileUploadDialog
        isOpen={isOpenFileUploadDialog}
        messageOption={{
          headerLabelId: { id: 'file_add', prefixId: 'DIALOG_TITLE' },
        }}
        fileUploadOption={{
          multiple: true,
          validateOption: {
            maxFileSizeInMebis: 50,
            maxFileCount: AttachFileCountLimit,
          },
        }}
        onDecision={uploadFiles}
        onCancel={() => setOpenFileUploadDialog(false)}
        onChangeLoadingState={(isLoading) => setLoading(isLoading)}
      />
      {/* 組織検索ダイアログ(管理) */}
      <OrganizationTreeSearchDialog
        isOpen={isOpenManagementOrgSearchDialog}
        organizationList={organizationList}
        checkedIds={
          managementOrganizationItem.length > 0
            ? [managementOrganizationItem[0].value]
            : []
        }
        messageOption={{
          headerLabelId: {
            prefixId: 'DIALOG_TITLE',
            id: 'OrganizationTreeSearchDialog',
          },
        }}
        onCancel={() => setOpenManagementOrgSearchDialog(false)}
        onDecision={(checks) => {
          if (checks.length > 1) {
            error([duplicated]);
            return;
          }
          setManagementOrganizationItem(
            managementOrganizationFilterBoxItems.filter(
              (v) => checks && checks[0] === v.value
            )
          );
          setOpenManagementOrgSearchDialog(false);
        }}
      />
      {/* 組織検索ダイアログ(作業) */}
      <OrganizationTreeSearchDialog
        isOpen={isOpenWorkOrgSearchDialog}
        organizationList={organizationList}
        checkedIds={
          workOrganizationItem.length > 0 ? [workOrganizationItem[0].value] : []
        }
        messageOption={{
          headerLabelId: {
            prefixId: 'DIALOG_TITLE',
            id: 'OrganizationTreeSearchDialog',
          },
        }}
        onCancel={() => setOpenWorkOrgSearchDialog(false)}
        onDecision={(checks) => {
          if (checks.length > 1) {
            error([duplicated]);
            return;
          }
          setWorkOrganizationItem(
            workOrganizationFilterBoxItems.filter(
              (v) => checks && checks[0] === v.value
            )
          );
          setOpenWorkOrgSearchDialog(false);
        }}
      />
      {isLoading && <LoadingIcon />}
    </>
  );
};
