import { mtechnavi } from '~/shared/libs/clientsdk';
import { getPathnameByViewId } from '~/shared/utils';
import {
  FullMethodName_ListPresetMenus,
  FullMethodName_ListPresets,
} from '~/worker';

export const getMenuItems = async (
  isDebug: boolean
): Promise<SideMenuItem[]> => {
  // debug用メニュー
  const debugMenus = isDebug ? window.App.debug.debugMenu : [];

  const menuList = await getPresetMenuItems();

  return [
    // debug表示
    ...debugMenus,

    // APIから取得した情報
    ...menuList,
  ];
};

const getPresetMenuItems = async (): Promise<SideMenuItem[]> => {
  // プリセットとメニュープリセットをワーカーから取得
  const listPresetsRes = (await window.App.services.ui.worker.filter({
    action: 'query',
    fullMethodName: FullMethodName_ListPresets,
    filter: {},
    sort: [],
  })) as mtechnavi.api.uicontroller.ListPresetsResponse;
  const listPresetMenusRes = (await window.App.services.ui.worker.filter({
    action: 'query',
    fullMethodName: FullMethodName_ListPresetMenus,
    filter: {},
    sort: [],
  })) as mtechnavi.api.uicontroller.ListPresetMenusResponse;
  const presets = listPresetsRes.items;
  const presetMenus = listPresetMenusRes.items;
  // parentPresetMenuIdが空のものがroot、そこからメニュー構成を辿る
  const rootMenu = presetMenus.find((v) => !v.parentPresetMenuId)?.container
    ?.childrenPresetMenuIds;

  const menuList: SideMenuItem[] =
    rootMenu
      ?.map((menu) => {
        // メニュー情報取得（サブメニューまで）
        return presetMenus.find((v) => v.presetMenuId === menu);
      })
      .map((menu): SideMenuItem => {
        // メニュー情報を設定
        return {
          label: menu?.container?.displayName ?? '',
          // サブメニュー情報取得及び設定
          children: menu?.container?.childrenPresetMenuIds
            ?.map((subMenu) => {
              // サブメニュー情報取得
              return presetMenus.find((v) => v.presetMenuId === subMenu);
            })
            ?.map((subMenu): SideMenuItem => {
              // メニューのアイテム情報を取得
              const preset = presets.find(
                (v) => subMenu?.item?.presetId === v.presetId
              );

              // メニューアイテムを設定
              return {
                label: preset?.displayName ?? '',
                pathname: getMenuPathname(preset?.viewId ?? ''),
                viewId: preset?.viewId ?? '',
              };
            }),
        };
      }) ?? [];
  return menuList;
};

// メニューアイテムのパス情報取得
const getMenuPathname = (viewId: string) => {
  // パス情報の読み込み
  const target = getPathnameByViewId(viewId);
  if (target) {
    return target.path;
  } else {
    return '/not-found'; // pathnameの有無でカテゴリ表示を判断しているため現状は /dummy or /not-found とする
  }
};
