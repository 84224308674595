import { useIntl } from 'react-intl';
import {
  Checkbox,
  CheckboxItem,
  GetMessageWithIntl,
  MessageProps,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../..';
import {
  CroppedViewer,
  ViewerControlButton,
  ViewerOverlayProperties,
  PROT_RATIO_ACCURACY,
} from '../Viewer';
import { CaptionButton } from '../Button';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import './SegmentConfirmationDialog.css';
import { SegmentEditDialog } from './SegmentEditDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';
import Long from 'long';
import { useConfirmation } from '~/shared/hooks';
import { BlueprintSegmentNames } from '~/tenant/blueprint/utils';

const CROP_VIEW_MIN_HEIGHT = '20vh';
const CROP_VIEW_MAX_HEIGHT = '50vh';

export interface MessageOption {
  headerLabel: MessageProps;
}

type SegmentInfoList = {
  segment?: mtechnavi.api.blueprint.IBlueprintSegment | null;
};
type InputOption = SegmentInfoList & {
  assetId: string;
  mode: 'confirm' | 'edit' | 'searchCondition';
};
export type SegmentConfirmationDialogResult =
  mtechnavi.api.blueprint.IBlueprintSegment | null;
export interface SegmentConfirmationDialogProps {
  isOpen: boolean;
  messageOption: MessageOption;
  inputOption: InputOption;
  onDecision: (result: SegmentConfirmationDialogResult) => void;
  onCancel: () => void;
}
export const SegmentConfirmationDialog = ({
  isOpen,
  messageOption,
  inputOption,
  onDecision,
  onCancel,
}: SegmentConfirmationDialogProps) => {
  const intl = useIntl();
  const [isOpenEdit, setOpenEdit] = useState(false);
  const { confirmation, confirmationElement } = useConfirmation();

  const { mode } = inputOption;
  const imageWidth = inputOption?.segment?.imageProperty?.imageWidth;
  const imageHeight = inputOption?.segment?.imageProperty?.imageHeight;

  const [frontChecked, setFrontChecked] = useState<boolean | undefined>(
    !!inputOption?.segment?.segmentPropertys?.find(
      (s) => s.segmentName === BlueprintSegmentNames.Front
    )?.fixed
  );
  const [rightChecked, setRightChecked] = useState<boolean | undefined>(
    !!inputOption?.segment?.segmentPropertys?.find(
      (s) => s.segmentName === BlueprintSegmentNames.Right
    )?.fixed
  );
  const [bottomChecked, setBottomChecked] = useState<boolean | undefined>(
    !!inputOption?.segment?.segmentPropertys?.find(
      (s) => s.segmentName === BlueprintSegmentNames.Bottom
    )?.fixed
  );
  const [frontSegment, setFrontSegment] = useState<
    ViewerOverlayProperties | undefined
  >(
    segmentToProtInfo(
      { imageWidth, imageHeight },
      inputOption?.segment?.segmentPropertys?.find(
        (s) => s.segmentName === BlueprintSegmentNames.Front
      )
    )
  );
  const [rightSegment, setRightSegment] = useState<
    ViewerOverlayProperties | undefined
  >(
    segmentToProtInfo(
      { imageWidth, imageHeight },
      inputOption?.segment?.segmentPropertys?.find(
        (s) => s.segmentName === BlueprintSegmentNames.Right
      )
    )
  );
  const [bottomSegment, setBottomSegment] = useState<
    ViewerOverlayProperties | undefined
  >(
    segmentToProtInfo(
      { imageWidth, imageHeight },
      inputOption?.segment?.segmentPropertys?.find(
        (s) => s.segmentName === BlueprintSegmentNames.Bottom
      )
    )
  );

  const existsUncheckedSegments = inputOption.segment?.segmentPropertys?.some(
    (segment) => !segment.fixed
  );

  const checkedValue: CheckboxItem[] = [
    {
      displayName: GetMessageWithIntl(intl, {
        prefixId: 'SegmentConfirmationDialog',
        id: 'checked',
      }),
      value: '1',
    },
  ];

  const handleDecision = async () => {
    if (mode === 'edit') {
      const isUnchecked =
        (frontSegment && !frontChecked) ||
        (rightSegment && !rightChecked) ||
        (bottomSegment && !bottomChecked);
      const confirmMessage = isUnchecked
        ? {
            prefixId: 'SegmentConfirmationDialog',
            id: 'existsUnCheckedSegmentConfirmation',
          }
        : {
            id: 'C0000001',
            value: { $1: GetMessageWithIntl(intl, { id: 'save' }) },
          };
      const confirmed = await confirmation(confirmMessage);
      if (!confirmed) {
        return;
      }
    }

    const segments: mtechnavi.api.blueprint.ISegmentProperty[] = [];
    if (frontSegment) {
      segments.push({
        ...protInfoToSegment({ imageWidth, imageHeight }, frontSegment),
        fixed: frontChecked,
        segmentName: BlueprintSegmentNames.Front,
      });
    }
    if (rightSegment) {
      segments.push({
        ...protInfoToSegment({ imageWidth, imageHeight }, rightSegment),
        fixed: rightChecked,
        segmentName: BlueprintSegmentNames.Right,
      });
    }
    if (bottomSegment) {
      segments.push({
        ...protInfoToSegment({ imageWidth, imageHeight }, bottomSegment),
        fixed: bottomChecked,
        segmentName: BlueprintSegmentNames.Bottom,
      });
    }

    onDecision({
      ...inputOption?.segment,
      segmentPropertys: segments,
    });
  };

  const handleSegmentEdit =
    useRef<(plotInfo: ViewerOverlayProperties) => void>();
  const [editPlotInfo, setEditPlotInfo] = useState<ViewerOverlayProperties>();
  const generateSegmentEditCallback = (
    resultDispatch: (
      value: SetStateAction<ViewerOverlayProperties | undefined>
    ) => void,
    checkedDispatch: (value: SetStateAction<boolean | undefined>) => void
  ) => {
    return (plotInfo: ViewerOverlayProperties) => {
      resultDispatch(plotInfo);
      checkedDispatch(true);
    };
  };

  const renderCroppedViewer = ({
    labelId,
    checked,
    segment,
    setChecked,
    setSegment,
  }: {
    labelId: string;
    checked?: boolean;
    segment?: ViewerOverlayProperties;
    setChecked: Dispatch<SetStateAction<boolean | undefined>>;
    setSegment: Dispatch<SetStateAction<ViewerOverlayProperties | undefined>>;
  }) => (
    <div className="w-33">
      <div className="segment-label">
        {GetMessageWithIntl(intl, {
          prefixId: 'SegmentConfirmationDialog',
          id: labelId,
        })}
        {mode === 'edit' && (
          <Checkbox
            name="checked"
            items={checkedValue}
            value={checked ? '1' : undefined}
            disabled={!segment}
            onChangeState={(v) => setChecked(v.length > 0)}
          />
        )}
      </div>
      <CroppedViewer
        assetId={inputOption.assetId}
        cropInfo={segment}
        imageStyle={{
          minHeight: CROP_VIEW_MIN_HEIGHT,
          maxHeight: CROP_VIEW_MAX_HEIGHT,
        }}
        controls={
          mode === 'confirm'
            ? []
            : [
                <ViewerControlButton
                  key="edit"
                  name="edit"
                  iconType="edit"
                  onClick={() => {
                    setEditPlotInfo(segment ? { ...segment } : undefined);
                    handleSegmentEdit.current = generateSegmentEditCallback(
                      setSegment,
                      setChecked
                    );
                    setOpenEdit(true);
                  }}
                />,
                <ViewerControlButton
                  key="delete"
                  name="delete"
                  iconType="delete"
                  onClick={() => {
                    setSegment(undefined);
                    setChecked(false);
                  }}
                />,
              ]
        }
      />
    </div>
  );

  const view = (
    <div className="segment-confirmation-dialog">
      <div className="detail-area">
        <p>
          {mode === 'edit' &&
            existsUncheckedSegments &&
            GetMessageWithIntl(intl, {
              prefixId: 'SegmentConfirmationDialog',
              id: 'existsUnCheckedSegment',
            })}
        </p>
      </div>
      <div className="detail-area">
        <div className="input-line">
          <div className="item-group-100">
            {renderCroppedViewer({
              labelId: BlueprintSegmentNames.Front,
              checked: frontChecked,
              segment: frontSegment,
              setChecked: setFrontChecked,
              setSegment: setFrontSegment,
            })}
            {renderCroppedViewer({
              labelId: BlueprintSegmentNames.Right,
              checked: rightChecked,
              segment: rightSegment,
              setChecked: setRightChecked,
              setSegment: setRightSegment,
            })}
            {renderCroppedViewer({
              labelId: BlueprintSegmentNames.Bottom,
              checked: bottomChecked,
              segment: bottomSegment,
              setChecked: setBottomChecked,
              setSegment: setBottomSegment,
            })}
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, {
            id: mode === 'confirm' ? 'close' : 'cancel',
          })}
          onClick={() => onCancel()}
        />
        {mode !== 'confirm' && (
          <CaptionButton
            name="sendBtn"
            buttonType="basic"
            className="button"
            caption={GetMessageWithIntl(intl, {
              id: mode === 'edit' ? 'save' : 'decision',
            })}
            onClick={handleDecision}
          />
        )}
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: onCancel,
    send: handleDecision,
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabel,
    messageLabelId: {},
    elements: view,
  };

  return (
    <div className="SegmentConfirmationDialog">
      <ModalDialogComponent {...openModalProps} />
      <SegmentEditDialog
        key={isOpenEdit ? 1 : 0}
        isOpen={isOpenEdit}
        messageOption={{
          headerLabel: {
            prefixId: 'DIALOG_TITLE',
            id: 'SegmentEditDialog',
          },
        }}
        inputOption={{
          assetId: inputOption.assetId,
          plotInfo: editPlotInfo,
        }}
        onDecision={(result) => {
          if (!handleSegmentEdit.current) {
            return;
          }
          handleSegmentEdit.current(result);
          setOpenEdit(false);
        }}
        onCancel={() => setOpenEdit(false)}
      />
      {confirmationElement}
    </div>
  );
};

/** セグメント情報を Viewer の座標情報に変換 */
const segmentToProtInfo = (
  imageSize: {
    imageWidth?: Long.Long | null;
    imageHeight?: Long.Long | null;
  },
  segment?: mtechnavi.api.blueprint.ISegmentProperty | null
): ViewerOverlayProperties | undefined => {
  if (!segment || !imageSize.imageWidth || !imageSize.imageHeight) {
    return;
  }
  const imageWidth = Long.fromValue(imageSize.imageWidth);
  const imageHeight = Long.fromValue(imageSize.imageHeight);
  const left = Long.fromValue(segment.left ?? 0);
  const top = Long.fromValue(segment.top ?? 0);
  const width = Long.fromValue(segment.width ?? 0);
  const height = Long.fromValue(segment.height ?? 0);

  return {
    x1: left.multiply(PROT_RATIO_ACCURACY).div(imageWidth).toNumber(),
    y1: top.multiply(PROT_RATIO_ACCURACY).div(imageHeight).toNumber(),
    x2: left
      .add(width)
      .multiply(PROT_RATIO_ACCURACY)
      .div(imageWidth)
      .toNumber(),
    y2: top
      .add(height)
      .multiply(PROT_RATIO_ACCURACY)
      .div(imageHeight)
      .toNumber(),
  };
};

/** Viewer の座標情報をセグメント情報に変換 */
const protInfoToSegment = (
  imageSize: {
    imageWidth?: Long.Long | null;
    imageHeight?: Long.Long | null;
  },
  prot: ViewerOverlayProperties
): mtechnavi.api.blueprint.ISegmentProperty => {
  if (!imageSize.imageWidth || !imageSize.imageHeight) {
    return {
      left: Long.fromNumber(0),
      top: Long.fromNumber(0),
      width: Long.fromNumber(0),
      height: Long.fromNumber(0),
    };
  }
  const imageWidth = Long.fromValue(imageSize.imageWidth);
  const imageHeight = Long.fromValue(imageSize.imageHeight);
  const x1 = Long.fromNumber(prot.x1)
    .multiply(imageWidth)
    .div(PROT_RATIO_ACCURACY);
  const y1 = Long.fromNumber(prot.y1)
    .multiply(imageHeight)
    .div(PROT_RATIO_ACCURACY);
  const x2 = Long.fromNumber(prot.x2)
    .multiply(imageWidth)
    .div(PROT_RATIO_ACCURACY);
  const y2 = Long.fromNumber(prot.y2)
    .multiply(imageHeight)
    .div(PROT_RATIO_ACCURACY);
  return {
    // 範囲指定の仕方によっては逆転しているので、小さい方を left / top として扱う
    left: x1.lt(x2) ? x1 : x2,
    top: y1.lt(y2) ? y1 : y2,
    width: x1.lt(x2) ? x2.sub(x1) : x1.sub(x2),
    height: y1.lt(y2) ? y2.sub(y1) : y1.sub(y2),
  };
};
