import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface UpdateBillingCompany {
  actionName: 'updateBillingCompany';
  request: mtechnavi.api.company.IBillingCompany;
}

export const updateBillingCompany = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.company.IBillingCompany
) => {
  const UpdateFullMethodName =
    'mtechnavi.api.company.BillingCompanyService/UpdateBillingCompany';

  return await worker.invokeListRequest(UpdateFullMethodName, {
    billingCompany: request,
  });
};
