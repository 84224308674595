import { CroppedViewer, ViewerControlButton } from '~/shared/components/ui';
import { mtechnavi } from '~/shared/libs/clientsdk';

interface Props {
  previewAsset?: mtechnavi.api.assetinventory.IAsset;
}

export const CroppedViewerSample = ({ previewAsset }: Props) => {
  const assetId = previewAsset?.assetId ?? '';
  return (
    <>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-25">
            <label>右上(50:25)</label>
            <CroppedViewer
              key={`${assetId}-1`}
              assetId={assetId}
              cropInfo={{
                x1: 5000,
                y1: 0,
                x2: 10000,
                y2: 2500,
              }}
              controls={
                <ViewerControlButton
                  name="edit"
                  iconType="edit"
                  onClick={() => {
                    console.log('CroppedViewer 1 Edit');
                  }}
                />
              }
            />
            <label>左下(75:75)</label>
            <CroppedViewer
              key={`{a$ssetId}-2`}
              assetId={assetId}
              cropInfo={{
                x1: 0,
                y1: 2500,
                x2: 7500,
                y2: 10000,
              }}
              controls={
                <ViewerControlButton
                  name="edit"
                  iconType="edit"
                  onClick={() => {
                    console.log('CroppedViewer 2 Edit');
                  }}
                />
              }
            />
            <label>真ん中(25:50)</label>
            <CroppedViewer
              key={`${assetId}-3`}
              assetId={assetId}
              cropInfo={{
                x1: 3750,
                y1: 2500,
                x2: 6250,
                y2: 7500,
              }}
              controls={
                <ViewerControlButton
                  name="edit"
                  iconType="edit"
                  onClick={() => {
                    console.log('CroppedViewer 3 Edit');
                  }}
                />
              }
            />
          </div>
          <div className="w-66">なんらかのコンテンツ</div>
        </div>
      </div>
    </>
  );
};
