import { useState } from 'react';
import {
  AsyncTaskAcceptDialog,
  MessageDialog,
  ProcessingDialog,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';

export const UISampleMessageDialogs = () => {
  return (
    <div className="categories">
      <h3 id="dialog">メッセージダイアログ</h3>
      <h4>汎用メッセージダイアログ</h4>
      <MessageDialogSample />
      <h4>非同期処理受付ダイアログ</h4>
      <AsyncTaskAcceptDialogSample />
      <h4>汎用メッセージダイアログ(任意の要素を渡す)</h4>
      <MessageDialogElementSample />
      <h4>処理中ダイアログ</h4>
      <ProcessingDialogSample />
    </div>
  );
};

const MessageDialogSample = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="input-line">
      <div className="item-group-100">
        <div className="w-33">
          <CaptionButton
            buttonType="basic"
            caption="確認する"
            name=""
            onClick={() => {
              setOpen(true);
            }}
          />
          <MessageDialog
            isOpen={isOpen}
            messageOption={{
              headerLabelId: {
                prefixId: 'DIALOG_TITLE',
                id: 'InformationDialog',
              },
              message: {
                prefixId: 'InformationDialog',
                id: 'content',
              },
            }}
            onClose={() => {
              setOpen(false);
            }}
          />
        </div>
        <div className="w-66">
          <pre className="output"></pre>
        </div>
      </div>
    </div>
  );
};

const AsyncTaskAcceptDialogSample = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="input-line">
      <div className="item-group-100">
        <div className="w-33">
          <CaptionButton
            buttonType="basic"
            caption="確認する"
            name=""
            onClick={() => {
              setOpen(true);
            }}
          />
          <AsyncTaskAcceptDialog
            isOpen={isOpen}
            targetActionName={{
              prefixId: 'BLUEPRINT',
              id: 'blueprintFileImport',
            }}
            onClose={() => setOpen(false)}
          />
        </div>
        <div className="w-66">
          <pre className="output"></pre>
        </div>
      </div>
    </div>
  );
};

const MessageDialogElementSample = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="input-line">
      <div className="item-group-100">
        <div className="w-33">
          <CaptionButton
            buttonType="basic"
            caption="確認する"
            name=""
            onClick={() => {
              setOpen(true);
            }}
          />
          <MessageDialog
            isOpen={isOpen}
            messageOption={{
              headerLabelId: {
                prefixId: 'DIALOG_TITLE',
                id: 'InformationDialog',
              },
              messageContents: (
                <div>
                  <span style={{ fontWeight: 'bold' }}>テスト小見出し</span>
                  <p>
                    実画面からの利用の際は
                    <span
                      style={{
                        fontStyle: 'italic',
                        fontWeight: 'bold',
                        paddingInline: '0.5rem',
                      }}
                    >
                      GetMessageWithIntl
                    </span>
                    を利用して辞書化して渡してくださいね
                  </p>
                </div>
              ),
            }}
            onClose={() => {
              setOpen(false);
            }}
          />
        </div>
        <div className="w-66">
          <pre className="output"></pre>
        </div>
      </div>
    </div>
  );
};

const ProcessingDialogSample = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="input-line">
      <div className="item-group-100">
        <div className="w-33">
          <CaptionButton
            buttonType="basic"
            caption="確認する"
            name=""
            onClick={() => {
              setOpen(true);
            }}
          />
          <ProcessingDialog
            isOpen={isOpen}
            processName={{
              prefixId: 'BLP_BLUEPRINT_CONFIRMATION',
              id: 'extractionProgress',
            }}
            onClose={() => setOpen(false)}
          />
        </div>
        <div className="w-66">
          <pre className="output"></pre>
        </div>
      </div>
    </div>
  );
};
