// TenantManagementService
export const FullMethodName_GetTenantManagementContent = 'mtechnavi.api.admin.TenantManagementService/GetTenantManagementContent';
export const FullMethodName_SaveTenantManagementWithRelatedItems = 'mtechnavi.api.admin.TenantManagementService/SaveTenantManagementWithRelatedItems';
export const FullMethodName_DeleteTenantManagementWithRelatedItems = 'mtechnavi.api.admin.TenantManagementService/DeleteTenantManagementWithRelatedItems';
export const FullMethodName_ReserveDeleteTenantManagement = 'mtechnavi.api.admin.TenantManagementService/ReserveDeleteTenantManagement';
export const FullMethodName_CancelReserveDeleteTenantManagement = 'mtechnavi.api.admin.TenantManagementService/CancelReserveDeleteTenantManagement';
export const FullMethodName_DisableTenantManagement = 'mtechnavi.api.admin.TenantManagementService/DisableTenantManagement';
export const FullMethodName_EnableTenantManagement = 'mtechnavi.api.admin.TenantManagementService/EnableTenantManagement';
export const FullMethodName_SendTenantManagementEmailTenantInvitation = 'mtechnavi.api.admin.TenantManagementService/SendEmailTenantInvitation';
export const FullMethodName_CreateTenantManagementLicense = 'mtechnavi.api.admin.TenantManagementService/CreateLicense';
export const FullMethodName_UpdateTenantManagementLicense = 'mtechnavi.api.admin.TenantManagementService/UpdateLicense';
export const FullMethodName_DeleteTenantManagementLicense = 'mtechnavi.api.admin.TenantManagementService/DeleteLicense';
export const FullMethodName_BulkWriteLicenses = 'mtechnavi.api.admin.TenantManagementService/BulkWriteLicenses';
export const FullMethodName_CreateTenantAdmin = 'mtechnavi.api.admin.TenantManagementService/CreateTenantAdmin';
export const FullMethodName_SendEmailAccountCreated = 'mtechnavi.api.admin.TenantManagementService/SendEmailAccountCreated';
export const FullMethodName_ManualTenantProvisioning = 'mtechnavi.api.admin.TenantManagementService/ManualTenantProvisioning';
// InformationService
export const FullMethodName_CreateInformation = 'mtechnavi.api.admin.InformationService/CreateInformation';
export const FullMethodName_UpdateInformation = 'mtechnavi.api.admin.InformationService/UpdateInformation';
export const FullMethodName_DeleteInformation = 'mtechnavi.api.admin.InformationService/DeleteInformation';
// AnalysisService
export const FullMethodName_ExportAnalysisOrders = 'mtechnavi.api.analysis.AnalysisService/ExportAnalysisOrders';
// AssetInventory
export const FullMethodName_GenerateWritableSignedUrl = 'mtechnavi.api.assetinventory.AssetInventory/GenerateWritableSignedUrl';
export const FullMethodName_GenerateReadableSignedUrl = 'mtechnavi.api.assetinventory.AssetInventory/GenerateReadableSignedUrl';
export const FullMethodName_CreateAsset = 'mtechnavi.api.assetinventory.AssetInventory/CreateAsset';
export const FullMethodName_GetAsset = 'mtechnavi.api.assetinventory.AssetInventory/GetAsset';
export const FullMethodName_UpdateAsset = 'mtechnavi.api.assetinventory.AssetInventory/UpdateAsset';
export const FullMethodName_DeleteAsset = 'mtechnavi.api.assetinventory.AssetInventory/DeleteAsset';
export const FullMethodName_CreateZippedAsset = 'mtechnavi.api.assetinventory.AssetInventory/CreateZippedAsset';
export const FullMethodName_CreateTemporaryAsset = 'mtechnavi.api.assetinventory.AssetInventory/CreateTemporaryAsset';
// ImageService
export const FullMethodName_CreateAssetImages = 'mtechnavi.api.assetinventory.ImageService/CreateAssetImages';
export const FullMethodName_CreateTemporaryImages = 'mtechnavi.api.assetinventory.ImageService/CreateTemporaryImages';
// BcpService
export const FullMethodName_CreateDisasterSystemNotification = 'mtechnavi.api.bcp.BcpService/CreateDisasterSystemNotification';
export const FullMethodName_UpdateDisasterSystemNotification = 'mtechnavi.api.bcp.BcpService/UpdateDisasterSystemNotification';
export const FullMethodName_CreateDisasterSurvey = 'mtechnavi.api.bcp.BcpService/CreateDisasterSurvey';
export const FullMethodName_UpdateDisasterSurvey = 'mtechnavi.api.bcp.BcpService/UpdateDisasterSurvey';
export const FullMethodName_DeleteDisasterSurvey = 'mtechnavi.api.bcp.BcpService/DeleteDisasterSurvey';
export const FullMethodName_CompleteDisasterSurvey = 'mtechnavi.api.bcp.BcpService/CompleteDisasterSurvey';
export const FullMethodName_UncompleteDisasterSurvey = 'mtechnavi.api.bcp.BcpService/UncompleteDisasterSurvey';
export const FullMethodName_AddSuveyBaseRequestIdInDisasterSurvey = 'mtechnavi.api.bcp.BcpService/AddSuveyBaseRequestIdInDisasterSurvey';
// TransactionUnitService
export const FullMethodName_CreateTransactionUnit = 'mtechnavi.api.blueprint.TransactionUnitService/CreateTransactionUnit';
export const FullMethodName_GetTransactionUnit = 'mtechnavi.api.blueprint.TransactionUnitService/GetTransactionUnit';
export const FullMethodName_UpdateTransactionUnit = 'mtechnavi.api.blueprint.TransactionUnitService/UpdateTransactionUnit';
export const FullMethodName_DeleteTransactionUnit = 'mtechnavi.api.blueprint.TransactionUnitService/DeleteTransactionUnit';
export const FullMethodName_ImportTransactionUnits = 'mtechnavi.api.blueprint.TransactionUnitService/ImportTransactionUnits';
export const FullMethodName_ExportTransactionUnits = 'mtechnavi.api.blueprint.TransactionUnitService/ExportTransactionUnits';
// BlueprintService
export const FullMethodName_SearchBlueprints = 'mtechnavi.api.blueprint.BlueprintService/SearchBlueprints';
export const FullMethodName_ExtractBlueprintImageSegment = 'mtechnavi.api.blueprint.BlueprintService/ExtractBlueprintImageSegment';
export const FullMethodName_CreateBlueprintAndBlueprintRevision = 'mtechnavi.api.blueprint.BlueprintService/CreateBlueprintAndBlueprintRevision';
export const FullMethodName_UpdateBlueprint = 'mtechnavi.api.blueprint.BlueprintService/UpdateBlueprint';
export const FullMethodName_DeleteBlueprintWithRelatedItems = 'mtechnavi.api.blueprint.BlueprintService/DeleteBlueprintWithRelatedItems';
export const FullMethodName_GetBlueprintContent = 'mtechnavi.api.blueprint.BlueprintService/GetBlueprintContent';
export const FullMethodName_CreateBlueprintRevision = 'mtechnavi.api.blueprint.BlueprintService/CreateBlueprintRevision';
export const FullMethodName_UpdateBlueprintRevision = 'mtechnavi.api.blueprint.BlueprintService/UpdateBlueprintRevision';
export const FullMethodName_DeleteBlueprintRevisionWithRelatedItems = 'mtechnavi.api.blueprint.BlueprintService/DeleteBlueprintRevisionWithRelatedItems';
export const FullMethodName_CreateBlueprintSegment = 'mtechnavi.api.blueprint.BlueprintService/CreateBlueprintSegment';
export const FullMethodName_UpdateBlueprintSegment = 'mtechnavi.api.blueprint.BlueprintService/UpdateBlueprintSegment';
export const FullMethodName_CreateBlueprintAttribute = 'mtechnavi.api.blueprint.BlueprintService/CreateBlueprintAttribute';
export const FullMethodName_UpdateBlueprintAttribute = 'mtechnavi.api.blueprint.BlueprintService/UpdateBlueprintAttribute';
export const FullMethodName_BulkWriteBlueprintAttributes = 'mtechnavi.api.blueprint.BlueprintService/BulkWriteBlueprintAttributes';
export const FullMethodName_DeleteBlueprintAttribute = 'mtechnavi.api.blueprint.BlueprintService/DeleteBlueprintAttribute';
export const FullMethodName_ExistsBlueprintNumber = 'mtechnavi.api.blueprint.BlueprintService/ExistsBlueprintNumber';
export const FullMethodName_AsyncImportBlueprintsWithBlueprintFiles = 'mtechnavi.api.blueprint.BlueprintService/AsyncImportBlueprintsWithBlueprintFiles';
export const FullMethodName_AsyncImportBlueprints = 'mtechnavi.api.blueprint.BlueprintService/AsyncImportBlueprints';
export const FullMethodName_ExportBlueprints = 'mtechnavi.api.blueprint.BlueprintService/ExportBlueprints';
export const FullMethodName_AsyncImportBlueprintAttributes = 'mtechnavi.api.blueprint.BlueprintService/AsyncImportBlueprintAttributes';
export const FullMethodName_ExportBlueprintAttributes = 'mtechnavi.api.blueprint.BlueprintService/ExportBlueprintAttributes';
export const FullMethodName_AsyncImportBlueprintLinkItems = 'mtechnavi.api.blueprint.BlueprintService/AsyncImportBlueprintLinkItems';
export const FullMethodName_ExportBlueprintLinkItems = 'mtechnavi.api.blueprint.BlueprintService/ExportBlueprintLinkItems';
// CompanyService
export const FullMethodName_GetCompany = 'mtechnavi.api.company.CompanyService/GetCompany';
export const FullMethodName_UpdateCompany = 'mtechnavi.api.company.CompanyService/UpdateCompany';
// ComponentUnitService
export const FullMethodName_CreateComponentUnit = 'mtechnavi.api.company.ComponentUnitService/CreateComponentUnit';
export const FullMethodName_GetComponentUnit = 'mtechnavi.api.company.ComponentUnitService/GetComponentUnit';
export const FullMethodName_UpdateComponentUnit = 'mtechnavi.api.company.ComponentUnitService/UpdateComponentUnit';
export const FullMethodName_DeleteComponentUnit = 'mtechnavi.api.company.ComponentUnitService/DeleteComponentUnit';
export const FullMethodName_ImportComponentUnits = 'mtechnavi.api.company.ComponentUnitService/ImportComponentUnits';
export const FullMethodName_ExportComponentUnits = 'mtechnavi.api.company.ComponentUnitService/ExportComponentUnits';
// BusinessUnitService
export const FullMethodName_GetBusinessUnit = 'mtechnavi.api.company.BusinessUnitService/GetBusinessUnit';
export const FullMethodName_UpdateBusinessUnit = 'mtechnavi.api.company.BusinessUnitService/UpdateBusinessUnit';
export const FullMethodName_DeleteBusinessUnit = 'mtechnavi.api.company.BusinessUnitService/DeleteBusinessUnit';
export const FullMethodName_ImportBusinessUnits = 'mtechnavi.api.company.BusinessUnitService/ImportBusinessUnits';
export const FullMethodName_ExportBusinessUnits = 'mtechnavi.api.company.BusinessUnitService/ExportBusinessUnits';
// StaffService
export const FullMethodName_GetStaff = 'mtechnavi.api.company.StaffService/GetStaff';
export const FullMethodName_ImportStaffs = 'mtechnavi.api.company.StaffService/ImportStaffs';
export const FullMethodName_ExportStaffs = 'mtechnavi.api.company.StaffService/ExportStaffs';
// PublicCompanyService
export const FullMethodName_PublicGetCompany = 'mtechnavi.api.company.PublicCompanyService/PublicGetCompany';
export const FullMethodName_CreateBusinessUnitRequest = 'mtechnavi.api.company.PublicCompanyService/CreateBusinessUnitRequest';
export const FullMethodName_GetBusinessUnitRequest = 'mtechnavi.api.company.PublicCompanyService/GetBusinessUnitRequest';
export const FullMethodName_UpdateBusinessUnitRequest = 'mtechnavi.api.company.PublicCompanyService/UpdateBusinessUnitRequest';
export const FullMethodName_DeleteBusinessUnitRequest = 'mtechnavi.api.company.PublicCompanyService/DeleteBusinessUnitRequest';
export const FullMethodName_SharetoBusinessUnitRequest = 'mtechnavi.api.company.PublicCompanyService/SharetoBusinessUnitRequest';
export const FullMethodName_CreateBusinessUnitRequestResult = 'mtechnavi.api.company.PublicCompanyService/CreateBusinessUnitRequestResult';
export const FullMethodName_GetBusinessUnitRequestResult = 'mtechnavi.api.company.PublicCompanyService/GetBusinessUnitRequestResult';
export const FullMethodName_UpdateBusinessUnitRequestResult = 'mtechnavi.api.company.PublicCompanyService/UpdateBusinessUnitRequestResult';
export const FullMethodName_DeleteBusinessUnitRequestResult = 'mtechnavi.api.company.PublicCompanyService/DeleteBusinessUnitRequestResult';
export const FullMethodName_SharetoBusinessUnitRequestResult = 'mtechnavi.api.company.PublicCompanyService/SharetoBusinessUnitRequestResult';
// BusinessUnitManagementService
export const FullMethodName_GetBusinessUnitManagement = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitManagement';
export const FullMethodName_CreateBusinessUnitManagement = 'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitManagement';
export const FullMethodName_UpdateBusinessUnitManagement = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitManagement';
export const FullMethodName_DeleteBusinessUnitManagement = 'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitManagement';
export const FullMethodName_ImportOldBusinessUnitManagements = 'mtechnavi.api.company.BusinessUnitManagementService/ImportOldBusinessUnitManagements';
export const FullMethodName_ExportOldBusinessUnitManagements = 'mtechnavi.api.company.BusinessUnitManagementService/ExportOldBusinessUnitManagements';
export const FullMethodName_GetBusinessUnitManagementContent = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitManagementContent';
export const FullMethodName_SendBusinessUnitManagementEmailTenantInvitation = 'mtechnavi.api.company.BusinessUnitManagementService/SendEmailTenantInvitation';
export const FullMethodName_CreateBusinessUnitProfile = 'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitProfile';
export const FullMethodName_GetBusinessUnitProfile = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitProfile';
export const FullMethodName_UpdateBusinessUnitProfile = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitProfile';
export const FullMethodName_DeleteBusinessUnitProfile = 'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitProfile';
export const FullMethodName_UpdateSystemNotificationUsersInBusinessUnitProfile = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateSystemNotificationUsersInBusinessUnitProfile';
export const FullMethodName_CreateBusinessUnitFinancial = 'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitFinancial';
export const FullMethodName_GetBusinessUnitFinancial = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitFinancial';
export const FullMethodName_UpdateBusinessUnitFinancial = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitFinancial';
export const FullMethodName_DeleteBusinessUnitFinancial = 'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitFinancial';
export const FullMethodName_CreateBusinessUnitBranch = 'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitBranch';
export const FullMethodName_GetBusinessUnitBranch = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitBranch';
export const FullMethodName_UpdateBusinessUnitBranch = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitBranch';
export const FullMethodName_DeleteBusinessUnitBranch = 'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitBranch';
export const FullMethodName_CreateBusinessUnitFacilities = 'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitFacilities';
export const FullMethodName_GetBusinessUnitFacilities = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitFacilities';
export const FullMethodName_UpdateBusinessUnitFacilities = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitFacilities';
export const FullMethodName_DeleteBusinessUnitFacilities = 'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitFacilities';
export const FullMethodName_CreateBusinessUnitCertificate = 'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitCertificate';
export const FullMethodName_GetBusinessUnitCertificate = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitCertificate';
export const FullMethodName_UpdateBusinessUnitCertificate = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitCertificate';
export const FullMethodName_DeleteBusinessUnitCertificate = 'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitCertificate';
export const FullMethodName_CreateBusinessUnitStrength = 'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitStrength';
export const FullMethodName_GetBusinessUnitStrength = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitStrength';
export const FullMethodName_UpdateBusinessUnitStrength = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitStrength';
export const FullMethodName_DeleteBusinessUnitStrength = 'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitStrength';
export const FullMethodName_CreateBusinessUnitSkill = 'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitSkill';
export const FullMethodName_GetBusinessUnitSkill = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitSkill';
export const FullMethodName_UpdateBusinessUnitSkill = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitSkill';
export const FullMethodName_DeleteBusinessUnitSkill = 'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitSkill';
export const FullMethodName_GetBusinessUnitSkillTree = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitSkillTree';
export const FullMethodName_CreateBusinessUnitContactHeader = 'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitContactHeader';
export const FullMethodName_GetBusinessUnitContactHeader = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitContactHeader';
export const FullMethodName_UpdateBusinessUnitContactHeader = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitContactHeader';
export const FullMethodName_DeleteBusinessUnitContactHeader = 'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitContactHeader';
export const FullMethodName_CreateBusinessUnitContact = 'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitContact';
export const FullMethodName_GetBusinessUnitContact = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitContact';
export const FullMethodName_UpdateBusinessUnitContact = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitContact';
export const FullMethodName_DeleteBusinessUnitContact = 'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitContact';
export const FullMethodName_ActivateMainContactInBusinessUnitContact = 'mtechnavi.api.company.BusinessUnitManagementService/ActivateMainContactInBusinessUnitContact';
export const FullMethodName_CreateBusinessUnitContactAttribute = 'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitContactAttribute';
export const FullMethodName_GetBusinessUnitContactAttribute = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitContactAttribute';
export const FullMethodName_UpdateBusinessUnitContactAttribute = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitContactAttribute';
export const FullMethodName_DeleteBusinessUnitContactAttribute = 'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitContactAttribute';
export const FullMethodName_CreateBusinessUnitDocument = 'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitDocument';
export const FullMethodName_GetBusinessUnitDocument = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitDocument';
export const FullMethodName_UpdateBusinessUnitDocument = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitDocument';
export const FullMethodName_DeleteBusinessUnitDocument = 'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitDocument';
export const FullMethodName_CreateBusinessUnitSnapshot = 'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitSnapshot';
export const FullMethodName_GetBusinessUnitSnapshot = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitSnapshot';
export const FullMethodName_UpdateBusinessUnitSnapshot = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitSnapshot';
export const FullMethodName_DeleteBusinessUnitSnapshot = 'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitSnapshot';
export const FullMethodName_CreateBusinessUnitChangeNotification = 'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitChangeNotification';
export const FullMethodName_GetBusinessUnitChangeNotification = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitChangeNotification';
export const FullMethodName_UpdateBusinessUnitChangeNotification = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitChangeNotification';
export const FullMethodName_DeleteBusinessUnitChangeNotification = 'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitChangeNotification';
export const FullMethodName_GetBusinessUnitContactContent = 'mtechnavi.api.company.BusinessUnitManagementService/GetBusinessUnitContactContent';
export const FullMethodName_SharedGetBusinessUnitProfile = 'mtechnavi.api.company.BusinessUnitManagementService/SharedGetBusinessUnitProfile';
export const FullMethodName_SharedGetBusinessUnitFinancial = 'mtechnavi.api.company.BusinessUnitManagementService/SharedGetBusinessUnitFinancial';
export const FullMethodName_SharedGetBusinessUnitBranch = 'mtechnavi.api.company.BusinessUnitManagementService/SharedGetBusinessUnitBranch';
export const FullMethodName_SharedGetBusinessUnitFacilities = 'mtechnavi.api.company.BusinessUnitManagementService/SharedGetBusinessUnitFacilities';
export const FullMethodName_SharedGetBusinessUnitCertificate = 'mtechnavi.api.company.BusinessUnitManagementService/SharedGetBusinessUnitCertificate';
export const FullMethodName_SharedGetBusinessUnitStrength = 'mtechnavi.api.company.BusinessUnitManagementService/SharedGetBusinessUnitStrength';
export const FullMethodName_SharedGetBusinessUnitSkill = 'mtechnavi.api.company.BusinessUnitManagementService/SharedGetBusinessUnitSkill';
export const FullMethodName_SharetoBusinessUnitContactHeader = 'mtechnavi.api.company.BusinessUnitManagementService/SharetoBusinessUnitContactHeader';
export const FullMethodName_SharetoBusinessUnitContact = 'mtechnavi.api.company.BusinessUnitManagementService/SharetoBusinessUnitContact';
export const FullMethodName_StartBusiness = 'mtechnavi.api.company.BusinessUnitManagementService/StartBusiness';
export const FullMethodName_StopBusiness = 'mtechnavi.api.company.BusinessUnitManagementService/StopBusiness';
export const FullMethodName_ConfirmBusinessUnitChangeNotification = 'mtechnavi.api.company.BusinessUnitManagementService/ConfirmBusinessUnitChangeNotification';
export const FullMethodName_ExportBusinessUnitManagements = 'mtechnavi.api.company.BusinessUnitManagementService/ExportBusinessUnitManagements';
export const FullMethodName_ImportBusinessUnitManagements = 'mtechnavi.api.company.BusinessUnitManagementService/ImportBusinessUnitManagements';
export const FullMethodName_ExportBusinessUnitScores = 'mtechnavi.api.company.BusinessUnitManagementService/ExportBusinessUnitScores';
export const FullMethodName_ImportBusinessUnitScores = 'mtechnavi.api.company.BusinessUnitManagementService/ImportBusinessUnitScores';
export const FullMethodName_ExportBusinessUnitManagementItemValues = 'mtechnavi.api.company.BusinessUnitManagementService/ExportBusinessUnitManagementItemValues';
export const FullMethodName_ImportBusinessUnitManagementItemValues = 'mtechnavi.api.company.BusinessUnitManagementService/ImportBusinessUnitManagementItemValues';
export const FullMethodName_CreateBusinessUnitRankReception = 'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitRankReception';
export const FullMethodName_UpdateBusinessUnitRankReception = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitRankReception';
export const FullMethodName_DeleteBusinessUnitRankReception = 'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitRankReception';
export const FullMethodName_ExportBusinessUnitRankRequests = 'mtechnavi.api.company.BusinessUnitManagementService/ExportBusinessUnitRankRequests';
export const FullMethodName_ImportBusinessUnitRankRequests = 'mtechnavi.api.company.BusinessUnitManagementService/ImportBusinessUnitRankRequests';
export const FullMethodName_ExportBusinessUnitContacts = 'mtechnavi.api.company.BusinessUnitManagementService/ExportBusinessUnitContacts';
export const FullMethodName_ImportBusinessUnitContacts = 'mtechnavi.api.company.BusinessUnitManagementService/ImportBusinessUnitContacts';
export const FullMethodName_ExportBusinessUnitFinancials = 'mtechnavi.api.company.BusinessUnitManagementService/ExportBusinessUnitFinancials';
export const FullMethodName_ImportBusinessUnitFinancials = 'mtechnavi.api.company.BusinessUnitManagementService/ImportBusinessUnitFinancials';
export const FullMethodName_ExportBusinessUnitCertificates = 'mtechnavi.api.company.BusinessUnitManagementService/ExportBusinessUnitCertificates';
export const FullMethodName_ImportBusinessUnitCertificates = 'mtechnavi.api.company.BusinessUnitManagementService/ImportBusinessUnitCertificates';
export const FullMethodName_ExportBusinessUnitFacilitiess = 'mtechnavi.api.company.BusinessUnitManagementService/ExportBusinessUnitFacilitiess';
export const FullMethodName_ImportBusinessUnitFacilitiess = 'mtechnavi.api.company.BusinessUnitManagementService/ImportBusinessUnitFacilitiess';
export const FullMethodName_ExportBusinessUnitSkills = 'mtechnavi.api.company.BusinessUnitManagementService/ExportBusinessUnitSkills';
export const FullMethodName_ImportBusinessUnitSkills = 'mtechnavi.api.company.BusinessUnitManagementService/ImportBusinessUnitSkills';
export const FullMethodName_ExportBusinessUnitRankReceptions = 'mtechnavi.api.company.BusinessUnitManagementService/ExportBusinessUnitRankReceptions';
export const FullMethodName_ImportBusinessUnitRankReceptions = 'mtechnavi.api.company.BusinessUnitManagementService/ImportBusinessUnitRankReceptions';
export const FullMethodName_CreateBusinessUnitPublicDocument = 'mtechnavi.api.company.BusinessUnitManagementService/CreateBusinessUnitPublicDocument';
export const FullMethodName_UpdateBusinessUnitPublicDocument = 'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitPublicDocument';
export const FullMethodName_DeleteBusinessUnitPublicDocument = 'mtechnavi.api.company.BusinessUnitManagementService/DeleteBusinessUnitPublicDocument';
// OrganizationService
export const FullMethodName_SaveOrganizations = 'mtechnavi.api.company.OrganizationService/SaveOrganizations';
export const FullMethodName_BulkWriteOrganizationRelations = 'mtechnavi.api.company.OrganizationService/BulkWriteOrganizationRelations';
export const FullMethodName_DeleteOrganizationWithRelatedItems = 'mtechnavi.api.company.OrganizationService/DeleteOrganizationWithRelatedItems';
export const FullMethodName_ImportOrganizationRelations = 'mtechnavi.api.company.OrganizationService/ImportOrganizationRelations';
export const FullMethodName_ExportOrganizationRelations = 'mtechnavi.api.company.OrganizationService/ExportOrganizationRelations';
// BillingCompanyService
export const FullMethodName_GetBillingCompany = 'mtechnavi.api.company.BillingCompanyService/GetBillingCompany';
export const FullMethodName_CreateBillingCompany = 'mtechnavi.api.company.BillingCompanyService/CreateBillingCompany';
export const FullMethodName_UpdateBillingCompany = 'mtechnavi.api.company.BillingCompanyService/UpdateBillingCompany';
export const FullMethodName_DeleteBillingCompany = 'mtechnavi.api.company.BillingCompanyService/DeleteBillingCompany';
// CompanyBatchService
export const FullMethodName_TaskLinkBusinessUnitManagement = 'mtechnavi.api.company.CompanyBatchService/TaskLinkBusinessUnitManagement';
export const FullMethodName_TaskCreateHistory = 'mtechnavi.api.company.CompanyBatchService/TaskCreateHistory';
export const FullMethodName_TaskNotification = 'mtechnavi.api.company.CompanyBatchService/TaskNotification';
export const FullMethodName_TaskCreateMainContact = 'mtechnavi.api.company.CompanyBatchService/TaskCreateMainContact';
export const FullMethodName_TaskSharetoBusinessUnitContact = 'mtechnavi.api.company.CompanyBatchService/TaskSharetoBusinessUnitContact';
export const FullMethodName_TaskCreateBusinessUnitProfile = 'mtechnavi.api.company.CompanyBatchService/TaskCreateBusinessUnitProfile';
export const FullMethodName_TaskSharetoImportedBusinessUnitContact = 'mtechnavi.api.company.CompanyBatchService/TaskSharetoImportedBusinessUnitContact';
// Locker
export const FullMethodName_AcquireLock = 'mtechnavi.api.dlock.Locker/AcquireLock';
export const FullMethodName_GetLockStatus = 'mtechnavi.api.dlock.Locker/GetLockStatus';
export const FullMethodName_ExtendLock = 'mtechnavi.api.dlock.Locker/ExtendLock';
export const FullMethodName_ReleaseLock = 'mtechnavi.api.dlock.Locker/ReleaseLock';
// EstimationService
export const FullMethodName_SharetoEstimationDiscard = 'mtechnavi.api.estimation.EstimationService/SharetoDiscard';
export const FullMethodName_SharetoEstimationOpened = 'mtechnavi.api.estimation.EstimationService/SharetoOpened';
export const FullMethodName_SharetoEstimationComplete = 'mtechnavi.api.estimation.EstimationService/SharetoComplete';
// EstimateSenderService
export const FullMethodName_DeleteEstimateRequestPlan = 'mtechnavi.api.estimation.EstimateSenderService/DeleteEstimateRequestPlan';
export const FullMethodName_ImportEstimateRequestPlans = 'mtechnavi.api.estimation.EstimateSenderService/ImportEstimateRequestPlans';
export const FullMethodName_ExportEstimateRequestPlans = 'mtechnavi.api.estimation.EstimateSenderService/ExportEstimateRequestPlans';
export const FullMethodName_CompleteEstimateRequestPlan = 'mtechnavi.api.estimation.EstimateSenderService/CompleteEstimateRequestPlan';
export const FullMethodName_UncompleteEstimateRequestPlan = 'mtechnavi.api.estimation.EstimateSenderService/UncompleteEstimateRequestPlan';
export const FullMethodName_SaveEstimateManagementWithRelatedItems = 'mtechnavi.api.estimation.EstimateSenderService/SaveEstimateManagementWithRelatedItems';
export const FullMethodName_DeleteEstimateManagementWithRelatedItems = 'mtechnavi.api.estimation.EstimateSenderService/DeleteEstimateManagementWithRelatedItems';
export const FullMethodName_UpdateEstimateManagement = 'mtechnavi.api.estimation.EstimateSenderService/UpdateEstimateManagement';
export const FullMethodName_ApprovalRequestEstimateManagement = 'mtechnavi.api.estimation.EstimateSenderService/ApprovalRequestEstimateManagement';
export const FullMethodName_RejectEstimateManagement = 'mtechnavi.api.estimation.EstimateSenderService/RejectEstimateManagement';
export const FullMethodName_ApproveEstimateManagement = 'mtechnavi.api.estimation.EstimateSenderService/ApproveEstimateManagement';
export const FullMethodName_DiscardEstimateManagement = 'mtechnavi.api.estimation.EstimateSenderService/DiscardEstimateManagement';
export const FullMethodName_CompleteEstimateManagement = 'mtechnavi.api.estimation.EstimateSenderService/CompleteEstimateManagement';
export const FullMethodName_UpdateSystemNotificationUsersInEstimateManagement = 'mtechnavi.api.estimation.EstimateSenderService/UpdateSystemNotificationUsersInEstimateManagement';
export const FullMethodName_SharetoEstimateRequestWithRelatedItems = 'mtechnavi.api.estimation.EstimateSenderService/SharetoEstimateRequestWithRelatedItems';
export const FullMethodName_SaveEstimateSelection = 'mtechnavi.api.estimation.EstimateSenderService/SaveEstimateSelection';
export const FullMethodName_ApprovalRequestEstimateSelection = 'mtechnavi.api.estimation.EstimateSenderService/ApprovalRequestEstimateSelection';
export const FullMethodName_RejectEstimateSelection = 'mtechnavi.api.estimation.EstimateSenderService/RejectEstimateSelection';
export const FullMethodName_ApproveEstimateSelection = 'mtechnavi.api.estimation.EstimateSenderService/ApproveEstimateSelection';
export const FullMethodName_SharetoEstimateSelection = 'mtechnavi.api.estimation.EstimateSenderService/SharetoEstimateSelection';
export const FullMethodName_ExportOrders = 'mtechnavi.api.estimation.EstimateSenderService/ExportOrders';
export const FullMethodName_CreateEstimateResultChangeRequest = 'mtechnavi.api.estimation.EstimateSenderService/CreateEstimateResultChangeRequest';
export const FullMethodName_SharetoEstimateResultChangeRequest = 'mtechnavi.api.estimation.EstimateSenderService/SharetoEstimateResultChangeRequest';
// EstimateReceiverService
export const FullMethodName_UpdateEstimateResult = 'mtechnavi.api.estimation.EstimateReceiverService/UpdateEstimateResult';
export const FullMethodName_CompleteEstimateResult = 'mtechnavi.api.estimation.EstimateReceiverService/CompleteEstimateResult';
export const FullMethodName_UpdateSystemNotificationUsersInEstimateResult = 'mtechnavi.api.estimation.EstimateReceiverService/UpdateSystemNotificationUsersInEstimateResult';
export const FullMethodName_UpdateRemarksInEstimateResult = 'mtechnavi.api.estimation.EstimateReceiverService/UpdateRemarksInEstimateResult';
export const FullMethodName_SharetoEstimateResultWithRelatedItems = 'mtechnavi.api.estimation.EstimateReceiverService/SharetoEstimateResultWithRelatedItems';
export const FullMethodName_UpdateEstimateResultDetail = 'mtechnavi.api.estimation.EstimateReceiverService/UpdateEstimateResultDetail';
export const FullMethodName_OpenEstimateRequestUnit = 'mtechnavi.api.estimation.EstimateReceiverService/OpenEstimateRequestUnit';
// BaseFormService
export const FullMethodName_CreateBaseForm = 'mtechnavi.api.form.BaseFormService/CreateBaseForm';
export const FullMethodName_CreateOrUpdateBaseFormWithRelatedItems = 'mtechnavi.api.form.BaseFormService/CreateOrUpdateBaseFormWithRelatedItems';
export const FullMethodName_DeleteBaseFormWithRelatedItems = 'mtechnavi.api.form.BaseFormService/DeleteBaseFormWithRelatedItems';
// FormService
export const FullMethodName_GenerateForm = 'mtechnavi.api.form.FormService/GenerateForm';
export const FullMethodName_DeleteFormWithRelatedItems = 'mtechnavi.api.form.FormService/DeleteFormWithRelatedItems';
export const FullMethodName_SharetoFormWithRelatedItems = 'mtechnavi.api.form.FormService/SharetoFormWithRelatedItems';
export const FullMethodName_BulkWriteFormValues = 'mtechnavi.api.form.FormService/BulkWriteFormValues';
export const FullMethodName_SharetoFormValues = 'mtechnavi.api.form.FormService/SharetoFormValues';
export const FullMethodName_ImportFormValues = 'mtechnavi.api.form.FormService/ImportFormValues';
export const FullMethodName_BulkDeleteFormValues = 'mtechnavi.api.form.FormService/BulkDeleteFormValues';
// SampleWorkFormService
export const FullMethodName_CreateSampleWorkForm = 'mtechnavi.api.form.SampleWorkFormService/CreateSampleWorkForm';
export const FullMethodName_UpdateSampleWorkForm = 'mtechnavi.api.form.SampleWorkFormService/UpdateSampleWorkForm';
export const FullMethodName_DeleteSampleWorkForm = 'mtechnavi.api.form.SampleWorkFormService/DeleteSampleWorkForm';
export const FullMethodName_ImportSampleWorkForms = 'mtechnavi.api.form.SampleWorkFormService/ImportSampleWorkForms';
export const FullMethodName_ExportSampleWorkForms = 'mtechnavi.api.form.SampleWorkFormService/ExportSampleWorkForms';
// ForumService
export const FullMethodName_CheckUsableForum = 'mtechnavi.api.forum.ForumService/CheckUsableForum';
export const FullMethodName_CreateThread = 'mtechnavi.api.forum.ForumService/CreateThread';
export const FullMethodName_GetThread = 'mtechnavi.api.forum.ForumService/GetThread';
export const FullMethodName_UpdateThread = 'mtechnavi.api.forum.ForumService/UpdateThread';
export const FullMethodName_DeleteThread = 'mtechnavi.api.forum.ForumService/DeleteThread';
export const FullMethodName_CreateComment = 'mtechnavi.api.forum.ForumService/CreateComment';
export const FullMethodName_GetComment = 'mtechnavi.api.forum.ForumService/GetComment';
export const FullMethodName_UpdateComment = 'mtechnavi.api.forum.ForumService/UpdateComment';
export const FullMethodName_DeleteComment = 'mtechnavi.api.forum.ForumService/DeleteComment';
export const FullMethodName_SetMarker = 'mtechnavi.api.forum.ForumService/SetMarker';
export const FullMethodName_UnsetMarker = 'mtechnavi.api.forum.ForumService/UnsetMarker';
// Identity
export const FullMethodName_GetRole = 'mtechnavi.api.idp.Identity/GetRole';
// AdminLicenseService
export const FullMethodName_CreateLicenseCatalog = 'mtechnavi.api.license.AdminLicenseService/CreateLicenseCatalog';
export const FullMethodName_UpdateLicenseCatalog = 'mtechnavi.api.license.AdminLicenseService/UpdateLicenseCatalog';
export const FullMethodName_DeleteLicenseCatalog = 'mtechnavi.api.license.AdminLicenseService/DeleteLicenseCatalog';
export const FullMethodName_CreateAdminLicenseLicense = 'mtechnavi.api.license.AdminLicenseService/CreateLicense';
export const FullMethodName_UpdateAdminLicenseLicense = 'mtechnavi.api.license.AdminLicenseService/UpdateLicense';
export const FullMethodName_DeleteAdminLicenseLicense = 'mtechnavi.api.license.AdminLicenseService/DeleteLicense';
// LicenseService
export const FullMethodName_VerifyLicense = 'mtechnavi.api.license.LicenseService/VerifyLicense';
// ProgramOptionService
export const FullMethodName_CreateProgramOption = 'mtechnavi.api.programoption.ProgramOptionService/CreateProgramOption';
export const FullMethodName_GetProgramOption = 'mtechnavi.api.programoption.ProgramOptionService/GetProgramOption';
export const FullMethodName_UpdateProgramOption = 'mtechnavi.api.programoption.ProgramOptionService/UpdateProgramOption';
export const FullMethodName_DeleteProgramOption = 'mtechnavi.api.programoption.ProgramOptionService/DeleteProgramOption';
export const FullMethodName_ImportProgramOptions = 'mtechnavi.api.programoption.ProgramOptionService/ImportProgramOptions';
export const FullMethodName_ExportProgramOptions = 'mtechnavi.api.programoption.ProgramOptionService/ExportProgramOptions';
export const FullMethodName_MaintenanceProgramOptionFormats = 'mtechnavi.api.programoption.ProgramOptionService/MaintenanceProgramOptionFormats';
export const FullMethodName_ProvisionProgramOptions = 'mtechnavi.api.programoption.ProgramOptionService/ProvisionProgramOptions';
// AttributeService
export const FullMethodName_SaveAttribute = 'mtechnavi.api.programoption.AttributeService/SaveAttribute';
export const FullMethodName_DeleteAttribute = 'mtechnavi.api.programoption.AttributeService/DeleteAttribute';
// SurveyService
export const FullMethodName_SharetoSurveyDiscard = 'mtechnavi.api.survey.SurveyService/SharetoDiscard';
export const FullMethodName_SharetoSurveyComplete = 'mtechnavi.api.survey.SurveyService/SharetoComplete';
export const FullMethodName_SharetoSurveyOpened = 'mtechnavi.api.survey.SurveyService/SharetoOpened';
// SurveySenderService
export const FullMethodName_CreateSurveyRequestCatalog = 'mtechnavi.api.survey.SurveySenderService/CreateSurveyRequestCatalog';
export const FullMethodName_GetSurveyRequestCatalog = 'mtechnavi.api.survey.SurveySenderService/GetSurveyRequestCatalog';
export const FullMethodName_UpdateSurveyRequestCatalog = 'mtechnavi.api.survey.SurveySenderService/UpdateSurveyRequestCatalog';
export const FullMethodName_DeleteSurveyRequestCatalog = 'mtechnavi.api.survey.SurveySenderService/DeleteSurveyRequestCatalog';
export const FullMethodName_CreateSurveyBaseRequest = 'mtechnavi.api.survey.SurveySenderService/CreateSurveyBaseRequest';
export const FullMethodName_UpdateSurveyBaseRequest = 'mtechnavi.api.survey.SurveySenderService/UpdateSurveyBaseRequest';
export const FullMethodName_DeleteSurveyBaseRequest = 'mtechnavi.api.survey.SurveySenderService/DeleteSurveyBaseRequest';
export const FullMethodName_ApprovalRequestSurveyBaseRequest = 'mtechnavi.api.survey.SurveySenderService/ApprovalRequestSurveyBaseRequest';
export const FullMethodName_RejectSurveyBaseRequest = 'mtechnavi.api.survey.SurveySenderService/RejectSurveyBaseRequest';
export const FullMethodName_ApproveSurveyBaseRequest = 'mtechnavi.api.survey.SurveySenderService/ApproveSurveyBaseRequest';
export const FullMethodName_GetSurveyRequest = 'mtechnavi.api.survey.SurveySenderService/GetSurveyRequest';
export const FullMethodName_UpdateSurveyRequest = 'mtechnavi.api.survey.SurveySenderService/UpdateSurveyRequest';
export const FullMethodName_UpdateSystemNotificationUsersInSurveyRequest = 'mtechnavi.api.survey.SurveySenderService/UpdateSystemNotificationUsersInSurveyRequest';
export const FullMethodName_UpdateFlagInSurveyRequest = 'mtechnavi.api.survey.SurveySenderService/UpdateFlagInSurveyRequest';
export const FullMethodName_SharetoSurveyRequest = 'mtechnavi.api.survey.SurveySenderService/SharetoSurveyRequest';
export const FullMethodName_DiscardSurveyRequest = 'mtechnavi.api.survey.SurveySenderService/DiscardSurveyRequest';
export const FullMethodName_CompleteSurveyRequest = 'mtechnavi.api.survey.SurveySenderService/CompleteSurveyRequest';
export const FullMethodName_CreateSurveyReplyDueDateChangeResult = 'mtechnavi.api.survey.SurveySenderService/CreateSurveyReplyDueDateChangeResult';
export const FullMethodName_GetSurveyReplyDueDateChangeResult = 'mtechnavi.api.survey.SurveySenderService/GetSurveyReplyDueDateChangeResult';
export const FullMethodName_SharetoSurveyReplyDueDateChangeResult = 'mtechnavi.api.survey.SurveySenderService/SharetoSurveyReplyDueDateChangeResult';
export const FullMethodName_CreateSurveyResultChangeRequest = 'mtechnavi.api.survey.SurveySenderService/CreateSurveyResultChangeRequest';
export const FullMethodName_GetSurveyResultChangeRequest = 'mtechnavi.api.survey.SurveySenderService/GetSurveyResultChangeRequest';
export const FullMethodName_SharetoSurveyResultChangeRequest = 'mtechnavi.api.survey.SurveySenderService/SharetoSurveyResultChangeRequest';
export const FullMethodName_CreateSurveyReminder = 'mtechnavi.api.survey.SurveySenderService/CreateSurveyReminder';
export const FullMethodName_GetSurveyReminder = 'mtechnavi.api.survey.SurveySenderService/GetSurveyReminder';
export const FullMethodName_SharetoSurveyReminder = 'mtechnavi.api.survey.SurveySenderService/SharetoSurveyReminder';
export const FullMethodName_ExportSurveyRequests = 'mtechnavi.api.survey.SurveySenderService/ExportSurveyRequests';
export const FullMethodName_SharedGetSurveyReplyDueDateChangeRequest = 'mtechnavi.api.survey.SurveySenderService/SharedGetSurveyReplyDueDateChangeRequest';
export const FullMethodName_SharedGetSurveyResult = 'mtechnavi.api.survey.SurveySenderService/SharedGetSurveyResult';
export const FullMethodName_SharedGetSurveyResultChangeRequest = 'mtechnavi.api.survey.SurveySenderService/SharedGetSurveyResultChangeRequest';
export const FullMethodName_ExpandedGetSurveyRequestContent = 'mtechnavi.api.survey.SurveySenderService/ExpandedGetSurveyRequestContent';
export const FullMethodName_GetSurveyBaseRequestContent = 'mtechnavi.api.survey.SurveySenderService/GetSurveyBaseRequestContent';
export const FullMethodName_GetSurveyRequestContent = 'mtechnavi.api.survey.SurveySenderService/GetSurveyRequestContent';
export const FullMethodName_ExportedSurveyResultFile = 'mtechnavi.api.survey.SurveySenderService/ExportedSurveyResultFile';
export const FullMethodName_CreateSurveyRequestWorkForm = 'mtechnavi.api.survey.SurveySenderService/CreateSurveyRequestWorkForm';
export const FullMethodName_UpdateSurveyRequestWorkForm = 'mtechnavi.api.survey.SurveySenderService/UpdateSurveyRequestWorkForm';
export const FullMethodName_DeleteSurveyRequestWorkForm = 'mtechnavi.api.survey.SurveySenderService/DeleteSurveyRequestWorkForm';
export const FullMethodName_CreateSurveyResultWorkForm = 'mtechnavi.api.survey.SurveySenderService/CreateSurveyResultWorkForm';
export const FullMethodName_UpdateSurveyResultWorkForm = 'mtechnavi.api.survey.SurveySenderService/UpdateSurveyResultWorkForm';
export const FullMethodName_DeleteSurveyResultWorkForm = 'mtechnavi.api.survey.SurveySenderService/DeleteSurveyResultWorkForm';
export const FullMethodName_ImportProxySurveyResultWorkForms = 'mtechnavi.api.survey.SurveySenderService/ImportProxySurveyResultWorkForms';
export const FullMethodName_ExportProxySurveyResultWorkForms = 'mtechnavi.api.survey.SurveySenderService/ExportProxySurveyResultWorkForms';
// SurveyReceiverService
export const FullMethodName_CreateSurveyReplyDueDateChangeRequest = 'mtechnavi.api.survey.SurveyReceiverService/CreateSurveyReplyDueDateChangeRequest';
export const FullMethodName_GetSurveyReplyDueDateChangeRequest = 'mtechnavi.api.survey.SurveyReceiverService/GetSurveyReplyDueDateChangeRequest';
export const FullMethodName_SharetoSurveyReplyDueDateChangeRequest = 'mtechnavi.api.survey.SurveyReceiverService/SharetoSurveyReplyDueDateChangeRequest';
export const FullMethodName_GetSurveyReception = 'mtechnavi.api.survey.SurveyReceiverService/GetSurveyReception';
export const FullMethodName_UpdateSurveyReception = 'mtechnavi.api.survey.SurveyReceiverService/UpdateSurveyReception';
export const FullMethodName_UpdateSystemNotificationUsersInSurveyReception = 'mtechnavi.api.survey.SurveyReceiverService/UpdateSystemNotificationUsersInSurveyReception';
export const FullMethodName_CreateSurveyResult = 'mtechnavi.api.survey.SurveyReceiverService/CreateSurveyResult';
export const FullMethodName_SharetoSurveyResult = 'mtechnavi.api.survey.SurveyReceiverService/SharetoSurveyResult';
export const FullMethodName_SharedGetSurveyRequest = 'mtechnavi.api.survey.SurveyReceiverService/SharedGetSurveyRequest';
export const FullMethodName_SharedGetSurveyReplyDueDateChangeResult = 'mtechnavi.api.survey.SurveyReceiverService/SharedGetSurveyReplyDueDateChangeResult';
export const FullMethodName_SharedGetSurveyReminder = 'mtechnavi.api.survey.SurveyReceiverService/SharedGetSurveyReminder';
export const FullMethodName_GetSurveyReceptionContent = 'mtechnavi.api.survey.SurveyReceiverService/GetSurveyReceptionContent';
export const FullMethodName_OpenSurveyRequest = 'mtechnavi.api.survey.SurveyReceiverService/OpenSurveyRequest';
export const FullMethodName_ImportSurveyResultWorkForms = 'mtechnavi.api.survey.SurveyReceiverService/ImportSurveyResultWorkForms';
export const FullMethodName_ExportSurveyResultWorkForms = 'mtechnavi.api.survey.SurveyReceiverService/ExportSurveyResultWorkForms';
export const FullMethodName_CreateResultFormShareManagement = 'mtechnavi.api.survey.SurveyReceiverService/CreateResultFormShareManagement';
export const FullMethodName_UpdateResultFormShareManagement = 'mtechnavi.api.survey.SurveyReceiverService/UpdateResultFormShareManagement';
// SurveyIOService
export const FullMethodName_ExportSurveyResultFiles = 'mtechnavi.api.survey.SurveyIOService/ExportSurveyResultFiles';
// TenantAdminService
export const FullMethodName_CreateUserAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/CreateUserAttribute';
export const FullMethodName_CreateUserAndUserAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/CreateUserAndUserAttribute';
export const FullMethodName_GetUserAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/GetUserAttribute';
export const FullMethodName_UpdateUserAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/UpdateUserAttribute';
export const FullMethodName_UpdateUserAndUserAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/UpdateUserAndUserAttribute';
export const FullMethodName_DeleteUserAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/DeleteUserAttribute';
export const FullMethodName_DeleteUserAndUserAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/DeleteUserAndUserAttribute';
export const FullMethodName_DisableUser = 'mtechnavi.api.tenantadmin.TenantAdminService/DisableUser';
export const FullMethodName_EnableUser = 'mtechnavi.api.tenantadmin.TenantAdminService/EnableUser';
export const FullMethodName_ImportUserAttributes = 'mtechnavi.api.tenantadmin.TenantAdminService/ImportUserAttributes';
export const FullMethodName_ExportUserAttributes = 'mtechnavi.api.tenantadmin.TenantAdminService/ExportUserAttributes';
export const FullMethodName_SendEmailUserCreated = 'mtechnavi.api.tenantadmin.TenantAdminService/SendEmailUserCreated';
export const FullMethodName_CreateUserGroupAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/CreateUserGroupAttribute';
export const FullMethodName_CreateUserGroupAndUserGroupAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/CreateUserGroupAndUserGroupAttribute';
export const FullMethodName_GetUserGroupAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/GetUserGroupAttribute';
export const FullMethodName_UpdateUserGroupAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/UpdateUserGroupAttribute';
export const FullMethodName_UpdateUserGroupAndUserGroupAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/UpdateUserGroupAndUserGroupAttribute';
export const FullMethodName_DeleteUserGroupAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/DeleteUserGroupAttribute';
export const FullMethodName_DeleteUserAndUserGroupAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/DeleteUserAndUserGroupAttribute';
export const FullMethodName_ImportUserBelongsUserGroups = 'mtechnavi.api.tenantadmin.TenantAdminService/ImportUserBelongsUserGroups';
export const FullMethodName_ExportUserBelongsUserGroups = 'mtechnavi.api.tenantadmin.TenantAdminService/ExportUserBelongsUserGroups';
export const FullMethodName_ExportAttachedRoles = 'mtechnavi.api.tenantadmin.TenantAdminService/ExportAttachedRoles';
export const FullMethodName_ExportAllowedMenuItems = 'mtechnavi.api.tenantadmin.TenantAdminService/ExportAllowedMenuItems';
export const FullMethodName_CreateRoleAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/CreateRoleAttribute';
export const FullMethodName_GetRoleAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/GetRoleAttribute';
export const FullMethodName_UpdateRoleAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/UpdateRoleAttribute';
export const FullMethodName_DeleteRoleAttribute = 'mtechnavi.api.tenantadmin.TenantAdminService/DeleteRoleAttribute';
// TenantProvisionService
export const FullMethodName_GetTenantRequest = 'mtechnavi.api.tenantprovision.TenantProvisionService/GetTenantRequest';
export const FullMethodName_VerifyTenantRequest = 'mtechnavi.api.tenantprovision.TenantProvisionService/VerifyTenantRequest';
export const FullMethodName_ApplyTenantRequest = 'mtechnavi.api.tenantprovision.TenantProvisionService/ApplyTenantRequest';
// UiController
export const FullMethodName_CreatePresetMenu = 'mtechnavi.api.uicontroller.UiController/CreatePresetMenu';
export const FullMethodName_GetPresetMenu = 'mtechnavi.api.uicontroller.UiController/GetPresetMenu';
export const FullMethodName_UpdatePresetMenu = 'mtechnavi.api.uicontroller.UiController/UpdatePresetMenu';
export const FullMethodName_DeletePresetMenu = 'mtechnavi.api.uicontroller.UiController/DeletePresetMenu';
export const FullMethodName_CreatePreset = 'mtechnavi.api.uicontroller.UiController/CreatePreset';
export const FullMethodName_GetPreset = 'mtechnavi.api.uicontroller.UiController/GetPreset';
export const FullMethodName_UpdatePreset = 'mtechnavi.api.uicontroller.UiController/UpdatePreset';
export const FullMethodName_DeletePreset = 'mtechnavi.api.uicontroller.UiController/DeletePreset';
export const FullMethodName_SaveUserSetting = 'mtechnavi.api.uicontroller.UiController/SaveUserSetting';
export const FullMethodName_DeleteUserSetting = 'mtechnavi.api.uicontroller.UiController/DeleteUserSetting';
export const FullMethodName_GetTaskListSummary = 'mtechnavi.api.uicontroller.UiController/GetTaskListSummary';
// WorkTaskService
export const FullMethodName_CreateWorkTaskCatalog = 'mtechnavi.api.worktask.WorkTaskService/CreateWorkTaskCatalog';
export const FullMethodName_GetWorkTaskCatalog = 'mtechnavi.api.worktask.WorkTaskService/GetWorkTaskCatalog';
export const FullMethodName_UpdateWorkTaskCatalog = 'mtechnavi.api.worktask.WorkTaskService/UpdateWorkTaskCatalog';
export const FullMethodName_DeleteWorkTaskCatalog = 'mtechnavi.api.worktask.WorkTaskService/DeleteWorkTaskCatalog';
export const FullMethodName_CreateWorkTask = 'mtechnavi.api.worktask.WorkTaskService/CreateWorkTask';
export const FullMethodName_GetWorkTask = 'mtechnavi.api.worktask.WorkTaskService/GetWorkTask';
export const FullMethodName_UpdateWorkTask = 'mtechnavi.api.worktask.WorkTaskService/UpdateWorkTask';
export const FullMethodName_DeleteWorkTask = 'mtechnavi.api.worktask.WorkTaskService/DeleteWorkTask';
export const FullMethodName_ExportWorkTasks = 'mtechnavi.api.worktask.WorkTaskService/ExportWorkTasks';
export const FullMethodName_ApprovalRequestWorkTask = 'mtechnavi.api.worktask.WorkTaskService/ApprovalRequestWorkTask';
export const FullMethodName_ApproveWorkTask = 'mtechnavi.api.worktask.WorkTaskService/ApproveWorkTask';
export const FullMethodName_RejectWorkTask = 'mtechnavi.api.worktask.WorkTaskService/RejectWorkTask';
export const FullMethodName_ActivateWorkTask = 'mtechnavi.api.worktask.WorkTaskService/ActivateWorkTask';
export const FullMethodName_DiscardWorkTask = 'mtechnavi.api.worktask.WorkTaskService/DiscardWorkTask';
export const FullMethodName_CompleteWorkTask = 'mtechnavi.api.worktask.WorkTaskService/CompleteWorkTask';
export const FullMethodName_UncompleteWorkTask = 'mtechnavi.api.worktask.WorkTaskService/UncompleteWorkTask';
export const FullMethodName_CompleteWorkTaskTicket = 'mtechnavi.api.worktask.WorkTaskService/CompleteWorkTaskTicket';
export const FullMethodName_UncompleteWorkTaskTicket = 'mtechnavi.api.worktask.WorkTaskService/UncompleteWorkTaskTicket';
export const FullMethodName_CreateWorkTaskRelation = 'mtechnavi.api.worktask.WorkTaskService/CreateWorkTaskRelation';
export const FullMethodName_DeleteWorkTaskRelation = 'mtechnavi.api.worktask.WorkTaskService/DeleteWorkTaskRelation';
export const FullMethodName_GetWorkTaskRelationContent = 'mtechnavi.api.worktask.WorkTaskService/GetWorkTaskRelationContent';