import { useIntl } from 'react-intl';
import { useRef, useState } from 'react';
import {
  getMessageId,
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { Textbox } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';
import { includeInputValidateError, RequireInput } from '~/shared/utils';
import { TextBoxValidateOption } from '../../parts/Textbox/TextBox';

export interface MessageOption {
  headerLabel: MessageProps;
  messageLabel?: MessageProps;
  captionLabel?: MessageProps;
  decisionLabel?: MessageProps;
}

export interface InputOption {
  initialText?: string;
  validateOption?: TextBoxValidateOption;
}

export interface TextInputDialogProps {
  isOpen: boolean;
  messageOption: MessageOption;
  inputOption: InputOption;
  onDecision: (text: string) => void;
  onCancel: () => void;
}

export function TextInputDialog(props: TextInputDialogProps) {
  const intl = useIntl();
  const textRef = useRef<HTMLInputElement>(null);

  const { isOpen, messageOption, inputOption } = props;
  const [text, setText] = useState<string>(inputOption.initialText ?? '');

  const captionLabelId = getMessageId(
    messageOption.captionLabel ?? { id: 'comment' }
  );

  const handleDecision = () => {
    const el = document.querySelector('.text-input-dialog');
    const items: RequireInput[] = [];
    if (inputOption.validateOption?.required) {
      items.push({ ref: textRef, value: text });
    }
    if (includeInputValidateError(el, intl, items)) {
      return;
    }
    props.onDecision(text);
  };

  const view = (
    <div className="text-input-dialog">
      <div className="description-area">
        <p className="text-box">
          <span className="text">
            {messageOption.messageLabel &&
              GetMessageWithIntl(intl, messageOption.messageLabel)}
          </span>
        </p>
      </div>
      <div className="comment-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100" ref={textRef}>
              <Textbox
                type="text"
                name="text"
                labelId={captionLabelId}
                value={text}
                validateOption={inputOption.validateOption}
                columns={['text']}
                className="w-100 mh-middle"
                onChangeState={setText}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={() => props.onCancel()}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(
            intl,
            messageOption.decisionLabel ?? { id: 'send' }
          )}
          onClick={handleDecision}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabel,
    messageLabelId: messageOption.messageLabel ?? {},
    elements: view,
  };

  return (
    <>
      <ModalDialogComponent {...openModalProps} />
    </>
  );
}
