import { deleteBusinessUnitManagementList } from './deleteBusinessUnitManagementList';
import { sendTenantInvitationRequest } from './sendTenantInvitationRequest';
import { saveOcpFinancial } from './saveOcpFinancial';
import { saveOcpFacilities } from './saveOcpFacilities';
import { saveOcpCertificate } from './saveOcpCertificate';
import { saveOcpStrength } from './saveOcpStrength';
import { saveOcpProfile } from './saveOcpProfile';
import { saveOcpBranch } from './saveOcpBranch';
import { execBusinessUnitContactHeader } from './execBusinessUnitContactHeader';
import { execBusinessUnitContact } from './execBusinessUnitContact';
import { saveBusinessUnitContactAttribute } from './saveBusinessUnitContactAttribute';
import { execConfirmBusinessUnitChangeNotification } from './execConfirmBusinessUnitChangeNotification';
import { execBusinessUnitDocument } from './execBusinessUnitDocument';
import { deleteBusinessUnitDocument } from './deleteBusinessUnitDocument';
import { execStartBusiness } from './execStartBusiness';
import { execStopBusiness } from './execStopBusiness';
import { saveOcpSkill } from './saveOcpSkill';
import { updateOcpProfileSystemNotificationSetting } from './updateOcpProfileSystemNotificationSetting';
import { saveBusinessUnitRankReception } from './execSaveBusinessUnitRankReception';
import { deleteBusinessUnitRankReception } from './execDeleteBusinessUnitRankReception';
import { saveOrganizations } from './saveOrganizations';
import { deleteOrganizations } from './deleteOrganizations';
import { saveOrganizationUsers } from './saveOrganizationUsers';
import { deleteBusinessUnitPublicDocument } from './deleteBusinessUnitPublicDocument';
import { saveBusinessUnitPublicDocument } from './saveBusinessUnitPublicDocument';
import { deleteBusinessUnitContact } from './deleteBusinessUnitContact';
import { activateMainContactInBusinessUnitContact } from './activateMainContactInBusinessUnitContact';
import { execUpdateBusinessUnitManagement } from './execUpdateBusinessUnitManagement';

// actionName と function のマッピング
export const companyCallMap = {
  saveOrganizations: saveOrganizations,
  deleteOrganizations: deleteOrganizations,
  saveOrganizationUsers: saveOrganizationUsers,
  updateOcpProfileSystemNotificationSetting:
    updateOcpProfileSystemNotificationSetting,
  saveOcpFinancial: saveOcpFinancial,
  saveOcpFacilities: saveOcpFacilities,
  saveOcpCertificate: saveOcpCertificate,
  saveOcpStrength: saveOcpStrength,
  deleteBusinessUnitManagementList: deleteBusinessUnitManagementList,
  sendTenantInvitation: sendTenantInvitationRequest,
  saveOcpProfile: saveOcpProfile,
  saveOcpBranch: saveOcpBranch,
  execBusinessUnitContactHeader: execBusinessUnitContactHeader,
  execBusinessUnitContact: execBusinessUnitContact,
  saveBusinessUnitContactAttribute: saveBusinessUnitContactAttribute,
  execConfirmBusinessUnitChangeNotification:
    execConfirmBusinessUnitChangeNotification,
  execUpdateBusinessUnitManagement: execUpdateBusinessUnitManagement,
  execBusinessUnitDocument: execBusinessUnitDocument,
  deleteBusinessUnitDocument: deleteBusinessUnitDocument,
  execStartBusiness: execStartBusiness,
  execStopBusiness: execStopBusiness,
  saveOcpSkill: saveOcpSkill,
  saveBusinessUnitRankReception: saveBusinessUnitRankReception,
  deleteBusinessUnitRankReception: deleteBusinessUnitRankReception,
  deleteBusinessUnitPublicDocument: deleteBusinessUnitPublicDocument,
  saveBusinessUnitPublicDocument: saveBusinessUnitPublicDocument,
  deleteBusinessUnitContact: deleteBusinessUnitContact,
  activateMainContactInBusinessUnitContact:
    activateMainContactInBusinessUnitContact,
};
