import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  BlueprintRevisionDialog,
  BlueprintRevisionDialogProps,
} from '~/shared/components/ui/Dialog/BlueprintRevisionDialog';

export const UISampleBlueprintRevisionDialogs = () => {
  const [result, setResult] = useState('');
  const [isShow, setShow] = useState(false);
  const [inputOption, setInputOption] = useState<
    BlueprintRevisionDialogProps['inputOption']
  >({ mode: 'add' });

  return (
    <div className="categories">
      <h3 id="dialog">改訂登録ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <h4>登録</h4>
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setInputOption({
                  mode: 'add',
                });
                setResult('');
                setShow(true);
              }}
            />
            <h4>編集</h4>
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setInputOption({
                  mode: 'edit',
                  comment: 'コメント',
                  revisionDate: new Date('2024/03/09'),
                });
                setResult('');
                setShow(true);
              }}
            />
            <BlueprintRevisionDialog
              key={isShow ? 1 : 0}
              isOpen={isShow}
              inputOption={inputOption}
              messageOption={{
                headerLabel: {
                  prefixId: 'DIALOG_TITLE',
                  id: 'blueprintRevisionRegistration',
                },
              }}
              onDecision={(result) => {
                setResult(JSON.stringify(result, null, 2));
                setShow(false);
              }}
              onCancel={() => {
                setResult('キャンセル');
                setShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
