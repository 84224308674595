import React, { ReactNode } from 'react';
import { ReactComponent as AccountIcon } from '@material-design-icons/svg/filled/account_circle.svg';
import { ReactComponent as RecipientsIcon } from '@material-design-icons/svg/filled/group.svg';
import { ReactComponent as GroupIcon } from '@material-design-icons/svg/filled/group.svg';
import { ReactComponent as AssignmentIcon } from '@material-design-icons/svg/filled/assignment.svg';
import { ReactComponent as NotificationsIcon } from '@material-design-icons/svg/outlined/notifications.svg';
import { ReactComponent as CompanyIcon } from '@material-design-icons/svg/outlined/business.svg';
import { ReactComponent as AddUserIcon } from '@material-design-icons/svg/outlined/person_add.svg';
import { ReactComponent as LogoutIcon } from '@material-design-icons/svg/filled/logout.svg';
import { ReactComponent as PasswordIcon } from '@material-design-icons/svg/filled/password.svg';
import { ReactComponent as AddIcon } from '@material-design-icons/svg/filled/add.svg';
import { ReactComponent as CancelIcon } from '@material-design-icons/svg/filled/cancel.svg';
import { ReactComponent as ClearIcon } from '@material-design-icons/svg/filled/clear.svg';
import { ReactComponent as CloseIcon } from '@material-design-icons/svg/filled/close.svg';
import { ReactComponent as FirstIcon } from '@material-design-icons/svg/filled/first_page.svg';
import { ReactComponent as PrevIcon } from '@material-design-icons/svg/filled/keyboard_arrow_left.svg';
import { ReactComponent as NextIcon } from '@material-design-icons/svg/filled/keyboard_arrow_right.svg';
import { ReactComponent as LastIcon } from '@material-design-icons/svg/filled/last_page.svg';
import { ReactComponent as UpIcon } from '@material-design-icons/svg/filled/keyboard_arrow_up.svg';
import { ReactComponent as DownIcon } from '@material-design-icons/svg/filled/keyboard_arrow_down.svg';
import { ReactComponent as RefreshIcon } from '@material-design-icons/svg/filled/refresh.svg';
import { ReactComponent as BackIcon } from '@material-design-icons/svg//filled/arrow_back.svg';
import { ReactComponent as DownloadIcon } from '@material-design-icons/svg/filled/save_alt.svg';
import { ReactComponent as FilesIcon } from '@material-design-icons/svg/filled/perm_media.svg';
import { ReactComponent as CheckedIcon } from '@material-design-icons/svg/filled/check_circle.svg';
import { ReactComponent as UnCheckedIcon } from '@material-design-icons/svg/filled/check_circle_outline.svg';
import { ReactComponent as ReadIcon } from '@material-design-icons/svg/outlined/drafts.svg';
import { ReactComponent as UnReadIcon } from '@material-design-icons/svg/filled/mark_email_unread.svg';
import { ReactComponent as DeleteIcon } from '@material-design-icons/svg/filled/delete_outline.svg';
import { ReactComponent as SendMailIcon } from '@material-design-icons/svg/filled/forward_to_inbox.svg';
import { ReactComponent as ForumIcon } from '@material-design-icons/svg/filled/forum.svg';
import { ReactComponent as RegistrationIcon } from '@material-design-icons/svg/filled/library_books.svg';
import { ReactComponent as SendIcon } from '@material-design-icons/svg/filled/send.svg';
import { ReactComponent as MoreIcon } from '@material-design-icons/svg/filled/more_vert.svg';
import { ReactComponent as ReadMoreIcon } from '@material-design-icons/svg/filled/read_more.svg';
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/filled/search.svg';
import { ReactComponent as EditIcon } from '@material-design-icons/svg/filled/edit.svg';
import { ReactComponent as NoteAddIcon } from '@material-design-icons/svg/filled/note_add.svg';
import { ReactComponent as DescriptionIcon } from '@material-design-icons/svg/filled/description.svg';
import { ReactComponent as HistoryIcon } from '@material-design-icons/svg/filled/history.svg';
import { ReactComponent as ClearListIcon } from '@material-design-icons/svg/filled/playlist_remove.svg';
import { ReactComponent as UploadIcon } from '@material-design-icons/svg/filled/upload_file.svg';
import { ReactComponent as HelpIcon } from '@material-design-icons/svg/filled/help.svg';
import { ReactComponent as OpenInNew } from '@material-design-icons/svg/outlined/open_in_new.svg';
import { ReactComponent as SmsIcon } from '@material-design-icons/svg/outlined/sms.svg';
import { ReactComponent as InsertComment } from '@material-design-icons/svg/outlined/insert_comment.svg';
import { ReactComponent as SubmitCommentIcon } from '@material-design-icons/svg/outlined/library_books.svg';
import { ReactComponent as ReplyIcon } from '@material-design-icons/svg/filled/reply.svg';
import { ReactComponent as ReadAllIcon } from '@material-design-icons/svg/filled/mark_email_read.svg';
import { ReactComponent as SettingsIcon } from '@material-design-icons/svg/outlined/settings.svg';
import { ReactComponent as MailIcon } from '@material-design-icons/svg/outlined/mail.svg';
import { ReactComponent as CopyIcon } from '@material-design-icons/svg/outlined/content_copy.svg';
import { ReactComponent as ManageSearchIcon } from '@material-design-icons/svg/outlined/mark_chat_unread.svg';
import { ReactComponent as FlagOnIcon } from '@material-design-icons/svg/filled/flag.svg';
import { ReactComponent as FlagOffIcon } from '@material-design-icons/svg/outlined/flag.svg';
import { ReactComponent as ArrowUpwardIcon } from '@material-design-icons/svg/outlined/arrow_upward.svg';
import { ReactComponent as ArrowDownwardIcon } from '@material-design-icons/svg/outlined/arrow_downward.svg';
import { ReactComponent as QueryStatsIcon } from '@material-design-icons/svg/outlined/query_stats.svg';
import { ReactComponent as FitSizeIcon } from '@material-design-icons/svg/filled/crop_free.svg';
import { ReactComponent as ZoomOutIcon } from '@material-design-icons/svg/filled/remove.svg';
import { ReactComponent as ZoomInIcon } from '@material-design-icons/svg/filled/add.svg';
import { ReactComponent as CommentIcon } from '@material-design-icons/svg/filled/add_comment.svg';
import { ReactComponent as MoveAreaIcon } from '@material-design-icons/svg/filled/control_camera.svg';
import { ReactComponent as CropIcon } from '@material-design-icons/svg/filled/crop.svg';
import { ReactComponent as PictureInPictureIcon } from '@material-design-icons/svg/filled/picture_in_picture.svg';
import { ReactComponent as SquareIcon } from '@material-design-icons/svg/filled/crop_square.svg';
import { ReactComponent as ArrowIcon } from '@material-design-icons/svg/filled/north_west.svg';
import { ReactComponent as AttributesIcon } from '@material-design-icons/svg/filled/content_paste_search.svg';

export type GenerallyIconType = keyof typeof iconTypeMap;

export const getGenerallyIconElement = (
  iconType: GenerallyIconType
): ReactNode => {
  return iconTypeMap[iconType];
};

const iconTypeMap = {
  // 機能
  account: <AccountIcon />,
  recipients: <RecipientsIcon />,
  group: <GroupIcon />,
  assignment: <AssignmentIcon />,
  notifications: <NotificationsIcon />,
  forum: <ForumIcon />,
  files: <FilesIcon />,
  company: <CompanyIcon />,
  message_text: <SmsIcon />,
  insert_comment: <InsertComment />,
  submit: <SubmitCommentIcon />,

  // 決定的なデータの操作
  registration: <RegistrationIcon />,
  send: <SendIcon />,
  delete: <DeleteIcon />,

  // 一時的な追加 / 削除
  add: <AddIcon />,
  cancel: <CancelIcon />,
  clear: <ClearIcon />,
  noteadd: <NoteAddIcon />,
  copy: <CopyIcon />,

  // リストなどの操作
  first: <FirstIcon />,
  prev: <PrevIcon />,
  next: <NextIcon />,
  last: <LastIcon />,
  up: <UpIcon />,
  down: <DownIcon />,
  arrow_upward: <ArrowUpwardIcon />,
  arrow_downward: <ArrowDownwardIcon />,

  // 遷移
  close: <CloseIcon />,
  back: <BackIcon />,
  reply: <ReplyIcon />,

  // その他のアクション
  search: <SearchIcon />,
  refresh: <RefreshIcon />,
  export: <DownloadIcon />,
  download: <DownloadIcon />,
  logout: <LogoutIcon />,
  more: <MoreIcon />,
  edit: <EditIcon />,
  description: <DescriptionIcon />,
  history: <HistoryIcon />,
  clear_list: <ClearListIcon />,
  read_more: <ReadMoreIcon />,
  upload: <UploadIcon />,
  open_in_new: <OpenInNew />,
  settings: <SettingsIcon />,
  mail: <MailIcon />,
  unread_message: <ManageSearchIcon />,
  add_user: <AddUserIcon />,
  query_stats: <QueryStatsIcon />,

  // ビューワー
  fit_size: <FitSizeIcon />,
  zoom_out: <ZoomOutIcon />,
  zoom_in: <ZoomInIcon />,

  // 画像操作系
  move_area: <MoveAreaIcon />,
  crop: <CropIcon />,
  picture_in_picture: <PictureInPictureIcon />,
  // 図面管理
  comment: <CommentIcon />,
  attributes: <AttributesIcon />,

  // チェック / 既読
  checked: <CheckedIcon />,
  unchecked: <UnCheckedIcon />,
  read: <ReadIcon />,
  unread: <UnReadIcon />,
  read_all: <ReadAllIcon />,
  send_mail: <SendMailIcon />,

  // フラグ
  flag_on: <FlagOnIcon />,
  flag_off: <FlagOffIcon />,

  // その他記号
  password: <PasswordIcon />,
  help: <HelpIcon />,
  square: <SquareIcon />,
  arrow: <ArrowIcon />,
};
