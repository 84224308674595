import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components/ui/ModalDialog/ModalDialog';
import { error } from '~/shared/components/parts/Toast/Toast';
import { CaptionButton } from '~/shared/components/parts/Button/CaptionButton';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ExtendFileUploader,
  FileItem,
  FileUploaderValidateOption,
} from '~/shared/components/file';
import './base.css';
import './FileUploadDialog.css';

export interface FileUploadDialogMessageOption {
  headerLabelId: MessageProps;
  captionLabelId?: MessageProps;
  decisionLabelId?: MessageProps;
  /** ファイル選択下の補足情報を示すための要素 */
  noteContents?: React.ReactNode;
}

export interface FileUploadDialogUploadOption {
  multiple?: boolean;
  validateOption?: FileUploaderValidateOption;
  isDnd?: boolean;
}

export interface FileUploadDialogResult {
  files: FileItem[];
}

export interface FileUploadDialogProps {
  isOpen: boolean;
  messageOption: FileUploadDialogMessageOption;
  fileUploadOption: FileUploadDialogUploadOption;
  onDecision: (result: FileUploadDialogResult) => void;
  onCancel: () => void;
  onChangeLoadingState?: (isLoading: boolean) => void;
}

export const FileUploadDialog = ({
  isOpen,
  messageOption,
  fileUploadOption,
  onDecision,
  onCancel,
  onChangeLoadingState,
}: FileUploadDialogProps) => {
  const intl = useIntl();
  const [fileList, setFileList] = useState<FileItem[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isClickable, setClickable] = useState(false);

  const handleDecision = () => {
    setClickable(false);
    if (fileList.some((item) => item.status !== 'OK')) {
      error([GetMessageWithIntl(intl, { id: 'E0000081' })]);
      return;
    }
    onDecision({
      files: [...fileList],
    });
  };

  useEffect(() => {
    setClickable(true);
  }, [fileList]);

  const elements = (
    <div className="file-upload-dialog">
      {messageOption.captionLabelId && (
        <p className="caption">
          {GetMessageWithIntl(intl, messageOption.captionLabelId)}
        </p>
      )}
      <ExtendFileUploader
        name="uploader"
        multiple={fileUploadOption.multiple}
        dndOption={{
          enabled: fileUploadOption.isDnd !== false,
        }}
        resultOption={{
          previewRowCount: 4,
        }}
        validateOption={fileUploadOption.validateOption}
        onChangeLoadingState={(v) => {
          onChangeLoadingState && onChangeLoadingState(v);
          setLoading(v);
        }}
        onUpload={setFileList}
      />
      {messageOption.noteContents}
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={() => onCancel()}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          disabled={isLoading || fileList.length <= 0 || !isClickable}
          caption={GetMessageWithIntl(
            intl,
            messageOption.decisionLabelId
              ? messageOption.decisionLabelId
              : { id: 'decision' }
          )}
          onClick={handleDecision}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements,
  };

  return (
    <div className="FileUploadDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
