import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveSamlProviderSetting {
  actionName: 'saveSamlProviderSetting';
  request: mtechnavi.api.idp.ISamlProviderSetting;
}

export const saveSamlProviderSetting = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.idp.ISamlProviderSetting
) => {
  const SaveFullMethodName =
    'mtechnavi.api.idp.Identity/SaveSamlProviderSetting';

  return await worker.invokeListRequest(SaveFullMethodName, {
    samlProviderSetting: request,
  });
};
