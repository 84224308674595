import React, { useReducer } from 'react';
import { SHAPE_TYPE_RECT } from './parts/Shapes/Rect';
import { OffsetInfo, SelectionInfo } from '.';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { baseScaleShape, ShapeInfo, ViewerInfo } from './util';

export interface AssetInfo {
  asset?: mtechnavi.api.assetinventory.IAsset;
  resourceId: string;
}

interface State {
  /** ファイルビューワーエリアの情報連携(サイズ・位置・スケール) */
  viewerInfo: ViewerInfo;
  /** 選択中のシェイプ情報 */
  selection: ShapeInfo;
  /** 選択を終えた永続化用のシェイプ情報 */
  draft: ShapeInfo | null;
}

const initialState: State = {
  viewerInfo: {
    offset: { x: 0, y: 0 },
    scale: 1,
    height: 0,
    width: 0,
    isDragging: false,
  },
  selection: {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
    attributes: {
      selectionShape: SHAPE_TYPE_RECT,
    },
  },
  draft: null,
};

interface ActionViewerResize {
  type: 'viewerResize';
  size: { width: number; height: number };
}
interface ActionChangeScale {
  type: 'changeScale';
  scale: number;
}
interface ActionDragging {
  type: 'dragging';
  offset: OffsetInfo;
}
interface ActionChangeOffset {
  type: 'changeOffset';
  offset: OffsetInfo;
}
interface ActionChangeShapeType {
  type: 'changeShapeType';
  shapeType: string;
}
interface ActionChangeSelection {
  type: 'changeSelection';
  selection: SelectionInfo;
}
interface ActionEndSelection {
  type: 'endSelection';
  selection: SelectionInfo;
}

type Action =
  | ActionViewerResize
  | ActionChangeScale
  | ActionDragging
  | ActionChangeOffset
  | ActionChangeShapeType
  | ActionChangeSelection
  | ActionEndSelection;
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'viewerResize':
      return {
        ...state,
        viewerInfo: {
          ...state.viewerInfo,
          ...action.size,
        },
      };
    case 'changeScale':
      return {
        ...state,
        viewerInfo: {
          ...state.viewerInfo,
          scale: action.scale,
        },
      };
    case 'dragging':
      return {
        ...state,
        viewerInfo: {
          ...state.viewerInfo,
          offset: action.offset,
          isDragging: true,
        },
      };
    case 'changeOffset':
      return {
        ...state,
        viewerInfo: {
          ...state.viewerInfo,
          offset: action.offset,
          isDragging: false,
        },
      };
    case 'changeShapeType':
      return {
        ...state,
        selection: {
          ...state.selection,
          attributes: {
            selectionShape: action.shapeType,
          },
        },
      };
    case 'changeSelection': {
      if (isIgnoreSize(action.selection)) {
        return {
          ...state,
          selection: {
            ...state.selection,
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 0,
          },
        };
      }
      return {
        ...state,
        selection: baseScaleShape(
          {
            ...action.selection,
            attributes: state.selection.attributes,
          },
          state.viewerInfo
        ),
      };
    }
    case 'endSelection':
      if (isIgnoreSize(action.selection)) {
        return {
          ...state,
          draft: null,
          selection: {
            ...state.selection,
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 0,
          },
        };
      }
      return {
        ...state,
        draft: baseScaleShape(
          {
            ...action.selection,
            attributes: state.selection.attributes,
          },
          state.viewerInfo
        ),
        selection: {
          ...state.selection,
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 0,
        },
      };
    default:
      throw new Error(`unknown action: ${action}`);
  }
};

/** 描画しない閾値 */
const IGNORE_SIZE_THRESHOLD = 20;
/** 選択範囲が小さすぎる場合に描画しない判定 */
const isIgnoreSize = (selection: SelectionInfo) => {
  return (
    Math.abs(selection.x1 - selection.x2) <= IGNORE_SIZE_THRESHOLD &&
    Math.abs(selection.y1 - selection.y2) <= IGNORE_SIZE_THRESHOLD
  );
};

export const useSelection = (initialShape?: string) => {
  return useReducer(reducer, {
    ...initialState,
    selection: {
      ...initialState.selection,
      attributes: initialShape
        ? {
            selectionShape: initialShape,
          }
        : initialState.selection.attributes,
    },
  });
};
