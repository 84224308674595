import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_ExtractBlueprintImageSegment } from 'worker/fullMethodName/crud';

export const extractBlueprintImageSegment = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.blueprint.IExtractBlueprintImageSegmentRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.blueprint.IExtractBlueprintImageSegmentRequest,
    mtechnavi.api.blueprint.IExtractBlueprintImageSegmentResponse
  >(FullMethodName_ExtractBlueprintImageSegment, {
    url: req.url,
  });
};
