import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_CreateTemporaryImages } from 'worker/fullMethodName/crud';

export interface CreateTemporaryImageRequest {
  actionName: 'createTemporaryImage';
  request: mtechnavi.api.assetinventory.ICreateTemporaryImagesRequest;
}

export const createTemporaryImage = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.assetinventory.ICreateTemporaryImagesRequest
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.assetinventory.ICreateTemporaryImagesRequest,
    mtechnavi.api.assetinventory.ICreateTemporaryImagesResponse
  >(FullMethodName_CreateTemporaryImages, {
    sourceUrl: req.sourceUrl,
    imagePropertys: req.imagePropertys,
  });
};
