import React, { useEffect, useState } from 'react';
import {
  Arrow,
  FileUploadDialog,
  FileUploadDialogResult,
  Rect,
  ShapeInfo,
  Viewer,
  ViewerControlButton,
  ViewerInfo,
} from '~/shared/components/ui';
import { BlueprintAttributes } from './parts/BlueprintAttributes';
import {
  Forum,
  getUsableBaseThreadId,
  ForumTypeName,
} from '~/shared/components/ui/Forum';
import {
  convertDate,
  convertDatetime,
  getDateFormat,
  getWorkerExceptionMessage,
  ViewId,
} from '~/shared/utils';
import { error, GetMessageWithIntl, success } from '~/shared/components';
import { useIntl } from 'react-intl';
import { IconButton } from '~/shared/components/ui/Button';
import './BlueprintBlueprintConfirmationBlueprint.css';
import {
  SegmentConfirmationDialog,
  SegmentConfirmationDialogResult,
} from '~/shared/components/ui/Dialog/SegmentConfirmationDialog';
import {
  BLUEPRINT_FILE_SIZE,
  BLUEPRINT_FILE_TYPES,
  getBlueprintAttributesList,
  getBlueprintRevisionList,
  uploadBlueprintFile,
} from '../../utils';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { useConfirmation } from '~/shared/hooks';
import {
  BlueprintRevisionDialog,
  BlueprintRevisionDialogResult,
} from '~/shared/components/ui/Dialog/BlueprintRevisionDialog';
import { useBlueprintConfirmationData } from './BlueprintConfirmationDataProvider';
import { renderOverlayShapes } from './parts/renderOverlayShapes';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { getControlCompletionByTypeName } from '~/shared/components/ui/Forum/utils/util';

interface BlueprintBlueprintConfirmationBlueprintProps {
  viewId: ViewId;
  onOpenHistory?: () => void;
  onOpenProcessing?: () => void;
  onBackPage?: () => void;
  isEditable: boolean;
}
/** 図面タブ */
export const BlueprintBlueprintConfirmationBlueprint = ({
  viewId,
  onOpenHistory,
  onOpenProcessing,
  onBackPage,
  isEditable,
}: BlueprintBlueprintConfirmationBlueprintProps) => {
  const intl = useIntl();
  const [mode, setMode] = useState<'comment' | 'attributes'>('attributes');
  const [attachedPoint, setAttachedPoint] = useState<ShapeInfo | null>();
  const { showLoading, hideLoading } = useLoading();
  const { confirmation, confirmationElement } = useConfirmation();

  const {
    blueprintContent,
    blueprintRevisions,
    isProcessing: buttonDisabled,
    dispatch,
  } = useBlueprintConfirmationData();

  const [isOpenSegmentConfirmation, setOpenSegmentConfirmation] =
    useState(false);
  const [isOpenFileReplace, setOpenFileReplace] = useState(false);
  const [isOpenRevision, setOpenRevision] = useState(false);

  // 図面コメント関連
  const [baseThreadId, setBaseThreadId] = useState<string>();
  const [threadList, setThreadList] = useState<
    mtechnavi.api.forum.IThread[] | null
  >();
  const [activeThreadId, setActiveThreadId] = useState<string | null>();
  const [viewerInfo, setViewerInfo] = useState<ViewerInfo>();

  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });
  const revisionDeleteMessage = {
    viewId,
    id: 'revisionDelete.confirm',
    value: {
      $1: blueprintContent?.blueprint?.blueprintNumber ?? '',
      $2: blueprintContent?.blueprintRevision?.revision ?? '',
    },
  };

  const blueprintRevisionId =
    blueprintContent?.blueprintRevision?.blueprintRevisionId;
  const blueprintAsset =
    blueprintContent?.blueprintRevision?.blueprintFile?.attachment;

  const isFirstRevision =
    !blueprintContent?.blueprintRevision?.revisedDt?.timestamp;

  useEffect(() => {
    if (!blueprintRevisionId) {
      return;
    }
    (async () => {
      showLoading();
      try {
        const baseThreadId = await getUsableBaseThreadId({
          typeName: ForumTypeName.Blueprint,
          resourceId: blueprintRevisionId ?? '',
        });

        setBaseThreadId(baseThreadId);
      } catch (err) {
        error(getWorkerExceptionMessage(intl, err));
      } finally {
        hideLoading();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, blueprintRevisionId]);

  /** 改訂情報の削除 */
  const handleDelete = async () => {
    if (blueprintRevisions?.length === 1) {
      error([GetMessageWithIntl(intl, { id: 'E0000187' })]);
      return;
    }
    if (!(await confirmation(revisionDeleteMessage))) {
      return;
    }
    showLoading();
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteBlueprintRevision',
        request: {
          blueprintRevisionId:
            blueprintContent?.blueprintRevision?.blueprintRevisionId,
          blueprintId: blueprintContent?.blueprintRevision?.blueprintId,
          updatedAt: blueprintContent?.blueprintRevision?.updatedAt,
        },
      });
      success([toastSuccess]);
      setOpenRevision(false);

      onBackPage && onBackPage();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      hideLoading();
    }
  };

  /** 改訂情報の編集ボタン */
  const handleRevisionEdit = () => {
    setOpenRevision(true);
  };

  /** 改訂情報の編集確定 */
  const handleUpdateRevision = async (
    result: BlueprintRevisionDialogResult
  ) => {
    showLoading();
    try {
      const resp = await window.App.services.ui.worker.apiCall({
        actionName: 'updateBlueprintRevision',
        request: {
          ...blueprintContent?.blueprintRevision,
          comment: result.comment,
          revisedDt: convertDatetime(result.revisionDate ?? null, 'YYYY/MM/DD'),
        },
      });
      success([toastSuccess]);
      setOpenRevision(false);

      // 更新後の状態を反映
      dispatch({
        type: 'updateRevision',
        blueprintRevision: resp.at(0)?.blueprintRevision,
      });
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      hideLoading();
    }
  };

  /** セグメント情報の更新 */
  const handleSegmentDecision = async (
    result: SegmentConfirmationDialogResult
  ) => {
    showLoading();
    try {
      const segmentAssets = await window.App.services.ui.worker.apiCall({
        actionName: 'createAssetImages',
        request: {
          assetId: result?.imageProperty?.imageAssetId,
          imagePropertys: result?.segmentPropertys?.map((segment) => ({
            format: mtechnavi.api.assetinventory.ImageFormat.PNG,
            name: segment.segmentName,
            option: {
              crop: true,
              width: segment.width,
              height: segment.height,
              top: segment.top,
              left: segment.left,
            },
          })),
        },
      });
      const resp = await window.App.services.ui.worker.apiCall({
        actionName: 'updateBlueprintSegment',
        request: {
          blueprintSegment: {
            ...result,
            segmentPropertys: result?.segmentPropertys?.map((segment) => {
              const segmentAssetId = segmentAssets
                ?.at(0)
                ?.createdImages?.find(
                  (image) => image.name === segment.segmentName
                )?.assetId;
              return {
                ...segment,
                assetId: segmentAssetId,
              };
            }),
          },
        },
      });
      success([toastSuccess]);
      setOpenRevision(false);

      // 更新後の状態を反映
      dispatch({
        type: 'updateSegment',
        blueprintSegment: resp.at(0),
      });
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      hideLoading();
    }
  };

  /** 図面ファイル差替確定 */
  const handleFileReplaceDecision = async (result: FileUploadDialogResult) => {
    const file = result.files?.at(0);
    if (!file) {
      error([GetMessageWithIntl(intl, { id: 'E0000186' })]);
      return;
    }
    showLoading();
    try {
      const attachment = await uploadBlueprintFile(file);
      const resp = await window.App.services.ui.worker.apiCall({
        actionName: 'updateBlueprintRevision',
        request: {
          ...blueprintContent?.blueprintRevision,
          blueprintFile: { attachment },
        },
      });
      success([toastSuccess]);
      setOpenFileReplace(false);

      // 差替後の状態を反映
      dispatch({
        type: 'setContent',
        content: resp.at(0),
      });
      onOpenProcessing && onOpenProcessing();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      hideLoading();
    }
  };

  /** 図面抽出属性の再取得 */
  const handleReloadAttributes = async () => {
    if (!blueprintContent?.blueprintRevision?.blueprintRevisionId) {
      return;
    }
    showLoading();
    try {
      const attributes = await getBlueprintAttributesList(
        blueprintContent?.blueprintRevision?.blueprintRevisionId
      );
      dispatch({
        type: 'setAttributes',
        blueprintAttributes: attributes,
      });
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      hideLoading();
    }
  };

  return (
    <div className="BlueprintBlueprintConfirmationBlueprint">
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-66">
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-33 fix-baseline">
                  {`${GetMessageWithIntl(intl, {
                    id: 'revision',
                    viewId,
                  })}：${blueprintContent?.blueprintRevision?.revision ?? ''}`}
                  <IconButton
                    name="history"
                    iconType="history"
                    buttonType="basic"
                    onClick={() => onOpenHistory && onOpenHistory()}
                  />
                  <IconButton
                    name="delete"
                    iconType="delete"
                    buttonType="basic"
                    disabled={buttonDisabled || !isEditable}
                    onClick={handleDelete}
                  />
                </div>
                <div className="w-33 fix-baseline">
                  {`${GetMessageWithIntl(intl, {
                    id: 'revisedDt',
                    viewId,
                  })}：${
                    getDateFormat(
                      blueprintContent?.blueprintRevision?.revisedDt
                        ?.timestamp ?? '',
                      'YYYY/MM/DD'
                    ) || '-'
                  }`}
                  {!isFirstRevision && (
                    <IconButton
                      name="edit"
                      iconType="edit"
                      buttonType="basic"
                      disabled={buttonDisabled || !isEditable}
                      onClick={handleRevisionEdit}
                    />
                  )}
                </div>
              </div>
            </div>
            {blueprintAsset?.assetId && (
              <Viewer
                assetId={blueprintAsset?.assetId ?? ''}
                fileName={blueprintAsset?.filename ?? ''}
                controls={{
                  filename: true,
                  downloading: true,
                  pager: true,
                  scaling: true,
                  cropping: mode === 'comment',
                  croppingOption: {
                    shapeList: {
                      Rectangle: { iconType: 'square', component: Rect },
                      Arrow: { iconType: 'arrow', component: Arrow },
                    },
                  },
                  extraControlsPosition: 'top',
                  extraControls: [
                    <>
                      <ViewerControlButton
                        name="attributes"
                        isActive={mode === 'attributes'}
                        iconType="attributes"
                        onClick={() => {
                          setAttachedPoint(null);
                          setActiveThreadId(null);
                          setMode('attributes');
                        }}
                      />
                      <ViewerControlButton
                        name="comment"
                        isActive={mode === 'comment'}
                        iconType="comment"
                        onClick={() => setMode('comment')}
                      />
                    </>,
                    mode === 'attributes' ? (
                      <>
                        <ViewerControlButton
                          name="segment"
                          iconType="picture_in_picture"
                          disabled={buttonDisabled}
                          onClick={() => setOpenSegmentConfirmation(true)}
                        />
                        <ViewerControlButton
                          name="edit"
                          iconType="edit"
                          disabled={buttonDisabled || !isEditable}
                          onClick={() => setOpenFileReplace(true)}
                        />
                      </>
                    ) : null,
                  ],
                }}
                onChangeViewerInfo={setViewerInfo}
                onSelectionEnd={setAttachedPoint}
                overlayItems={
                  mode === 'comment'
                    ? renderOverlayShapes({
                        threadList,
                        activeThreadId,
                        draft: attachedPoint,
                        viewerInfo,
                        onClickSelection: setActiveThreadId,
                      })
                    : []
                }
                width="100%"
                height="100%"
              />
            )}
          </div>
          <div className="w-33">
            <div className="sticky-area">
              {mode === 'attributes' && (
                <BlueprintAttributes
                  viewId={viewId}
                  disabled={buttonDisabled || !isEditable}
                  onReloadAttributes={handleReloadAttributes}
                />
              )}
              {mode === 'comment' && (
                <Forum
                  viewId={viewId}
                  baseThreadId={baseThreadId}
                  isShowDraft={!!attachedPoint}
                  currentThreadId={activeThreadId}
                  draftOptionalData={{
                    associations: [blueprintAsset?.assetId ?? ''],
                    attachedPoint,
                  }}
                  controls={{
                    hideCreateThread: true,
                    isControlCompletion: getControlCompletionByTypeName(
                      ForumTypeName.Blueprint
                    ),
                  }}
                  onChangeActiveThread={setActiveThreadId}
                  onFilterThreadList={setThreadList}
                  onAddThread={() => {
                    // 追加処理は終わっているため後処理だけ行う
                    setAttachedPoint(null);
                  }}
                  onCancelAddThread={() => setAttachedPoint(null)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <>
        {/* セグメント確認ダイアログ */}
        <SegmentConfirmationDialog
          key={isOpenSegmentConfirmation ? 1 : 0}
          isOpen={isOpenSegmentConfirmation}
          messageOption={{
            headerLabel: {
              prefixId: 'DIALOG_TITLE',
              id: 'SegmentConfirmationDialog',
            },
          }}
          inputOption={{
            mode: isEditable ? 'edit' : 'confirm',
            assetId: blueprintAsset?.assetId ?? '',
            segment: blueprintContent?.blueprintSegment,
          }}
          onDecision={handleSegmentDecision}
          onCancel={() => setOpenSegmentConfirmation(false)}
        />
      </>
      <>
        {/* 図面ファイル差替えダイアログ */}
        <FileUploadDialog
          isOpen={isOpenFileReplace}
          messageOption={{
            headerLabelId: {
              prefixId: 'DIALOG_TITLE',
              id: 'blueprintFileReplace',
            },
            captionLabelId: {
              prefixId: 'blueprintFileReplaceDialog',
              id: 'fileReplace',
            },
            decisionLabelId: {
              id: 'import',
            },
            noteContents: <BlueprintFileReplaceNote viewId={viewId} />,
          }}
          fileUploadOption={{
            multiple: false,
            validateOption: {
              allowedFileExtensions: BLUEPRINT_FILE_TYPES,
              maxFileSizeInMebis: BLUEPRINT_FILE_SIZE,
            },
            isDnd: false,
          }}
          onDecision={handleFileReplaceDecision}
          onCancel={() => setOpenFileReplace(false)}
          onChangeLoadingState={(isLoading) => {
            console.info('Loading Status', isLoading);
          }}
        />
      </>
      <>
        {/* 改訂登録ダイアログ(編集) */}
        <BlueprintRevisionDialog
          key={isOpenRevision ? 1 : 0}
          isOpen={isOpenRevision}
          inputOption={{
            mode: 'edit',
            comment: blueprintContent?.blueprintRevision?.comment ?? '',
            revisionDate:
              convertDate(
                blueprintContent?.blueprintRevision?.revisedDt ?? null
              ) ?? undefined,
          }}
          messageOption={{
            headerLabel: {
              prefixId: 'DIALOG_TITLE',
              id: 'blueprintRevisionRegistration',
            },
          }}
          onDecision={handleUpdateRevision}
          onCancel={() => setOpenRevision(false)}
        />
      </>
      <>
        {/* 確認ダイアログ */}
        {confirmationElement}
      </>
    </div>
  );
};

/** ファイル差し替え用の注意事項 */
const BlueprintFileReplaceNote = ({ viewId }: { viewId: ViewId }) => {
  const intl = useIntl();
  return (
    <div className="BlueprintFileReplaceNote">
      <h4>
        {GetMessageWithIntl(intl, {
          viewId,
          id: 'revisionReplace.title',
        })}
      </h4>
      <p>
        {GetMessageWithIntl(intl, {
          viewId,
          id: 'revisionReplace.description.attribute',
        })}
      </p>
      <p>
        {GetMessageWithIntl(intl, {
          viewId,
          id: 'revisionReplace.description.segment',
        })}
      </p>
      <p>
        {GetMessageWithIntl(intl, {
          viewId,
          id: 'revisionReplace.description.caution',
        })}
      </p>
    </div>
  );
};
