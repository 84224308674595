import React, {
  CSSProperties,
  Fragment,
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
  RefObject,
  createRef,
} from 'react';

import { ReactComponent as ArrowDropUpIcon } from '@material-design-icons/svg/filled/arrow_drop_up.svg';
import { ReactComponent as ArrowDropDownIcon } from '@material-design-icons/svg/filled/arrow_drop_down.svg';
import { ReactComponent as Menu } from '@material-design-icons/svg/filled/arrow_drop_down.svg';
import { ReactComponent as HeaderMenu } from '@material-design-icons/svg/filled/menu.svg';

import { useAuth } from '~/shared/contexts/AuthProvider';

import sortProfile from './sort.json';
import { IconButton } from '../Button';

import './base.css';
import type {
  DummyUser,
  SortTerm,
  AggregateStage,
  FullMethodName,
} from '~/worker';
import { usePagenator } from './pagenator';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import { useIntl } from 'react-intl';
import { Checkbox } from '~/shared/components/parts/Checkbox/Checkbox';
import { GetMessageComponent } from '~/shared/components/parts/Message/Message';
import { defaultFormatter } from './formatter';

import { Selectbox } from '../Selectbox';
import { ImportDialog } from '../Dialog/ImportDialog';
import { error } from '~/shared/components/parts/Toast/Toast';
import { LoadingIcon } from '../LoadingIcon/LoadingIcon';
import {
  FilterView,
  FilterObject,
  FilterViewQuery,
  toFilterExpression,
  toFilterViewQuery,
} from '../FilterView';
import { getPresetPropertyValue } from '../FilterView/preset';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getFilterAndSortData,
  getLocalStorageCheckboxData,
  saveLocalStorageCheckboxData,
  PageState,
  saveFilterAndSortData,
  ViewId,
  autoDownloadFileWithDate,
  exportGoogleAnalytics,
  getWorkerExceptionMessage,
} from '~/shared/utils';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';

import {
  Preset,
  Property,
  Schema,
  ListViewImportDialogOption,
  ListViewPageSetting,
  InitialFilter,
  InitialFilterItem,
} from './commonType';

import { setMenuEventHandle, setMenuListStyle } from '../common';

interface ListSkipType {
  isAllActionMenu?: boolean;
  isListActionMenu?: boolean;
  isFilter?: boolean;
  isCheckbox?: boolean;
  isTotal?: boolean;
  isReload?: boolean;
  isOutput?: boolean;
}

export interface MenuListViewProps {
  fullMethodName: FullMethodName;
  areaTitle?: string;
  schema: Schema;
  preset: Preset;
  isReload?: boolean;
  presetItems?: PresetItem[];
  menuEvent?: MenuItem[];
  menuViewlist?: string[];
  maxMenuColumn?: string;
  checkValue?: string[];
  listSkipType?: ListSkipType;
  menuTarget?: string;
  headerTitle?: MessageProps;
  onHandleDownload?: (arg: string[]) => Promise<string>;
  importDialogOption?: ListViewImportDialogOption;
  onChangeState?: (arg: string[]) => void;
  onChangeCheckItemsState?: (items?: DummyUser[]) => void;
  initialFilterItems?: InitialFilter; // 初期検索時に実行されるフィルタ
  aggregateStages?: AggregateStage[]; // join条件
  naviFilterItems?: InitialFilter; // ページ遷移時に実行されるフィルタ
  onOriginalItemState?: (items: DummyUser[]) => void;
  isNotEqFilter?: boolean;
}

// アクションメニュー名
export type MenuName =
  | 'add'
  | 'closing'
  | 'unclosing'
  | 'approval'
  | 'confirm'
  | 'delete'
  | 'discard'
  | 'setInfo'
  | 'decline'
  | 'tag'
  | 'companyConfirm'
  | 'businessunitConfirm'
  | 'export'
  | 'exportMenuItems'
  | 'exportRoleItems'
  | 'edit'
  | 'businessUnitImport'
  | 'blueprintRevise'
  | 'viewerAll'
  | 'viewer'
  | 'remove'
  | 'open'
  | 'complete'
  | 'staffImport'
  | 'drawingLinkage'
  | 'import'
  | 'sendTradeRequest'
  | 'approvalTradeRequest'
  | 'estimateRequestInputAdd'
  | 'estimateRequestInputCopy'
  | 'estimateRequestInputEdit'
  | 'feedback'
  | 'estimateSelection'
  | 'estimateResultList'
  | 'stop'
  | 'resume'
  | 'sendEmail'
  | 'drawingInfoSetting'
  | 'copy';

// ヘッダーアクション実行時にチェックボックスが空でもエラーとしないMenuName
const NO_CHECK_ACTION_MENU_NAME: MenuName[] = [
  'add',
  'import',
  'staffImport',
  'estimateRequestInputAdd',
  'estimateResultList',
  'tag',
  'drawingInfoSetting',
  'businessUnitImport',
];

type TotalType = 'selected' | 'all';

type MenuFunc = (v?: string[]) => void;

export interface Menu {
  menuName: MenuName;
  displayName: string;
  func: MenuFunc;
  property?: PresetProperty[];
}

export interface MenuItem {
  name: MenuName;
  func: MenuFunc;
  property?: PresetProperty[];
}

const getPresetItem = (
  name: string,
  presetItems: PresetItem[] | undefined
): PresetItem | undefined => {
  return presetItems
    ? presetItems.find((v) => v.name === name)
    : { name: name };
};

export function MenuListView(props: MenuListViewProps) {
  const {
    fullMethodName,
    areaTitle,
    schema,
    preset,
    isReload,
    importDialogOption,
    menuEvent,
    menuViewlist,
    maxMenuColumn = '4',
    presetItems,
    initialFilterItems,
    aggregateStages,
    naviFilterItems,
    listSkipType,
    menuTarget,
    headerTitle,
    onHandleDownload,
    onChangeState,
    onChangeCheckItemsState,
    onOriginalItemState,
  } = props;

  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const intl = useIntl();
  const { state } = useLocation();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const [isLoading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState<FilterViewQuery>({
    filterTerms: {},
  });
  const [headerSelect, setHeaderSelect] = useState<string[]>([]);
  const [isImportSuccess, setImportSuccess] = useState(false); // importが成功したかどうかの判定

  const contentsRef = useRef<HTMLDivElement>(null);

  const onParentChangeState = useCallback(
    (arg: string[]) => {
      onChangeState && onChangeState(arg);
    },
    [onChangeState]
  );
  const onParentChangeCheckItemsState = useCallback(
    (items: DummyUser[]) => {
      onChangeCheckItemsState && onChangeCheckItemsState(items);
    },
    [onChangeCheckItemsState]
  );
  const onParentOriginalItemState = useCallback(
    (items: DummyUser[]) => {
      onOriginalItemState && onOriginalItemState(items);
    },
    [onOriginalItemState]
  );

  const getQueryFromLocalStorage =
    useCallback((): ListViewPageSetting | null => {
      if (!headerTitle?.viewId) {
        return null;
      }
      return getFilterAndSortData(headerTitle.viewId, myEmail);
    }, [headerTitle?.viewId, myEmail]);

  const burgerMenu = useCallback(
    (target: string): Menu[] => {
      const burgerMenu: Menu[] = [];
      const burgerItem: PresetItem = getPresetItem(target, presetItems) ?? {
        name: target,
        property: [],
      };
      if (burgerItem.property) {
        const childrenItem: PresetItem = getPresetItem(
          'visibleCondition',
          burgerItem.children
        ) ?? {
          name: target,
        };
        burgerItem.property.map((v) => {
          if (!menuViewlist?.includes(v.name)) return;
          const initMenuItem: MenuItem = {
            name: 'add',
            func: () => {},
          };

          const menuItem = menuEvent
            ? menuEvent.find((event) => event.name === v.name)
            : initMenuItem;
          const event: MenuItem = menuItem ?? initMenuItem;
          burgerMenu.push({
            menuName: event.name,
            displayName: v.propertyValue,
            func: (v) => {
              event.func(v);
            },
            property: childrenItem.property?.filter(
              (item) => item.name === v.name
            ),
          });
        });
      }
      return burgerMenu;
    },
    [presetItems, menuEvent, menuViewlist]
  );

  const [
    properties,
    filterPresetItem,
    allBurgerMenu,
    listBurgermenu,
    listPresetProperty,
    totalColumn,
    numberColumnPreset,
  ] = useMemo<
    [
      Property[],
      PresetItem | undefined,
      Menu[],
      Menu[],
      PresetProperty[],
      string[],
      PresetItem | undefined
    ]
  >(() => {
    const schemaProps: Property[] = schema.map((prop) => {
      return {
        ...prop,
        formatter: prop.formatter ?? defaultFormatter([prop.name]),
      };
    });
    const listProperties = preset.propertyNames
      .map((propertyName) => {
        return schemaProps.find((v) => v.name === propertyName);
      })
      .filter((v) => !!v);
    const allBurgerMenu = burgerMenu('allMenu');
    const listBurgermenu = burgerMenu('listMenu');
    const totalItemsPreset = getPresetItem('totalItem', presetItems);
    const listViewItem = getPresetItem('listView', presetItems);
    const listPresetProperty = listViewItem?.property ?? [];

    return [
      listProperties as Property[],
      getPresetItem('filter', presetItems),
      allBurgerMenu,
      listBurgermenu,
      listPresetProperty,
      totalItemsPreset?.columns ?? [],
      getPresetItem('numberColumn', listViewItem?.children),
    ];
  }, [burgerMenu, schema, preset, presetItems]);

  const getPresetInitialSortTerms = useCallback((): SortTerm[] => {
    const initialSortPresetItem = getPresetItem(
      'initialSort',
      filterPresetItem?.children ?? []
    ) ?? {
      name: 'initialSort',
      columns: [],
    };
    const sortOrder: SortTerm[] = [];
    if (initialSortPresetItem.property) {
      for (const v of initialSortPresetItem.property) {
        sortOrder.push({
          [v.name]: v.propertyValue,
        });
      }
    }
    return sortOrder;
  }, [filterPresetItem]);

  const getSort = useCallback((): SortTerm[] => {
    // LocalStorageにソート履歴がある場合は、再利用
    const tmp = getQueryFromLocalStorage();
    if (tmp?.sort && tmp.sort.length > 0) {
      return tmp.sort;
    }
    return getPresetInitialSortTerms();
  }, [getQueryFromLocalStorage, getPresetInitialSortTerms]);

  const composeInitialFilters = useCallback(
    (...initialFilters: InitialFilter[]): FilterViewQuery => {
      const filterObj: FilterObject = {};
      for (const initialFilter of initialFilters) {
        for (const { targetKey, targetValue } of initialFilter.info) {
          filterObj[targetKey] = filterObj[targetKey] || [];
          filterObj[targetKey].push(targetValue);
        }
      }
      return toFilterViewQuery(filterPresetItem, filterObj);
    },
    [filterPresetItem]
  );

  const getPresetInitialFilters = useCallback((): InitialFilter[] => {
    // プリセット（初期フィルタ条件）
    const initialFilterPresetItem: PresetItem = getPresetItem(
      'initialFilterItems',
      filterPresetItem?.children
    ) ?? {
      name: 'initialFilterItems',
      columns: [],
    };
    // プリセット内の初期フィルタプロパティから条件を抽出
    const initialFilterMap = (initialFilterPresetItem.property ?? []).reduce(
      (prev, v) => {
        if (!v.propertyName || !v.name) {
          return prev;
        }
        const items = prev.get(v.propertyName) ?? [];
        items.push({
          targetKey: v.name,
          targetValue: v.propertyValue,
        });
        prev.set(v.propertyName, items);
        return prev;
      },
      new Map<string, InitialFilterItem[]>()
    );
    const initialFilters: InitialFilter[] = [];
    initialFilterMap.forEach((v) => {
      initialFilters.push({
        info: v,
      });
    });
    return initialFilters;
  }, [filterPresetItem?.children]);

  const getInitialFilter = useCallback((): FilterViewQuery => {
    const initialFilters = getPresetInitialFilters();
    if (initialFilterItems) {
      initialFilters.push(initialFilterItems); // ListViewPropsから指定された条件もあればマージ
    }
    return composeInitialFilters(...initialFilters);
  }, [initialFilterItems, getPresetInitialFilters, composeInitialFilters]);

  const getFilter = useCallback((): FilterViewQuery => {
    // 例外的にPageState経由で検索条件が指定されている場合は、naviFilterItems の条件を最優先に適用する
    if (naviFilterItems) {
      return composeInitialFilters(naviFilterItems);
    }
    // LocalStorageに検索履歴がある場合は、再利用
    const tmp = getQueryFromLocalStorage();
    if (tmp?.filter) return tmp.filter;
    // 初期検索条件を取得
    return getInitialFilter();
  }, [
    naviFilterItems,
    getInitialFilter,
    getQueryFromLocalStorage,
    composeInitialFilters,
  ]);

  // チェック済みのID配列を取得
  const handleChangeCheckedIds = (items: DummyUser[]) => {
    if (!headerTitle?.viewId) return;
    const lsCheckedValue = getLocalStorageCheckboxData(
      headerTitle.viewId,
      myEmail
    );
    const changeIds = items.reduce((changeIds, item) => {
      const id = item[menuTarget as keyof typeof item];
      lsCheckedValue.includes(id) && changeIds.push(id);
      return changeIds;
    }, [] as string[]);
    saveLocalStorageCheckboxData(headerTitle.viewId, changeIds, myEmail);
  };

  // チェック済みのID配列を取得
  const initializeLocalStorageCheckboxData = () => {
    headerTitle?.viewId &&
      saveLocalStorageCheckboxData(headerTitle.viewId, [], myEmail);
  };

  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  const [page, dispatch] = usePagenator(
    {
      fullMethodName: fullMethodName,
      pageNumber: 1,
      pageSize: 50,
      maxPageNumber: 1,
      filter: toFilterExpression(
        getFilter(),
        filterPresetItem,
        props.isNotEqFilter
      ),
      sort: getSort(),
      aggregate: aggregateStages,
      items: [],
      onError(err) {
        setException(true);
        setPagenateError(err);
        handleError(err);
      },
    },
    intl
  );

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  const handleReload = useCallback((): FilterViewQuery => {
    const pageState = getFilterAndSortData(headerTitle?.viewId ?? '', myEmail);
    const pageSize = pageState?.pageSize ?? '50';
    const pageNumber = pageState?.pageNumber ?? '1';
    const filter = getFilter();
    dispatch({
      type: 'reload',
      fullMethodName: fullMethodName,
      pageSize: Number(pageSize),
      pageNumber: Number(pageNumber),
      filter: toFilterExpression(filter, filterPresetItem),
      sort: getSort(),
      onChangeLoadingState: (v) => {
        setLoading(v);
      },
    });
    return filter;
    // dispatch は呼び出し毎に変更されてしまうため依存引数から除外する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullMethodName, getFilter, getSort]);

  const handleQuery = useCallback(
    (v: FilterViewQuery): void => {
      const pageState = getFilterAndSortData(
        headerTitle?.viewId ?? '',
        myEmail
      );
      const pageSize = pageState?.pageSize ?? '50';
      dispatch({
        type: 'query',
        fullMethodName: fullMethodName,
        pageNumber: 1,
        pageSize: Number(pageSize),
        sort: getSort(),
        filter: toFilterExpression(v, filterPresetItem, props.isNotEqFilter),
      });
    },
    // dispatch は呼び出し毎に変更されてしまうため依存引数から除外する
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterPresetItem, fullMethodName]
  );

  const initializePageFilter = () => {
    const pageInfo = state as PageState;
    pageInfo?.naviFilters && (pageInfo.naviFilters = []);
    navigate('.', {
      replace: true,
      state: pageInfo,
    });
  };

  const onSubmitFilter = (v: FilterViewQuery): void => {
    // 検索時はローカルストレージのチェック状況をクリアする
    initializeLocalStorageCheckboxData();
    // 検索時はページ遷移フィルタを初期化する
    initializePageFilter();
    // 検索時のみフィルタ条件をLocalStorageに格納
    if (headerTitle?.viewId) {
      saveFilterAndSortData(
        headerTitle.viewId,
        {
          filter: v,
        },
        myEmail
      );
    }
    setFilterValue(v);
  };

  useEffect(() => {
    handleQuery(filterValue);
  }, [filterValue, handleQuery]);

  useEffect(() => {
    if (!!preset.propertyNames.length && headerTitle?.viewId) {
      setFilterValue(handleReload());
    }
  }, [preset.propertyNames, headerTitle?.viewId, handleReload]);

  useEffect(() => {
    if (isReload) {
      // データを再読み込み
      handleReload();
    }
  }, [handleReload, isReload]);

  useEffect(() => {
    if (isImportSuccess) {
      // データを再読み込み
      handleReload();
    }
  }, [handleReload, isImportSuccess]);

  useEffect(() => {
    onParentOriginalItemState(page.originalItems!);
  }, [page.originalItems, onParentOriginalItemState]);

  useEffect(() => {
    if (importDialogOption?.isDialogOpen) {
      // 取り込みダイアログオープン時に取り込み成功フラグを初期化
      setImportSuccess(false);
    }
  }, [importDialogOption]);

  // 表示件数変更時の処理 全て未選択にする
  useEffect(() => {
    const tmpSelected: string[] = [];
    [...Array(page.pageSize)].forEach(() => {
      tmpSelected.push('');
    });
    onParentChangeState(tmpSelected);
  }, [page.pageSize, onParentChangeState]);

  const headerMenuBoxRef = useRef<HTMLLIElement>(null);
  const listMenuBoxRefs: Map<number, RefObject<HTMLLIElement>> = useMemo(() => {
    return new Map<number, RefObject<HTMLLIElement>>();
  }, []);
  page.items.forEach((_, idx) => {
    listMenuBoxRefs.set(idx, createRef());
  });
  useEffect(() => {
    // ヘッダメニューのイベントハンドラを設定
    const headerMenuBoxElement = headerMenuBoxRef?.current;
    if (headerMenuBoxElement) {
      setMenuEventHandle(headerMenuBoxElement);
    }
    // 一覧メニューのイベントハンドラを設定
    page.items.forEach((_, idx) => {
      const listMenuBoxElement = listMenuBoxRefs.get(idx)?.current;
      if (!listMenuBoxElement) return;
      setMenuListStyle(contentsRef, listMenuBoxElement, maxMenuColumn);
      setMenuEventHandle(listMenuBoxElement);
    });
  }, [page.items, listMenuBoxRefs, maxMenuColumn]);

  const handleChangePageSize = (value: string) => {
    const pageSize = parseInt(value, 10);
    dispatch({
      type: 'query',
      fullMethodName: fullMethodName,
      pageSize: pageSize,
      filter: toFilterExpression(getFilter(), filterPresetItem),
      onChangeItems(v) {
        handleChangeCheckedIds(v);
      },
    });
    headerTitle?.viewId &&
      saveFilterAndSortData(headerTitle.viewId, { pageSize: value }, myEmail);
  };

  // pagenaterのページ移動時の処理 全て未選択にする
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: fullMethodName,
      pageNumber: n,
    });
    const tmpSelected: string[] = [];
    [...Array(page.pageSize)].forEach(() => {
      tmpSelected.push('');
    });
    onParentChangeState(tmpSelected);
    setHeaderSelect([]);
    if (headerTitle?.viewId) {
      saveFilterAndSortData(
        headerTitle.viewId,
        { pageNumber: n.toString() },
        myEmail
      );
      initializeLocalStorageCheckboxData();
    }
  };

  // ソートを除外するか判定する
  const isExcludeSortColumn = (viewId: ViewId, column: string): boolean => {
    // ソート除外情報の読み込み
    const excludeSortList = sortProfile;

    const target = excludeSortList.find((v) => v.viewId === viewId);
    if (target) {
      return target.columns.includes(column);
    } else {
      return false;
    }
  };

  const handleChangeSort = (prop: string) => {
    if (isExcludeSortColumn(headerTitle?.viewId ?? '', prop)) {
      return;
    }
    const value = page.sort.find((v) => Object.keys(v).includes(prop)) || {
      [prop]: 'asc',
    };
    value[prop] = value[prop] === 'asc' ? 'desc' : 'asc';
    headerTitle?.viewId &&
      saveFilterAndSortData(headerTitle.viewId, { sort: [value] }, myEmail);
    dispatch({
      type: 'query',
      fullMethodName: fullMethodName,
      sort: [value],
      onChangeItems(v) {
        handleChangeCheckedIds(v);
      },
    });
  };

  const selectMenuId = () => {
    return getLocalStorageCheckboxData(headerTitle?.viewId ?? '', myEmail);
  };

  const propertyValue = (
    listPresetProperty: PresetProperty[],
    columnName: string,
    propertyName: string
  ) => {
    const property: PresetProperty[] = listPresetProperty.filter((v) => {
      return v.propertyName === propertyName && v.name === columnName;
    });

    const value = property[0]?.propertyValue ?? '';
    return value;
  };

  const handleDownload = async () => {
    // ダウンロード処理が設定されている場合は処理を行う、それ以外はスキップ
    if (onHandleDownload) {
      const ids = page.items.map((item) => {
        return item[menuTarget as keyof typeof item];
      });
      const id = await onHandleDownload(ids);
      const fileName = GetMessageWithIntl(intl, {
        prefixId: 'HEADER_TITLE',
        id: headerTitle?.viewId ?? '',
      });
      autoDownloadFileWithDate(fileName, 'csv', id);
    }
  };

  const totalType: TotalType = 'all';
  const totalValue = (propertyName: string) => {
    let total = 0;
    let isExists = false;
    if (totalType === 'all') {
      (page.allItems ?? []).map((item) => {
        totalColumn.map((column) => {
          if (propertyName === column) {
            const dbValue = item[column as keyof typeof item];
            if (dbValue) {
              total = total + Number(dbValue);
            }
            isExists = true;
          }
        });
      });
    } else {
      (page.items ?? []).map((item) => {
        totalColumn.map((column) => {
          if (propertyName === column) {
            const dbValue = item[column as keyof typeof item];
            isExists = true;
            const lsCheckedValue = getLocalStorageCheckboxData(
              headerTitle?.viewId ?? '',
              myEmail
            );
            const id = item[props.menuTarget as keyof typeof item];
            if (lsCheckedValue.includes(id)) {
              total = total + Number(dbValue);
            }
          }
        });
      });
    }
    return isExists ? total : '';
  };

  const sorterIndicator = (prop: string) => {
    if (isExcludeSortColumn(headerTitle?.viewId ?? '', prop)) {
      return '';
    }
    const value = page.sort.find((v) => Object.keys(v).includes(prop));
    if (!value) {
      return '';
    }
    return value[prop] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
  };

  const listViewChecker = (type?: string): boolean => {
    if (listSkipType?.isAllActionMenu) return false;
    switch (type) {
      case 'filter':
        return listSkipType?.isFilter ? false : true;
      case 'menu':
        return listSkipType?.isListActionMenu ? false : true;
      case 'check':
        return listSkipType?.isCheckbox ? false : true;
      case 'total':
        return listSkipType?.isTotal ? false : true;
      case 'reload':
        return listSkipType?.isReload ? false : true;
      case 'output':
        return listSkipType?.isOutput ? false : true;
      default:
        return true;
    }
  };

  const gridLIineWidth = (): string => {
    let num = '';
    num = listViewChecker('menu') ? '4rem ' : '';
    num += listViewChecker('check') ? '4rem ' : '';

    properties.map((propertyName) => {
      num +=
        propertyValue(listPresetProperty, propertyName.name, 'width') + ' ';
    });
    return num;
  };

  const listViewStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: gridLIineWidth(),
    alignItems: 'center',
    flex: 1,
  };
  const propertyListViewStyle = (propertyName: string): CSSProperties => {
    const style: CSSProperties = {
      width: getPresetPropertyValue(listPresetProperty, propertyName, 'width'),
    };
    return style;
  };

  const propertyListViewNumberColumnStyle = (
    propertyName: string
  ): CSSProperties => {
    const value = numberColumnPreset?.columns?.find((v) => v === propertyName);
    if (value) {
      return {
        margin: '0 0 0 auto',
      };
    } else {
      return {};
    }
  };

  const ListNavgate = () => {
    return (
      <div className="navgate">
        <div className="count-number">
          {(page.allItems ?? []).length}件中
          <label htmlFor="selectItems" className="label">
            <Selectbox
              name="filterItem"
              items={[
                { value: '10', displayName: '10' },
                { value: '20', displayName: '20' },
                { value: '30', displayName: '30' },
                { value: '50', displayName: '50' },
              ]}
              value={String(page.pageSize)}
              columns={['filterItem']}
              multiple={false}
              onChangeState={(arg) => {
                handleChangePageSize(arg[0]);
              }}
            />
          </label>
          <span className="unit">件表示</span>
        </div>
        <div className="pages-btns">
          <IconButton
            name="firstPageIcon"
            className="list-icon"
            buttonType="basic"
            iconType="first"
            onClick={() => handleMovePage(1)}
          />
          <IconButton
            name="prevIcon"
            className="list-icon"
            buttonType="basic"
            onClick={() => handleMovePage(page.pageNumber - 1)}
            iconType="prev"
          />
          <span className="page-counter">
            {page.pageNumber} / {page.maxPageNumber}
          </span>
          <IconButton
            name="nextIcon"
            className="list-icon"
            buttonType="basic"
            onClick={() => handleMovePage(page.pageNumber + 1)}
            iconType="next"
          />
          <IconButton
            name="lastPageIcon"
            className="list-icon"
            buttonType="basic"
            onClick={() => handleMovePage(page.maxPageNumber)}
            iconType="last"
          />
          {listViewChecker('reload') && (
            <IconButton
              name="headReloadIcon"
              className="list-icon"
              buttonType="basic"
              onClick={() => {
                handleReload();
              }}
              iconType="refresh"
            />
          )}
          {listViewChecker('output') && (
            <IconButton
              name="headDownloadIcon"
              className="list-icon"
              buttonType="basic"
              onClick={() => {
                handleDownload();
              }}
              iconType="download"
            />
          )}
        </div>
      </div>
    );
  };

  // header部分のチェックボックス処理
  const handleOnChangeHeaderSelect = (headerSelected: string[]) => {
    const tmpSelected: string[] = [];
    [...Array(page.pageSize)].forEach(() => {
      tmpSelected.push(headerSelected[0] === '1' ? '1' : '');
    });
    setHeaderSelect(headerSelected);
    // 呼出元画面に返す
    onParentChangeState(tmpSelected);
    page.originalItems && onParentChangeCheckItemsState(page.originalItems);
    if (headerSelected[0] === '1') {
      const lsCheckedValue = getLocalStorageCheckboxData(
        headerTitle?.viewId ?? '',
        myEmail
      );

      const checkedValue = page.items
        .filter((item) => {
          const id = item[menuTarget as keyof typeof item];
          return !lsCheckedValue.includes(id);
        })
        .map((item) => {
          return item[menuTarget as keyof typeof item];
        });
      lsCheckedValue.push(...checkedValue);
      headerTitle?.viewId &&
        saveLocalStorageCheckboxData(
          headerTitle.viewId,
          lsCheckedValue,
          myEmail
        );
    } else {
      initializeLocalStorageCheckboxData();
    }
  };

  // バーガーメニューリスト関係のView
  const burgerMenuView = (item: DummyUser) => {
    if (listBurgermenu.length === 0) {
      return <></>;
    }

    return listBurgermenu.map((v, index) => {
      let isViewMenu = true;
      const properties = v.property ?? [];
      if (properties.length === 0) {
        isViewMenu = true;
      } else {
        const chooseProperty = properties.filter((p) => {
          const targetValue = item[p.propertyName as keyof typeof item];
          return targetValue === p.propertyValue;
        });
        isViewMenu = chooseProperty.length > 0 ? true : false;
      }
      if (!isViewMenu) return <li key={index} className="empty"></li>;
      return (
        <li key={index} className={'menu-item'}>
          <a
            onClick={() => {
              v.func([item[menuTarget as keyof typeof item]]);
              // GoogleAnalyticsへデータ送信
              exportGoogleAnalytics('burger_menu_item', {
                targetName: `${fullMethodName}_${v.displayName}`,
                documentIds: [item[menuTarget as keyof typeof item]],
              });
            }}
          >
            {v.displayName}
          </a>
        </li>
      );
    });
  };

  const isValidMenuValue = (menu: Menu, ids: string[]): boolean => {
    if (
      ids.length === 0 &&
      !NO_CHECK_ACTION_MENU_NAME.includes(menu.menuName)
    ) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000023',
        }),
      ]);
      return false;
    }

    return true;
  };

  const isDataView = () => {
    return (
      properties.findIndex((property) => property.name === 'データ未選択') < 0
    );
  };

  return (
    <>
      <div className="ListView">
        {listViewChecker() && areaTitle && (
          <h3 className="area-title">{areaTitle}</h3>
        )}
        {listViewChecker('filter') && (
          <FilterView
            viewId={headerTitle?.viewId}
            filterValue={filterValue}
            filterPresetItem={filterPresetItem}
            onSubmitFilter={onSubmitFilter}
            initialFilter={getInitialFilter()}
          />
        )}
        <div className="list-header">
          <ListNavgate />
        </div>
        <div ref={contentsRef} className="contents">
          <div className="list-caption">
            <div style={listViewStyle}>
              {listViewChecker('menu') && isDataView() && (
                <div className="list-header-cell burgermenu">
                  <ul className="list">
                    <li className="menu-box" ref={headerMenuBoxRef}>
                      <a href="#">
                        <HeaderMenu />
                      </a>
                      <ul
                        className="menu-list"
                        style={{
                          maxHeight: `${Number(maxMenuColumn ?? '4') * 50}px`,
                        }}
                      >
                        {allBurgerMenu &&
                          allBurgerMenu.map((v, index) => {
                            return (
                              <li key={index} className="menu-item">
                                <a
                                  onClick={() => {
                                    const selectMenuIds = selectMenuId();
                                    if (!isValidMenuValue(v, selectMenuIds)) {
                                      return;
                                    }
                                    v.func(selectMenuIds);
                                  }}
                                >
                                  {[v.displayName]}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                  </ul>
                </div>
              )}
              {listViewChecker('check') && isDataView() && (
                <div className="list-header-cell check">
                  <Checkbox
                    name="all"
                    items={[{ value: '1', displayName: '' }]}
                    value={headerSelect}
                    onChangeState={(v) => {
                      handleOnChangeHeaderSelect(v);
                    }}
                    validateOption={{ isSkippedValidation: true }}
                  />
                </div>
              )}
              {properties.map((property) => (
                <div
                  className="list-header-cell"
                  key={property.name}
                  onClick={() => handleChangeSort(property.name)}
                  style={propertyListViewStyle(property.name)}
                >
                  <GetMessageComponent
                    prefixId={headerTitle?.prefixId ?? ''}
                    viewId={headerTitle?.viewId ?? ''}
                    id={property.name}
                  />
                  {sorterIndicator(property.name)}
                </div>
              ))}
            </div>
          </div>
          <div className="list-content">
            <div style={listViewStyle}>
              {page.items.map((item, i) => (
                <Fragment key={i}>
                  {listViewChecker('menu') && (
                    <div
                      className={`burgermenu item${
                        i % 2 === 0 ? ' even' : ' odd'
                      }`}
                    >
                      <ul className="list">
                        <li className="menu-box" ref={listMenuBoxRefs.get(i)}>
                          <a href="#">
                            <Menu className="menuIcon" />
                          </a>
                          <ul className="menu-list">{burgerMenuView(item)}</ul>
                        </li>
                      </ul>
                    </div>
                  )}
                  {listViewChecker('check') && (
                    <div
                      className={`item check${i % 2 === 0 ? ' even' : ' odd'}`}
                    >
                      <Checkbox
                        name={'list_' + i}
                        value={(() => {
                          const recordId =
                            item[props.menuTarget as keyof typeof item];
                          const lsCheckbox = getLocalStorageCheckboxData(
                            headerTitle?.viewId ?? '',
                            myEmail
                          );
                          return lsCheckbox.includes(recordId) ? ['1'] : [];
                        })()}
                        items={[{ value: '1', displayName: '' }]}
                        onChangeState={(prop: string[]) => {
                          const newListSelect: string[] = [];
                          const id =
                            item[props.menuTarget as keyof typeof item] ??
                            'none';
                          const checkedValues = getLocalStorageCheckboxData(
                            headerTitle?.viewId ?? '',
                            myEmail
                          );

                          if (prop.length > 0) {
                            checkedValues.push(id);
                            saveLocalStorageCheckboxData(
                              headerTitle?.viewId ?? '',
                              checkedValues,
                              myEmail
                            );
                            checkedValues.map((v) => {
                              newListSelect.push(v);
                            });
                          } else {
                            const exclusionIds = checkedValues.filter(
                              (cv) => cv !== id
                            );
                            saveLocalStorageCheckboxData(
                              headerTitle?.viewId ?? '',
                              exclusionIds,
                              myEmail
                            );
                            exclusionIds.map((v) => {
                              newListSelect.push(v);
                            });
                          }
                          onParentChangeState(newListSelect);
                          page.originalItems &&
                            onParentChangeCheckItemsState(page.originalItems);
                        }}
                        validateOption={{ isSkippedValidation: true }}
                      />
                    </div>
                  )}
                  {properties.map((property) => {
                    return (
                      <div
                        key={property.name}
                        className={`item${i % 2 === 0 ? ' even' : ' odd'}`}
                        style={propertyListViewStyle(property.name)}
                      >
                        <div
                          className="viewText"
                          style={propertyListViewNumberColumnStyle(
                            property.name
                          )}
                        >
                          {property.formatter!(item)}
                        </div>
                        <div className="description">
                          {property.formatter!(item)}
                        </div>
                      </div>
                    );
                  })}
                </Fragment>
              ))}
              {/* total item */}
              {listViewChecker('total') && isDataView() && (
                <>
                  <div className="item total"></div>
                  <div className="item total">合計</div>
                </>
              )}
              {listViewChecker('total') &&
                properties.map((property) => (
                  <div
                    key={property.name}
                    className="item total"
                    style={propertyListViewStyle(property.name)}
                  >
                    {totalValue(property.name)}
                  </div>
                ))}
              {listViewChecker('total') &&
                [...Array(page.pageSize - page.items.length)].map((_, i) => (
                  <Fragment key={`pad-${i}`}>
                    {properties.map((property) => (
                      <div key={property.name}>&nbsp;</div>
                    ))}
                  </Fragment>
                ))}
            </div>
          </div>
        </div>
        <div className="list-footer">
          <ListNavgate />
        </div>
      </div>
      {importDialogOption?.isDisplay && (
        <ImportDialog
          isOpen={importDialogOption.isDialogOpen}
          headerLabelId={importDialogOption!.headerLabelId}
          allIds={
            page.allItems
              ?.map((v) => v[menuTarget as keyof typeof v])
              .filter((v) => !!v) ?? []
          }
          ids={selectMenuId()}
          preset={importDialogOption!.dialogPreset}
          handleExport={importDialogOption!.handleExport}
          handleImport={importDialogOption!.handleImport}
          onChangeState={(v) => {
            importDialogOption.onChangeState(v);
          }}
          onHandleSuccess={(v) => {
            setImportSuccess(v);
          }}
          onChangeLoadingState={setLoading}
        ></ImportDialog>
      )}
      {isLoading && <LoadingIcon />}
    </>
  );
}
