import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  Container,
  GetMessage,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  success,
  error,
} from '~/shared/components';
import {
  ConfirmationDialog,
  DataFilterbox,
  DataFilterboxItem,
  PageNavigation,
  Textbox,
  Textarea,
  Checkbox,
  Filterbox,
  FileMemoDialogFormOption,
  FileUploadWithMemoDialog,
  FilterboxItem,
  Schema,
  getAltDisplaySchema,
  DateSuggest,
  ApprovalRequestDialog,
  Accordion,
} from '~/shared/components/ui';
import { IconButton } from '~/shared/components/ui/Button';
import { RadioButton } from '~/shared/components/ui/Radiobutton';
import {
  SimpleIconListView,
  IconMenuItem,
  SimpleListView,
} from '~/shared/components/ui/SimpleListView';
import {
  ExtendFileUploader,
  ExtendFileUploaderRef,
  FileItem,
} from '~/shared/components/file';
import { PresetItem } from '~/shared/services';
import { FilterExpression } from '~/worker';
import {
  FullMethodName_ListSharedContactContents,
  PageState,
  ViewId,
  getExceptionMessage,
  getWorkerExceptionMessage,
  getMaxMainContentsHeight,
  saveLocalStorageCheckboxData,
  includeInputValidateError,
  autoDownloadFileOnlyName,
  autoBulkDownload,
  AttachmentItems,
  createAttachmentFilesWithCodes,
  getRandomStringId,
  toCommaTypeString,
  createWorkTaskRelation,
  RequireInput,
  formatUserWIthEmail,
  getPathnameByViewId,
} from '~/shared/utils';
import {
  getProgramOptionFilterboxData,
  convertDatetime,
  convertDate,
  convertLongToNumber,
  convertUserReference,
  convertOrganizationStructureReference,
  convertOrganizationStructureReferenceToFilterboxItem,
} from '~/shared/utils/converter';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './EstEstimateRequestInput.css';
import { SearchListViewDialog } from '~/shared/components/ui/SearchListViewDialog';
import { OutputOption } from '~/shared/components/ui/Dialog';
import { useAuth } from '~/shared/contexts/AuthProvider';
import {
  DetailInputDialog,
  DetailInputDialogInputOption,
  EstimateDetailInfo,
} from '~/shared/components/ui/Dialog/DetailInputDialog';
import {
  listSharedContactContents,
  listUsers,
  listTransactionUnits,
  listEstimateRequestPlans,
  listEstimateManagements,
  listEstimateRequests,
  listEstimateRequestDetails,
  listEstimateRequestUnits,
} from '~/tenant/estimate/utils';
import Long from 'long';
import {
  FullMethodName_ListBusinessUnitBranchs,
  FullMethodName_ListOrganizations,
  FullMethodName_ListUserAttributes,
} from '~/worker';

/**
 * 連絡先
 */
interface BusinessUnitContactViewItem {
  id: string; // レコードキー
  businessUnitContactId: string; // 連絡先ID
  businessUnitManagementId: string; // 取引先管理ID
  code: string; // 取引先コード
  displayName: string; // 取引先名（連絡先s）
  contactData?: mtechnavi.api.company.ISharedContactContent | null; // 対応する連絡先データ
}
/**
 * 添付ファイル
 */
interface AttachmentViewItem {
  id: string; // レコードキー
  displayName: string; // 添付ファイル
  attribute: string; // 備考
  attachment: AttachmentItems; // 添付ファイル（ダウンロード用）
}
/**
 * 依頼明細
 */
interface EstimateRequestDetailViewItem {
  id: string; // レコードキー
  autoName: number; // 明細番号
  code: string; // 品目コード
  displayName: string; //品目名
  amount: string; // 数量/単位
  attribute: string; // 型式/図番
  process: string; // 工程
  attached: string; // 図面
  detailData: mtechnavi.api.estimation.IEstimateRequestDetail; // 対応する明細データ
}

const VIEW_ID: ViewId = 'EST_ESTIMATE_REQUEST_INPUT';

const MAX_REQUEST_UNIT = 10;
const MAX_ATTACHMENT = 10;
const MAX_REQUEST_DETAIL = 50;

const businessUnitContactColumns = [
  {
    header: { id: 'businessUnitContact.code', viewId: VIEW_ID },
    propertyName: 'code',
    width: '8rem',
  },
  {
    header: { id: 'businessUnitContact.displayName', viewId: VIEW_ID },
    propertyName: 'displayName',
  },
];

const attachmentColumns = [
  {
    header: { id: 'attachment.displayName', viewId: VIEW_ID },
    propertyName: 'displayName',
    width: '15rem',
  },
  {
    header: { id: 'attachment.attribute', viewId: VIEW_ID },
    propertyName: 'attribute',
    readonly: true,
  },
];

const estimateRequestDetailColumns = [
  {
    header: { id: 'estimateRequestDetail.autoName', viewId: VIEW_ID },
    propertyName: 'autoName',
    width: '6rem',
  },
  {
    header: { id: 'estimateRequestDetail.code', viewId: VIEW_ID },
    propertyName: 'code',
    width: '10rem',
  },
  {
    header: { id: 'estimateRequestDetail.displayName', viewId: VIEW_ID },
    propertyName: 'displayName',
    width: '20rem',
  },
  {
    header: { id: 'estimateRequestDetail.amount', viewId: VIEW_ID },
    propertyName: 'amount',
    width: '10rem',
  },
  {
    header: { id: 'estimateRequestDetail.attribute', viewId: VIEW_ID },
    propertyName: 'attribute',
    width: '15rem',
  },
  {
    header: { id: 'estimateRequestDetail.process', viewId: VIEW_ID },
    propertyName: 'process',
    width: '10rem',
  },
  {
    header: { id: 'estimateRequestDetail.attached', viewId: VIEW_ID },
    propertyName: 'attached',
    width: '4rem',
  },
];

export function EstEstimateRequestInput() {
  const sourcePageInfo = (useLocation().state as PageState) ?? [];
  const actionType = sourcePageInfo.actionType;
  const intl = useIntl();
  const myEmail = useAuth().user?.email ?? '';
  const [isLoading, setLoading] = useState(false);
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  const navi = useNavigate();
  const [backPageUrl, setBackPageUrl] = useState('');
  const loginUser = useAuth().user;
  const locationState = useLocation().state as PageState;

  const successMessage = GetMessage({ id: 'I0000001' });

  const viewMessageSave: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'temporarySave' }),
    },
  };

  const viewMessageDelete: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };

  const viewMessageBack: MessageProps = {
    id: 'confirmationDialogMessageBack',
    prefixId: VIEW_ID,
  };

  const viewMessageBusinessUnitContactDelete: MessageProps = {
    id: 'confirmationDialogMessageBusinessUnitContactDelete',
    prefixId: VIEW_ID,
  };

  const viewMessageBusinessUnitContactDeleteAll: MessageProps = {
    id: 'confirmationDialogMessageBusinessUnitContactDeleteAll',
    prefixId: VIEW_ID,
  };

  const viewMessageAttachmentDelete: MessageProps = {
    id: 'confirmationDialogMessageAttachmentDelete',
    prefixId: VIEW_ID,
  };

  const viewMessageAttachmentDeleteAll: MessageProps = {
    id: 'confirmationDialogMessageAttachmentDeleteAll',
    prefixId: VIEW_ID,
  };

  const radioDeliveryPointTypeItemValues = ['B01', 'B02'];
  const radioDeliveryPointTypeItems = [
    {
      value: radioDeliveryPointTypeItemValues[0],
      displayName: GetMessageWithIntl(intl, {
        prefixId: VIEW_ID,
        id: 'deliveryPointType.branch',
      }),
    },
    {
      value: radioDeliveryPointTypeItemValues[1],
      displayName: GetMessageWithIntl(intl, {
        prefixId: VIEW_ID,
        id: 'deliveryPointType.direct',
      }),
    },
  ];

  // カラムの読み替え処理
  const onHandleFormatSchema = (schema: Schema) => {
    // ja表示のみする処理
    const jaColumn = ['displayNameLang'];
    return getAltDisplaySchema(schema, jaColumn, 'ja');
  };

  // 見積依頼予定ID
  const estimateRequestPlanIds = useMemo(() => {
    if (locationState.sourceViewId !== 'EST_ESTIMATE_REQUEST_PLAN_LIST') {
      return null;
    }
    return locationState.ids;
  }, [locationState]);

  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 連絡先検索ダイアログ
  const [
    isOpenBusinessUnitContactSearchDialog,
    setOpenBusinessUnitContactSearchDialog,
  ] = useState(false);
  // ファイル追加ダイアログ
  const [isOpenFileUploadDialog, setOpenFileUploadDialog] = useState(false);
  // 明細登録ダイアログ
  const [isOpenDetailInputDialog, setOpenDetailInputDialog] = useState(false);
  const [detailInputInitialData, setDetailInputInitialData] =
    useState<DetailInputDialogInputOption>({
      inputMode: 'new',
      detailItem: {},
    });
  // 承認依頼ダイアログ
  const [isOpenApprovalRequestDialog, setOpenApprovalRequestDialog] =
    useState(false);

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] =
    useState<MessageProps>(viewMessageBack);
  const confirmPromiseRef =
    useRef<(value: boolean | PromiseLike<boolean>) => void>();

  // 組織マスタ
  const organizations = useRef<mtechnavi.api.company.IOrganization[]>([]);
  // 組織所属情報
  const organizationRelations = useRef<
    mtechnavi.api.company.IOrganizationRelation[]
  >([]);
  // ユーザーマスタ
  const users = useRef<mtechnavi.api.tenantadmin.IUserAttribute[]>([]);
  // 連絡先コンテンツ
  const sharedContactContents = useRef<
    mtechnavi.api.company.ISharedContactContent[]
  >([]);
  // 品目マスタ
  const transactionUnits = useRef<mtechnavi.api.blueprint.ITransactionUnit[]>(
    []
  );
  // 拠点マスタ
  const businessUnitBranchs = useRef<
    mtechnavi.api.company.IBusinessUnitBranch[]
  >([]);

  // 画面アイテム
  const [trackingCode, setTrackingCode] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [estimateRequestAutoName, setEstimateRequestAutoName] = useState('');
  const [estimateDeadlineDt, setEstimateDeadlineDt] = useState<Date | null>();
  const [desiredReceiveType, setDesiredReceiveType] = useState<
    DataFilterboxItem[]
  >([]);
  const [desiredReceiveDt, setDesiredReceiveDt] = useState<Date | null>();
  const [desiredReceiveRemarks, setDesiredReceiveRemarks] = useState('');
  const [orderPlanDt, setOrderPlanDt] = useState<Date | null>();
  const [orderPlanRemarks, setOrderPlanRemarks] = useState('');
  const [topPriorityContent, setTopPriorityContent] = useState<
    DataFilterboxItem[]
  >([]);
  const [purpose, setPurpose] = useState<DataFilterboxItem[]>([]);
  const [notice, setNotice] = useState('');
  const [deliveryPoint, setDeliveryPoint] = useState<DataFilterboxItem[]>([]);
  const [deliveryPostCode, setDeliveryPostCode] = useState<string>('');
  const [deliveryAddressLine, setDeliveryAddressLine] = useState<string>('');
  const [deliveryPhoneNumber, setDeliveryPhoneNumber] = useState<string>('');
  const [deliveryPlace, setDeliveryPlace] = useState<string>('');
  const [deliveryAttachedCheck, setDeliveryAttachedCheck] = useState<string[]>(
    []
  );
  const [contactOrganizationUnit, setContactOrganizationUnit] = useState<
    FilterboxItem[]
  >([]);
  const [contactStaff, setContactStaff] = useState<FilterboxItem[]>([]);
  const [requesterDepartment, setRequesterDepartment] = useState<string>('');
  const [requesterResponsible, setRequesterResponsible] = useState<string>('');
  const [systemNotificationUsers, setSystemNotificationUsers] = useState<
    DataFilterboxItem[]
  >([]);
  const [remarks, setRemarks] = useState<string>('');

  // 入力制御
  const [isDeliveryPointTypeRequired, setDeliveryPointTypeRequired] =
    useState(true);
  const [isDeliveryPointDisabled, setDeliveryPointDisabled] = useState(false);
  const [isDeliveryPostCodeDisabled, setDeliveryPostCodeDisabled] =
    useState(false);
  const [isDeliveryAddressLineDisabled, setDeliveryAddressLineDisabled] =
    useState(false);
  const [isDeliveryPhoneNumberDisabled, setDeliveryPhoneNumberDisabled] =
    useState(false);
  const [
    isDeliveryAttachmentDeleteDisabled,
    setDeliveryAttachmentDeleteDisabled,
  ] = useState<boolean>(false);
  const [
    isDeliveryAttachmentUploadDisabled,
    setDeliveryAttachmentUploadDisabled,
  ] = useState<boolean>(false);
  const [contactStaffCustomQuery, setContactStaffCustomQuery] =
    useState<FilterExpression>({});

  // 自社企業情報
  const myCompanyInfo = useRef<mtechnavi.api.company.ICompany>();

  // ラジオボタン
  const [deliveryPointTypeRadio, setDeliveryPointTypeRadio] = useState(
    radioDeliveryPointTypeItemValues[0]
  );

  // 取得データ格納用 見積管理データ
  const estimateManagementData =
    useRef<mtechnavi.api.estimation.IEstimateManagement>({});
  // 取得データ格納用 見積依頼データ
  const estimateRequestData = useRef<mtechnavi.api.estimation.IEstimateRequest>(
    {}
  );
  // 取得データ格納用 見積依頼明細データ
  const estimateRequestDetailData = useRef<
    mtechnavi.api.estimation.IEstimateRequestDetail[]
  >([]);
  // 取得データ格納用 見積依頼送信先データ
  const estimateRequestUnitData = useRef<
    mtechnavi.api.estimation.IEstimateRequestUnit[]
  >([]);

  // 入力チェック用
  const requiredDisplayNameArea = useRef(null);
  const requiredDeliveryPoint = useRef(null);
  const [workingBlurDeliperyPoint, setWorkingBlurDeliperyPoint] =
    useState<Date>();

  //アップロード処理
  const uploaderRef = useRef<ExtendFileUploaderRef>(null);
  const [isUploadingContain, setUploadingContain] = useState(false);
  const [isErrorContain, setErrorContain] = useState(false);
  const [tempFileList, setTempFileList] = useState<FileItem[]>([]);
  const [isFileUploaderLoading, setFileUploaderLoading] = useState(false);
  const executeCreateWorkTaskRelation = useRef(true);

  // 連絡先リスト (表示用)
  const [businessUnitContactViewItem, setBusinessUnitContactViewItem] =
    useState<BusinessUnitContactViewItem[]>([]);

  // 添付ファイルリスト (表示用)
  const [attachmentViewItem, setAttachmentViewItem] = useState<
    AttachmentViewItem[]
  >([]);

  // 依頼明細（表示用）
  const [estimateRequestDetails, setEstimateRequestDetails] = useState<
    EstimateRequestDetailViewItem[]
  >([]);

  // DataFilterbox用(名称マスタ)
  const DesiredReceiveTypeCode = 'A7000001';
  const TopPriorityContentCode = 'A7000003';
  const PurposeContentCode = 'A7000004';
  const [desiredReceiveTypes, topPriorityContents, purposes] = useMemo<
    [DataFilterboxItem[], DataFilterboxItem[], DataFilterboxItem[]]
  >(() => {
    const desiredReceiveTypes = getProgramOptionFilterboxData(
      DesiredReceiveTypeCode
    );
    const topPriorityContents = getProgramOptionFilterboxData(
      TopPriorityContentCode
    );
    const purposes = getProgramOptionFilterboxData(PurposeContentCode);
    return [desiredReceiveTypes, topPriorityContents, purposes];
  }, []);

  // filterbox用(問合せ組織)
  const managementItemType = {
    value: 'organizationId',
    displayName: 'displayName',
  };
  // filterbox用(担当者)
  const staffItemType = {
    value: 'user.email',
    displayName: 'user.displayName',
  };

  // filterbox用(納入先、拠点)
  const deliveryPointItemType = {
    value: 'businessUnitBranchId',
    displayName: 'displayName',
  };

  // DataFilterbox用(システム通知先)
  const systemNotificationUsersList = useMemo(
    () =>
      users.current
        ? users.current!.map(
            (v): FilterboxItem => ({
              displayName: formatUserWIthEmail(
                v.user?.displayName,
                v.user?.email
              ),
              value: v.user?.userId ?? '',
            })
          ) ?? []
        : [],
    // 想定通りの挙動なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users.current]
  );

  // 依頼明細のアイコンヘッダメニュー
  const getRequestDetailIconHeaderMenuItem = (): IconMenuItem[] => {
    const iconItems: IconMenuItem[] = [];
    iconItems.push({
      name: 'noteadd',
      displayName: '追加',
      func: () => {
        handleAddRequestDetail();
      },
    });

    return iconItems;
  };

  // 依頼明細のアイコンメニュー
  const getRequestDetailIconMenuItem = (): IconMenuItem[] => {
    const iconItems: IconMenuItem[] = [];
    iconItems.push({
      name: 'edit',
      displayName: '編集',
      func: (v: EstimateRequestDetailViewItem) => {
        handleEditRequestDetail(v);
      },
    });
    iconItems.push({
      name: 'delete',
      displayName: '削除',
      func: (v: EstimateRequestDetailViewItem) => {
        handleDeleteRequestDetail(v);
      },
    });

    return iconItems;
  };

  /**
   * 連絡先検索ダイアログ選択時処理
   */
  const handleSearchSelect = (
    contacts: mtechnavi.api.company.ISharedContactContent[]
  ) => {
    if (contacts.length === 0) {
      return;
    }

    if (
      contacts &&
      contacts.length + businessUnitContactViewItem.length > MAX_REQUEST_UNIT
    ) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000138',
          value: { $1: MAX_REQUEST_UNIT },
        }),
      ]);
      return;
    }

    const listViewItems: BusinessUnitContactViewItem[] = [];
    contacts.forEach((contact) => {
      const duplicateId = businessUnitContactViewItem.some(
        (w) =>
          w.businessUnitContactId ===
          contact.businessUnitContact?.businessUnitContactId
      );
      if (duplicateId) {
        return;
      }

      // 表示用に加工して追加
      listViewItems.push({
        id: contact.businessUnitContact?.businessUnitContactId ?? '',
        businessUnitContactId:
          contact.businessUnitContact?.businessUnitContactId ?? '',
        businessUnitManagementId:
          contact.businessUnitManagement?.businessUnitManagementId ?? '',

        code: contact.businessUnitManagement?.code ?? '',
        displayName: contact.displayName ?? '',
        contactData: contact,
      });
    });

    setBusinessUnitContactViewItem(
      sortBusinessUnitContact([
        ...businessUnitContactViewItem,
        ...listViewItems,
      ])
    );

    clearCheckBox();
    setOpenBusinessUnitContactSearchDialog(false);
  };

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData('SHARED_CONTACT_CONTENTS_SEARCH', [], myEmail);
  }, [myEmail]);

  // 画面サイズ変更時の高さ調整
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  // 画面データ取得
  useEffect(() => {
    setLoading(true);

    //非表示項目リセット
    estimateManagementData.current = {};
    estimateRequestData.current = {};
    estimateRequestDetailData.current = [];
    estimateRequestUnitData.current = [];

    // 戻るボタンURLセット
    setBackPageUrl(
      getPathnameByViewId(sourcePageInfo.sourceViewId)?.path ?? ''
    );

    (async () => {
      try {
        // 自社情報を取得
        myCompanyInfo.current =
          await window.App.services.companyService.getCompany({});
        // 共通で取得するもの
        const [
          { presetItem },
          listOrganizationRes,
          listOrganizationRelationRes,
          listSharedContactContentsRes,
          listUsersRes,
          listTransactionUnitsRes,
          listBusinessUnitBranchsRes,
        ] = await Promise.all([
          window.App.services.ui.getViewIdPreset(VIEW_ID), // プリセット取得
          window.App.services.ui.listOrganization(), // 組織リスト
          window.App.services.ui.listOrganizationRelation(), // 組織所属情報
          listSharedContactContents(), // 連絡先コンテンツ
          listUsers(), // ユーザーマスタ
          listTransactionUnits(), // 品目マスタ
          listBusinessUnitBranchs(), // 拠点マスタ
        ]);
        setPreset(presetItem);
        organizations.current = listOrganizationRes || [];
        organizationRelations.current = listOrganizationRelationRes || [];
        sharedContactContents.current =
          listSharedContactContentsRes.items || [];
        users.current = listUsersRes || [];
        transactionUnits.current = listTransactionUnitsRes?.items || [];
        businessUnitBranchs.current = listBusinessUnitBranchsRes.items || [];

        // 新規登録モード（見積依頼一覧から追加）
        if (actionType === 'add') {
          userToViews(loginUser);
        }
        // 見積依頼予定一覧から見積依頼予定を選択して登録
        if (
          actionType === 'add' &&
          sourcePageInfo.sourceViewId === 'EST_ESTIMATE_REQUEST_PLAN_LIST'
        ) {
          if (!estimateRequestPlanIds) return;
          // 見積依頼予定から取得
          const [listEstimateRequestPlansRes] = await Promise.all([
            listEstimateRequestPlans(estimateRequestPlanIds ?? [], false),
          ]);
          const plans: mtechnavi.api.estimation.IEstimateRequestPlan[] = [];

          const firstPlan = listEstimateRequestPlansRes.items.find(
            (v) => v.estimateRequestPlanId === estimateRequestPlanIds[0]
          );
          const otherPlan = listEstimateRequestPlansRes.items.filter(
            (v) => v.estimateRequestPlanId !== estimateRequestPlanIds[0]
          );

          if (firstPlan) {
            plans.push(firstPlan);
          }
          if (otherPlan.length) {
            plans.push(...otherPlan);
          }

          // 画面項目のセット
          estimatePlanToViews(estimateRequestPlanIds[0], plans);
          userToViews(loginUser);
        }
        // 編集モード（見積依頼確認から遷移）
        if (actionType === 'edit') {
          const [
            listEstimateManagementsRes,
            listEstimateRequestsRes,
            listEstimateRequestDetailsRes,
            listEstimateRequestUnitsRes,
          ] = await Promise.all([
            listEstimateManagements(sourcePageInfo.ids ?? [], false),
            listEstimateRequests(sourcePageInfo.ids ?? [], false),
            listEstimateRequestDetails(sourcePageInfo.ids ?? [], false),
            listEstimateRequestUnits(sourcePageInfo.ids ?? [], false),
          ]);

          // 非表示項目のセット
          estimateManagementData.current =
            listEstimateManagementsRes.items.at(0) || {};
          estimateRequestData.current =
            listEstimateRequestsRes.items.at(0) || {};
          estimateRequestDetailData.current =
            listEstimateRequestDetailsRes.items;
          estimateRequestUnitData.current = listEstimateRequestUnitsRes.items;

          // 画面項目のセット
          estimateDataToViews(
            estimateManagementData.current,
            estimateRequestData.current,
            estimateRequestDetailData.current,
            estimateRequestUnitData.current
          );
        }
        // 複写モード
        if (actionType === 'copy') {
          const [listEstimateRequestsRes, listEstimateRequestDetailsRes] =
            await Promise.all([
              listEstimateRequests(sourcePageInfo.ids ?? [], true),
              listEstimateRequestDetails(sourcePageInfo.ids ?? [], true),
            ]);

          // 非表示項目のセット
          estimateRequestData.current =
            listEstimateRequestsRes.items.at(0) || {};
          estimateRequestDetailData.current =
            listEstimateRequestDetailsRes.items;

          // 複写処理のみクリア処理
          clearItemForCopy();

          // 画面項目のセット
          estimateDataToViews(
            estimateManagementData.current,
            estimateRequestData.current,
            estimateRequestDetailData.current,
            estimateRequestUnitData.current
          );
          userToViews(loginUser);
        }
      } catch (err) {
        error(getExceptionMessage(intl, err));
      } finally {
        setLoading(false);
      }
    })();

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listBusinessUnitBranchs = async () => {
    const listRes = (await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: FullMethodName_ListBusinessUnitBranchs,
      filter: {},
      requestBody: {
        companyIds: [myCompanyInfo.current?.companyId as string],
      },
      sort: [],
    })) as mtechnavi.api.company.ListBusinessUnitBranchsResponse;

    return new Promise<mtechnavi.api.company.ListBusinessUnitBranchsResponse>(
      (resolve, reject) => {
        const firstItem = listRes?.items.length > 0 ? listRes?.items[0] : {};
        if (firstItem?.businessUnitBranchId === '') {
          reject({ id: 'E0000070' });
          return;
        }
        resolve(listRes);
      }
    );
  };

  /**
   * 複写時にクリアする項目のセット処理
   */
  const clearItemForCopy = () => {
    estimateRequestData.current = {
      ...estimateRequestData.current,
      estimateRequestId: null,
      estimateRequestAutoName: null,
      trackingCode: null,
      estimateDeadlineDt: null,
      desiredReceiveType: null,
      desiredReceiveDt: null,
      desiredReceiveRemarks: null,
      orderPlanDt: null,
      orderPlanRemarks: null,
      contactOrganization: null,
      contactStaff: null,
      sendedAt: null,
      sharedProperties: null,
      createdProperties: null,
      updatedProperties: null,
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    };

    estimateRequestDetailData.current.forEach((v) => {
      v.estimateRequestDetailId = getRandomStringId(); // 明細特定に使用するため一時IDを採番する
      v.estimateRequestId = null;
      v.createdAt = null;
      v.updatedAt = null;
      v.deletedAt = null;
    });
  };

  /**
   * ユーザー情報からの画面データセット
   * 新規時のみ使用する。
   */
  const userToViews = (user: mtechnavi.api.idp.IUser | undefined) => {
    if (!user) return;

    const initialStaff = users.current.find(
      (v) => v?.user?.email === loginUser?.email
    );

    const filterboxItemContactStaff = initialStaff
      ? [
          {
            value: initialStaff.user?.email ?? '',
            displayName: initialStaff.user?.displayName ?? '',
          },
        ]
      : [];
    setContactStaff(filterboxItemContactStaff);

    (async () => {
      const userRepresentativeOrg =
        await window.App.services.ui.getUserRepresentativeOrganization(
          loginUser?.email
        );
      setContactOrganizationUnit(
        userRepresentativeOrg
          ? [
              {
                displayName: userRepresentativeOrg?.displayName ?? '',
                value: userRepresentativeOrg?.organizationId ?? '',
              },
            ]
          : [
              {
                displayName: 'aaaaa',
                value: 'gggg',
              },
            ]
      );
    })();

    const usersFilterBoxItems: FilterboxItem[] = [
      {
        displayName: formatUserWIthEmail(user.displayName, user.email),
        value: user.userId ?? '',
      },
    ];
    setSystemNotificationUsers(usersFilterBoxItems);
  };

  /**
   * 見積依頼予定からの画面データセット
   * 新規時のみ使用する。
   */
  const estimatePlanToViews = (
    mainEstimateRequestPlanId: string,
    estimateRequestPlans: mtechnavi.api.estimation.IEstimateRequestPlan[]
  ) => {
    if (!estimateRequestPlans.length) return;

    const mainEstimateRequestPlan = estimateRequestPlans.find(
      (v) => v.estimateRequestPlanId === mainEstimateRequestPlanId
    );

    setTrackingCode(mainEstimateRequestPlan?.sapWorkOrder?.trackingCode ?? '');
    setDisplayName(mainEstimateRequestPlan?.displayNameLang?.ja ?? '');
    const desiredReceiveDate = convertDate(
      mainEstimateRequestPlan?.sapWorkOrder?.desiredReceiveDt || null
    );
    setDesiredReceiveDt(desiredReceiveDate);
    setRequesterDepartment(
      mainEstimateRequestPlan?.sapWorkOrder?.requesterProperties
        ?.organizationUnit?.displayNameLang?.ja ?? ''
    );
    setRequesterResponsible(
      mainEstimateRequestPlan?.sapWorkOrder?.requesterProperties?.staff
        ?.displayNameLang?.ja ?? ''
    );

    let detailAutoNameCounter = 0;
    const details: mtechnavi.api.estimation.IEstimateRequestDetail[] = [];
    estimateRequestPlans.forEach((plan) => {
      // Implementation Note:
      //
      //  同じレコードの図番と型式が同じ番号が登録されている場合、複数レコード生成される
      //  異なるレコードの図番と型式が同じ番号が登録されている場合、複数レコード生成される
      //
      const trans_blueprintNumber = transactionUnits.current.filter(
        (v) =>
          plan.sapWorkOrder?.freeItem &&
          v.blueprintReference?.blueprintNumber &&
          v.blueprintReference?.blueprintNumber === plan.sapWorkOrder?.freeItem
      );
      const trans_modelNumber = transactionUnits.current.filter(
        (v) =>
          plan.sapWorkOrder?.freeItem &&
          v.catalogReference?.modelNumber &&
          v.catalogReference?.modelNumber === plan.sapWorkOrder?.freeItem
      );
      const trans = [...trans_blueprintNumber, ...trans_modelNumber];
      const quantityUnits = getProgramOptionFilterboxData('A0000005'); // 名称マスタ（数量単位）のフィルタボックス対象
      const pranUnit = quantityUnits
        ? window.App.services.ui.getNameOptionWithCode(
            'A0000005',
            quantityUnits.find(
              (v) => v.displayName === plan.sapWorkOrder?.quantity?.unit
            )?.value ?? ''
          )
        : {};
      trans.forEach((tran) => {
        detailAutoNameCounter += 1;
        details.push({
          estimateRequestDetailId: getRandomStringId(),
          estimateRequestId: null,
          detailAutoName: Long.fromNumber(detailAutoNameCounter),
          transactionUnit: {
            transactionUnitId: tran.transactionUnitId,
            displayNameLang: tran.displayNameLang?.ja,
            transactionUnitCode: tran.transactionUnitCode,
          },
          displayName: tran.displayNameLang?.ja,
          quantity: {
            unit: pranUnit,
            amountNum: plan.sapWorkOrder?.quantity?.amount,
          },
          inspection: null,
          blueprint: {
            blueprintNumber: tran.blueprintReference?.blueprintNumber,
          },
          attachment: null,
          processs: tran.processs,
          modelNumber: tran.catalogReference?.modelNumber,
          manufacture: tran.catalogReference?.manufacture,
          contactMatter: tran.contactMatter,
          suppliedParts: null,
          suppliesDt: null,
          suppliesRemarks: null,
          requiredMaterialCost: null,
          requiredProcessingCost: null,
          requiredAdministrativeCost: null,
          requiredFreightCost: null,
          sharedProperties: null,
          createdAt: null,
          updatedAt: null,
          deletedAt: null,
        });
      });
    });
    estimateRequestDetailData.current = details;

    const newList = [
      ...estimateRequestDetailData.current.map((v) => {
        return {
          id: v.estimateRequestDetailId,
          autoName: convertLongToNumber(v.detailAutoName),
          code: v.transactionUnit?.transactionUnitCode,
          displayName: v.displayName,
          amount: [
            toCommaTypeString(v.quantity?.amountNum),
            v.quantity?.unit?.displayNameLang?.ja,
          ].join(''),
          attribute:
            v.modelNumber && v.blueprint?.blueprintNumber
              ? [v.modelNumber, v.blueprint?.blueprintNumber].join('/')
              : [v.modelNumber, v.blueprint?.blueprintNumber].join(''),
          process:
            v.processs?.map((v) => v.displayNameLang?.ja).join(',') ?? '',
          attached: (v.attachment?.assetId ?? '') !== '' ? '○' : '-',
          detailData: v,
        } as EstimateRequestDetailViewItem;
      }),
    ];
    setEstimateRequestDetails(newList);
  };

  /**
   * 見積関連データからの画面データセット
   */
  const estimateDataToViews = (
    management: mtechnavi.api.estimation.IEstimateManagement,
    request: mtechnavi.api.estimation.IEstimateRequest,
    details: mtechnavi.api.estimation.IEstimateRequestDetail[],
    units: mtechnavi.api.estimation.IEstimateRequestUnit[]
  ) => {
    estimateManagementToViews(management);
    estimateRequestToViews(request);
    estimateRequestDetailsToViews(details);
    estimateRequestUnitsToViews(units);
  };

  /**
   * 見積管理データからの画面データセット
   */
  const estimateManagementToViews = (
    management: mtechnavi.api.estimation.IEstimateManagement
  ) => {
    const usersFilterBoxItems: FilterboxItem[] = (
      management.systemNotificationUsers || []
    ).map((v) => ({
      displayName: formatUserWIthEmail(v.displayName, v.email),
      value: v.userId ?? '',
    }));
    setSystemNotificationUsers(usersFilterBoxItems);
    setRemarks(management.remarks ?? '');
  };

  /**
   * 見積依頼データからの画面データセット
   */
  const estimateRequestToViews = (
    request: mtechnavi.api.estimation.IEstimateRequest
  ) => {
    setTrackingCode(request.trackingCode ?? '');
    setDisplayName(request.displayName ?? '');
    setEstimateRequestAutoName(
      request.estimateRequestAutoName
        ? Long.fromValue(request.estimateRequestAutoName).toString(10)
        : ''
    );
    const estimateDeadlineDate = convertDate(
      request.estimateDeadlineDt || null
    );
    setEstimateDeadlineDt(estimateDeadlineDate);
    const filterboxItemDesiredReceiveType = request.desiredReceiveType
      ? [
          {
            value: request.desiredReceiveType?.code ?? '',
            displayName: request.desiredReceiveType?.displayNameLang?.ja ?? '',
          },
        ]
      : [];
    setDesiredReceiveType(filterboxItemDesiredReceiveType);
    const desiredReceiveDate = convertDate(request.desiredReceiveDt || null);
    setDesiredReceiveDt(desiredReceiveDate);
    setDesiredReceiveRemarks(request.desiredReceiveRemarks ?? '');
    const orderPlanDate = convertDate(request.orderPlanDt || null);
    setOrderPlanDt(orderPlanDate);
    setOrderPlanRemarks(request.orderPlanRemarks ?? '');
    const filterboxItemTopPriorityContent = request.topPriorityContent
      ? [
          {
            value: request.topPriorityContent?.code ?? '',
            displayName: request.topPriorityContent?.displayNameLang?.ja ?? '',
          },
        ]
      : [];
    setTopPriorityContent(filterboxItemTopPriorityContent);
    const filterboxItemPurpose = request.purpose
      ? [
          {
            value: request.purpose?.code ?? '',
            displayName: request.purpose?.displayNameLang?.ja ?? '',
          },
        ]
      : [];
    setPurpose(filterboxItemPurpose);
    setNotice(request.notice ?? '');
    if (
      request.deliveryPointType?.systemName?.includes(
        radioDeliveryPointTypeItemValues[1]
      )
    ) {
      setDeliveryPointTypeRadio(radioDeliveryPointTypeItemValues[1]);
    } else {
      setDeliveryPointTypeRadio(radioDeliveryPointTypeItemValues[0]);
    }
    const filterboxItemDeliveryPoint = request.deliveryPoint
      ? [
          {
            value: request.deliveryPoint?.businessUnitBranchId ?? '',
            displayName: request.deliveryPoint?.displayName ?? '',
          },
        ]
      : [];
    setDeliveryPoint(filterboxItemDeliveryPoint);
    setDeliveryPostCode(request.deliveryAddress?.postalCode ?? '');
    setDeliveryAddressLine(request.deliveryAddress?.addressLine ?? '');
    setDeliveryPhoneNumber(request.deliveryPhoneNumber ?? '');
    setDeliveryPlace(request.deliveryPlace ?? '');
    if (request.deliveryImage?.assetId || request.deliveryAttachment?.assetId) {
      setDeliveryAttachedCheck(['1']);
    }

    const filterboxItemContactOrganizationUnit =
      convertOrganizationStructureReferenceToFilterboxItem(
        request.contactOrganization
      );
    setContactOrganizationUnit(
      filterboxItemContactOrganizationUnit
        ? [filterboxItemContactOrganizationUnit]
        : []
    );
    const filterboxItemContactStaff = request.contactStaff
      ? [
          {
            value: request.contactStaff?.email ?? '',
            displayName: request.contactStaff?.displayName ?? '',
          },
        ]
      : [];
    setContactStaff(filterboxItemContactStaff);
    setRequesterDepartment(request.requesterDepartment ?? '');
    setRequesterResponsible(request.requesterResponsible ?? '');

    // 添付ファイル
    const newList: AttachmentViewItem[] = [];
    request.attachments?.forEach((v) => {
      newList.push({
        id: v.assetId ?? '',
        displayName: v.filename ?? '',
        attribute: v.remarks ?? '',
        attachment: v,
      } as AttachmentViewItem);
    });
    setAttachmentViewItem(newList);
  };

  /**
   * 見積依頼明細データからの画面データセット
   */
  const estimateRequestDetailsToViews = (
    details: mtechnavi.api.estimation.IEstimateRequestDetail[]
  ) => {
    const newList = [
      ...details.map((v) => {
        return {
          id: v.estimateRequestDetailId,
          autoName: convertLongToNumber(v.detailAutoName),
          code: v.transactionUnit?.transactionUnitCode,
          displayName: v.displayName,
          amount: [
            toCommaTypeString(v.quantity?.amountNum),
            v.quantity?.unit?.displayNameLang?.ja,
          ].join(''),
          attribute:
            v.modelNumber && v.blueprint?.blueprintNumber
              ? [v.modelNumber, v.blueprint?.blueprintNumber].join('/')
              : [v.modelNumber, v.blueprint?.blueprintNumber].join(''),
          process:
            v.processs?.map((v) => v.displayNameLang?.ja).join(',') ?? '',
          attached: (v.attachment?.assetId ?? '') !== '' ? '○' : '-',
          detailData: v,
        } as EstimateRequestDetailViewItem;
      }),
    ];
    setEstimateRequestDetails(newList);
  };

  /**
   * 見積依頼送信先データからの画面データセット
   */
  const estimateRequestUnitsToViews = (
    estimateRequestUnints: mtechnavi.api.estimation.IEstimateRequestUnit[]
  ) => {
    if (!estimateRequestUnints) return;

    // 取引先を連絡先単位に展開して保持する
    const listViewItems = estimateRequestUnints?.flatMap((v) =>
      (v.businessUnitContactIds || []).map(
        (contactId): BusinessUnitContactViewItem => {
          const contactData = sharedContactContents.current.find(
            (item) =>
              item.businessUnitContact?.businessUnitContactId === contactId
          );
          return {
            id: contactId ?? '',
            businessUnitContactId: contactId ?? '',
            businessUnitManagementId: v.businessUnitManagementId ?? '',
            code: v.code ?? '',
            displayName: contactData?.displayName ?? '',
            contactData: contactData,
          };
        }
      )
    );
    setBusinessUnitContactViewItem(sortBusinessUnitContact(listViewItems));
  };

  /**
   * 自社拠点変更時の画面データセット
   */
  const deliveryPointDataToViews = (
    deliveryPointDataList: mtechnavi.api.company.IBusinessUnitBranch[],
    deliveryPointItems: DataFilterboxItem[]
  ) => {
    if (deliveryPointItems.length > 0) {
      (async () => {
        const deliveryPointItem = deliveryPointDataList?.find(
          (item) => item.businessUnitBranchId === deliveryPointItems[0].value
        );
        if (!deliveryPointItem) {
          return;
        }

        if (deliveryPointItem?.businessUnitBranchId === '') {
          setLoading(false);
          error([GetMessageWithIntl(intl, { id: 'E0000070' })]);
          return;
        }
        setDeliveryPostCode(deliveryPointItem.address?.postalCode ?? '');
        const joinedAddressLines =
          deliveryPointItem.address?.addressLines &&
          deliveryPointItem.address?.addressLines.join('');
        setDeliveryAddressLine(
          (deliveryPointItem.address?.region?.displayNameLang?.ja ?? '') +
            joinedAddressLines ?? ''
        );
        setDeliveryPhoneNumber(deliveryPointItem.phoneNumber ?? '');
      })();
    }
  };

  /**
   * 依頼送信先のソート
   */
  const sortBusinessUnitContact = (data: BusinessUnitContactViewItem[]) => {
    return data.sort((val1, val2) => {
      return val1.code.localeCompare(val2.code, 'ja');
    });
  };

  /**
   * 納入先変更時の処理
   */
  const handleChangeDeliveryPointType = (v: string) => {
    handleClearDeliveryPoint();
    setDeliveryPointTypeRadio(v);
  };

  /**
   * 自社拠点変更時の処理
   */
  const handleChangeDeliveryPoint = (deliveryPoints: FilterboxItem[]) => {
    setDeliveryPoint(deliveryPoints);
    // 地図情報削除
    setDeliveryAttachedCheck([]);
    estimateRequestData.current = {
      ...estimateRequestData.current,
      deliveryAttachment: null,
    };

    deliveryPointDataToViews(businessUnitBranchs.current || [], deliveryPoints);
  };

  // 表示用の連絡先リストから保存用の取引先リストに変換する
  const contactListToRequestUnits = (
    items: BusinessUnitContactViewItem[]
  ): mtechnavi.api.estimation.IEstimateRequestUnit[] => {
    const resultList: mtechnavi.api.estimation.IEstimateRequestUnit[] = [];
    items.forEach(
      ({ businessUnitContactId, businessUnitManagementId, contactData }) =>
        contactItemToRequestUnit(
          resultList,
          businessUnitContactId,
          businessUnitManagementId,
          contactData
        )
    );

    return resultList;
  };

  const contactItemToRequestUnit = (
    workingResultList: mtechnavi.api.estimation.IEstimateRequestUnit[],
    businessUnitContactId: string,
    businessUnitManagementId: string,
    contactData?: mtechnavi.api.company.ISharedContactContent | null
  ) => {
    const duplicateRequestUnit = workingResultList.find(
      (item) => item.businessUnitManagementId === businessUnitManagementId
    );

    if (duplicateRequestUnit) {
      // 重複した取引先
      // 連絡先IDも重複していたらスキップ
      if (
        duplicateRequestUnit.businessUnitContactIds?.includes(
          businessUnitContactId
        )
      ) {
        return;
      }
      // 重複していない連絡先IDを追加
      duplicateRequestUnit.businessUnitContactIds = [
        ...(duplicateRequestUnit.businessUnitContactIds || []),
        businessUnitContactId,
      ];
      // 連絡先ユーザを追加
      contactData?.businessUnitContact?.notificationUsers?.forEach(
        (notifyUser) => {
          if (!notifyUser.user) {
            return;
          }
          if (
            duplicateRequestUnit.contactUsers?.every(
              (contact) => contact.email !== notifyUser.user?.email
            )
          ) {
            duplicateRequestUnit.contactUsers.push(notifyUser.user);
          }
        }
      );
    } else {
      // 重複していない取引先
      const requestUnit: mtechnavi.api.estimation.IEstimateRequestUnit = {
        businessUnitManagementId:
          contactData?.businessUnitManagement?.businessUnitManagementId ?? '',
        businessUnitContactIds: [
          contactData?.businessUnitContact?.businessUnitContactId || '',
        ],
        companyId: contactData?.businessUnitManagement?.companyId ?? '',
        code: contactData?.businessUnitManagement?.code ?? '',
        displayName: contactData?.businessUnitManagement?.displayName ?? '',
        contactUsers: (
          contactData?.businessUnitContact?.notificationUsers || []
        ).map((notifyUser) => notifyUser.user || {}),
      };
      workingResultList.push(requestUnit);
    }
  };

  // データ保存時の入力項目エラーチェック
  const isInputErrorCheck = () => {
    setWorkingBlurDeliperyPoint(new Date());

    const requiredItem: RequireInput[] = [];
    requiredItem.push({
      value: displayName ?? '',
      ref: requiredDisplayNameArea,
    });
    if (deliveryPointTypeRadio === radioDeliveryPointTypeItemValues[0]) {
      const deliveryPointValue =
        deliveryPoint.length > 0 ? deliveryPoint[0].value : '';
      requiredItem.push({
        value: deliveryPointValue,
        ref: requiredDeliveryPoint,
      });
    }

    // 入力エラーチェック
    const targetEle = document.querySelector('.scroll-main-contents-area');
    if (includeInputValidateError(targetEle, intl, requiredItem)) {
      return true;
    }
    return false;
  };

  // 依頼明細入力チェック
  const isRequestDetailInputErrorCheck = () => {
    // 依頼明細の必須チェック
    if (
      estimateRequestDetails.find(
        (v) =>
          !v.detailData.quantity?.amountNum ||
          !v.detailData.quantity?.unit?.systemName
      )
    ) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000140',
        }),
      ]);
      return true;
    }
    return false;
  };

  // 依頼明細入力チェック(承認依頼時)
  const isRequestDetailApprovalRequestErrorCheck = () => {
    // 図面未選択チェック
    if (
      estimateRequestDetails.find(
        (v) =>
          v.detailData.blueprint?.blueprintNumber &&
          !v.detailData.blueprint?.revision
      )
    ) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000141',
        }),
      ]);
      return true;
    }

    // 見積依頼先未選択チェック
    if (businessUnitContactViewItem.length < 1) {
      error([GetMessageWithIntl(intl, { id: 'E0000084' })]);
      return true;
    }

    if (estimateRequestDetails.length > MAX_REQUEST_DETAIL) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000139',
          value: { $1: MAX_REQUEST_DETAIL },
        }),
      ]);
      return true;
    }

    return false;
  };

  // データの永続化
  const saveAction = async () => {
    // システム通知先
    const selectedSystemNotificationUsers: sharelib.IUserReference[] = [];
    systemNotificationUsers.forEach((v) => {
      const selectedUser = (users.current ?? []).find(
        (w) => w.userAttributeId === v.value
      );
      if (selectedUser) {
        selectedSystemNotificationUsers.push({
          userId: selectedUser.user?.userId,
          email: selectedUser.user?.email,
          displayName: selectedUser.user?.displayName,
        });
      }
    });

    // 拠点
    const selectedDeliveryPoint =
      deliveryPoint.length > 0
        ? ({
            businessUnitBranchId: deliveryPoint[0].value,
            displayName: deliveryPoint[0].displayName,
          } as mtechnavi.api.estimation.IBusinessUnitBranchReference)
        : null;
    const selectedContactOrganizationUnit = (organizations.current ?? []).find(
      (v) => v.organizationId === contactOrganizationUnit.at(0)?.value
    );
    const selectedContactStaff = (users.current ?? []).find(
      (v) => v.user?.email === contactStaff.at(0)?.value
    );

    const reqEstimateManagement: mtechnavi.api.estimation.IEstimateManagement =
      {
        estimateManagementId:
          estimateManagementData.current.estimateManagementId,
        systemNotificationUsers: selectedSystemNotificationUsers,
        remarks: remarks,
        status: null,
        approvalStatus: null,
        approvalRequest: null,
        approvalResult: null,
        issuer: null,
        createdAt: estimateManagementData.current.createdAt,
        updatedAt: estimateManagementData.current.updatedAt,
        deletedAt: null,
      };

    const reqEstimateRequest: mtechnavi.api.estimation.IEstimateRequest = {
      estimateRequestId: estimateRequestData.current.estimateRequestId,
      estimateRequestAutoName:
        estimateRequestData.current.estimateRequestAutoName,
      trackingCode: trackingCode,
      displayName: displayName,
      estimateDeadlineDt: convertDatetime(
        estimateDeadlineDt || null,
        'YYYY/MM/DD'
      ),
      desiredReceiveType: desiredReceiveType[0]
        ? window.App.services.ui.getNameOptionWithCode(
            'A7000001',
            desiredReceiveType[0].value ?? ''
          )
        : null,
      desiredReceiveDt: convertDatetime(desiredReceiveDt || null, 'YYYY/MM/DD'),
      desiredReceiveRemarks: desiredReceiveRemarks,
      orderPlanDt: convertDatetime(orderPlanDt || null, 'YYYY/MM/DD'),
      orderPlanRemarks: orderPlanRemarks,
      topPriorityContent: topPriorityContent[0]
        ? window.App.services.ui.getNameOptionWithCode(
            'A7000003',
            topPriorityContent[0].value ?? ''
          )
        : null,
      purpose: purpose[0]
        ? window.App.services.ui.getNameOptionWithCode(
            'A7000004',
            purpose[0].value ?? ''
          )
        : null,
      notice: notice,
      deliveryPointType: deliveryPointTypeRadio
        ? window.App.services.ui
            .getNameOptionWithSystemName('A7000005', deliveryPointTypeRadio)
            .at(0)
        : null,
      deliveryPoint: selectedDeliveryPoint,
      deliveryAddress: {
        countryCode: '81',
        postalCode: deliveryPostCode,
        addressLine: deliveryAddressLine,
      },
      deliveryPhoneNumber: deliveryPhoneNumber,
      deliveryPlace: deliveryPlace,
      deliveryImage: estimateRequestData.current.deliveryImage,
      deliveryAttachment: estimateRequestData.current.deliveryAttachment,
      contactOrganization: await convertOrganizationStructureReference(
        selectedContactOrganizationUnit?.organizationId ?? ''
      ),
      contactStaff: await convertUserReference(
        selectedContactStaff?.user?.email ?? ''
      ),
      requesterDepartment: requesterDepartment,
      requesterResponsible: requesterResponsible,
      attachments: attachmentViewItem.map((v) => v.attachment),
      requestingCompany: null,
      sendedAt: null,
      sharedProperties: null,
      createdProperties: null,
      updatedProperties: null,
      createdAt: estimateRequestData.current.createdAt,
      updatedAt: estimateRequestData.current.updatedAt,
      deletedAt: null,
    };
    const reqEstimateRequestDetails: mtechnavi.api.estimation.IEstimateRequestDetail[] =
      [];

    estimateRequestDetails.forEach((detail) => {
      const estimateDetail: mtechnavi.api.estimation.IEstimateRequest = {
        ...detail.detailData,
        // FEで作成したIDは念の為削除する
        estimateRequestId: detail.detailData.updatedAt
          ? detail.detailData.estimateRequestId
          : '',
      };
      reqEstimateRequestDetails.push(estimateDetail);
    });

    const reqEstimateRequestUnits: mtechnavi.api.estimation.IEstimateRequestUnit[] =
      contactListToRequestUnits(businessUnitContactViewItem);

    const requestData = {
      estimateManagement: reqEstimateManagement,
      estimateRequest: reqEstimateRequest,
      estimateRequestDetails: reqEstimateRequestDetails,
      estimateRequestUnits: reqEstimateRequestUnits,
    };

    const res = (await window.App.services.ui.worker.apiCall({
      actionName: 'saveEstimateManagementWithRelatedItems',
      request: requestData,
    })) as mtechnavi.api.estimation.ISaveEstimateManagementWithRelatedItemsResponse;

    return res;
  };

  // 画面情報の再取得
  const reloadDisplay = async (estimateManagementId: string) => {
    if (!estimateManagementId) return;
    const [
      listEstimateManagementsRes,
      listEstimateRequestsRes,
      listEstimateRequestDetailsRes,
      listEstimateRequestUnitsRes,
    ] = await Promise.all([
      listEstimateManagements([estimateManagementId], true),
      listEstimateRequests([estimateManagementId], true),
      listEstimateRequestDetails([estimateManagementId], true),
      listEstimateRequestUnits([estimateManagementId], true),
    ]);

    // 非表示項目のセット
    estimateManagementData.current =
      listEstimateManagementsRes.items.at(0) || {};
    estimateRequestData.current = listEstimateRequestsRes.items.at(0) || {};
    estimateRequestDetailData.current =
      listEstimateRequestDetailsRes.items || [];
    estimateRequestUnitData.current = listEstimateRequestUnitsRes.items || [];

    // 画面項目のセット
    estimateDataToViews(
      estimateManagementData.current,
      estimateRequestData.current,
      estimateRequestDetailData.current,
      estimateRequestUnitData.current
    );
  };

  // 一時保存
  const handleSave = async () => {
    // 入力エラーチェック
    if (isInputErrorCheck()) {
      return;
    }
    // 依頼明細入力チェック
    if (isRequestDetailInputErrorCheck()) {
      return;
    }
    // 確認処理
    if (!(await confirmation(viewMessageSave))) {
      return;
    }
    setLoading(true);
    try {
      const res =
        (await saveAction()) as mtechnavi.api.estimation.ISaveEstimateManagementWithRelatedItemsResponse[];
      estimateManagementData.current = res.at(0)?.estimateManagement ?? {};
      if (
        estimateManagementData.current.estimateManagementId &&
        executeCreateWorkTaskRelation.current
      ) {
        await createWorkTaskRelation(
          [estimateManagementData.current.estimateManagementId],
          sourcePageInfo.beforeStateIds ?? [],
          sourcePageInfo.baseViewOption?.sourceViewId ?? null,
          'estimate'
        );
        executeCreateWorkTaskRelation.current = false;
      }
      success([successMessage]);
      setOpenConfirmDialog(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      // reload処理 画面情報の再取得
      await reloadDisplay(
        estimateManagementData.current.estimateManagementId ?? ''
      );
      setLoading(false);
    }
  };

  const handleApprovalRequest = async (result: OutputOption) => {
    setLoading(true);
    try {
      // 保存処理
      const res =
        (await saveAction()) as mtechnavi.api.estimation.ISaveEstimateManagementWithRelatedItemsResponse[];
      if (
        res.length > 0 &&
        res[0].estimateManagement?.estimateManagementId &&
        executeCreateWorkTaskRelation.current
      ) {
        await createWorkTaskRelation(
          [res[0].estimateManagement.estimateManagementId],
          sourcePageInfo.beforeStateIds ?? [],
          sourcePageInfo.baseViewOption?.sourceViewId ?? null,
          'estimate'
        );
        executeCreateWorkTaskRelation.current = false;
      }
      // 選択値を担当者情報に変換
      const selectedStaff =
        (await convertUserReference(
          result?.approvalOrganizationStaff[0]?.value || ''
        )) || {};

      // 管理場所マスタから取得
      // 選択値を管理場所情報に変換
      const selectedOrganization =
        (await convertOrganizationStructureReference(
          result?.approvalOrganizationUnit[0]?.value || ''
        )) || {};

      await window.App.services.ui.worker.apiCall({
        actionName: 'approvalRequestEstimateManagement',
        request: {
          estimateManagementId:
            res.length > 0
              ? res[0].estimateManagement?.estimateManagementId ?? ''
              : '',
          approvalPlanStaff: res.length > 0 ? selectedStaff : {},
          approvalPlanOrganization: res.length > 0 ? selectedOrganization : {},
          comment: result.comment,
        },
      });

      success([successMessage]);
      setOpenApprovalRequestDialog(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    // 一覧に戻る
    backToBaseViewPage();
  };

  /**
   * 見積依頼先の削除
   */
  const handleDeleteBusinessUnitContact = async (
    item: BusinessUnitContactViewItem
  ) => {
    if (!(await confirmation(viewMessageBusinessUnitContactDelete))) {
      return;
    }
    setBusinessUnitContactViewItem(
      businessUnitContactViewItem?.filter((v) => v.id !== item.id)
    );
  };

  /**
   * 見積依頼先の全削除
   */
  const handleAllDeleteBusinessUnitContact = async () => {
    if (!(await confirmation(viewMessageBusinessUnitContactDeleteAll))) {
      return;
    }
    setBusinessUnitContactViewItem([]);
  };

  /**
   * 添付ファイルの追加
   */
  const handleAddAttachment = async (result: FileMemoDialogFormOption) => {
    if (!result.files) return;
    if (
      attachmentViewItem &&
      attachmentViewItem.length + result.files?.length > MAX_ATTACHMENT
    ) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000077',
          value: { $1: MAX_ATTACHMENT },
        }),
      ]);
      return;
    }
    setLoading(true);

    const files = result.files.map((file) => ({
      id: `${file.filename ?? ''}`,
      category: {},
      assetId: '',
      filename: `${file.filename ?? ''}`,
      url: file.url,
      mimeType: '',
      remarks: '',
      linkType: {},
    }));

    try {
      const tmpData = await createAttachmentFilesWithCodes(
        files,
        'B11',
        'B02',
        'B01'
      );
      if (tmpData.length > 0) {
        const newList: AttachmentViewItem[] = [];
        tmpData.forEach((v) => {
          newList.push({
            id: v.assetId,
            displayName: v.filename,
            attribute: result.memo,
            attachment: { ...v, remarks: result.memo ?? '' },
          } as AttachmentViewItem);
        });

        setAttachmentViewItem([...attachmentViewItem, ...newList]);
      } else {
        setAttachmentViewItem([...attachmentViewItem]);
      }
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * 添付ファイルの削除
   */
  const handleDeleteAttachment = async (item: AttachmentViewItem) => {
    if (!(await confirmation(viewMessageAttachmentDelete))) {
      return;
    }
    setAttachmentViewItem(attachmentViewItem?.filter((v) => v.id !== item.id));
  };

  /**
   * 添付ファイルの全削除
   */
  const handleAllDeleteAttachment = async () => {
    if (!(await confirmation(viewMessageAttachmentDeleteAll))) {
      return;
    }
    setAttachmentViewItem([]);
  };

  /**
   * 添付ファイル一覧の行クリック
   */
  const handleRowClickAttachment = (item: AttachmentViewItem) => {
    autoDownloadFileOnlyName(
      item.attachment.filename ?? '',
      item.attachment.assetId ?? ''
    );
  };

  /**
   * 添付ファイル一覧の全件ダウンロード
   */
  const handleFullDownloadAttachment = () => {
    autoBulkDownload(
      (attachmentViewItem ?? []).map((item) => item.attachment.assetId ?? ''),
      intl,
      VIEW_ID
    );
  };

  /**
   * 明細番号の再採番
   */
  const numberingDetailAutoName = (
    details: EstimateRequestDetailViewItem[]
  ) => {
    let autoName = 0;
    const result = details.map((v) => {
      autoName += 1;
      return {
        ...v,
        autoName: autoName,
        detailData: {
          ...v.detailData,
          detailAutoName: Long.fromValue(autoName),
        },
      };
    });
    setEstimateRequestDetails(result);
  };

  /**
   * 明細番号の最大値の取得
   */
  const getMaxDetailAutoName = (): Long.Long => {
    const detailAutoNames = estimateRequestDetails.map(
      (v) => v.detailData.detailAutoName
    );
    return (
      detailAutoNames
        .sort((a, b) => {
          const start = Long.fromValue(a || 0).toNumber();
          const end = Long.fromValue(b || 0).toNumber();
          return start < end ? 1 : -1;
        })
        .at(0) ?? Long.ZERO
    );
  };

  /**
   * 依頼明細の追加
   */
  const handleAddRequestDetail = () => {
    if (estimateRequestDetails.length >= MAX_REQUEST_DETAIL) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000139',
          value: { $1: MAX_REQUEST_DETAIL },
        }),
      ]);
      return;
    }
    setDetailInputInitialData({
      inputMode: 'new',
      detailItem: {
        estimateRequestDetailId: getRandomStringId(),
        detailAutoName: Long.fromNumber(
          Long.fromValue(getMaxDetailAutoName()).toNumber() + 1
        ),
      },
    });
    setOpenDetailInputDialog(true);
  };

  /**
   * 依頼明細の編集
   */
  const handleEditRequestDetail = (
    targetItem: EstimateRequestDetailViewItem
  ) => {
    setDetailInputInitialData({
      inputMode: 'edit',
      detailItem: {
        ...targetItem.detailData,
        filterboxItemTransactionUnit: {
          displayName:
            targetItem.detailData.transactionUnit?.displayNameLang ?? '',
          value: targetItem.detailData.transactionUnit?.transactionUnitId ?? '',
        },
        filterboxItemQuantityUnit: [
          {
            displayName:
              targetItem.detailData.quantity?.unit?.displayNameLang?.ja ?? '',
            value: targetItem.detailData.quantity?.unit?.code ?? '',
          },
        ],
        filterboxItemProcesss:
          targetItem.detailData.processs?.map((v) => {
            return {
              displayName: v.displayNameLang?.ja ?? '',
              value: v.code ?? '',
            };
          }) ?? [],
      },
    });
    setOpenDetailInputDialog(true);
  };

  /**
   * 依頼明細の確定時処理
   */
  const handleRequestDetailDecision = (result: EstimateDetailInfo) => {
    const detailData: mtechnavi.api.estimation.IEstimateRequestDetail = {
      ...result,
    };
    const newData: EstimateRequestDetailViewItem = {
      id: result.estimateRequestDetailId ?? '',
      autoName: convertLongToNumber(result.detailAutoName) ?? 0,
      code: result.transactionUnit?.transactionUnitCode ?? '',
      displayName: result.displayName ?? '',
      amount: [
        toCommaTypeString(result.quantity?.amountNum),
        result.quantity?.unit?.displayNameLang?.ja,
      ].join(''),
      attribute:
        result.modelNumber && result.blueprint?.blueprintNumber
          ? [result.modelNumber, result.blueprint?.blueprintNumber].join('/')
          : [result.modelNumber, result.blueprint?.blueprintNumber].join(''),
      process:
        result.processs?.map((v) => v.displayNameLang?.ja).join(',') ?? '',
      attached: (result.attachment?.assetId ?? '') !== '' ? '○' : '-',
      detailData: detailData,
    };
    const targetIndex = estimateRequestDetails.findIndex(
      (detail) => detail.id === newData.id
    );
    if (targetIndex === -1) {
      setEstimateRequestDetails([...estimateRequestDetails, newData]);
      return;
    }
    const newList = [...estimateRequestDetails];
    newList.splice(targetIndex, 1, newData);
    setEstimateRequestDetails(newList);
  };

  /**
   * 依頼明細の削除
   */
  const handleDeleteRequestDetail = async (
    deleteItem: EstimateRequestDetailViewItem
  ) => {
    if (!(await confirmation(viewMessageDelete))) {
      return;
    }
    const newList = [...estimateRequestDetails];
    const targetIndex = estimateRequestDetails.findIndex(
      (item) => item.id === deleteItem.id
    );
    if (targetIndex === -1) {
      return;
    }
    newList.splice(targetIndex, 1);
    numberingDetailAutoName(newList);
  };

  /**
   * 地図アップロード処理
   */
  const handleUploadedDeliveryAttachement = (fileList: FileItem[]) => {
    setUploadingContain(fileList.some((item) => item.status === 'uploading'));
    setErrorContain(fileList.some((item) => item.status === 'failure'));
    setTempFileList(fileList);
  };

  // アセット作成処理
  const createAsset = async () => {
    if (isUploadingContain) return;
    if (isErrorContain) {
      error([GetMessageWithIntl(intl, { id: 'E0000103' })]);
      return;
    }
    setLoading(true);

    const files = tempFileList.map((file) => ({
      id: `${file.file.name ?? ''}`,
      category: {},
      assetId: '',
      filename: `${file.file.name ?? ''}`,
      url: file.url,
      mimeType: '',
      remarks: '',
      linkType: {},
    }));

    try {
      const tmpData = await createAttachmentFilesWithCodes(
        files,
        'B11',
        'B02',
        'B01'
      );
      if (tmpData.length > 0) {
        estimateRequestData.current = {
          ...estimateRequestData.current,
          deliveryAttachment: tmpData[0],
        };
        setDeliveryAttachmentDeleteDisabled(false);
      } else {
        estimateRequestData.current = {
          ...estimateRequestData.current,
          deliveryAttachment: null,
        };
        setDeliveryAttachmentDeleteDisabled(false);
      }
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // ファイル添付削除時処理
  const handleDeleteDeliveryAttachment = async () => {
    if (!(await confirmation(viewMessageDelete))) {
      return;
    }
    // 保存行為はしないので、Attachmentのクリア処理
    estimateRequestData.current = {
      ...estimateRequestData.current,
      deliveryAttachment: null,
    };
    uploaderRef.current?.clear();
  };

  // 納入先関連項目クリア処理
  const handleClearDeliveryPoint = () => {
    // クリア処理
    setDeliveryPoint([]);
    setDeliveryPostCode('');
    setDeliveryAddressLine('');
    setDeliveryPhoneNumber('');

    // 非表示項目のクリア処理
    estimateRequestData.current = {
      ...estimateRequestData.current,
      deliveryImage: null,
      deliveryAttachment: null,
    };
  };

  // 地図情報変更時
  useEffect(() => {
    if (
      estimateRequestData.current.deliveryImage?.assetId ||
      estimateRequestData.current.deliveryAttachment?.assetId
    ) {
      setDeliveryAttachedCheck(['1']);
    } else {
      setDeliveryAttachedCheck([]);
    }
  }, [
    estimateRequestData.current.deliveryImage,
    estimateRequestData.current.deliveryAttachment,
  ]);

  // 納入先変更時
  useEffect(() => {
    if (deliveryPointTypeRadio === radioDeliveryPointTypeItemValues[0]) {
      setDeliveryPointTypeRequired(true);
      // 活性制御
      setDeliveryPointDisabled(false);
      setDeliveryPostCodeDisabled(true);
      setDeliveryAddressLineDisabled(true);
      setDeliveryPhoneNumberDisabled(true);
      setDeliveryAttachmentDeleteDisabled(true);
      setDeliveryAttachmentUploadDisabled(false);
    } else {
      setDeliveryPointTypeRequired(false);
      setWorkingBlurDeliperyPoint(undefined);
      // 活性制御
      setDeliveryPointDisabled(true);
      setDeliveryPostCodeDisabled(false);
      setDeliveryAddressLineDisabled(false);
      setDeliveryPhoneNumberDisabled(false);
      setDeliveryAttachmentDeleteDisabled(false);
      setDeliveryAttachmentUploadDisabled(false);
    }

    // deliveryPointTypeRadio 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryPointTypeRadio]);

  // ファイルアップロード時
  // handleUploadedが複数回実行されているため、直接createAssetをすると不要な呼び出しが実行される
  // 対応策としてtempFileListをの変更を検知してアセットを作成している
  useEffect(() => {
    if (tempFileList.length > 0) {
      createAsset();
    }
    // tempFileList変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempFileList]);

  // 問合せ部門変更時
  useEffect(() => {
    if (contactOrganizationUnit.length === 0) {
      setContactStaffCustomQuery({});
      return;
    }
    const users = organizationRelations.current.filter(
      (item) => contactOrganizationUnit[0].value === item.organizationId
    );
    const query: FilterExpression = users
      ? {
          'user.userId': {
            $in:
              users.map((item) => {
                return item.userId ?? '';
              }) ?? [],
          },
        }
      : {};
    setContactStaffCustomQuery(query);
  }, [contactOrganizationUnit]);

  useEffect(() => {
    if (contactStaff.length > 0) {
      const selectedStaff = users.current.find(
        (v) => v.user?.email === contactStaff[0].value
      );
      const representativeOrg = selectedStaff?.representativeOrganization;
      if (
        contactOrganizationUnit.length === 0 &&
        representativeOrg?.organizationId
      ) {
        const setItem = organizations.current.filter(
          (item) => item.organizationId === representativeOrg.organizationId
        );
        setContactOrganizationUnit(
          setItem.length > 0
            ? [
                {
                  displayName: setItem[0].displayName ?? '',
                  value: setItem[0].organizationId ?? '',
                },
              ]
            : []
        );
        return;
      }
    }
    // contactStaff 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactStaff]);

  /**
   * 確認ダイアログ処理
   * Promise で結果を制御する。
   * 確定: true / キャンセル: false
   */
  const confirmation = (viewMessage: MessageProps): Promise<boolean> => {
    setOpenConfirmDialog(true);
    setConfirmMessage(viewMessage);
    return new Promise((resolve) => {
      confirmPromiseRef.current = resolve;
    });
  };

  /**
   * 確認ダイアログの確認処理
   */
  const handleConfirmed = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(true);
    }
    setOpenConfirmDialog(false);
  };

  /**
   * 確認ダイアログのキャンセル処理
   */
  const handleCancel = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(false);
    }
    setOpenConfirmDialog(false);
  };

  // 戻るページ
  const backToPreviewPage = async () => {
    if (!(await confirmation(viewMessageBack))) {
      return;
    }
    const state: PageState = {
      ids: sourcePageInfo.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.naviFilters,
      beforeStateIds: sourcePageInfo.beforeStateIds,
      baseViewOption: sourcePageInfo.baseViewOption,
      confirmationViewOption: sourcePageInfo.confirmationViewOption,
    };
    navi(backPageUrl, { state });
  };

  // 一覧に戻る
  const backToBaseViewPage = () => {
    const state: PageState = {
      ids: sourcePageInfo.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.naviFilters,
      beforeStateIds: sourcePageInfo.beforeStateIds,
      baseViewOption: sourcePageInfo.baseViewOption,
    };

    // 戻り先に遷移元(一覧)情報をセット
    //  遷移先不明の場合は見積依頼一覧に戻る
    const backPath =
      getPathnameByViewId(sourcePageInfo.baseViewOption?.sourceViewId)?.path ??
      '/estimate/est-estimate-request-list';

    navi(backPath, { state });
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="EstEstimateRequestInput">
          <div className="header">
            <PageNavigation
              backpagePath={backPageUrl}
              pageInfo={{
                isVisibleMoveNavi: false,
              }}
              infoOption={{
                lastUpdateInfo: { isVisibleUpdateInfo: false },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={backToPreviewPage}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            {/* 公開情報（基本情報） */}
            <Accordion title={GetMessage({ id: 'request', prefixId: VIEW_ID })}>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <Textbox
                      name="trackingCode"
                      className="field"
                      value={trackingCode}
                      type="text"
                      labelId="EST_ESTIMATE_REQUEST_INPUT.trackingCode"
                      columns={preset.columns}
                      onChangeState={setTrackingCode}
                    />
                  </div>
                  <div className="w-40" ref={requiredDisplayNameArea}>
                    <Textbox
                      name="displayName"
                      className="field"
                      value={displayName}
                      type="text"
                      labelId="EST_ESTIMATE_REQUEST_INPUT.displayName"
                      columns={preset.columns}
                      onChangeState={setDisplayName}
                      validateOption={{ required: true }}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="estimateRequestAutoName"
                      className="field"
                      value={estimateRequestAutoName}
                      type="text"
                      labelId="EST_ESTIMATE_REQUEST_INPUT.estimateRequestAutoName"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <DateSuggest
                      labelId="EST_ESTIMATE_REQUEST_INPUT.estimateDeadlineDt"
                      name="estimateDeadlineDt"
                      columns={preset.columns}
                      value={estimateDeadlineDt}
                      onChangeState={setEstimateDeadlineDt}
                    ></DateSuggest>
                  </div>
                  <div className="w-20">
                    <DataFilterbox
                      data={desiredReceiveTypes}
                      name="desiredReceiveType"
                      labelId="EST_ESTIMATE_REQUEST_INPUT.desiredReceiveType"
                      columns={preset.columns}
                      searchOption={{ targets: 'displayName' }}
                      onChangeState={setDesiredReceiveType}
                      value={desiredReceiveType}
                    />
                  </div>
                  <div className="w-20">
                    <DateSuggest
                      labelId="EST_ESTIMATE_REQUEST_INPUT.desiredReceiveDt"
                      name="desiredReceiveDt"
                      columns={preset.columns}
                      value={desiredReceiveDt}
                      onChangeState={setDesiredReceiveDt}
                    ></DateSuggest>
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="desiredReceiveRemarks"
                      className="field"
                      value={desiredReceiveRemarks}
                      type="text"
                      labelId="EST_ESTIMATE_REQUEST_INPUT.desiredReceiveRemarks"
                      columns={preset.columns}
                      onChangeState={setDesiredReceiveRemarks}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <DateSuggest
                      labelId="EST_ESTIMATE_REQUEST_INPUT.orderPlanDt"
                      name="orderPlanDt"
                      columns={preset.columns}
                      value={orderPlanDt}
                      onChangeState={setOrderPlanDt}
                    ></DateSuggest>
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="orderPlanRemarks"
                      className="field"
                      value={orderPlanRemarks}
                      type="text"
                      labelId="EST_ESTIMATE_REQUEST_INPUT.orderPlanRemarks"
                      columns={preset.columns}
                      onChangeState={setOrderPlanRemarks}
                    />
                  </div>
                  <div className="w-20">
                    <DataFilterbox
                      data={topPriorityContents}
                      name="topPriorityContent"
                      labelId="EST_ESTIMATE_REQUEST_INPUT.topPriorityContent"
                      columns={preset.columns}
                      searchOption={{ targets: 'displayName' }}
                      onChangeState={setTopPriorityContent}
                      value={topPriorityContent}
                    />
                  </div>
                  <div className="w-20">
                    <DataFilterbox
                      data={purposes}
                      name="purpose"
                      labelId="EST_ESTIMATE_REQUEST_INPUT.purpose"
                      columns={preset.columns}
                      searchOption={{ targets: 'displayName' }}
                      onChangeState={setPurpose}
                      value={purpose}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-50">
                    <Textarea
                      labelId="EST_ESTIMATE_REQUEST_INPUT.notice"
                      name="notice"
                      className="w-100 mh-middle"
                      value={notice}
                      columns={preset.columns}
                      onChangeState={setNotice}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <RadioButton
                      labelId="EST_ESTIMATE_REQUEST_INPUT.deliveryPointType"
                      name="deliveryPointType"
                      columns={preset.columns}
                      className="group"
                      items={radioDeliveryPointTypeItems}
                      value={deliveryPointTypeRadio}
                      onChangeState={handleChangeDeliveryPointType}
                    ></RadioButton>
                  </div>
                  <div className="w-20" ref={requiredDeliveryPoint}>
                    <Filterbox
                      name="deliveryPoint"
                      fullMethodName={FullMethodName_ListBusinessUnitBranchs}
                      itemType={deliveryPointItemType}
                      columns={['deliveryPoint']}
                      labelId="EST_ESTIMATE_REQUEST_INPUT.deliveryPoint"
                      validateOption={{
                        required: isDeliveryPointTypeRequired,
                        isSkippedValidation: !isDeliveryPointTypeRequired,
                      }}
                      value={deliveryPoint ?? [{ value: '', displayName: '' }]}
                      searchOption={{
                        targets: 'displayName',
                        customQuery: {
                          companyId: {
                            $eq: myCompanyInfo.current?.companyId ?? '',
                          },
                        },
                      }}
                      onChangeState={handleChangeDeliveryPoint}
                      disabled={isDeliveryPointDisabled}
                      workingBlur={workingBlurDeliperyPoint}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <Textbox
                      name="deliveryPostCode"
                      className="field"
                      value={deliveryPostCode}
                      type="text"
                      labelId="EST_ESTIMATE_REQUEST_INPUT.deliveryPostCode"
                      columns={preset.columns}
                      onChangeState={setDeliveryPostCode}
                      disabled={isDeliveryPostCodeDisabled}
                    />
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="deliveryAddressLine"
                      className="field"
                      value={deliveryAddressLine}
                      type="text"
                      labelId="EST_ESTIMATE_REQUEST_INPUT.deliveryAddressLine"
                      columns={preset.columns}
                      onChangeState={setDeliveryAddressLine}
                      disabled={isDeliveryAddressLineDisabled}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="deliveryPhoneNumber"
                      className="field"
                      value={deliveryPhoneNumber}
                      type="text"
                      labelId="EST_ESTIMATE_REQUEST_INPUT.deliveryPhoneNumber"
                      columns={preset.columns}
                      onChangeState={setDeliveryPhoneNumber}
                      disabled={isDeliveryPhoneNumberDisabled}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-40">
                    <Textbox
                      name="deliveryPlace"
                      className="field"
                      value={deliveryPlace}
                      type="text"
                      labelId="EST_ESTIMATE_REQUEST_INPUT.deliveryPlace"
                      columns={preset.columns}
                      onChangeState={setDeliveryPlace}
                    />
                  </div>
                  <div className="w-50 attachment-area">
                    <div className="attached-checkbox">
                      <Checkbox
                        name="deliveryAttachedCheck"
                        items={[
                          {
                            value: '1',
                            displayName: GetMessageWithIntl(intl, {
                              prefixId: 'EST_ESTIMATE_REQUEST_INPUT',
                              id: 'deliveryAttachedCheck',
                            }),
                          },
                        ]}
                        columns={['deliveryAttachedCheck']}
                        value={deliveryAttachedCheck}
                        onChangeState={setDeliveryAttachedCheck}
                        disabled={true}
                      />
                    </div>
                    <div className="delete-icon">
                      <IconButton
                        name="delete"
                        iconType="delete"
                        onClick={handleDeleteDeliveryAttachment}
                        disabled={
                          isDeliveryAttachmentDeleteDisabled ||
                          !estimateRequestData.current.deliveryAttachment
                            ?.assetId ||
                          isLoading ||
                          isFileUploaderLoading
                        }
                      />
                    </div>
                    <div className="upload-button">
                      <ExtendFileUploader
                        name="fileUploader"
                        messageOption={{
                          uploadLabelId: {
                            prefixId: 'EST_ESTIMATE_REQUEST_INPUT',
                            id: 'deliveryAttachment.upload',
                          },
                          omitAttachedIcon: true,
                        }}
                        ref={uploaderRef}
                        dndOption={{
                          enabled: false,
                        }}
                        multiple={false}
                        validateOption={{
                          maxFileSizeInMebis: 50,
                        }}
                        resultOption={{
                          previewRowCount: 0,
                          omitFooter: true,
                        }}
                        onUpload={handleUploadedDeliveryAttachement}
                        onChangeLoadingState={(v) => setFileUploaderLoading(v)}
                        disabled={isDeliveryAttachmentUploadDisabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <Filterbox
                      name="contactOrganizationUnit"
                      labelId="EST_ESTIMATE_REQUEST_INPUT.contactOrganizationUnit"
                      fullMethodName={FullMethodName_ListOrganizations}
                      columns={['contactOrganizationUnit']}
                      value={
                        contactOrganizationUnit ?? [
                          { value: '', displayName: '' },
                        ]
                      }
                      itemType={managementItemType}
                      searchOption={{
                        targets: 'displayName',
                        customQuery: { usable: { $eq: true } },
                      }}
                      onChangeState={(v) => {
                        setContactStaff([]);
                        setContactOrganizationUnit(v);
                      }}
                    />
                  </div>
                  <div className="w-20">
                    <Filterbox
                      name="contactStaff"
                      fullMethodName={FullMethodName_ListUserAttributes}
                      labelId="EST_ESTIMATE_REQUEST_INPUT.contactStaff"
                      columns={['contactStaff']}
                      value={contactStaff ?? [{ value: '', displayName: '' }]}
                      itemType={staffItemType}
                      searchOption={{
                        targets: 'displayName',
                        customQuery: contactStaffCustomQuery,
                      }}
                      onChangeState={setContactStaff}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="requesterDepartment"
                      className="field"
                      value={requesterDepartment}
                      type="text"
                      labelId="EST_ESTIMATE_REQUEST_INPUT.requesterDepartment"
                      columns={preset.columns}
                      onChangeState={setRequesterDepartment}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="requesterResponsible"
                      className="field"
                      value={requesterResponsible}
                      type="text"
                      labelId="EST_ESTIMATE_REQUEST_INPUT.requesterResponsible"
                      columns={preset.columns}
                      onChangeState={setRequesterResponsible}
                    />
                  </div>
                </div>
              </div>
            </Accordion>

            {/* 見積依頼先 */}
            <Accordion
              title={GetMessage({ id: 'requestUnit', prefixId: VIEW_ID })}
            >
              <div className="input-line">
                <div className="item-group-100 requestUnit">
                  <div className="w-50">
                    <SimpleListView
                      data={businessUnitContactViewItem}
                      viewOptions={{
                        previewRowCount: 5,
                        keyColumn: 'contactId',
                        columns: businessUnitContactColumns,
                      }}
                      actionOptions={{
                        onDelete: handleDeleteBusinessUnitContact,
                        onDeleteAll: handleAllDeleteBusinessUnitContact,
                      }}
                    />
                  </div>
                  <div className="w-20">
                    <CaptionButton
                      buttonType="basic"
                      caption="追加"
                      name=""
                      onClick={() => {
                        if (
                          businessUnitContactViewItem.length >= MAX_REQUEST_UNIT
                        ) {
                          error([
                            GetMessageWithIntl(intl, {
                              id: 'E0000138',
                              value: { $1: MAX_REQUEST_UNIT },
                            }),
                          ]);
                          return;
                        }
                        setOpenBusinessUnitContactSearchDialog(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Accordion>

            {/* 添付ファイル */}
            <Accordion
              title={GetMessage({ id: 'attachment', prefixId: VIEW_ID })}
            >
              {/* 添付ファイル */}
              <div className="input-line">
                <div className="item-group-100 file-area">
                  <div className="w-50">
                    <SimpleListView
                      data={attachmentViewItem}
                      viewOptions={{
                        previewRowCount: 5,
                        columns: attachmentColumns,
                      }}
                      actionOptions={{
                        onDelete: handleDeleteAttachment,
                        onDeleteAll: handleAllDeleteAttachment,
                        onRowClick: handleRowClickAttachment,
                        onFullDownLoad: handleFullDownloadAttachment,
                      }}
                    />
                  </div>
                  <div className="w-20">
                    <CaptionButton
                      buttonType="basic"
                      caption="ファイル追加"
                      name=""
                      onClick={() => {
                        if (
                          attachmentViewItem &&
                          attachmentViewItem.length >= MAX_ATTACHMENT
                        ) {
                          error([
                            GetMessageWithIntl(intl, {
                              id: 'E0000077',
                              value: { $1: MAX_ATTACHMENT },
                            }),
                          ]);
                          return;
                        }
                        setOpenFileUploadDialog(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Accordion>

            {/* 依頼明細 */}
            <Accordion
              title={GetMessage({ id: 'requestDetail', prefixId: VIEW_ID })}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-100">
                    <SimpleIconListView
                      data={estimateRequestDetails}
                      viewOptions={{
                        previewRowCount: 4,
                        keyColumn: 'id',
                        columns: estimateRequestDetailColumns,
                        omitFooter: true,
                      }}
                      actionOptions={{}}
                      iconMenuOptions={{
                        iconHeaderMenu: getRequestDetailIconHeaderMenuItem(),
                        iconMenu: getRequestDetailIconMenuItem(),
                      }}
                    />
                  </div>
                </div>
              </div>
            </Accordion>

            {/* 社内管理情報 */}
            <Accordion
              title={GetMessage({ id: 'management', prefixId: VIEW_ID })}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-40">
                    <DataFilterbox
                      data={systemNotificationUsersList}
                      name="systemNotificationUsers"
                      labelId="EST_ESTIMATE_REQUEST_INPUT.systemNotificationUsers"
                      columns={preset.columns}
                      searchOption={{ targets: 'displayName' }}
                      onChangeState={setSystemNotificationUsers}
                      value={systemNotificationUsers}
                      multiple={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-80">
                    <Textarea
                      labelId="EST_ESTIMATE_REQUEST_INPUT.remarks"
                      name="remarks"
                      className="w-100 mh-middle"
                      value={remarks}
                      columns={preset.columns}
                      onChangeState={setRemarks}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
        </div>
        <div className="footer" ref={footerRef}>
          <div className="footer-contents">
            <div className="input-line">
              <div className="item-group-100">
                <CaptionButton
                  name="saveBtn"
                  caption="一時保存"
                  className="btn"
                  onClick={handleSave}
                  buttonType="basic"
                />
                {/* {承認依頼ボタン} */}
                <CaptionButton
                  name="approvalRequestBtn"
                  caption="承認依頼"
                  className="btn"
                  onClick={() => {
                    // 入力エラーチェック
                    if (isInputErrorCheck()) {
                      return;
                    }
                    // 依頼明細入力チェック
                    if (isRequestDetailInputErrorCheck()) {
                      return;
                    }
                    // 依頼明細入力(承認依頼時)チェック
                    if (isRequestDetailApprovalRequestErrorCheck()) {
                      return;
                    }
                    setOpenApprovalRequestDialog(true);
                  }}
                  buttonType="basic"
                />
              </div>
            </div>
          </div>
        </div>
        <Toast />
      </Container>
      {/* 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isOpenConfirmDialog}
        viewMessage={confirmMessage}
        onDecision={handleConfirmed}
        onCancel={handleCancel}
      />
      {/* ファイル追加ダイアログ */}
      <FileUploadWithMemoDialog
        isOpen={isOpenFileUploadDialog}
        uploaderOption={{
          validateOption: {
            maxFileSizeInMebis: 50,
            maxFileCount: MAX_ATTACHMENT,
          },
        }}
        inputOption={{
          categorySystemName: 'B02',
          linkTypeSystemName: 'B01',
        }}
        displayOption={{
          isDnd: true,
        }}
        messageOption={{
          dialogTitle: {
            prefixId: 'DIALOG_TITLE',
            id: 'file_add',
          },
          buttonType: {
            id: 'decision',
          },
          memoLabelId: 'remarks',
        }}
        onDecision={(result) => {
          handleAddAttachment(result);
          setOpenFileUploadDialog(false);
        }}
        onCancel={() => {
          setOpenFileUploadDialog(false);
        }}
      />
      {/* 取引先(連絡先)検索ダイアログ */}
      <SearchListViewDialog
        viewId="SHARED_CONTACT_CONTENTS_SEARCH"
        fullMethodName={FullMethodName_ListSharedContactContents}
        isOpen={isOpenBusinessUnitContactSearchDialog}
        headerLabelId={{
          prefixId: 'DIALOG_TITLE',
          id: 'sharedContactContentsSearch',
        }}
        onCloseChange={() => {
          clearCheckBox();
          setOpenBusinessUnitContactSearchDialog(false);
        }}
        onSelectChange={(items) =>
          handleSearchSelect(
            items as unknown as mtechnavi.api.company.ISharedContactContent[]
          )
        }
        selectedItemIds={businessUnitContactViewItem.map(
          (contact) => contact.businessUnitContactId
        )}
        menuTarget="businessUnitContact.businessUnitContactId"
        onHandleFormatSchema={onHandleFormatSchema}
        selectLimit={MAX_REQUEST_UNIT}
      />
      {/* 明細登録ダイアログ */}
      <DetailInputDialog
        isOpen={isOpenDetailInputDialog}
        inputOption={detailInputInitialData}
        onDecision={(result) => {
          handleRequestDetailDecision(result);
          setOpenDetailInputDialog(false);
        }}
        onCancel={() => {
          setOpenDetailInputDialog(false);
        }}
      />
      {/* 承認依頼ダイアログ */}
      <ApprovalRequestDialog
        isOpen={isOpenApprovalRequestDialog}
        inputOption={{
          organizations: organizations.current,
          organizationRelations: organizationRelations.current,
          FullMethodName_ListUserAttributes: FullMethodName_ListUserAttributes,
          requestReciptId:
            estimateManagementData.current?.estimateManagementId || '',
        }}
        onDecision={(result) => {
          handleApprovalRequest(result);
          setOpenApprovalRequestDialog(false);
        }}
        onCancel={() => {
          setOpenApprovalRequestDialog(false);
        }}
      />

      {isLoading && <LoadingIcon />}
    </>
  );
}
