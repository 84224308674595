import { useIntl } from 'react-intl';
import { useState, useEffect, useRef } from 'react';
import { GetMessageWithIntl } from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components';
import { Textbox } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import '~/shared/components/ui/Dialog/base.css';
import { includeInputValidateError } from '~/shared/utils';
import { IFormSettingItem } from './';
import { error } from '~/shared/components/parts/Toast/Toast';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { validateHalfAlphanumericAndUnderScoreAndMaxSize } from '~/shared/components/parts/validator';

export interface InputOption {
  formItemId: string;
  inputType: string;
  formAllItems?: IFormSettingItem[];
  typeCheckList?: mtechnavi.api.form.IItemAutoNameManagement[];
}

export interface FormItemIdChangeDialogProps {
  isOpen: boolean;
  inputOption: InputOption;
  onDecision: (result: string) => void;
  onCancel: () => void;
}

export function FormItemIdChangeDialog(props: FormItemIdChangeDialogProps) {
  const intl = useIntl();
  const { isOpen, inputOption } = props;
  const [formItemId, setFormItemId] = useState<string>('');
  const [inputType, setInputType] = useState<string>('');
  const requiredFormItemIdArea = useRef(null);
  const [originAllItems, setOriginAllItems] = useState<IFormSettingItem[]>();
  const [typeCheckList, setTypeCheckList] =
    useState<mtechnavi.api.form.IItemAutoNameManagement[]>();

  useEffect(() => {
    // ダイアログ起動時
    if (isOpen) {
      setFormItemId(inputOption.formItemId);
      setInputType(inputOption.inputType);
      setOriginAllItems(inputOption.formAllItems);
      setTypeCheckList(inputOption.typeCheckList);
    }
    // isOpen変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // エラーチェック
  const isInputError = (): boolean => {
    const inputValidationCheckList = [
      {
        value: formItemId ?? '',
        ref: requiredFormItemIdArea,
      },
    ];
    const targetElm = document.querySelector('.form-item-id-change-dialog');
    if (includeInputValidateError(targetElm, intl, inputValidationCheckList)) {
      return true;
    }

    // 入力内容が変更されている場合のみチェックする
    if (inputOption.formItemId === formItemId) return false;

    // ID重複チェック
    if (originAllItems?.find((v) => v.item?.itemAutoName === formItemId)) {
      error([GetMessageWithIntl(intl, { id: 'E0000106' })]);
      return true;
    }

    // 型チェック
    if (
      typeCheckList?.some(
        (v) => v.itemAutoName === formItemId && v.inputType !== inputType
      )
    ) {
      error([GetMessageWithIntl(intl, { id: 'E0000112' })]);
      return true;
    }

    return false;
  };

  const handleDecision = () => {
    if (isInputError()) {
      return;
    }
    props.onDecision(formItemId);
  };

  // ダイアログ関係
  const view = (
    <div className="form-item-id-change-dialog">
      <div className="text-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="input" ref={requiredFormItemIdArea}>
                <Textbox
                  labelId={'FormItemIdChangeDialog.formItemId'}
                  name="formItemId"
                  columns={['formItemId']}
                  value={formItemId}
                  onChangeState={setFormItemId}
                  type="text"
                  validateOption={{ required: true }}
                  validator={validateHalfAlphanumericAndUnderScoreAndMaxSize(
                    intl,
                    100
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={() => props.onCancel()}
        />
        <CaptionButton
          name="decisionBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'decision' })}
          onClick={handleDecision}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      id: 'formItemIdChange',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements: view,
  };

  return <ModalDialogComponent {...openModalProps} />;
}
