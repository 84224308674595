import React, { useEffect, useMemo, useRef, useState } from 'react';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { ForumThread } from './ForumThread';
import './Forum.css';
import { Checkbox, Textbox } from '..';
import { IconButton } from '../Button';
import { ForumThreadForm, ForumThreadFormInputs } from './ForumThreadForm';
import { GetMessageWithIntl } from '../../parts/Message/Message';
import { useIntl } from 'react-intl';
import { getCommentList, getMarkerList, getThreadList } from './utils/api';
import { ForumMaxThreadCount, ForumPrefixId as prefixId } from './utils/util';
import { error } from '../../parts/Toast/Toast';
import {
  getExceptionMessage,
  getWorkerExceptionMessage,
  ViewId,
} from '~/shared/utils';
import { FullMethodName_ListBusinessUnitManagements } from '~/worker';
import { FilterResult } from '~/worker';

/** スクロールが必要な要素かどうかをチェック */
const isNeedScroll = (
  parent: HTMLDivElement,
  child: HTMLElement | null
): boolean => {
  if (!child) {
    return false;
  }
  const scrollThreshold = parent.getBoundingClientRect().height;
  return (
    parent.scrollTop + scrollThreshold >
      child.offsetTop + child.getBoundingClientRect().height ||
    parent.scrollTop + parent.getBoundingClientRect().height - scrollThreshold <
      child.offsetTop
  );
};

/** 未読のスレッド ID を抽出する処理 */
const extractUnreadThreadIdList = (args: {
  markerList: mtechnavi.api.forum.IMarker[];
  threadList: mtechnavi.api.forum.IThread[];
  unreadMarker?: sharelib.INameOption;
}) => {
  const { markerList, threadList, unreadMarker } = args;
  // 未読のコメントを含むスレッドID
  const unreadThreadIdList = markerList
    .filter((marker) =>
      marker.markers?.some(
        (mark) =>
          mark.categoryName === unreadMarker?.categoryName &&
          mark.systemName === unreadMarker?.systemName
      )
    )
    .map((marker) => marker.threadId)
    .filter((threadId) => !!threadId);
  // スレッドIDを一意にする
  const uniqueUnreadThreadIdList = Array.from(new Set(unreadThreadIdList));
  // スレッドの並び順に合わせるため、スレッドリストを加工して戻り値とする
  return threadList
    .filter((thread) => uniqueUnreadThreadIdList.includes(thread.threadId))
    .map((thread) => thread.threadId);
};

interface ForumControlInfo {
  hideCreateThread?: boolean;
  isControlCompletion?: boolean;
}

type DraftOptionalData = Pick<
  mtechnavi.api.forum.IThread,
  'associations' | 'attachedPoint'
>;

interface ForumProps {
  baseThreadId?: string;
  viewId: ViewId;
  companyIds?: string[];
  controls?: ForumControlInfo;
  isShowDraft?: boolean;
  draftOptionalData?: DraftOptionalData;
  currentThreadId?: string | null;
  onChangeActiveThread?: (threadId?: string | null) => Promise<void> | void;
  onAddThread?: (
    newThread: mtechnavi.api.forum.IThread
  ) => Promise<void> | void;
  onCancelAddThread?: () => Promise<void> | void;
  onFilterThreadList?: (threadList: mtechnavi.api.forum.IThread[]) => void;
}

/**
 * フォーラム UI
 */
export const Forum = ({
  baseThreadId,
  viewId,
  companyIds,
  controls,
  draftOptionalData,
  isShowDraft: isShowParentDraft,
  currentThreadId: parentCurrentThreadId,
  onChangeActiveThread,
  onAddThread,
  onCancelAddThread,
  onFilterThreadList,
}: ForumProps) => {
  const intl = useIntl();
  const { isControlCompletion } = controls ?? {};
  const [threadList, setThreadList] = useState<mtechnavi.api.forum.IThread[]>(
    []
  );
  const [filteredThreadList, setFilteredThreadList] = useState<
    mtechnavi.api.forum.IThread[]
  >([]);
  const [commentList, setCommentList] = useState<
    mtechnavi.api.forum.IComment[]
  >([]);
  const [markerList, setMarkerList] = useState<mtechnavi.api.forum.IMarker[]>(
    []
  );
  const [searchWord, setSearchWord] = useState('');
  const [destinationBusinessUnitList, setDestinationBusinessUnitList] =
    useState<mtechnavi.api.company.IBusinessUnitManagement[]>([]);
  const [currentThreadId, setCurrentThreadId] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [isWorking, setWorking] = useState(false);
  const [isDraft, setDraft] = useState(false);
  const [isUnCompletedOnly, setUncompletedOnly] = useState(true);
  const listRef = useRef<HTMLDivElement>(null);
  const isDisabled = isWorking || isLoading;

  const unreadMarker: sharelib.INameOption | undefined = useMemo(
    () =>
      window.App.services.ui
        .getNameOption('A0000039')
        .find((item) => item.systemName === 'B01'),
    []
  );

  // 未読コメントを含むスレッドID
  const unreadThreadIdList = useMemo(() => {
    return extractUnreadThreadIdList({
      markerList,
      threadList: filteredThreadList,
      unreadMarker,
    });
  }, [markerList, filteredThreadList, unreadMarker]);

  // スレッドの開閉
  const handleThreadOpen = (threadId: string) => {
    setCurrentThreadId(threadId);
    onChangeActiveThread && onChangeActiveThread(threadId);
  };
  const handleThreadClose = () => {
    setCurrentThreadId(null);
    onChangeActiveThread && onChangeActiveThread(null);
  };

  /** 検索ワードに一致するスレッドID */
  const matchWordThreadIdList = useMemo(() => {
    if (!searchWord || filteredThreadList.length === 0) {
      return [];
    }
    const matchThreadList = filteredThreadList.filter((item) =>
      (item.displayName || '').match(searchWord)
    );
    const matchCommentList = commentList.filter((item) =>
      (item.text || '').match(searchWord)
    );

    const matchThreadIdList = [
      ...matchThreadList.map((item) => item.threadId || ''),
      ...matchCommentList.map((item) => item.threadId || ''),
    ];
    const uniqueThreadIdList = Array.from(new Set(matchThreadIdList));

    return uniqueThreadIdList;
  }, [filteredThreadList, commentList, searchWord]);

  /** 検索に一致するスレッドの表示 */
  const handleSearch = () => {
    if (matchWordThreadIdList.length === 0) {
      return;
    }
    const currentIndex = matchWordThreadIdList.findIndex(
      (threadId) => threadId === currentThreadId
    );
    const threadId =
      currentIndex === -1
        ? matchWordThreadIdList.at(0)
        : matchWordThreadIdList.at(
            (currentIndex + 1) % matchWordThreadIdList.length
          );
    setCurrentThreadId(threadId || null);
    onChangeActiveThread && onChangeActiveThread(threadId);
  };

  const validateThreadCount = () => {
    if (threadList.length >= ForumMaxThreadCount) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000137',
          value: { $1: ForumMaxThreadCount },
        }),
      ]);
      return false;
    }
    return true;
  };

  /** スレッドの追加 */
  const handleAddThread = () => {
    if (!validateThreadCount()) {
      return;
    }
    setCurrentThreadId(null);
    setDraft(true);
  };
  const handleAddThreadCancel = () => {
    setDraft(false);
    onCancelAddThread && onCancelAddThread();
  };
  const handleAddThreadDecision = async (thread: ForumThreadFormInputs) => {
    if (controls?.hideCreateThread && !validateThreadCount()) {
      return;
    }

    const result = await window.App.services.ui.worker.apiCall({
      actionName: 'createForumThread',
      request: {
        thread: {
          ...thread,
          ...draftOptionalData,
          baseThreadId,
        },
      },
    });

    const newThread = result?.at(0);
    if (!newThread) {
      return;
    }
    const newThreadList = [newThread, ...(threadList || [])];
    setThreadListAndFiltered(newThreadList);
    setDraft(false);
    setCurrentThreadId(newThread.threadId || null);
    onAddThread && onAddThread(newThread);
    onChangeActiveThread && onChangeActiveThread(newThread.threadId);
  };

  /** スレッドの更新 */
  const handleUpdateThread = async (thread: mtechnavi.api.forum.IThread) => {
    const result = await window.App.services.ui.worker.apiCall({
      actionName: 'updateForumThread',
      request: { thread },
    });

    // 更新結果で該当スレッドの差し替え
    const newThread = result?.at(0);
    if (!newThread) {
      return;
    }
    const index = (threadList || []).findIndex(
      (thread) => thread.threadId === newThread.threadId
    );
    if (index < 0) {
      return;
    }
    const newList = [...threadList];
    newList.splice(index, 1, newThread);
    setThreadListAndFiltered(newList);
  };

  /** コメントの追加 */
  const handleSubmitComment = async (comment: mtechnavi.api.forum.IComment) => {
    const result = await window.App.services.ui.worker.apiCall({
      actionName: 'createForumComment',
      request: { comment },
    });

    const newComment = result?.at(0);
    if (!newComment) {
      return;
    }
    setCommentList([...(commentList || []), newComment]);
  };

  /** コメントの未読マーカーセット */
  const handleUnReadComment = async (comment: mtechnavi.api.forum.IComment) => {
    const result = await window.App.services.ui.worker.apiCall({
      actionName: 'setForumMarker',
      request: {
        recordId: comment.commentId,
        markers: [unreadMarker || {}],
      },
    });

    // 更新結果でマーカーの差し替え
    replaceMarkerListItem(result?.at(0)?.marker);
  };

  /** コメントの未読マーカー解除 */
  const handleReadComment = async (comment: mtechnavi.api.forum.IComment) => {
    const result = await window.App.services.ui.worker.apiCall({
      actionName: 'unsetForumMarker',
      request: {
        recordId: comment.commentId,
        markers: [unreadMarker || {}],
      },
    });

    // 更新結果でマーカーの差し替え
    replaceMarkerListItem(result?.at(0)?.marker);
  };

  /** マーカーの差し替え */
  const replaceMarkerListItem = (
    newMarker?: mtechnavi.api.forum.IMarker | null
  ) => {
    if (!newMarker) {
      return;
    }
    const index = (markerList || []).findIndex(
      (marker) => marker.markerId === newMarker.markerId
    );
    if (index < 0) {
      setMarkerList([...markerList, newMarker]);
      return;
    }
    const newList = [...markerList];
    newList.splice(index, 1, newMarker);
    setMarkerList(newList);
  };

  /** 全未読マーカー解除 */
  const handleReadAll = async () => {
    setWorking(true);
    try {
      const result = await window.App.services.ui.worker.apiCall({
        actionName: 'unsetAllForumMarker',
        request: {
          recordIdList: markerList
            .map((marker) => marker.urn?.split(':')?.at(1) || '')
            .filter((id) => !!id),
          markers: [unreadMarker || {}],
        },
      });

      const newMarkerList = result
        .flatMap((item) => item.map((item) => item.marker))
        .filter((item) => !!item) as mtechnavi.api.forum.IMarker[];
      setMarkerList(newMarkerList);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setWorking(false);
    }
  };

  /** コメントの削除 */
  const handleDeleteComment = async (comment: mtechnavi.api.forum.IComment) => {
    const { commentId, threadId, text, replyCommentId, updatedAt } = comment;
    const result = await window.App.services.ui.worker.apiCall({
      actionName: 'updateForumComment',
      request: {
        comment: {
          commentId,
          threadId,
          text,
          replyCommentId,
          updatedAt,
        },
      },
    });

    // 更新結果で該当コメントの差し替え
    const newComment = result?.at(0);
    if (!newComment) {
      return;
    }
    const index = commentList.findIndex(
      (comment) => comment.commentId === newComment.commentId
    );
    if (index < 0) {
      return;
    }
    const newList = [...commentList];
    newList.splice(index, 1, newComment);
    setCommentList(newList);
  };

  /** 一つ前のスレッドを表示 */
  const handlePrevThread = () => {
    if (filteredThreadList.length === 0) {
      return;
    }
    if (!currentThreadId) {
      const threadId =
        filteredThreadList.at(filteredThreadList.length - 1)?.threadId || null;
      setCurrentThreadId(threadId);
      onChangeActiveThread && onChangeActiveThread(threadId);
      return;
    }
    const currentIndex = filteredThreadList.findIndex(
      (thread) => thread.threadId === currentThreadId
    );
    const threadId =
      currentIndex === -1
        ? filteredThreadList.at(filteredThreadList.length - 1)?.threadId
        : filteredThreadList.at(currentIndex - 1)?.threadId;
    setCurrentThreadId(threadId || null);
    onChangeActiveThread && onChangeActiveThread(threadId);
  };

  /** 一つ後のスレッドを表示 */
  const handleNextThread = () => {
    if (filteredThreadList.length === 0) {
      return;
    }
    if (!currentThreadId) {
      const threadId = filteredThreadList.at(0)?.threadId || null;
      setCurrentThreadId(threadId);
      onChangeActiveThread && onChangeActiveThread(threadId);
      return;
    }
    const currentIndex = filteredThreadList.findIndex(
      (thread) => thread.threadId === currentThreadId
    );
    const threadId =
      currentIndex === -1
        ? filteredThreadList.at(0)?.threadId
        : filteredThreadList.at((currentIndex + 1) % filteredThreadList.length)
            ?.threadId;
    setCurrentThreadId(threadId || null);
    onChangeActiveThread && onChangeActiveThread(threadId);
  };

  /** 未読のスレッドを表示 */
  const handleNextUnreadThread = () => {
    if (unreadThreadIdList.length === 0) {
      // 未読がなかったら何もしない
      return;
    }
    const currentIndex = unreadThreadIdList.findIndex(
      (threadId) => threadId === currentThreadId
    );
    const threadId =
      currentIndex === -1
        ? unreadThreadIdList.at(0)
        : unreadThreadIdList.at((currentIndex + 1) % unreadThreadIdList.length);
    setCurrentThreadId(threadId || null);
    onChangeActiveThread && onChangeActiveThread(threadId);
  };

  useEffect(() => {
    (async () => {
      try {
        await handleRefresh(baseThreadId);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
    // intl と baseThreadId の変更のみを検知したい
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, baseThreadId]);

  // DM の場合の宛先企業(取引先)
  useEffect(() => {
    (async () => {
      if ((companyIds ?? []).length <= 0) {
        return [];
      }
      const result = (await window.App.services.ui.worker.filter({
        action: 'reload',
        fullMethodName: FullMethodName_ListBusinessUnitManagements,
        filter: { companyId: { $in: companyIds ?? [] } },
        sort: [],
      })) as FilterResult<mtechnavi.api.company.IBusinessUnitManagement>;
      setDestinationBusinessUnitList(result.items ?? []);
    })();
  }, [companyIds]);

  // 表示スレッドの切り替えによるスクロール位置調整
  useEffect(() => {
    if (!listRef.current) {
      return;
    }
    if (!currentThreadId) {
      listRef.current.scroll({ top: 0 });
      return;
    }
    const target = document.getElementById(currentThreadId);
    if (!isNeedScroll(listRef.current, target)) {
      return;
    }
    listRef.current.scroll({
      top: (target?.offsetTop || 0) - 9,
      behavior: 'smooth',
    });
  }, [currentThreadId]);

  // 利用側からアクティブスレッドを変える
  useEffect(() => {
    setCurrentThreadId(parentCurrentThreadId ?? null);
  }, [parentCurrentThreadId]);

  // 利用側からスレッド登録フォームを開く
  useEffect(() => {
    setDraft(!!isShowParentDraft);
  }, [isShowParentDraft]);

  /** 再取得 */
  const handleRefresh = async (baseThreadId?: string) => {
    setLoading(true);
    const [threadList, commentList, markerList] = await Promise.all([
      getThreadList(baseThreadId),
      getCommentList(baseThreadId),
      getMarkerList(baseThreadId),
    ]);
    setThreadListAndFiltered(threadList?.items || []);
    setCommentList(commentList?.items || []);
    setMarkerList(markerList?.items || []);
    setLoading(false);
  };

  /** スレッドリストとフィルタ適用後のリストを更新 */
  const setThreadListAndFiltered = (
    allThreadList: mtechnavi.api.forum.IThread[]
  ) => {
    setThreadList(allThreadList);
    applyCompletionFilter(allThreadList, isUnCompletedOnly);
  };

  /** フィルタ適用後のスレッドリストの更新と親への伝播 */
  const applyCompletionFilter = (
    allThreadList: mtechnavi.api.forum.IThread[],
    isUnCompletedOnly: boolean
  ) => {
    const completeFilteredThreads = allThreadList.filter(
      (thread) =>
        !isControlCompletion || !isUnCompletedOnly || !thread.completed
    );
    setFilteredThreadList(completeFilteredThreads);
    onFilterThreadList && onFilterThreadList(completeFilteredThreads);
  };

  return (
    <>
      {!!baseThreadId && (
        <div className="Forum">
          <div className="ForumHeader">
            <div className="search-box">
              <Textbox
                name="searchWord"
                className="search-word"
                type="text"
                columns={['searchWord']}
                labelId="searchWord"
                disabled={isDisabled}
                onChangeState={setSearchWord}
              />
              <IconButton
                name="search"
                className="search-button"
                iconType="search"
                buttonType="basic"
                disabled={isDisabled}
                onClick={handleSearch}
              />
            </div>
            <div className="action-box">
              {!controls?.hideCreateThread && (
                <>
                  <IconButton
                    name="createThread"
                    iconType="add"
                    buttonType="basic"
                    caption={GetMessageWithIntl(intl, {
                      prefixId,
                      id: 'createThread',
                    })}
                    disabled={isDisabled}
                    onClick={handleAddThread}
                  />
                  <hr />
                </>
              )}
              <IconButton
                name="readAll"
                iconType="read"
                buttonType="basic"
                caption={GetMessageWithIntl(intl, {
                  prefixId,
                  id: 'readAll',
                })}
                disabled={isDisabled}
                onClick={handleReadAll}
              />
              <IconButton
                name="refresh"
                buttonType="basic"
                iconType="refresh"
                caption={GetMessageWithIntl(intl, {
                  prefixId,
                  id: 'refresh',
                })}
                disabled={isDisabled}
                onClick={() => handleRefresh(baseThreadId)}
              />
              <hr />
              <IconButton
                name="prevThread"
                iconType="up"
                buttonType="basic"
                caption={GetMessageWithIntl(intl, {
                  prefixId,
                  id: 'prevThread',
                })}
                disabled={isDisabled}
                onClick={handlePrevThread}
              />
              <IconButton
                name="nextThread"
                iconType="down"
                buttonType="basic"
                caption={GetMessageWithIntl(intl, {
                  prefixId,
                  id: 'nextThread',
                })}
                disabled={isDisabled}
                onClick={handleNextThread}
              />
              <IconButton
                name="openUnread"
                iconType="unread"
                buttonType="basic"
                caption={GetMessageWithIntl(intl, {
                  prefixId,
                  id: 'openUnread',
                })}
                disabled={isDisabled}
                onClick={handleNextUnreadThread}
              />
            </div>
            {isControlCompletion && (
              <div className="uncompleted-checkbox">
                <Checkbox
                  name="uncompleted"
                  items={[
                    {
                      displayName: GetMessageWithIntl(intl, {
                        id: 'uncompleted',
                        prefixId: 'Forum',
                      }),
                      value: '1',
                    },
                  ]}
                  columns={['uncompleted']}
                  labelId=""
                  value={isUnCompletedOnly ? '1' : []}
                  onChangeState={(v) => {
                    const uncompletedOnly = v.length > 0;
                    setUncompletedOnly(uncompletedOnly);
                    applyCompletionFilter(threadList, uncompletedOnly);
                  }}
                />
              </div>
            )}
          </div>
          {isDraft && (
            <ForumThreadForm
              viewId={viewId}
              destinationBusinessUnit={destinationBusinessUnitList}
              onCancel={handleAddThreadCancel}
              onDecision={handleAddThreadDecision}
            />
          )}
          <div
            className={`ForumThreadList ${isLoading ? 'loading' : ''}`}
            ref={listRef}
          >
            {!isLoading &&
              filteredThreadList?.map((thread) => (
                <ForumThread
                  key={thread.threadId}
                  unreadMarker={unreadMarker}
                  inputOption={{
                    thread,
                    commentList:
                      commentList?.filter(
                        (comment) => comment.threadId === thread.threadId
                      ) || [],
                    markerList:
                      markerList?.filter(
                        (marker) => marker.threadId === thread.threadId
                      ) || [],
                  }}
                  onThreadOpen={handleThreadOpen}
                  onThreadClose={handleThreadClose}
                  onEditThread={handleUpdateThread}
                  onSubmitComment={handleSubmitComment}
                  onReadComment={handleReadComment}
                  onUnReadComment={handleUnReadComment}
                  onDeleteComment={handleDeleteComment}
                  isOpen={thread.threadId === currentThreadId}
                  isParentWorking={isWorking}
                  isControlCompletion={isControlCompletion}
                />
              ))}
          </div>
        </div>
      )}
    </>
  );
};
