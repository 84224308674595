/**
 * 日付型との相互変換処理
 */

import dayjs from 'dayjs';
import Long from 'long';
import { InitAccuracyFormatStyle } from '~/shared/components/ui';
import { sharelib } from '~/shared/libs/clientsdk';
import { FormatStyle } from '../formatter';

// datetime型（API）を日付形式に変換
export function convertDate(datetime: sharelib.IDatetime | null): Date | null {
  if (datetime === null || !datetime.timestamp) {
    return null;
  }
  const formatStyle = InitAccuracyFormatStyle.find(
    (v) => v.accuracy === datetime.accuracy
  );
  const dayformatStyle = formatStyle?.formatStyle ?? 'YYYY/MM/DD';
  const timestamp = Long.fromValue(datetime.timestamp!).toNumber() / 1000;
  const date = dayjs(timestamp).format(dayformatStyle);

  return new Date(date);
}

// 日付形式をdatetime型（API）に変換
export function convertDatetime(
  date: Date | null,
  dayFormatStyle: FormatStyle
): sharelib.IDatetime {
  // 日付が無い場合は現状各プロパティにnullを設定して返却
  if (date === null) {
    return {
      timestamp: null,
      timezoneName: null,
      accuracy: null,
    };
  }
  const timezone = 'Asia/Tokyo'; // 現在は固定
  const tmp = dayjs(date).format(dayFormatStyle);
  const timestamp = dayjs(tmp).unix();

  return {
    timestamp: new Long(timestamp).multiply(1000000), // マイクロ秒に直す
    timezoneName: timezone,
    accuracy: InitAccuracyFormatStyle.find(
      (v) => v.formatStyle === dayFormatStyle
    )?.accuracy as sharelib.Datetime.Accuracy,
  };
}

/**
 *APIからデータ取得時にLong(ナノ秒まで計測済み前提)->Dateに変換
 */
export function convertLongToDate(unix: Long.Long | undefined | null) {
  if (unix === null || unix === undefined) {
    return null;
  }
  const date = new Date(Long.fromValue(unix).toNumber() / 1000);

  return isNaN(date.getDate()) ? null : date;
}

/**
 * Date 型を Long 型に変換
 */
export function convertDateToLong(date: Date | undefined | null) {
  if (!date) {
    return null;
  }
  return Long.fromNumber(date.getTime()).multiply(1000);
}

/**
 * IDate型をDate型に変換
 * @param 日付:sharelib.IDate
 */
export function convertIDateToDate(value: sharelib.IDate | null | undefined) {
  if (value === null || value === undefined) {
    return null;
  }
  if (!value.year || !value.month || !value.day) {
    return null;
  }
  const date = new Date();
  date.setFullYear(value.year);
  date.setMonth(value.month - 1);
  date.setDate(value.day);
  return isNaN(date.getDate()) ? null : date;
}

/**
 * Date型をIDate型に変換
 * @param 日付:Date
 */
export function convertDateToIDate(value: Date | null | undefined) {
  if (value === null || value === undefined) {
    return null;
  }
  return {
    year: value.getFullYear(),
    month: value.getMonth() + 1,
    day: value.getDate(),
  } as sharelib.IDate;
}

/**
 * 文字列日付を日付変換し、getTime()した数値を返す。
 */
export const StringDateToNumber = (date: string) => {
  const dateNumber = new Date(date).getTime();
  return isNaN(dateNumber) ? 0 : dateNumber;
};
