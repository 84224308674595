import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  FullMethodName_ListEstimateRequests,
  FullMethodName_ListEstimateRequestUnits,
} from '~/worker';
import { Container, Toast } from '~/shared/components';
import { ForumTypeName } from '~/shared/components/ui/Forum';
import {
  AssetInfo,
  CommentableViewer as CommentableViewerUi,
  CommentResourceId,
} from '~/shared/components/ui';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  FullMethodName_ListEstimateManagements,
  FullMethodName_ListEstimateRequestDetails,
  ViewId,
} from '~/shared/utils';

/**
 * 見積図面コメント機能
 * 見積機能のみにフォーカスしているため、他の機能で利用することになった場合は別途調整する必要がある。
 */
export const CommentableViewer = () => {
  const [idList, setIdList] = useState<CommentResourceId[]>([]);
  const [isOwner, setIsOwner] = useState<boolean>(false); // true: 依頼側, false: 解答側
  const { hash } = useLocation();

  const resourceType = ForumTypeName.EstimateBlueprint;

  useEffect(() => {
    let params: CommentResourceId[] = [];
    try {
      params = JSON.parse(
        decodeURI(hash).replace('#', '')
      ) as CommentResourceId[];
    } catch {
      console.error(`Invalid URL hash`);
    }
    console.log('params', params);
    setIdList(params);
  }, [hash]);

  // AssetInfo を元に、送信先の企業IDを取得する
  // できれば BE にスマートに取ってこれるエンドポイントがあると嬉しい…。
  const companyIdsResolve = async (assetInfo: AssetInfo) => {
    if (!assetInfo.resourceId) {
      setIsOwner(false);
      return [];
    }
    // FIXME: 202409 スコープで調整予定
    // resourceId(estimateRequestDetailID) から estimateRequestDetail を取得し、estimateRequestId を割り出す。
    const details =
      await window.App.services.ui.worker.filter<mtechnavi.api.estimation.IEstimateRequestDetail>(
        {
          action: 'reload',
          fullMethodName: FullMethodName_ListEstimateRequestDetails,
          filter: {
            estimateRequestDetailId: { $in: [assetInfo.resourceId ?? ''] },
          },
          sort: [],
        }
      );
    const estimateRequestDetail = details.items.at(0);
    const estimateRequestId = estimateRequestDetail?.estimateRequestId;
    if (!estimateRequestId) {
      setIsOwner(false);
      return [];
    }

    // estimateRequestId から見積依頼管理情報の取得を試みる
    const management =
      await window.App.services.ui.worker.filter<mtechnavi.api.estimation.IEstimateManagement>(
        {
          action: 'reload',
          fullMethodName: FullMethodName_ListEstimateManagements,
          filter: {},
          sort: [],
          requestBody: {
            // FIXME: 202409 スコープで調整予定
            // estimateManagementId と estimateRequestId は本来別物である
            estimateManagementIds: [estimateRequestId],
          } as mtechnavi.api.estimation.IListEstimateRequestUnitsRequest,
        }
      );
    // 管理情報が取得できたら依頼側として扱う
    const isOwner = management.items.length > 0;
    setIsOwner(isOwner);

    if (!isOwner) {
      // estimateRequestId から見積依頼情報を取得し、依頼元企業IDを取り出す
      const request =
        await window.App.services.ui.worker.filter<mtechnavi.api.estimation.IEstimateRequest>(
          {
            action: 'reload',
            fullMethodName: FullMethodName_ListEstimateRequests,
            filter: {},
            sort: [],
            requestBody: {
              // FIXME: 202409 スコープで調整予定
              // estimateManagementId と estimateRequestId は本来別物である
              estimateManagementIds: [estimateRequestId],
            } as mtechnavi.api.estimation.IListEstimateRequestsRequest,
          }
        );
      const requestingCompanyId =
        request.items.at(0)?.requestingCompany?.companyId;
      return requestingCompanyId ? [requestingCompanyId] : [];
    }

    // estimateRequestId から見積依頼の対象となる企業(取引先)を取得
    const result =
      await window.App.services.ui.worker.filter<mtechnavi.api.estimation.IEstimateRequestUnit>(
        {
          action: 'reload',
          fullMethodName: FullMethodName_ListEstimateRequestUnits,
          filter: {},
          sort: [],
          requestBody: {
            // FIXME: 202409 スコープで調整予定
            // estimateManagementId と estimateRequestId は本来別物である
            estimateManagementIds: [estimateRequestId],
          } as mtechnavi.api.estimation.IListEstimateRequestUnitsRequest,
        }
      );
    return (result.items ?? [])
      .map((item) => item.companyId ?? '')
      .filter((id) => !!id);
  };

  return (
    <>
      <Container>
        <CommentableViewerUi
          resourceIdList={idList}
          resourceType={resourceType}
          companyIdsResolver={companyIdsResolve}
          viewId={
            (isOwner
              ? 'BLUEPRINT_REQUEST_COMMENT'
              : 'BLUEPRINT_RESULT_COMMENT') as unknown as ViewId
          }
        />
      </Container>
      <Toast />
    </>
  );
};
