import {
  PropsWithRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import Long from 'long';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
  SelectItem,
} from '~/shared/components';
import { CaptionButton } from '~/shared/components/ui/Button';
import { BuildFormInputType } from './utils';
import {
  Checkbox,
  DataFilterbox,
  DataFilterboxItem,
  Selectbox,
  SimpleIconListView,
  Textarea,
  Textbox,
} from '~/shared/components/ui';
import './FormItemDialog.css';
import {
  getProgramOptionFilterboxData,
  convertLongToString,
  convertLongToAmount,
} from '~/shared/utils/converter';
import { includeInputValidateError } from '~/shared/utils';
import { FormItemIdChangeDialog } from '~/shared/components/ui/FormBuilder/FormItemIdChangeDialog';
import { error } from '~/shared/components/parts/Toast/Toast';
import { IFormSettingItem } from './';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { validateIncludeProhibit } from '~/shared/components/parts/validator';
import { Amountbox } from '~/shared/components/ui/Amountbox';

const TEXTAREA_SIZE = '3';

export const sizeOptions: SelectItem[] = [
  { value: '1', displayName: '1' },
  { value: '2', displayName: '2' },
  { value: '3', displayName: '3' },
  { value: '4', displayName: '4' },
];

export const inputTypeOptions: SelectItem[] = [
  { value: 'text', displayName: '1行テキスト' },
  { value: 'textarea', displayName: '複数行テキスト' },
  { value: 'number', displayName: '数値' },
  { value: 'date', displayName: '日付' },
  { value: 'toggle', displayName: 'チェックボックス' },
  { value: 'select', displayName: '選択式（単一）' },
  { value: 'selectMultiple', displayName: '選択式（複数）' },
  { value: 'file', displayName: 'ファイル（単一）' },
  { value: 'label', displayName: 'ラベル' },
  { value: 'labelMultiLine', displayName: '複数行ラベル' },
  { value: 'spacer', displayName: '余白' },
];

interface SelectOption {
  displayItem: string;
  displayName: string;
  value: string;
}

export interface FormItemDialogRef {
  open: (
    formItem: IFormSettingItem,
    formAllItems: IFormSettingItem[],
    typeCheckList: mtechnavi.api.form.IItemAutoNameManagement[]
  ) => void;
}
export interface FormItemDialogProps {
  onDecision?: (result: IFormSettingItem) => void;
  onCancel?: () => void;
}
export const FormItemDialog = forwardRef(
  (props: PropsWithRef<FormItemDialogProps>, ref) => {
    const intl = useIntl();
    const [isOpen, setOpen] = useState(false);
    const [originItem, setOriginItem] = useState<IFormSettingItem>();
    const [originAllItems, setOriginAllItems] = useState<IFormSettingItem[]>();
    const [typeCheckList, setTypeCheckList] =
      useState<mtechnavi.api.form.IItemAutoNameManagement[]>();
    const [buildFormItemAutoName, setBuildFormItemAutoName] = useState('');
    const [requiredCheck, setRequiredCheck] = useState<string[]>([]);
    const [sizeOption, setSizeOption] = useState<string[]>([
      sizeOptions[0].value,
    ]);
    const [displayName, setDisplayName] = useState('');
    const [description, setDescription] = useState('');
    const [inputTypeOption, setInputTypeOption] = useState<string[]>([
      inputTypeOptions[0].value,
    ]);
    const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);
    const [selectOptionCodeInput, setSelectOptionCodeInput] = useState('');
    const [selectOptionDisplayNameInput, setSelectOptionDisplayNameInput] =
      useState('');
    const [fileTypeOption, setFileTypeOption] = useState<DataFilterboxItem[]>(
      []
    );
    const [min, setMin] = useState<Long.Long | null>();
    const [max, setMax] = useState<Long.Long | null>();
    const [precision, setPrecision] = useState<Long.Long | null>();
    const [label, setLabel] = useState('');

    // 項目ID変更ダイアログ
    const [isFormItemIdChangeDialogOpen, setFormItemIdChangeDialogOpen] =
      useState(false);

    // フィルタボックス定義
    const FileTypeOptionCode = 'A5000005';
    const [fileTypeOptions] = useMemo<[DataFilterboxItem[]]>(() => {
      const fileTypeOptions = getProgramOptionFilterboxData(FileTypeOptionCode);
      return [fileTypeOptions];
    }, []);

    // 必須チェック
    const requiredInputTypeOptionArea = useRef(null);
    const requiredSizeOptionArea = useRef(null);
    const requiredDisplayNameArea = useRef(null);
    const requiredLabelArea = useRef(null);
    const [workingBlurFileTypeOption, setWorkingBlurFileTypeOption] =
      useState<Date>();
    const [requiredFileTypeOption, setRequiredFileTypeOption] = useState(false);
    const requiredFileTypeOptionArea = useRef(null);
    // 選択肢追加エラーチェック
    const validateSelectOptionArea = useRef<HTMLDivElement>(null);

    // 値変更時制御
    const [isSuspendInputTypeOptionChange, setSuspendInputTypeOptionChange] =
      useState(false);

    const isSizeUnchangeable = useMemo(
      () =>
        inputTypeOption.length > 0 &&
        ['textarea', 'labelMultiLine'].includes(inputTypeOption[0]),
      [inputTypeOption]
    );

    useEffect(() => {
      if (isSuspendInputTypeOptionChange) {
        setSuspendInputTypeOptionChange(false);
        return;
      }
      if (inputTypeOption.length > 0) {
        // 余白、ラベル、ラベル（複数行）に変更した場合、必須・項目名・項目説明をクリア
        if (['spacer', 'label', 'labeMultiLine'].includes(inputTypeOption[0])) {
          setRequiredCheck([]);
          setDisplayName('');
          setDescription('');
        }
      }
      // 入力値を初期化する
      setMin(null);
      setMax(null);
      setLabel('');
      setPrecision(null);
      setFileTypeOption([]);
      setSelectOptions([]);
      setSelectOptionCodeInput('');
      setSelectOptionDisplayNameInput('');
      setRequiredFileTypeOption(false);
      setWorkingBlurFileTypeOption(undefined);

      if (
        inputTypeOption.length > 0 &&
        ['textarea', 'labelMultiLine'].includes(inputTypeOption[0])
      ) {
        setSizeOption([TEXTAREA_SIZE]);
      }

      if (
        ['file', 'fileMultiple'].some(
          (type) => inputTypeOption.length > 0 && type === inputTypeOption[0]
        )
      ) {
        setRequiredFileTypeOption(true);
      }
      // inputTypeOption 変更時のみ起動させたい処理なのでlintから除外させる
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputTypeOption]);

    useImperativeHandle(
      ref,
      (): FormItemDialogRef => ({
        open: (
          formItem: IFormSettingItem,
          formAllItems: IFormSettingItem[],
          typeCheckList: mtechnavi.api.form.IItemAutoNameManagement[]
        ) => {
          setOriginItem(formItem);
          setOriginAllItems(formAllItems);
          setTypeCheckList(typeCheckList);
          setBuildFormItemAutoName(formItem.item?.itemAutoName || '');
          if (
            ['label', 'labelMultiLine'].includes(formItem.item?.inputType ?? '')
          ) {
            setLabel(formItem.item?.displayName ?? '');
            setDisplayName('');
          } else {
            setDisplayName(formItem.item?.displayName || '');
          }
          setDescription(formItem.item?.description || '');
          setRequiredCheck(formItem.item?.required ? ['1'] : []);
          const size = sizeOptions.find(
            (opt) => opt.value === convertLongToString(formItem.item?.itemSize)
          );
          setSizeOption(size ? [size.value] : []);
          setSuspendInputTypeOptionChange(true);
          const inputType = inputTypeOptions.find(
            (opt) => opt.value === formItem.item?.inputType
          );
          //   "React has detected a change in the order of Hooks called by ForwardRef."
          setInputTypeOption(inputType ? [inputType.value] : []);
          setMin(
            formItem.item?.minNum
              ? Long.fromString(formItem.item?.minNum)
              : null
          );
          setMax(
            formItem.item?.maxNum
              ? Long.fromString(formItem.item?.maxNum)
              : null
          );
          setPrecision(
            formItem.item?.precisionNum
              ? Long.fromString(formItem.item?.precisionNum)
              : null
          );
          const selectOptionItems = formItem.item?.selectItems?.map((v) => {
            const obj: SelectOption = JSON.parse(v);
            obj.displayItem = `${obj.displayName} (${obj.value})`;
            return obj;
          });
          setSelectOptions(selectOptionItems ?? []);
          const fileTypeOption = fileTypeOptions.filter((opt) =>
            (formItem.item?.fileTypes || []).some((type) => type === opt.value)
          );
          setFileTypeOption(fileTypeOption);

          // 必須制御
          setRequiredFileTypeOption(false);
          if (
            ['file', 'fileMultiple'].some(
              (type) => inputType && type === inputType.value
            )
          ) {
            setRequiredFileTypeOption(true);
          }
          setWorkingBlurFileTypeOption(undefined);

          setOpen(true);
        },
      }),
      // ハンドルの実行時のみ起動させたい処理なのでlintから除外させる
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    // エラーチェック
    const isInputError = (): boolean => {
      if (
        ['file', 'fileMultiple'].some(
          (type) => inputTypeOption.length > 0 && type === inputTypeOption[0]
        )
      ) {
        // 同一フォーム内に自分以外にファイル系が１０個あればエラー
        const otherItems =
          originAllItems?.filter(
            (v) =>
              v.formSettingItemId !== originItem?.formSettingItemId &&
              ['file', 'fileMultiple'].some(
                (type) => type === v.item?.inputType
              )
          ) ?? [];
        if (otherItems?.length >= 10) {
          error([GetMessageWithIntl(intl, { id: 'E0000110' })]);
          return true;
        }
      }
      if (
        ['select', 'selectMultiple'].some(
          (type) => inputTypeOption.length > 0 && type === inputTypeOption[0]
        )
      ) {
        // 選択肢必須チェック
        if (!selectOptions.length) {
          error([GetMessageWithIntl(intl, { id: 'E0000107' })]);
          return true;
        }
      }

      setWorkingBlurFileTypeOption(new Date());

      const inputValidationCheckList = [
        {
          value: inputTypeOption.length > 0 ? inputTypeOption[0] : '',
          ref: requiredInputTypeOptionArea,
        },
        {
          value: sizeOption.length > 0 ? sizeOption[0] : '',
          ref: requiredSizeOptionArea,
        },
      ];

      if (
        !['spacer', 'label', 'labelMultiLine'].some(
          (type) => inputTypeOption.length > 0 && type === inputTypeOption[0]
        )
      ) {
        inputValidationCheckList.push({
          value: displayName ?? '',
          ref: requiredDisplayNameArea,
        });
      }
      if (
        ['label', 'labelMultiLine'].some(
          (type) => inputTypeOption.length > 0 && type === inputTypeOption[0]
        )
      ) {
        inputValidationCheckList.push({
          value: label ?? '',
          ref: requiredLabelArea,
        });
      }
      if (
        ['file', 'fileMultiple'].some(
          (type) => inputTypeOption.length > 0 && type === inputTypeOption[0]
        )
      ) {
        // ファイル形式必須チェック
        inputValidationCheckList.push({
          value: fileTypeOption.length > 0 ? fileTypeOption[0].value : '',
          ref: requiredFileTypeOptionArea,
        });
      }
      const targetElm = document.querySelector('.FormItemDialog');
      if (
        includeInputValidateError(targetElm, intl, inputValidationCheckList)
      ) {
        return true;
      }

      if (
        ['number'].some(
          (type) => inputTypeOption.length > 0 && type === inputTypeOption[0]
        )
      ) {
        if (min && max && min.toNumber() > max.toNumber()) {
          error([GetMessageWithIntl(intl, { id: 'E0000115' })]);
          return true;
        }
      }

      return false;
    };

    const handleChangeFormItemId = (v: string) => {
      setBuildFormItemAutoName(v);
      setFormItemIdChangeDialogOpen(false);
    };

    const handleCancel = () => {
      setOpen(false);
      if (props.onCancel) {
        props.onCancel();
      }
    };

    const handleDecision = () => {
      if (!originItem) {
        return;
      }
      if (isInputError()) {
        return;
      }
      const result: IFormSettingItem = {
        ...originItem,
        item: {
          itemAutoName: buildFormItemAutoName,
          itemSize: Long.fromString(sizeOption[0]),
          inputType: inputTypeOption[0] as BuildFormInputType,
          displayName: ['label', 'labelMultiLine'].includes(inputTypeOption[0])
            ? label
            : displayName,
          description,
          required: requiredCheck.length > 0,
        },
      };

      if (!result.item) {
        return;
      }

      if (result.item?.inputType === 'number') {
        result.item.minNum = min?.toString();
        result.item.maxNum = max?.toString();
        result.item.precisionNum = precision?.toString();
      }

      if (['file'].some((type) => type === result.item?.inputType)) {
        result.item.fileTypes = fileTypeOption.map((item) => item.value);
      } else {
        result.item.fileTypes = [];
      }

      if (
        ['select', 'selectMultiple'].some(
          (type) => type === result.item?.inputType
        )
      ) {
        const selectOptionsJsonData = selectOptions.map((v) => {
          const obj = {
            value: v.value,
            displayName: v.displayName,
          };
          return JSON.stringify(obj);
        });
        result.item.selectItems = selectOptionsJsonData;
      } else {
        result.item.selectItems = [];
      }

      setOpen(false);
      if (props.onDecision) {
        props.onDecision(result);
      }
    };

    const handleAddSelectItem = () => {
      if (!selectOptionCodeInput || !selectOptionDisplayNameInput) {
        return;
      }
      if (
        validateSelectOptionArea.current &&
        !!validateSelectOptionArea.current.querySelector(
          '.visible-error-message'
        )
      ) {
        return;
      }
      if (selectOptions.length === 20) {
        error([
          GetMessageWithIntl(intl, { id: 'E0000148', value: { $1: 20 } }),
        ]);
        return;
      }
      if (selectOptions.some((v) => v.value === selectOptionCodeInput)) {
        error([GetMessageWithIntl(intl, { id: 'E0000072' })]);
        return;
      }

      setSelectOptions([
        ...selectOptions,
        {
          displayItem: `${selectOptionDisplayNameInput} (${selectOptionCodeInput})`,
          displayName: selectOptionDisplayNameInput,
          value: selectOptionCodeInput,
        },
      ]);
      setSelectOptionCodeInput('');
      setSelectOptionDisplayNameInput('');
    };
    const handleDeleteSelectItem = (target: SelectOption) => {
      setSelectOptions(
        selectOptions.filter((opt) => opt.value !== target.value)
      );
    };

    const handleDeleteAllItem = () => {
      setSelectOptions([]);
    };

    const elements = (
      <div className="FormItemDialog">
        <div className="comment-area">
          <div className="form-item-id">
            {GetMessageWithIntl(intl, {
              prefixId: 'FORM_BUILDER',
              id: 'formItemId',
            })}
            :
            <div
              className="link-button"
              onClick={() => setFormItemIdChangeDialogOpen(true)}
            >
              {buildFormItemAutoName}
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-33">
                <div className="input" ref={requiredInputTypeOptionArea}>
                  <Selectbox
                    labelId="FORM_BUILDER.inputType"
                    name="inputType"
                    columns={['inputType']}
                    items={inputTypeOptions}
                    multiple={false}
                    onChangeState={setInputTypeOption}
                    value={inputTypeOption}
                    validateOption={{ required: true }}
                  />
                </div>
              </div>
              <div className="w-20">
                <div className="input" ref={requiredSizeOptionArea}>
                  <Selectbox
                    labelId="FORM_BUILDER.size"
                    name="size"
                    columns={['size']}
                    items={sizeOptions}
                    multiple={false}
                    onChangeState={setSizeOption}
                    disabled={isSizeUnchangeable}
                    value={sizeOption}
                    validateOption={{ required: true }}
                  />
                </div>
              </div>
              <div className="w-33 required-checkbox">
                <Checkbox
                  name="required"
                  items={[
                    {
                      value: '1',
                      displayName: GetMessageWithIntl(intl, {
                        prefixId: 'FORM_BUILDER',
                        id: 'required',
                      }),
                    },
                  ]}
                  value={requiredCheck}
                  onChangeState={setRequiredCheck}
                  columns={['required']}
                  disabled={[
                    'spacer',
                    'label',
                    'labelMultiLine',
                    'two-choice',
                  ].some(
                    (type) =>
                      inputTypeOption.length > 0 && type === inputTypeOption[0]
                  )}
                />
              </div>
            </div>
          </div>

          <div className="input-line">
            <div className="item-group-100">
              <div className="w-66">
                <div className="input" ref={requiredDisplayNameArea}>
                  <Textbox
                    labelId="FORM_BUILDER.displayName"
                    name="displayName"
                    columns={['displayName']}
                    value={displayName}
                    type="text"
                    validateOption={{
                      required: true,
                      maxLength: 50,
                    }}
                    onChangeState={setDisplayName}
                    disabled={['spacer', 'label', 'labelMultiLine'].some(
                      (type) =>
                        inputTypeOption.length > 0 &&
                        type === inputTypeOption[0]
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <Textbox
                  labelId="FORM_BUILDER.description"
                  name="description"
                  columns={['description']}
                  value={description}
                  type="text"
                  onChangeState={setDescription}
                  disabled={['spacer', 'label', 'labelMultiLine'].some(
                    (type) =>
                      inputTypeOption.length > 0 && type === inputTypeOption[0]
                  )}
                />
              </div>
            </div>
          </div>
          {inputTypeOption.length > 0 && inputTypeOption[0] === 'number' && (
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-33">
                  <Amountbox
                    labelId="FORM_BUILDER.min"
                    name="min"
                    columns={['min']}
                    value={convertLongToAmount(min)}
                    validateOption={{
                      length: { min: -999999999, max: 999999999 },
                    }}
                    onChangeState={(v) =>
                      setMin(v?.integralAmount ? v.integralAmount : null)
                    }
                    disabled={
                      !(
                        inputTypeOption.length > 0 &&
                        inputTypeOption[0] === 'number'
                      )
                    }
                  />
                </div>
                <div className="w-33">
                  <Amountbox
                    labelId="FORM_BUILDER.max"
                    name="max"
                    columns={['max']}
                    value={convertLongToAmount(max)}
                    validateOption={{
                      length: { min: -999999999, max: 999999999 },
                    }}
                    onChangeState={(v) =>
                      setMax(v?.integralAmount ? v.integralAmount : null)
                    }
                  />
                </div>
                <div className="w-20">
                  <Amountbox
                    labelId="FORM_BUILDER.precision"
                    name="precision"
                    columns={['precision']}
                    value={convertLongToAmount(precision)}
                    validateOption={{
                      length: { min: 0, max: 4 },
                    }}
                    onChangeState={(v) =>
                      setPrecision(v?.integralAmount ? v.integralAmount : null)
                    }
                  />
                </div>
              </div>
            </div>
          )}

          {['file'].some(
            (type) => inputTypeOption.length > 0 && type === inputTypeOption[0]
          ) && (
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-66">
                  <div className="input" ref={requiredFileTypeOptionArea}>
                    <DataFilterbox
                      name={'fileType'}
                      columns={['fileType']}
                      labelId="FORM_BUILDER.fileType"
                      multiple={true}
                      data={fileTypeOptions}
                      onChangeState={setFileTypeOption}
                      value={fileTypeOption}
                      validateOption={{ required: requiredFileTypeOption }}
                      workingBlur={workingBlurFileTypeOption}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {['select', 'selectMultiple'].some(
            (type) => inputTypeOption.length > 0 && type === inputTypeOption[0]
          ) && (
            <div className="select-item-container">
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-25" ref={validateSelectOptionArea}>
                    <Textbox
                      labelId="FORM_BUILDER.selectOptionCodeInput"
                      name="selectOptionCodeInput"
                      columns={['selectOptionCodeInput']}
                      type="text"
                      value={selectOptionCodeInput}
                      onChangeState={setSelectOptionCodeInput}
                      validateOption={{ maxLength: 40 }}
                      validator={validateIncludeProhibit(intl, ',')}
                    />
                  </div>
                  <div className="w-50" ref={validateSelectOptionArea}>
                    <Textbox
                      labelId="FORM_BUILDER.selectOptionDisplayNameInput"
                      name="selectOptionDisplayNameInput"
                      columns={['selectOptionDisplayNameInput']}
                      type="text"
                      value={selectOptionDisplayNameInput}
                      onChangeState={setSelectOptionDisplayNameInput}
                      validateOption={{ maxLength: 40 }}
                      validator={validateIncludeProhibit(intl, ',')}
                    />
                  </div>
                  <div className="w-25 button-padding">
                    <CaptionButton
                      name="add"
                      buttonType="basic"
                      className="button"
                      caption={GetMessageWithIntl(intl, { id: 'add' })}
                      onClick={() => handleAddSelectItem()}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-66">
                    <SimpleIconListView
                      data={selectOptions}
                      key="value"
                      viewOptions={{
                        columns: [
                          {
                            propertyName: 'displayItem',
                            header: {
                              prefixId: 'FORM_BUILDER',
                              id: 'selectItems',
                            },
                          },
                        ],
                        omitFooter: true,
                      }}
                      iconMenuOptions={{
                        iconMenu: [
                          {
                            name: 'arrow_upward',
                            displayName: '',
                            func: (v: SelectOption) => {
                              const originalIndex = selectOptions.findIndex(
                                (op) => op.value === v.value
                              );
                              const targetIndex = originalIndex - 1;
                              if (targetIndex === -1) {
                                return;
                              }
                              const cloneSelectOptions = [...selectOptions];
                              [
                                cloneSelectOptions[targetIndex],
                                cloneSelectOptions[originalIndex],
                              ] = [
                                selectOptions[originalIndex],
                                selectOptions[targetIndex],
                              ];
                              setSelectOptions(cloneSelectOptions);
                            },
                          },
                        ],
                      }}
                      actionOptions={{
                        onDelete: handleDeleteSelectItem,
                        onDeleteAll: handleDeleteAllItem,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {inputTypeOption.length > 0 && inputTypeOption[0] === 'label' && (
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <div className="input" ref={requiredLabelArea}>
                  <Textbox
                    labelId="FORM_BUILDER.label"
                    name="label"
                    columns={['label']}
                    value={label}
                    type="text"
                    validateOption={{
                      required: true,
                      maxLength: 100,
                    }}
                    onChangeState={setLabel}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {inputTypeOption.length > 0 && inputTypeOption[0] === 'labelMultiLine' && (
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <div className="input" ref={requiredLabelArea}>
                  <Textarea
                    labelId="FORM_BUILDER.labelMultiLine"
                    className="w-100 mh-middle"
                    name="labelMultiLine"
                    columns={['labelMultiLine']}
                    value={label}
                    validateOption={{
                      required: true,
                      maxLength: 500,
                    }}
                    onChangeState={setLabel}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={`button-area ${
            ['file', 'fileMultiple'].some(
              (type) =>
                inputTypeOption.length > 0 && type === inputTypeOption[0]
            )
              ? 'top-space-10rem'
              : ''
          }`}
        >
          <CaptionButton
            name="cancel"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={handleCancel}
          />
          <CaptionButton
            name="decision"
            buttonType="basic"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'decision' })}
            onClick={() => handleDecision()}
          />
        </div>
      </div>
    );

    const openModalProps: ModalDialogComponentProps = {
      cancel: handleCancel,
      send: () => {},
      modalIsOpen: isOpen,
      headerLabelId: { prefixId: 'FORM_BUILDER', id: 'FormItemDialog' },
      messageLabelId: {},
      elements,
    };

    return (
      <>
        <ModalDialogComponent {...openModalProps} />
        {/* 項目ID変更ダイアログ */}
        <FormItemIdChangeDialog
          isOpen={isFormItemIdChangeDialogOpen}
          inputOption={{
            formItemId: buildFormItemAutoName,
            inputType: inputTypeOption.length > 0 ? inputTypeOption[0] : '',
            formAllItems: originAllItems,
            typeCheckList: typeCheckList,
          }}
          onDecision={handleChangeFormItemId}
          onCancel={() => {
            setFormItemIdChangeDialogOpen(false);
          }}
        />
      </>
    );
  }
);
