import { useIntl } from 'react-intl';
import { ModalDialogComponent } from '../..';
import { GetMessageWithIntl, MessageProps } from '../../parts/Message/Message';
import { CaptionButton } from '../../parts/Button/CaptionButton';

interface MessageDialogMessageOption {
  /** ヘッダラベル辞書情報 */
  headerLabelId: MessageProps;
  /** メッセージ内容として表示する辞書情報 */
  message?: MessageProps;
  /** メッセージ内容として表示する任意の要素 */
  messageContents?: React.ReactElement;
  /** ボタンキャプション辞書情報 */
  buttonCaption?: MessageProps;
}

export interface MessageDialogProps {
  isOpen: boolean;
  messageOption: MessageDialogMessageOption;
  onClose?: () => void;
}
/** メッセージを表示するだけのダイアログ */
export const MessageDialog = ({
  isOpen,
  messageOption,
  onClose,
}: MessageDialogProps) => {
  const intl = useIntl();

  const handleCLose = () => {
    onClose && onClose();
  };

  const elements = (
    <div className="MessageDialog dialog-with-description">
      <div className="message">
        {messageOption.messageContents && messageOption.messageContents}
        {messageOption.message &&
          GetMessageWithIntl(intl, messageOption.message)}
      </div>
      <div className="button-area">
        <CaptionButton
          buttonType="cancel"
          caption={
            messageOption.buttonCaption
              ? GetMessageWithIntl(intl, messageOption.buttonCaption)
              : GetMessageWithIntl(intl, { id: 'close' })
          }
          name=""
          onClick={handleCLose}
        />
      </div>
    </div>
  );

  return (
    <>
      <ModalDialogComponent
        cancel={handleCLose}
        send={handleCLose}
        modalIsOpen={isOpen}
        headerLabelId={messageOption.headerLabelId}
        messageLabelId={{}}
        elements={elements}
      />
    </>
  );
};
