import React, {
  MouseEvent,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { useRenderer } from './renderer';
import { useDragging, Coord, DraggingInfo } from './dragging';
import './Viewer.css';
import { ReactComponent as KeyboardArrowRightIcon } from '@material-design-icons/svg/filled/keyboard_arrow_right.svg';
import { ReactComponent as KeyboardArrowLeftIcon } from '@material-design-icons/svg/filled/keyboard_arrow_left.svg';
import { LoadingIcon } from '../LoadingIcon/LoadingIcon';
import { useSelection } from './useSelection';
import { autoDownloadFile } from '~/shared/utils';
import { IconButton } from '../Button';
import {
  DragMode,
  DragModeSelector,
  DragModeSelectorProps,
} from './parts/DragModeSelector';
import { ShapeInfo, ViewerInfo } from './util';
import { ViewerControlButton } from './parts/ViewerControlButton';
import { OverlayItem, ViewerOverlay } from './parts/ViewerOverlay';
import { Arrow, Rect } from './parts/Shapes';

export interface ViewerProps {
  assetId: string;
  fileName: string;
  className?: string;
  width: string;
  height: string;
  controls?: ViewerControls;
  dragMode?: DragMode;
  overlayItems?: OverlayItem | OverlayItem[];
  onSelectionStart?: () => void;
  onSelectionEnd?: (selection: ShapeInfo) => void;
  onChangeViewerInfo?: (viewerInfo: ViewerInfo) => void;
}

export interface SelectionInfo {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export interface OffsetInfo {
  x: number;
  y: number;
}

interface ViewerControls {
  /** ファイル名の表示 */
  filename?: boolean;
  /** ページネーションの表示 */
  pager?: boolean;
  /** 拡大・縮小メニューの表示 */
  scaling?: boolean;
  /** 範囲指定メニューの表示 */
  cropping?: boolean;
  /** 範囲指定メニューのオプション設定 */
  croppingOption?: DragModeSelectorProps['croppingOption'];
  /** ファイルダウンロードの表示 */
  downloading?: boolean;
  /**
   * その他のコントロール
   *
   * ボタンとして ViewerControlButton の利用をお勧めします。
   */
  extraControls?: (JSX.Element | null) | (JSX.Element | null)[];
  /** その他のコントロール配置箇所 */
  extraControlsPosition?: 'top' | 'bottom';
}

const defaultCropOption: DragModeSelectorProps['croppingOption'] = {
  shapeList: {
    Rectangle: { iconType: 'square', component: Rect },
    Arrow: { iconType: 'arrow', component: Arrow },
  },
};

export const Viewer = (props: ViewerProps) => {
  const { onSelectionStart, onSelectionEnd, onChangeViewerInfo, controls } =
    props;
  // Viewerコンポーネントのクラス名
  const className = props.className ?? '';
  const srcURL = `/mtechnavi.api.assetinventory.AssetProxy/${props.assetId}`;
  const croppingOption = controls?.croppingOption ?? defaultCropOption;

  const [state, render] = useRenderer(srcURL);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [isLoading, setLoading] = useState(false);
  const [offset, setOffset] = useState({
    x: 0,
    y: 0,
  });
  const [dragMode, setDragMode] = useState<DragMode>(props.dragMode || 'move');
  const [isShiftKeyDown, setIsShiftKeyDown] = useState(false);
  const [selectionState, dispatch] = useSelection(
    croppingOption?.defaultShape ??
      Object.keys(croppingOption?.shapeList ?? {}).at(0)
  );
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!selectionState.draft || !onSelectionEnd) {
      return;
    }
    onSelectionEnd(selectionState.draft);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionState.draft]);

  useEffect(() => {
    if (!selectionState.viewerInfo || !onChangeViewerInfo) {
      return;
    }
    onChangeViewerInfo(selectionState.viewerInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionState.viewerInfo]);

  useEffect(() => {
    if (pageNumber === 0) {
      return;
    }
    if (!canvasRef.current) {
      return;
    }
    const el = canvasRef.current!;
    const ctx = el.getContext('2d');
    if (!ctx) {
      throw new Error('unsupported browser');
    }
    render(ctx, pageNumber);
  }, [canvasRef, render, pageNumber]);

  useEffect(() => {
    if (state.status === 'loading') {
      setPageNumber(0);
    } else if (pageNumber === 0) {
      setPageNumber(1);
    }

    // ローディングアイコン制御
    if (state.status === 'loading' || state.status === 'rendering') {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [state.status, pageNumber]);

  const handlePrevPage = () => {
    const n = pageNumber - 1;
    if (n > 0) {
      setPageNumber(n);
    }
  };
  const maxPageNumber = state.status === 'OK' ? state.maxPageNumber : 0;
  const handleNextPage = () => {
    const n = pageNumber + 1;
    if (n <= maxPageNumber) {
      setPageNumber(n);
    }
  };

  const [scale, setScale] = useState<number>(1);
  const handleZoomReset = () => {
    setScale(1);
    resetDrag();
  };
  const handleZoomOut = () => {
    setScale(scale - 0.1);
  };
  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };
  const scalePercent = `${Math.round(scale * 100)}%`;

  const handleDownload = () => {
    autoDownloadFile(srcURL, props.fileName);
  };

  const handleDragEnd = useCallback(
    (state: DraggingInfo) => {
      dispatch({
        type: 'changeOffset',
        offset: { ...state.offset },
      });
    },
    [dispatch]
  );

  const handleSelectionEnd = useCallback(
    (state: DraggingInfo) => {
      dispatch({
        type: 'endSelection',
        selection: dragStateToSelectionInfo(state),
      });
    },
    [dispatch]
  );

  const [dragState, dragTrigger, resetDrag] = useDragging({
    coord: Coord.Offset,
    complete: handleDragEnd,
  });

  useEffect(() => {
    if (!props.assetId) {
      return;
    }
    resetDrag();
  }, [resetDrag, props.assetId]);

  const [rangeState, rangeTrigger] = useDragging({
    coord: Coord.Offset,
    complete: handleSelectionEnd,
  });

  const handleDragStart = (evt_: MouseEvent<unknown>) => {
    // 左クリックでのドラッグ開始に反応
    // See https://w3schools.com/jsref/event_which.asp
    const evt = evt_.nativeEvent;
    if (evt.which === 1) {
      if (
        controls?.cropping &&
        ((evt.shiftKey && dragMode === 'move') ||
          (!evt.shiftKey && dragMode === 'selection'))
      ) {
        rangeTrigger(evt);
        onSelectionStart && onSelectionStart();
      } else {
        dragTrigger(evt);
      }
    }
  };

  useEffect(() => {
    const offset = {
      x: dragState.offset.x + (dragState.delta?.x || 0),
      y: dragState.offset.y + (dragState.delta?.y || 0),
    };
    setOffset(offset);
    if (!dragState.dragStart && !dragState.delta) {
      dispatch({ type: 'changeOffset', offset: offset });
    } else {
      dispatch({ type: 'dragging', offset: offset });
    }
  }, [dispatch, dragState]);

  useEffect(() => {
    if (!rangeState.delta) {
      return;
    }
    dispatch({
      type: 'changeSelection',
      selection: dragStateToSelectionInfo(rangeState),
    });
  }, [dispatch, rangeState]);

  useEffect(() => {
    dispatch({ type: 'changeScale', scale: scale });
  }, [dispatch, scale]);

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }
    const observer = new ResizeObserver((entries) => {
      if (!entries[0]) {
        return;
      }
      const { clientWidth, clientHeight } = entries[0].target;
      dispatch({
        type: 'viewerResize',
        size: { width: clientWidth, height: clientHeight },
      });
    });
    observer.observe(wrapperRef.current);
    return () => {
      observer.disconnect();
    };
  }, [dispatch]);

  useEffect(() => {
    if (!controls?.cropping) {
      setDragMode('move');
    }
  }, [controls?.cropping]);

  useEffect(() => {
    const handleKeydown = (evt: globalThis.KeyboardEvent) => {
      if (evt.key === 'Shift') {
        setIsShiftKeyDown(true);
      }
    };
    const handleKeyup = (evt: globalThis.KeyboardEvent) => {
      if (evt.key === 'Shift') {
        setIsShiftKeyDown(false);
      }
    };
    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('keyup', handleKeyup);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('keyup', handleKeyup);
    };
  }, []);

  const dragStateToSelectionInfo = (_range: DraggingInfo) => {
    return {
      x1: (_range.dragStart?.x || 0) - (_range.dragStart?.offsetX || 0),
      y1: (_range.dragStart?.y || 0) - (_range.dragStart?.offsetY || 0),
      x2:
        (_range.dragStart?.x || 0) -
        (_range.dragStart?.offsetX || 0) +
        (_range.delta?.x || 0),
      y2:
        (_range.dragStart?.y || 0) -
        (_range.dragStart?.offsetY || 0) +
        (_range.delta?.y || 0),
    };
  };

  const renderPager = () => {
    if (!controls?.pager) {
      return <></>;
    }
    return (
      <div className="pager">
        <KeyboardArrowLeftIcon
          className={
            pageNumber <= 1 ? 'disabled-svg-icon arrow-icon' : 'arrow-icon'
          }
          onClick={handlePrevPage}
        />
        <span>
          {pageNumber} / {maxPageNumber}
        </span>
        <KeyboardArrowRightIcon
          className={
            maxPageNumber <= pageNumber
              ? 'disabled-svg-icon arrow-icon'
              : 'arrow-icon'
          }
          onClick={handleNextPage}
        />
      </div>
    );
  };

  const renderScaling = () => {
    if (!controls?.scaling) {
      return <></>;
    }
    return (
      <>
        <ViewerControlButton
          name="Viewer-ZoomIn"
          iconType="zoom_in"
          onClick={handleZoomIn}
        />
        <ViewerControlButton
          name="Viewer-ZoomReset"
          iconType="fit_size"
          onClick={handleZoomReset}
        />
        <ViewerControlButton
          name="Viewer-ZoomOut"
          iconType="zoom_out"
          onClick={handleZoomOut}
        />
      </>
    );
  };

  const renderCropping = () => {
    if (!controls?.cropping) {
      return <></>;
    }

    return (
      <>
        <hr className="divider" />
        <DragModeSelector
          mode={dragMode}
          selectionShape={selectionState.selection?.attributes?.selectionShape}
          croppingOption={controls.croppingOption ?? defaultCropOption}
          onChangeMode={(mode, shapeType) => {
            setDragMode(mode);
            if (mode === 'selection' && shapeType) {
              dispatch({ type: 'changeShapeType', shapeType });
            }
          }}
        />
      </>
    );
  };

  const getOverlayElements = () => {
    const overlayElements: OverlayItem[] = [];
    if (props.overlayItems instanceof Array) {
      overlayElements.push(...props.overlayItems);
    } else if (props.overlayItems) {
      overlayElements.push(props.overlayItems);
    }

    if (
      !controls?.cropping ||
      !selectionState.selection ||
      !selectionState.selection.attributes?.selectionShape
    ) {
      return overlayElements;
    }
    const croppingShape =
      croppingOption?.shapeList[
        selectionState.selection.attributes?.selectionShape
      ]?.component;
    if (!croppingShape) {
      return overlayElements;
    }
    overlayElements.push({
      plotInfo: selectionState.selection,
      isActive: true,
      component: croppingShape,
    });
    return overlayElements;
  };

  const renderDownloading = () => {
    if (typeof controls?.downloading !== 'boolean' || !controls?.downloading) {
      return controls?.downloading || <></>;
    }
    return (
      <IconButton
        name="downloadIcon"
        className="btn btn-normal"
        buttonType="basic"
        properties={[
          {
            name: 'downloadIcon',
            propertyName: 'downloadIcon',
            propertyValue: 'downloadIcon',
          },
        ]}
        onClick={handleDownload}
        iconType="download"
      />
    );
  };

  const renderExtraControls = () => {
    if (!(controls?.extraControls instanceof Array)) {
      return (
        controls?.extraControls && (
          <>
            <hr className="divider" />
            {controls?.extraControls}
          </>
        )
      );
    }
    return (
      <>
        {controls?.extraControls.map(
          (control, index) =>
            control && (
              <React.Fragment key={index}>
                <hr className="divider" />
                {control}
              </React.Fragment>
            )
        )}
      </>
    );
  };

  return (
    <div className={`Viewer ${className}`}>
      {controls && (
        <div className="controls" style={{ width: props.width }}>
          <div className="filename">
            {controls.filename ? props.fileName : ''}
          </div>
          {renderPager()}
          {controls.scaling && <span>{scalePercent}</span>}
          {renderDownloading()}
        </div>
      )}

      <div
        className="view-area"
        style={{
          width: props.width,
          height: props.height,
        }}
        ref={wrapperRef}
      >
        <div className="viewer-control-area">
          {renderScaling()}
          {controls?.extraControlsPosition !== 'bottom' &&
            renderExtraControls()}
          {renderCropping()}
          {controls?.extraControlsPosition === 'bottom' &&
            renderExtraControls()}
        </div>
        <canvas
          className={`document ${dragMode} ${
            controls?.cropping && isShiftKeyDown ? 'flip' : ''
          }`}
          style={{
            transform: `scale(${scale}) translate(${offset.x}px, ${offset.y}px)`,
          }}
          width={640}
          height={480}
          onMouseDown={handleDragStart}
          ref={canvasRef}
        />
        <ViewerOverlay
          className={
            isLoading || selectionState.viewerInfo.isDragging ? 'dragging' : ''
          }
          viewerInfo={{
            ...selectionState.viewerInfo,
            scale,
            offset,
          }}
          overlayItems={getOverlayElements()}
        />
      </div>
      {isLoading && <LoadingIcon />}
    </div>
  );
};
