import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
} from '~/shared/components';
import {
  BusinessUnitBranchDialog,
  BusinessUnitBranchFormOption,
  ConfirmationDialog,
  PageNavigation,
  SimpleIconListView,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  FullMethodName_ListBusinessUnitBranchs,
  PageState,
  ViewId,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getPathnameByViewId,
  getWorkerExceptionMessage,
  includeInputValidateError,
} from '~/shared/utils';
import './OcpBusinessUnitProfileInput.css';

interface ocpBranchList {
  id: string;
  businessUnitBranchId: string;
  displayName: string;
  address?: mtechnavi.api.company.IAddress;
  addressDisplayName: string;
  phoneNumber?: string;
  faxNumber?: string;
  overview?: string;
  updatedAt: Long.Long | null;
}

const VIEW_ID: ViewId = 'OCP_BUSINESS_UNIT_BRANCH_INPUT';
export const OcpBusinessUnitBranchInput = () => {
  const sourcePageInfo = useRef((useLocation().state as PageState) ?? []);

  const intl = useIntl();
  const navi = useNavigate();
  const backPageUrl = useMemo(
    () => getPathnameByViewId(sourcePageInfo.current.sourceViewId)?.path ?? '/',
    [sourcePageInfo]
  );
  const myCompanyInfo = useRef<mtechnavi.api.company.ICompany>();

  // ラベル
  const labelSave = GetMessageWithIntl(intl, {
    id: 'save',
  });

  type TabName = 'branch';
  const tabs: TabName[] = ['branch'];
  const [tabPanel, setTabPanel] = useState<TabName>('branch');
  const isCurrentTab = (target: TabName) => {
    return tabPanel === target;
  };

  // メッセージ
  const viewMessageBack: MessageProps = {
    id: 'confirmationDialogMessageBack',
    prefixId: VIEW_ID,
  };
  const viewMessageSave: MessageProps = {
    id: 'C0000003',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'save' }),
    },
  };
  const viewMessageDelete: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };

  const [isLoading, setLoading] = useState(false);

  // 画面レイアウト
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);

  // 画面項目
  const [isBranchDialog, setBranchDialog] = useState(false);
  const [selectId, setSelectId] = useState('');
  const [listData, setListData] = useState<ocpBranchList[]>([]);
  const [branchData, setBranchData] = useState<BusinessUnitBranchFormOption>();
  const [updatedProperties, setUpdatedProperties] =
    useState<sharelib.IEmbeddedUpdatedProperties>();

  // 確認ダイアログ（戻る）
  const [isBackConfirmOpen, setBackConfirmOpen] = useState(false);
  // 確認ダイアログ（保存）
  const [isSaveConfirmOpen, setSaveConfirmOpen] = useState(false);
  // 確認ダイアログ（削除）
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState('');

  // 入力チェック
  const formAreaRef = useRef(null);

  const isInputError = (): boolean => {
    return includeInputValidateError(document, intl, []);
  };

  const getBusinessUnitBranchList = async () => {
    return (await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: FullMethodName_ListBusinessUnitBranchs,
      filter: {},
      requestBody: {
        companyIds: [myCompanyInfo.current?.companyId as string],
      },
      sort: [], // ソートは画面項目セット時に行うため不要
    })) as mtechnavi.api.company.ListBusinessUnitBranchsResponse;
  };

  const handleDelete = () => {
    setListData(listData.filter((v) => v.id !== deleteTargetId));
    setDeleteTargetId('');
    setDeleteConfirmOpen(false);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await saveBusinessUnitBranch();
      setSaveConfirmOpen(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    backToPreviewPage();
  };

  const saveBusinessUnitBranch = async () => {
    const branchList: mtechnavi.api.company.IBusinessUnitBranch[] = [];

    listData.forEach((branch) => {
      const request: mtechnavi.api.company.IBusinessUnitBranch = {
        companyId: myCompanyInfo.current?.companyId,
        businessUnitBranchId: branch.businessUnitBranchId,
        updatedAt: branch.updatedAt,
        displayName: branch.displayName,
        address: branch.address,
        phoneNumber: branch.phoneNumber,
        faxNumber: branch.faxNumber,
        overview: branch.overview,
      };
      branchList.push(request);
    });

    await window.App.services.ui.worker.apiCall({
      actionName: 'saveOcpBranch',
      request: {
        businessUnitBranchs: branchList,
        companyId: myCompanyInfo.current?.companyId ?? '',
      },
    });
  };

  // 戻るページ
  const backToPreviewPage = () => {
    const ids = [] as string[];
    sourcePageInfo.current.ids?.map((v) => ids.push(v));
    const state: PageState = {
      ids: sourcePageInfo.current.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.current.naviFilters,
      beforeStateIds: sourcePageInfo.current.beforeStateIds,
      baseViewOption: sourcePageInfo.current.baseViewOption,
      confirmationViewOption: sourcePageInfo.current.confirmationViewOption,
    };
    navi(backPageUrl, { state });
  };

  // 並び替え
  const listDataSort = (data: ocpBranchList[]) => {
    data.sort((val1, val2) =>
      val1.displayName.localeCompare(val2.displayName, 'ja')
    );
    setListData(
      data.map(
        (v): ocpBranchList => ({
          id: v.id,
          businessUnitBranchId: v.businessUnitBranchId,
          displayName: v.displayName ?? '',
          address: v.address,
          addressDisplayName: v.addressDisplayName,
          phoneNumber: v.phoneNumber,
          faxNumber: v.faxNumber,
          overview: v.overview,
          updatedAt: v.updatedAt,
        })
      )
    );
  };

  // 画面データ取得
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        // 自社情報を取得
        myCompanyInfo.current =
          await window.App.services.companyService.getCompany({});

        const branchList = (await getBusinessUnitBranchList())
          .items as mtechnavi.api.company.IBusinessUnitBranch[];
        console.info(branchList);
        if (branchList) {
          const ocpList: ocpBranchList[] = [];
          branchList.forEach((list) => {
            const data: ocpBranchList = {
              id: list.businessUnitBranchId ?? (listData.length + 1).toString(),
              displayName: list.displayName ?? '',
              address: list.address ?? undefined,
              phoneNumber: list.phoneNumber ?? '',
              faxNumber: list.faxNumber ?? '',
              overview: list.overview ?? '',
              addressDisplayName:
                (list.address?.region?.displayNameLang?.ja ?? '') +
                list.address?.addressLines?.join(''),
              businessUnitBranchId: list.businessUnitBranchId ?? '',
              updatedAt: list.updatedAt ?? null,
            };
            setUpdatedProperties(list.updatedProperties!);
            ocpList.push(data);
          });
          listDataSort(ocpList);
        }
        setLoading(false);
      })();
    } catch (err) {
      error(getExceptionMessage(intl, err));
    }

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  // リサイズ
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  const renderBranchComponent = () => {
    return (
      <>
        <div className="tab-info-body">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-80">
                <SimpleIconListView
                  data={listData}
                  viewOptions={{
                    previewRowCount: 10,
                    keyColumn: 'id',
                    columns: [
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_BRANCH_INPUT.displayName',
                        },
                        propertyName: 'displayName',
                        width: '16%',
                      },
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_BRANCH_INPUT.addressDisplayName',
                        },
                        propertyName: 'addressDisplayName',
                      },
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_BRANCH_INPUT.phoneNumber',
                        },
                        propertyName: 'phoneNumber',
                        width: '11rem',
                      },
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_BRANCH_INPUT.overview',
                        },
                        propertyName: 'overview',
                        width: '22%',
                      },
                    ],
                    omitFooter: true,
                  }}
                  actionOptions={{}}
                  iconMenuOptions={{
                    iconMenu: [
                      {
                        name: 'edit',
                        displayName: '編集',
                        func: (item: ocpBranchList) => {
                          const selectData: BusinessUnitBranchFormOption = {
                            displayName: item.displayName,
                            address: item.address,
                            phoneNumber: item.phoneNumber,
                            faxNumber: item.faxNumber,
                            overview: item.overview,
                          };
                          setSelectId(item.id);
                          setBranchData(selectData);
                          setBranchDialog(true);
                        },
                      },
                      {
                        name: 'delete',
                        displayName: '削除',
                        func: (item: ocpBranchList) => {
                          setDeleteTargetId(item.id);
                          setDeleteConfirmOpen(true);
                        },
                      },
                    ],
                  }}
                />
              </div>
              <div className="w-20">
                <CaptionButton
                  buttonType="basic"
                  caption="新規行追加"
                  name=""
                  onClick={() => {
                    setSelectId('');
                    setBranchData({});
                    setBranchDialog(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="OcpBusinessUnitBranchInput">
          <div className="header">
            <PageNavigation
              backpagePath="/opc-businessunit-profile-confirmation"
              pageInfo={{
                isVisibleMoveNavi: false,
              }}
              infoOption={{
                lastUpdateInfo: {
                  isVisibleUpdateInfo: true,
                  attribute: updatedProperties?.updatedBy?.email ?? '',
                  attributeAt: updatedProperties?.updatedAt ?? undefined,
                  content: updatedProperties?.updatedBy?.displayName ?? '',
                },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => setBackConfirmOpen(true)}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
            ref={formAreaRef}
          >
            <div className="input">
              <div className="basic-info-body">
                <div className="tabPanelContents">
                  {tabs.map((v, index) => {
                    const selectPane = v === tabPanel ? 'current' : '';
                    return (
                      <div
                        key={`tab-${index}`}
                        className={`tabPanel ${selectPane}`}
                        onClick={() => setTabPanel(v)}
                      >
                        {GetMessageWithIntl(intl, { prefixId: VIEW_ID, id: v })}
                      </div>
                    );
                  })}
                </div>
                {isCurrentTab('branch') && renderBranchComponent()}
              </div>
            </div>
          </div>
          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                <CaptionButton
                  name="saveButton"
                  buttonType="basic"
                  caption={labelSave}
                  onClick={() => {
                    if (isInputError()) {
                      return;
                    }
                    setSaveConfirmOpen(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Toast />
      </Container>
      {/* 戻る 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isBackConfirmOpen}
        viewMessage={viewMessageBack}
        onDecision={() => {
          backToPreviewPage();
        }}
        onCancel={() => {
          setBackConfirmOpen(false);
        }}
      />
      <BusinessUnitBranchDialog
        isOpen={isBranchDialog}
        inputMode={true}
        inputOption={branchData}
        onCancel={() => {
          setBranchDialog(false);
        }}
        onDesicion={(v) => {
          if (selectId) {
            listData.map((e) => {
              if (e.id === selectId) {
                e.displayName = v.displayName ?? '';
                e.address = v.address;
                e.phoneNumber = v.phoneNumber;
                e.faxNumber = v.faxNumber;
                e.overview = v.overview;
                e.addressDisplayName =
                  (v.address?.region?.displayNameLang?.ja ?? '') +
                  v.address?.addressLines?.join('');
              }
            });
          } else {
            const data: ocpBranchList = {
              id: (listData.length + 1).toString(),
              displayName: v.displayName ?? '',
              address: v.address,
              phoneNumber: v.phoneNumber,
              faxNumber: v.faxNumber,
              overview: v.overview,
              addressDisplayName:
                (v.address?.region?.displayNameLang?.ja ?? '') +
                v.address?.addressLines?.join(''),
              businessUnitBranchId: '',
              updatedAt: null,
            };
            console.info(data);
            listData.push(data);
          }
          listDataSort(listData);
          setBranchDialog(false);
        }}
      />
      {/* 保存 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isSaveConfirmOpen}
        viewMessage={viewMessageSave}
        onDecision={() => {
          handleSave();
        }}
        onCancel={() => {
          setSaveConfirmOpen(false);
        }}
      />
      {/* 削除 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isDeleteConfirmOpen}
        viewMessage={viewMessageDelete}
        onDecision={() => {
          handleDelete();
        }}
        onCancel={() => {
          setDeleteConfirmOpen(false);
        }}
      />
      {isLoading && <LoadingIcon />}
    </>
  );
};
