import { useState, useEffect, useRef, useMemo } from 'react';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword as fbUpdatePassword,
} from 'firebase/auth';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { SignOutButton } from './SignOutButton';
import './SessionCard.css';
import {
  getExceptionMessage,
  includeInputValidateError,
  RequireInput,
} from '~/shared/utils';
import { useIntl } from 'react-intl';
import {
  GetMessage,
  ModalDialogComponent,
  ModalDialogComponentProps,
  success,
  error,
  GetMessageWithIntl,
} from '~/shared/components';

import { Textbox } from '~/shared/components/ui';
import { CaptionButton, IconWithCaptionButton } from '../ui/Button';

import { validatePassword } from '~/shared/utils';
import { useLocation } from 'react-router-dom';

export function SessionCard() {
  const auth = useAuth();
  const intl = useIntl();
  const pathname = useLocation().pathname;
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [isRequired, setRequired] = useState(true);
  const [isCurrentPasswordError, setCurrentPasswordError] = useState(false);
  const [isInvalidPasswordError, setInvalidPasswordError] = useState(false);
  const [companyName, setCompanyName] = useState<string>();
  const updateProcessing = useRef(false); //連打による処理派生防止
  const errorCheckProcessing = useRef(false); //連打による処理派生防止
  const [isDisabled, setDisabled] = useState(false); //連打防止のボタン制御

  const successMessage = GetMessage({ id: 'I0000001' });
  const errorMessage = GetMessage({ id: 'E0000048' });
  const errorMessageNone = GetMessage({ id: 'E0000040' });
  const errorMessageNoMatch = GetMessage({ id: 'E0000039' });
  const errorInvalieMessage = GetMessage({ id: 'E0000092' });

  const policy1 = GetMessage({ id: 'POLICY_1' });
  const policy2 = GetMessage({ id: 'POLICY_2' });
  const policy3 = GetMessage({ id: 'POLICY_3' });
  const policy4 = GetMessage({ id: 'POLICY_4' });

  const formAreaRef = useRef(null);

  const passwordLogin = useMemo(() => {
    return !window.App.config.singleSignOn.enabled;
  }, []);

  // ユーザーの会社名を取得する
  useEffect(() => {
    (async () => {
      try {
        const res = await window.App.services.companyService.getCompany({});
        setCompanyName(res.displayNameLang.ja);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
    // 初回起動時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      resetInputForm();
    }
    // isModalOpen 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  // フォームのクリア処理
  const resetInputForm = () => {
    setRequired(false);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmationPassword('');
    setCurrentPasswordError(false);
    setInvalidPasswordError(false);
    setTimeout(() => {
      setRequired(true);
    }, 400);
  };

  // リセット情報の送信
  const updatePassword = async () => {
    setInvalidPasswordError(false);
    const user = window.App.firebaseApps.auth.currentUser;
    if (!user) return;
    // なぜかif文の中でリターンするとスルーされる時あったので判定を追加
    let isValidatePassword = false;
    if (!validatePassword(newPassword)) {
      setInvalidPasswordError(true);
      setDisabled(false);
      isValidatePassword = true;
      return;
    }
    if (isValidatePassword) return;
    if (updateProcessing.current) return;
    updateProcessing.current = true;
    try {
      const credetil = await EmailAuthProvider.credential(
        user?.email ?? '',
        currentPassword
      );
      await reauthenticateWithCredential(user, credetil);
      await fbUpdatePassword(user, newPassword);
      success([successMessage]);
      setModalOpen(false);
      resetInputForm();
    } catch (err) {
      error([errorMessage]);
      console.error(err);
    } finally {
      setDisabled(false);
      updateProcessing.current = false;
    }
  };

  const isInputError = (): boolean => {
    const targetElm = document.querySelector('.change-password-daialog');

    const validateErrorList: RequireInput[] = [];
    validateErrorList.push({
      value: currentPassword ?? '',
      ref: formAreaRef,
    });
    validateErrorList.push({
      value: newPassword ?? '',
      ref: formAreaRef,
    });
    validateErrorList.push({
      value: confirmationPassword ?? '',
      ref: formAreaRef,
    });

    if (includeInputValidateError(targetElm, intl, validateErrorList)) {
      return true;
    }
    return false;
  };

  // ユーザー情報と現在のパスワードが一致するかを確認
  const validateConfirmationPassword = (value: string): string[] => {
    // バリデーションエラーメッセージ
    const message: string[] = [];
    if (newPassword !== value) {
      message.push(errorMessageNoMatch);
    }

    return message;
  };

  // 現在のパスワードが有効かチェック
  const checkCurrentPassWord = async () => {
    setCurrentPasswordError(false);
    if (!auth.user?.email) return false;
    try {
      await auth.signInWithEmailAndPassword(auth.user?.email, currentPassword);
    } catch (err) {
      setCurrentPasswordError(true);
      setDisabled(false);
      return false;
    }
    return true;
  };

  const changePasswordAction = async () => {
    setDisabled(true);
    if (await isInputError()) {
      setDisabled(false);
      return;
    }

    if (errorCheckProcessing.current) return;
    errorCheckProcessing.current = true;
    checkCurrentPassWord()
      .then((res) => {
        if (res) {
          updatePassword();
        }
      })
      .catch(() => {
        error([errorMessage]);
        setDisabled(false);
      })
      .finally(() => {
        // 更新が終わるまではボタンを押せないようにする
        errorCheckProcessing.current = false;
      });
  };

  const DaialogPassWordContent = () => (
    <div className="change-password-daialog">
      <div className="change-password-content" ref={formAreaRef}>
        <div className="content-head">
          <h3 className="content-head-title">
            {GetMessageWithIntl(intl, { id: 'changePassword' })}
          </h3>
        </div>
        <div className="content-area">
          <div className="inputfield-items">
            <div className="field-block long-box">
              {/* 現在のパスワード */}
              <Textbox
                name="header.user.current.password"
                type="password"
                value={currentPassword}
                validateOption={{
                  required: isRequired,
                }}
                labelId="header.user.current.password"
                columns={['header.user.current.password']}
                properties={[
                  {
                    name: 'header.user.current.password',
                    propertyName: 'displayName',
                    propertyValue: 'header.user.current.password',
                  },
                ]}
                onChangeState={setCurrentPassword}
              />
            </div>
          </div>
          <div className="field-block long-box">
            {/* 新しいパスワード */}
            <Textbox
              name="header.user.new.password"
              type="password"
              value={newPassword}
              validateOption={{
                required: isRequired,
              }}
              labelId="header.user.new.password"
              columns={['header.user.new.password']}
              properties={[
                {
                  name: 'header.user.new.password',
                  propertyName: 'displayName',
                  propertyValue: 'header.user.new.password',
                },
              ]}
              onChangeState={setNewPassword}
            />
          </div>
          <div className="field-block long-box">
            {/* 新しいパスワード（確認） */}
            <Textbox
              name="header.user.confirmation.password"
              type="password"
              value={confirmationPassword}
              validateOption={{
                required: isRequired,
              }}
              validator={validateConfirmationPassword}
              labelId="header.user.confirmation.password"
              columns={['header.user.confirmation.password']}
              properties={[
                {
                  name: 'header.user.confirmation.password',
                  propertyName: 'displayName',
                  propertyValue: 'header.user.confirmation.password',
                },
              ]}
              onChangeState={setConfirmationPassword}
            />
          </div>
        </div>
        <div className="policy">
          <p>{policy1}</p>
          <p>{policy2}</p>
          <p>{policy3}</p>
          <p>{policy4}</p>
        </div>
        <div className="content-footer item-line">
          <CaptionButton
            name="cancelBtn"
            caption="キャンセル"
            className="btn"
            properties={[]}
            onClick={() => {
              resetInputForm();
              setModalOpen(false);
            }}
            buttonType="cancel"
          />
          <CaptionButton
            name="sendBtn"
            caption="パスワード変更"
            className="btn"
            properties={[]}
            onClick={() => {
              changePasswordAction();
            }}
            disabled={isDisabled}
            buttonType="basic"
          />
        </div>
        {isCurrentPasswordError && <p className="error">{errorMessageNone}</p>}
        {isInvalidPasswordError && (
          <p className="error">{errorInvalieMessage}</p>
        )}
      </div>
    </div>
  );

  const changePasswordModalProps: ModalDialogComponentProps = {
    modalIsOpen: isModalOpen,
    headerLabelId: { prefixId: 'DIALOG_CHANGE_PASSWORD_TITLE' },
    messageLabelId: { prefixId: 'DIALOG_CHANGE_PASSWORD_DECLINE' },
    send: () => {},
    cancel: () => setModalOpen(false),
    elements: DaialogPassWordContent(),
  };

  return (
    <div className="SessionCard">
      <dl className="control">
        <dt className="control-item">
          <div className="username">
            {companyName}
            <span className="aid user-email">{auth.user?.email}</span>
          </div>
        </dt>
        {passwordLogin && (
          <dd className="control-item">
            <IconWithCaptionButton
              name="passwordChange"
              caption="パスワード変更"
              className="passwordChange"
              buttonType="basic"
              iconType="password"
              onClick={() => setModalOpen(true)}
              properties={[
                {
                  name: 'passwordChange',
                  propertyName: 'passwordChange',
                  propertyValue: 'passwordChange',
                },
              ]}
            />
          </dd>
        )}
        <dd className="control-item">
          <SignOutButton />
        </dd>
        <dd className="control-item">
          <div className="linkItems">
            <a href="/privacypolicy" target="_blank" rel="noreferrer">
              プライバシーポリシー
            </a>
            <a href="/term" target="_blank" rel="noreferrer">
              利用規約
            </a>
            <a href={`/help${pathname}`} target="_blank" rel="noreferrer">
              ヘルプ
            </a>
          </div>
        </dd>
      </dl>
      <ModalDialogComponent {...changePasswordModalProps} />
    </div>
  );
}
