import React, { useEffect, useState } from 'react';
import { Forum } from '../../ui/Forum/Forum';
import { useForumTool } from './useForumTool';
import { error } from '../../parts/Toast/Toast';
import { GetMessageWithIntl } from '../../parts/Message/Message';
import { useIntl } from 'react-intl';
import { getUsableBaseThreadId } from '../../ui/Forum';
import { getExceptionMessage } from '~/shared/utils';
import './ForumTool.css';

/** ツールメニュー用のフォーラム UI */
export const ForumTool = () => {
  const intl = useIntl();
  const {
    typeName,
    resourceId,
    viewId,
    companyIds,
    isControlCompletion,
    closeForum,
  } = useForumTool();
  const [baseThreadId, setBaseThreadId] = useState<string>();

  useEffect(() => {
    (async () => {
      if (!typeName || !resourceId) {
        // 親スレッドを特定できる状況にないのでフォーラム UI を閉じる
        error([GetMessageWithIntl(intl, { id: 'E0000145' })]);
        closeForum();
        return;
      }
      try {
        const baseThreadId = await getUsableBaseThreadId({
          typeName,
          resourceId,
        });
        if (!baseThreadId) {
          // 親スレッドを取得できないのでフォーラム UI を閉じる
          error([GetMessageWithIntl(intl, { id: 'E0000145' })]);
          closeForum();
          return;
        }
        setBaseThreadId(baseThreadId);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        closeForum();
        throw err;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeName, resourceId]);

  return (
    <div className="ForumTool">
      <Forum
        baseThreadId={baseThreadId}
        viewId={viewId}
        companyIds={companyIds}
        controls={{
          isControlCompletion: isControlCompletion,
        }}
      />
    </div>
  );
};
