import { CSSProperties } from 'react';
import { OverlayItemProps } from '../ViewerOverlay';
import './Arrow.css';

export const SHAPE_TYPE_ARROW = 'Arrow';

const MIN_SIZE = 40;
const MAX_SIZE = 180;

const calcSize = (width: number, height: number) => {
  let sideRange = height;
  if (width > height) {
    sideRange = width;
  }
  if (sideRange <= MIN_SIZE) {
    return MIN_SIZE;
  }
  if (sideRange >= MAX_SIZE) {
    return MAX_SIZE;
  }
  return sideRange;
};

/**
 * オーバーレイ描画用の図形（矢印）
 */
export const Arrow = ({
  plotInfo,
  isActive,
  className,
  onClick,
}: OverlayItemProps) => {
  const size = calcSize(
    Math.abs(plotInfo.x2 - plotInfo.x1),
    Math.abs(plotInfo.y2 - plotInfo.y1)
  );
  const rangeStyle: CSSProperties = {
    WebkitMaskImage: `url('/assets/Viewer/arrow.svg')`,
    maskImage: `url('/assets/Viewer/arrow.svg')`,
    top: plotInfo.y1 - size / 2,
    left: plotInfo.x1 - size / 2,
    width: size,
    height: size,
    transform: `rotate(${Math.atan2(
      plotInfo.y2 - plotInfo.y1,
      plotInfo.x2 - plotInfo.x1
    )}rad) translate(49%, 2.8%)`,
  };
  return (
    <div
      className={`Arrow ${isActive ? 'active' : ''} ${className ?? ''}`}
      style={rangeStyle}
      onClick={onClick}
    ></div>
  );
};
