import { SearchListViewDialog } from '~/shared/components/ui/SearchListViewDialog';
import { FullMethodName_ListAssets } from '~/worker';
import { CaptionButton } from '~/shared/components/ui/Button';
import { useState } from 'react';
import { mtechnavi } from '~/shared/libs/clientsdk';

interface Props {
  selectedAsset?: mtechnavi.api.assetinventory.IAsset;
  onSelectChange: (asset?: mtechnavi.api.assetinventory.IAsset) => void;
}
export const AssetSelector = ({ selectedAsset, onSelectChange }: Props) => {
  const [isOpenSearch, setOpenSearch] = useState(false);

  return (
    <>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            <CaptionButton
              name="searchAsset"
              buttonType="basic"
              caption="アセットの選択"
              onClick={() => setOpenSearch(true)}
            />
            {selectedAsset && (
              <span>
                {` Asset : ${selectedAsset?.filename} ( ${selectedAsset?.assetId} )`}
              </span>
            )}
          </div>
        </div>
      </div>
      <SearchListViewDialog
        viewId="ASSET_ASSET_LIST"
        fullMethodName={FullMethodName_ListAssets}
        isOpen={isOpenSearch}
        menuTarget="assetId"
        headerLabelId={{}}
        selectLimit={1}
        onCloseChange={() => setOpenSearch(false)}
        onSelectChange={(items) => {
          const selection = (
            items as unknown as mtechnavi.api.assetinventory.IAsset[]
          ).at(0);
          onSelectChange(selection);
          setOpenSearch(false);
        }}
      />
    </>
  );
};
