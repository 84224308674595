import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_DeleteUserSetting } from 'worker/fullMethodName/crud';

type DeleteUserSettingParam = Pick<
  mtechnavi.api.uicontroller.IUserSetting,
  'userSettingId' | 'updatedAt'
>;

export interface DeleteUserSettingReq {
  actionName: 'deleteUserSetting';
  request: DeleteUserSettingParam;
}

export const deleteUserSetting = async (
  worker: AppSaveWorker,
  req: DeleteUserSettingParam
) => {
  return worker.invokeListRequest<
    mtechnavi.api.uicontroller.IDeleteUserSettingRequest,
    void
  >(FullMethodName_DeleteUserSetting, {
    userSetting: req,
  });
};
