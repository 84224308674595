import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
} from '~/shared/components';
import {
  ConfirmationDialog,
  PageNavigation,
  Textarea,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  FullMethodName_ListBusinessUnitSkills,
  PageState,
  ViewId,
  convertSkillTreeToCategorizedList,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getPathnameByViewId,
  getWorkerExceptionMessage,
  includeInputValidateError,
} from '~/shared/utils';
import {
  CategorizedCheckList,
  CategorizedList,
  CheckItem,
} from '~/shared/components/ui/CategorizedCheckList';
import './OcpBusinessUnitProfileInput.css';

const getBusinessUnitSkills = async (reloadFlg: boolean, companyId: string) => {
  return (await window.App.services.ui.worker.filter({
    action: reloadFlg ? 'reload' : 'query',
    fullMethodName: FullMethodName_ListBusinessUnitSkills,
    filter: {},
    requestBody: {
      companyIds: [companyId],
    },
    sort: [],
  })) as mtechnavi.api.company.IListBusinessUnitSkillsResponse;
};

const VIEW_ID: ViewId = 'OCP_BUSINESS_UNIT_SKILL_INPUT';
export const OcpBusinessUnitSkillInput = () => {
  const sourcePageInfo = useRef((useLocation().state as PageState) ?? []);
  const intl = useIntl();
  const navi = useNavigate();
  const backPageUrl = useMemo(
    () => getPathnameByViewId(sourcePageInfo.current.sourceViewId)?.path ?? '/',
    [sourcePageInfo]
  );

  // ラベル
  const labelSave = GetMessageWithIntl(intl, {
    id: 'save',
  });

  // メッセージ
  const viewMessageBack: MessageProps = {
    id: 'confirmationDialogMessageBack',
    prefixId: VIEW_ID,
  };
  const viewMessageSave: MessageProps = {
    id: 'C0000003',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'save' }),
    },
  };

  const [isLoading, setLoading] = useState(false);

  // 画面レイアウト
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);

  // 編集元データ格納用
  const [companyId, setCompanyId] = useState('');
  const businessUnitSkillData =
    useRef<mtechnavi.api.company.IBusinessUnitSkill>();
  const [updatedProperties, setUpdatedProperties] =
    useState<sharelib.IEmbeddedUpdatedProperties | null>();
  const [initialSkillIds, setInitialSkillIds] = useState<string[]>([]); // ループを防ぐため別で保持する
  // 画面項目
  const [skillTreeData, setSkillTreeData] = useState<CategorizedList>([]);
  const [businessUnitSkillItems, setBusinessUnitSkillItems] = useState<
    CheckItem[]
  >([]);
  const [otherSkill, setOtherSkill] = useState<string>();

  // 確認ダイアログ（戻る）
  const [isBackConfirmOpen, setBackConfirmOpen] = useState(false);
  // 確認ダイアログ（保存）
  const [isSaveConfirmOpen, setSaveConfirmOpen] = useState(false);

  // 入力チェック
  const formAreaRef = useRef(null);

  const isInputError = (): boolean => {
    return includeInputValidateError(document, intl, []);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await saveBusinessUnitSkill();
      setSaveConfirmOpen(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    backToPreviewPage();
  };

  const saveBusinessUnitSkill = async () => {
    return window.App.services.ui.worker.apiCall({
      actionName: 'saveOcpSkill',
      request: {
        companyId,
        ...businessUnitSkillData?.current,
        businessUnitSkillItemIds: businessUnitSkillItems.map(
          (item) => item.id || ''
        ),
        otherSkill: otherSkill,
      },
    }) as Promise<mtechnavi.api.company.IBusinessUnitSkill>;
  };

  // 戻るページ
  const backToPreviewPage = () => {
    const ids = [] as string[];
    sourcePageInfo.current.ids?.map((v) => ids.push(v));
    const state: PageState = {
      ids: sourcePageInfo.current.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.current.naviFilters,
      beforeStateIds: sourcePageInfo.current.beforeStateIds,
      baseViewOption: sourcePageInfo.current.baseViewOption,
      confirmationViewOption: sourcePageInfo.current.confirmationViewOption,
    };
    navi(backPageUrl, { state });
  };

  // 画面データ取得
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        // 新規登録用に自社の companyId を保持
        const myCompanyInfo: mtechnavi.api.company.Company =
          await window.App.services.companyService.getCompany({});
        setCompanyId(myCompanyInfo.companyId);

        // 全技術項目の取得
        const skillTree =
          await window.App.services.businessUnitManagementService.getBusinessUnitSkillTree(
            {}
          );
        setSkillTreeData(convertSkillTreeToCategorizedList(skillTree));

        // 保有技術の取得
        const skills = await getBusinessUnitSkills(
          true,
          myCompanyInfo.companyId
        );
        const originalData = skills.items?.at(0);
        businessUnitSkillData.current = originalData;
        setUpdatedProperties(originalData?.updatedProperties);
        setInitialSkillIds(originalData?.businessUnitSkillItemIds || []);
        setOtherSkill(originalData?.otherSkill || '');

        setLoading(false);
      })();
    } catch (err) {
      error(getExceptionMessage(intl, err));
    }
  }, [intl]);

  // リサイズ
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="OcpBusinessUnitSkillInput">
          <div className="header">
            <PageNavigation
              backpagePath="/opc-businessunit-profile-confirmation"
              pageInfo={{
                isVisibleMoveNavi: false,
              }}
              infoOption={{
                lastUpdateInfo: {
                  isVisibleUpdateInfo: true,
                  attribute: updatedProperties?.updatedBy?.email ?? '',
                  attributeAt: updatedProperties?.updatedAt ?? undefined,
                  content: updatedProperties?.updatedBy?.displayName ?? '',
                },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => setBackConfirmOpen(true)}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
            ref={formAreaRef}
          >
            <div className="input">
              <div className="basic-info-body">
                <div className="tabPanelContents">
                  <div key="tab-0" className="tabPanel current">
                    {GetMessageWithIntl(intl, {
                      prefixId: VIEW_ID,
                      id: 'skill',
                    })}
                  </div>
                </div>
                <div className="contents">
                  <p>
                    {GetMessageWithIntl(intl, {
                      prefixId: VIEW_ID,
                      id: 'description',
                    })}
                  </p>
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-100">
                        <CategorizedCheckList
                          listData={skillTreeData}
                          checkedIds={initialSkillIds}
                          onChange={(checkedItems) => {
                            setBusinessUnitSkillItems(checkedItems);
                          }}
                          inputOption={{
                            isVisibleOpenAll: true,
                            isVisibleCloseAll: true,
                            isVisibleOpenChecked: true,
                          }}
                        ></CategorizedCheckList>
                      </div>
                    </div>
                  </div>
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-100">
                        <Textarea
                          name="otherSkill"
                          className="w-75 mh-middle"
                          value={otherSkill}
                          labelId="OCP_BUSINESS_UNIT_SKILL_INPUT.otherSkill"
                          columns={['otherSkill']}
                          onChangeState={setOtherSkill}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                <CaptionButton
                  name="saveButton"
                  buttonType="basic"
                  caption={labelSave}
                  onClick={() => {
                    if (isInputError()) {
                      return;
                    }
                    setSaveConfirmOpen(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Toast />
      </Container>
      {/* 戻る 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isBackConfirmOpen}
        viewMessage={viewMessageBack}
        onDecision={() => {
          backToPreviewPage();
        }}
        onCancel={() => {
          setBackConfirmOpen(false);
        }}
      />
      {/* 保存 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isSaveConfirmOpen}
        viewMessage={viewMessageSave}
        onDecision={() => {
          handleSave();
        }}
        onCancel={() => {
          setSaveConfirmOpen(false);
        }}
      />
      {isLoading && <LoadingIcon />}
    </>
  );
};
