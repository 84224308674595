import { useState } from 'react';
import { PartialLoading, SimpleListView } from '~/shared/components/ui';
import { BlueprintAttributeConfirm } from './BlueprintAttributeConfirm';
import { formatBool, getWorkerExceptionMessage, ViewId } from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import './BlueprintAttributes.css';
import { useIntl } from 'react-intl';
import { useBlueprintConfirmationData } from '../BlueprintConfirmationDataProvider';
import { error } from '~/shared/components';

interface BlueprintAttributesProps {
  viewId: ViewId;
  disabled?: boolean;
  onReloadAttributes?: () => void;
}
export const BlueprintAttributes = ({
  viewId,
  disabled,
  onReloadAttributes,
}: BlueprintAttributesProps) => {
  const intl = useIntl();
  const [isShowPartialLoading, setShowPartialLoading] = useState(false);
  const { blueprintContent, dispatch } = useBlueprintConfirmationData();

  const attributes = blueprintContent?.blueprintAttributes ?? [];

  const attributeViewData = attributes.map((attr) => ({
    blueprintAttributeId: attr.blueprintAttributeId,
    attributeName: attr.attributeProperty?.attributeName,
    attributeValue: attr.attributeProperty?.attributeValue,
    decision: formatBool(attr.fixed),
  }));
  const [currentIndex, setCurrentIndex] = useState(0);

  const uncompletedAttributes = attributes?.filter((attr) => !attr.fixed);
  const currentAttribute = uncompletedAttributes.at(currentIndex);

  const handleNextAttribute = () => {
    if (uncompletedAttributes.length === 0) {
      return;
    }
    if (uncompletedAttributes.length - 1 === currentIndex) {
      reloadAttributes();
      return;
    }
    setCurrentIndex((prev) => {
      // 次の属性へ(最後の場合は循環させる)
      return (prev + 1) % uncompletedAttributes.length;
    });
  };

  /** 確定して保存(更新) */
  const handleSaveAttribute = async (
    blueprintAttribute: mtechnavi.api.blueprint.IBlueprintAttribute
  ) => {
    try {
      setShowPartialLoading(true);
      const result = await window.App.services.ui.worker.apiCall({
        actionName: 'updateBlueprintAttribute',
        request: { blueprintAttribute },
      });

      // 更新後の値を反映
      dispatch({
        type: 'updateAttribute',
        attribute: { ...result?.at(0) },
      });
      if (uncompletedAttributes.length - 1 === currentIndex) {
        reloadAttributes();
      }
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      setShowPartialLoading(false);
    }
  };

  /** 確定して保存(削除) */
  const handleDeleteAttribute = async (
    blueprintAttribute: mtechnavi.api.blueprint.IBlueprintAttribute
  ) => {
    try {
      setShowPartialLoading(true);

      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteBlueprintAttribute',
        request: { blueprintAttribute },
      });

      // 削除の状態を反映
      dispatch({
        type: 'deleteAttribute',
        blueprintAttributeId: blueprintAttribute.blueprintAttributeId,
      });
      if (uncompletedAttributes.length - 1 === currentIndex) {
        reloadAttributes();
      }
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      setShowPartialLoading(false);
    }
  };

  const reloadAttributes = () => {
    setCurrentIndex(0);
    onReloadAttributes && onReloadAttributes();
  };

  return (
    <div className="BlueprintAttributes">
      <SimpleListView
        actionOptions={{}}
        data={attributeViewData}
        viewOptions={{
          currentId: currentAttribute?.blueprintAttributeId,
          focusCurrent: true,
          keyColumn: 'blueprintAttributeId',
          columns: [
            {
              propertyName: 'attributeName',
              header: { viewId, id: 'attributeName' },
            },
            {
              propertyName: 'attributeValue',
              header: { viewId, id: 'attributeValue' },
            },
            {
              propertyName: 'decision',
              width: '4rem',
              header: { id: 'decision' },
            },
          ],
          previewRowCount: currentAttribute ? 5 : 10,
          omitFooter: true,
        }}
      />

      {!disabled && currentAttribute && (
        <BlueprintAttributeConfirm
          key={currentAttribute.blueprintAttributeId}
          viewId={viewId}
          attribute={currentAttribute}
          onNextAttribute={handleNextAttribute}
          onSaveAttribute={(attr, isDelete) =>
            isDelete ? handleDeleteAttribute(attr) : handleSaveAttribute(attr)
          }
        />
      )}
      <PartialLoading isShow={isShowPartialLoading} />
    </div>
  );
};
