import React, { useEffect, useState } from 'react';
import {
  AssetInfo,
  CommentPaneContainer,
  CommentPaneProvider,
  useCommentPane,
  useCommentPaneDispatch,
} from '../CommentPane';
import { Viewer } from './Viewer';
import { error } from '~/shared/components/parts/Toast/Toast';
import { useIntl } from 'react-intl';
import { getAssetInfoListByResourceIds } from '../CommentPane/ApiUtil';
import { getExceptionMessage, ViewId } from '~/shared/utils';
import { ShapeInfo, ViewerInfo } from './util';
import { OverlayItem, OverlayItemProps } from './parts/ViewerOverlay';
import { Arrow, Rect, SHAPE_TYPE_ARROW, SHAPE_TYPE_RECT } from './parts/Shapes';

export type CommentResourceId = [string, string[]];

export interface CommentableViewerProps {
  resourceIdList?: CommentResourceId[];
  resourceType: string;
  viewId: ViewId;
  /** AssetInfo を元に送信先になり得る企業IDの配列を取得する */
  companyIdsResolver?: (assetInfo: AssetInfo) => Promise<string[]>;
}
export const CommentableViewer = ({
  resourceType,
  resourceIdList,
  viewId,
  companyIdsResolver,
}: CommentableViewerProps) => {
  const [previewAsset, setPreviewAsset] = useState({
    assetId: '',
    fileName: '',
  });
  const [fileList, setFileList] = useState<AssetInfo[]>([]);
  const [companyIds, setCompanyIds] = useState<string[]>([]);
  const intl = useIntl();

  const handleChangeFile = async (assetInfo: AssetInfo) => {
    setPreviewAsset({
      assetId: assetInfo.asset?.assetId ?? '',
      fileName: assetInfo.asset?.filename ?? '',
    });
    if (companyIdsResolver) {
      const companyIds = await companyIdsResolver(assetInfo);
      setCompanyIds(companyIds);
    }
  };

  useEffect(() => {
    try {
      (async () => {
        const assetInfoList = await getAssetInfoListByResourceIds(
          resourceIdList || []
        );
        setFileList(assetInfoList || []);
      })();
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    }
  }, [intl, resourceIdList]);

  return (
    <CommentPaneProvider>
      <CommentPaneContainer
        resourceType={resourceType}
        fileList={fileList}
        onChangeFile={handleChangeFile}
        companyIds={companyIds}
        viewId={viewId}
      >
        <ViewerWithCommentShape previewAsset={previewAsset} />
      </CommentPaneContainer>
    </CommentPaneProvider>
  );
};

interface ViewerWithCommentShapeProps {
  previewAsset: {
    assetId: string;
    fileName: string;
  };
}
const ViewerWithCommentShape = ({
  previewAsset,
}: ViewerWithCommentShapeProps) => {
  const { threadSet, draft, viewerInfo } = useCommentPane();
  const commentPaneDispatch = useCommentPaneDispatch();

  const handleSelection = (selection: ShapeInfo) => {
    commentPaneDispatch({
      type: 'setDraft',
      relativeShapeInfo: selection,
    });
  };

  const handleChangeViewerInfo = (viewerInfo: ViewerInfo) => {
    commentPaneDispatch({
      type: 'setViewerInfo',
      viewerInfo,
    });
  };

  const handleClickSelection = (threadId?: string | null) => {
    commentPaneDispatch({
      type: 'changeActiveThread',
      threadId,
    });
  };

  const renderOverlayShapes = (): OverlayItem[] => {
    if (!viewerInfo) {
      return [];
    }
    const threadShapes = threadSet.threadList.map(
      (thread): OverlayItem | null => {
        if (!thread.attachedPoint || !viewerInfo) {
          return null;
        }
        return getOverlayContent(
          thread.attachedPoint.attributes?.selectionShape ?? '',
          {
            plotInfo: {
              x1: thread.attachedPoint.x1 ?? 0,
              y1: thread.attachedPoint.y1 ?? 0,
              x2: thread.attachedPoint.x2 ?? 0,
              y2: thread.attachedPoint.y2 ?? 0,
            },
            isActive: thread.threadId === threadSet.activeThreadId,
            onClick: () => handleClickSelection(thread.threadId),
          }
        );
      }
    );
    if (draft && draft?.attributes?.selectionShape) {
      threadShapes.push(
        getOverlayContent(draft.attributes.selectionShape, {
          plotInfo: draft,
          isActive: true,
        })
      );
    }
    return threadShapes.filter((item) => item !== null) as OverlayItem[];
  };

  const getOverlayContent = (
    shapeType: string,
    overlayItemProps: OverlayItemProps
  ): OverlayItem | null => {
    switch (shapeType) {
      case SHAPE_TYPE_RECT:
        return { ...overlayItemProps, component: Rect };
      case SHAPE_TYPE_ARROW:
        return { ...overlayItemProps, component: Arrow };
      default:
        return null;
    }
  };

  return (
    <>
      <Viewer
        assetId={previewAsset.assetId}
        fileName={previewAsset.fileName}
        controls={{
          downloading: true,
          pager: true,
          scaling: true,
          cropping: true,
        }}
        width="100%"
        height="100%"
        onSelectionEnd={handleSelection}
        onChangeViewerInfo={handleChangeViewerInfo}
        overlayItems={renderOverlayShapes()}
      />
    </>
  );
};
