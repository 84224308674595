import { useCallback, useRef, useEffect, useReducer } from 'react';
import { ImageRenderer } from './image';
import { PDFRenderer } from './pdf';

// state transition:
//   (init) --> (loading) --> (ready) <--> (rendering)
type State = StateInit | StateReady | StateRendering;

type Action = ActionLoad | ActionReady | ActionRender | ActionCancel;

interface StateInit {
  status: 'loading';
}

interface StateReady {
  status: 'OK';

  renderer: Renderer;

  maxPageNumber: number;
}

interface StateRendering {
  status: 'rendering';

  renderer: Renderer;

  cancel: CancelFunc;
}

interface ActionLoad {
  type: 'load';

  src: string;
}

interface ActionReady {
  type: 'ready';

  renderer: Renderer;
}

interface ActionRender {
  type: 'render';

  renderer?: Renderer;

  context: CanvasRenderingContext2D;

  pageNumber: number;

  maxPageNumber?: number;

  cancel?: CancelFunc;
}

interface ActionCancel {
  type: 'cancel';
}

const rendererCtors: {
  mimeTypePrefixes: string[];
  ctor: RendererConstructor;
}[] = [
  {
    mimeTypePrefixes: ['image/'],
    ctor: ImageRenderer,
  },
  {
    mimeTypePrefixes: ['application/pdf'],
    ctor: PDFRenderer,
  },
];

interface RendererConstructor {
  new (src: string): Renderer;
}

type CancelFunc = () => void;

interface Renderer {
  load(): Promise<void>;

  maxPageNumber(): number;

  render(
    ctx: CanvasRenderingContext2D,
    pageNumber: number
  ): [Promise<void>, CancelFunc];
}

class nullRenderer {
  load(): Promise<void> {
    return Promise.resolve();
  }

  maxPageNumber(): number {
    return 0;
  }

  render(
    ctx: CanvasRenderingContext2D, // eslint-disable-line
    pageNumber: number // eslint-disable-line
  ): [Promise<void>, CancelFunc] {
    return [Promise.resolve(), () => {}];
  }
}

async function createRenderer(src: string): Promise<Renderer> {
  if (!src) {
    return new nullRenderer();
  }
  const res = await fetch(src, {
    method: 'HEAD',
  });
  const contentType =
    (await res.headers.get('Content-Type')) ?? 'application/octet-stream';
  const v = rendererCtors.find((v) =>
    v.mimeTypePrefixes.some((prefix) => contentType.startsWith(prefix))
  );
  if (!v) {
    return new nullRenderer();
  }
  return new v.ctor(src);
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'load':
      return {
        status: 'loading',
      };
    case 'ready':
      return {
        status: 'OK',
        renderer: action.renderer,
        maxPageNumber: action.renderer.maxPageNumber(),
      };
    case 'render':
      return {
        status: 'rendering',
        renderer: action.renderer!,
        cancel: action.cancel!,
      };
    case 'cancel':
      if (state.status !== 'rendering') {
        return state;
      }
      state.cancel();
      return {
        ...state,
        status: 'OK',
        maxPageNumber: state.renderer.maxPageNumber(),
      };
    default:
      throw new Error(`unable to handle action: ${action}`);
  }
}

type RenderFunc = (
  context: CanvasRenderingContext2D,
  pageNumber: number
) => Promise<void>;

export function useRenderer(src: string): [State, RenderFunc] {
  const [state, dispatch_] = useReducer(reducer, {
    status: 'loading',
  });
  // dispatch内で常に最新のstateを扱いたいため、refで扱う
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  const dispatch = useCallback(async (action: Action) => {
    const state = stateRef.current;
    switch (action.type) {
      case 'load': {
        dispatch_(action);
        const renderer = await createRenderer(action.src);
        await renderer.load();
        return dispatch_({
          type: 'ready',
          renderer,
        });
      }
      case 'render': {
        if (state.status === 'loading') {
          // loadingのとき呼ばれたら、呼び出し遅延させる
          setTimeout(() => dispatch(action), 100);
          return state;
        }
        dispatch_({ type: 'cancel' });
        const renderer = state.renderer;
        const [promise, cancel] = renderer.render(
          action.context,
          action.pageNumber
        );
        dispatch_({ ...action, renderer, cancel });
        await promise;
        return dispatch_({
          ...state,
          type: 'ready',
        });
      }
      default:
        throw new Error(`unable to handle action: ${action.type}`);
    }
  }, []);
  useEffect(() => {
    (async () => {
      await dispatch({ type: 'load', src });
    })();
  }, [dispatch, src]);
  const render = useCallback(
    async (context: CanvasRenderingContext2D, pageNumber: number) => {
      await dispatch({ type: 'render', context, pageNumber });
    },
    [dispatch]
  );
  return [state, render];
}
