import { useState, useEffect } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  ContactRequestDialog,
  ContactRequestResult,
} from '~/shared/components/ui/Dialog/ContactRequestDialog';
import {
  UserAdditionDialog,
  UserAdditionResult,
} from '~/shared/components/ui/Dialog/UserAdditionDialog';
import {
  ContactAttributeSettingDialog,
  ContactAttributeSettingResult,
} from '~/shared/components/ui/Dialog/ContactAttributeSettingDialog';
import {
  ContactRegistrationDialog,
  ContactRegistrationResult,
  ContactRegistrationDisplayModeType,
} from '~/shared/components/ui/Dialog/ContactRegistrationDialog';
import { Textbox } from '~/shared/components/ui';
import { mtechnavi } from '~/shared/libs/clientsdk';

export const UISampleContactDialogs = () => {
  const [companyId, setCompanyId] = useState<string>('');
  const [sampleDisplayName, setSampleDisplayName] = useState('');
  const [isShowContactRequest, setShowContactRequest] = useState(false);
  const [contactRequestResult, setContactRequestResult] =
    useState<ContactRequestResult | null>(null);
  const [isShowUserAddition, setShowUserAddition] = useState(false);
  const [userAdditionResult, setUserAdditionResult] =
    useState<UserAdditionResult | null>(null);
  const [isShowContactAttributeSetting, setShowContactAttributeSetting] =
    useState(false);
  const [contactAttributeSettingResult, setContactAttributeSettingResult] =
    useState<ContactAttributeSettingResult | null>(null);
  const [
    isShowContactAttributeSettingOther,
    setShowContactAttributeSettingOther,
  ] = useState(false);
  const [
    contactAttributeSettingOtherResult,
    setContactAttributeSettingOtherResult,
  ] = useState<ContactAttributeSettingResult | null>(null);
  const [isShowContactRegistration, setShowContactRegistration] =
    useState(false);
  const [contactRegistrationResult, setContactRegistrationResult] =
    useState<ContactRegistrationResult | null>(null);
  const [displayModeType, setDisplayModeType] =
    useState<ContactRegistrationDisplayModeType>('input');
  const [isActiveTenant, setActiveTenant] = useState(false);

  useEffect(() => {
    // 会社マスタ
    (async () => {
      const selfCompany = (await window.App.services.companyService.getCompany(
        {}
      )) as mtechnavi.api.company.ICompany;
      setCompanyId(selfCompany.companyId ?? '');
    })();
  }, []);

  return (
    <div className="categories">
      <h3 id="dialog">連絡先系ダイアログ</h3>

      <h4>連絡先入力依頼ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setShowContactRequest(true);
              }}
            />
            <h4>連絡先名（引数）</h4>
            <Textbox
              name="displayName"
              type="text"
              columns={['displayName']}
              value={sampleDisplayName}
              onChangeState={setSampleDisplayName}
            />
            <ContactRequestDialog
              isOpen={isShowContactRequest}
              inputOption={{
                displayName: sampleDisplayName,
              }}
              messageOption={{}}
              onCancel={() => {
                setContactRequestResult(null);
                setShowContactRequest(false);
              }}
              onDecision={(result) => {
                setContactRequestResult(result);
                setShowContactRequest(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {contactRequestResult &&
                JSON.stringify(contactRequestResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>
      <h4>担当追加ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setShowUserAddition(true);
              }}
            />
            <UserAdditionDialog
              isOpen={isShowUserAddition}
              onCancel={() => {
                setUserAdditionResult(null);
                setShowUserAddition(false);
              }}
              onDecision={(result: UserAdditionResult) => {
                setUserAdditionResult(result);
                setShowUserAddition(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {userAdditionResult &&
                JSON.stringify(userAdditionResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>

      <h4>自社情報設定（自社連絡先）ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setShowContactAttributeSetting(true);
              }}
            />
            <ContactAttributeSettingDialog
              isOpen={isShowContactAttributeSetting}
              messageOption={{
                headerLabelId: {
                  prefixId: 'DIALOG_TITLE',
                  id: 'contactAttributeSetting.self',
                },
                codeLabelId: {
                  prefixId: 'ContactAttributeSettingDialog',
                  id: 'organization',
                },
              }}
              inputOption={{}}
              onCancel={() => {
                setContactAttributeSettingResult(null);
                setShowContactAttributeSetting(false);
              }}
              onDecision={(result) => {
                setContactAttributeSettingResult(result);
                setShowContactAttributeSetting(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {contactAttributeSettingResult &&
                JSON.stringify(contactAttributeSettingResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>

      <h4>自社情報設定（相手連絡先）ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setShowContactAttributeSettingOther(true);
              }}
            />
            <ContactAttributeSettingDialog
              isOpen={isShowContactAttributeSettingOther}
              messageOption={{
                headerLabelId: {
                  prefixId: 'DIALOG_TITLE',
                  id: 'contactAttributeSetting.other',
                },
                codeLabelId: {
                  prefixId: 'ContactAttributeSettingDialog',
                  id: 'code',
                },
              }}
              inputOption={{}}
              onCancel={() => {
                setContactAttributeSettingOtherResult(null);
                setShowContactAttributeSettingOther(false);
              }}
              onDecision={(result) => {
                setContactAttributeSettingOtherResult(result);
                setShowContactAttributeSettingOther(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {contactAttributeSettingOtherResult &&
                JSON.stringify(contactAttributeSettingOtherResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>

      <h4>自社連絡先登録ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する(初期)"
              name=""
              onClick={() => {
                setDisplayModeType('input');
                setActiveTenant(false);
                setShowContactRegistration(true);
              }}
            />
            <ContactRegistrationDialog
              isOpen={isShowContactRegistration}
              inputOption={{
                displayModeType: displayModeType,
                isActiveTenant: isActiveTenant,
                companyId: companyId,
                // 依頼時情報
                requestDocumentRecipientAddress: false,
                requestNotificationUser: false,
                // 画面表示用項目
                displayName: contactRegistrationResult?.displayName,
                businessUnitBranchProperties:
                  contactRegistrationResult?.businessUnitBranchProperties,
                alias: contactRegistrationResult?.alias,
                notificationUsers: contactRegistrationResult?.notificationUsers,
                comment: contactRegistrationResult?.comment,
              }}
              saveContactAttribute={async () => {}}
              onCancel={() => {
                setContactRegistrationResult(null);
                setShowContactRegistration(false);
              }}
              onDecision={(result) => {
                setContactRegistrationResult(result);
                setShowContactRegistration(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {contactRegistrationResult &&
                JSON.stringify(contactRegistrationResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="紐付き後"
              name=""
              onClick={() => {
                setDisplayModeType('input');
                setActiveTenant(true);
                setShowContactRegistration(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className="input-line space-between-buttons">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認ダイアログ(紐付き前)"
              name=""
              onClick={() => {
                setDisplayModeType('display');
                setActiveTenant(false);
                setShowContactRegistration(true);
              }}
            />
          </div>
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認ダイアログ(紐付き後)"
              name=""
              onClick={() => {
                setDisplayModeType('display');
                setActiveTenant(true);
                setShowContactRegistration(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
