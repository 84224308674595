import { useEffect, useRef, useState } from 'react';
import {
  Container,
  error,
  GetMessageWithIntl,
  success,
  Toast,
} from '~/shared/components';
import { PageNavigation, ProcessingDialog, Tabs } from '~/shared/components/ui';
import {
  convertDatetime,
  createAttachmentAsset,
  getSortSettingFromLocalStorage,
  getWorkerExceptionMessage,
  PageState,
  ViewId,
} from '~/shared/utils';
import { BlueprintBlueprintConfirmationHeader } from './BlueprintBlueprintConfirmationHeader';
import { useIntl } from 'react-intl';
import { BlueprintBlueprintConfirmationBlueprint } from './BlueprintBlueprintConfirmationBlueprint';
import { BlueprintBlueprintConfirmationData } from './BlueprintBlueprintConfirmationData';
import { BlueprintBlueprintConfirmationLink } from './BlueprintBlueprintConfirmationLink';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import {
  getBlueprintContent,
  getBlueprintRevisionList,
  isBlueprintProcessing,
} from '../../utils';
import { RevisionHistories } from './parts/RevisionHistories';
import { BlueprintRevisionDialogResult } from '~/shared/components/ui/Dialog/BlueprintRevisionDialog';
import { FileItem } from '~/shared/components/file';
import {
  BlueprintConfirmationDataProvider,
  useBlueprintConfirmationData,
} from './BlueprintConfirmationDataProvider';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { FullMethodName_ListBlueprints } from '~/worker';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';

const backPath = '/blueprint/blueprint-list';
const viewId: ViewId = 'BLP_BLUEPRINT_CONFIRMATION';
const BlueprintBlueprintConfirmationMain = () => {
  const intl = useIntl();
  const navi = useNavigate();
  const myEmail = useAuth().user?.email ?? '';
  const location = useLocation();
  const locationState = (location.state as PageState) ?? [];
  const { showLoading, hideLoading } = useLoading();
  const auth = useAuth();
  const [enabledEditorMode, setEnabledEditorMode] = useState(false);

  const { blueprintContent, blueprintRevisions, dispatch } =
    useBlueprintConfirmationData();

  const [isOpenHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [isOpenProcessing, setOpenProcessing] = useState(false);

  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });
  const validRevisionNumbers = useRef<string[]>([]);

  // パスの図面IDと更新番号(更新番号指定時)
  const { blueprintId: pathBlueprintId, revision: pathRevision } = useParams();

  // 例外処理用にエラー情報を取得する様修正
  const handleError = useErrorHandler();

  // ページング
  const [page, paginator] = usePagenator<mtechnavi.api.blueprint.IBlueprint>({
    fullMethodName: FullMethodName_ListBlueprints,
    pageNumber: locationState.confirmationViewOption?.pageNumber ?? 1,
    pageSize: 1,
    maxPageNumber: 1,
    filter: {
      blueprintId: { $in: locationState.ids ?? [] },
    },
    sort: getSortSettingFromLocalStorage('BLP_BLUEPRINT_LIST', myEmail),
    items: [],
    onError(err) {
      error(getWorkerExceptionMessage(intl, err));
      handleError(err);
    },
  });
  const isFirstPage = page.pageNumber === 1;
  const isMaxPage = page.pageNumber === page.maxPageNumber;
  const selectionBlueprintId = page.items.at(0)?.blueprintId;
  const selectionRevision = (locationState.subIds ?? []).at(
    page.pageNumber - 1
  );
  const pageNumber = page.pageNumber;

  // リビジョン指定のモードかどうか
  const isSpecifiedRevision = pathBlueprintId && pathRevision !== undefined;

  // 実際に画面で扱う図面ID
  const blueprintId = isSpecifiedRevision
    ? pathBlueprintId
    : selectionBlueprintId;

  // 実際に画面で扱う更新番号
  const revision = isSpecifiedRevision ? pathRevision : selectionRevision;

  useEffect(() => {
    if (!blueprintId) {
      return;
    }
    showLoading();
    dispatch({ type: 'clear' }); // データが取得できるまで一旦クリアする
    (async () => {
      try {
        await loadData();
      } catch (err) {
        error(getWorkerExceptionMessage(intl, err));
      } finally {
        hideLoading();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blueprintId]);

  const loadData = async () => {
    // ロールによる画面項目制御
    const [roleNames] = await window.App.services.ui.getUserRoles(
      auth.user?.userGroupIds
    );
    setEnabledEditorMode(
      roleNames.some(
        (roleName) => roleName === 'Role-BlueprintManagement-Editor'
      )
    );
    // 更新履歴の取得
    await fetchBlueprintRevisions();
    // 図面コンテンツの取得
    await fetchBlueprintContent();
  };

  /** 図面コンテンツの取得 */
  const fetchBlueprintContent = async () => {
    if (
      isSpecifiedRevision &&
      !validRevisionNumbers.current.some((item) => item === revision)
    ) {
      navi(backPath);
      return;
    }
    const blueprintContent = await getBlueprintContent(
      blueprintId ?? '',
      revision ?? '0'
    );
    setBlueprintAndProcessing(blueprintContent);
  };

  /** 図面コンテンツのセットと処理中表示の制御 */
  const setBlueprintAndProcessing = (
    content?: mtechnavi.api.blueprint.IBlueprintContent
  ) => {
    // 取得できたコンテンツ情報を反映
    dispatch({ type: 'setContent', content });

    // 処理中表示
    // useBlueprintConfirmationData では同期的に値を得られないため別途判定してセットする
    setOpenProcessing(isBlueprintProcessing(content));
  };

  /** 改訂履歴の取得 */
  const fetchBlueprintRevisions = async () => {
    const blueprintRevisions = await getBlueprintRevisionList(
      blueprintId ?? ''
    );
    // 取得できた履歴情報を反映
    validRevisionNumbers.current = blueprintRevisions.map(
      (item): string => item.revision?.toString() ?? ''
    );
    dispatch({ type: 'setRevisions', blueprintRevisions });
  };

  const onRevisionAdd = async (result: BlueprintRevisionDialogResult) => {
    if (!result.file) {
      error([GetMessageWithIntl(intl, { id: 'E0000186' })]);
      return;
    }

    showLoading();
    try {
      // アセット登録を行う
      const resultAttachment = await createAttachment(result.file);

      const request: mtechnavi.api.blueprint.IBlueprintRevision = {
        blueprintId: blueprintContent?.blueprint?.blueprintId,
        blueprintFile: {
          attachment: resultAttachment,
        },
        revisedDt: convertDatetime(
          result.revisionDate ?? null,
          'YYYY/MM/DD HH:mm'
        ),
        comment: result.comment,
      };

      const resp = await window.App.services.ui.worker.apiCall({
        actionName: 'createBlueprintRevision',
        request,
      });
      success([toastSuccess]);

      const nerRevision = resp.at(0);

      // 改訂登録後の図面コンテンツを反映
      dispatch({
        type: 'addRevision',
        content: nerRevision,
      });

      // 表示中の図面の指定更新番号を追加されたものに置き換える
      const state: PageState = {
        ...locationState,
        subIds: locationState.subIds?.map((v, index) =>
          index === page.pageNumber - 1
            ? `${nerRevision?.blueprintRevision?.revision ?? 0}`
            : v
        ),
      };
      navi(location.pathname, { state, replace: true });
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      hideLoading();
    }
  };

  const createAttachment = async (file: FileItem) => {
    // アセット登録
    const assetRes = await createAttachmentAsset({ files: [file] }, 'B01');
    const asset = assetRes.at(0);
    let resultAsset: mtechnavi.api.assetinventory.IAsset = {};
    if (Array.isArray(asset)) {
      if (asset.length > 0) {
        resultAsset = asset[0];
      }
    } else {
      if (asset) {
        resultAsset = asset;
      }
    }
    if (!resultAsset) {
      return null;
    }

    const attachment: sharelib.IAttachment = {
      assetId: resultAsset.assetId ?? '',
      filename: resultAsset.filename ?? '',
      mimeType: resultAsset.mimeType ?? '',
    };

    return attachment;
  };

  const backPage = () => {
    const state: PageState = {
      ids: locationState.ids,
      sourceViewId: viewId,
      naviFilters: locationState.naviFilters,
      beforeStateIds: locationState.ids,
      baseViewOption: locationState.baseViewOption,
    };
    navi(backPath, isSpecifiedRevision ? {} : { state });
  };

  const handleHeaderEdit = () => {
    const ids = [blueprintContent?.blueprint?.blueprintId ?? ''];
    const subIds = revision ? [revision ?? ''] : [];
    const state: PageState = {
      ...locationState,
      ids: ids,
      subIds: subIds,
      // 更新番号指定の際は ids を持たずに遷移してくるため現在の id をセットする
      beforeStateIds: locationState.ids ?? ids,
      beforeStateSubIds: locationState.subIds ?? subIds,
      confirmationViewOption: { pageNumber },
      sourceViewId: 'BLP_BLUEPRINT_CONFIRMATION',
      actionType: 'edit',
    };
    navi('/blueprint/blueprint-input', { state });
  };

  // 初回画面用データ
  useEffect(() => {
    paginator({
      type: 'reload',
      fullMethodName: FullMethodName_ListBlueprints,
      onChangeLoadingState: (loading) =>
        loading ? showLoading() : hideLoading(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    paginator({
      type: 'query',
      fullMethodName: FullMethodName_ListBlueprints,
      pageNumber: n,
    });
  };

  const tabData = [
    {
      label: GetMessageWithIntl(intl, { viewId, id: 'blueprint' }),
      tabContent: (
        <BlueprintBlueprintConfirmationBlueprint
          key={blueprintId}
          viewId={viewId}
          onOpenHistory={() => setOpenHistoryDialog(true)}
          onOpenProcessing={() => setOpenProcessing(true)}
          onBackPage={backPage}
          isEditable={enabledEditorMode}
        />
      ),
    },
    {
      label: GetMessageWithIntl(intl, { viewId, id: 'relation' }),
      tabContent: <BlueprintBlueprintConfirmationLink viewId={viewId} />,
    },
    {
      label: GetMessageWithIntl(intl, { viewId, id: 'data' }),
      tabContent: (
        <BlueprintBlueprintConfirmationData
          viewId={viewId}
          pageNumber={page.pageNumber}
        />
      ),
    },
  ];

  return (
    <>
      <div className="BlueprintBlueprintConfirmation">
        <div className="header">
          <div className="header-navigation">
            <PageNavigation
              backpagePath={backPath}
              iconItems={[]}
              pageInfo={{
                isVisibleMoveNavi: !isSpecifiedRevision,
                isFirstPage,
                isMaxPage,
                pageNumber,
              }}
              handleMovePage={handleMovePage}
              infoOption={{
                lastUpdateInfo: blueprintContent?.blueprint?.updatedProperties,
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={backPage}
            />
          </div>
          <BlueprintBlueprintConfirmationHeader
            viewId={viewId}
            onRevisionAdd={(result) => onRevisionAdd(result)}
            handleHeaderEdit={handleHeaderEdit}
            isEditable={enabledEditorMode}
          />
        </div>
        <div className="contents-area">
          <Tabs tabItems={tabData} />
        </div>
      </div>
      <>
        {/* 履歴ダイアログ */}
        <RevisionHistories
          revisions={blueprintRevisions}
          isOpen={isOpenHistoryDialog}
          onChangeOpen={setOpenHistoryDialog}
        />
      </>
      <>
        {/* 処理中ダイアログ */}
        <ProcessingDialog
          isOpen={isOpenProcessing}
          processName={{ viewId, id: 'extractionProgress' }}
          onClose={() => setOpenProcessing(false)}
        />
      </>
    </>
  );
};

/** BlueprintContentProvider を適用する */
export const BlueprintBlueprintConfirmation = () => {
  return (
    <Container viewId={viewId}>
      <BlueprintConfirmationDataProvider>
        <BlueprintBlueprintConfirmationMain />
        <Toast />
      </BlueprintConfirmationDataProvider>
    </Container>
  );
};
