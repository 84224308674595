import React, { createContext, useContext, useReducer } from 'react';
import { SHAPE_TYPE_RECT } from '../Viewer/parts/Shapes';
import { OffsetInfo, ShapeInfo } from '../Viewer';
import { mtechnavi } from '~/shared/libs/clientsdk';

export interface AssetInfo {
  asset?: mtechnavi.api.assetinventory.IAsset;
  resourceId: string;
}

interface AssetSet {
  assetList: AssetInfo[];
  activeResourceId?: string | null;
  activeAssetId?: string | null;
  baseThreadId?: string | null;
}

interface ThreadSet {
  threadList: mtechnavi.api.forum.IThread[];
  activeThreadId?: string | null;
}

interface ShowOption {
  /** フォーラムエリアの表示・非表示 */
  isShowCommentPane: boolean;
  /** ファイル(アセット)一覧エリアの表示・非表示 */
  isShowFileList: boolean;
}

interface ViewerInfo {
  offset: OffsetInfo;
  scale: number;
  width: number;
  height: number;
  isDragging: boolean;
}

interface State {
  /** ファイルビューワーエリアの情報連携(サイズ・位置・スケール) */
  viewerInfo: ViewerInfo;
  /** 選択中のシェイプ情報 */
  selection: ShapeInfo;
  /** 選択を終えた永続化用のシェイプ情報 */
  draft: ShapeInfo | null;
  /** アセット(ファイル)情報 */
  assetSet: AssetSet;
  /** スレッド情報 */
  threadSet: ThreadSet;
  /** 画面全体の制御フラグ */
  showOption: ShowOption;
}

const initialState: State = {
  viewerInfo: {
    offset: { x: 0, y: 0 },
    scale: 1,
    height: 0,
    width: 0,
    isDragging: false,
  },
  selection: {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
    attributes: {
      selectionShape: SHAPE_TYPE_RECT,
    },
  },
  draft: null,
  assetSet: {
    assetList: [],
  },
  threadSet: {
    threadList: [],
  },
  showOption: {
    isShowCommentPane: false,
    isShowFileList: false,
  },
};

const CommentPaneContext = createContext<State>({
  ...initialState,
});
const CommentPaneDispatchContext = createContext<React.Dispatch<Action>>(
  () => {}
);

interface ActionSetDraft {
  type: 'setDraft';
  relativeShapeInfo: ShapeInfo;
}
interface ActionSetViewerInfo {
  type: 'setViewerInfo';
  viewerInfo: ViewerInfo;
}
interface ActionAddThread {
  type: 'addThread';
  thread: mtechnavi.api.forum.IThread;
}
interface ActionUpdateThread {
  type: 'updateThread';
  thread: mtechnavi.api.forum.IThread;
}
interface ActionCancelThread {
  type: 'cancelThread';
}
interface ActionShowCommentPane {
  type: 'showCommentPane';
}
interface ActionHideCommentPane {
  type: 'hideCommentPane';
}
interface ActionShowFileList {
  type: 'showFileList';
}
interface ActionHideFileList {
  type: 'hideFileList';
}
interface ActionChangeActiveThread {
  type: 'changeActiveThread';
  threadId?: string | null;
}
interface ActionSetFileList {
  type: 'setFileList';
  fileList: AssetInfo[];
}
interface ActionChangeActiveAsset {
  type: 'changeActiveAsset';
  resourceId: string;
  assetId: string;
  baseThreadId: string;
  threadList: mtechnavi.api.forum.IThread[];
}

// TODO: 202409 スコープで調整予定
// 全部ごちゃごちゃに管理しているので、「座標情報を業務機能側に繋げる」役割に徹した方がよい。
type Action =
  | ActionSetDraft
  | ActionSetViewerInfo
  | ActionAddThread
  | ActionUpdateThread
  | ActionCancelThread
  | ActionShowCommentPane
  | ActionHideCommentPane
  | ActionShowFileList
  | ActionHideFileList
  | ActionChangeActiveThread
  | ActionSetFileList
  | ActionChangeActiveAsset;
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setDraft':
      return {
        ...state,
        draft: { ...action.relativeShapeInfo },
        showOption: {
          ...state.showOption,
          isShowCommentPane: true,
          isShowFileList: false,
        },
      };
    case 'setViewerInfo':
      return {
        ...state,
        viewerInfo: { ...action.viewerInfo },
      };
    case 'addThread':
      if (!state.draft) {
        return state;
      }
      return {
        ...state,
        draft: null,
        threadSet: {
          threadList: [action.thread, ...state.threadSet.threadList],
          activeThreadId: action.thread.threadId,
        },
      };
    case 'updateThread':
      return {
        ...state,
        threadSet: {
          ...state.threadSet,
          threadList: state.threadSet.threadList.map((item) => {
            if (item.threadId === action.thread.threadId) {
              return action.thread;
            }
            return item;
          }),
        },
      };
    case 'cancelThread':
      return {
        ...state,
        draft: null,
        showOption: {
          ...state.showOption,
          isShowCommentPane: state.threadSet.threadList.length > 0,
        },
      };
    case 'showCommentPane':
      return {
        ...state,
        showOption: {
          isShowCommentPane: true,
          isShowFileList: false,
        },
      };
    case 'hideCommentPane':
      return {
        ...state,
        showOption: {
          ...state.showOption,
          isShowCommentPane: false,
        },
        draft: null,
      };
    case 'showFileList':
      return {
        ...state,
        showOption: {
          isShowFileList: true,
          isShowCommentPane: false,
        },
      };
    case 'hideFileList':
      return {
        ...state,
        showOption: {
          ...state.showOption,
          isShowFileList: false,
        },
      };
    case 'changeActiveThread':
      if (state.draft) {
        return state;
      }
      return {
        ...state,
        threadSet: {
          ...state.threadSet,
          activeThreadId: action.threadId,
        },
        showOption: {
          isShowFileList: false,
          isShowCommentPane: true,
        },
      };
    case 'setFileList':
      return {
        ...state,
        assetSet: {
          activeResourceId: null,
          activeAssetId: null,
          assetList: action.fileList,
        },
        showOption: {
          isShowFileList:
            action.fileList.length > 0 ? true : state.showOption.isShowFileList,
          isShowCommentPane:
            action.fileList.length > 0
              ? false
              : state.showOption.isShowCommentPane,
        },
      };
    case 'changeActiveAsset':
      return {
        ...state,
        assetSet: {
          ...state.assetSet,
          activeResourceId: action.resourceId,
          activeAssetId: action.assetId,
          baseThreadId: action.baseThreadId,
        },
        threadSet: {
          threadList: action.threadList,
          activeThreadId: null,
        },
      };
    default:
      throw new Error(`unknown action: ${action}`);
  }
};

export interface Props {
  children: React.ReactNode;
}
export const CommentPaneProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  return (
    <CommentPaneContext.Provider value={state}>
      <CommentPaneDispatchContext.Provider value={dispatch}>
        {children}
      </CommentPaneDispatchContext.Provider>
    </CommentPaneContext.Provider>
  );
};

export const useCommentPane = () => {
  return useContext(CommentPaneContext);
};

export const useCommentPaneDispatch = () => {
  return useContext(CommentPaneDispatchContext);
};
