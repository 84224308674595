import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { SystemNotificationSetting } from '../type';

export interface UpdateEstimateManagementSystemNotificationSettingRequest {
  actionName: 'updateEstimateManagementSystemNotificationSetting';
  request: SystemNotificationSetting;
}

export const updateEstimateManagementSystemNotificationSetting = async (
  worker: AppSaveWorker,
  req: SystemNotificationSetting
) => {
  const getFullMethodName =
    'mtechnavi.api.estimation.EstimateSenderService/ListEstimateManagements';
  const updateFullMethodName =
    'mtechnavi.api.estimation.EstimateSenderService/UpdateSystemNotificationUsersInEstimateManagement';

  const items = await worker.invokeListRequest<
    mtechnavi.api.estimation.IListEstimateManagementsRequest,
    mtechnavi.api.estimation.IListEstimateManagementsResponse
  >(getFullMethodName, {
    estimateManagementIds: [req.recordId ?? ''],
  });
  if (items && items.length > 0) {
    const item = items[0] as mtechnavi.api.estimation.IEstimateManagement;
    const users = item.systemNotificationUsers ?? [];
    // 追加ユーザーが渡された場合
    if (req.appendUser) {
      if (!users.find((user) => user.userId === req.appendUser?.userId)) {
        users.push(req.appendUser);
      }
      item.systemNotificationUsers = users;
    }
    // 削除ユーザーが渡された場合
    if (req.removeUser) {
      const deleteUsers = users.filter(
        (user) => user.userId !== req.removeUser?.userId
      );
      item.systemNotificationUsers = deleteUsers;
    }
    await worker.invokeListRequest<
      mtechnavi.api.estimation.IUpdateSystemNotificationUsersInEstimateManagementRequest,
      mtechnavi.api.estimation.EstimateManagement
    >(updateFullMethodName, {
      estimateManagementId: item.estimateManagementId,
      systemNotificationUsers: item.systemNotificationUsers,
      updatedAt: item.updatedAt,
    });
  }
};
