import { useState, useMemo, useEffect } from 'react';
import { Textbox } from '~/shared/components/ui';
import { getPathnameByViewId, openWindowOrTab } from '~/shared/utils';
import { CaptionButton, IconButton } from '~/shared/components/ui/Button';

import './Help.css';
import { useHelp } from './useHelp';
import { QAItemComponent } from '~/shared/pages/Help/QAItemComponent';
import {
  ContentsItem,
  getContentsHtmlData,
} from '~/shared/services/staticContents';
import { GetMessage } from '../../parts/Message/Message';

export const Help = () => {
  const { viewId } = useHelp(); // マニュアルで使う
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [currentHelp, setCurrentHelp] = useState<ContentsItem[]>([]);
  const [manualHTML, setManualHTML] = useState<
    string | JSX.Element | JSX.Element[]
  >();
  const helpList = useMemo<ContentsItem[]>(() => {
    return window.App.helpList;
  }, []);
  const manualList = useMemo<ContentsItem[]>(() => {
    return window.App.manualLIst;
  }, []);

  useEffect(() => {
    // 現在のURLから画面に合わせたQAリストを絞り込み
    const pathInfo = getPathnameByViewId(viewId);
    const pathTag = pathInfo?.tag ?? '';
    let result: ContentsItem[] = [];
    if (pathTag) {
      result = helpList.filter((v) => v.tag.some((tag) => tag === pathTag));
    }
    setCurrentHelp(result.length > 0 ? result.slice(0, 3) : result);
  }, [helpList, viewId]);

  useEffect(() => {
    // 画面に合わせた画面マニュアルを取得
    (async () => {
      const path = manualList.find((v) =>
        v.tag.some((tag) => tag === viewId)
      )?.htmlId;
      if (path) {
        const result = await getContentsHtmlData(path, 'manual');
        setManualHTML(result);
      } else {
        setManualHTML(<div>画面マニュアルはありません。</div>);
      }
    })();
  }, [manualList, viewId]);

  const handleSearch = () => {
    const answerItems: ContentsItem[] = [];
    helpList.forEach((v) => {
      if (
        v.title.indexOf(searchKeyword) >= 0 ||
        v.answer.indexOf(searchKeyword) >= 0
      ) {
        answerItems.push(v);
      }
    });
    setCurrentHelp(answerItems);
  };

  const tabs: string[] = ['画面マニュアル', 'よくある質問'];
  const [tabPanel, setTabPanel] = useState<string>('画面マニュアル');

  const ManualTab = () => {
    return (
      <>
        <div className="search-area">
          <Textbox
            name="help"
            type="text"
            value={searchKeyword}
            columns={['help']}
            onChangeState={setSearchKeyword}
          />
          <IconButton
            name="search"
            className="search-btn"
            iconType="search"
            onClick={handleSearch}
          />
        </div>
        <div className="manual">
          <p className="viewTitle">
            {GetMessage({ prefixId: 'HEADER_TITLE', viewId })}
          </p>
          <div className="manual-contents">{manualHTML}</div>
        </div>
      </>
    );
  };

  const QAtab = () => {
    return (
      <>
        <div className="search-area">
          <Textbox
            name="help"
            type="text"
            value={searchKeyword}
            columns={['help']}
            onChangeState={setSearchKeyword}
          />
          <IconButton
            name="search"
            className="search-btn"
            iconType="search"
            onClick={handleSearch}
          />
        </div>
        <div className="help-list">
          <hr />
          {currentHelp.map((v) => (
            <QAItemComponent qaItem={v} key={v.id} />
          ))}
        </div>
        <CaptionButton
          name="navTOHelpTop"
          className="navTOHelpTop"
          buttonType="basic"
          caption="他の質問を探す"
          onClick={() => {
            openWindowOrTab('help');
          }}
        />
      </>
    );
  };

  return (
    <>
      <div className="help-right">
        <div className="tabPanelContents">
          {tabs.map((v, index) => {
            const selectPane = v === tabPanel ? 'current' : '';
            return (
              <div
                key={`tab-${index}`}
                className={`tabPanel ${selectPane}`}
                onClick={() => setTabPanel(v)}
              >
                {v}
              </div>
            );
          })}
        </div>
        {tabPanel === '画面マニュアル' && ManualTab()}
        {tabPanel === 'よくある質問' && QAtab()}
      </div>
    </>
  );
};
