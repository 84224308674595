import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  error,
  GetMessageWithIntl,
  LoadingIcon,
  success,
  Toast,
} from '~/shared/components';
import { useIntl } from 'react-intl';
import {
  MenuListView,
  Preset,
  MenuItem,
  Property,
  ConfirmationDialog,
  getAltDisplaySchema,
} from '~/shared/components/ui';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { ImportDialogHandleFormat } from '~/shared/components/ui/Dialog/ImportDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  ViewId,
  getExceptionMessage,
  getPresetAndSchema,
} from '~/shared/utils';
import { FullMethodName } from 'worker/worker';

const VIEW_ID: ViewId = 'PUBLIC_COMPANY_LIST';

export function BusinessunitPublicCompanyList() {
  const intl = useIntl();
  const navigate = useNavigate();

  // APiが利用可能になるまでの仮
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [approvalTradeRequestIsOpen, setApprovalTradeRequestIsOpen] =
    useState(false);
  const [sendTradeRequestModalIsOpen, setSendTradeRequestModalIsOpen] =
    useState(false);

  const [sendTradeRequestDialogInfo, setSendTradeRequestDialogInfo] = useState({
    id: 'SENDTRADEREQUEST_DIALOG_TITLE',
    msg: {
      id: 'C0000001',
      value: {
        $1: GetMessageWithIntl(intl, {
          prefixId: VIEW_ID,
          id: 'SENDTRADEREQUEST_DIALOG_TITLE',
        }),
      },
    },
    func: () => {},
  });
  const [approvalTradeRequestDialogInfo, setApprovalTradeRequestDialogInfo] =
    useState({
      id: 'APPROVALTRADEREQUEST_DIALOG_TITLE',
      msg: {
        id: 'C0000001',
        value: {
          $1: GetMessageWithIntl(intl, {
            prefixId: VIEW_ID,
            id: 'APPROVALTRADEREQUEST_DIALOG_TITLE',
          }),
        },
      },
      func: () => {},
    });

  const [isReload, setReload] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // 取引先取り込みダイアログ関係
  const dialogPreset: PresetProperty[] = useMemo(() => {
    return (
      childrenPresetItem?.find((v) => v.name === 'adminUserGroupListImport')
        ?.property ?? []
    );
  }, [childrenPresetItem]);

  useEffect(() => {
    (async () => {
      try {
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            'FullMethodName', // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
          ]);

        // ja表示のみする処理
        const jaColumn = [
          'company.companyStatus.status.displayNameLang',
          'company.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        setChildrenPresetItem(childrenPresetItem);
        setSchema(formatterSch);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  const handleExport: ImportDialogHandleFormat = {
    name: 'businessUnit',
    headerColumns: [],
    searchCondition: async () => {
      // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
      // companyIDを元にbusinessUnitIdを取得
      return [] as string[];
    },
  };
  const handleImport: ImportDialogHandleFormat = {
    name: 'businessUnit',
    headerColumns: [],
  };

  const handleSendTradeRequest = (ids: string[]) => {
    (async () => {
      setLoading(true);
      try {
        const result =
          await window.App.services.publicCompanyService.createBusinessUnitRequest(
            {
              businessUnitRequest: {
                companyId: ids.pop() ?? '',
              },
            }
          );
        await window.App.services.publicCompanyService.sharetoBusinessUnitRequest(
          { id: result.businessUnitRequestId }
        );
        // ListViewのデータをreload
        setReload(!isReload);
        success([GetMessageWithIntl(intl, { id: 'I0000001' })]);
        setSendTradeRequestModalIsOpen(false);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  };

  const handleApprovalTradeRequest = (ids: string[]) => {
    (async () => {
      setLoading(true);
      try {
        // 自社情報を取得
        const myCompanyInfo: mtechnavi.api.company.Company =
          await window.App.services.companyService.getCompany({});

        // 取引開始依頼の存在チェック
        const partnerCompanyId = ids.pop() ?? '';
        const businessUnitRequestResponse =
          await window.App.services.publicCompanyService.getBusinessUnitRequest(
            {
              businessUnitRequestId: `${partnerCompanyId}${myCompanyInfo.companyId}`,
            }
          );

        const createResponse =
          await window.App.services.publicCompanyService.createBusinessUnitRequestResult(
            {
              businessUnitRequestResult: {},
              businessUnitRequest: businessUnitRequestResponse,
            }
          );
        await window.App.services.publicCompanyService.sharetoBusinessUnitRequestResult(
          { id: createResponse.businessUnitRequestResultId }
        );

        // ListViewのデータをreload
        setReload(!isReload);
        success([GetMessageWithIntl(intl, { id: 'I0000001' })]);
        setApprovalTradeRequestIsOpen(false);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  };

  const menuFunc = (prop?: string[], type?: string) => {
    switch (type) {
      case 'companyConfirm':
        navigate('/company-confirmation', { state: { ids: prop } });
        break;
      case 'businessunitConfirm':
        navigate('/businessunit-confirmation', { state: { ids: prop } });
        break;
      case 'businessUnitImport':
        // 取引先取込ダイアログを表示
        setDialogOpen(true);
        break;
      default:
        break;
    }
  };

  const burgerMenuEvent = (): MenuItem[] => {
    // 必要な関数を宣言
    const menuItems: MenuItem[] = [];
    // 企業確認
    menuItems.push({
      name: 'companyConfirm',
      func: (v?: string[]) => menuFunc(v, 'companyConfirm'),
    });
    // 取引先確認
    menuItems.push({
      name: 'businessunitConfirm',
      func: (v?: string[]) => menuFunc(v, 'businessunitConfirm'),
    });
    // 取引先取込 取引先取込ダイアログを表示
    menuItems.push({
      name: 'businessUnitImport',
      func: (v?: string[]) => menuFunc(v, 'businessUnitImport'),
    });

    // 取引依頼送信
    menuItems.push({
      name: 'sendTradeRequest',
      func: (v?: string[]) => {
        setSendTradeRequestDialogInfo({
          ...sendTradeRequestDialogInfo,
          func: () => {
            v && handleSendTradeRequest(v);
          },
        });
        setSendTradeRequestModalIsOpen(true);
      },
    });

    // 取引依頼承認
    menuItems.push({
      name: 'approvalTradeRequest',
      func: (v?: string[]) => {
        setApprovalTradeRequestDialogInfo({
          ...approvalTradeRequestDialogInfo,
          func: () => {
            v && handleApprovalTradeRequest(v);
          },
        });
        setApprovalTradeRequestIsOpen(true);
      },
    });

    // タグ付け
    // タグ設定ダイアログを表示
    menuItems.push({ name: 'tag', func: (v?: string[]) => menuFunc(v) });
    return menuItems;
  };

  const menuViewlist = burgerMenuEvent().map((v) => v.name);

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="BusinessunitPublicCompanyList">
          <MenuListView
            headerTitle={{ viewId: VIEW_ID }}
            fullMethodName={
              'FullMethodName' as FullMethodName // FIXME:PublicListの削除のみ一次対応 未使用画面のため動作検証なし
            }
            schema={schema}
            preset={preset}
            presetItems={childrenPresetItem}
            isReload={isReload}
            listSkipType={{
              isOutput: true,
              isTotal: true,
            }}
            menuEvent={burgerMenuEvent()}
            menuTarget="company.companyId"
            menuViewlist={menuViewlist}
            importDialogOption={{
              isDisplay: true,
              isDialogOpen,
              dialogPreset,
              headerLabelId: { prefixId: 'DIALOG', viewId: VIEW_ID },
              handleExport,
              handleImport,
              onChangeState: (v) => {
                setDialogOpen(v);
              },
            }}
          />
        </div>
        <ConfirmationDialog
          isOpen={sendTradeRequestModalIsOpen}
          viewMessage={sendTradeRequestDialogInfo.msg}
          onDecision={() => sendTradeRequestDialogInfo.func()}
          onCancel={() => setSendTradeRequestModalIsOpen(false)}
        />
        <ConfirmationDialog
          isOpen={approvalTradeRequestIsOpen}
          viewMessage={approvalTradeRequestDialogInfo.msg}
          onDecision={() => approvalTradeRequestDialogInfo.func()}
          onCancel={() => setApprovalTradeRequestIsOpen(false)}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
