import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface ExecUpdateBusinessUnitManagement {
  actionName: 'execUpdateBusinessUnitManagement';
  request: mtechnavi.api.company.IBusinessUnitManagement;
}

export const execUpdateBusinessUnitManagement = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.company.IBusinessUnitManagement
) => {
  const execMethodName =
    'mtechnavi.api.company.BusinessUnitManagementService/UpdateBusinessUnitManagement';

  return await worker.invokeListRequest(execMethodName, {
    businessUnitManagement: req,
  });
};
