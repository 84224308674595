import { useId, useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  SurveyReplyDelegationDialog,
  SurveyReplyDelegationResult,
} from '~/shared/components/ui/Dialog';

export const UISampleSurveyReplyDelegationDialogs = () => {
  const [isShow, setShow] = useState(false);
  const [result, setResult] = useState<SurveyReplyDelegationResult | null>(
    null
  );

  return (
    <div className="categories">
      <h3 id="dialog">回答代行ダイアログ</h3>

      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setShow(true);
              }}
            />
            <SurveyReplyDelegationDialog
              isOpen={isShow}
              inputOption={{
                files: [...(result?.files || [])],
              }}
              messageOption={{
                headerLabelId: {
                  id: 'survey_reply_delegation',
                  prefixId: 'DIALOG_TITLE',
                },
                messageLabelId: {
                  id: 'survey_reply_delegation',
                  prefixId: 'DIALOG_DESCRIPTION',
                },
              }}
              uploadOption={{
                validateOption: {
                  allowedFileExtensions: ['xls', 'xlsx', 'pdf', 'txt'],
                  maxFileSizeInMebis: 10,
                  maxFileCount: 5,
                },
              }}
              onCancel={() => {
                setShow(false);
              }}
              onDecision={(result) => {
                setResult({
                  ...result,
                  // 永続化されたことを再現するため assetId に適当な値をセットする
                  files: (result.files ?? []).map((item, index) => ({
                    ...item,
                    assetId: index.toString(),
                  })),
                });
                setShow(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {result && JSON.stringify(result, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
