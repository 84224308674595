import { CSSProperties } from 'react';
import { OverlayItemProps } from '../ViewerOverlay';
import './Rect.css';

export const SHAPE_TYPE_RECT = 'Rectangle';

/**
 * オーバーレイ描画用の図形（矩形）
 */
export const Rect = ({
  plotInfo,
  isActive,
  className,
  onClick,
}: OverlayItemProps) => {
  const rangeStyle: CSSProperties = {
    top: plotInfo.y1 < plotInfo.y2 ? plotInfo.y1 : plotInfo.y2,
    left: plotInfo.x1 < plotInfo.x2 ? plotInfo.x1 : plotInfo.x2,
    width: Math.abs(plotInfo.x2 - plotInfo.x1),
    height: Math.abs(plotInfo.y2 - plotInfo.y1),
  };
  return (
    <div
      className={`Rect ${isActive ? 'active' : ''} ${className ?? ''}`}
      style={rangeStyle}
      onClick={onClick}
    ></div>
  );
};
