import Long from 'long';

export interface SortTerm {
  [k: string]: 'asc' | 'desc';
}

export function makeSorter<T>(
  expr: SortTerm[],
  getter: (obj: unknown, key: string) => unknown
): (a: T, b: T) => number {
  if (expr.length === 0) {
    return () => 0;
  }
  const l: ((a: T, b: T) => number)[] = expr.map((e) => {
    const keys = Object.keys(e);
    if (keys.length !== 1) {
      throw new Error(`unable to create sorter from ${e}`);
    }
    const prop = keys.shift()!;
    const propDir = e[prop];
    const fn = (a: T, b: T): number => {
      const [av, bv] = [getter(a, prop), getter(b, prop)];
      if (!av && !bv) {
        return 0;
      }
      if (av === null) return -1;
      if (bv === null) return 1;
      if (av === undefined) return -1;
      if (bv === undefined) return 1;
      if (typeof av === 'string' && av === '') return -1;
      if (typeof bv === 'string' && bv === '') return 1;
      if (typeof av === 'number' && av === 0) return -1;
      if (typeof bv === 'number' && bv === 0) return 1;
      if (av === bv) {
        return 0;
      }
      switch (typeof av) {
        case 'string':
          return ensureString(compareString)(av, bv);
        case 'number':
          return ensureNumber(compareNumber)(av, bv);
        case 'boolean':
          return ensureBoolean(compareBoolean)(av, bv);
        case 'object':
          if (Long.isLong(av)) {
            return ensureLong(compareLong)(av, bv);
          }
          if (Array.isArray(av)) {
            return ensureArray(compareArray)(av, bv);
          }
          return 0;
        default:
          return 0;
      }
    };
    if (propDir === 'asc') {
      return fn;
    } else {
      return (a: T, b: T): number => {
        return -fn(a, b);
      };
    }
  });

  return (a: T, b: T) => {
    for (const fn of l) {
      const n = fn(a, b);
      if (n !== 0) {
        return n;
      }
    }
    return 0;
  };
}

function compareString(a: string, b: string): number {
  a = a.toLowerCase();
  b = b.toLowerCase();
  return a < b ? -1 : 1;
}

function compareNumber(a: number, b: number): number {
  return a - b;
}

function compareBoolean(a: boolean, b: boolean): number {
  return a ? 1 : b ? -1 : 0;
}

function compareLong(a: Long, b: Long): number {
  if (a.isZero()) return -1;
  if (b.isZero()) return 1;
  return a.compare(b);
}

function compareArray(a: unknown[], b: unknown[]): number {
  return compareNumber(a.length, b.length);
}

function ensureNumber(fn: (c: number, d: number) => number) {
  return (a: unknown, b: unknown): number => {
    const av = isNumber(a) ? a : isNumberString(a) ? Number(a) : null;
    const bv = isNumber(b) ? b : isNumberString(b) ? Number(b) : null;
    if (av == null) return -1;
    if (bv == null) return 1;
    return fn(av, bv);
  };
}

function ensureString(fn: (c: string, d: string) => number) {
  return (a: unknown, b: unknown) => {
    const av = isString(a) ? a : String(a);
    const bv = isString(b) ? b : String(b);
    return fn(av, bv);
  };
}

const ensureBoolean = (fn: (c: boolean, d: boolean) => number) => {
  return (a: unknown, b: unknown) => {
    const av = isBoolean(a) ? a : false;
    const bv = isBoolean(b) ? b : false;
    return fn(av, bv);
  };
};

const ensureLong = (fn: (c: Long, d: Long) => number) => {
  return (a: unknown, b: unknown) => {
    const av = Long.isLong(a) ? a : Long.fromNumber(0);
    const bv = Long.isLong(b) ? b : Long.fromNumber(0);
    return fn(av, bv);
  };
};

const ensureArray = (fn: (c: unknown[], d: unknown[]) => number) => {
  return (a: unknown, b: unknown) => {
    const av = Array.isArray(a) ? a : [];
    const bv = Array.isArray(b) ? b : [];
    return fn(av, bv);
  };
};

function isString(v: unknown): v is string {
  return typeof v === 'string';
}

function isNumberString(v: unknown): v is string {
  return !!v && isString(v) && !isNaN(Number(v));
}

function isNumber(v: unknown): v is number {
  return typeof v === 'number';
}

const isBoolean = (v: unknown): v is boolean => {
  return typeof v === 'boolean';
};
