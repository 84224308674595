import { createBlueprintRevision } from './createBlueprintRevision';
import { updateBlueprintRevision } from './updateBlueprintRevision';
import { deleteBlueprintRevision } from './deleteBlueprintRevision';
import { updateBlueprintAttribute } from './updateBlueprintAttribute';
import { deleteBlueprintAttribute } from './deleteBlueprintAttribute';
import { updateBlueprintSegment } from './updateBlueprintSegment';
import { createBlueprint } from './createBlueprint';
import { deleteBlueprint } from './deleteBlueprint';
import { updateBlueprint } from './updateBlueprint';
import { importBlueprints } from './importBlueprints';
import { extractBlueprintImageSegment } from './extractBlueprintImageSegment';

// actionName と function のマッピング
export const blueprintCallMap = {
  createBlueprintRevision: createBlueprintRevision,
  updateBlueprintRevision: updateBlueprintRevision,
  deleteBlueprintRevision: deleteBlueprintRevision,
  updateBlueprintAttribute: updateBlueprintAttribute,
  deleteBlueprintAttribute: deleteBlueprintAttribute,
  updateBlueprintSegment: updateBlueprintSegment,
  createBlueprint: createBlueprint,
  updateBlueprint: updateBlueprint,
  deleteBlueprint: deleteBlueprint,
  importBlueprints: importBlueprints,
  extractBlueprintImageSegment: extractBlueprintImageSegment,
};
