import { useIntl } from 'react-intl';
import { GetMessageWithIntl, MessageProps } from '~/shared/components';
import { MessageDialog } from '~/shared/components/ui';

interface AsyncTaskAcceptDialogProps {
  isOpen: boolean;
  targetActionName: MessageProps;
  onClose?: () => void;
}
/**
 * 非同期処理受付ダイアログ
 *
 * MessageDialog を特定の文言に限定したラッパー
 */
export const AsyncTaskAcceptDialog = ({
  isOpen,
  targetActionName,
  onClose,
}: AsyncTaskAcceptDialogProps) => {
  const intl = useIntl();

  return (
    <>
      <MessageDialog
        isOpen={isOpen}
        messageOption={{
          headerLabelId: {
            prefixId: 'DIALOG_TITLE',
            id: 'asyncTaskAcceptDialog',
          },
          message: {
            prefixId: 'asyncTaskAcceptDialog',
            id: 'message',
            value: { $1: GetMessageWithIntl(intl, targetActionName) },
          },
        }}
        onClose={() => onClose && onClose()}
      />
    </>
  );
};
