import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import {
  Container,
  error,
  Toast,
  success,
  GetMessage,
  GetMessageWithIntl,
} from '~/shared/components';
import {
  Preset,
  Property,
  getAltDisplaySchema,
  getBooleanDataFormetterSchema,
  ListView,
  MenuActionItem,
  ViewMenu,
} from '~/shared/components/ui';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { useNavigate } from 'react-router-dom';
import { ImportDialogHandleFormat } from '~/shared/components/ui/Dialog/ImportDialog';
import { useIntl } from 'react-intl';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { getProgramOptions } from '~/shared/services';
import {
  PageState,
  getExceptionMessage,
  getPresetAndSchema,
  FullMethodName_ListProgramOptions,
  saveLocalStorageCheckboxData,
} from '~/shared/utils';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { useConfirmation } from '~/shared/hooks';
import { useAuth } from '~/shared/contexts/AuthProvider';
const VIEW_ID = 'MASTER_PROGRAMOPTION_LIST';

type ProgramOption = mtechnavi.api.programoption.ProgramOption;
export function MasterProgramOptionList() {
  const { showLoading, hideLoading } = useLoading();
  const intl = useIntl();
  const navi = useNavigate();
  const myEmail = useAuth().user?.email ?? '';
  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });

  const handleExport: ImportDialogHandleFormat = {
    name: 'masterProgramOpiton',
    headerColumns: [],
  };

  const handleImport: ImportDialogHandleFormat = {
    name: 'masterProgramOpiton',
    headerColumns: [],
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogPreset, setDialogPreset] = useState<PresetProperty[]>([
    { name: '', propertyName: '', propertyValue: '' },
  ]);
  const [isReload, setReload] = useState(false);
  const successMessage = GetMessage({ id: 'I0000001' });
  const deleteMessage = {
    id: 'C0000001',
    value: { $1: GetMessageWithIntl(intl, { id: 'delete' }) },
  };
  const masterProgramOptionList = useRef<ProgramOption[]>([]);
  const { confirmation, confirmationElement } = useConfirmation();

  useMemo(() => {
    const dialogPreset = childrenPresetItem?.find(
      (v) => v.name === 'ProgramOptionList'
    );
    setDialogPreset(
      dialogPreset?.property ?? [
        { name: '', propertyName: '', propertyValue: '' },
      ]
    );

    // prest 変更時に起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preset, childrenPresetItem]);

  useEffect(() => {
    (async () => {
      try {
        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListProgramOptions,
          ]);

        // ja表示のみする処理
        const jaColumn = [
          'displayNameLang',
          'category.displayNameLang',
          'system.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        const booleanColumn = ['userEditable'];
        const booleanSch = getBooleanDataFormetterSchema(
          formatterSch,
          booleanColumn
        );
        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(booleanSch);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
  }, [intl]);

  // メニューの「確認」処理
  const handleMenuConfirm = (prop?: string[]) => {
    const state: PageState = {
      sourceViewId: VIEW_ID,
      ids: prop ?? [],
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navi('/master-programoption-confirmation', {
      state,
    });
  };

  // メニューの「複写追加」処理
  const handleMenuCopy = (prop?: string[]) => {
    const state: PageState = {
      sourceViewId: VIEW_ID,
      ids: prop ?? [],
      baseViewOption: { sourceViewId: VIEW_ID },
      actionType: 'copy',
    };
    navi('/master-programoption-input', {
      state,
    });
  };

  const deleteAction = async (selectedProgramOption: ProgramOption) => {
    showLoading();
    setReload(false);
    try {
      //KEY（種別コード＋システムコード）で集計
      const filteredLatestTargetProgramOptions =
        (await window.App.services.ui.worker.filter({
          action: 'reload',
          fullMethodName: FullMethodName_ListProgramOptions,
          filter: {
            $and: [
              { categoryName: { $eq: selectedProgramOption.categoryName } },
              { systemName: { $eq: selectedProgramOption.systemName } },
            ],
          },
          sort: [],
        })) as mtechnavi.api.programoption.ListProgramOptionsResponse;

      //件数が1件になった状態で該当明細の削除する場合はエラーを表示して続行不可
      if (filteredLatestTargetProgramOptions.items.length <= 1) {
        error([GetMessageWithIntl(intl, { id: 'E0000063' })]);
        return;
      }

      hideLoading();

      // 確認ダイアログ表示
      if (!(await confirmation(deleteMessage))) {
        return;
      }

      showLoading();
      const res =
        await window.App.services.programOptionService.deleteProgramOption({
          programOption: selectedProgramOption,
        });
      if (res) {
        window.App.programoptions = await getProgramOptions();
        success([successMessage]);
        setReload(true);
        clearCheckBox();
      }
    } catch (err) {
      console.error('err', err);
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
      maxMenuColumn: 5,
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    // 複写
    menuItems.push({
      name: 'copy',
      func: (v) => {
        if (!v) {
          return;
        }
        if (v.length > 1) {
          error([GetMessageWithIntl(intl, { id: 'E0000073' })]);
          return;
        }
        if (
          !masterProgramOptionList.current.find(
            (item) => item.programOptionId === v[0]
          )?.userEditable
        ) {
          error([GetMessageWithIntl(intl, { id: 'E0000179' })]);
          return;
        }
        handleMenuCopy(v);
      },
    });
    // アップロード
    menuItems.push({
      name: 'upload',
      func: () => {
        setIsDialogOpen(true);
      },
    });
    // 削除
    menuItems.push({
      name: 'delete',
      func: (v?: string[]) => {
        if (!v || v.length === 0) {
          error([GetMessageWithIntl(intl, { id: 'E0000023' })]);
          return true;
        }
        if (v.length > 1) {
          error([GetMessageWithIntl(intl, { id: 'E0000073' })]);
          return true;
        }
        const programOptionItems = masterProgramOptionList.current?.find(
          (item) => v[0] === item.programOptionId
        );
        if (!programOptionItems) {
          return;
        }
        if (!programOptionItems.userEditable) {
          error([GetMessageWithIntl(intl, { id: 'E0000176' })]);
          return;
        }
        deleteAction(programOptionItems);
      },
    });

    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => handleMenuConfirm(v),
    });

    return menuItems;
  };

  const onOriginalItem = (items: unknown) => {
    masterProgramOptionList.current = items as unknown as ProgramOption[];
  };

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [myEmail]);

  return (
    <>
      <Container viewId={VIEW_ID}>
        <ListView
          pageInfo={{
            schema: schema,
            preset: preset,
            menuItem: setMenuActionItem(),
            menuTarget: 'programOptionId',
            headerTitle: { viewId: VIEW_ID },
            presetItems: childrenPresetItem,
            listSkipType: {
              isTotal: true,
              isOutput: true,
              isListActionMenu: true,
            },
          }}
          isReload={isReload}
          fullMethodName={FullMethodName_ListProgramOptions}
          importDialogOption={{
            isDisplay: true,
            isDialogOpen,
            dialogPreset,
            headerLabelId: {
              viewId: VIEW_ID,
              id: 'import',
            },
            handleExport,
            handleImport,
            onChangeState: setIsDialogOpen,
          }}
          stateOption={{
            onOriginalItemState: onOriginalItem,
          }}
        />
        {confirmationElement}
        <Toast />
      </Container>
    </>
  );
}
