import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  LicenseEditData,
  LicenseEditDialog,
} from '~/shared/components/ui/Dialog/LicenseEditDialog';
import { convertDateToLong } from '~/shared/utils';

export const UISampleLicenseEditDialogs = () => {
  const [result, setResult] = useState<LicenseEditData[]>([]);
  const [isShowDialog, setIsShowDialog] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">運営ライセンス編集ダイアログ</h3>

      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult([]);
                setIsShowDialog(true);
              }}
            />
            <LicenseEditDialog
              isOpen={isShowDialog}
              messageOption={{
                headerLabelId: {
                  id: 'license_edit',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              inputOption={{
                licenseData: [
                  {
                    displayName: 'ライセンス有効期限内',
                    withoutEnd: false,
                    startAt: convertDateToLong(new Date('2024/6/25')),
                    endAt: convertDateToLong(new Date('2024/10/25')),
                  },
                  {
                    displayName: 'ライセンス有効期限開始前',
                    withoutEnd: false,
                    startAt: convertDateToLong(new Date('2024/10/25')),
                    endAt: convertDateToLong(new Date('2024/11/25')),
                  },
                  {
                    displayName: 'ライセンス有効期限切れ',
                    withoutEnd: false,
                    startAt: convertDateToLong(new Date('2024/5/25')),
                    endAt: convertDateToLong(new Date('2024/6/25')),
                  },
                  {
                    displayName: 'ライセンス開始後期限なし',
                    withoutEnd: true,
                    startAt: convertDateToLong(new Date('2024/6/25')),
                  },
                  {
                    displayName: 'ライセンス開始前期限なし',
                    withoutEnd: true,
                    startAt: convertDateToLong(new Date('2024/10/25')),
                  },
                ],
              }}
              onDecision={(v) => {
                setResult(v);
                setIsShowDialog(false);
              }}
              onCancel={() => {
                setResult([]);
                setIsShowDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{JSON.stringify(result, null, 2)}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
