import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
  Container,
  error,
  GetMessageWithIntl,
  MessageProps,
  success,
  Toast,
} from '~/shared/components';
import { useIntl } from 'react-intl';
import {
  Preset,
  Property,
  ConfirmationDialog,
  ListView,
  ViewMenu,
  MenuActionItem,
  getAltDisplaySchema,
} from '~/shared/components/ui';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import { ImportDialogHandleFormat } from '~/shared/components/ui/Dialog/ImportDialog';
import {
  CommentDialog,
  TenantInvitationDialog,
  TenantInvitationDialogInputOption,
  TenantInvitationDialogResult,
} from '~/shared/components/ui/Dialog';
import {
  ViewId,
  getExceptionMessage,
  getWorkerExceptionMessage,
  getPresetAndSchema,
  FullMethodName_ListBusinessUnitManagementContents,
  PageState,
  saveLocalStorageCheckboxData,
  FullMethodName_ListBusinessUnitContactContents,
} from '~/shared/utils';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { FilterExpression, FullMethodName_SharedListLicenses } from '~/worker';
import { RadioSelectDialog } from '~/shared/components/ui/Dialog/RadioSelectDialog';
import './BusinessUnitManagementList.css';
import { CompanySearchLocationState } from '~/tenant/company/pages/util';
import { useLoading } from '~/shared/contexts/LoadingProvider';

const VIEW_ID: ViewId = 'BUM_BUSINESS_UNIT_MANAGEMENT_LIST';

type BusinessUnitManagement =
  mtechnavi.api.company.IBusinessUnitManagementContent;
export function BusinessUnitManagementList() {
  const { showLoading, hideLoading } = useLoading();
  const intl = useIntl();
  const navi = useNavigate();
  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });

  // APiが利用可能になるまでの仮
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [tenantInvitationInputOption, setTenantInvitaionInputOption] =
    useState<TenantInvitationDialogInputOption>({
      businessUnitManagementIds: [],
      companyName: '',
      staffName: '',
      staffEmail: '',
    });

  const selectedIds = useRef<string[]>([]);
  const myEmail = useAuth().user?.email ?? '';

  const unselectedMessage = GetMessageWithIntl(intl, { id: 'E0000023' });
  const sendErrorMessage = GetMessageWithIntl(intl, { id: 'E0000020' });
  const sendMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'send' }),
    },
  };

  const deleteMessage = {
    id: 'C0000001',
    value: { $1: GetMessageWithIntl(intl, { id: 'delete' }) },
  };
  const unselectedRadioMessage = GetMessageWithIntl(intl, { id: 'E0000172' });

  const [isSendMailOpen, setSendMailOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [isSendConfirmOpen, setSendConfirmOpen] = useState(false);
  const [tenantInvitationDialogResult, setTenantInvitationDialogResult] =
    useState<TenantInvitationDialogResult>({} as TenantInvitationDialogResult);

  const [childrenPresetItem, setChildrenPresetItem] = useState<PresetItem[]>();
  const [isImportDialogOpen, setImportDialogOpen] = useState(false);

  const [isUpdateContactDialogOpen, setUpdateContactDialogOpen] =
    useState(false);
  const [isVisibleContactAddButton, setVisibleContactAddButton] =
    useState(false);

  // 送信ダイアログ
  const [isSendDialogOpen, setSendDialogOpen] = useState(false);
  const [comment, setComment] = useState('');

  const businessUnitManagementList = useRef<BusinessUnitManagement[]>([]);
  const organizations = useRef<mtechnavi.api.company.IOrganization[]>([]);
  const organizationRelations = useRef<
    mtechnavi.api.company.IOrganizationRelation[]
  >([]);
  const checkedBusinessUnitContactContents = useRef<
    mtechnavi.api.company.IBusinessUnitContactContent[]
  >([]);

  const handleExport: ImportDialogHandleFormat = {
    name: 'businessUnitManagement',
    headerColumns: [],
  };
  const handleImport: ImportDialogHandleFormat = {
    name: 'businessUnitManagement',
    headerColumns: [],
  };

  const [isReload, setReload] = useState(false);

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [myEmail]);

  useEffect(() => {
    (async () => {
      try {
        // スキーマ情報、preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListBusinessUnitManagementContents,
          ]);

        // ja表示のみする処理
        const jaColumn = [
          'profile.address.region.displayNameLang',
          'businessUnitManagement.status.displayNameLang',
          'tenantStatus.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        // 取得した情報をセット
        setChildrenPresetItem(childrenPresetItem);
        setSchema(formatterSch);
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
    (async () => {
      try {
        const licenses = (
          (await window.App.services.ui.worker.filter({
            action: 'reload',
            fullMethodName: FullMethodName_SharedListLicenses,
            filter: {
              licenseCode: { $eq: '_NITERRA_COMPANY' },
            },
            sort: [],
          })) as mtechnavi.api.license.SharedListLicensesResponse
        ).items;
        if (licenses.length > 0) {
          setVisibleContactAddButton(true);
        } else {
          setVisibleContactAddButton(false);
        }
      } catch (err) {
        error(getWorkerExceptionMessage(intl, err));
        throw err;
      }
    })();
    (async () => {
      organizations.current = await window.App.services.ui.listOrganization();
      organizationRelations.current =
        await window.App.services.ui.listOrganizationRelation();
    })();
  }, [intl]);

  const handleMenuConfirm = (prop?: string[]) => {
    const state: PageState = {
      ids: prop ?? [],
      sourceViewId: VIEW_ID,
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navi('/businessunitmanagement-confirmation', {
      state,
    });
  };

  const handleDelete = async () => {
    const targetDeleteItems = businessUnitManagementList.current.filter(
      (item) =>
        selectedIds.current.includes(
          item.businessUnitManagement?.businessUnitManagementId || ''
        )
    );
    const deleteItems = targetDeleteItems.map((item) => {
      return item.businessUnitManagement ?? {};
    });
    showLoading();
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteBusinessUnitManagementList',
        request: deleteItems,
      });
      clearCheckBox();
      success([toastSuccess]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      hideLoading();
      setDeleteOpen(false);
    }
  };

  const handleSendTenantInviteMail = async () => {
    showLoading();
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'sendTenantInvitation',
        request: {
          businessUnitManagementIds: selectedIds.current,
          individualMessage: tenantInvitationDialogResult.message,
          contactInformationMessage: tenantInvitationDialogResult.contact,
        },
      });
      setSendConfirmOpen(false);
      setSendMailOpen(false);
      success([toastSuccess]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      hideLoading();
    }
  };

  // 連絡先自社担当更新
  const handleSaveContact = async () => {
    showLoading();
    setReload(false);
    try {
      await Promise.all(
        checkedBusinessUnitContactContents.current.map(
          async (item: mtechnavi.api.company.IBusinessUnitContactContent) => {
            if (!item.businessUnitContactAttribute?.code) {
              return;
            }
            if (!item.businessUnitContact?.businessUnitContactId) {
              return;
            }
            const currentBusinessUnitManagement =
              businessUnitManagementList.current.find(
                (v) =>
                  v.businessUnitManagement?.businessUnitManagementId ===
                  item.businessUnitContact?.businessUnitManagementId
              );
            const notificationUsers = await setNotificationUsers(
              item.businessUnitContactAttribute.code
            );
            const request: mtechnavi.api.company.IBusinessUnitContact = {
              businessUnitContactId:
                item.businessUnitContact?.businessUnitContactId,
              businessUnitManagementId:
                item.businessUnitContact?.businessUnitManagementId ?? '',
              companyId: item.businessUnitContact?.companyId ?? '',
              displayName: item.businessUnitContact?.displayName ?? '',
              businessUnitBranchProperties:
                item.businessUnitContact?.businessUnitBranchProperties ?? null,
              alias: item.businessUnitContact?.alias ?? '',
              notificationUsers: notificationUsers,
              sendProcess: { processedComment: comment },
              updatedAt: item.businessUnitContact?.updatedAt,
            };

            const tenantStatus =
              currentBusinessUnitManagement?.tenantStatus ?? {};
            const activeStatus =
              currentBusinessUnitManagement?.notificationStatus ?? {};

            return window.App.services.ui.worker.apiCall({
              actionName: 'execBusinessUnitContact',
              request: {
                tenantStatus,
                activeStatus,
                businessUnitContact: request,
                businessUnitContactHeaderId:
                  item.businessUnitContactHeader?.businessUnitContactHeaderId ??
                  '',
              },
            });
          }
        )
      );
      clearCheckBox();
      setComment('');
      success([toastSuccess]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
      setSendDialogOpen(false);
      setUpdateContactDialogOpen(false);
    }
  };

  // 自社連絡先更新用の連絡先セット
  const setNotificationUsers = async (
    code: string
  ): Promise<mtechnavi.api.company.BusinessUnitContact.INotificationUser[]> => {
    const orgId = organizations.current.find(
      (v) => v.code === code
    )?.organizationId;
    const relations = organizationRelations.current.filter(
      (v) => v.organizationId === orgId
    );
    if (relations.length === 0) {
      return [];
    }
    const mainUserIds: string[] = [];
    const subUserIds: string[] = [];
    relations.map((v) => {
      if (
        ['1', '2'].some((level) => level === v.level?.low?.toString()) &&
        v.userId
      ) {
        mainUserIds.push(v.userId);
      }
      if (
        ['3', '4'].some((level) => level === v.level?.low.toString()) &&
        v.userId
      ) {
        subUserIds.push(v.userId);
      }
    });
    const mainUsers = await convertUserReferenceByUserId(mainUserIds);
    const subUsers = await convertUserReferenceByUserId(subUserIds);
    const notificationUsers: mtechnavi.api.company.BusinessUnitContact.INotificationUser[] =
      [];
    const contactCategoryMain =
      window.App.services.ui.getNameOptionWithSystemName('A4000006', 'B01');
    const contactCategorySub =
      window.App.services.ui.getNameOptionWithSystemName('A4000006', 'B02');
    mainUsers.map((user) => {
      notificationUsers.push({
        category: contactCategoryMain[0],
        user,
      });
    });
    subUsers.map((user) => {
      notificationUsers.push({
        category: contactCategorySub[0],
        user,
      });
    });
    return notificationUsers;
  };

  // ユーザーIDから連絡先用にユーザ情報を取得する
  const convertUserReferenceByUserId = async (
    userIds: string[]
  ): Promise<sharelib.IUserReference[]> => {
    const filterExpression: FilterExpression = {
      'user.userId': {
        $in: userIds,
      },
    };
    const result = await window.App.services.ui.listUserAttributes(
      filterExpression
    );
    // 該当するデータがなければ、表示上データが表示されていてもnullで登録する
    if (result.length === 0) {
      return [];
    }

    const items = result;
    const users: sharelib.IUserReference[] = [];
    items.map((item) => {
      users.push({
        userId: item.user?.userId ?? '',
        email: item.user?.email ?? '',
        displayName: item.user?.displayName ?? '',
      });
    });
    return users;
  };

  // 自社連絡先更新用エラーチェック
  const isContactListHasError = (
    listBusinessContactContents: mtechnavi.api.company.IBusinessUnitContactContent[]
  ) => {
    let errorFlag = false;
    listBusinessContactContents.some(
      (contactContents: mtechnavi.api.company.IBusinessUnitContactContent) => {
        if (errorFlag) {
          return true;
        }
        const orgCode = contactContents?.businessUnitContactAttribute?.code;
        if (!orgCode) {
          return;
        }
        // 購買グループではない組織が登録されている
        if (isNotProcurementOptionOrg(orgCode)) {
          error([GetMessageWithIntl(intl, { id: 'E0000155' })]);
          errorFlag = true;
          return;
        }
        // 購買グループ所属メンバーが存在しない
        if (isZeroMember(orgCode)) {
          error([GetMessageWithIntl(intl, { id: 'E0000156' })]);
          errorFlag = true;
          return;
        }
        // 購買グループ所属メンバーが10人以上
        if (isOver10Member(orgCode)) {
          error([
            GetMessageWithIntl(intl, { id: 'E0000157', value: { $1: '10' } }),
          ]);
          errorFlag = true;
          return;
        }
      }
    );
    return errorFlag;
  };
  // 登録されている組織が購買グループかのエラーチェック
  const isNotProcurementOptionOrg = (code: string) => {
    const contactOrg = organizations.current.find((org) => org.code === code);
    if (contactOrg && !contactOrg.procurementOption) {
      return true;
    }
  };

  // 購買グループ所属メンバーが存在してるかチェック
  const isZeroMember = (code: string) => {
    const contactOrg = organizations.current.find((org) => org.code === code);
    const orgRelations = organizationRelations.current.filter(
      (relation) => relation.organizationId === contactOrg?.organizationId
    );
    if (orgRelations.length === 0) {
      return true;
    }
  };

  // 購買グループ所属メンバーが10人以下かのエラーチェック
  const isOver10Member = (code: string) => {
    const contactOrg = organizations.current.find((org) => org.code === code);
    const orgRelations = organizationRelations.current.filter(
      (relation) => relation.organizationId === contactOrg?.organizationId
    );
    if (orgRelations.length > 10) {
      return true;
    }
  };

  // [データ取得] 取引先管理（連絡先）コンテンツ
  const listBusinessUnitContactContents = async (ids: string[]) => {
    return (
      await window.App.services.ui.worker.filter({
        action: 'reload',
        fullMethodName: FullMethodName_ListBusinessUnitContactContents,
        filter: {},
        requestBody: { businessUnitManagementIds: ids },
        sort: [],
      })
    ).items as mtechnavi.api.company.IBusinessUnitContactContent[];
  };

  const getAllBusinessUnitManagementIds = () => {
    const ids: string[] = [];
    businessUnitManagementList.current.map((v) => {
      if (v.businessUnitManagement?.businessUnitManagementId) {
        ids.push(v.businessUnitManagement?.businessUnitManagementId);
      }
    });
    return ids;
  };

  const handleMenuAnalysis = (ids: string[]) => {
    const businessUnitManagement = businessUnitManagementList.current.find(
      (v) => v.businessUnitManagement?.businessUnitManagementId === ids[0]
    );
    const state: CompanySearchLocationState = {
      baseViewOption: { sourceViewId: VIEW_ID },
      pageSetting: {
        ids: [businessUnitManagement?.businessUnitManagement?.companyId ?? ''],
      },
    };
    navi('/company/confirmation', {
      state,
    });
  };

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'footerMenu',
      menu: footerMenuEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    // アップロード
    menuItems.push({
      name: 'upload',
      func: () => setImportDialogOpen(true),
    });
    // 削除
    menuItems.push({
      name: 'delete',
      func: (v?: string[]) => {
        selectedIds.current = v as string[];
        setDeleteOpen(true);
      },
    });

    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => handleMenuConfirm(v),
    });
    // 分析
    menuItems.push({
      name: 'query_stats',
      func: (v?: string[]) => v && v.length > 0 && handleMenuAnalysis(v),
    });
    return menuItems;
  };

  const footerMenuEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 招待メール送信
    menuItems.push({
      name: 'input',
      func: (v?: string[]) => {
        const targetIds = v ?? [];
        if (targetIds.length === 0) {
          error([unselectedMessage]);
          return;
        }

        const selectedItems = businessUnitManagementList.current.filter(
          (item) =>
            targetIds.includes(
              item.businessUnitManagement?.businessUnitManagementId || ''
            )
        );

        const linkedItems = selectedItems.filter(
          (item) => item.tenantStatus?.systemName === 'B03'
        );

        // 紐付けありが存在する場合
        if (linkedItems.length > 0) {
          error([sendErrorMessage]);
          return;
        }

        const companyName =
          selectedItems.length > 0
            ? selectedItems[0].businessUnitManagement?.profile?.displayNameLang
                ?.ja ?? ''
            : '';
        const invitees: sharelib.IUserReference[] =
          selectedItems.length > 0
            ? selectedItems[0].businessUnitManagement?.invitees ?? []
            : [];
        const staffName =
          invitees.length > 0 ? invitees[0].displayName ?? '' : '';
        const staffEmail = invitees.length > 0 ? invitees[0].email ?? '' : '';

        selectedIds.current = v as string[];
        const inputOpt: TenantInvitationDialogInputOption = {
          businessUnitManagementIds: targetIds,
          companyName: companyName,
          staffName: staffName,
          staffEmail: staffEmail,
        };
        setTenantInvitaionInputOption(inputOpt);
        setSendMailOpen(true);
      },
    });
    // 連絡先自社担当更新
    if (isVisibleContactAddButton) {
      menuItems.push({
        name: 'update_contact',
        func: (v?: string[]) => {
          selectedIds.current = v ? v : [];
          setUpdateContactDialogOpen(true);
        },
      });
    }
    return menuItems;
  };

  const dialogPreset: PresetProperty[] = useMemo(() => {
    return (
      childrenPresetItem?.find((v) => v.name === 'BusinessUnitManagementList')
        ?.property ?? [{ name: '', propertyName: '', propertyValue: '' }]
    );
  }, [childrenPresetItem]);

  const headerLabelId: MessageProps = {
    prefixId: 'DIALOG_TITLE',
    id: 'FILE_IMPORT',
  };

  const onOriginalItem = (items: unknown) => {
    businessUnitManagementList.current =
      items as unknown as BusinessUnitManagement[];
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="businessUnitManagementList">
          <ListView
            pageInfo={{
              schema: schema,
              preset: preset,
              menuItem: setMenuActionItem(),
              menuTarget: 'businessUnitManagement.businessUnitManagementId',
              headerTitle: { viewId: VIEW_ID },
              presetItems: childrenPresetItem,
              listSkipType: {
                isTotal: true,
                isOutput: true,
                isListActionMenu: true,
              },
            }}
            isReload={isReload}
            fullMethodName={FullMethodName_ListBusinessUnitManagementContents}
            stateOption={{
              onOriginalItemState: onOriginalItem,
            }}
            filterItemOption={{
              isRequestBodyFilter: true,
            }}
            importDialogOption={{
              isDisplay: true,
              isDialogOpen: isImportDialogOpen,
              dialogPreset: dialogPreset,
              isFileTypeSelectBox: true,
              userMasterCategoryName: 'A4000009',
              headerLabelId: headerLabelId,
              handleExport: handleExport,
              handleImport: handleImport,
              onChangeState: (v) => {
                setImportDialogOpen(v);
              },
            }}
            isUseFilterSave={true}
          />
        </div>
        <ConfirmationDialog
          isOpen={isDeleteOpen}
          messageLabelId={deleteMessage}
          viewMessage={deleteMessage}
          onDecision={() => {
            handleDelete();
          }}
          onCancel={() => setDeleteOpen(false)}
        />
        <div className="TenantInvitationDialog">
          <TenantInvitationDialog
            isOpen={isSendMailOpen}
            inputOption={tenantInvitationInputOption}
            messageOption={{
              headerLabelId: {
                id: 'tenant_invitation',
                prefixId: 'DIALOG_TITLE',
              },
              messageLabelId: {
                id: 'tenant_invitation',
                prefixId: 'DIALOG_DESCRIPTION',
              },
            }}
            onDecision={(result) => {
              setTenantInvitationDialogResult(result);
              setSendConfirmOpen(true);
            }}
            onCancel={() => {
              setSendMailOpen(false);
            }}
          />
        </div>
        {/* 連絡先自社担当更新ダイアログ */}
        <RadioSelectDialog
          isOpen={isUpdateContactDialogOpen}
          inputOption={{
            radioButtonInfo: [
              {
                value: 'selectedOnly',
                displayName: '選択した取引先のみ',
              },
              {
                value: 'all',
                displayName: 'すべて',
              },
            ],
          }}
          messageOption={{
            headerMessageOption: {
              id: 'updateContact',
              prefixId: 'DIALOG_TITLE',
            },
            descriptionMessageOption: {
              id: 'updateContact',
              prefixId: 'DIALOG_CAPTION',
            },
          }}
          onDecision={async (v) => {
            // 一括対象未選択の場合、エラー
            if (!v) {
              error([unselectedRadioMessage]);
              return;
            }
            if (v === 'selectedOnly' && selectedIds.current.length === 0) {
              error([unselectedMessage]);
              return;
            }
            const ids =
              v === 'all'
                ? getAllBusinessUnitManagementIds()
                : selectedIds.current;
            const businessUnitContactContents = (
              await listBusinessUnitContactContents(ids)
            ).filter(
              // 自社の連絡先情報のみに絞り込む
              (item) =>
                !item.businessUnitContactHeader?.businessUnitManagementId &&
                item.businessUnitContact?.businessUnitManagementId
            );
            if (isContactListHasError(businessUnitContactContents)) {
              return;
            }
            checkedBusinessUnitContactContents.current =
              businessUnitContactContents;
            setSendDialogOpen(true);
          }}
          onCancel={() => {
            setUpdateContactDialogOpen(false);
          }}
        />
        {/* 送信確認ダイアログ */}
        <ConfirmationDialog
          isOpen={isSendConfirmOpen}
          viewMessage={sendMessage}
          onDecision={() => {
            handleSendTenantInviteMail();
          }}
          onCancel={() => {
            setSendConfirmOpen(false);
          }}
        />
        {/* 送信 ダイアログ */}
        <CommentDialog
          isOpen={isSendDialogOpen}
          inputOption={{
            modeType: 'send',
            butonType: 'high',
            comment: comment,
          }}
          inputStateOption={{ onChangeComment: setComment }}
          messageOption={{
            headerLabelId: {
              id: 'send',
              prefixId: 'DIALOG_TITLE',
            },
            messageLabelId: {
              id: 'C0000004',
              value: {
                $1: GetMessageWithIntl(intl, { id: 'send' }),
              },
            },
            captionLabelId: {
              id: 'comment',
              prefixId: 'SendDialog',
            },
            decisionLabelId: { id: 'send' },
          }}
          onDecision={handleSaveContact}
          onCancel={() => {
            setSendDialogOpen(false);
          }}
        />
        <Toast />
      </Container>
    </>
  );
}
