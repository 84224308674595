import { useEffect, useMemo, useRef, useState } from 'react';
import Long from 'long';
import { useIntl } from 'react-intl';
import { PresetItem } from '~/shared/services';
import {
  Container,
  GetMessage,
  Toast,
  LoadingIcon,
  success,
  error,
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components';
import { useNavigate, useLocation } from 'react-router-dom';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  HistoryDialog,
  AutoReminderSettingDialog,
  AutoReminderSettingDialogOutputOption,
  DeadlineChangeDialog,
  DeadlineChangeResult,
  CommentDialog,
  SurveyReplyDelegationDialog,
  ConfirmationDialog,
  DeadlineChangeApprovalDialog,
  DeadlineChangeApprovalResult,
  ManualReminderDialog,
  ManualReminderDialogOutputOption,
  SystemNotificationSettingDialog,
  FileUploadDialog,
  FileUploadDialogResult,
} from '~/shared/components/ui/Dialog';
import { OwnStaffDialog } from '~/shared/components/ui/Dialog/OwnStaffDialog';
import { OwnStaffNotificationSettingDialog } from '~/shared/components/ui/Dialog/OwnStaffNotificationSetting';
import { AttachmentFile } from '~/shared/components/ui/Dialog/CommonDialogTypes';

import './SurveyRequestSenderConfirmation.css';

import {
  PageNavigation,
  NavigationIconMenu,
} from '~/shared/components/ui/PageNavigation/PageNavigation';

import {
  Textarea,
  Textbox,
  SimpleListView,
  Accordion,
} from '~/shared/components/ui';
import { useAuth } from '~/shared/contexts/AuthProvider';
import {
  PageState,
  getMaxMainContentsHeight,
  getSortSettingFromLocalStorage,
  getViewIdPreset,
  FullMethodName_ExpandedListSurveyRequestContents,
  FullMethodName_ListSurveyRequestContents,
  FullMethodName_ListBusinessUnitContactContents,
  getDateFormat,
  getExceptionMessage,
  getWorkerExceptionMessage,
  getSurveyRequestAutoName,
  convertUserReference,
  autoDownloadFileOnlyName,
  autoBulkDownload,
  convertDatetime,
  convertDate,
  getDateFormatWithTimezone,
  createAttachmentFiles,
  HistoryItems,
  getHistoryItems,
  isShowNotificationArea,
  typeName,
  ViewId,
  autoDownloadFileWithDate,
  autoDownloadErrorFile,
  convertOrganizationStructureReferenceToFilterboxItem,
  getPathnameByViewId,
} from '~/shared/utils';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { useHelp } from '~/shared/components/tool/Help';
import { useForumTool, ForumTypeName } from '~/shared/components/tool/Forum';

import {
  AggregateStage,
  AggregateStageUnion,
  FilterRequest,
  SystemNotificationSetting,
  SortTerm,
} from '~/worker';
import {
  SurveyRequestWorkFormWithValues,
  SurveyResultWorkFormWithValues,
  convertToRequestWorkFormWithValues,
  convertToPlanResultWorkFormWithValues,
  convertToActualResultWorkFormWithValues,
  listActualSurveyResultWorkForms,
  listBaseFormSettingItems,
  listBaseFormSettings,
  listBaseForms,
  listFormSettingItems,
  listFormSettings,
  listFormValues,
  listForms,
  listSurveyRequestWorkForms,
  listPlanSurveyResultWorkForms,
  getLatestSharedSurveyReplyDueDateChangeRequest,
  getLatestSurveyReplyDueDateChangeResult,
  getLatestSurveyResultChangeRequest,
  getLatestSharedListSurveyResult,
  exportProxySurveyResultWorkForms,
} from '../utils';
import {
  BuildedInputFormDialog,
  BuildedInputFormDialogRef,
} from '~/shared/components/ui/FormBuilder';
import {
  isCanBeDiscard,
  isCanBeEdit,
  isCanBeNotificationSetting,
  isCanBeDueDateChangeRequest,
  isCanBeChangeRequest,
  isCanBeCompanyMemo,
  isCanBeReminderSetting,
  isCanBeDeadlineChange,
  isCanBeReplyDelegation,
  isCanBeComplete,
} from '../utils/validator';
import { IconButton } from '~/shared/components/ui/Button';
import { getFileName } from '~/shared/components/ui/FormBuilder/utils';
import { NotificationSendTargetSettingDialog } from '~/shared/components/ui/Dialog/NotificationSendTargetSettingDialog';
import { BqListDelayTime } from '~/shared/config';

interface AttachmentItems {
  id?: string;
  category?: sharelib.INameOption;
  assetId?: string;
  filename: string;
  url?: string;
  mimeType?: string;
  remarks?: string;
  linkType?: sharelib.INameOption;
}

const VIEW_ID: ViewId = 'SURVEY_REQUEST_SENDER_CONFIRMATION';

const requestFormListColumns = [
  {
    header: { id: 'requestDetail', viewId: VIEW_ID },
    propertyName: 'displayName',
  },
  {
    header: { id: 'attribute1', viewId: VIEW_ID },
    propertyName: 'attribute1',
    readonly: true,
    width: '20%',
  },
  {
    header: { id: 'attribute2', viewId: VIEW_ID },
    propertyName: 'attribute2',
    readonly: true,
    width: '20%',
  },
  {
    header: { id: 'attribute3', viewId: VIEW_ID },
    propertyName: 'attribute3',
    readonly: true,
    width: '20%',
  },
];
const resultFormListColumns = [
  {
    propertyName: 'agencyLabel',
    readonly: true,
    width: '4rem',
  },
  {
    header: { id: 'result', viewId: VIEW_ID },
    propertyName: 'answerLabel',
    readonly: true,
    width: '3rem',
  },
  {
    header: { id: 'required', viewId: VIEW_ID },
    propertyName: 'requiredLabel',
    readonly: true,
    width: '3rem',
  },
  {
    header: { id: 'formName', viewId: VIEW_ID },
    propertyName: 'displayName',
  },
  {
    header: { id: 'attribute1', viewId: VIEW_ID },
    propertyName: 'attribute1',
    readonly: true,
    width: '20%',
  },
  {
    header: { id: 'attribute2', viewId: VIEW_ID },
    propertyName: 'attribute2',
    readonly: true,
    width: '20%',
  },
  {
    header: { id: 'attribute3', viewId: VIEW_ID },
    propertyName: 'attribute3',
    readonly: true,
    width: '20%',
  },
];

const aggregateStages: AggregateStage[] = [
  {
    $union: {
      from: FullMethodName_ExpandedListSurveyRequestContents,
      fields: [
        {
          // 一意に識別できるよう、予定データに仮の依頼明細ID列を追加
          // surveyRequest.surveyReqyestIdにしているとフィルタできないのでsurveyRequestIdにしている
          name: [
            'surveyRequest.surveyBaseRequestId',
            'surveyRequest.requestUnit.businessUnitManagementId',
          ],
          as: 'surveyRequestId',
        },
        'surveyRequest',
        'surveyResult',
        'complete',
        'discard',
        'surveyResultChangeRequest',
        'surveyReplyDueDateChangeResult',
        'status',
        'replyDueDateDt',
      ],
    },
  },
];

export function SurveyRequestSenderConfirmation() {
  const intl = useIntl();
  const location = (useLocation().state as PageState) ?? [];
  const navi = useNavigate();
  const handleError = useErrorHandler();
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const myTenantId = useAuth().tenant?.tenantId ?? '';
  // ローディング
  const [isLoading, setLoading] = useState(false);

  // コミュニケーション機能設定
  const { setForumInfo } = useForumTool();

  // コミュニケーション機能設定
  const { setViewId } = useHelp();

  // 例外処理用のエラー情報
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  // preset
  const [preset, setPreset] = useState<PresetItem>({ name: '' });

  // ページ情報
  const [item, setItem] =
    useState<mtechnavi.api.survey.ISurveyRequestContent>();
  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);
  // 履歴通知表示
  const [isNotificationShow, setNotificationShow] = useState<boolean>(false);

  // 依頼通知番号
  const [surveyRequestAutoName, setSurveyRequestAutoName] =
    useState<string>('');
  // 自動催促設定
  const [autoReminderSetting, setAutoReminderSetting] =
    useState<boolean>(false);
  // 取引先名
  const [companyName, setCompanyName] = useState<string>('');
  // 依頼元
  const [requesters, setRequesters] = useState<string[]>([]);
  // 修正依頼コメント
  const [comment, setComment] = useState('');
  // 社内メモ
  const [companyMemo, setCompanyMemo] = useState('');
  // 自社（代表連絡先）
  const [mainContact, setMainContact] =
    useState<mtechnavi.api.company.IBusinessUnitContactContent>();

  // 依頼ファイル
  const [requestAttachmentItems, setRequestAttachmentItems] = useState<
    AttachmentItems[]
  >([]);
  // 回答ファイル (代行分)
  const [proxyAttachmentItems, setProxyAttachmentItems] = useState<
    AttachmentItems[]
  >([]);
  // 回答ファイル (受信側回答分)
  const [replyAttachmentItems, setReplyAttachmentItems] = useState<
    AttachmentItems[]
  >([]);
  // 回答ファイル (全件)
  const answerAttachmentItems = [
    ...proxyAttachmentItems,
    ...replyAttachmentItems,
  ];

  // 取得データ格納用 回答期日変更依頼データ（最新）
  const surveyReplyDueDateChangeRequestData =
    useRef<mtechnavi.api.survey.ISurveyReplyDueDateChangeRequest>({});
  const surveyReplyDueDateChangeResultData =
    useRef<mtechnavi.api.survey.ISurveyReplyDueDateChangeResult | null>();
  // 回答データ
  const surveyReplyData = useRef<mtechnavi.api.survey.ISurveyResult | null>();
  // 修正依頼データ
  const changeRequestData =
    useRef<mtechnavi.api.survey.ISurveyResultChangeRequest | null>();

  // ナビゲーションエリアの表示制御
  const [isEditable, setEditable] = useState<boolean>(true);
  const [isDeleteable, setDeleteable] = useState<boolean>(true);
  const [isSystemNotificationEditable, setSystemNotificationEditable] =
    useState<boolean>(true);

  // ボタン制御
  const [isVisibleDeadlineChangeApproval, setVisibleDeadlineChangeApproval] =
    useState<boolean>(true);
  const [isVisibleCompanyMemo, setVisibleCompanyMemo] = useState<boolean>(true);
  const [isVisibleDeadlineChange, setVisibleDeadlineChange] =
    useState<boolean>(true);
  const [isVisibleManualReminderSetting, setVisibleManualReminderSetting] =
    useState<boolean>(true);
  const [isVisibleSurveyRequest, setVisibleSurveyRequest] =
    useState<boolean>(true);
  const [isVisibleSurveyReplyDelegation, setVisibleSurveyReplyDelegation] =
    useState<boolean>(true);
  const [isVisibleSurveyRequestComplete, setVisibleSurveyRequestComplete] =
    useState<boolean>(true);
  const [isEditableAutoReminderSetting, setEditableAutoReminderSetting] =
    useState<boolean>(true);

  // 履歴
  const [histories, setHistories] = useState<HistoryItems[]>([]);
  const historyMessage = GetMessage({
    id: 'I0000005',
    value: { $1: histories?.length > 0 ? histories[0].slip_category : '' },
  });

  // システム通知先
  const [systemNotificationUsers, setSystemNotificationUsers] = useState<
    sharelib.IUserReference[]
  >([]);

  // 依頼フォーム情報
  const [requestForms, setRequestForms] = useState<
    SurveyRequestWorkFormWithValues[]
  >([]);
  // 回答フォーム情報
  const [resultForms, setResultForms] = useState<
    SurveyResultWorkFormWithValues[]
  >([]);

  // フッタ高さ調整用
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);

  // 各種ダイアログ制御
  const [isSystemNotificationDialogShow, setSystemNotificationDialogShow] =
    useState(false);
  const [isHistoryDialogShow, setHistoryDialogShow] = useState(false);
  const [isAutoReminderSettingDialogShow, setAutoReminderSettingDialogShow] =
    useState(false);
  const [isDeadlineChangeDialogShow, setDeadlineChangeDialogShow] =
    useState(false);
  const [isCompanyMemoDialogShow, setCompanyMemoDialogShow] = useState(false);
  const [
    isDeadlineChangeApprovalDialogShow,
    setDeadlineChangeApprovalDialogShow,
  ] = useState(false);
  const [isManualReminderDialogShow, setManualReminderDialogShow] =
    useState(false);
  // 手動催促ダイアログの送信ボタン活性・非活性対応            setLoading(true);
  const [isClickable, setClickable] = useState(true);
  const [isOwnStaffDialogShow, setOwnStaffDialogShow] = useState(false);
  const [
    isOwnStaffNotificationSettingDialogShow,
    setOwnStaffNotificationSettingDialogShow,
  ] = useState(false);
  const [isShowDiscard, setShowDiscard] = useState(false);
  const [discardComment, setDiscardComment] = useState('');
  const [isSurveyRequestDialogShow, setSurveyRequestDialogShow] =
    useState(false);
  const [
    isSurveyReplyDelegationDialogShow,
    setSurveyReplyDelegationDialogShow,
  ] = useState(false);
  // 通知メール送信対象設定ダイアログ
  const [
    isNotificationSendTargetSettingDialogShow,
    setNotificationSendTargetSettingDialogShow,
  ] = useState(false);
  const formInputDialogRef =
    useRef<
      BuildedInputFormDialogRef<mtechnavi.api.survey.ISurveyRequestWorkForm>
    >(null);
  const [formInputDialogTitleId, setFormInputDialogTitleId] = useState<
    'requestFormConfirmationDialog' | 'resultFormConfirmationDialog' | ''
  >('');
  const [isFileUploadDialogShow, setFileUploadDialogShow] = useState(false);

  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState<MessageProps>({});
  const confirmPromiseRef =
    useRef<(value: boolean | PromiseLike<boolean>) => void>();

  // メッセージ
  const surveyRequestCompleteConfMessage = {
    id: 'C0000001',
    value: { $1: '依頼終了' },
  };
  const completeWithDeleteFlagMessage = {
    prefixId: VIEW_ID,
    id: 'completeWithDeleteFlag',
  };
  const sendMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'send' }),
    },
  };
  const sendWithDeleteFlagMessage = {
    prefixId: VIEW_ID,
    id: 'sendWithDeleteFlag',
  };
  const successMessage = GetMessage({ id: 'I0000001' });

  // 依頼フォーム入力ステータス(名称マスタ)
  const [enteredStatus, notEnteredStatus] = useMemo(() => {
    const status = window.App.services.ui.getNameOption('A1010003');
    return [
      status.find((item) => item.systemName === 'B02'),
      status.find((item) => item.systemName === 'B01'),
    ];
  }, []);

  // paginator の ID 取得処理
  const getTargetId = () => {
    const urlPathName = window.location.pathname;
    const splitPath = urlPathName.split('/');
    if (location.ids) {
      return location.ids ?? [];
    } else {
      if (splitPath.length > 1) {
        const idPath = splitPath[splitPath.length - 1];
        const endpointPath = splitPath[splitPath.length - 2];
        if (
          idPath !== 'survey-request-sender-confirmation' &&
          endpointPath === 'survey-request-sender-confirmation'
        ) {
          // 複数IDが結合された時想定（指図案件確認）
          if (idPath.indexOf(',') >= 0) {
            return idPath.split(',');
          } else {
            return [idPath];
          }
        }
      }
    }
    return [];
  };

  // paginator のソート処理
  const getAppendSort = () => {
    const nowSort: SortTerm[] = getSortSettingFromLocalStorage(
      location.baseViewOption?.sourceViewId ?? 'SURVEY_REQUEST_SENDER_LIST',
      myEmail
    );

    nowSort.map((item) => {
      // 明細番号がなかったら追加する
      if (!item['surveyRequest.surveyRequestDetailAutoName']) {
        nowSort.push({
          'surveyRequest.surveyRequestDetailAutoName': 'asc',
        });
      }
    });
    if (nowSort.length === 0) {
      nowSort.push({
        'surveyRequest.surveyRequestDetailAutoName': 'asc',
      });
    }
    return nowSort;
  };

  // pagenatorに基本情報をセット
  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListSurveyRequestContents,
    pageNumber: location.confirmationViewOption?.pageNumber ?? 1,
    pageSize: 1,
    maxPageNumber: 1,
    requestBody: {
      dataFilter: {
        surveyRequestIds: getTargetId(),
      } as mtechnavi.api.survey.ListSurveyRequestContentsRequest.IDataFilter,
    },
    filter: {
      $or: [
        { 'surveyRequest.surveyRequestId': { $in: getTargetId() } },
        { surveyRequestId: { $in: getTargetId() } },
      ],
    },
    sort: getAppendSort(),
    items: [],
    originalItems: [],
    aggregate: [
      {
        $union: {
          ...(aggregateStages[0].$union as AggregateStageUnion),
          option: {
            useQuery: false,
            requestBody: {
              surveyBaseRequestIds: location.subIds ?? [],
            },
          },
        },
      },
    ],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  // 戻るボタン用のパス
  const backPath = useMemo(() => {
    if (!location.baseViewOption?.sourceViewId) {
      // 一覧起動ではない場合は戻るパスは空、戻るアクションは非活性
      return '';
    }
    switch (location.baseViewOption?.sourceViewId) {
      case 'SURVEY_REQUEST_SENDER_LIST':
        return '/supplier-base/survey-request-list';
      case 'SURVEY_REQUEST_SENDER_DETAIL_LIST':
        return '/supplier-base/survey-request-sender-detail-list';
      case 'SURVEY_REQUEST_SENDER_APPROVAL_LIST':
        return '/supplier-base/survey-request-approval-list';
      case 'SURVEY_REQUEST_SENDER_APPROVAL_DETAIL_LIST':
        return '/supplier-base/survey-request-sender-approval-detail-list';
      default:
        return '/supplier-base/survey-request-list';
    }
  }, [location.baseViewOption?.sourceViewId]);

  // ページ高さ調整
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  // 権限エラーの場合、トーストを出す
  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  // ページ変更時
  useEffect(() => {
    const items =
      page.originalItems as unknown as mtechnavi.api.survey.ISurveyRequestContent[];
    if (!items.length) return;
    const item = items[0];
    setForumInfo({
      typeName: ForumTypeName.Survey,
      resourceId: item.surveyRequest?.surveyRequestId ?? '',
      companyIds: [item.surveyRequest?.requestUnit?.companyId ?? ''],
    });
    setViewId(VIEW_ID);
  }, [page, setForumInfo, myTenantId]);

  // pageデータ変更時の処理
  useEffect(() => {
    const items =
      page.originalItems as unknown as mtechnavi.api.survey.ISurveyRequestContent[];
    if (items.length > 0) {
      setItem(items[0]);
    }
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
    setNotificationShow(false);
  }, [page]);

  // 初回画面用データ
  useEffect(() => {
    // 基本情報でセットした値を元に情報を取得
    handleReload();

    (async () => {
      try {
        const [{ presetItem }, selfCompany] = await Promise.all([
          getViewIdPreset(intl, VIEW_ID), // viewIDに紐付くpresetを取得
          window.App.services.companyService.getCompany({}), // 会社マスタ
        ]);
        setPreset(presetItem);
        setCompanyName(selfCompany.displayNameLang?.ja ?? '');
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ページデータの変更を受けて表示する情報を取得する
  useEffect(() => {
    if (!item) {
      return;
    }

    setSurveyRequestAutoName(
      getSurveyRequestAutoName(
        item?.surveyRequest?.surveyRequestAutoName,
        item?.surveyRequest?.surveyRequestDetailAutoName
      )
    );

    // 社内メモを取得
    setCompanyMemo(item?.surveyRequest?.remarks ?? '');

    // 自動催促設定を取得
    const autoReminder = (
      item?.surveyRequest?.autoReminderSettings ?? []
    ).filter((v) => v.autoReminder);
    setAutoReminderSetting(autoReminder.length > 0);

    // システム通知先データを取得
    setSystemNotificationUsers(
      item?.surveyRequest?.systemNotificationUsers ?? []
    );

    // 依頼元を設定
    if (!item?.surveyRequest?.requesters) {
      const tmp: string[] = Array(2).fill('');
      setRequesters(tmp);
    } else {
      setRequesters(item?.surveyRequest?.requesters);
    }
    // 依頼ファイルリストを設定
    const requestAttachments: sharelib.IAttachment[] = [
      ...(item?.surveyRequest?.commonRequestAttachments ?? []),
      ...(item?.surveyRequest?.businessUnitRequestAttachments ?? []),
    ];
    putRequestAttachmentItem(requestAttachments);

    // 回答ファイルリストを設定
    if (
      item?.surveyRequest?.proxyAttachments &&
      item?.surveyRequest?.proxyAttachments.length > 0
    ) {
      putProxyAttachmentItems(item?.surveyRequest?.proxyAttachments);
    }

    // 各種操作可否の制御
    setDeleteable(isCanBeDiscard(item?.status)); // 破棄
    setEditable(isCanBeEdit(item?.status)); // 編集
    setSystemNotificationEditable(isCanBeNotificationSetting(item?.status)); // 通知設定
    setVisibleSurveyRequest(isCanBeChangeRequest(item?.status)); // 修正依頼
    setVisibleCompanyMemo(isCanBeCompanyMemo(item?.status)); // 社内メモ
    const isCanBeRemind = isCanBeReminderSetting(item?.status); // 催促設定
    setEditableAutoReminderSetting(isCanBeRemind); // 自動催促
    setVisibleManualReminderSetting(isCanBeRemind); // 手動催促
    setVisibleDeadlineChange(isCanBeDeadlineChange(item?.status)); // 回答期日変更
    setVisibleSurveyReplyDelegation(isCanBeReplyDelegation(item?.status)); // 回答代行
    setVisibleSurveyRequestComplete(isCanBeComplete(item?.status)); // 依頼終了

    // ページデータに紐づく各種データの取得
    (async () => {
      setLoading(true);
      try {
        // フォームの描画に関わるため先に取得
        await getReplyAndChangeRequestData(); // 回答および修正依頼データ

        await Promise.all([
          listHistoryData(), //履歴データ
          getReplyDueDateChangeData(), // 期日変更および期日変更依頼データ
          getWorkFormData(), // 依頼・回答フォーム
          getMainContactData(), // 自社（代表連絡先）
        ]);

        // (依頼)回答期日変更の表示制御
        setVisibleDeadlineChangeApproval(
          isCanBeDueDateChangeRequest(item?.status) &&
            !!surveyReplyDueDateChangeRequestData.current
              .surveyReplyDueDateChangeRequestId
        );
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();

    // item変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, intl]);

  /**
   * 依頼登録遷移(編集ボタン)
   */
  const handleSurveyRequestInput = () => {
    const state: PageState = {
      ids: [item?.surveyRequest?.surveyBaseRequestId ?? ''],
      sourceViewId: VIEW_ID,
      actionType: 'edit',
      naviFilters: location.naviFilters,
      beforeStateIds: location.ids,
      baseViewOption: location.baseViewOption,
      confirmationViewOption: { pageNumber: page.pageNumber },
    };
    navi('/supplier-base/survey-request-sender-input', { state });
  };

  /**
   * リロード処理
   */
  const handleReload = () => {
    dispatch({
      type: 'reload',
      fullMethodName: FullMethodName_ListSurveyRequestContents,
      onChangeLoadingState: (v) => {
        setLoading(v);
      },
    });
  };

  /**
   * 履歴ダイアログの表示処理
   */
  const handleOpenHistoryDialog = async () => {
    setLoading(true);
    await listHistoryData();
    setHistoryDialogShow(true);
    setLoading(false);
  };

  /**
   * 履歴データ取得
   */
  const listHistoryData = async () => {
    try {
      let typeName: typeName = '';
      let recordId = '';
      const privateStatus: string[] = ['B00', 'B01', 'B02', 'B03'];
      if (privateStatus.includes(item?.status?.systemName ?? '')) {
        typeName = 'mtechnavi.api.survey.SurveyBaseRequest';
        recordId = item?.surveyRequest?.surveyBaseRequestId ?? '';
      } else {
        typeName = 'mtechnavi.api.survey.SurveyRequest';
        recordId = item?.surveyRequest?.surveyRequestId ?? '';
      }

      // 履歴一覧を取得
      const datas = await getHistoryItems(intl, typeName, [recordId]);
      setHistories(datas);
      // 最新の履歴通知レベルによる制御
      setNotificationShow(isShowNotificationArea(datas));
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    }
  };

  /**
   * 回答期日変更依頼データの取得
   */
  const getReplyDueDateChangeData = async () => {
    const surveyRequestId = item?.surveyRequest?.surveyRequestId || '';
    if (!surveyRequestId) {
      return;
    }
    const [changeRequest, changeResult] = await Promise.all([
      getLatestSharedSurveyReplyDueDateChangeRequest(surveyRequestId),
      getLatestSurveyReplyDueDateChangeResult(surveyRequestId),
    ]);
    if (!changeRequest) {
      surveyReplyDueDateChangeRequestData.current = {};
      surveyReplyDueDateChangeResultData.current = null;
      return;
    }
    const requestedAt = Long.fromValue(
      changeRequest.requestedAt || 0
    ).toNumber();
    const changedAt = Long.fromValue(changeResult?.changedAt || 0).toNumber();
    if (requestedAt >= changedAt) {
      surveyReplyDueDateChangeRequestData.current = changeRequest;
    } else {
      surveyReplyDueDateChangeRequestData.current = {};
    }
    surveyReplyDueDateChangeResultData.current = changeResult;
  };

  /**
   * 回答および修正依頼データの取得
   */
  const getReplyAndChangeRequestData = async () => {
    const surveyRequestId = item?.surveyRequest?.surveyRequestId || '';
    if (!surveyRequestId) {
      return;
    }
    const reply = await getLatestSharedListSurveyResult(surveyRequestId);
    surveyReplyData.current = reply;
    if (reply?.replyAttachments && reply.replyAttachments.length > 0) {
      putReplyAttachmentItems(reply.replyAttachments);
    } else {
      putReplyAttachmentItems([]);
    }
    if (!reply) {
      // 回答がなければ修正依頼もないはず
      changeRequestData.current = null;
      return;
    }
    const changeRequest = await getLatestSurveyResultChangeRequest(
      surveyRequestId,
      reply?.surveyResultId || ''
    );
    changeRequestData.current = changeRequest;
  };

  /**
   * 依頼・回答フォームの情報を取得
   */
  const getWorkFormData = async () => {
    if (!item) {
      return;
    }
    const [requestWorkForms, planResultWorkForms, actualResultWorkForms] =
      await Promise.all([
        // 依頼フォームを取得
        item?.surveyRequest?.requestFormIds &&
        item?.surveyRequest?.requestFormIds.length > 0
          ? listSurveyRequestWorkForms(item?.surveyRequest?.requestFormIds)
          : ({} as Partial<mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse>),
        // 回答(予定)フォームを取得
        item.surveyRequest?.resultFormIds &&
        item.surveyRequest?.resultFormIds.length > 0
          ? listPlanSurveyResultWorkForms(item.surveyRequest?.resultFormIds)
          : ({} as Partial<mtechnavi.api.survey.ListSurveyResultWorkFormsResponse>),
        // 回答(実績)フォームを取得
        item.surveyRequest?.resultFormIds &&
        item.surveyRequest?.resultFormIds.length > 0
          ? listActualSurveyResultWorkForms(item.surveyRequest?.resultFormIds)
          : ({} as Partial<mtechnavi.api.survey.ListSurveyResultWorkFormsResponse>),
      ]);

    // 各フォームの formId (依頼 / 回答実績) を抽出
    const formIds = [
      ...(requestWorkForms?.items || []).map((form) => form.formId),
      ...(actualResultWorkForms?.items || []).map((form) => form.formId),
    ].filter((formId) => !!formId) as string[];

    // 回答予定の baseFormId を抽出
    const baseFormIds = (planResultWorkForms?.items || []).map(
      (form) => form.baseFormId || ''
    );

    // 各フォームの構成情報をまとめて取得
    const [
      [
        formListRes,
        formSettingListRes,
        formSettingItemListRes,
        formValueListRequestFormRes,
        formValueListResultFormRes,
      ],
      [baseFormListRes, baseFormSettingListRes, baseFormSettingItemListRes],
    ] = await Promise.all([
      Promise.all(
        formIds.length > 0
          ? [
              listForms(formIds),
              listFormSettings(formIds),
              listFormSettingItems(formIds),
              listFormValues(
                null,
                'mtechnavi.api.survey.SurveyBaseRequest',
                item.surveyRequest?.surveyBaseRequestId
              ),
              item.surveyRequest?.surveyRequestId
                ? listFormValues(
                    null,
                    'mtechnavi.api.survey.SurveyRequest',
                    item.surveyRequest?.surveyRequestId
                  )
                : ({} as Partial<mtechnavi.api.form.ListFormValuesResponse>),
            ]
          : [
              {} as Partial<mtechnavi.api.form.ListFormsResponse>,
              {} as Partial<mtechnavi.api.form.ListFormSettingsResponse>,
              {} as Partial<mtechnavi.api.form.ListFormSettingItemsResponse>,
              {} as Partial<mtechnavi.api.form.ListFormValuesResponse>,
              {} as Partial<mtechnavi.api.form.ListFormValuesResponse>,
            ]
      ),
      Promise.all(
        baseFormIds.length > 0
          ? [
              listBaseForms({ baseFormIds }),
              listBaseFormSettings(baseFormIds),
              listBaseFormSettingItems(baseFormIds),
            ]
          : [
              {} as Partial<mtechnavi.api.form.ListBaseFormsResponse>,
              {} as Partial<mtechnavi.api.form.ListBaseFormSettingsResponse>,
              {} as Partial<mtechnavi.api.form.ListBaseFormSettingItemsResponse>,
            ]
      ),
    ]);

    // 依頼フォームを画面用に整形
    const requestFormWithValues = convertToRequestWorkFormWithValues(
      requestWorkForms?.items || [],
      {
        formList: formListRes?.items || [],
        formSettingList: formSettingListRes?.items || [],
        formSettingItemList: formSettingItemListRes?.items || [],
        formValueList: formValueListRequestFormRes?.items || [],
        targetId: item?.surveyRequest?.surveyBaseRequestId || '',
      },
      { intl, enteredStatus, notEnteredStatus }
    );
    setRequestForms(requestFormWithValues);

    // 回答(予定)フォームを画面用に整形
    const planResultFormWithValues = convertToPlanResultWorkFormWithValues(
      planResultWorkForms.items || [],
      {
        baseFormList: baseFormListRes.items || [],
        baseFormSettingList: baseFormSettingListRes.items || [],
        baseFormSettingItemList: baseFormSettingItemListRes.items || [],
      }
    );

    // 回答(実績)フォームを画面用に整形
    const actualResultFormWithValues = convertToActualResultWorkFormWithValues(
      actualResultWorkForms.items || [],
      {
        formList: formListRes.items || [],
        formSettingList: formSettingListRes.items || [],
        formSettingItemList: formSettingItemListRes.items || [],
        formValueList: formValueListResultFormRes.items || [],
        targetId: item.surveyRequest?.surveyRequestId || '',
      },
      surveyReplyData.current?.lockedFormIds || [],
      !!changeRequestData.current
    );

    setResultForms([
      ...planResultFormWithValues,
      ...actualResultFormWithValues,
    ]);
  };

  /**
   * 自社（代表連絡先）の取得
   */
  const getMainContactData = async () => {
    try {
      // 取引先詳細を取得
      if (item?.surveyRequest?.requestUnit?.businessUnitManagementId) {
        const businessUnitContactContentOption: FilterRequest = {
          action: 'reload',
          fullMethodName: FullMethodName_ListBusinessUnitContactContents,
          filter: {
            $and: [
              { 'businessUnitContact.mainContact': { $eq: true } },
              { 'businessUnitContact.businessUnitManagementId': { $ne: '' } },
            ],
          },
          requestBody: {
            businessUnitManagementIds: [
              item?.surveyRequest?.requestUnit?.businessUnitManagementId ?? '',
            ],
          },
          sort: [],
        };
        const businessUnitContactContentResponse =
          await window.App.services.ui.worker.filter(
            businessUnitContactContentOption
          );
        const itemBusinessUnitContactContent: mtechnavi.api.company.IBusinessUnitContactContent =
          businessUnitContactContentResponse.items.length > 0
            ? (businessUnitContactContentResponse
                .items[0] as mtechnavi.api.company.IBusinessUnitContactContent)
            : {};

        setMainContact(itemBusinessUnitContactContent);
      }
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    }
  };

  /**
   * 依頼ファイルのセット
   */
  const putRequestAttachmentItem = (
    attachmentItems: sharelib.IAttachment[]
  ) => {
    const resultItems: AttachmentItems[] = [];
    attachmentItems.map((item) => {
      resultItems.push({
        id: item.assetId ?? '',
        category: item.category ?? {},
        assetId: item.assetId ?? '',
        filename: item.filename ?? '',
        mimeType: item.mimeType ?? '',
        remarks: item.remarks ?? '',
        linkType: item.linkType ?? {},
      });
    });
    setRequestAttachmentItems([...resultItems]);
  };

  const putProxyAttachmentItems = (attachmentItems: sharelib.IAttachment[]) => {
    const resultItems: AttachmentItems[] = [];
    attachmentItems.map((item) => {
      resultItems.push({
        id: item.assetId ?? '',
        category: item.category ?? {},
        assetId: item.assetId ?? '',
        filename: item.filename ?? '',
        mimeType: item.mimeType ?? '',
        remarks: item.remarks ?? '',
        linkType: item.linkType ?? {},
      });
    });
    setProxyAttachmentItems([...resultItems]);
  };

  const putReplyAttachmentItems = (attachmentItems: sharelib.IAttachment[]) => {
    const resultItems: AttachmentItems[] = [];
    attachmentItems.map((item) => {
      resultItems.push({
        id: item.assetId ?? '',
        category: item.category ?? {},
        assetId: item.assetId ?? '',
        filename: item.filename ?? '',
        mimeType: item.mimeType ?? '',
        remarks: item.remarks ?? '',
        linkType: item.linkType ?? {},
      });
    });
    setReplyAttachmentItems([...resultItems]);
  };

  /**
   * TO(宛先表示)テキストの生成
   */
  const formatSurveyRequestToLabel = () => {
    const mainContact = item?.surveyRequest?.requestUnit?.mainContact ?? [];
    const mainContactAssistant =
      item?.surveyRequest?.requestUnit?.mainContactAssistant ?? [];
    const results: string[] = [];
    mainContact.map((v) => {
      if (!v.displayName && !v.email) {
        return;
      }
      const displayName = v.displayName ?? '';
      const email = v.email ?? '';
      const to = `${displayName}(${email})`;
      results.push(to);
    });
    mainContactAssistant.map((v) => {
      if (mainContact.some((main) => main.email === v.email)) {
        return;
      }
      if (!v.displayName && !v.email) {
        return;
      }
      const displayName = v.displayName ?? '';
      const email = v.email ?? '';
      const to = `${displayName}(${email})`;
      results.push(to);
    });
    return results.join(',');
  };

  const formatResponsibleUserToLabel = () => {
    const responsibleUsers = item?.surveyRequest?.responsibleUsers ?? [];
    const results: string[] = [];
    responsibleUsers.map((v) => {
      if (!v.displayName && !v.email) {
        return;
      }
      const displayName = v.displayName ?? '';
      const email = v.email ?? '';
      const to = `${displayName}(${email})`;
      results.push(to);
    });
    return results.join(',');
  };

  // ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListSurveyRequestContents,
      pageNumber: n,
    });
  };

  /**
   * システム通知設定ダイアログの確定処理
   */
  const handleSystemNotificationSetting = async (result: string) => {
    setLoading(true);
    try {
      const userReference = await convertUserReference(myEmail);
      const req: SystemNotificationSetting = {
        recordId: item?.surveyRequest?.surveyRequestId ?? '',
        appendUser:
          result === 'setAsNotificationDestination' ? userReference : null,
        removeUser:
          result !== 'setAsNotificationDestination' ? userReference : null,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'updateSystemNotificationSetting',
        request: req,
      });
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    }
  };

  /**
   * 破棄ダイアログの確定処理
   */
  const handleDiscard = async () => {
    if (item?.surveyRequest?.flagProperties?.active) {
      if (!(await confirmation(sendWithDeleteFlagMessage))) {
        return;
      }
    } else {
      if (!(await confirmation(sendMessage))) {
        return;
      }
    }

    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'discardSurveyRequest',
        request: {
          surveyRequestIds: [item?.surveyRequest?.surveyRequestId ?? ''],
          comment: discardComment,
        },
      });
      if (item?.surveyRequest?.flagProperties?.active) {
        await window.App.services.ui.worker.apiCall({
          actionName: 'deleteFlagInSurveyRequest',
          request: {
            surveyRequestIds: [item?.surveyRequest?.surveyRequestId ?? ''],
          },
        });
      }

      setShowDiscard(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      setLoading(false);
      throw err;
    }
    // 一覧に戻る
    backToBaseViewPage();
  };

  /**
   * 自動催促設定ダイアログ確定処理
   */
  const handleAutoReminderSettingSave = async (
    result: AutoReminderSettingDialogOutputOption
  ) => {
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'surveyAutoReminder',
        request: {
          surveyRequestId: item?.surveyRequest?.surveyRequestId ?? '',
          autoReminderSetting: result.autoReminderSetting,
        },
      });
      setAutoReminderSettingDialogShow(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * 依頼終了処理
   */
  const handleSurveyRequestComplete = async () => {
    if (item?.surveyRequest?.flagProperties?.active) {
      if (!(await confirmation(completeWithDeleteFlagMessage))) {
        return;
      }
    } else {
      if (!(await confirmation(surveyRequestCompleteConfMessage))) {
        return;
      }
    }

    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'completeSurveyRequest',
        request: {
          surveyRequestIds: [item?.surveyRequest?.surveyRequestId ?? ''],
        },
      });
      if (item?.surveyRequest?.flagProperties?.active) {
        await window.App.services.ui.worker.apiCall({
          actionName: 'deleteFlagInSurveyRequest',
          request: {
            surveyRequestIds: [item?.surveyRequest?.surveyRequestId ?? ''],
          },
        });
      }
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      setLoading(false);
      throw err;
    }
    // 一覧に戻る
    backToBaseViewPage();
  };

  /**
   * （依頼）回答期日変更ダイアログ確定処理
   */
  const handleDeadlineChangeRequestSave = async (
    deadlineChangeApprovalResult: DeadlineChangeApprovalResult
  ) => {
    if (!(await confirmation(sendMessage))) {
      return;
    }

    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'changeSurveyRequestDeadline',
        request: [
          {
            surveyRequestId: item?.surveyRequest?.surveyRequestId ?? '',
            replyDueDateDt: convertDatetime(
              deadlineChangeApprovalResult.deadline,
              'YYYY/MM/DD'
            ),
            comment: deadlineChangeApprovalResult.comment,
            surveyReplyDueDateChangeRequestId:
              surveyReplyDueDateChangeRequestData.current
                ?.surveyReplyDueDateChangeRequestId || null,
          },
        ],
      });
      setDeadlineChangeApprovalDialogShow(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * 回答期日変更ダイアログ確定処理
   */
  const handleDeadlineChangeSave = async (
    deadlineChangeResult: DeadlineChangeResult
  ) => {
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'changeSurveyRequestDeadline',
        request: [
          {
            surveyRequestId: item?.surveyRequest?.surveyRequestId ?? '',
            replyDueDateDt: convertDatetime(
              deadlineChangeResult.deadline,
              'YYYY/MM/DD'
            ),
            comment: deadlineChangeResult.comment,
            surveyReplyDueDateChangeRequestId:
              surveyReplyDueDateChangeResultData.current
                ?.surveyReplyDueDateChangeRequestId || null,
          },
        ],
      });
      setDeadlineChangeDialogShow(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * 社内メモ更新ダイアログ確定処理
   */
  const handleRemarksSave = async () => {
    setLoading(true);
    try {
      const req: mtechnavi.api.survey.ISurveyRequest = {
        surveyRequestId: item?.surveyRequest?.surveyRequestId ?? '',
        remarks: companyMemo,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'updateSurveyRequestRemarks',
        request: req,
      });
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * 修正依頼ダイアログ確定処理
   */
  const handleSurveyRequest = async () => {
    if (!(await confirmation(sendMessage))) {
      return;
    }
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'createSurveyResultChange',
        request: {
          surveyRequestId: item?.surveyRequest?.surveyRequestId ?? '',
          surveyResultId: surveyReplyData?.current?.surveyResultId ?? '',
          comment: comment,
        },
      });
      setSurveyRequestDialogShow(false);
      success([successMessage]);
      setComment('');
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 手動催促
  const handleManualReminder = async (
    manualReminderResult: ManualReminderDialogOutputOption
  ) => {
    if (!(await confirmation(sendMessage))) {
      setClickable(true);
      return;
    }
    setLoading(true);
    setClickable(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'surveyManualReminder',
        request: {
          surveyRequestId: item?.surveyRequest?.surveyRequestId ?? '',
          contentType: manualReminderResult.reminderMessageType || null,
          content: manualReminderResult.reminderMessage,
          businessUnitManagementId:
            item?.surveyRequest?.requestUnit?.businessUnitManagementId || null,
          surveyReplyDueDateChangeResultId:
            surveyReplyDueDateChangeResultData?.current
              ?.surveyReplyDueDateChangeRequestId || null,
          sendTarget: manualReminderResult.sendTarget,
        },
      });
      setManualReminderDialogShow(false);
      success([successMessage]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setClickable(true);
      setLoading(false);
    }
  };

  // 出力済みフラグ更新
  const updateExportedFlag = async () => {
    const surveyResultId = surveyReplyData.current?.surveyResultId;
    if (!surveyResultId || !!item?.surveyRequest?.surveyResultExportedAt) {
      return;
    }
    setLoading(true);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'exportedSurveyResultFile',
        request: {
          surveyResultId,
        },
      });
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * フォーム回答代行用ファイルダウンロード
   */
  const handleDownloadResultWorkFormTemplates = async () => {
    setLoading(true);
    try {
      const resp = await exportProxySurveyResultWorkForms(
        item?.surveyRequest?.surveyRequestId || ''
      );
      const assetId = resp.items.at(0)?.assetId;
      if (!assetId) {
        setLoading(false);
        return;
      }
      const fileName = `${item?.surveyRequest?.displayName || ''}(${
        item?.surveyRequest?.requestUnit?.displayName
      })`;
      autoDownloadFileWithDate(fileName, 'xlsx', assetId);
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * フォーム回答代行
   */
  const handleFormReplyDelegation = () => {
    if (resultForms.filter((form) => form.isReplied).length > 0) {
      // (修正依頼中でも)受信側の回答があったらフォーム回答代行は不可とする
      error([GetMessageWithIntl(intl, { id: 'E0000124' })]);
      return;
    }
    setFileUploadDialogShow(true);
  };
  const handleFormReplyDelegateDecision = async (
    result: FileUploadDialogResult
  ) => {
    try {
      setLoading(true);
      const importResult = (await window.App.services.ui.worker.apiCall({
        actionName: 'importProxySurveyResultWorkForms',
        request: {
          url: result.files.at(0)?.url,
          exportError: true,
        },
      })) as mtechnavi.api.survey.IImportProxySurveyResultWorkFormsResponse[];
      if (
        importResult &&
        importResult.length > 0 &&
        importResult.at(0)?.assetId
      ) {
        // アップロードファイル名の拡張子部分を除いたものをファイル名とする
        autoDownloadErrorFile(
          getFileName(result.files.at(0)?.file.name || ''),
          'xlsx',
          importResult.at(0)?.assetId || ''
        );
        error([GetMessageWithIntl(intl, { id: 'E1000018' })]);
        setLoading(false);
        return;
      }
      success([successMessage]);
      setFileUploadDialogShow(false);
      handleReload();
    } catch (err) {
      // 正常終了時は再取得処理に続くので、エラー時のみローディング解除
      setLoading(false);
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    }
  };

  /**
   * 自社担当通知設定ダイアログの確定処理
   */
  const handleOwnStaffSystemNotificationSetting = async (
    result: sharelib.IUserReference[]
  ) => {
    setLoading(true);
    try {
      const req = {
        recordId: item?.surveyRequest?.surveyRequestId ?? '',
        appendUsers: result,
        removeUsers: null,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'updateSurveyRequestSystemNotificationSetting',
        request: req,
      });
      success([successMessage]);
      handleReload();
    } catch (err) {
      // 正常終了時は再取得処理に続くので、エラー時のみローディング解除
      setLoading(false);
      error(getWorkerExceptionMessage(intl, err));
      return;
    }
    setOwnStaffNotificationSettingDialogShow(false);
    setOwnStaffDialogShow(false);
  };

  // 一覧に戻る
  const backToBaseViewPage = async () => {
    const state: PageState = {
      ids: location.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: location.naviFilters,
      beforeStateIds: location.beforeStateIds,
      baseViewOption: location.baseViewOption,
    };

    // 戻り先に遷移元(一覧)情報をセット
    //  遷移先不明の場合は依頼管理一覧に戻る
    const backPath =
      getPathnameByViewId(location.baseViewOption?.sourceViewId)?.path ??
      '/supplier-base/survey-request-list';

    if (BqListDelayTime) {
      // 更新内容が反映されるようウェイトをかける
      await new Promise((resolve) => setTimeout(resolve, BqListDelayTime));
    }

    navi(backPath, { state });
  };

  // 依頼フォーム確認
  const handleOpenRequestForm = (
    targetItem: SurveyRequestWorkFormWithValues
  ) => {
    setFormInputDialogTitleId('requestFormConfirmationDialog');
    formInputDialogRef.current?.open(
      targetItem.form || {},
      targetItem.formSetting || {},
      targetItem.formSettingItems || [],
      targetItem.workForm || {},
      'B10',
      targetItem.formValues,
      false,
      false
    );
  };

  // 回答フォーム確認
  const handleOpenResultForm = (targetItem: SurveyResultWorkFormWithValues) => {
    if (!targetItem.form) {
      // フォーム情報がない場合は開かない
      error([GetMessageWithIntl(intl, { id: 'E0000126' })]);
      return;
    }
    setFormInputDialogTitleId('resultFormConfirmationDialog');
    formInputDialogRef.current?.open(
      targetItem.form || {},
      targetItem.formSetting || {},
      targetItem.formSettingItems || [],
      targetItem.workForm || {},
      'B10',
      targetItem.formValues,
      false,
      true,
      targetItem.isComplete
    );
  };

  /**
   * 確認ダイアログ処理
   * Promise で結果を制御する。
   * 確定: true / キャンセル: false
   */
  const confirmation = (viewMessage: MessageProps): Promise<boolean> => {
    setOpenConfirmDialog(true);
    setConfirmMessage(viewMessage);
    return new Promise((resolve) => {
      confirmPromiseRef.current = resolve;
    });
  };

  /**
   * 確認ダイアログの確認処理
   */
  const handleConfirmed = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(true);
    }
    setOpenConfirmDialog(false);
  };

  /**
   * 確認ダイアログのキャンセル処理
   */
  const handleCancel = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(false);
    }
    setOpenConfirmDialog(false);
  };

  const getPageNavigationIconItems = (): NavigationIconMenu[] => {
    const navigationIconItems: NavigationIconMenu[] = [];
    if (isEditable) {
      navigationIconItems.push({
        name: 'edit',
        displayName: '編集',
        func: () => {
          handleSurveyRequestInput();
        },
      });
    }
    if (isDeleteable) {
      navigationIconItems.push({
        name: 'delete',
        displayName: '削除',
        func: () => {
          setDiscardComment('');
          setShowDiscard(true);
        },
      });
    }
    navigationIconItems.push({
      name: 'history',
      displayName: '履歴',
      func: handleOpenHistoryDialog,
    });
    if (isSystemNotificationEditable) {
      navigationIconItems.push({
        name: 'recipients',
        displayName: '通知設定',
        func: () => {
          setSystemNotificationDialogShow(true);
        },
      });
    }
    return navigationIconItems;
  };

  const boolDataFormat = (target: boolean) => {
    return target ? '○' : '-';
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="SurveyRequestSenderConfirmation">
          <div className="header">
            <PageNavigation
              backpagePath={backPath}
              iconItems={getPageNavigationIconItems()}
              pageInfo={{
                isDisableBackNavi: !backPath,
                isVisibleMoveNavi: true,
                isFirstPage,
                isMaxPage,
                pageNumber: page.pageNumber,
              }}
              handleMovePage={handleMovePage}
              infoOption={{
                lastUpdateInfo: { isVisibleUpdateInfo: false },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => {
                const state: PageState = {
                  ids: [item?.surveyRequest?.surveyBaseRequestId ?? ''],
                  sourceViewId: VIEW_ID,
                  naviFilters: location.naviFilters,
                  beforeStateIds: location.ids,
                  baseViewOption: location.baseViewOption,
                };
                navi(backPath, { state });
              }}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <div className="info">
              <div className="basic-info-body">
                {/* ////通知枠//// */}
                <div
                  className={`notification-area ${
                    isNotificationShow ? '' : 'close'
                  }`}
                >
                  {/* {履歴通知エリア} */}
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-50">
                        <div className="notification-display-area">
                          {historyMessage}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-50">
                      {isVisibleDeadlineChangeApproval && (
                        <CaptionButton
                          className="w-15"
                          name=""
                          caption="(依頼)回答期日変更"
                          onClick={() => {
                            if (
                              !surveyReplyDueDateChangeRequestData.current
                                .surveyReplyDueDateChangeRequestId
                            ) {
                              error([
                                GetMessageWithIntl(intl, {
                                  id: 'E0000075',
                                }),
                              ]);
                              return;
                            }
                            setDeadlineChangeApprovalDialogShow(true);
                          }}
                          buttonType="basic"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* ////依頼//// */}
                <Accordion
                  title={GetMessageWithIntl(intl, {
                    id: 'request',
                    viewId: VIEW_ID,
                  })}
                >
                  <div className="input-line mixed-input-line">
                    <div className="item-group-100">
                      <div className="w-25">
                        {/* 取引先コード */}
                        <Textbox
                          name="requestUnit.code"
                          type="text"
                          disabled={true}
                          value={item?.surveyRequest?.requestUnit?.code ?? ''}
                          labelId={`${VIEW_ID}.businessUnitCode`}
                          columns={preset.columns}
                        />
                      </div>
                      <div className="w-50">
                        {/* 取引先名 */}
                        <Textbox
                          name="requestUnit.displayName"
                          type="text"
                          disabled={true}
                          value={
                            item?.surveyRequest?.requestUnit?.displayName ?? ''
                          }
                          labelId={`${VIEW_ID}.businessUnitDisplayName`}
                          columns={preset.columns}
                        />
                      </div>
                      <div className="w-25">
                        {/* 自社担当 */}
                        <CaptionButton
                          name=""
                          caption="自社担当"
                          onClick={() => {
                            setOwnStaffDialogShow(true);
                          }}
                          buttonType="basic"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-77">
                        {/* 依頼名 */}
                        <h3 className="strong">
                          {item?.surveyRequest?.displayName ?? ''}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="input-line label-margin">
                    <div className="item-group-100">
                      <div className="w-25">
                        {/* ステータス */}
                        {item?.status?.displayNameLang?.ja ?? ''}
                      </div>
                      <div className="w-50">
                        {/* 社内メモ */}
                        {isVisibleCompanyMemo && (
                          <CaptionButton
                            className="button-margin"
                            name=""
                            caption="社内メモ"
                            onClick={() => {
                              setCompanyMemoDialogShow(true);
                            }}
                            buttonType="basic"
                          />
                        )}
                        {/* 通知メール送信対象設定 */}
                        <CaptionButton
                          name=""
                          caption="通知メール送信対象設定"
                          onClick={() => {
                            setNotificationSendTargetSettingDialogShow(true);
                          }}
                          buttonType="basic"
                        />
                      </div>
                      <div className="w-25 right">
                        {/* 依頼通知番号 */}
                        {GetMessage({
                          id: 'surveyRequestNotificationAutoName',
                          prefixId: VIEW_ID,
                        })}{' '}
                        :{surveyRequestAutoName}
                      </div>
                    </div>
                  </div>
                  <div className="input-line label-margin">
                    <div className="item-group-100">
                      <div className="w-25">
                        {/* 回答期日 */}
                        {`${GetMessage({
                          id: 'replyDueDateDt',
                          prefixId: VIEW_ID,
                        })} :
                        ${getDateFormatWithTimezone(item?.replyDueDateDt)}`}
                      </div>
                      <div className="w-50">
                        {isVisibleDeadlineChange && (
                          <CaptionButton
                            className="button-margin"
                            name=""
                            caption="回答期日変更"
                            onClick={() => {
                              if (
                                surveyReplyDueDateChangeRequestData.current
                                  ?.surveyReplyDueDateChangeRequestId
                              ) {
                                error([
                                  GetMessageWithIntl(intl, {
                                    id: 'E0000076',
                                  }),
                                ]);
                                return;
                              }
                              setDeadlineChangeDialogShow(true);
                            }}
                            buttonType="basic"
                          />
                        )}
                        {isVisibleManualReminderSetting && (
                          <CaptionButton
                            name=""
                            caption="手動催促"
                            onClick={() => {
                              setManualReminderDialogShow(true);
                            }}
                            buttonType="basic"
                          />
                        )}
                      </div>
                      <div className="w-25 right">
                        {/* 依頼日 */}
                        {GetMessage({ id: 'sendedAt', prefixId: VIEW_ID })} :
                        {getDateFormat(
                          item?.surveyRequest?.sendedAt ?? '',
                          'YYYY/MM/DD HH:mm'
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="input-line label-margin">
                    <div className="item-group-100">
                      <div className="w-25">
                        {/* 自動催促 */}
                        {GetMessage({
                          id: 'autoReminder',
                          prefixId: VIEW_ID,
                        })}{' '}
                        :{boolDataFormat(autoReminderSetting)}
                      </div>
                      <div className="w-50">
                        {/* 自動催促設定 */}
                        <CaptionButton
                          name=""
                          caption="自動催促設定"
                          onClick={() => {
                            setAutoReminderSettingDialogShow(true);
                          }}
                          buttonType="basic"
                        />
                      </div>
                      <div className="w-25 right">
                        {/* 依頼元 */}
                        {companyName}
                      </div>
                    </div>
                  </div>
                  <div className="input-line label-margin">
                    <div className="item-group-100">
                      <div className="w-100 right">
                        {/* 依頼元1 */}
                        {requesters[0]}
                      </div>
                    </div>
                  </div>
                  <div className="input-line label-margin">
                    <div className="item-group-100">
                      <div className="w-50">
                        {/* TO */}
                        TO : {formatSurveyRequestToLabel()}
                      </div>
                      <div className="w-50 right">
                        {/* 依頼元2 */}
                        {requesters[1]}
                      </div>
                    </div>
                  </div>
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-100 right">
                        {/* 案内文 */}
                        <Textarea
                          name="guidance"
                          labelId=""
                          className="w-100  mh-middle"
                          value={item?.surveyRequest?.guidance ?? ''}
                          columns={preset.columns}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-line label-margin">
                    <div className="item-group-100">
                      <div className="w-100">
                        {/* 依頼管理組織 */}
                        <span className="label">
                          {GetMessage({
                            id: 'responsibleManagementOrganization',
                            prefixId: VIEW_ID,
                          })}
                          ：
                          {convertOrganizationStructureReferenceToFilterboxItem(
                            item?.surveyRequest?.managementOrganization
                          )?.displayName ?? ''}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-100">
                        {/* 依頼担当 */}
                        <span className="label">
                          {GetMessage({
                            id: 'responsibleUsers',
                            prefixId: VIEW_ID,
                          })}
                          ：{formatResponsibleUserToLabel()}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-20">
                        {/* 承認必要 */}
                        <Textbox
                          name="approval"
                          type="text"
                          disabled={true}
                          value={boolDataFormat(
                            item?.surveyRequest?.approval ?? false
                          )}
                          labelId={`${VIEW_ID}.approval`}
                          columns={preset.columns}
                        />
                      </div>
                      <div className="w-20">
                        {/* 完了区分 */}
                        <Textbox
                          name="completeCondition"
                          type="text"
                          disabled={true}
                          value={
                            item?.surveyRequest?.completeCondition
                              ?.displayNameLang?.ja ?? ''
                          }
                          labelId={`${VIEW_ID}.completeCondition`}
                          columns={preset.columns}
                        />
                      </div>
                      <div className="w-20">
                        {/* 部分回答許可 */}
                        <Textbox
                          name="partialReply"
                          type="text"
                          disabled={true}
                          value={boolDataFormat(
                            item?.surveyRequest?.partialReply ?? false
                          )}
                          labelId={`${VIEW_ID}.partialReply`}
                          columns={preset.columns}
                        />
                      </div>
                      <div className="w-20">
                        {/* 辞退許可 */}
                        <Textbox
                          name="decline"
                          type="text"
                          disabled={true}
                          value={boolDataFormat(
                            item?.surveyRequest?.decline ?? false
                          )}
                          labelId={`${VIEW_ID}.decline`}
                          columns={preset.columns}
                        />
                      </div>
                      <div className="w-20">
                        {/* 回答ファイル必須 */}
                        <Textbox
                          name="requiredAttachment"
                          type="text"
                          disabled={true}
                          value={boolDataFormat(
                            item?.surveyRequest?.requiredAttachment ?? false
                          )}
                          labelId={`${VIEW_ID}.requiredAttachment`}
                          columns={preset.columns}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-line label-margin">
                    <div className="item-group-100">
                      <div className="w-33">
                        {/* 添付ファイル（依頼） */}
                        <SimpleListView
                          data={requestAttachmentItems}
                          viewOptions={{
                            readonly: true,
                            previewRowCount: 5,
                            keyColumn: 'id',
                            columns: [
                              {
                                header: {
                                  id: 'attachmentRequest',
                                  prefixId: VIEW_ID,
                                },
                                propertyName: 'filename',
                              },
                            ],
                          }}
                          actionOptions={{
                            onRowClick: (item: AttachmentItems) => {
                              autoDownloadFileOnlyName(
                                item.filename ?? '',
                                item.assetId ?? ''
                              );
                            },
                            onFullDownLoad: () => {
                              autoBulkDownload(
                                (requestAttachmentItems ?? []).map(
                                  (item) => item.assetId ?? ''
                                ),
                                intl,
                                VIEW_ID
                              );
                            },
                          }}
                        />
                      </div>
                      {/* 詳細情報(依頼フォーム) */}
                      {item?.surveyRequest?.requestFormUsable && (
                        <div className="w-66">
                          <div className="area-container">
                            <SimpleListView
                              data={requestForms}
                              viewOptions={{
                                previewRowCount: 10,
                                omitFooter: true,
                                keyColumn: 'id',
                                columns: requestFormListColumns,
                              }}
                              actionOptions={{
                                onRowClick: handleOpenRequestForm,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Accordion>

                {/* ////回答//// */}
                <Accordion
                  title={GetMessageWithIntl(intl, {
                    id: 'answer',
                    viewId: VIEW_ID,
                  })}
                >
                  {/* 回答フォーム */}
                  {item?.surveyRequest?.resultFormUsable && (
                    <div className="area-container">
                      {isVisibleSurveyReplyDelegation && (
                        <div className="input-line">
                          <div className="item-group-100">
                            <div className="w-75">
                              <IconButton
                                name="download"
                                buttonType="basic"
                                iconType="download"
                                onClick={handleDownloadResultWorkFormTemplates}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="input-line">
                        <div className="item-group-100">
                          <div className="w-75">
                            <SimpleListView
                              data={resultForms}
                              viewOptions={{
                                previewRowCount: 10,
                                keyColumn: 'id',
                                columns: resultFormListColumns,
                              }}
                              actionOptions={{
                                onRowClick: handleOpenResultForm,
                              }}
                            />
                          </div>
                          <div className="w-25">
                            <ul className="replyActions">
                              {isVisibleSurveyRequest && (
                                <li>
                                  <CaptionButton
                                    name=""
                                    caption="修正依頼"
                                    onClick={() => {
                                      setSurveyRequestDialogShow(true);
                                    }}
                                    buttonType="basic"
                                  />
                                </li>
                              )}
                              {isVisibleSurveyReplyDelegation && (
                                <>
                                  <li>
                                    <CaptionButton
                                      name=""
                                      caption="回答代行(ファイル)"
                                      onClick={() => {
                                        setSurveyReplyDelegationDialogShow(
                                          true
                                        );
                                      }}
                                      buttonType="basic"
                                    />
                                  </li>
                                  <li>
                                    <CaptionButton
                                      name=""
                                      caption="フォーム回答代行"
                                      onClick={handleFormReplyDelegation}
                                      buttonType="basic"
                                    />
                                  </li>
                                </>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="input-line">
                    <div className="item-group-100">
                      <div className="w-33">
                        {/* 添付ファイル（回答） */}
                        <SimpleListView
                          data={answerAttachmentItems}
                          viewOptions={{
                            previewRowCount: 5,
                            keyColumn: 'id',
                            columns: [
                              {
                                header: {
                                  id: 'attachmentAnswer',
                                  prefixId: VIEW_ID,
                                },
                                propertyName: 'filename',
                              },
                            ],
                          }}
                          actionOptions={{
                            onRowClick: async (item: AttachmentItems) => {
                              autoDownloadFileOnlyName(
                                item.filename ?? '',
                                item.assetId ?? ''
                              );
                              // 代行ファイルの場合はフラグを立てない
                              const isProxyAttachment =
                                proxyAttachmentItems.some(
                                  (attachment) =>
                                    attachment.assetId === item.assetId
                                );
                              if (!isProxyAttachment) {
                                await updateExportedFlag();
                              }
                            },
                            onFullDownLoad: async () => {
                              autoBulkDownload(
                                (answerAttachmentItems ?? []).map(
                                  (item) => item.assetId ?? ''
                                ),
                                intl,
                                VIEW_ID
                              );
                              await updateExportedFlag();
                            },
                          }}
                        />
                      </div>
                      {!item?.surveyRequest?.resultFormUsable && (
                        <div className="w-25">
                          {/* 回答フォームを使用しない場合の修正依頼・代行ボタン */}
                          <ul className="replyActions">
                            {isVisibleSurveyRequest && (
                              <li>
                                <CaptionButton
                                  name=""
                                  caption="修正依頼"
                                  onClick={() => {
                                    setSurveyRequestDialogShow(true);
                                  }}
                                  buttonType="basic"
                                />
                              </li>
                            )}
                            {isVisibleSurveyReplyDelegation && (
                              <li>
                                <CaptionButton
                                  name=""
                                  caption="回答代行(ファイル)"
                                  onClick={() => {
                                    setSurveyReplyDelegationDialogShow(true);
                                  }}
                                  buttonType="basic"
                                />
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                {isVisibleSurveyRequestComplete && (
                  <CaptionButton
                    className="button-margin"
                    name=""
                    caption="依頼終了"
                    onClick={handleSurveyRequestComplete}
                    buttonType="basic"
                  ></CaptionButton>
                )}
              </div>
            </div>
          </div>
        </div>
        <Toast />
      </Container>
      {/* システム通知設定ダイアログ */}
      <SystemNotificationSettingDialog
        isOpen={isSystemNotificationDialogShow}
        inputData={systemNotificationUsers}
        onDecision={(result) => {
          handleSystemNotificationSetting(result);
        }}
        onCancel={() => {
          setSystemNotificationDialogShow(false);
        }}
      />
      <HistoryDialog
        isOpen={isHistoryDialogShow}
        messageOption={{
          headerLabelId: {
            id: 'history',
            prefixId: 'DIALOG_TITLE',
          },
        }}
        inputOption={{
          data: histories ?? [],
          keyColumn: 'history_id',
          columns: [
            {
              propertyName: 'slip_category',
              width: '12rem',
              header: {
                prefixId: 'HistoryDialog',
                id: 'slip_category',
              },
            },
            {
              propertyName: 'occurred_at',
              width: '12rem',
              header: {
                prefixId: 'HistoryDialog',
                id: 'occurred_at',
              },
            },
            {
              propertyName: 'content',
              header: {
                prefixId: 'HistoryDialog',
                id: 'content',
              },
            },
          ],
        }}
        onCancel={() => {
          setHistoryDialogShow(false);
        }}
      />
      <AutoReminderSettingDialog
        isOpen={isAutoReminderSettingDialogShow}
        inputOption={{
          deadline: convertDate(item?.replyDueDateDt || null) ?? undefined,
          displayModeType: isEditableAutoReminderSetting ? 'save' : 'display',
          autoReminderSetting:
            (item?.surveyRequest?.autoReminderSettings ?? []).length > 0
              ? item?.surveyRequest?.autoReminderSettings![0]
              : {},
        }}
        onDecision={(v: AutoReminderSettingDialogOutputOption) => {
          handleAutoReminderSettingSave(v);
          setAutoReminderSettingDialogShow(false);
        }}
        onCancel={() => {
          setAutoReminderSettingDialogShow(false);
        }}
      />
      <DeadlineChangeDialog
        isOpen={isDeadlineChangeDialogShow}
        outputOption={{
          currentDeadline: convertDate(item?.replyDueDateDt || null) || null,
        }}
        messageOption={{
          headerLabelId: {
            id: 'deadline_change',
            prefixId: 'DIALOG_TITLE',
          },
          messageLabelId: {
            id: 'deadline_change',
            prefixId: 'DIALOG_DESCRIPTION',
          },
        }}
        onCancel={() => {
          setDeadlineChangeDialogShow(false);
        }}
        onDecision={(result: DeadlineChangeResult) => {
          handleDeadlineChangeSave(result);
        }}
      />
      {/* (依頼)回答期日変更 */}
      <DeadlineChangeApprovalDialog
        isOpen={isDeadlineChangeApprovalDialogShow}
        outputOption={{
          currentDeadline: convertDate(item?.replyDueDateDt || null) || null,
          requestComment:
            surveyReplyDueDateChangeRequestData.current.comment ?? '',
          requestDeadline:
            convertDate(
              surveyReplyDueDateChangeRequestData.current.replyDueDateDt || null
            ) || new Date(),
        }}
        messageOption={{
          headerLabelId: {
            id: 'deadline_change_approval',
            prefixId: 'DIALOG_TITLE',
          },
          messageLabelId: {
            id: 'deadline_change_approval',
            prefixId: 'DIALOG_DESCRIPTION',
          },
        }}
        onCancel={() => {
          setDeadlineChangeApprovalDialogShow(false);
        }}
        onDecision={handleDeadlineChangeRequestSave}
      />
      {/* 社内メモ */}
      <CommentDialog
        isOpen={isCompanyMemoDialogShow}
        inputOption={{ comment: item?.surveyRequest?.remarks ?? '' }}
        inputStateOption={{ onChangeComment: setCompanyMemo }}
        messageOption={{
          headerLabelId: {
            id: 'company_memo',
            prefixId: 'DIALOG_TITLE',
          },
          captionLabelId: {
            id: 'company_memo',
            prefixId: 'DIALOG_CAPTION',
          },
          decisionLabelId: { id: 'save' },
        }}
        onDecision={() => {
          handleRemarksSave();
          setCompanyMemoDialogShow(false);
        }}
        onCancel={() => {
          setCompanyMemoDialogShow(false);
        }}
      />
      {/* 修正依頼 */}
      <CommentDialog
        isOpen={isSurveyRequestDialogShow}
        inputOption={{ comment: comment, butonType: 'high' }}
        inputStateOption={{ onChangeComment: setComment }}
        messageOption={{
          headerLabelId: {
            id: 'modification_request',
            prefixId: 'DIALOG_TITLE',
          },
          messageLabelId: {
            id: 'modification_request',
            prefixId: 'DIALOG_DESCRIPTION',
          },
        }}
        onDecision={handleSurveyRequest}
        onCancel={() => {
          setSurveyRequestDialogShow(false);
          setComment('');
        }}
      />
      {/* 回答代行 */}
      <SurveyReplyDelegationDialog
        isOpen={isSurveyReplyDelegationDialogShow}
        inputOption={{
          files: proxyAttachmentItems,
        }}
        messageOption={{
          headerLabelId: {
            id: 'survey_reply_delegation',
            prefixId: 'DIALOG_TITLE',
          },
          messageLabelId: {
            id: 'survey_reply_delegation',
            prefixId: 'DIALOG_DESCRIPTION',
          },
        }}
        uploadOption={{
          validateOption: {
            maxFileSizeInMebis: 50,
            maxFileCount: 10,
          },
        }}
        onCancel={() => {
          setSurveyReplyDelegationDialogShow(false);
        }}
        onDecision={async (result) => {
          setLoading(true);
          try {
            const tmpData: AttachmentFile[] = (await createAttachmentFiles(
              result.files,
              'B05'
            )) as unknown as AttachmentFile[];
            const req: mtechnavi.api.survey.ISurveyRequest = {
              surveyRequestId: item?.surveyRequest?.surveyRequestId ?? '',
              proxyAttachments: tmpData,
            };
            await window.App.services.ui.worker.apiCall({
              actionName: 'updateProxyAttachments',
              request: req,
            });
            setProxyAttachmentItems([...tmpData]);

            success([successMessage]);
            setSurveyReplyDelegationDialogShow(false);
          } catch (err) {
            error(getWorkerExceptionMessage(intl, err));
            throw err;
          } finally {
            setLoading(false);
          }
        }}
        onChangeLoadingState={(isLoading) => {
          setLoading(isLoading);
        }}
      />
      <FileUploadDialog
        isOpen={isFileUploadDialogShow}
        messageOption={{
          headerLabelId: { viewId: VIEW_ID, id: 'formReplyDelegateDialog' },
          captionLabelId: { viewId: VIEW_ID, id: 'importCaption' },
          decisionLabelId: { id: 'import' },
        }}
        fileUploadOption={{
          isDnd: false,
          validateOption: {
            allowedFileExtensions: ['xlsx'],
            maxFileCount: 1,
          },
        }}
        onDecision={handleFormReplyDelegateDecision}
        onCancel={() => setFileUploadDialogShow(false)}
      />
      {/* 自社担当 */}
      <OwnStaffDialog
        isOpen={isOwnStaffDialogShow}
        messageOption={{
          headerLabelId: {
            prefixId: 'DIALOG_TITLE',
            id: 'OwnStaff',
          },
        }}
        inputOption={{
          isDisplayButton: isSystemNotificationEditable,
          code: item?.surveyRequest?.requestUnit?.code ?? '',
          displayName: item?.surveyRequest?.requestUnit?.displayName ?? '',
          notificationUsers:
            mainContact?.businessUnitContact?.notificationUsers ?? [],
        }}
        onCancel={() => {
          setOwnStaffDialogShow(false);
        }}
        onNotificationSetting={() => {
          setOwnStaffNotificationSettingDialogShow(true);
        }}
      />
      {/* 自社担当通知設定 */}
      <OwnStaffNotificationSettingDialog
        isOpen={isOwnStaffNotificationSettingDialogShow}
        messageOption={{
          headerLabelId: {
            prefixId: 'DIALOG_TITLE',
            id: 'OwnStaffNotificationSetting',
          },
        }}
        inputOption={{
          notificationUsers:
            mainContact?.businessUnitContact?.notificationUsers?.map(
              (v) => v.user ?? {}
            ) ?? [],
        }}
        onCancel={() => {
          setOwnStaffNotificationSettingDialogShow(false);
        }}
        onDecision={handleOwnStaffSystemNotificationSetting}
      />
      {/* 手動催促設定 */}
      <ManualReminderDialog
        isOpen={isManualReminderDialogShow}
        onDecision={handleManualReminder}
        onCancel={() => {
          setManualReminderDialogShow(false);
        }}
        isClickableState={isClickable}
        onChangeClickableState={setClickable}
      />
      <CommentDialog
        isOpen={isShowDiscard}
        inputOption={{ comment: discardComment, butonType: 'high' }}
        inputStateOption={{ onChangeComment: setDiscardComment }}
        messageOption={{
          headerLabelId: {
            id: 'survey_discard',
            prefixId: 'DIALOG_TITLE',
          },
          messageLabelId: {
            id: 'survey_discard',
            prefixId: 'DIALOG_DESCRIPTION',
          },
        }}
        onDecision={handleDiscard}
        onCancel={() => {
          setShowDiscard(false);
        }}
      />
      {/* フォーム入力ダイアログ */}
      <BuildedInputFormDialog
        messageOption={{
          headerLabelId: {
            prefixId: VIEW_ID,
            id: formInputDialogTitleId,
          },
        }}
        ref={formInputDialogRef}
      />
      {/* 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isOpenConfirmDialog}
        viewMessage={confirmMessage}
        onDecision={handleConfirmed}
        onCancel={handleCancel}
      />
      {/* 通知メール送信対象設定ダイアログ */}
      <NotificationSendTargetSettingDialog
        isOpen={isNotificationSendTargetSettingDialogShow}
        messageOption={{
          headerLabelId: {
            id: 'NOTIFICATION_SEND_TARGET_SETTING',
            prefixId: 'DIALOG_TITLE',
          },
        }}
        onDecision={() => {
          setNotificationSendTargetSettingDialogShow(false);
        }}
        onCancel={() => {
          setNotificationSendTargetSettingDialogShow(false);
        }}
      />
      {isLoading && <LoadingIcon />}
    </>
  );
}
