import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { IconButton } from '../Button/IconButton';
import { GenerallyIconType } from '../../parts/Button/GenerallyIcons';
import './PageNavigation.css';
import { useNavigate } from 'react-router-dom';
import { getDateFormat } from '~/shared/utils';
import { GetMessageWithIntl } from '~/shared/components/parts/Message/Message';
import { sharelib } from '~/shared/libs/clientsdk';
export interface NavigationIconMenu {
  name: GenerallyIconType;
  displayName: string;
  func: () => void;
  disabled?: boolean;
}

export interface PageInfo {
  isDisableBackNavi?: boolean;
  isUnvisibleBackNavi?: boolean;
  isVisibleMoveNavi: boolean;
  isFirstPage?: boolean;
  isMaxPage?: boolean;
  pageNumber?: number;
}

/**
 * 表示用項目
 *  {表示名称}({補足情報}) {日時情報 YYYY/MM/DD HH:mmフォーマット}
 */
interface InfoItem {
  content?: string; // 表示名称
  attribute?: string; // 補足情報
  attributeAt?: Long; // 日時情報
}
export interface IssuerInfo extends InfoItem {
  isVisibleIssuerInfo: boolean;
}

export interface LatestUpdateInfo extends InfoItem {
  isVisibleUpdateInfo: boolean;
}

/**
 * PageNavigationエリアに表示する情報を管理する
 * 非表示にするものも明示的に指定する（isVisibleXXXの指定必須）
 *  現状、利用画面内で表示切り替えをする際の考慮で必須仕様としている
 *  今後、infoOption.xxxInfoのオプショナル型の検討の余地あり
 */
export interface infoOption {
  lastUpdateInfo:
    | LatestUpdateInfo
    | (sharelib.IEmbeddedUpdatedProperties | null | undefined);
  issuerInfo: IssuerInfo;
}
export interface NaviProps {
  backpagePath: string;
  iconItems?: NavigationIconMenu[];
  pageInfo: PageInfo;
  infoOption: infoOption;
  handleBackPage?: () => void;
  handleMovePage?: (n: number) => void;
}

export function PageNavigation(props: NaviProps) {
  const [isFirstPage, setFisrtPage] = useState(true);
  const [isMaxPage, setMaxPage] = useState(false);
  const navi = useNavigate();
  const intl = useIntl();

  const {
    backpagePath,
    iconItems,
    pageInfo,
    handleMovePage,
    infoOption,
    handleBackPage,
  } = props;

  useEffect(() => {
    setFisrtPage(pageInfo.isFirstPage ?? false);
    setMaxPage(pageInfo.isMaxPage ?? false);
  }, [pageInfo.isFirstPage, pageInfo.isMaxPage]);

  /**
   * keyが重複するという警告を避けるため、乱数を生成
   * @returns 生成された乱数値
   */
  const createRandomKey = () => {
    return Math.random().toString(32).substring(2);
  };

  const renderDescription = (item: NavigationIconMenu) => {
    if (!item?.disabled) {
      return (
        <div className="icon-description" onClick={item.func}>
          {item.displayName}
        </div>
      );
    } else {
      return (
        <div className="icon-description disabled">{item.displayName}</div>
      );
    }
  };

  const IconItemList = () => {
    const itemList = iconItems?.map((item, index) => {
      return (
        <div className="navi-icon" key={createRandomKey()}>
          <IconButton
            key={index}
            name=""
            iconType={item.name}
            buttonType="basic"
            onClick={item.func}
            disabled={item?.disabled}
          />
          {renderDescription(item)}
        </div>
      );
    });
    if (iconItems?.length ?? 0 > 0) {
      return <div className="customIconArea">{itemList}</div>;
    }
    return <></>;
  };

  const PageMoveNavigation = (isMoveNavi: boolean) => {
    if (isMoveNavi) {
      return (
        <div className="moveNavi">
          <IconButton
            name=""
            iconType="prev"
            buttonType="basic"
            onClick={() =>
              handleMovePage && handleMovePage((pageInfo.pageNumber ?? 0) - 1)
            }
            disabled={isFirstPage}
          />
          <IconButton
            name=""
            iconType="next"
            buttonType="basic"
            onClick={() => {
              handleMovePage && handleMovePage((pageInfo.pageNumber ?? 0) + 1);
            }}
            disabled={isMaxPage}
          />
        </div>
      );
    }
    return <></>;
  };

  const RenderInfoArea = (
    isVisibleInfo: boolean,
    info: InfoItem,
    className: string,
    labelId: string
  ) => {
    if (isVisibleInfo && info?.attributeAt) {
      return (
        <div className={className}>
          {GetMessageWithIntl(intl, {
            prefixId: 'PageNavigation',
            id: labelId,
          })}
          ：{`${info.content}`}
          {info.attribute ? `(${info.attribute})` : ``}
          {` ${getDateFormat(info?.attributeAt ?? '', 'YYYY/MM/DD HH:mm')}`}
        </div>
      );
    }
    return <></>;
  };

  const RenderbackNavi = (isUnvisibleBackNavi: boolean) => {
    if (!isUnvisibleBackNavi) {
      return (
        <div className="back-button navi-icon">
          <IconButton
            name=""
            iconType="back"
            buttonType="basic"
            onClick={() => {
              if (pageInfo.isDisableBackNavi === true) return;
              handleBackPage ? handleBackPage() : navi(backpagePath);
            }}
            disabled={pageInfo.isDisableBackNavi ?? false}
          />
          <div
            className="icon-description"
            onClick={() => {
              if (pageInfo.isDisableBackNavi === true) return;
              handleBackPage ? handleBackPage() : navi(backpagePath);
            }}
          >
            {GetMessageWithIntl(intl, { id: 'return' })}
          </div>
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className="pageNavigation">
      {RenderbackNavi(pageInfo.isUnvisibleBackNavi ?? false)}
      {IconItemList()}
      {PageMoveNavigation(pageInfo.isVisibleMoveNavi)}
      {RenderInfoArea(
        infoOption.issuerInfo.isVisibleIssuerInfo,
        infoOption.issuerInfo,
        'issuer-info',
        'issuerInfo'
      )}
      {isLatestUpdateInfo(infoOption.lastUpdateInfo) &&
        RenderInfoArea(
          infoOption.lastUpdateInfo.isVisibleUpdateInfo,
          infoOption.lastUpdateInfo,
          'updated-info',
          'latestUpdateInfo'
        )}
      {!isLatestUpdateInfo(infoOption.lastUpdateInfo) &&
        RenderInfoArea(
          !!infoOption.lastUpdateInfo,
          {
            attribute: infoOption.lastUpdateInfo?.updatedBy?.email ?? '',
            attributeAt: infoOption.lastUpdateInfo?.updatedAt ?? undefined,
            content: infoOption.lastUpdateInfo?.updatedBy?.displayName ?? '',
          },
          'updated-info',
          'latestUpdateInfo'
        )}
    </div>
  );
}

const isLatestUpdateInfo = (
  val?: LatestUpdateInfo | sharelib.IEmbeddedUpdatedProperties | null
): val is LatestUpdateInfo => {
  if (!val) {
    return false;
  }
  return Object.hasOwn(val, 'isVisibleUpdateInfo');
};
