import { useIntl } from 'react-intl';
import {
  Checkbox,
  error,
  GetMessageWithIntl,
  MessageProps,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../..';
import { CaptionButton } from '../Button';
import {
  OverlayItem,
  PROT_RATIO_ACCURACY,
  Rect,
  Viewer,
  ViewerOverlayProperties,
} from '../Viewer';
import { useState } from 'react';
import './SegmentEditDialog.css';

export interface MessageOption {
  headerLabel: MessageProps;
}

interface InputOption {
  assetId: string;
  plotInfo?: ViewerOverlayProperties;
}
type SegmentEditDialogResult = ViewerOverlayProperties;

interface SegmentEditDialogProps {
  isOpen: boolean;
  messageOption: MessageOption;
  inputOption: InputOption;
  onDecision: (result: SegmentEditDialogResult) => void;
  onCancel: () => void;
}
export const SegmentEditDialog = ({
  isOpen,
  messageOption,
  inputOption,
  onDecision,
  onCancel,
}: SegmentEditDialogProps) => {
  const intl = useIntl();

  const [isShowFormer, setShowShowFormer] = useState(true);
  const [plotInfo, setPlotInfo] = useState<ViewerOverlayProperties>();

  const handleDecision = async () => {
    if (!plotInfo) {
      error([GetMessageWithIntl(intl, { id: 'E0000023' })]);
      return;
    }
    onDecision({ ...plotInfo });
  };

  const getOverlay = () => {
    const overlays: OverlayItem[] = [];
    if (isShowFormer && inputOption?.plotInfo) {
      overlays.push({
        component: Rect,
        plotInfo: inputOption.plotInfo,
        className: 'former-plot',
        isActive: false,
      });
    }
    if (plotInfo) {
      overlays.push({
        component: Rect,
        plotInfo,
        className: 'current-plot',
        isActive: true,
      });
    }
    return overlays;
  };

  const view = (
    <div className="segment-edit-dialog">
      <div className="dialog-with-description">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Checkbox
                name="showCurrentSegment"
                items={[
                  {
                    displayName: GetMessageWithIntl(intl, {
                      prefixId: 'SegmentEditDialog',
                      id: 'showCurrentSegment',
                    }),
                    value: '1',
                  },
                ]}
                value={isShowFormer ? '1' : undefined}
                disabled={!inputOption?.plotInfo}
                onChangeState={(v) => setShowShowFormer(!!v.length)}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-75">
              <Viewer
                assetId={inputOption.assetId ?? ''}
                fileName={''}
                dragMode="selection"
                controls={{
                  downloading: false,
                  pager: false,
                  scaling: true,
                  cropping: true,
                  croppingOption: {
                    shapeList: {
                      Rectangle: { iconType: 'square', component: Rect },
                    },
                  },
                }}
                onSelectionStart={() => setPlotInfo(undefined)}
                onSelectionEnd={(selection) => {
                  setPlotInfo({
                    x1: selection.x1,
                    y1: selection.y1,
                    x2: correctInside(selection.x2),
                    y2: correctInside(selection.y2),
                  });
                }}
                overlayItems={getOverlay()}
                width="100%"
                height="100%"
              />
            </div>
            <div className="w-25 actions">
              <CaptionButton
                name="sendBtn"
                buttonType="basic"
                className="button"
                caption={GetMessageWithIntl(intl, { id: 'decision' })}
                onClick={handleDecision}
              />
              <CaptionButton
                name="cancelBtn"
                buttonType="cancel"
                className="button"
                caption={GetMessageWithIntl(intl, { id: 'cancel' })}
                onClick={() => onCancel()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: onCancel,
    send: handleDecision,
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabel,
    messageLabelId: {},
    elements: view,
  };

  return (
    <div className="SegmentEditDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};

/**
 * 画像外の相対座標を画像内に補正する。
 * 望ましくないケースもあるため Viewer 外で行う。
 */
const correctInside = (relativePoint: number) => {
  let result = relativePoint;
  if (relativePoint < 0) {
    result = 0;
  }
  if (relativePoint > PROT_RATIO_ACCURACY) {
    result = PROT_RATIO_ACCURACY;
  }
  return result;
};
