import { useMemo, useState } from 'react';
import { Radio, Select } from '~/shared/components';
import {
  Checkbox,
  DataFilterbox,
  Filterbox,
  FilterboxItem,
} from '~/shared/components/ui';
import {
  FullMethodName_ListOrganizations,
  FullMethodName_ListUserAttributes,
} from '~/worker';

const SELECT_BOX = 'セレクトボックス';
const MULTI_SELECT_BOX = '複数選択セレクトボックス';
const CHECK_BOX = 'チェックボックス';
const RADIO_BUTTON = 'ラジオボタン';
const FILTERBOX = 'フィルターボックス';

const selectItems = [
  { value: '1', displayName: '1番目の値' },
  { value: '2', displayName: '2番目の値' },
  { value: '3', displayName: '3番目の値' },
  { value: '4', displayName: '4番目の値' },
  { value: '5', displayName: '5番目の値' },
  { value: '6', displayName: '6番目の値' },
  { value: '7', displayName: '7番目の値' },
];

const checkboxItems = [
  { value: '1', displayName: '1番' },
  { value: '2', displayName: '2番' },
  { value: '3', displayName: '3番' },
  { value: '4', displayName: '4番' },
  { value: '5', displayName: '5番' },
  { value: '6', displayName: '6番' },
  { value: '7', displayName: '7番' },
];

const radioItems = [
  { value: 'yes', displayName: 'はい' },
  { value: 'no', displayName: 'いいえ' },
];

export const UISampleSelectInputs = () => {
  // セレクトボックス
  const [selectBox, setSelectBox] = useState<string[]>([]);
  const [multiSelect, setMultiSelect] = useState<string[]>([]);

  // チェックボックス
  const [checkbox, setCheckbox] = useState<string[]>(['2', '6']);

  // ラジオボタン
  const [radio, setRadio] = useState('');

  // フィルターボックス
  const [singleSB, setSingleSB] = useState<FilterboxItem[]>([]);
  const [userGroupSB, setUserGroupSB] = useState<FilterboxItem[]>([]);
  const [userSB, setUserSB] = useState<FilterboxItem[]>([]);

  // データフィルターボックス
  const [dataFilterBox, setDataFilterBox] = useState<FilterboxItem[]>([
    { displayName: '表示名3', value: '3' },
    { displayName: '表示名7', value: '76' },
  ]);

  // フィルターボックス１で取得した値をuseStateに詰め替え
  const customQuery = useMemo(() => {
    if (!userGroupSB[0]?.value) {
      return;
    }
    return {
      'representativeOrganization.organizationId': {
        $eq: userGroupSB[0]?.value ?? '',
      },
    };
  }, [userGroupSB]);

  return (
    <div className="categories">
      <h3 id="selection-inputs">選択入力</h3>

      <h4>{SELECT_BOX}（単一選択、選択必須）</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Select
              name="select"
              items={selectItems}
              unselectedOption={{ isUnselected: true, value: '未選択' }}
              validateOption={{ required: true }}
              onChangeState={setSelectBox}
            ></Select>
          </div>
          <div className="w-66">
            <pre className="output">{selectBox}</pre>
          </div>
        </div>
      </div>

      <h4>{MULTI_SELECT_BOX}（複数選択可、必須入力、初期値:3、5番目の値）</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Select
              name="select"
              items={selectItems}
              value={['3', '5']}
              validateOption={{ required: true }}
              multiple={true}
              onChangeState={setMultiSelect}
            ></Select>
          </div>
          <div className="w-66">
            <pre className="output">{JSON.stringify(multiSelect, null, 2)}</pre>
          </div>
        </div>
      </div>

      <h4>{CHECK_BOX}（必須入力、初期値:2番、6番）</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Checkbox
              name="checkbox"
              className="group"
              items={checkboxItems}
              validateOption={{ required: true }}
              value={checkbox}
              onChangeState={setCheckbox}
              columns={['checkbox']}
            ></Checkbox>
          </div>
          <div className="w-66">
            <pre className="output">{JSON.stringify(checkbox, null, 2)}</pre>
          </div>
        </div>
      </div>

      <h4>{RADIO_BUTTON}（必須入力、初期値:「はい」）</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Radio
              name="radio"
              className="group"
              items={radioItems}
              value={'yes'}
              validateOption={{ required: true }}
              onChangeState={setRadio}
            ></Radio>
          </div>
          <div className="w-66">
            <pre className="output">{radio}</pre>
          </div>
        </div>
      </div>

      <h4>
        {FILTERBOX}(登録ユーザーを単一選択)
        <br />
        <small>※自動で名称に「02」を含む絞り込みが行われています</small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Filterbox
              name="singleSB"
              fullMethodName={FullMethodName_ListUserAttributes}
              itemType={{
                value: 'userAttributeId',
                displayName: 'user.displayName',
              }}
              searchOption={{ keyword: ['02'], targets: 'both' }}
              columns={['singleSB']}
              displayOption={{
                displayedItems: 'displayName',
                pageSize: 5,
                sort: [
                  {
                    ['displayName']: 'asc',
                  },
                ],
              }}
              onChangeState={setSingleSB}
              // disabled={true}
            ></Filterbox>
          </div>
          <div className="w-66">
            <pre className="output">{JSON.stringify(singleSB, null, 2)}</pre>
          </div>
        </div>
      </div>

      <h4>
        {FILTERBOX}1 (組織を単一選択)
        <br />
        <small>
          ※「フィルターボックス1」「フィルターボックス2」で連携して絞り込みを実施
        </small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Filterbox
              name="userGroupSB"
              fullMethodName={FullMethodName_ListOrganizations}
              itemType={{
                value: 'organizationId',
                displayName: 'displayName',
              }}
              columns={['userGroupSB']}
              displayOption={{
                pageSize: 3,
                sort: [
                  {
                    ['displayName']: 'asc',
                  },
                ],
              }}
              searchOption={{ targets: 'both' }}
              onChangeState={setUserGroupSB}
            ></Filterbox>
          </div>
          <div className="w-66">
            <pre className="output">{JSON.stringify(userGroupSB, null, 2)}</pre>
          </div>
        </div>
      </div>

      <h4>
        {FILTERBOX}2 (ユーザーを複数選択)
        <br />
        <small>
          ※「フィルターボックス1」の組織が代表組織であるユーザーが表示されます
        </small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Filterbox
              name="userSB"
              fullMethodName={FullMethodName_ListUserAttributes}
              itemType={{
                value: 'userAttributeId',
                displayName: 'user.displayName',
              }}
              multiple={true}
              columns={['userSB']}
              displayOption={{
                pageSize: 3,
                sort: [
                  {
                    ['displayName']: 'asc',
                  },
                ],
              }}
              searchOption={{
                targets: 'displayName',
                customQuery: customQuery,
              }}
              onChangeState={setUserSB}
            ></Filterbox>
          </div>
          <div className="w-66">
            <pre className="output">{JSON.stringify(userSB, null, 2)}</pre>
          </div>
        </div>
      </div>

      <h4>任意データフィルターボックス</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <DataFilterbox
              data={[
                { displayName: '表示名1', value: '1' },
                { displayName: '表示名2', value: '12' },
                { displayName: '表示名3', value: '3' },
                { displayName: '表示名4', value: '4' },
                { displayName: '表示名5', value: '5' },
                { displayName: '表示名6', value: '6' },
                { displayName: '表示名7', value: '76' },
                { displayName: '表示名8', value: '8' },
              ]}
              multiple={true}
              searchOption={{ targets: 'displayName' }}
              name="aaa"
              columns={['aaa']}
              onChangeState={setDataFilterBox}
              value={dataFilterBox}
            ></DataFilterbox>
          </div>
          <div className="w-66">
            <pre className="output">
              {JSON.stringify(dataFilterBox, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
