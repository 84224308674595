import React, {
  CSSProperties,
  Fragment,
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { ReactComponent as ArrowDropUpIcon } from '@material-design-icons/svg/filled/arrow_drop_up.svg';
import { ReactComponent as ArrowDropDownIcon } from '@material-design-icons/svg/filled/arrow_drop_down.svg';
import { ReactComponent as MenuIcon } from '@material-design-icons/svg/filled/menu.svg';

import { useAuth } from '~/shared/contexts/AuthProvider';

import sortProfile from './sort.json';

import './base.css';
import type {
  DummyUser,
  SortTerm,
  AggregateStage,
  UnionGroup,
  FullMethodName,
} from '~/worker';
import { usePagenator } from './pagenator';
import { PresetItem, Property as PresetProperty } from '~/shared/services';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import { useIntl } from 'react-intl';
import { Checkbox } from '~/shared/components/parts/Checkbox/Checkbox';
import { GetMessageComponent } from '~/shared/components/parts/Message/Message';
import { CaptionButton, IconButton } from '../Button';
import { defaultFormatter } from './formatter';

import { ImportDialog } from '../Dialog/ImportDialog';
import { error } from '~/shared/components/parts/Toast/Toast';
import { LoadingIcon } from '../LoadingIcon/LoadingIcon';
import {
  FilterView,
  FilterObject,
  FilterViewQuery,
  toFilterExpression,
  toRequestBodyRequest,
  toFilterViewQuery,
} from '../FilterView';
import { getPresetPropertyValue } from '../FilterView/preset';
import {
  getFilterAndSortData,
  getLocalStorageCheckboxData,
  saveLocalStorageCheckboxData,
  saveFilterAndSortData,
  getSortSettingFromLocalStorage,
  ViewId,
  autoDownloadFileWithDate,
  exportGoogleAnalytics,
  getWorkerExceptionMessage,
} from '~/shared/utils';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';

import {
  InitialFilter,
  Schema,
  Preset,
  Property,
  InitialFilterItem,
  ListViewPageSetting,
  IconMenuName,
  ViewMenu,
  MenuActionItem,
  MenuActionType,
  ListViewImportDialogOption,
} from './commonType';
import { createRandomKey, getMenuItemType, isValidMenuValue } from './utils';
import { useTooltip } from '../../parts/Tooltip';
import { ListNavigator } from './ListNavigator';

interface ListSkipType {
  isAllActionMenu?: boolean;
  isListActionMenu?: boolean;
  isHeaderIconMenu?: boolean;
  isListIconMenu?: boolean;
  isFooterMenu?: boolean;
  isFilter?: boolean;
  isCheckbox?: boolean;
  isTotal?: boolean;
  isReload?: boolean;
  isOutput?: boolean;
}

/**
 * アクションに関するオプション
 */
interface ActionOption {
  onHandleDownload?: (arg: string[]) => Promise<string>;
}

/**
 * 呼出元のstate情報を変更したい情報を格納するオプション
 */
interface StateOption {
  onChangeState?: (arg: string[]) => void;
  onOriginalItemState?: (items: DummyUser[]) => void;
  onAllItemState?: (items: DummyUser[]) => void;
}

/**
 * フィルタに関する設定値を格納するオプション
 * 検索条件やソートなどを格納
 */
interface FilterItemOption<P extends FullMethodName = FullMethodName> {
  initialFilterItems?: InitialFilter; // 初期検索時に実行されるフィルタ
  initialRequestBodyFilterItems?: InitialFilter; // 初期検索時に実行されるフィルタ
  naviFilterItems?: InitialFilter; // ページ遷移時に実行されるフィルタ
  aggregateStages?: AggregateStage[]; // join条件
  isNotEqFilter?: boolean;
  uniongroupItems?: UnionGroup<P>[];
  isRequestBodyFilter?: boolean; // 固定フィルタを行うかの制御
}

/**
 * プリセット・スキーマなど画面表示に関係する情報を格納するオプション
 */
interface PageInfo {
  schema: Schema;
  preset: Preset;
  menuItem?: MenuActionItem[];
  menuTarget?: string;
  headerTitle?: MessageProps;
  presetItems?: PresetItem[];
  listSkipType?: ListSkipType;
  reloadDelayTime?: number;
}

export interface ListViewProps {
  fullMethodName: FullMethodName;
  pageInfo: PageInfo;
  maxMenuColumn?: Number;
  isReload?: boolean;
  actionOption?: ActionOption;
  stateOption?: StateOption;
  filterItemOption?: FilterItemOption;
  importDialogOption?: ListViewImportDialogOption;
  selectedItemIds?: string[];
  isUseFilterSave?: boolean;
}
type TotalType = 'selected' | 'all';

const getPresetItem = (
  name: string,
  presetItems: PresetItem[] | undefined
): PresetItem | undefined => {
  return presetItems
    ? presetItems.find((v) => v.name === name)
    : { name: name };
};

export function ListView(props: ListViewProps) {
  return (
    <>
      <ListViewContent {...props} />
    </>
  );
}

function ListViewContent(props: ListViewProps) {
  const {
    fullMethodName,
    pageInfo,
    maxMenuColumn,
    isReload,
    importDialogOption,
    actionOption,
    stateOption,
    filterItemOption,
    selectedItemIds,
    isUseFilterSave,
  } = props;

  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const intl = useIntl();
  const handleError = useErrorHandler();
  const [isLoading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState<FilterViewQuery>({
    filterTerms: {},
  });
  const [headerSelect, setHeaderSelect] = useState<string[]>([]);
  const [isImportSuccess, setImportSuccess] = useState(false); // importが成功したかどうかの判定

  const contentsRef = useRef<HTMLDivElement>(null);
  const [contentsAreaSize, setContentsAreaSize] = useState<string>('');

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  // サイズ計算用
  const listViewRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const naviRef = useRef<HTMLDivElement>(null);
  const filterRef = useRef<HTMLDivElement>(null);

  const contentsAreaStyle: CSSProperties = {
    height: contentsAreaSize,
  };

  // ツールチップ
  const { showTooltip, hideTooltip } = useTooltip();

  const onParentChangeState = (arg: string[]) => {
    stateOption?.onChangeState && stateOption.onChangeState(arg);
  };
  const onParentOriginalItemState = (items: DummyUser[]) => {
    stateOption?.onOriginalItemState && stateOption.onOriginalItemState(items);
  };
  const onParentAllItemState = (items: DummyUser[]) => {
    stateOption?.onAllItemState && stateOption.onAllItemState(items);
  };

  const getQueryFromLocalStorage = (): ListViewPageSetting | null => {
    if (!pageInfo?.headerTitle?.viewId) {
      return null;
    }
    return getFilterAndSortData(pageInfo?.headerTitle.viewId, myEmail);
  };

  const actionMenu = useCallback(
    (target: string): MenuActionItem => {
      const targetMenuItem = (pageInfo?.menuItem ?? []).filter((item) => {
        return item.menuActionType === getMenuItemType(target);
      });
      const initMenu: ViewMenu = {
        name: 'add',
        displayName: '',
        func: () => {},
      };
      if (targetMenuItem.length === 0)
        return { menuActionType: 'allBurgerMenu', menu: [initMenu] };
      const menuItem = targetMenuItem[0];
      const resultMenu: ViewMenu[] = [];
      const actionItem: PresetItem = getPresetItem(
        target,
        pageInfo?.presetItems
      ) ?? {
        name: target,
        property: [],
      };
      if (actionItem.property) {
        const childrenItem: PresetItem = getPresetItem(
          'visibleCondition',
          actionItem.children
        ) ?? {
          name: target,
        };
        actionItem.property.map((v) => {
          const targetMenuItem = menuItem.menu.find((event) => {
            return event.name === v.name;
          });
          if (targetMenuItem) {
            resultMenu.push({
              name: targetMenuItem.name,
              displayName: v.propertyValue,
              content: targetMenuItem.content,
              func: targetMenuItem.func,
              property: childrenItem.property?.filter(
                (item) => item.name === v.name
              ),
              viewMenuOption: targetMenuItem.viewMenuOption,
            });
          }
        });
      }
      return {
        menuActionType: menuItem.menuActionType,
        menu: resultMenu,
        maxMenuColumn: menuItem.maxMenuColumn,
      };
    },
    [pageInfo?.presetItems, pageInfo?.menuItem]
  );

  const [
    properties,
    filterPresetItem,
    allBurgerMenu,
    listBurgermenu,
    allActionMenu,
    listPresetProperty,
    totalColumn,
    numberColumnPreset,
    filterRequestBodyPresetItem,
  ] = useMemo<
    [
      Property[],
      PresetItem | undefined,
      MenuActionItem,
      MenuActionItem,
      MenuActionItem[],
      PresetProperty[],
      string[],
      PresetItem | undefined,
      PresetItem | undefined
    ]
  >(() => {
    const schemaProps: Property[] = pageInfo?.schema.map((prop) => {
      return {
        ...prop,
        formatter: prop.formatter ?? defaultFormatter([prop.name]),
      };
    });
    const listProperties = pageInfo?.preset.propertyNames
      .map((propertyName) => {
        return schemaProps.find((v) => v.name === propertyName);
      })
      .filter((v) => !!v);
    const allBurgerMenu = actionMenu('allMenu');
    const listBurgermenu = actionMenu('listMenu');
    const allActionMenu: MenuActionItem[] = [];
    allActionMenu.push(actionMenu('allMenu'));
    allActionMenu.push(actionMenu('listMenu'));
    allActionMenu.push(actionMenu('iconHeaderMenu'));
    allActionMenu.push(actionMenu('iconListMenu'));
    allActionMenu.push(actionMenu('footerMenu'));
    allActionMenu.push(actionMenu('footerInformation'));

    const totalItemsPreset = getPresetItem('totalItem', pageInfo?.presetItems);
    const listViewItem = getPresetItem('listView', pageInfo?.presetItems);
    const listPresetProperty = listViewItem?.property ?? [];

    // プリセット（初期フィルタ条件）
    const initialFilterPresetItem: PresetItem = getPresetItem(
      'initialRequestBodyFilter',
      getPresetItem('filter', pageInfo?.presetItems)?.children
    ) ?? {
      name: 'initialRequestBodyFilter',
      columns: [],
    };
    return [
      listProperties as Property[],
      pageInfo?.presetItems && getPresetItem('filter', pageInfo?.presetItems),
      allBurgerMenu,
      listBurgermenu,
      allActionMenu,
      listPresetProperty,
      totalItemsPreset?.columns ?? [],
      getPresetItem('numberColumn', listViewItem?.children),
      initialFilterPresetItem,
    ];
  }, [actionMenu, pageInfo?.schema, pageInfo?.preset, pageInfo?.presetItems]);

  const getPresetInitialSortTerms = (): SortTerm[] => {
    const initialSortPresetItem = getPresetItem(
      'initialSort',
      filterPresetItem?.children ?? []
    ) ?? {
      name: 'initialSort',
      columns: [],
    };
    const sortOrder: SortTerm[] = [];
    if (initialSortPresetItem.property) {
      for (const v of initialSortPresetItem.property) {
        sortOrder.push({
          [v.name]: v.propertyValue,
        });
      }
    }
    if (pageInfo?.headerTitle?.viewId) {
      const sortData = getSortSettingFromLocalStorage(
        pageInfo?.headerTitle.viewId,
        myEmail
      );
      // ソートがない場合、初期ソートをセットする
      if (sortData.length === 0) {
        if (sortOrder.length > 0) {
          saveFilterAndSortData(
            pageInfo?.headerTitle.viewId,
            { sort: sortOrder },
            myEmail
          );
        }
      }
    }
    return sortOrder;
  };

  const getSort = (): SortTerm[] => {
    // LocalStorageにソート履歴がある場合は、再利用
    const tmp = getQueryFromLocalStorage();
    if (tmp?.sort && tmp.sort.length > 0) {
      return tmp.sort;
    }
    return getPresetInitialSortTerms();
  };

  const composeInitialFilters = (
    ...initialFilters: InitialFilter[]
  ): FilterViewQuery => {
    const filterObj: FilterObject = {};
    for (const initialFilter of initialFilters) {
      for (const { targetKey, targetValue } of initialFilter.info) {
        filterObj[targetKey] = filterObj[targetKey] || [];
        filterObj[targetKey].push(targetValue);
      }
    }
    return toFilterViewQuery(filterPresetItem, filterObj);
  };

  const composeInitialRequestBodyFilters = (
    ...initialFilters: InitialFilter[]
  ): FilterViewQuery => {
    const filterObj: FilterObject = {};
    for (const initialFilter of initialFilters) {
      for (const { targetKey, targetValue } of initialFilter.info) {
        filterObj[targetKey] = filterObj[targetKey] || [];
        filterObj[targetKey].push(targetValue);
      }
    }
    return toFilterViewQuery(filterPresetItem, filterObj);
  };

  const getPresetInitialFilters = (): InitialFilter[] => {
    // プリセット（初期フィルタ条件）
    const initialFilterPresetItem: PresetItem = getPresetItem(
      'initialFilterItems',
      filterPresetItem?.children
    ) ?? {
      name: 'initialFilterItems',
      columns: [],
    };
    // プリセット内の初期フィルタプロパティから条件を抽出
    const initialFilterMap = (initialFilterPresetItem.property ?? []).reduce(
      (prev, v) => {
        if (!v.propertyName || !v.name) {
          return prev;
        }
        const items = prev.get(v.propertyName) ?? [];
        items.push({
          targetKey: v.name,
          targetValue: v.propertyValue,
        });
        prev.set(v.propertyName, items);
        return prev;
      },
      new Map<string, InitialFilterItem[]>()
    );
    const initialFilters: InitialFilter[] = [];
    initialFilterMap.forEach((v) => {
      initialFilters.push({
        info: v,
      });
    });
    return initialFilters;
  };

  const getInitialRequestBodyFilterColumns = (): string[] =>
    filterRequestBodyPresetItem?.columns ?? [];

  const getPresetInitialRequestBodyFilters = (): InitialFilter[] => {
    // プリセット（初期フィルタ条件）
    const initialFilterPresetItem: PresetItem = getPresetItem(
      'initialFilterItems',
      filterPresetItem?.children
    ) ?? {
      name: 'initialFilterItems',
      columns: [],
    };
    const requestBodyFilterColumns = getInitialRequestBodyFilterColumns();
    // プリセット内の初期フィルタプロパティから条件を抽出
    const initialFilterMap = (initialFilterPresetItem.property ?? []).reduce(
      (prev, v) => {
        if (!v.propertyName || !v.name) {
          return prev;
        }
        // initialRequestBodyFilter に設定されているものだけに絞る
        if (!requestBodyFilterColumns.some((col) => col === v.name)) {
          return prev;
        }
        const items = prev.get(v.propertyName) ?? [];
        items.push({
          targetKey: v.name,
          targetValue: v.propertyValue,
        });
        prev.set(v.propertyName, items);
        return prev;
      },
      new Map<string, InitialFilterItem[]>()
    );
    const initialFilters: InitialFilter[] = [];
    initialFilterMap.forEach((v) => {
      initialFilters.push({
        info: v,
      });
    });
    return initialFilters;
  };

  const getInitialFilter = (): FilterViewQuery => {
    const initialFilters = getPresetInitialFilters();
    if (filterItemOption?.initialFilterItems) {
      initialFilters.push(filterItemOption.initialFilterItems); // ListViewPropsから指定された条件もあればマージ
    }
    // naviFilterから指定された条件をマージ
    if (filterItemOption?.naviFilterItems) {
      const filterColumns = filterPresetItem?.columns || [];
      initialFilters.push({
        info: filterItemOption?.naviFilterItems.info.filter((v) =>
          filterColumns.some((col) => col === v.targetKey)
        ),
      });
    }
    return composeInitialFilters(...initialFilters);
  };

  const getInitialRequestBodyFilter = (): FilterViewQuery => {
    const initialRequestBodyFilters = getPresetInitialRequestBodyFilters();
    if (filterItemOption?.initialRequestBodyFilterItems) {
      initialRequestBodyFilters.push(
        filterItemOption.initialRequestBodyFilterItems
      ); // ListViewPropsから指定された条件もあればマージ
    }
    // naviFilterから指定された条件をマージ
    if (filterItemOption?.naviFilterItems) {
      const requestBodyFilterColumns = getInitialRequestBodyFilterColumns();
      initialRequestBodyFilters.push({
        info: filterItemOption?.naviFilterItems.info.filter((v) =>
          requestBodyFilterColumns.some((col) => col === v.targetKey)
        ),
      });
    }
    return composeInitialRequestBodyFilters(...initialRequestBodyFilters);
  };

  const getFilter = (): FilterViewQuery => {
    // 例外的にPageState経由で検索条件が指定されている場合は、naviFilterItems の条件を最優先に適用する
    if (filterItemOption?.naviFilterItems) {
      return composeInitialFilters(filterItemOption.naviFilterItems);
    }
    // LocalStorageに検索履歴がある場合は、再利用
    const tmp = getQueryFromLocalStorage();
    if (tmp?.filter) return tmp.filter;
    // 初期検索条件を取得
    return getInitialFilter();
  };

  const getRequestBody = (): FilterViewQuery => {
    // LocalStorageに検索履歴がある場合は、再利用
    const tmp = getQueryFromLocalStorage();
    if (tmp?.filter) return tmp.filter;
    // 初期検索条件を取得
    return getInitialRequestBodyFilter();
  };

  // チェック済みのID配列を取得
  const handleChangeCheckedIds = (items: DummyUser[]) => {
    if (!pageInfo?.headerTitle?.viewId) return;
    const lsCheckedValue = getLocalStorageCheckboxData(
      pageInfo?.headerTitle.viewId,
      myEmail
    );
    const changeIds = items.reduce((changeIds, item) => {
      const id = item[pageInfo?.menuTarget as keyof typeof item];
      lsCheckedValue.includes(id) && changeIds.push(id);
      return changeIds;
    }, [] as string[]);
    saveLocalStorageCheckboxData(
      pageInfo?.headerTitle.viewId,
      changeIds,
      myEmail
    );
  };

  // チェック済みのID配列を取得
  const initializeLocalStorageCheckboxData = () => {
    pageInfo?.headerTitle?.viewId &&
      saveLocalStorageCheckboxData(pageInfo?.headerTitle.viewId, [], myEmail);
  };

  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  const [page, dispatch] = usePagenator({
    fullMethodName: fullMethodName,
    pageNumber: 1,
    pageSize: 50,
    maxPageNumber: 1,
    filter: toFilterExpression(
      getFilter(),
      filterPresetItem,
      filterItemOption?.isNotEqFilter,
      pageInfo.schema
    ),
    requestBody: toRequestBodyRequest(
      getRequestBody(),
      filterPresetItem,
      filterRequestBodyPresetItem
    ),
    sort: getSort(),
    aggregate: filterItemOption?.aggregateStages,
    items: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
    uniongroupItems: filterItemOption?.uniongroupItems,
  });

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  useEffect(() => {
    const footerSize = footerRef.current?.clientHeight ?? 0;
    const naviSize = naviRef.current?.clientHeight ?? 0;
    const filterSize = filterRef.current?.clientHeight ?? 0;

    // 基準となるピクセルサイズ
    const basePx = 16;

    // --header-heightを変更する場合はこっちも変更する
    const headerSize = Math.ceil(basePx * 2.6);
    // .Listiew .contents min-heightを変更する場合はこっちも変更する
    const minContentsSize = Math.ceil(basePx * 17.36);

    // フッター、ヘッダー、フィルター、ナビゲーションのエリアサイズ
    const defaultPartsSize =
      footerSize + naviSize + filterSize + headerSize + 20;
    // コンテンツの表示領域計算
    const calcSize = windowDimensions.height - defaultPartsSize;

    // 一覧のヘッダーサイズ
    const listHeaderSize = Math.ceil(3.8 * 16) + 8;

    // 一覧のアイテムサイズ
    const listItemSize = Math.ceil(2.8 * 16);
    const listSize = page.items.length * listItemSize + listHeaderSize + 16;

    // 表示件数が小さい時は最小の高さを設定する
    let contentsSize = 0;
    if (page.items.length < 4) {
      contentsSize = minContentsSize;
    } else {
      // 表示領域内に一覧が収まる場合は一覧のサイズ
      if (listSize < calcSize) {
        contentsSize = listSize;
        // 表示領域内に一覧が収まらない場合は表示可能な領域
      } else {
        contentsSize = calcSize;
      }
    }

    setContentsAreaSize(`${contentsSize}px`);
  }, [windowDimensions.height, filterRef.current?.clientHeight, page.items]);

  const handleReload = (delayTime?: number): FilterViewQuery => {
    const pageState = getFilterAndSortData(
      pageInfo?.headerTitle?.viewId ?? '',
      myEmail
    );
    const pageSize = pageState?.pageSize ?? '50';
    const pageNumber = pageState?.pageNumber ?? '1';
    const filter = getFilter();
    dispatch({
      type: 'reload',
      fullMethodName: fullMethodName,
      pageSize: Number(pageSize),
      pageNumber: Number(pageNumber),
      filter: toFilterExpression(
        filter,
        filterPresetItem,
        false,
        pageInfo.schema
      ),

      requestBody: toRequestBodyRequest(
        getRequestBody(),
        filterPresetItem,
        filterRequestBodyPresetItem
      ),
      sort: getSort(),
      onChangeLoadingState: (v) => {
        setLoading(v);
      },
      aggregate: filterItemOption?.aggregateStages,
      delayTime,
    });
    return filter;
  };

  const onSubmitFilter = (v: FilterViewQuery): void => {
    // 検索時はローカルストレージのチェック状況をクリアする
    initializeLocalStorageCheckboxData();
    const pageState = getFilterAndSortData(
      pageInfo?.headerTitle?.viewId ?? '',
      myEmail
    );
    const pageSize = pageState?.pageSize ?? '50';
    dispatch({
      type: filterItemOption?.isRequestBodyFilter ? 'reload' : 'query',
      fullMethodName: fullMethodName,
      pageNumber: 1,
      pageSize: Number(pageSize),
      sort: getSort(),
      filter: toFilterExpression(
        v,
        filterPresetItem,
        filterItemOption?.isNotEqFilter,
        pageInfo.schema
      ),
      requestBody: toRequestBodyRequest(
        v,
        filterPresetItem,
        filterRequestBodyPresetItem
      ),
      onChangeLoadingState: setLoading,
    });
    // 検索時のみフィルタ条件をLocalStorageに格納
    // const filterViewQuery = toFilterViewQuery(v);
    if (pageInfo?.headerTitle?.viewId) {
      saveFilterAndSortData(
        pageInfo?.headerTitle.viewId,
        {
          filter: v,
          pageSize: pageSize,
          pageNumber: '1',
        },
        myEmail
      );
    }
    setFilterValue(v);
  };

  useEffect(() => {
    if (pageInfo?.headerTitle?.viewId && filterPresetItem) {
      setFilterValue(handleReload());
    }
    // filterPresetItem 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterPresetItem]);

  useEffect(() => {
    if (isReload) {
      // データを再読み込み
      handleReload(pageInfo.reloadDelayTime);
    }
    // isReload 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReload]);

  useEffect(() => {
    if (isImportSuccess) {
      // データを再読み込み
      handleReload(pageInfo.reloadDelayTime);
    }
    // isImportSuccess 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImportSuccess]);

  useEffect(() => {
    onParentOriginalItemState(page.originalItems!);
    // originalItems 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.originalItems]);

  useEffect(() => {
    onParentAllItemState(page.allItems!);
    // allItems 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.allItems]);

  useEffect(() => {
    if (importDialogOption?.isDialogOpen) {
      // 取り込みダイアログオープン時に取り込み成功フラグを初期化
      setImportSuccess(false);
    }
  }, [importDialogOption?.isDialogOpen]);

  // 表示件数変更時の処理
  useEffect(() => {
    // 全て未選択にする
    const tmpSelected: string[] = [];
    [...Array(page.pageSize)].forEach(() => {
      tmpSelected.push('');
    });
    onParentChangeState(tmpSelected);
    // pageSize 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.pageSize]);

  const handleChangePageSize = (value: string) => {
    const pageSize = parseInt(value, 10);
    dispatch({
      type: 'query',
      fullMethodName: fullMethodName,
      pageSize: pageSize,
      pageNumber: 1,
      filter: toFilterExpression(
        getFilter(),
        filterPresetItem,
        false,
        pageInfo.schema
      ),
      requestBody: toRequestBodyRequest(
        getRequestBody(),
        filterPresetItem,
        filterRequestBodyPresetItem
      ),
      onChangeItems(v) {
        handleChangeCheckedIds(v);
      },
    });
    pageInfo?.headerTitle?.viewId &&
      saveFilterAndSortData(
        pageInfo?.headerTitle.viewId,
        { pageSize: value },
        myEmail
      );
  };

  // pagenaterのページ移動時の処理 全て未選択にする
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: fullMethodName,
      pageNumber: n,
    });
    const tmpSelected: string[] = [];
    [...Array(page.pageSize)].forEach(() => {
      tmpSelected.push('');
    });
    onParentChangeState(tmpSelected);
    setHeaderSelect([]);
    if (pageInfo?.headerTitle?.viewId) {
      saveFilterAndSortData(
        pageInfo?.headerTitle.viewId,
        { pageNumber: n.toString() },
        myEmail
      );
      initializeLocalStorageCheckboxData();
    }
  };

  // ソートを除外するか判定する
  const isExcludeSortColumn = (viewId: ViewId, column: string): boolean => {
    // ソート除外情報の読み込み
    const excludeSortList = sortProfile;

    const target = excludeSortList.find((v) => v.viewId === viewId);
    if (target) {
      return target.columns.includes(column);
    } else {
      return false;
    }
  };

  const handleChangeSort = (prop: string) => {
    if (isExcludeSortColumn(pageInfo?.headerTitle?.viewId ?? '', prop)) {
      return;
    }
    const value = page.sort.find((v) => Object.keys(v).includes(prop)) || {
      [prop]: 'asc',
    };
    value[prop] = value[prop] === 'asc' ? 'desc' : 'asc';
    pageInfo?.headerTitle?.viewId &&
      saveFilterAndSortData(
        pageInfo?.headerTitle.viewId,
        { sort: [value] },
        myEmail
      );
    dispatch({
      type: 'query',
      fullMethodName: fullMethodName,
      sort: [value],
      onChangeItems(v) {
        handleChangeCheckedIds(v);
      },
    });
  };

  const selectMenuId = () => {
    return getLocalStorageCheckboxData(
      pageInfo?.headerTitle?.viewId ?? '',
      myEmail
    );
  };

  const propertyValue = (
    listPresetProperty: PresetProperty[],
    columnName: string,
    propertyName: string
  ) => {
    const property: PresetProperty[] = listPresetProperty.filter((v) => {
      return v.propertyName === propertyName && v.name === columnName;
    });

    const value = property[0]?.propertyValue ?? '';
    return value;
  };

  /**
   * FIXME:ダウンロード処理の仕様確定後、対応
   */
  const handleDownload = async () => {
    // ダウンロード処理が設定されている場合は処理を行う、それ以外はスキップ
    if (actionOption?.onHandleDownload) {
      const ids = page.items.map((item) => {
        return item[pageInfo?.menuTarget as keyof typeof item];
      });
      const id = await actionOption.onHandleDownload(ids);
      const fileName = GetMessageWithIntl(intl, {
        prefixId: 'HEADER_TITLE',
        id: pageInfo?.headerTitle?.viewId ?? '',
      });
      autoDownloadFileWithDate(fileName, 'csv', id);
    }
  };

  const totalType: TotalType = 'all';
  const totalValue = (propertyName: string) => {
    let total = 0;
    let isExists = false;
    if (totalType === 'all') {
      (page.allItems ?? []).map((item) => {
        totalColumn.map((column) => {
          if (propertyName === column) {
            const dbValue = item[column as keyof typeof item];
            if (dbValue) {
              total = total + Number(dbValue);
            }
            isExists = true;
          }
        });
      });
    } else {
      (page.items ?? []).map((item) => {
        totalColumn.map((column) => {
          if (propertyName === column) {
            const dbValue = item[column as keyof typeof item];
            isExists = true;
            const lsCheckedValue = getLocalStorageCheckboxData(
              pageInfo?.headerTitle?.viewId ?? '',
              myEmail
            );
            const id = item[pageInfo?.menuTarget as keyof typeof item];
            if (lsCheckedValue.includes(id)) {
              total = total + Number(dbValue);
            }
          }
        });
      });
    }
    return isExists ? total : '';
  };

  const sorterIndicator = (prop: string) => {
    if (isExcludeSortColumn(pageInfo?.headerTitle?.viewId ?? '', prop)) {
      return '';
    }
    const value = page.sort.find((v) => Object.keys(v).includes(prop));
    if (!value) {
      return '';
    }
    return value[prop] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
  };

  const listViewChecker = (type?: string): boolean => {
    if (pageInfo?.listSkipType?.isAllActionMenu) return false;
    switch (type) {
      case 'filter':
        return pageInfo?.listSkipType?.isFilter ? false : true;
      case 'menu':
        return pageInfo?.listSkipType?.isListActionMenu ? false : true;
      case 'check':
        return pageInfo?.listSkipType?.isCheckbox ? false : true;
      case 'total':
        return pageInfo?.listSkipType?.isTotal ? false : true;
      case 'reload':
        return pageInfo?.listSkipType?.isReload ? false : true;
      case 'output':
        return pageInfo?.listSkipType?.isOutput ? false : true;
      case 'listicon':
        return pageInfo?.listSkipType?.isListIconMenu ? false : true;
      default:
        return true;
    }
  };

  const listIconWidth = () => {
    let width = '';
    const menuItems = pageInfo?.menuItem ?? [];
    const menuItem = menuItems.find(
      (item) => item.menuActionType === 'listIconMenu'
    );
    if (menuItem) {
      const icons = menuItem.menu.length * 3;
      width = `${icons}rem `;
    }
    return width;
  };

  const gridLIineWidth = (): string => {
    let num = '';
    num = listViewChecker('menu') ? '4rem ' : '';
    num += listViewChecker('check') ? '2.5rem ' : '';
    num += listViewChecker('listicon') ? listIconWidth() : '';

    properties.map((propertyName) => {
      num +=
        propertyValue(listPresetProperty, propertyName.name, 'width') + ' ';
    });
    return num;
  };

  const listViewStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: gridLIineWidth(),
    alignItems: 'center',
    flex: 1,
  };
  const propertyListViewStyle = (propertyName: string): CSSProperties => {
    const style: CSSProperties = {
      width: getPresetPropertyValue(listPresetProperty, propertyName, 'width'),
    };
    return style;
  };

  const propertyListViewNumberColumnStyle = (
    propertyName: string
  ): CSSProperties => {
    const value = numberColumnPreset?.columns?.find((v) => v === propertyName);
    if (value) {
      return {
        margin: '0 0 0 auto',
      };
    } else {
      return {};
    }
  };

  /**
   * 一覧下部のボタンを描画
   */
  const renderFooterActionButton = (menuItems: MenuActionItem | undefined) => {
    if (menuItems) {
      return menuItems.menu.map((menuItem) => {
        return (
          <div className="button-area" key={createRandomKey()}>
            <CaptionButton
              name={menuItem.name}
              caption={menuItem.displayName}
              buttonType="basic"
              onClick={() => {
                let ids: string[] = [];
                ids = selectMenuId();
                if (!isValidMenuValue(intl, 'footerMenu', menuItem.name, ids)) {
                  return;
                }
                menuItem.func(ids);
              }}
            />
          </div>
        );
      });
    } else {
      return <></>;
    }
  };

  const renderFooterInformation = (menuItems: MenuActionItem | undefined) => {
    if (menuItems) {
      return menuItems.menu.map((menuItem) => {
        if (!menuItem.content) {
          return <></>;
        }
        return <div key={createRandomKey()}>{menuItem.content()}</div>;
      });
    }
  };

  /**
   * 一覧下部のアクションボタンを生成
   */
  const ListFooterButton = () => {
    const menuItems = allActionMenu.find(
      (item) => item.menuActionType === 'footerMenu'
    );
    const menuFooterInformationItem = allActionMenu.find(
      (item) => item.menuActionType === 'footerInformation'
    );

    if (
      (!menuItems || menuItems.menu.length === 0) &&
      (!menuFooterInformationItem ||
        menuFooterInformationItem.menu.length === 0)
    ) {
      return <></>;
    }
    return (
      <div className="icon-list-footer" ref={footerRef}>
        <div className="footer-contents">
          <div className="list-footer-button">
            {renderFooterActionButton(menuItems)}
          </div>
          {menuFooterInformationItem &&
            menuFooterInformationItem.menu.length > 0 && (
              <div className="footer-information">
                {renderFooterInformation(menuFooterInformationItem)}
              </div>
            )}
        </div>
      </div>
    );
  };

  // header部分のチェックボックス処理
  const handleOnChangeHeaderSelect = (headerSelected: string[]) => {
    const tmpSelected: string[] = [];
    [...Array(page.pageSize)].forEach(() => {
      tmpSelected.push(headerSelected[0] === '1' ? '1' : '');
    });
    setHeaderSelect(headerSelected);
    // 呼出元画面に返す
    onParentChangeState(tmpSelected);
    if (headerSelected[0] === '1') {
      const lsCheckedValue = getLocalStorageCheckboxData(
        pageInfo?.headerTitle?.viewId ?? '',
        myEmail
      );

      const checkedValue = page.items
        .filter((item) => {
          const id = item[pageInfo?.menuTarget as keyof typeof item];
          return !lsCheckedValue.includes(id);
        })
        .map((item) => {
          return item[pageInfo?.menuTarget as keyof typeof item];
        });
      lsCheckedValue.push(...checkedValue);
      pageInfo?.headerTitle?.viewId &&
        saveLocalStorageCheckboxData(
          pageInfo?.headerTitle.viewId,
          lsCheckedValue,
          myEmail
        );
    } else {
      initializeLocalStorageCheckboxData();
    }
  };

  // 一覧部分のチェックボックス処理
  const handleOnChangeListCheckbox = (
    headerSelected: string[],
    item?: DummyUser
  ) => {
    if (!item) return;
    const newListSelect: string[] = [];
    const id = item[pageInfo?.menuTarget as keyof typeof item] ?? 'none';
    const checkedValues = getLocalStorageCheckboxData(
      pageInfo?.headerTitle?.viewId ?? '',
      myEmail
    );

    if (headerSelected.length > 0) {
      checkedValues.push(id);
      saveLocalStorageCheckboxData(
        pageInfo?.headerTitle?.viewId ?? '',
        checkedValues,
        myEmail
      );
      checkedValues.map((v) => {
        newListSelect.push(v);
      });
    } else {
      const exclusionIds = checkedValues.filter((cv) => cv !== id);
      saveLocalStorageCheckboxData(
        pageInfo?.headerTitle?.viewId ?? '',
        exclusionIds,
        myEmail
      );
      exclusionIds.map((v) => {
        newListSelect.push(v);
      });
    }
    onParentChangeState(newListSelect);
  };

  // バーガーメニューリスト関係のView
  const burgerMenuView = (item: DummyUser) => {
    if (listBurgermenu.menu.length === 0) {
      return <></>;
    }

    let viewCnt = 0;
    return listBurgermenu.menu.map((v, index) => {
      let isViewMenu = true;
      const properties = v.property ?? [];
      if (properties.length === 0) {
        isViewMenu = true;
      } else {
        const chooseProperty = properties.filter((p) => {
          const targetValue = item[p.propertyName as keyof typeof item];
          return targetValue === p.propertyValue;
        });
        isViewMenu = chooseProperty.length > 0 ? true : false;
      }

      if (isViewMenu) {
        viewCnt++;
      }

      if (!isViewMenu) return <li key={index} className="empty"></li>;
      return (
        <li
          key={index}
          className={`${viewCnt > 1 ? 'menu-item-divide' : 'menu-item'}`}
        >
          <a
            onClick={() => {
              v.func([item[pageInfo?.menuTarget as keyof typeof item]]);
              // GoogleAnalyticsへデータ送信
              exportGoogleAnalytics('burger_menu_item', {
                targetName: `${fullMethodName}_${v.displayName}`,
                documentIds: [item[pageInfo?.menuTarget as keyof typeof item]],
              });
            }}
          >
            {v.displayName}
          </a>
        </li>
      );
    });
  };

  const isSelectedItem = useCallback(
    (item?: DummyUser): boolean => {
      if (!item || !selectedItemIds) {
        return false;
      }

      const recordId = item[pageInfo?.menuTarget as keyof typeof item];
      return selectedItemIds.includes(recordId);
    },
    [pageInfo.menuTarget, selectedItemIds]
  );

  const renderCheckbox = (
    menuType: MenuActionType,
    name: string,
    item?: DummyUser
  ): JSX.Element => {
    let selectValue: string[] = [];
    if (menuType === 'allBurgerMenu') {
      selectValue = headerSelect;
    } else {
      if (!item) return <></>;
      const recordId = item[pageInfo?.menuTarget as keyof typeof item];
      const lsCheckbox = getLocalStorageCheckboxData(
        pageInfo?.headerTitle?.viewId ?? '',
        myEmail
      );
      if (lsCheckbox.includes(recordId)) {
        selectValue.push('1');
      }
    }
    return (
      <Checkbox
        name={name}
        items={[{ value: '1', displayName: '' }]}
        value={selectValue}
        onChangeState={(v) => {
          if (menuType === 'allBurgerMenu') {
            handleOnChangeHeaderSelect(v);
          } else {
            handleOnChangeListCheckbox(v, item);
          }
        }}
        validateOption={{ isSkippedValidation: true }}
      />
    );
  };

  /**
   * メニューのアクション実行時の対象IDを取得
   *
   * @param item 一覧の時のみ設定
   * @returns アクション対象のID
   */
  const getSelectedId = (item?: DummyUser) => {
    let ids: string[] = [];
    // 対象アイテムが存在しているときは対象のアイテムからID
    if (item) {
      ids.push(item[pageInfo?.menuTarget as keyof typeof item]);
    } else {
      ids = selectMenuId();
    }
    return ids;
  };

  /**
   * メニュー用のアイコンボタンを生成
   */
  const renderIconButton = (
    menuItem: ViewMenu,
    className: string,
    item?: DummyUser
  ): JSX.Element => {
    let iconType = menuItem.name as IconMenuName;
    let caption = menuItem.displayName ?? '';

    if (menuItem.viewMenuOption?.iconChangeFn) {
      iconType = menuItem.viewMenuOption.iconChangeFn(item ?? {});
    }

    if (menuItem.viewMenuOption?.captionChangeFn) {
      caption = menuItem.viewMenuOption.captionChangeFn(item ?? {});
    }

    return (
      <div className={className} key={createRandomKey()}>
        <IconButton
          name={menuItem.name}
          buttonType="basic"
          iconType={iconType}
          onClick={() => {
            const ids = getSelectedId(item);
            if (!isValidMenuValue(intl, 'headerIconMenu', menuItem.name, ids)) {
              return;
            }
            menuItem.func(ids);
          }}
          caption={caption}
        />
      </div>
    );
  };

  /**
   * 指定された種類に応じたアイコンメニューを表示
   *
   * @param targetMenuType 対象のメニュータイプ
   * @param item 一覧の時のみ設定
   * @returns
   */
  const renderIconMenu = (
    targetMenuType: MenuActionType,
    item?: DummyUser
  ): JSX.Element[] | JSX.Element => {
    const menuItem = allActionMenu.find(
      (item) => item.menuActionType === targetMenuType
    );
    if (menuItem) {
      const className =
        menuItem.menuActionType === 'headerIconMenu'
          ? 'header-icon'
          : 'list-icon';
      return menuItem.menu.map((v) => {
        return renderIconButton(v, className, item);
      });
    } else {
      return <></>;
    }
  };

  const decisionMenuPositionClass = (
    e: React.MouseEvent,
    eventType: string
  ) => {
    e.preventDefault();
    const targetElement = e.currentTarget;
    const menuList = targetElement.querySelector('.menu-list');
    const contentsElememnt = contentsRef.current as HTMLDivElement;
    const contentsBottom = contentsElememnt.getBoundingClientRect().bottom;
    const burgermenuBottom = menuList?.getBoundingClientRect().bottom;

    switch (eventType) {
      case 'mouseOver':
        // 一覧のスクロール範囲外でバーガーメニューが表示される場合
        if (burgermenuBottom && contentsBottom < burgermenuBottom) {
          document.body.classList.add('noscroll');
          contentsElememnt.classList.add('over-menu-contents');
          menuList?.setAttribute(
            'style',
            [
              `bottom:0`,
              `max-height:${
                Number(maxMenuColumn ?? '4') * targetElement.clientHeight
              }px`,
            ].join(';')
          );
        }
        break;
      case 'mouseOut':
        document.body.classList.remove('noscroll');
        contentsElememnt.classList.remove('over-menu-contents');
        menuList?.setAttribute(
          'style',
          [
            `max-height:${
              Number(maxMenuColumn ?? '4') * targetElement.clientHeight
            }px`,
          ].join(';')
        );
        break;
    }
  };

  return (
    <>
      <div className="ListView" ref={listViewRef}>
        {listViewChecker('filter') && (
          <div ref={filterRef}>
            <FilterView
              viewId={pageInfo?.headerTitle?.viewId}
              filterValue={filterValue}
              filterPresetItem={filterPresetItem}
              onSubmitFilter={onSubmitFilter}
              isRequestBodyFilter={
                filterItemOption?.isRequestBodyFilter ?? false
              }
              initialFilter={getInitialFilter()}
              initialRequestBodyFilter={getInitialRequestBodyFilter()}
              isUseFilterSave={isUseFilterSave}
            />
          </div>
        )}
        <div className="list-header" ref={naviRef}>
          <ListNavigator
            navigatorPosition="header"
            renderIconMenu={(e) => renderIconMenu(e)}
            renderFooterButton={ListFooterButton}
            handleMovePage={(e) => handleMovePage(e)}
            currentPage={page.pageNumber}
            maxPage={page.maxPageNumber}
            onReload={listViewChecker('reload') ? handleReload : undefined}
            handleDownload={
              listViewChecker('output') ? handleDownload : undefined
            }
            items={page.items}
            selectBoxProps={{
              pageSize: page.pageSize,
              setPageSize: (e) => handleChangePageSize(e.toString()),
            }}
            itemSize={(page.allItems ?? []).length}
          />
        </div>
        <div ref={contentsRef} className="contents" style={contentsAreaStyle}>
          <div className="list-caption">
            <div style={listViewStyle}>
              {listViewChecker('menu') && (
                <div className="list-header-cell burgermenu">
                  <ul className="list">
                    <li className="menu-box">
                      <a href="#">
                        <MenuIcon />
                      </a>
                      <ul
                        className="menu-list"
                        style={{
                          maxHeight: `${Number(maxMenuColumn ?? '4') * 50}px`,
                        }}
                      >
                        {allBurgerMenu &&
                          allBurgerMenu.menu.map((v, index) => {
                            return (
                              <li key={index} className="menu-item">
                                <a
                                  onClick={() => {
                                    const selectMenuIds = selectMenuId();
                                    if (
                                      !isValidMenuValue(
                                        intl,
                                        allBurgerMenu.menuActionType,
                                        v.name,
                                        selectMenuIds
                                      )
                                    ) {
                                      return;
                                    }
                                    v.func(selectMenuIds);
                                  }}
                                >
                                  {[v.displayName]}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                  </ul>
                </div>
              )}
              {listViewChecker('check') && (
                <div className="list-header-cell check">
                  {renderCheckbox('allBurgerMenu', 'all')}
                </div>
              )}
              {listViewChecker('listicon') && (
                <div
                  style={{ width: listIconWidth() }}
                  className="list-header-cell"
                >
                  &nbsp;
                </div>
              )}
              {properties.map((property) => (
                <div
                  className="list-header-cell"
                  key={property.name}
                  onClick={() => handleChangeSort(property.name)}
                >
                  <GetMessageComponent
                    prefixId={pageInfo?.headerTitle?.prefixId ?? ''}
                    viewId={pageInfo?.headerTitle?.viewId ?? ''}
                    id={property.name}
                  />
                  {sorterIndicator(property.name)}
                </div>
              ))}
            </div>
          </div>
          <div className="list-content">
            <div>
              {page.items.map((item, i) => (
                <Fragment key={i}>
                  <div
                    className={`listcolor ${i % 2 === 0 ? 'even' : 'odd'}  ${
                      isSelectedItem(item) ? ' selectedItem' : ''
                    } `}
                    style={listViewStyle}
                  >
                    {listViewChecker('menu') && (
                      <div
                        className={`burgermenu item${
                          i % 2 === 0 ? ' even' : ' odd'
                        }`}
                      >
                        <ul className="list">
                          <li
                            className="menu-box"
                            onMouseOver={(e: React.MouseEvent) => {
                              decisionMenuPositionClass(e, 'mouseOver');
                            }}
                            onMouseOut={(e: React.MouseEvent) => {
                              decisionMenuPositionClass(e, 'mouseOut');
                            }}
                          >
                            <a href="#">
                              <MenuIcon className="menuIcon" />
                            </a>
                            <ul className="menu-list">
                              {burgerMenuView(item)}
                            </ul>
                          </li>
                        </ul>
                      </div>
                    )}
                    {listViewChecker('check') && (
                      <div
                        className={`item check${
                          i % 2 === 0 ? ' even' : ' odd'
                        }`}
                      >
                        {renderCheckbox('listBurgerMenu', 'list_' + i, item)}
                      </div>
                    )}
                    {listViewChecker('listicon') && (
                      <div
                        className={`item ${i % 2 === 0 ? ' even' : ' odd'}`}
                        key={`icon-${i}`}
                      >
                        {renderIconMenu('listIconMenu', item)}
                      </div>
                    )}
                    {properties.map((property) => {
                      return (
                        <div
                          key={property.name}
                          className={`item${i % 2 === 0 ? ' even' : ' odd'}`}
                          onMouseEnter={(e) =>
                            showTooltip(
                              property.formatter!(item),
                              e.currentTarget
                            )
                          }
                          onMouseLeave={hideTooltip}
                        >
                          <div
                            className="viewText"
                            style={propertyListViewNumberColumnStyle(
                              property.name
                            )}
                          >
                            {property.formatter!(item)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Fragment>
              ))}
              {/* total item */}
              {listViewChecker('total') && (
                <>
                  <div className="item total"></div>
                  <div className="item total">合計</div>
                </>
              )}
              {listViewChecker('total') &&
                properties.map((property) => (
                  <div
                    key={property.name}
                    className="item total"
                    style={propertyListViewStyle(property.name)}
                  >
                    {totalValue(property.name)}
                  </div>
                ))}
              {listViewChecker('total') &&
                [...Array(page.pageSize - page.items.length)].map((_, i) => (
                  <Fragment key={`pad-${i}`}>
                    {properties.map((property) => (
                      <div key={property.name}>&nbsp;</div>
                    ))}
                  </Fragment>
                ))}
            </div>
          </div>
        </div>
        <ListFooterButton />
      </div>
      {importDialogOption?.isDisplay && (
        <ImportDialog
          isOpen={importDialogOption.isDialogOpen}
          headerLabelId={importDialogOption!.headerLabelId}
          allIds={
            page.allItems
              ?.map((v) => v[pageInfo?.menuTarget as keyof typeof v])
              .filter((v) => !!v) ?? []
          }
          ids={selectMenuId()}
          preset={importDialogOption!.dialogPreset}
          isFileTypeSelectBox={importDialogOption!.isFileTypeSelectBox}
          userMasterCategoryName={importDialogOption!.userMasterCategoryName}
          handleExport={importDialogOption!.handleExport}
          handleImport={importDialogOption!.handleImport}
          onChangeState={(v) => {
            importDialogOption.onChangeState(v);
          }}
          onHandleSuccess={(v) => {
            setImportSuccess(v);
          }}
          onChangeLoadingState={setLoading}
        ></ImportDialog>
      )}
      {isLoading && <LoadingIcon />}
    </>
  );
}
