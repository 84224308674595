import { IntlShape } from 'react-intl';
import type {
  AppWorker,
  FilterExpression,
  FilterRequest,
  SortTerm,
} from '~/worker';
import * as Comlink from 'comlink';
import { Catalog, mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import preset from '../preset/preset.config.json';
import { GetMessageWithIntl } from '../components';
import {
  FullMethodName_GetCompany,
  FullMethodName_ListPresets,
  FullMethodName_ListUserAttributes,
} from '../utils';
import {
  FullMethodName_ListOrganizationRelations,
  FullMethodName_ListOrganizations,
  FullMethodName_ListUserSettings,
} from '~/worker';
import { UserSettingDataType } from 'worker/saveworker/uicontroller/saveUserSetting';

export interface Property {
  name: string;
  propertyName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propertyValue: any;
}

export interface PresetItem {
  name: string;
  type?: string;
  columns?: string[];
  property?: Property[];
  children?: PresetItem[];
}
export interface ButtonItem {
  label: string;
  is_visibillity?: boolean;
  is_disabled?: boolean;
}
export interface WorkerRequest {}

export type FileFormatName =
  | 'transactionUnit'
  | 'componentUnit'
  | 'businessUnit'
  | 'businessUnitManagement'
  | 'businessUnitManagementItemValue'
  | 'oldBusinessUnitManagement'
  | 'staff'
  | 'blueprint'
  | 'assetUnit'
  | 'estimateRequestPlanUnit'
  | 'order'
  | 'userAttribute'
  | 'userBelongsUserGroup'
  | 'userGroupAttachedRoles'
  | 'userGroupAllowedMenuItem'
  | 'masterProgramOpiton'
  | 'surveyRequest'
  | 'workTask'
  | 'estimateRequestPlans'
  | 'rankRequest'
  | 'rankReception'
  | 'organizationRelations'
  | 'organizationRelationsProcurement'
  | 'organizationRelationsDefault'
  | 'businessUnitContacts'
  | 'businessUnitFinancials'
  | 'businessUnitFacilitiess'
  | 'businessUnitCertificates'
  | 'businessUnitStrengths'
  | 'businessUnitSkills';

export class UiService {
  // 実際は Comlink.Remote<AppWorker> だが、
  // AppWorker のメソッドの型が不完全になってしまうため AppWorker そのものとして扱う。
  // https://github.com/GoogleChromeLabs/comlink?tab=readme-ov-file#typescript
  worker: AppWorker;

  constructor(worker: Worker, lang: string) {
    this.worker = Comlink.wrap<AppWorker>(worker) as unknown as AppWorker;
    this.worker.setLang(lang);
  }

  /**
   * カテゴリーで名称マスタを取得
   * @param {string} categoryName カテゴリー。
   */
  getProgramOption(
    categoryName: string
  ): mtechnavi.api.programoption.IProgramOption[] {
    return window.App.programoptions
      .filter((v) => {
        return v.categoryName === categoryName;
      })
      .sort((n1, n2) => {
        const n1Order = n1.order ?? 0;
        const n2Order = n2.order ?? 0;
        if (n1Order > n2Order) {
          return 1;
        }
        if (n1Order < n2Order) {
          return -1;
        }
        return 0;
      });
  }

  /**
   * programOptionIdで名称マスタを取得
   * @param {string} id programOption
   */
  getProgramOptionWithId(id: string) {
    const value = window.App.programoptions.find(
      (v) => v.programOptionId === id
    );
    if (!value) {
      return {};
    }

    return {
      categoryName: value.category,
      code: value.code,
      displayNameLang: value.displayNameLang,
      systemName: value.systemName,
    } as sharelib.INameOption;
  }

  /**
   * カテゴリーとコードで名称マスタを取得
   * @param {string} categoryName カテゴリーコード
   * @param {string} code ユーザーコード
   */
  getProgramOptionWithCode(
    categoryName: string,
    code: string
  ): mtechnavi.api.programoption.IProgramOption {
    return (
      window.App.programoptions.find((v) => {
        return v.categoryName === categoryName && v.code === code;
      }) ?? {}
    );
  }

  /**
   * カテゴリーでINameOptionを取得
   * @param {string} categoryName カテゴリーコード
   */
  getNameOption(categoryName: string): sharelib.INameOption[] {
    const opts = this.getProgramOption(categoryName);
    return opts.map((v) => this.toNameOption(v));
  }

  /**
   * カテゴリーとcodeでINameOptionを取得
   * @param {string} categoryName カテゴリーコード
   * @param {string} code ユーザーコード
   */
  getNameOptionWithCode(
    categoryName: string,
    code: string
  ): sharelib.INameOption {
    const opts = this.getProgramOptionWithCode(categoryName, code);

    return this.toNameOption(opts);
  }

  /**
   * カテゴリーとシステムコードで名称マスタを取得
   * @param {string} categoryName カテゴリーコード
   * @param {string} sysytemName システムコード
   */
  getProgramOptionWithSystemName(
    categoryName: string,
    systemName: string
  ): mtechnavi.api.programoption.IProgramOption[] {
    return window.App.programoptions
      .filter((v) => {
        return v.categoryName === categoryName && v.systemName === systemName;
      })
      .sort((n1, n2) => {
        const n1Order = n1.order ?? 0;
        const n2Order = n2.order ?? 0;
        if (n1Order > n2Order) {
          return 1;
        }
        if (n1Order < n2Order) {
          return -1;
        }
        return 0;
      });
  }

  /**
   * カテゴリーとシステムコードでINameOptionを取得
   * @param {string} categoryName カテゴリーコード
   * @param {string} sysytemName システムコード
   */
  getNameOptionWithSystemName(
    categoryName: string,
    systemName: string
  ): sharelib.INameOption[] {
    const opts = this.getProgramOptionWithSystemName(categoryName, systemName);
    return opts.map((v) => this.toNameOption(v));
  }

  private toNameOption(
    programOption: mtechnavi.api.programoption.IProgramOption
  ): sharelib.INameOption {
    return <sharelib.INameOption>{
      categoryName: programOption.categoryName,
      code: programOption.code,
      displayNameLang: programOption.displayNameLang,
      systemName: programOption.systemName,
    };
  }

  async getUserRoles(
    userGroupIds: string[] | null | undefined
  ): Promise<[string[], string[]]> {
    const userGroups = await window.App.services.identity.listUserGroups({});
    const roles = await window.App.services.identity.listRoles({});
    const userRoleName: string[] = [];
    const permissions: string[] = [];
    (userGroupIds ?? []).map((value) => {
      const userGroup = userGroups.items.filter((item) => {
        return item.userGroupId === value;
      });

      roles.items.map((rl) => {
        userGroup.map((ug) => {
          if ((ug.roleNames ?? []).findIndex((nm) => nm === rl.roleName) >= 0) {
            userRoleName.push(rl.roleName ?? '');
            (rl.permissions ?? []).map((permission) => {
              if (permission !== '*/*' && permission) {
                const method = Catalog.lookupMethod(permission);
                if (method) permissions.push(method.name);
                // 管理者は全権限
              } else {
                const availableFullMethodNames = Catalog.services()
                  .flatMap((v) => v.methodsArray)
                  // 先頭1文字は "." がつくので、削除
                  .map((v) => v.fullName.substr(1))
                  // サービス名とメソッド名は "/" で区切る
                  .map((v) => v.replace(/\.(\w+)$/, '/$1'));

                availableFullMethodNames.map((fullMethodName) => {
                  const method = Catalog.lookupMethod(fullMethodName);
                  if (method) permissions.push(method.name);
                });
              }
            });
          }
        });
      });
    });
    return [userRoleName, permissions];
  }

  async getTargetPreset(target: string): Promise<PresetItem> {
    const allPresetItem = await this.getPresetItems();
    for (const preset of allPresetItem) {
      if (preset.name === target) {
        return preset;
      } else {
        if (preset.children) {
          for (const childrenPreset of preset.children) {
            if (target === childrenPreset.name) {
              return childrenPreset;
            }
          }
        }
      }
    }
    return { name: '' };
  }

  getTargetPresetItem(
    target: string,
    allPreset: PresetItem[]
  ): PresetItem | undefined {
    for (const preset of allPreset) {
      if (preset.name === target) {
        return preset;
      } else {
        if (preset.children) {
          for (const childrenPreset of preset.children) {
            if (target === childrenPreset.name) {
              return childrenPreset;
            }
          }
        }
      }
    }
    return;
  }

  async getPresetItems(): Promise<PresetItem[]> {
    return await preset;
  }

  // viewIDを指定してプリセットを取得
  /*
     presetの取得経路
     listPresets -> items.<viewId>.internalData
  */
  async getViewIdPreset(viewId: string) {
    const presets = window.App.presets.filter((v) => v.viewId === viewId);

    // viewIDに属するpresetをMongoDB経由で取得
    const viewIdPreset: mtechnavi.api.uicontroller.Preset =
      presets.pop() as mtechnavi.api.uicontroller.Preset;
    const presetItem = viewIdPreset.internalData as unknown as PresetItem;

    return { presetItem, viewIdPreset };
  }

  // 各fileformatの取得
  async getFileFormat(name: FileFormatName, intl: IntlShape) {
    let result;
    switch (name) {
      case 'transactionUnit':
        result =
          await window.App.services.transactionUnitService.listTransactionUnitFormats(
            {}
          );
        break;

      case 'componentUnit':
        result =
          await window.App.services.componentunitService.listComponentUnitFormats(
            {}
          );
        break;

      case 'businessUnit':
        result =
          await window.App.services.businessunitService.listBusinessUnitFormats(
            {}
          );
        break;

      case 'businessUnitManagement':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitManagementFormats(
            {}
          );
        break;

      case 'businessUnitManagementItemValue':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitManagementItemValueFormats(
            {}
          );
        break;

      case 'oldBusinessUnitManagement':
        result =
          await window.App.services.businessUnitManagementService.listOldBusinessUnitManagementFormats(
            {}
          );
        break;

      case 'staff':
        result = await window.App.services.staffService.listStaffFormats({});
        break;

      case 'blueprint':
        result =
          await window.App.services.blueprintService.listBlueprintFormats({});
        break;

      // case 'assetUnit':
      //   result =
      //     await window.App.services.assetInventory.listBlueprintPropertiesFormats(
      //       {}
      //     );
      //   break;

      case 'estimateRequestPlanUnit':
        result =
          await window.App.services.estimateSenderService.listEstimateRequestPlanFormats(
            {}
          );
        break;

      case 'order':
        result =
          await window.App.services.estimateSenderService.listOrderFormats({});
        break;

      case 'userAttribute':
        result =
          await window.App.services.tenantAdminService.listUserAttributeFormats(
            {}
          );
        break;

      case 'userBelongsUserGroup':
        result =
          await window.App.services.tenantAdminService.listUserBelongsUserGroupFormats(
            {}
          );
        break;

      case 'userGroupAttachedRoles':
        result =
          await window.App.services.tenantAdminService.listUserGroupAttachedRolesFormats(
            {}
          );
        break;

      case 'userGroupAllowedMenuItem':
        result =
          await window.App.services.tenantAdminService.listUserGroupAllowedMenuItemFormats(
            {}
          );
        break;

      case 'workTask':
        result = await window.App.services.workTaskService.listWorkTaskFormats(
          {}
        );
        break;

      case 'masterProgramOpiton':
        result =
          await window.App.services.programOptionService.listProgramOptionFormats(
            {}
          );
        break;

      case 'surveyRequest':
        result =
          await window.App.services.surveySenderService.listSurveyRequestFormats(
            {}
          );
        break;
      case 'estimateRequestPlans':
        result =
          await window.App.services.estimateSenderService.listEstimateRequestPlanFormats(
            {}
          );
        break;
      case 'rankRequest':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitRankRequestFormats(
            {}
          );
        break;
      case 'rankReception':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitRankReceptionFormats(
            {}
          );
        break;
      case 'businessUnitContacts':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitContactFormats(
            {}
          );
        break;
      case 'businessUnitFacilitiess':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitFacilitiesFormats(
            {}
          );
        break;
      case 'businessUnitCertificates':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitCertificateFormats(
            {}
          );
        break;
      case 'businessUnitFinancials':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitFinancialFormats(
            {}
          );
        break;
      case 'businessUnitSkills':
        result =
          await window.App.services.businessUnitManagementService.listBusinessUnitSkillFormats(
            {}
          );
        break;
      case 'organizationRelationsDefault':
        result =
          await window.App.services.organizationService.listOrganizationRelationFormats(
            {}
          );
        result = {
          ...result,
          items: result.items.filter(
            (item) => item.format?.systemName === 'B01'
          ),
        };
        break;
      case 'organizationRelationsProcurement':
        result =
          await window.App.services.organizationService.listOrganizationRelationFormats(
            {}
          );
        result = {
          ...result,
          items: result.items.filter(
            (item) => item.format?.systemName === 'B02'
          ),
        };
        break;
      default:
        result = { items: [] };
        break;
    }
    const items = result.items.pop() ?? {};
    (items.headerColumns ?? []).map((v) => {
      if (v.messageName) {
        v.displayName = GetMessageWithIntl(intl, { id: v.messageName });
      }
    });
    return items;
  }

  /**
   * ユーザの所属組織を取得
   * @param userId
   * @returns
   */
  async getUserOrganization(userId: string) {
    if (!userId) {
      return [];
    }
    const organizationRelations = (await window.App.services.ui.worker.filter({
      action: 'query',
      fullMethodName: FullMethodName_ListOrganizationRelations,
      filter: {
        userId: { $eq: userId },
      },
      sort: [],
    })) as mtechnavi.api.company.IListOrganizationRelationsResponse;
    return organizationRelations.items ?? [];
  }

  /**
   * ログインユーザの代表所属組織を取得
   * @param email
   * @returns 組織リファレンス
   */
  async getUserRepresentativeOrganization(email: string | null | undefined) {
    if (!email) {
      return null;
    }
    const userAttribute = (await window.App.services.ui.worker.filter({
      action: 'query',
      fullMethodName: FullMethodName_ListUserAttributes,
      filter: {
        'user.email': { $eq: email },
      },
      sort: [],
    })) as mtechnavi.api.tenantadmin.ListUserAttributesResponse;

    return userAttribute.items.length && userAttribute.items.length > 0
      ? userAttribute.items[0].representativeOrganization
      : null;
  }

  /**
   * ログインユーザの代表承認組織を取得
   * @param email
   * @returns 組織リファレンス
   */
  async getUserApprovalOrganization(email: string | null | undefined) {
    if (!email) {
      return null;
    }
    const userAttribute = (await window.App.services.ui.worker.filter({
      action: 'query',
      fullMethodName: FullMethodName_ListUserAttributes,
      filter: {
        'user.email': { $eq: email },
      },
      sort: [],
    })) as mtechnavi.api.tenantadmin.ListUserAttributesResponse;

    return userAttribute.items.length && userAttribute.items.length > 0
      ? userAttribute.items[0].approvalOrganization
      : null;
  }

  /**
   * 組織リストを取得
   * @returns mtechnavi.api.company.IOrganization[]
   */
  async listOrganization(isAll?: boolean) {
    const filerExpression: FilterExpression = isAll
      ? {}
      : {
          usable: { $eq: true },
        };
    const organizations = (await window.App.services.ui.worker.filter({
      action: 'query',
      fullMethodName: FullMethodName_ListOrganizations,
      filter: filerExpression,
      sort: [{ code: 'asc' }],
    })) as mtechnavi.api.company.ListOrganizationsResponse;

    return organizations.items ?? [];
  }
  /**
   * 組織所属情報を取得
   * @returns mtechnavi.api.company.IOrganization[]
   */
  async listOrganizationRelation() {
    const filerExpression: FilterExpression = {};
    const organizationRelations = (await window.App.services.ui.worker.filter({
      action: 'query',
      fullMethodName: FullMethodName_ListOrganizationRelations,
      filter: filerExpression,
      sort: [],
    })) as mtechnavi.api.company.ListOrganizationRelationsResponse;

    return organizationRelations.items ?? [];
  }

  // ユーザー情報を取得する
  async listUserAttributes(
    filterExpression: FilterExpression = {},
    sortItem: SortTerm[] = []
  ) {
    const userAttributes = (await window.App.services.ui.worker.filter({
      action: 'query',
      fullMethodName: FullMethodName_ListUserAttributes,
      filter: filterExpression,
      sort: sortItem,
    })) as mtechnavi.api.tenantadmin.ListUserAttributesResponse;

    return userAttributes.items ?? [];
  }

  /**
   * ログイン者の企業マスタを取得
   */
  async getMyCompany() {
    return (
      await window.App.services.ui.worker.filter({
        action: 'reload',
        fullMethodName: FullMethodName_GetCompany,
        filter: {},
        sort: [],
      })
    ).items.at(0) as mtechnavi.api.company.ICompany;
  }

  /** ユーザー設定データを取得 */
  async getUserSetting(type: UserSettingDataType, key?: string) {
    return window.App.services.ui.worker.filter<mtechnavi.api.uicontroller.IUserSetting>(
      {
        action: 'reload',
        fullMethodName: FullMethodName_ListUserSettings,
        filter: {
          type: { $eq: (key ? [key, type] : [type]).join('.') },
        },
        sort: [{ displayName: 'asc' }],
      }
    );
  }
}
