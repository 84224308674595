import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { useConfirmation } from '~/shared/hooks';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { getWorkerExceptionMessage, ViewId } from '~/shared/utils';
import { FilterViewQuery } from '~/worker';
import { DataFilterbox, DataFilterboxItem } from '../Filterbox';
import { GetMessageWithIntl } from '../../parts/Message/Message';
import { error } from '../../parts/Toast/Toast';
import { IconButton } from '../../parts/Button/IconButton';
import { TextInputDialog } from '../Dialog/TextInputDialog';
import './FilterSave.css';

const isFilterViewQuery = (v: unknown): v is FilterViewQuery => {
  if (!v || typeof v !== 'object') {
    return false;
  }
  return (
    Object.hasOwn(v, 'filterTerms') &&
    !!(v as Partial<FilterViewQuery>)?.filterTerms
  );
};

const MAX_FILTER_SAVE = 5;
export interface FilterSaveProps {
  viewId?: ViewId;
  filterValue: FilterViewQuery;
  onSubmitFilter?: (v: FilterViewQuery) => void;
}
export const FilterSave = ({
  viewId,
  filterValue,
  onSubmitFilter,
}: FilterSaveProps) => {
  const intl = useIntl();
  const { showLoading, hideLoading } = useLoading();
  const { confirmation, confirmationElement } = useConfirmation();

  const [isShowTextInputDialog, setShowTextInputDialog] = useState(false);
  const [userSettingFilterValues, setUserSettingFilterValues] = useState<
    mtechnavi.api.uicontroller.IUserSetting[]
  >([]);
  const [selectedUserSetting, setSelectedUserSetting] =
    useState<mtechnavi.api.uicontroller.IUserSetting | null>(null);

  const userSettingForView: DataFilterboxItem[] = userSettingFilterValues.map(
    (v) => ({
      value: v.userSettingId ?? '',
      displayName: v.displayName ?? '',
    })
  );
  const selectedUserSettingForView = userSettingForView.filter(
    (item) => item.value === selectedUserSetting?.userSettingId
  );

  const getUserSettingFilterValues = async (viewId?: ViewId) => {
    const resp = await window.App.services.ui.getUserSetting('filter', viewId);
    setUserSettingFilterValues(resp.items);
  };

  useEffect(() => {
    (async () => {
      await getUserSettingFilterValues(viewId);
    })();
  }, [viewId]);

  /** フィルタ条件の保存 */
  const handleSaveDialogOpen = () => {
    if (userSettingFilterValues.length >= MAX_FILTER_SAVE) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000183',
          value: { $1: MAX_FILTER_SAVE },
        }),
      ]);
      return;
    }
    setShowTextInputDialog(true);
  };

  /** フィルタ条件の保存(確定) */
  const handleSaveFilterValue = async (displayName: string) => {
    const isDuplicatedName =
      userSettingFilterValues.findIndex(
        (v) => v.displayName === displayName
      ) !== -1;
    if (isDuplicatedName) {
      error([GetMessageWithIntl(intl, { id: 'E0000184' })]);
      return;
    }
    showLoading();
    try {
      // 保存する
      await window.App.services.ui.worker.apiCall({
        actionName: 'saveUserSetting',
        request: {
          displayName,
          type: 'filter',
          viewId: viewId ?? '',
          recordData: filterValue,
        },
      });
      // 保存後の再取得
      await getUserSettingFilterValues(viewId);
      setShowTextInputDialog(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      return;
    } finally {
      hideLoading();
    }
  };

  /** フィルタ保存条件の反映 */
  const handleChangeUserSetting = async (selections: DataFilterboxItem[]) => {
    if (selections.length <= 0) {
      setSelectedUserSetting(null);
      return;
    }
    const selectedId = selections.at(0)?.value;
    const selectedValue = userSettingFilterValues.find(
      (v) => v.userSettingId === selectedId
    );

    if (!selectedValue) {
      error([GetMessageWithIntl(intl, { id: 'E0000177' })]);
      setSelectedUserSetting(null);
      return;
    }

    setSelectedUserSetting(selectedValue);
    try {
      const filter = JSON.parse(selectedValue.recordData ?? '');
      if (!isFilterViewQuery(filter)) {
        error([GetMessageWithIntl(intl, { id: 'E0000177' })]);
        return;
      }
      onSubmitFilter && onSubmitFilter(filter);
    } catch (err) {
      if (err instanceof SyntaxError) {
        error([GetMessageWithIntl(intl, { id: 'E0000177' })]);
        return;
      }
      error(getWorkerExceptionMessage(intl, err));
    }
  };

  /** フィルタ保存条件の削除 */
  const handleDeleteFilterValue = async () => {
    if (!selectedUserSetting) {
      return;
    }
    const message = GetMessageWithIntl(intl, { id: 'savedFilterValueDelete' });
    if (!(await confirmation({ id: 'C0000001', value: { $1: message } }))) {
      return;
    }
    showLoading();
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteUserSetting',
        request: {
          userSettingId: selectedUserSetting.userSettingId,
          updatedAt: selectedUserSetting.updatedAt,
        },
      });

      // 削除後の再取得
      await getUserSettingFilterValues(viewId);
      setSelectedUserSetting(null);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      hideLoading();
    }
  };

  return (
    <div className="FilterSave">
      <DataFilterbox
        className="user-setting-selector"
        name="userSettings"
        labelId="savedFilterValue"
        columns={['userSettings']}
        data={userSettingForView}
        value={selectedUserSettingForView}
        onChangeState={handleChangeUserSetting}
      />
      <IconButton
        iconType="delete"
        buttonType="basic"
        name="load"
        disabled={!selectedUserSetting}
        onClick={handleDeleteFilterValue}
      />
      <IconButton
        iconType="add"
        buttonType="basic"
        name="save"
        onClick={handleSaveDialogOpen}
      />
      <TextInputDialog
        key={isShowTextInputDialog ? 1 : 0}
        isOpen={isShowTextInputDialog}
        inputOption={{ validateOption: { required: true, maxLength: 20 } }}
        messageOption={{
          headerLabel: {
            prefixId: 'DIALOG_TITLE',
            id: 'filterValueSaveDialog',
          },
          messageLabel: {
            prefixId: 'filterValueSaveDialog',
            id: 'message',
          },
          captionLabel: {
            prefixId: 'filterValueSaveDialog',
            id: 'displayName',
          },
          decisionLabel: { id: 'save' },
        }}
        onDecision={handleSaveFilterValue}
        onCancel={() => setShowTextInputDialog(false)}
      />
      <>{confirmationElement}</>
    </div>
  );
};
