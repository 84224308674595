/** テナント向けデバッグメニュー */
export const TenantDebugMenu = [
  {
    label: 'マスタ1',
    children: [
      {
        label: '公開企業一覧',
        pathname: '/businessunit-public-companies-list',
        viewId: 'PUBLIC_COMPANY_LIST',
      },
      {
        label: '企業登録',
        pathname: '/company-input',
        viewId: 'MASTER_COMPANY_INPUT',
      },
      {
        label: '管理場所一覧',
        pathname: '/company-component-unit-list',
        viewId: 'MASTER_COMPONENT_UNIT_LIST',
      },
      {
        label: '名称マスタ一覧',
        pathname: '/master-programoption-list',
        viewId: 'MASTER_PROGRAMOPTION_LIST',
      },
      {
        label: '拡張版名称マスタ一覧',
        pathname: '/extend-master-programoption-list',
        viewId: 'EXTEND_MASTER_PROGRAMOPTION_LIST',
      },
      {
        label: '自社企業確認',
        pathname: '/opc-businessunit-profile-confirmation',
        viewId: 'OCP_BUSINESS_UNIT_PROFILE_CONFIRMATION',
      },
    ],
  },
  {
    label: 'マスタ2',
    children: [
      {
        label: '品目一覧',
        pathname: '/master-transaction-unit-list',
        viewId: 'MASTER_TRANSACTION_UNIT_LIST',
      },
      {
        label: '担当者一覧',
        pathname: '/company-staff-list',
        viewId: 'MASTER_STAFF_LIST',
      },
    ],
  },
  {
    label: 'マスタ3',
    children: [
      {
        label: 'アセット一覧',
        pathname: '/assets-unit-list',
        viewId: 'ASSET_ASSET_LIST',
      },
      {
        label: '取引先一覧',
        pathname: '/businessunitmanagement-list',
        viewId: 'BUM_BUSINESS_UNIT_MANAGEMENT_LIST',
      },
      {
        label: '取引先公開資料一覧',
        pathname: '/businessunit-public-documents-list',
        viewId: 'BUM_BUSINESS_UNIT_PUBLIC_DOCUMENTS_LIST',
      },
      {
        label: '組織ツリー',
        pathname: '/ocp-organization-tree-confirmation',
      },
      {
        label: '属性情報一覧',
        pathname: '/master-attribute-list',
        viewId: 'MASTER_ATTRIBUTE_LIST',
      },
    ],
  },
  {
    label: 'テナント管理',
    children: [
      {
        label: 'テナント設定',
        pathname: '/admin-tenant-setting-confirmation',
        viewId: 'ADMIN_TENANT_SETTING_CONFIRMATION',
      },
    ],
  },
  {
    label: '見積管理',
    children: [
      {
        label: '見積依頼予定一覧',
        pathname: '/estimate/est-estimate-request-plan-list',
        viewId: 'EST_ESTIMATE_REQUEST_PLAN_LIST',
      },
      {
        label: '見積依頼一覧',
        pathname: '/estimate/est-estimate-request-list',
        viewId: 'EST_ESTIMATE_REQUEST_LIST',
      },
      {
        label: '見積依頼詳細一覧',
        pathname: '/estimate/est-estimate-request-detail-list',
        viewId: 'EST_ESTIMATE_REQUEST_DETAIL_LIST',
      },
      {
        label: '未承認見積依頼一覧',
        pathname: '/estimate/est-estimate-request-approval-list',
        viewId: 'EST_ESTIMATE_REQUEST_APPROVAL_LIST',
      },
      {
        label: '未承認見積選考通知一覧',
        pathname: '/estimate/est-estimate-selection-approval-list',
        viewId: 'EST_ESTIMATE_SELECTION_APPROVAL_LIST',
      },
      {
        label: '見積回答一覧',
        pathname: '/estimate/est-estimate-result-list',
        viewId: 'EST_ESTIMATE_RESULT_LIST',
      },
    ],
  },
  {
    label: 'Survey',
    children: [
      {
        label: '依頼テンプレート一覧',
        pathname: '/supplier-base/survey-request-catalog-list',
        viewId: 'SURVEY_REQUEST_CATALOG_LIST',
      },
      {
        label: '依頼管理一覧',
        pathname: '/supplier-base/survey-request-list',
        viewId: 'SURVEY_REQUEST_SENDER_LIST',
      },
      {
        label: '依頼一覧（受信）',
        pathname: '/supplier-base/survey-request-receiver-list',
        viewId: 'SURVEY_REQUEST_RECEIVER_LIST',
      },
      {
        label: '未承認依頼一覧',
        pathname: '/supplier-base/survey-request-approval-list',
        viewId: 'SURVEY_REQUEST_SENDER_APPROVAL_LIST',
      },
    ],
  },
  {
    label: '指図案件',
    children: [
      {
        label: '指図案件テンプレート一覧',
        pathname: '/work-task/wt-request-catalog-list',
        viewId: 'WT_REQUEST_CATALOG_LIST',
      },
      {
        label: '指図案件一覧',
        pathname: '/work-task/wk-worktask-list',
        viewId: 'WT_WORKTASK_LIST',
      },
      {
        label: '未承認指図案件一覧',
        pathname: '/work-task/wk-worktask-approval-list',
        viewId: 'WT_WORKTASK_APPROVAL_LIST',
      },
      {
        label: '指図案件登録',
        pathname: '/work-task/wt-worktask-input',
        viewId: 'WT_WORKTASK_INPUT',
      },
    ],
  },
  {
    label: 'フォーム管理',
    children: [
      {
        label: 'フォーム設定一覧',
        pathname: '/form/ifm-form-definition-list',
        viewId: 'IFM_FORM_DEFINITION_LIST',
      },
      {
        label: '[動作確認]フォーム設定一覧',
        pathname: '/form/ifm-sample-form-definition-list',
        viewId: 'IFM_SAMPLE_FORM_DEFINITION_LIST',
      },
    ],
  },
  {
    label: 'BCP',
    children: [
      {
        label: '災害外部情報一覧',
        pathname: '/bcp/bcp-disaster-list',
        viewId: 'BCP_DISASTER_LIST',
      },
      {
        label: '災害調査一覧',
        pathname: '/bcp/bcp-disaster-survey-list',
        viewId: 'BCP_DISASTER_SURVEY_LIST',
      },
    ],
  },
  {
    label: '図面管理',
    children: [
      {
        label: '図面一覧(新)',
        pathname: '/blueprint/blueprint-list',
        viewId: 'BLP_BLUEPRINT_LIST',
      },
      {
        label: '図面確認(モック用)',
        pathname: '/blueprint/blueprint-confirmation',
        viewId: 'BLP_BLUEPRINT_CONFIRMATION',
      },
      {
        label: '図面確認(モック用・更新番号指定)',
        pathname: '/blueprint/blueprint-confirmation/id1/rev1',
        viewId: 'BLP_BLUEPRINT_CONFIRMATION',
      },
      {
        label: '図面検索',
        pathname: '/blueprint/blueprint-search',
        viewId: 'BPR_BLUEPRINT_SEARCH',
      },
    ],
  },
  {
    label: 'Debug',
    children: [
      {
        label: '図面管理調査サンプル',
        pathname: '/debug/blueprint/experimental',
      },
      {
        label: '組織ツリー',
        pathname: '/debug/org-tree',
      },
      {
        label: '企業検索',
        pathname: '/company/search',
      },
      {
        label: '技術情報サンプル',
        pathname: '/debug/categorized-checks',
      },
      {
        label: 'ファイル機能',
        pathname: '/debug/file',
      },
      {
        label: '図面コメントサンプル',
        pathname: '/debug/comment-viewer-sample',
      },
      {
        label: 'UI サンプル',
        pathname: '/debug/ui-sample',
        search: { test: 'sample' }, // クエリパラメータの挙動チェック用
      },
      {
        label: 'ダイアログ サンプル',
        pathname: '/debug/dialog-sample',
      },
      {
        label: '一覧',
        pathname: '/debug/list',
      },
      {
        label: 'プリセット（一覧）',
        pathname: '/debug/preset/list',
      },
      {
        label: 'プリセット（入力）',
        pathname: '/debug/preset/input',
      },
      {
        label: 'ダイアログ',
        pathname: '/debug/dialog',
      },
      {
        label: '取引窓口検索',
        pathname: '/debug/master-post-unit-search',
      },
      {
        label: '品目検索',
        pathname: '/debug/transaction-unit-search-list',
      },
      {
        label: '図面検索',
        pathname: '/debug/blueprint-blueprint-search-list',
      },
      {
        label: 'フォーム登録（JSON）',
        pathname: '/debug/form-input-to-json',
      },
    ],
  },
  {
    label: 'テナント管理',
    icon: 'control',
    children: [
      {
        label: 'アカウント一覧',
        pathname: '/admin-account-list',
      },
      {
        label: 'ユーザー管理',
        pathname: '/user-admin',
      },
      {
        label: 'ユーザーグループ一覧',
        pathname: '/admin-user-group-list',
      },

      {
        label: 'ユーザーグループ管理',
        pathname: '/user-group-admin',
      },
    ],
  },
  {
    label: 'ユーザー設定',
    pathname: '/user-setting',
  },
  {
    label: 'テナント設定',
    pathname: '/tenante/settings',
  },
  // debug及びダミーAPI用と実際のAPI情報との区切り表示
  {
    label: '--------',
  },
];
