import { FileItem } from '~/shared/components/file';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { convertLongToNumber, createAttachmentAsset } from '~/shared/utils';
import {
  FullMethodName_ExistsBlueprintNumber,
  FullMethodName_GetBlueprintContent,
  FullMethodName_ListBlueprintAttributes,
  FullMethodName_ListBlueprintRevisions,
  FullMethodName_ListUserDefinedDatas,
  FullMethodName_ListUserDefinedViews,
  FilterExpression,
  FullMethodName_SearchBlueprints,
} from '~/worker';
import {
  BLUEPRINT_SEARCH_LIMIT,
  BlueprintSearchCondition,
  BlueprintSearchFilter,
  BlueprintSearchSort,
  conditionsToRequest,
  keywordToRegExp,
} from '../pages/BlueprintBlueprintSearchResult/util';
import Long from 'long';

/** 図番の重複チェック */
export const existsBlueprintNumber = async (blueprintNumber: string) => {
  const result =
    await window.App.services.ui.worker.filter<mtechnavi.api.blueprint.IExistsBlueprintNumberRequestResponse>(
      {
        action: 'reload',
        fullMethodName: FullMethodName_ExistsBlueprintNumber,
        filter: {},
        requestBody: { blueprintNumber },
        sort: [],
      }
    );
  return !!result.items.at(0)?.exists;
};

/** 図面コンテンツの取得 */
export const getBlueprintContent = async (
  blueprintId: string,
  revision?: string
) => {
  const result =
    await window.App.services.ui.worker.filter<mtechnavi.api.blueprint.IBlueprintContent>(
      {
        action: 'reload',
        fullMethodName: FullMethodName_GetBlueprintContent,
        filter: {},
        requestBody: { blueprintId, revision },
        sort: [],
      }
    );
  const sortedAttributes = (result.items.at(0)?.blueprintAttributes ?? []).sort(
    (a, b) => (a.order?.low ?? 0) - (b.order?.low ?? 0)
  );
  const blueprintContent = {
    ...result.items.at(0),
    blueprintAttributes: sortedAttributes,
  };

  return blueprintContent;
};

/** 図面改訂データ一覧の取得 */
export const getBlueprintRevisionList = async (blueprintId: string) => {
  const result =
    await window.App.services.ui.worker.filter<mtechnavi.api.blueprint.IBlueprintRevision>(
      {
        action: 'reload',
        fullMethodName: FullMethodName_ListBlueprintRevisions,
        filter: {},
        requestBody: { blueprintId },
        sort: [{ revision: 'desc' }],
      }
    );
  return result.items;
};

/** 図面抽出属性一覧の取得 */
export const getBlueprintAttributesList = async (
  blueprintRevisionId: string
) => {
  const result =
    await window.App.services.ui.worker.filter<mtechnavi.api.blueprint.IBlueprintAttribute>(
      {
        action: 'reload',
        fullMethodName: FullMethodName_ListBlueprintAttributes,
        filter: {},
        requestBody: { blueprintRevisionId },
        sort: [{ order: 'asc' }],
      }
    );
  return result.items;
};

/** 図面ファイル単体のアップロード */
export const uploadBlueprintFile = async (file: FileItem) => {
  const assetRes = await createAttachmentAsset({ files: [file] }, 'B01');
  const fileResult = assetRes.at(0);
  return Array.isArray(fileResult) ? fileResult.at(0) : fileResult;
};

/** 図面ファイル複数のアップロード */
export const uploadBlueprintFiles = async (files: FileItem[]) => {
  const assetRes = await createAttachmentAsset({ files }, 'B01');
  return assetRes.flatMap((resp) => resp);
};

/** ユーザー定義ビューのリスト取得 */
export const getUserDefinedViewList = async () => {
  const result =
    await window.App.services.ui.worker.filter<mtechnavi.api.blueprint.IListUserDefinedViewsResponse>(
      {
        action: 'reload',
        fullMethodName: FullMethodName_ListUserDefinedViews,
        filter: {},
        sort: [{ displayName: 'asc' }],
      }
    );
  return result.items.at(0);
};

/** ユーザー定義ビューデータのリスト取得 */
export const getUserDefinedViewData = async (viewName: string) => {
  const result =
    await window.App.services.ui.worker.filter<mtechnavi.api.blueprint.IListUserDefinedDatasResponse>(
      {
        action: 'reload',
        fullMethodName: FullMethodName_ListUserDefinedDatas,
        filter: {},
        requestBody: { viewName },
        sort: [],
      }
    );
  return result.items.at(0);
};

export interface BlueprintSearchParam {
  condition?: BlueprintSearchCondition;
  filter?: BlueprintSearchFilter;
  sort?: BlueprintSearchSort;
}
export const searchBlueprint = async (param: BlueprintSearchParam) => {
  const { condition, filter: filterParam, sort } = param;
  // FE保持用の型からリクエストの型を生成する
  const requestBody = conditionsToRequest(condition ?? {});
  if (!requestBody.limit) {
    requestBody.limit = Long.fromNumber(BLUEPRINT_SEARCH_LIMIT);
  }

  const keywordRegExp = keywordToRegExp(filterParam?.keyword);
  let filter: FilterExpression = {};
  if (keywordRegExp) {
    filter = {
      $or: [
        { blueprintNumber: { $regex: keywordRegExp } },
        { 'attributes.name': { $regex: keywordRegExp } },
        { 'attributes.value': { $regex: keywordRegExp } },
      ],
    };
  }

  const result =
    await window.App.services.ui.worker.filter<mtechnavi.api.blueprint.ISearchBlueprintContent>(
      {
        // 結果画面での追加の条件をフィルタで扱う。
        // その場合は一度取得している結果に対してフィルタする。
        action: filterParam || sort ? 'query' : 'reload',
        fullMethodName: FullMethodName_SearchBlueprints,
        filter,
        requestBody,
        sort: sort ?? [],
      }
    );
  return result.items;
};
