import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  FullMethodName_ListBusinessUnitBranchs,
  FullMethodName_ListBusinessUnitChangeNotifications,
  FullMethodName_ListBusinessUnitContactContents,
  FullMethodName_ListBusinessUnitDocuments,
  FullMethodName_SharedListBusinessUnitPublicDocuments,
  FullMethodName_ListBusinessUnitRankReceptionContents,
  FullMethodName_ListBusinessUnitRankRequestContents,
  FullMethodName_ListLocalBusinessUnitCertificates,
  FullMethodName_ListLocalBusinessUnitContactContents,
  FullMethodName_ListLocalBusinessUnitFacilitiess,
  FullMethodName_ListLocalBusinessUnitFinancials,
  FullMethodName_ListLocalBusinessUnitRankRequestContents,
  FullMethodName_ListLocalBusinessUnitSkills,
  FullMethodName_ListLocalBusinessUnitStrengths,
  FullMethodName_SharedListBusinessUnitBranchs,
  FullMethodName_SharedListBusinessUnitCertificates,
  FullMethodName_SharedListBusinessUnitFacilitiess,
  FullMethodName_SharedListBusinessUnitFinancials,
  FullMethodName_SharedListBusinessUnitSkills,
  FullMethodName_SharedListBusinessUnitStrengths,
} from '~/worker';

// [データ取得] 取引先管理（連絡先）コンテンツ
export const listBusinessUnitContactContents = async (
  businessUnitManagementIds: string[],
  isLinkedTenant: boolean
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: isLinkedTenant
      ? FullMethodName_ListBusinessUnitContactContents
      : FullMethodName_ListLocalBusinessUnitContactContents,
    filter: {},
    requestBody: isLinkedTenant
      ? { businessUnitManagementIds: businessUnitManagementIds }
      : { businessUnitManagementId: businessUnitManagementIds.at(0) },
    sort: [], // ソートは画面項目セット時に行うため不要
  })) as
    | mtechnavi.api.company.IListBusinessUnitContactContentsResponse
    | mtechnavi.api.company.IListLocalBusinessUnitContactContentsResponse;
};

// [データ取得] 自社企業（拠点）マスタ
export const listBusinessUnitBranchs = async (companyIds: string[]) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_SharedListBusinessUnitBranchs,
    filter: {},
    requestBody: {
      companyIds: companyIds,
    },
    sort: [], // ソートは画面項目セット時に行うため不要
  })) as mtechnavi.api.company.ISharedListBusinessUnitBranchsResponse;
};

// [データ取得]自社企業（決算）マスタ
export const listBusinessUnitFinancials = async (
  companyIds: string[],
  businessUnitManagementId: string,
  isLinkedTenant: boolean
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: isLinkedTenant
      ? FullMethodName_SharedListBusinessUnitFinancials
      : FullMethodName_ListLocalBusinessUnitFinancials,
    filter: {},
    requestBody: isLinkedTenant
      ? { companyIds: companyIds }
      : { businessUnitManagementId: businessUnitManagementId },
    sort: [], // ソートは画面項目セット時に行うため不要
  })) as
    | mtechnavi.api.company.ISharedListBusinessUnitFinancialsResponse
    | mtechnavi.api.company.IListLocalBusinessUnitFinancialsResponse;
};

// [データ取得] 自社企業（設備）マスタ
export const listBusinessUnitFacilitiess = async (
  companyIds: string[],
  businessUnitManagementId: string,
  isLinkedTenant: boolean
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: isLinkedTenant
      ? FullMethodName_SharedListBusinessUnitFacilitiess
      : FullMethodName_ListLocalBusinessUnitFacilitiess,
    filter: {},
    requestBody: isLinkedTenant
      ? { companyIds: companyIds }
      : { businessUnitManagementId: businessUnitManagementId },
    sort: [], // ソートは画面項目セット時に行うため不要
  })) as
    | mtechnavi.api.company.ISharedListBusinessUnitFacilitiessResponse
    | mtechnavi.api.company.IListLocalBusinessUnitFacilitiessResponse;
};

// [データ取得] 自社企業（認証）マスタ
export const listBusinessUnitCertificates = async (
  companyIds: string[],
  businessUnitManagementId: string,
  isLinkedTenant: boolean
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: isLinkedTenant
      ? FullMethodName_SharedListBusinessUnitCertificates
      : FullMethodName_ListLocalBusinessUnitCertificates,
    filter: {},
    requestBody: isLinkedTenant
      ? { companyIds: companyIds }
      : { businessUnitManagementId: businessUnitManagementId },
    sort: [], // ソートは画面項目セット時に行うため不要
  })) as
    | mtechnavi.api.company.ISharedListBusinessUnitCertificatesResponse
    | mtechnavi.api.company.IListLocalBusinessUnitCertificatesResponse;
};

// [データ取得] 自社企業（得意分野）マスタ
export const listBusinessUnitStrengths = async (
  companyIds: string[],
  businessUnitManagementId: string,
  isLinkedTenant: boolean
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: isLinkedTenant
      ? FullMethodName_SharedListBusinessUnitStrengths
      : FullMethodName_ListLocalBusinessUnitStrengths,
    filter: {},
    requestBody: isLinkedTenant
      ? { companyIds: companyIds }
      : { businessUnitManagementId: businessUnitManagementId },
    sort: [], // ソートは画面項目セット時に行うため不要
  })) as
    | mtechnavi.api.company.ISharedListBusinessUnitStrengthsResponse
    | mtechnavi.api.company.IListLocalBusinessUnitStrengthsResponse;
};

// [データ取得] 取引先管理（文書）マスタ
export const listBusinessUnitDocuments = async (
  businessUnitManagementIds: string[]
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListBusinessUnitDocuments,
    filter: {},
    requestBody: { businessUnitManagementIds: businessUnitManagementIds },
    sort: [], // ソートは画面項目セット時に行うため不要
  })) as mtechnavi.api.company.IListBusinessUnitDocumentsResponse;
};

// [データ取得] 自社企業（技術情報）マスタ
export const listBusinessUnitSkills = async (
  companyIds: string[],
  businessUnitManagementId: string,
  isLinkedTenant: boolean
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: isLinkedTenant
      ? FullMethodName_SharedListBusinessUnitSkills
      : FullMethodName_ListLocalBusinessUnitSkills,
    filter: {},
    requestBody: isLinkedTenant
      ? { companyIds: companyIds }
      : { businessUnitManagementId: businessUnitManagementId },
    sort: [], // ソートは画面項目セット時に行うため不要
  })) as
    | mtechnavi.api.company.ISharedListBusinessUnitSkillsResponse
    | mtechnavi.api.company.IListLocalBusinessUnitSkillsResponse;
};

// [データ取得] 取引先管理（品質要求）マスタ
export const listBusinessUnitRankRequestContents = async (
  businessUnitManagementIds: string[],
  isLinkedTenant: boolean
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: isLinkedTenant
      ? FullMethodName_ListBusinessUnitRankRequestContents
      : FullMethodName_ListLocalBusinessUnitRankRequestContents,
    filter: {},
    requestBody: isLinkedTenant
      ? { businessUnitManagementIds: businessUnitManagementIds }
      : { businessUnitManagementId: businessUnitManagementIds.at(0) },
    sort: [], // ソートは画面項目セット時に行うため不要
  })) as
    | mtechnavi.api.company.IListBusinessUnitRankRequestContentsResponse
    | mtechnavi.api.company.IListLocalBusinessUnitRankRequestContentsResponse;
};

// [データ取得] 取引先管理（品質管理）マスタ
export const listBusinessUnitRankReceptionContents = async (
  businessUnitManagementIds: string[]
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListBusinessUnitRankReceptionContents,
    filter: {},
    requestBody: {
      businessUnitManagementIds: businessUnitManagementIds,
    },
    sort: [],
  })) as mtechnavi.api.company.IListBusinessUnitRankReceptionContentsResponse;
};

// [データ取得] 自社企業（拠点）マスタ
//  自社側の拠点情報の取得
//  取引先管理（品質管理）マスタの自社拠点管理の工場名を取得するため
export const listOwnCompanyBranchs = async (companyIds: string[]) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListBusinessUnitBranchs,
    filter: {},
    requestBody: {
      companyIds: companyIds,
    },
    sort: [],
  })) as mtechnavi.api.company.IListBusinessUnitBranchsResponse;
};

// [データ取得] 更新通知データ(全タブ分)
export const listBusinessUnitChangeNotifications = async (
  businessUnitManagementIds: string[]
) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListBusinessUnitChangeNotifications,
    filter: {},
    requestBody: {
      baseTypeName: 'mtechnavi.api.company.BusinessUnitManagement',
      baseRecordIds: businessUnitManagementIds,
    },
    sort: [], // ソートは画面項目セット時に行うため不要
  })) as mtechnavi.api.company.IListBusinessUnitChangeNotificationsResponse;
};

// [データ取得] 公開資料マスタ（公開資料タブ）
export const listBusinessUnitPublicDocument = async (companyIds: string[]) => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_SharedListBusinessUnitPublicDocuments,
    filter: {},
    requestBody: {
      companyIds: companyIds,
    },
    sort: [],
  })) as mtechnavi.api.company.ISharedListBusinessUnitPublicDocumentsResponse;
};
