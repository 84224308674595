import { FullMethodName_SaveUserSetting } from 'worker/fullMethodName/crud';
import { AppSaveWorker } from '..';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { FilterViewQuery } from 'worker/filter/type';

export interface SaveUserSettingReq {
  actionName: 'saveUserSetting';
  request: SaveUserSettingParam;
}

/** ユーザーデータの保存 */
export const saveUserSetting = async (
  worker: AppSaveWorker,
  req: SaveUserSettingParam
) => {
  return worker.invokeListRequest<
    mtechnavi.api.uicontroller.ISaveUserSettingRequest,
    mtechnavi.api.uicontroller.IUserSetting
  >(FullMethodName_SaveUserSetting, {
    userSetting: {
      userSettingId: req.userSettingId,
      updatedAt: req.updatedAt,
      ...generateUserSetting(req),
    },
  });
};

type SaveUserSettingParam = Pick<
  mtechnavi.api.uicontroller.IUserSetting,
  'userSettingId' | 'updatedAt'
> &
  (UserSettingViewFilter | UserSettingDummy);

export type UserSettingDataType = SaveUserSettingParam['type'];

interface UserSettingViewFilter {
  type: 'filter';
  /** 一覧の ViewId */
  viewId: string;
  displayName?: string;
  recordData: FilterViewQuery;
}

interface UserSettingDummy {
  type: 'dummy';
  recordData: {
    theme: 'dark' | '';
  };
}

/** 一覧フィルタ条件の保存 */
const generateUserSettingViewFilter = (
  request: UserSettingViewFilter
): mtechnavi.api.uicontroller.IUserSetting => {
  return {
    type: `${request.viewId}.${request.type}`,
    displayName: request.displayName,
    recordData: JSON.stringify(request.recordData),
    tag: request.type,
  };
};

/** 別のユーザデータ保存を追加する際のサンプル */
const generateUserSettingDummy = (
  request: UserSettingDummy
): mtechnavi.api.uicontroller.IUserSetting => {
  return {
    type: request.type,
    recordData: JSON.stringify(request.recordData),
    tag: request.type,
  };
};

const generateMap: {
  [K in UserSettingDataType]: (
    req: Extract<SaveUserSettingParam, { type: K }>
  ) => mtechnavi.api.uicontroller.IUserSetting;
} = {
  filter: generateUserSettingViewFilter,
  dummy: generateUserSettingDummy,
} as const;

const generateUserSetting = (
  request: SaveUserSettingParam
): mtechnavi.api.uicontroller.IUserSetting => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return generateMap[request.type](request as any);
};
