import { useEffect, useState } from 'react';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  ViewId,
  PageState,
  getWorkerExceptionMessage,
  getSortSettingFromLocalStorage,
  backToBaseViewPage,
  getPathnameByViewId,
} from '~/shared/utils';
import { error } from '~/shared/components';
import {
  PageNavigation,
  NavigationIconMenu,
  LatestUpdateInfo,
} from '~/shared/components/ui/PageNavigation/PageNavigation';
import type { FilterExpression, FullMethodName } from '~/worker';

interface ConfirmationPaginationProps {
  viewId: ViewId;
  listViewId: ViewId;
  myEmail: string;
  fullMethodName: FullMethodName;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestBody?: { [k: string]: any };
  filter?: FilterExpression;
  pageNavigationIconItems?: NavigationIconMenu[];
  lastUpdateInfo?: LatestUpdateInfo;
  currentItemId: string;
  onChangeItem: (items: unknown) => void;
  onChangeLoadingState?: (isLoading: boolean) => void;
  onChangePage?: (v: number) => void;
}
export const ConfirmationPagination = (props: ConfirmationPaginationProps) => {
  const intl = useIntl();
  const navi = useNavigate();
  // 遷移元から値を受け取る
  const location = (useLocation().state as PageState) ?? [];
  // 例外処理用
  const handleError = useErrorHandler();
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});
  // ページング処理用
  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);

  // pagenatorに基本情報をセット
  const [page, dispatch] = usePagenator({
    fullMethodName: props.fullMethodName,
    pageNumber: location.confirmationViewOption?.pageNumber ?? 1,
    pageSize: 1,
    maxPageNumber: 1,
    requestBody: props.requestBody ?? {},
    filter: props.filter ?? {},
    sort: getSortSettingFromLocalStorage(props.listViewId, props.myEmail),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  //  例外エラー発生時
  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  // pageデータ変更時の処理
  useEffect(() => {
    // メインデータセット
    props.onChangeItem(page.originalItems as unknown);
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
    props.onChangePage && props.onChangePage(page.pageNumber);

    // page変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // [実行処理]ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: props.fullMethodName,
      pageNumber: n,
    });
  };

  // [実行処理]リロード
  const handleReload = () => {
    dispatch({
      type: 'reload',
      fullMethodName: props.fullMethodName,
      onChangeLoadingState: (v) => {
        props.onChangeLoadingState && props.onChangeLoadingState(v);
      },
    });
  };

  // 初回画面用データ
  useEffect(() => {
    // 初回に画面情報を取得する
    handleReload();

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageNavigation
        backpagePath="/"
        iconItems={props.pageNavigationIconItems}
        pageInfo={{
          isVisibleMoveNavi: true,
          isFirstPage,
          isMaxPage,
          pageNumber: page.pageNumber,
        }}
        handleMovePage={handleMovePage}
        infoOption={{
          lastUpdateInfo: {
            isVisibleUpdateInfo: props.lastUpdateInfo?.isVisibleUpdateInfo
              ? true
              : false,
            attribute: props.lastUpdateInfo?.attribute,
            attributeAt: props.lastUpdateInfo?.attributeAt,
            content: props.lastUpdateInfo?.content,
          },
          issuerInfo: { isVisibleIssuerInfo: false },
        }}
        handleBackPage={() => {
          if (location.baseViewOption?.sourceViewId) {
            backToBaseViewPage(navi, location, props.viewId);
          } else {
            const backPath = getPathnameByViewId(props.listViewId)?.path ?? '/';
            navi(backPath, { state: location });
          }
        }}
      />
    </>
  );
};
