import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_AsyncImportBlueprintsWithBlueprintFiles } from 'worker/fullMethodName/crud';

export const importBlueprints = (
  worker: AppSaveWorker,
  req: mtechnavi.api.blueprint.IAsyncImportBlueprintsWithBlueprintFilesRequest
) => {
  return worker.invokeListRequest<
    mtechnavi.api.blueprint.IAsyncImportBlueprintsWithBlueprintFilesRequest,
    void
  >(FullMethodName_AsyncImportBlueprintsWithBlueprintFiles, req);
};
