import { IconButton } from '~/shared/components/parts/Button/IconButton';
import {
  GetMessage,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import { Property } from 'csstype';
import './SimpleListView.css';
import { useTooltip } from '../../parts/Tooltip';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox } from '../../parts/Checkbox/Checkbox';

interface SimpleListActionOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRowClick?: (data: any) => void;
  onFullDownLoad?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDelete?: (data: any) => void;
  onDeleteAll?: () => void;
}

export interface ListColumnSetting {
  header?: MessageProps;
  propertyName: string;
  width?: string;
  align?: Property.TextAlign;
  readonly?: boolean;
}

interface SimpleListViewOptions {
  readonly?: boolean;
  omitHeader?: boolean;
  omitFooter?: boolean;
  previewRowCount?: number;
  keyColumn?: string;
  isVisibleCheckBox?: boolean;
  columns: ListColumnSetting[];
  /** 選択中などで強調する行の keyColumn の値 */
  currentId?: string | null;
  /** currentId に変化があった時に対象の行に追従してスクロールするかどうか */
  focusCurrent?: boolean | null;
}

interface SimpleListViewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  actionOptions: SimpleListActionOptions;
  viewOptions: SimpleListViewOptions;
  onChangeState?: (selectedItems: string[]) => void;
}
export const SimpleListView = (props: SimpleListViewProps) => {
  return (
    <>
      <SimpleListViewContent {...props} />
    </>
  );
};

const SimpleListViewContent = ({
  data,
  actionOptions,
  viewOptions,
  onChangeState,
}: SimpleListViewProps) => {
  const { onRowClick, onFullDownLoad, onDelete, onDeleteAll } = actionOptions;
  const { currentId, focusCurrent } = viewOptions;
  const listViewRef = useRef<HTMLDivElement | null>(null);
  const { showTooltip, hideTooltip } = useTooltip();
  const [selectedItemKeys, setSelectedItemKeys] = useState<string[]>([]);

  const handleRowCheck = (itemKey: string) => {
    if (selectedItemKeys.some((item) => item === itemKey)) {
      setSelectedItemKeys(selectedItemKeys.filter((item) => item !== itemKey));
      return;
    }
    setSelectedItemKeys([...selectedItemKeys, ...[itemKey]]);
  };

  const onParentChangeState = useMemo(
    () => onChangeState ?? (() => {}),
    [onChangeState]
  );

  useEffect(() => {
    onParentChangeState(selectedItemKeys);
  }, [onParentChangeState, selectedItemKeys]);

  useEffect(() => {
    if (!currentId || !focusCurrent) {
      return;
    }
    setTimeout(() => {
      if (!listViewRef.current) {
        return;
      }
      const parent = listViewRef.current;
      const target = parent
        .getElementsByClassName('current')
        .item(0) as HTMLUListElement;
      if (!target) {
        return;
      }
      const parentArea = parent.getBoundingClientRect();
      const targetArea = target.getBoundingClientRect();
      if (targetArea.top < parentArea.top) {
        parent.scrollTop -= parentArea.top - targetArea.top + 48;
      } else if (targetArea.bottom > parentArea.bottom) {
        parent.scrollTop += targetArea.bottom - parentArea.bottom;
      }
    }, 0);
  }, [currentId, focusCurrent]);

  return (
    <div className="SimpleListView">
      <div
        className="scrollable-area"
        style={{
          maxHeight: viewOptions.previewRowCount
            ? `calc(2.5 * ${viewOptions.previewRowCount}rem${
                !viewOptions.omitHeader ? ' + 3rem' : ''
              })`
            : 'auto',
        }}
        ref={listViewRef}
      >
        {!viewOptions.omitHeader && (
          <div
            className="list-header"
            style={{
              gridTemplateColumns: `${
                viewOptions.isVisibleCheckBox ? '2.5rem' : ''
              } ${viewOptions.columns
                .map((column) => column.width || '1fr')
                .join(' ')} ${!viewOptions.readonly && onDelete ? '2em' : ''}`,
            }}
          >
            {viewOptions.isVisibleCheckBox && <div>&nbsp;</div>}
            {viewOptions.columns.map((column) => (
              <div
                key={column.propertyName}
                style={{
                  textAlign: column?.align,
                }}
              >
                {column.header ? GetMessage(column.header) : ''}
              </div>
            ))}
            {!viewOptions.readonly && onDeleteAll && (
              <IconButton
                name="delete"
                iconType="clear_list"
                onClick={() => {
                  onDeleteAll();
                }}
              />
            )}
          </div>
        )}
        <ul className="list-items">
          {data.map((item, index) => {
            const key = item[viewOptions.keyColumn ?? ''];
            return (
              <li
                key={key ?? index}
                className={!!currentId && currentId === key ? 'current' : ''}
                style={{
                  gridTemplateColumns: `${
                    viewOptions.isVisibleCheckBox ? '2.5rem' : ''
                  } ${viewOptions.columns
                    .map((column) => column.width || '1fr')
                    .join(' ')} ${
                    !viewOptions.readonly && onDelete ? '2em' : ''
                  }`,
                }}
              >
                {viewOptions.isVisibleCheckBox && (
                  <div
                    className={`cell check${
                      index % 2 === 0 ? ' even' : ' odd'
                    }`}
                  >
                    <Checkbox
                      name={'list_' + index}
                      value={selectedItemKeys.includes(key) ? '1' : ''}
                      items={[{ value: '1', displayName: '' }]}
                      onChangeState={() => handleRowCheck(key)}
                      validateOption={{ isSkippedValidation: true }}
                    />
                  </div>
                )}
                {viewOptions.columns.map((column) => (
                  <div
                    className="cell"
                    key={column.propertyName}
                    style={{
                      textAlign: column?.align,
                    }}
                    onMouseEnter={(e) =>
                      showTooltip(item[column.propertyName], e.currentTarget)
                    }
                    onMouseLeave={hideTooltip}
                  >
                    {onRowClick && !column.readonly && (
                      <a onClick={() => onRowClick(item)}>
                        {item[column.propertyName]}
                      </a>
                    )}
                    {(!onRowClick || column.readonly) && (
                      <span>{item[column.propertyName]}</span>
                    )}
                  </div>
                ))}
                {!viewOptions.readonly && onDelete && (
                  <IconButton
                    name="delete"
                    buttonType="danger"
                    iconType="clear"
                    onClick={() => onDelete(item)}
                  />
                )}
              </li>
            );
          })}
        </ul>
      </div>
      {!viewOptions.omitFooter && (
        <div className="list-footer">
          <span className="total">{`全 ${data.length} 件`}</span>
          {onFullDownLoad && data.length > 0 && (
            <a
              className="download"
              onClick={() => {
                onFullDownLoad();
              }}
            >
              全件確認
            </a>
          )}
        </div>
      )}
    </div>
  );
};
