import {
  IconButton,
  IconButtonProps,
} from '~/shared/components/parts/Button/IconButton';
import './ViewerControlButton.css';

type ViewerControlButtonProps = {
  /** (必要な場合に)選択中のモードかどうかを示すフラグ */
  isActive?: boolean;
} & Pick<
  IconButtonProps,
  'iconType' | 'onClick' | 'caption' | 'name' | 'disabled'
>;

/**
 * ビューワー上のコントロールボタンコンポーネント
 */
export const ViewerControlButton = ({
  name,
  isActive,
  caption,
  iconType,
  disabled,
  onClick,
}: ViewerControlButtonProps) => {
  return (
    <IconButton
      name={name}
      className={`ViewerControlButton ${isActive ? 'active' : ''}`}
      iconType={iconType}
      caption={caption}
      onClick={onClick}
      disabled={disabled}
    />
  );
};
