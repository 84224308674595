import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  FullMethodName_ListStaffs,
  FullMethodName_ListComponentUnits,
  FullMethodName_ListSharedContactContents,
  FullMethodName_ListTransactionUnits,
  FullMethodName_ListEstimateRequestPlans,
  FullMethodName_ListEstimateManagements,
  FullMethodName_ListEstimateRequests,
  FullMethodName_ListEstimateRequestDetails,
  FullMethodName_ListEstimateRequestUnits,
  FullMethodName_ListEstimateSelections,
  FullMethodName_SharedListEstimateResults,
  FullMethodName_SharedListEstimateResultDetails,
} from '~/shared/utils';

/**
 * 担当者マスタ取得
 * 1件目の ID が空の場合はエラーとする。
 */
export const listStaffs = async () => {
  const listRes = (await window.App.services.ui.worker.filter({
    action: 'query',
    fullMethodName: FullMethodName_ListStaffs,
    filter: {},
    sort: [],
  })) as mtechnavi.api.company.ListStaffsResponse;

  return new Promise<mtechnavi.api.company.ListStaffsResponse>(
    (resolve, reject) => {
      const firstItem = listRes?.items.length > 0 ? listRes?.items[0] : {};
      if (firstItem?.staffId === '') {
        reject({ id: 'E0000070' });
        return;
      }
      resolve(listRes);
    }
  );
};

/**
 * 管理場所マスタ取得
 * 1件目の ID が空の場合はエラーとする。
 */
export const listComponentUnits = async () => {
  const listRes = (await window.App.services.ui.worker.filter({
    action: 'query',
    fullMethodName: FullMethodName_ListComponentUnits,
    filter: {},
    sort: [],
  })) as mtechnavi.api.company.ListComponentUnitsResponse;

  return new Promise<mtechnavi.api.company.ListComponentUnitsResponse>(
    (resolve, reject) => {
      const firstItem = listRes?.items.length > 0 ? listRes?.items[0] : {};
      if (firstItem?.componentUnitId === '') {
        reject({ id: 'E0000070' });
        return;
      }
      resolve(listRes);
    }
  );
};

/**
 * 連絡先を全取得する
 */
export const listSharedContactContents = async () => {
  return (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListSharedContactContents,
    filter: {},
    sort: [],
    requestBody: {},
  })) as mtechnavi.api.company.IListSharedContactContentsResponse;
};

/**
 * ユーザーマスタ取得
 * 1件目の ID が空の場合はエラーとする。
 */
export const listUsers = async () => {
  const listRes = await window.App.services.ui.listUserAttributes();

  return new Promise<mtechnavi.api.tenantadmin.IUserAttribute[]>(
    (resolve, reject) => {
      const firstItem = listRes.length > 0 ? listRes[0] : {};
      if (firstItem?.userAttributeId === '') {
        reject({ id: 'E0000070' });
        return;
      }
      resolve(listRes);
    }
  );
};

/**
 * 品目マスタ取得
 * 1件目の ID が空の場合はエラーとする。
 */
export const listTransactionUnits = async () => {
  const listRes = (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListTransactionUnits,
    filter: {},
    sort: [],
  })) as mtechnavi.api.blueprint.ListTransactionUnitsResponse;

  return new Promise<mtechnavi.api.blueprint.ListTransactionUnitsResponse>(
    (resolve, reject) => {
      const firstItem = listRes?.items.length > 0 ? listRes?.items[0] : {};
      if (firstItem?.transactionUnitId === '') {
        reject({ id: 'E0000070' });
        return;
      }
      resolve(listRes);
    }
  );
  return;
};

/** 選考データの取得(最新1件) */
export const getOneByEstimateSelection = async (
  estimateRequestUnitId: string
) => {
  if (!estimateRequestUnitId) return undefined;
  const result = (await window.App.services.ui.worker.filter({
    action: 'reload',
    fullMethodName: FullMethodName_ListEstimateSelections,
    filter: { $and: [{ estimateSelectionId: { $eq: estimateRequestUnitId } }] },
    requestBody: {
      estimateRequestUnitIds: [estimateRequestUnitId],
    },
    sort: [{ requestedAt: 'desc' }],
  })) as mtechnavi.api.estimation.IListEstimateSelectionsResponse;
  return result.items?.at(0);
};

/**
 * 見積依頼予定データの取得
 */
export const listEstimateRequestPlans = async (
  estimateRequestPlanIds: string[],
  isReload: boolean
) => {
  return (await window.App.services.ui.worker.filter({
    action: isReload ? 'reload' : 'query',
    fullMethodName: FullMethodName_ListEstimateRequestPlans,
    filter: isReload
      ? {}
      : {
          estimateRequestPlanId: {
            $in: estimateRequestPlanIds,
          },
        },
    sort: [],
    requestBody: isReload
      ? {
          estimateRequestPlanIds,
        }
      : {},
  })) as mtechnavi.api.estimation.ListEstimateRequestPlansResponse;
};

/**
 * 見積管理データの取得
 */
export const listEstimateManagements = async (
  estimateManagementIds: string[],
  isReload: boolean
) => {
  return (await window.App.services.ui.worker.filter({
    action: isReload ? 'reload' : 'query',
    fullMethodName: FullMethodName_ListEstimateManagements,
    filter: isReload
      ? {}
      : {
          estimateManagementId: {
            $in: estimateManagementIds,
          },
        },
    sort: [],
    requestBody: isReload
      ? {
          estimateManagementIds,
        }
      : {},
  })) as mtechnavi.api.estimation.ListEstimateManagementsResponse;
};

/**
 * 見積依頼データの取得
 */
export const listEstimateRequests = async (
  estimateManagementIds: string[],
  isReload: boolean
) => {
  return (await window.App.services.ui.worker.filter({
    action: isReload ? 'reload' : 'query',
    fullMethodName: FullMethodName_ListEstimateRequests,
    filter: isReload
      ? {}
      : {
          estimateRequestId: {
            $in: estimateManagementIds,
          },
        },
    sort: [],
    requestBody: isReload
      ? {
          estimateManagementIds,
        }
      : {},
  })) as mtechnavi.api.estimation.ListEstimateRequestsResponse;
};

/**
 * 見積依頼明細データの取得
 */
export const listEstimateRequestDetails = async (
  estimateManagementIds: string[],
  isReload: boolean
) => {
  return (await window.App.services.ui.worker.filter({
    action: isReload ? 'reload' : 'query',
    fullMethodName: FullMethodName_ListEstimateRequestDetails,
    filter: isReload
      ? {}
      : {
          estimateRequestId: {
            $in: estimateManagementIds,
          },
        },
    sort: [
      {
        detailAutoName: 'asc',
      },
    ],
    requestBody: isReload
      ? {
          estimateManagementIds,
        }
      : {},
  })) as mtechnavi.api.estimation.ListEstimateRequestDetailsResponse;
};

/**
 * 見積依頼送信先データの取得
 */
export const listEstimateRequestUnits = async (
  estimateManagementIds: string[],
  isReload: boolean
) => {
  return (await window.App.services.ui.worker.filter({
    action: isReload ? 'reload' : 'query',
    fullMethodName: FullMethodName_ListEstimateRequestUnits,
    filter: isReload
      ? {}
      : {
          estimateRequestId: {
            $in: estimateManagementIds,
          },
        },
    sort: [],
    requestBody: isReload
      ? {
          estimateManagementIds,
        }
      : {},
  })) as mtechnavi.api.estimation.ListEstimateRequestUnitsResponse;
};

/**
 * 公開された 見積回答データの取得
 */
export const listSharedEstimateResults = async (
  estimateManagementIds: string[],
  isReload: boolean
) => {
  return (await window.App.services.ui.worker.filter({
    action: isReload ? 'reload' : 'query',
    fullMethodName: FullMethodName_SharedListEstimateResults,
    filter: isReload
      ? {}
      : {
          estimateManagementId: {
            $in: estimateManagementIds,
          },
        },
    sort: [],
    requestBody: isReload
      ? {
          estimateManagementIds,
        }
      : {},
  })) as mtechnavi.api.estimation.SharedListEstimateResultsResponse;
};

/**
 * 公開された 見積回答明細データの取得
 */
export const listSharedEstimateResultDetails = async (
  estimateManagementIds: string[],
  isReload: boolean
) => {
  return (await window.App.services.ui.worker.filter({
    action: isReload ? 'reload' : 'query',
    fullMethodName: FullMethodName_SharedListEstimateResultDetails,
    filter: isReload
      ? {}
      : {
          estimateManagementId: {
            $in: estimateManagementIds,
          },
        },
    sort: [
      {
        'estimateRequestDetail.detailAutoName': 'asc',
      },
    ],
    requestBody: isReload
      ? {
          estimateManagementIds,
        }
      : {},
  })) as mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse;
};

/**
 * 見積選考データの取得
 */
export const listEstimateSelections = async (
  estimateRequestUnitIds: string[],
  isReload: boolean
) => {
  return (await window.App.services.ui.worker.filter({
    action: isReload ? 'reload' : 'query',
    fullMethodName: FullMethodName_ListEstimateSelections,
    filter: isReload
      ? {}
      : {
          estimateSelectionId: {
            $in: estimateRequestUnitIds,
          },
        },
    sort: [],
    requestBody: isReload
      ? {
          estimateRequestUnitIds,
        }
      : {},
  })) as mtechnavi.api.estimation.ListEstimateSelectionsResponse;
};
