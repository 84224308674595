import {
  ModalDialogComponent,
  ModalDialogComponentProps,
  GetMessage,
  GetMessageWithIntl,
} from '~/shared/components';
import { error } from '../../parts/Toast/Toast';
import { Textarea, Textbox } from '~/shared/components/ui';
import './SamlSettingDialog.css';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { ChangeEvent, useEffect, useState } from 'react';
import { ErrorMessage } from '../../parts/ErrorMessage/ErrorMessage';
import { useIntl } from 'react-intl';

export type DisplayType = 'display' | 'setting';

export interface SamlSettingItem {
  ssosetting?: string;
  ssourl?: string;
  entityId?: string;
  certificateBody?: string;
}

export interface SamlSettingDialogInputOption {
  info: SamlSettingItem;
  displayMode: DisplayType;
}

export interface SamlSettingDialogProps {
  inputOption: SamlSettingDialogInputOption;
  isOpen: boolean;
  onDecision: (result: SamlSettingItem) => void;
  onCancel: () => void;
}

export function SamlSettingDialog(props: SamlSettingDialogProps) {
  const getLabelId = (column: string): string => {
    return `samlSettingDialog.${column}`;
  };
  const intl = useIntl();
  const { isOpen, onCancel, onDecision } = props;
  const { info, displayMode } = props.inputOption;
  const [isDisabled, setDisabled] = useState(false);
  const [entityId, setEntityId] = useState('');
  const [certificateContent, setCertificateContent] = useState('');
  const [ssoURL, setSsoURL] = useState('');

  const [message, setMessage] = useState<string[]>([]); // バリデーションエラー用

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setDisabled(true);
    setEntityId(info.entityId ?? '');
    setSsoURL(info.ssourl ?? '');
    setCertificateContent(info.certificateBody ?? '');
  }, [isOpen, info]);

  const handleFileChange = (evt: ChangeEvent<HTMLInputElement>) => {
    // ファイルがない場合は処理終了
    if (!evt.target.files) {
      return;
    }
    setMessage([]);
    setEntityId('');
    setCertificateContent('');
    setSsoURL('');

    // FileListを配列に変換
    const uploads: File[] = [];
    for (let i = 0; i < evt.target.files.length; i++) {
      uploads.push(evt.target.files[i]);
    }

    const file = uploads[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const xmlData = e.target?.result as string;

        // XMLをパース
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlData, 'application/xml');

        // エラーチェック
        const parserError = xmlDoc.getElementsByTagName('parsererror');
        if (parserError.length > 0) {
          error([GetMessageWithIntl(intl, { id: 'E0000180' })]);
          setDisabled(true);
          return;
        }

        // md:EntityDescriptorの取得
        const entityDescriptor = xmlDoc.getElementsByTagName(
          'md:EntityDescriptor'
        )[0];
        // md:EntityDescriptorの取得が失敗した場合エラー
        if (!entityDescriptor) {
          error([GetMessageWithIntl(intl, { id: 'E0000180' })]);
          setDisabled(true);
          return;
        }

        // entityID属性を取得
        const entityID = entityDescriptor.getAttribute('entityID') ?? '';

        // 証明書の内容コンテンツを取得
        const content =
          entityDescriptor.getElementsByTagName('ds:X509Certificate')[0];
        let addedCertValue = '';
        if (content) {
          const certValue = content.textContent?.trim() || ''; // 内容を取得
          // ヘッダーとフッターを追加
          if (certValue) {
            addedCertValue = addFixedTextToCertificateValue(certValue);
          }
        }
        // SSO URLとBindingを取得
        const singleSignOnService = entityDescriptor.getElementsByTagName(
          'md:SingleSignOnService'
        )[0];
        // SSO URLとBindingを取得が失敗した場合エラー
        if (!singleSignOnService) {
          error([GetMessageWithIntl(intl, { id: 'E0000180' })]);
          setDisabled(true);
          return;
        }
        const SSOUrl = singleSignOnService.getAttribute('Location') ?? '';
        const Binding = singleSignOnService.getAttribute('Binding') ?? '';

        // 取得できていない項目が一つでもあればエラー
        if (!entityID || !addedCertValue || !SSOUrl || !Binding) {
          error([GetMessageWithIntl(intl, { id: 'E0000180' })]);
          setDisabled(true);
          return;
        }
        setEntityId(entityID);
        setCertificateContent(addedCertValue);
        setSsoURL(SSOUrl);
        setDisabled(false);
      } catch (err) {
        setMessage([GetMessage({ id: 'E0000180' })]);
        error([GetMessageWithIntl(intl, { id: 'E0000180' })]);
        setDisabled(true);
      }
    };

    reader.readAsText(file);
  };

  const handleDecision = () => {
    onDecision({
      ssourl: ssoURL,
      entityId: entityId,
      certificateBody: certificateContent,
    });
  };

  // 証明書のヘッダーとフッターを追加する関数
  const addFixedTextToCertificateValue = (certString: string): string => {
    return (
      '-----BEGIN CERTIFICATE-----\n' +
      certString +
      '\n-----END CERTIFICATE-----'
    );
  };

  const view = (
    <div className="content-box">
      <div className="detail-area">
        {displayMode === 'setting' && (
          <>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-100">
                  {/* 設定方法 */}
                  <Textarea
                    name="ssosetting"
                    labelId={getLabelId('ssosetting')}
                    value={info.ssosetting}
                    columns={['ssosetting']}
                    className="w-100 mh-middle"
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-100 file-uploader-button">
                  <div className="FileUploaderArea">
                    <div className="FileUploader">
                      <div className="FileUploaderSelectParts">
                        <label
                          className="file-chooser"
                          style={{ width: '15rem' }}
                        >
                          {GetMessage({
                            id: 'metadataUpload',
                            prefixId: 'samlSettingDialog',
                          })}
                          <input
                            type="file"
                            accept=".xml"
                            value=""
                            onChange={handleFileChange}
                            onClick={(event) => {
                              const inputElement =
                                event.target as HTMLInputElement;
                              inputElement.value = '';
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <ErrorMessage message={message}></ErrorMessage>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              {/* SSOのURL */}
              <Textbox
                name="ssourl"
                className="field"
                value={ssoURL}
                type="text"
                labelId={getLabelId('ssourl')}
                columns={['ssourl']}
                disabled={true}
              />
            </div>
            <div className="w-50">
              {/* エンティティID */}
              <Textbox
                name="entityId"
                className="field"
                value={entityId}
                type="text"
                labelId={getLabelId('entityId')}
                columns={['entityId']}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              {/* 証明書の内容 */}
              <Textarea
                name="certificateBody"
                labelId={getLabelId('certificateBody')}
                value={certificateContent}
                columns={['certificateBody']}
                className="w-100 mh-middle"
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessage({ id: 'cancel' })}
            onClick={() => {
              onCancel && onCancel();
            }}
          />
          {displayMode === 'setting' && (
            <CaptionButton
              name="DecisionBtn"
              buttonType="basic"
              className="button"
              caption={GetMessage({ id: 'decision' })}
              onClick={handleDecision}
              disabled={isDisabled}
            />
          )}
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel && onCancel();
    },
    send: () => handleDecision(),
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: displayMode === 'setting' ? 'samlSetting' : 'samlSettingConfirmation',
    },
    messageLabelId: {},
    elements: view,
  };

  return (
    <div className="SamlSettingDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
}
