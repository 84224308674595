import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  PublicDocument,
  PublicDocumentRegistrationDialog,
} from '~/shared/components/ui/Dialog/PublicDocumentRegistrationDialog';
import { convertDateToLong, convertDatetime } from '~/shared/utils';

export const UISamplePublicDocumentRegistrationDialogs = () => {
  const [addResult, setAddResult] = useState<PublicDocument | null>();
  const [editResult, setEditResult] = useState<PublicDocument | null>();
  const [displayResult, setDisplayResult] = useState<PublicDocument | null>();
  const [isShowAddDialog, setIsShowAddDialog] = useState(false);
  const [isShowEditDialog, setIsShowEditDialog] = useState(false);
  const [isShowDisplayDialog, setIsShowDisplayDialog] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">公開資料登録ダイアログ</h3>

      <h4>新規</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setAddResult(null);
                setIsShowAddDialog(true);
              }}
            />
            <PublicDocumentRegistrationDialog
              isOpen={isShowAddDialog}
              inputOption={{
                inputType: 'add',
                publicDocument: null,
              }}
              onDecision={(v) => {
                setAddResult(v.publicDocument);
                setIsShowAddDialog(false);
              }}
              onCancel={() => {
                setAddResult(null);
                setIsShowAddDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{JSON.stringify(addResult, null, 2)}</pre>
          </div>
        </div>
      </div>

      <h4>編集</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setEditResult(null);
                setIsShowEditDialog(true);
              }}
            />
            <PublicDocumentRegistrationDialog
              isOpen={isShowEditDialog}
              inputOption={{
                inputType: 'edit',
                publicDocument: {
                  statusSystemName: 'B03',
                  displayName: '資料タイトル',
                  contents: 'お知らせ内容',
                  attachments: [
                    {
                      filename: 'テスト.pdf',
                    },
                  ],
                  releaseStartDt: convertDatetime(
                    new Date(2024, 7, 31),
                    'YYYY/MM/DD'
                  ),
                  releaseEndDt: convertDatetime(
                    new Date(2024, 10, 31),
                    'YYYY/MM/DD'
                  ),
                  updatedProperties: {
                    updatedAt: convertDateToLong(new Date(2024, 7, 24)),
                    updatedBy: {
                      displayName: '田中太郎',
                      email: 'sample@p.centsys.jp',
                    },
                  },
                },
              }}
              onDecision={(v) => {
                setEditResult(v.publicDocument);
                setIsShowEditDialog(false);
              }}
              onCancel={() => {
                setEditResult(null);
                setIsShowEditDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{JSON.stringify(editResult, null, 2)}</pre>
          </div>
        </div>
      </div>

      <h4>表示</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setDisplayResult(null);
                setIsShowDisplayDialog(true);
              }}
            />
            <PublicDocumentRegistrationDialog
              isOpen={isShowDisplayDialog}
              inputOption={{
                inputType: 'display',
                publicDocument: {
                  statusSystemName: 'B02',
                  displayName: '資料タイトル',
                  contents: 'お知らせ内容',
                  attachments: [
                    {
                      filename: 'aaa.pdf',
                    },
                  ],
                  releaseStartDt: convertDatetime(
                    new Date(2024, 7, 31),
                    'YYYY/MM/DD'
                  ),
                  releaseEndDt: convertDatetime(
                    new Date(2024, 10, 31),
                    'YYYY/MM/DD'
                  ),
                  updatedProperties: {
                    updatedAt: convertDateToLong(new Date(2024, 7, 24)),
                    updatedBy: {
                      displayName: '田中太郎',
                      email: 'sample@p.centsys.jp',
                    },
                  },
                },
              }}
              onDecision={(v) => {
                setDisplayResult(v.publicDocument);
                setIsShowDisplayDialog(false);
              }}
              onCancel={() => {
                setDisplayResult(null);
                setIsShowDisplayDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {JSON.stringify(displayResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
