import { useEffect, useRef, useState } from 'react';
import {
  Container,
  Toast,
  error,
  GetMessageWithIntl,
} from '~/shared/components';
import {
  Accordion,
  Textbox,
  NavigationIconMenu,
  SimpleListView,
  SamlSettingDialog,
} from '~/shared/components/ui';
import {
  ViewId,
  convertLongToDate,
  formatDate,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getViewIdPreset,
} from '~/shared/utils';
import { PageNavigation } from '~/shared/components/ui/PageNavigation/PageNavigation';
import { CaptionButton } from '~/shared/components/parts/Button/CaptionButton';
import { useAuth } from '~/shared/contexts/AuthProvider';
import './AdminTenantSettingConfirmation.css';
import { useNavigate } from 'react-router-dom';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  FullMethodName_ListBillingCompanys,
  FullMethodName_SharedListLicenses,
} from '~/worker';
import { useIntl } from 'react-intl';
import { PresetItem } from '~/shared/services';
import { LicenseDataType } from './AdminTenantSettingInput';

const VIEW_ID: ViewId = 'ADMIN_TENANT_SETTING_CONFIRMATION';

const SamlDialogFixedText =
  'IDPメタデータをダウンロードし、「メタデータアップロード」ボタンからアップロードしてください。';

type BillingCompany = mtechnavi.api.company.IBillingCompany;
type SamlProviderSetting = mtechnavi.api.idp.ISamlProviderSetting;

export function AdminTenantSettingConfirmation() {
  // ログイン者の情報特定用email
  const auth = useAuth();
  const { showLoading, hideLoading } = useLoading();
  const navi = useNavigate();
  const intl = useIntl();

  // 遷移元から値を受け取る
  const [isOpenSamlSettingDialog, setOpenSamlSettingDialog] = useState(false);

  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  const licenseData = useRef<LicenseDataType[]>([]);

  // データ管理
  const [billingCompanyInfo, setBillingCompanyInfo] = useState<BillingCompany>(
    {}
  );
  const [samlProviderInfo, setSamlProviderInfo] = useState<SamlProviderSetting>(
    {}
  );
  const [tenantName, setTenantName] = useState('');

  // 初回画面用データ
  useEffect(() => {
    try {
      (async () => {
        showLoading();
        const { presetItem } = await getViewIdPreset(intl, VIEW_ID);
        setPreset(presetItem);

        const myCompany = await window.App.services.ui.getMyCompany();
        setTenantName(myCompany.displayNameLang?.ja ?? '');
        const billingCompanyRes = (await window.App.services.ui.worker.filter({
          action: 'reload',
          fullMethodName: FullMethodName_ListBillingCompanys,
          filter: {},
          sort: [],
        })) as mtechnavi.api.company.ListBillingCompanysResponse;
        setBillingCompanyInfo(billingCompanyRes?.items?.at(0) ?? {});
        const listLicenseRes = (await window.App.services.ui.worker.filter({
          action: 'reload',
          fullMethodName: FullMethodName_SharedListLicenses,
          filter: {},
          sort: [],
        })) as mtechnavi.api.license.SharedListLicensesResponse;
        licenseData.current = listLicenseRes?.items
          .filter((license) => {
            const endAt = convertLongToDate(license.endAt);
            return (endAt && endAt >= new Date()) || license.withoutEnd;
          })
          .map((license): LicenseDataType => {
            return {
              licenseEnabled: isEnableLicense(license) ? '◯' : '-',
              licenseName: license.displayName ?? '',
              licenseStartDt: formatDate(
                convertLongToDate(license.startAt),
                'YYYY/MM/DD'
              ),
              licenseEndDt: license.withoutEnd
                ? GetMessageWithIntl(intl, {
                    id: 'withoutEnd',
                    prefixId: VIEW_ID,
                  })
                : formatDate(convertLongToDate(license.endAt), 'YYYY/MM/DD'),
            };
          });
        const listSamlProviderSettingRes =
          (await window.App.services.ui.worker.filter({
            action: 'reload',
            fullMethodName:
              'mtechnavi.api.idp.Identity/ListSamlProviderSettings',
            filter: {},
            sort: [],
          })) as mtechnavi.api.idp.ListSamlProviderSettingsResponse;
        setSamlProviderInfo(
          listSamlProviderSettingRes?.items.find(
            (v) => v.samlProviderId === 'saml.default'
          ) ?? {}
        );
        hideLoading();
      })();
    } catch (err) {
      error(getExceptionMessage(intl, err));
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ライセンスの有効性チェック
  const isEnableLicense = (
    license: mtechnavi.api.license.ILicense
  ): boolean => {
    const startDt = convertLongToDate(license.startAt);
    const endDt = convertLongToDate(license.endAt);
    const today = new Date();
    if (!startDt) {
      return false;
    }
    // 有効期限内の場合
    if (startDt <= today && endDt && endDt >= today) {
      return true;
    }
    // 有効期限なしの場合
    if (startDt <= today && license.withoutEnd) {
      return true;
    }
    // 有効期限前の場合
    if (startDt > today) {
      return false;
    }
    return false;
  };

  // preset
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  // ナビゲーションメニュー
  const navigationIconItems = (): NavigationIconMenu[] => {
    const iconItems: NavigationIconMenu[] = [];
    // 編集
    iconItems.push({
      name: 'edit',
      displayName: '編集',
      func: () => {
        handleMenuInput();
      },
    });

    return iconItems;
  };

  // 編集
  const handleMenuInput = () => {
    navi('/admin-tenant-setting-input');
  };

  const getLabelId = (column: string): string => {
    return `${VIEW_ID}.${column}`;
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="header">
          <PageNavigation
            backpagePath="/admin-account-list"
            pageInfo={{
              isUnvisibleBackNavi: true,
              isVisibleMoveNavi: false,
            }}
            iconItems={navigationIconItems()}
            infoOption={{
              lastUpdateInfo: {
                isVisibleUpdateInfo: true,
                attributeAt:
                  billingCompanyInfo?.updatedProperties?.updatedAt ?? undefined,
                content:
                  billingCompanyInfo?.updatedProperties?.updatedBy
                    ?.displayName ?? '',
                attribute:
                  billingCompanyInfo?.updatedProperties?.updatedBy?.email ?? '',
              },
              issuerInfo: { isVisibleIssuerInfo: false },
            }}
          />
        </div>
        <div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <Accordion
              title={GetMessageWithIntl(intl, {
                id: 'companyTitle',
                prefixId: VIEW_ID,
              })}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-40">
                    <Textbox
                      name="companyName"
                      className="field"
                      value={billingCompanyInfo?.displayName ?? ''}
                      type="text"
                      labelId={getLabelId('companyName')}
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="adminName"
                      className="field"
                      value={billingCompanyInfo?.adminDisplayName ?? ''}
                      type="text"
                      labelId={getLabelId('adminName')}
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="adminEmail"
                      className="field"
                      value={billingCompanyInfo?.adminEmail ?? ''}
                      type="text"
                      labelId={getLabelId('adminEmail')}
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <Textbox
                      name="companyCountry"
                      className="field"
                      value={
                        billingCompanyInfo?.country?.displayNameLang?.ja ?? ''
                      }
                      type="text"
                      labelId={getLabelId('companyCountry')}
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="companyRegion"
                      className="field"
                      value={
                        billingCompanyInfo?.region?.displayNameLang?.ja ?? ''
                      }
                      type="text"
                      labelId={getLabelId('companyRegion')}
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="companyAddress"
                      className="field"
                      value={billingCompanyInfo?.addressLines?.join('') ?? ''}
                      type="text"
                      labelId={getLabelId('companyAddress')}
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="companyPhoneNumber"
                      className="field"
                      value={billingCompanyInfo?.phoneNumber ?? ''}
                      type="text"
                      labelId={getLabelId('companyPhoneNumber')}
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion
              title={GetMessageWithIntl(intl, {
                id: 'tenantTitle',
                prefixId: VIEW_ID,
              })}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-40">
                    <Textbox
                      name="tenantName"
                      className="field"
                      value={tenantName}
                      type="text"
                      labelId={getLabelId('tenantName')}
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>

              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <Textbox
                      name="tenantCode"
                      className="field"
                      value={auth.tenant?.tenantCode ?? ''}
                      type="text"
                      labelId={getLabelId('tenantCode')}
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="tenantUrl"
                      className="field"
                      value={auth.tenant?.domain}
                      type="text"
                      labelId={getLabelId('tenantUrl')}
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion
              title={GetMessageWithIntl(intl, {
                id: 'licenseTitle',
                prefixId: VIEW_ID,
              })}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-66">
                    <SimpleListView
                      data={licenseData.current}
                      viewOptions={{
                        previewRowCount: 5,
                        columns: [
                          {
                            header: { viewId: VIEW_ID, id: 'licenseEnabled' },
                            propertyName: 'licenseEnabled',
                            width: '8%',
                          },
                          {
                            header: { viewId: VIEW_ID, id: 'licenseName' },
                            propertyName: 'licenseName',
                            width: '48%',
                          },
                          {
                            header: { viewId: VIEW_ID, id: 'licenseStartDt' },
                            propertyName: 'licenseStartDt',
                            width: '21%',
                          },
                          {
                            header: { viewId: VIEW_ID, id: 'licenseEndDt' },
                            propertyName: 'licenseEndDt',
                            width: '21%',
                          },
                        ],
                        omitFooter: false,
                      }}
                      actionOptions={{}}
                    />
                  </div>
                </div>
              </div>
            </Accordion>

            <Accordion
              title={GetMessageWithIntl(intl, {
                id: 'certificateTitle',
                prefixId: VIEW_ID,
              })}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <Textbox
                      name="certificateWork"
                      className="field"
                      value={samlProviderInfo?.enabled ? 'SAML' : 'パスワード'}
                      type="text"
                      labelId={getLabelId('certificateWork')}
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  <div className="w-20 attachment-area">
                    <div className="upload-button">
                      <CaptionButton
                        buttonType="basic"
                        caption={GetMessageWithIntl(intl, {
                          id: 'ssostatusBtn',
                          viewId: VIEW_ID,
                        })}
                        name="add"
                        disabled={!samlProviderInfo?.enabled}
                        onClick={() => {
                          setOpenSamlSettingDialog(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
        </div>
        <SamlSettingDialog
          isOpen={isOpenSamlSettingDialog}
          inputOption={{
            info: {
              ssosetting: SamlDialogFixedText,
              ssourl: samlProviderInfo?.ssoUrl ?? '',
              entityId: samlProviderInfo?.entityId ?? '',
              certificateBody:
                samlProviderInfo?.certificates &&
                samlProviderInfo?.certificates.length
                  ? samlProviderInfo?.certificates[0]
                  : '',
            },
            displayMode: 'display',
          }}
          onCancel={() => {
            setOpenSamlSettingDialog(false);
          }}
          onDecision={() => {
            setOpenSamlSettingDialog(false);
          }}
        />
        <Toast />
      </Container>
    </>
  );
}
