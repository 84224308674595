import { FullMethodName_CreateBlueprintAndBlueprintRevision } from 'worker/fullMethodName/crud';
import { AppSaveWorker } from '..';
import { mtechnavi } from '~/shared/libs/clientsdk';

/** 図面の新規登録 */
export const createBlueprint = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.blueprint.ICreateBlueprintAndBlueprintRevisionRequest
) => {
  return worker.invokeListRequest<
    mtechnavi.api.blueprint.ICreateBlueprintAndBlueprintRevisionRequest,
    mtechnavi.api.blueprint.IBlueprintContent
  >(FullMethodName_CreateBlueprintAndBlueprintRevision, req);
};
