import { PresetItem } from '~/shared/services';
import { FilterViewQuery } from '../index';
import { FilterExpression, FilterTerm, SortTerm } from '~/worker';
import { DataFilterbox, DataFilterboxItem } from '../../Filterbox';
import { isFilterIn, isStringArray, toInFilter } from '../filter';
import { useEffect, useMemo, useState } from 'react';
import {
  checkDisalbedPresetProperty,
  getPresetPropertysByColumnName,
} from '../preset';

export const getFilteringFilterboxCustomValues = (
  terms: FilterTerm[]
): string[] => {
  const selectedValues: string[] = [];
  for (const term of terms) {
    for (const v of Array.from(Object.values(term))) {
      if (isFilterIn(v) && isStringArray(v.$in)) {
        selectedValues.push(...v.$in);
      }
    }
  }
  return selectedValues;
};

const dataFilterboxItem = (
  displayName: string,
  value: string
): DataFilterboxItem => {
  return {
    displayName: displayName,
    value: value,
  };
};

const toDataFilterboxItemsByDisplayValues = (
  items: DataFilterboxItem[],
  ...selectedValues: string[]
): DataFilterboxItem[] => {
  return selectedValues.map((value) => {
    const v = items.find((item) => item.value === value);
    return dataFilterboxItem(v?.displayName ?? '', v?.value ?? '');
  });
};

interface FilterInputFilterboxCustomProps {
  labelId: string;
  column: string;
  presetItem?: PresetItem;
  filterValue: FilterViewQuery;
  onChangeFilter?: (terms: FilterTerm[]) => void;
}
/**
 * カスタム項目値検索フィルタ
 * 
 * 個別に用意した項目値のvalueに対して、value一致検索を行う
 * 
 * - プリセット
 *  - type='filterboxCustom'
      - children name='filterboxCustomItem'
                 columns=['フィールド名']
                 property=[
                  {
                    name='フィールド名'
                    columnName='フィルター値（検索値）'
                    columnValue='表示名（フィルター表示名）'
                  }
                 ]
 * 
 * @param props 
 * @returns 
 */
export const FilterInputFilterboxCustom = (
  props: FilterInputFilterboxCustomProps
) => {
  const { labelId, column, presetItem, filterValue, onChangeFilter } = props;

  const [dataFilterboxValue, setDataFilterboxValue] = useState<
    DataFilterboxItem[]
  >([]);

  const [dataFilterboxItems, isDisabledColumn] = useMemo<
    [DataFilterboxItem[], boolean]
  >(() => {
    // フィルターボックスの初期値検索用
    const presetChildren = presetItem?.children?.find(
      (v) => v.name === 'filterboxCustomItem'
    );
    const dataFilterboxItems: DataFilterboxItem[] = [];
    const filterboxCustomItems = getPresetPropertysByColumnName(
      presetChildren?.property ?? [],
      column
    );
    filterboxCustomItems.map((property) => {
      if (property.name === column) {
        dataFilterboxItems.push({
          displayName: property.propertyValue,
          value: property.propertyName,
        });
      }
    });
    const isDisabled = checkDisalbedPresetProperty(
      column,
      presetItem?.children
    );
    return [dataFilterboxItems, isDisabled];
  }, [column, presetItem?.property, presetItem?.children]);

  useEffect(() => {
    const values = getFilteringFilterboxCustomValues(
      filterValue.filterTerms[column] ?? []
    );
    setDataFilterboxValue(
      toDataFilterboxItemsByDisplayValues(dataFilterboxItems, ...values)
    );
  }, [column, filterValue.filterTerms, dataFilterboxItems]);

  const onChangeDataFilterboxItem = (selectedItems: DataFilterboxItem[]) => {
    const values = selectedItems.map((v) => v.value);
    const filterTerms: FilterTerm[] = [];
    if (values.length > 0) {
      filterTerms.push(toInFilter(column, values));
    }
    setDataFilterboxValue(selectedItems);
    onChangeFilter && onChangeFilter(filterTerms);
  };
  return (
    <div className="input-area">
      <DataFilterbox
        data={dataFilterboxItems}
        value={dataFilterboxValue}
        labelId={labelId}
        multiple={true}
        searchOption={{ targets: 'displayName' }}
        name={column}
        columns={[column]}
        onChangeState={(v) => {
          onChangeDataFilterboxItem(v);
        }}
        disabled={isDisabledColumn}
      ></DataFilterbox>
    </div>
  );
};
