import { Navigate, Route, Routes } from 'react-router-dom';
import { RequireAuth } from '~/shared/contexts/AuthProvider';
import { BlueprintBlueprintConfirmation } from './pages/BlueprintBlueprintConfirmation/index';
import { BlueprintBlueprintInput } from './pages/BlueprintBlueprintInput';
import { BlueprintBlueprintList } from './pages/BlueprintBlueprintList';
import { BlueprintBlueprintSearch } from './pages/BlueprintBlueprintSearch';
import { BlueprintBlueprintSearchResult } from './pages/BlueprintBlueprintSearchResult';
import { BlueprintSearchProvider } from './pages/BlueprintSearchProvider';

export const BlueprintRoutes = () => {
  return (
    <Routes>
      <Route
        path="/blueprint-list"
        element={
          <RequireAuth>
            <BlueprintBlueprintList />
          </RequireAuth>
        }
      />
      <Route
        path="/blueprint-confirmation"
        element={
          <RequireAuth>
            <BlueprintBlueprintConfirmation />
          </RequireAuth>
        }
      />
      <Route
        path="/blueprint-confirmation/:blueprintId/:revision"
        element={
          <RequireAuth>
            <BlueprintBlueprintConfirmation />
          </RequireAuth>
        }
      />
      <Route
        path="/blueprint-input"
        element={
          <RequireAuth>
            <BlueprintBlueprintInput />
          </RequireAuth>
        }
      />
      <Route
        path="/*"
        element={
          <BlueprintSearchProvider>
            <Routes>
              <Route
                path="blueprint-search-result"
                element={
                  <RequireAuth>
                    <BlueprintBlueprintSearchResult />
                  </RequireAuth>
                }
              />
              <Route
                path="blueprint-search"
                element={
                  <RequireAuth>
                    <BlueprintBlueprintSearch />
                  </RequireAuth>
                }
              />
              <Route path="*" element={<Navigate to={{ pathname: '/' }} />} />
            </Routes>
          </BlueprintSearchProvider>
        }
      />
      <Route path="*" element={<Navigate to={{ pathname: '/' }} />} />
    </Routes>
  );
};
