import { useEffect, useState } from 'react';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { getWorkerExceptionMessage, ViewId } from '~/shared/utils';
import { getUserDefinedViewData, getUserDefinedViewList } from '../../utils';
import { error, getMessageId, GetMessageWithIntl } from '~/shared/components';
import { useIntl } from 'react-intl';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { DataFilterbox, DataFilterboxItem } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import './BlueprintBlueprintConfirmationData.css';
import { DataGrid } from '~/shared/components/ui/DataGrid';

interface BlueprintBlueprintConfirmationDataProps {
  viewId: ViewId;
  pageNumber: number;
}
/** データタブ */
export const BlueprintBlueprintConfirmationData = ({
  viewId,
  pageNumber,
}: BlueprintBlueprintConfirmationDataProps) => {
  const intl = useIntl();
  const { showLoading, hideLoading } = useLoading();

  const [views, setViews] =
    useState<mtechnavi.api.blueprint.IUserDefinedView[]>();
  const [selectedView, setSelectedView] = useState<DataFilterboxItem[]>();
  const [data, setData] =
    useState<mtechnavi.api.blueprint.IListUserDefinedDatasResponse>();

  const viewListItem: DataFilterboxItem[] = (views ?? []).map((view) => ({
    displayName: view.displayName ?? '',
    value: view.viewName ?? '',
  }));

  const columns = (data?.headers ?? []).map((col) => ({
    field: col.key ?? '',
    headerName: col.value ?? '',
  }));

  const rows = (data?.datas ?? []).map((row) =>
    Object.fromEntries((row.record ?? []).map((item) => [item.key, item.value]))
  );

  useEffect(() => {
    (async () => {
      showLoading();
      try {
        const views = await getUserDefinedViewList();
        setViews(views?.userDefinedViews ?? []);
      } catch (err) {
        error(getWorkerExceptionMessage(intl, err));
      } finally {
        hideLoading();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedView([]);
    setData({});
  }, [pageNumber]);

  const handleShowData = async () => {
    const viewName = selectedView?.at(0)?.value;
    if (!viewName) {
      return;
    }
    showLoading();
    try {
      const data = await getUserDefinedViewData(viewName);
      if (data?.datas?.length === 0) {
        error([GetMessageWithIntl(intl, { id: 'I0000006' })]);
        return;
      }
      setData(data);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      hideLoading();
    }
  };

  return (
    <div className="BlueprintBlueprintConfirmationData">
      <div className="input-line mixed-input-line">
        <div className="item-group-100">
          <div className="w-20">
            <DataFilterbox
              name="view"
              data={viewListItem}
              value={selectedView}
              labelId={getMessageId({ viewId, id: 'data.view' })}
              onChangeState={setSelectedView}
              columns={['view']}
            />
          </div>
          <div className="w-20">
            <CaptionButton
              buttonType="basic"
              name="show"
              caption={GetMessageWithIntl(intl, { viewId, id: 'data.show' })}
              onClick={handleShowData}
            />
          </div>
        </div>
      </div>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            <DataGrid columns={columns} rows={rows} />
          </div>
        </div>
      </div>
    </div>
  );
};
