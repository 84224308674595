import { useIntl } from 'react-intl';
import { Property } from 'csstype';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import {
  GetMessage,
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import './base.css';
import './HistoryDialog.css';
import { GenerallyIconType } from '../../parts/Button/GenerallyIcons';
import { IconButton, ButtonType } from '../../parts/Button/IconButton';
import { Key } from 'react';

export interface HistoryDialogColumnSetting<T> {
  header?: MessageProps;
  propertyName: Exclude<keyof T, symbol>;
  width?: string;
  align?: Property.TextAlign;
}

interface HistoryRowAction {
  iconType: GenerallyIconType;
  buttonType?: ButtonType;
  disabledFn?: (keyColumnValue: unknown) => boolean;
  action: (keyColumnValue: unknown) => void;
}

export interface HistoryDialogInputOption<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: T[];
  keyColumn: Exclude<keyof T, symbol>;
  columns: HistoryDialogColumnSetting<T>[];
  rowAction?: HistoryRowAction;
}

export interface HistoryDialogMessageOption {
  headerLabelId: MessageProps;
}

export interface HistoryDialogProps<T> {
  isOpen: boolean;
  messageOption: HistoryDialogMessageOption;
  inputOption: HistoryDialogInputOption<T>;
  onCancel: () => void;
}

const makeTemplateColumnStyles = <T,>(
  columns: HistoryDialogColumnSetting<T>[],
  hasAction?: boolean
): React.CSSProperties => {
  const columnStyles = columns.map((column) => column.width || '1fr');
  if (hasAction) {
    columnStyles.unshift('4rem');
  }
  return {
    gridTemplateColumns: `${columnStyles.join(' ')}`,
  };
};

export const HistoryDialog = <T,>({
  isOpen,
  messageOption,
  inputOption,
  onCancel,
}: HistoryDialogProps<T>) => {
  const intl = useIntl();

  const columnStyle = makeTemplateColumnStyles<T>(
    inputOption.columns,
    !!inputOption.rowAction
  );

  const elements = (
    <div className="history-dialog">
      <div className="detail-area">
        <div className="scrollable-area">
          {
            <div className="history-header" style={columnStyle}>
              {inputOption.rowAction && <div key="actionIcon" />}
              {inputOption.columns.map((column) => (
                <div key={column.propertyName}>
                  {column.header ? GetMessage(column.header) : ''}
                </div>
              ))}
            </div>
          }
          <ul className="history-list">
            {inputOption.data.map((item) => {
              const key = item[inputOption.keyColumn] as unknown as Key;
              return (
                <li key={key} style={columnStyle}>
                  {inputOption.rowAction && (
                    <div className="cell-action" key="actionIcon">
                      <IconButton
                        name="action"
                        iconType={inputOption.rowAction.iconType}
                        buttonType={inputOption.rowAction.buttonType}
                        disabled={
                          inputOption.rowAction.disabledFn &&
                          inputOption.rowAction.disabledFn(key)
                        }
                        onClick={() => {
                          inputOption.rowAction?.action(key);
                        }}
                      />
                    </div>
                  )}
                  {inputOption.columns.map((column) => (
                    <div
                      className="cell"
                      key={column.propertyName}
                      style={{
                        textAlign: column?.align,
                      }}
                    >
                      {item[column.propertyName] as unknown as string}
                    </div>
                  ))}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, {
            prefixId: 'DIALOG_CAPTION',
            id: 'return',
          })}
          onClick={() => onCancel()}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements,
  };

  return (
    <div className="HistoryDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
