import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessage,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
  success,
} from '~/shared/components';
import {
  Textbox,
  ConfirmationDialog,
  DataFilterbox,
  DataFilterboxItem,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { PresetItem } from '~/shared/services';
import {
  PageState,
  ViewId,
  convertOrganizationStructureReference,
  convertOrganizationStructureReferenceToFilterboxItem,
  getDateFormat,
  getMaxMainContentsHeight,
  getOrganizationFilterBoxItems,
  getPathnameByViewId,
  getWorkerExceptionMessage,
  includeInputValidateError,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  PageNavigation,
  LatestUpdateInfo,
} from '~/shared/components/ui/PageNavigation/PageNavigation';
import { OrganizationTreeSearchDialog } from '~/shared/components/ui/Dialog/OrganizationTreeSearchDialog';
import { IconButton } from '~/shared/components/ui/Button';
import './AdminAccountInput.css';

const VIEW_ID: ViewId = 'ADMIN_ACCOUNT_INPUT';

export function AdminAccountInput() {
  const viewMessageCancel: MessageProps = {
    id: 'confirmationDialogMessage',
    prefixId: VIEW_ID,
  };
  const requiredCodeArea = useRef(null);
  const requiredNameArea = useRef(null);

  const sourcePageInfo = (useLocation().state as PageState) ?? [];
  const actionType = sourcePageInfo.actionType;
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  const [backPageUrl, setBackPageUrl] = useState('');
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);
  const intl = useIntl();
  const navi = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });
  const excessTargetMessage = GetMessageWithIntl(intl, { id: 'E0000082' });
  const userItem = useRef<mtechnavi.api.tenantadmin.IUserAttribute>();
  const [email, setEmail] = useState('');
  const [isEmailTextBox, setEmailDisabled] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [staffCode, setStaffCode] = useState('');
  const [remarks, setRemarks] = useState('');

  // 組織
  const organizations = useRef<mtechnavi.api.company.IOrganization[]>([]);
  // 組織フィルターボックス
  const [
    representativeOrganizationFilterBoxItems,
    setRepresentativeOrganizationFilterBoxItems,
  ] = useState<DataFilterboxItem[]>([]);
  const [representativeOrganizationItem, setRepresentativeOrganizationItem] =
    useState<DataFilterboxItem[]>([]);
  const [
    approvalOrganizationFilterBoxItems,
    setApprovalOrganizationFilterBoxItems,
  ] = useState<DataFilterboxItem[]>([]);
  const [approvalOrganizationItem, setApprovalOrganizationItem] = useState<
    DataFilterboxItem[]
  >([]);

  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isOpenSaveDialog, setOpenSaveDialog] = useState(false);
  const [lastUpdateInfo, setLastUpdateInfo] = useState<LatestUpdateInfo>({
    isVisibleUpdateInfo: false,
  });
  const [confirmDialogInfo, setConfirmDialogInfo] = useState({
    viewMessage: viewMessageCancel,
    onDecision: () => {},
  });

  const viewMessageSave: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, {
        prefixId: 'CONFIRM_DIALOG_MESSAGE_ACTION',
        viewId: VIEW_ID,
      }),
    },
  };

  const duplicated = GetMessage({ id: 'E0000073' });

  // アコーディオン
  const [accordionState, setAccordionState] = useState<{
    [k: string]: boolean;
  }>({
    attributes: true,
  });
  const accordionAction = (type: string) => {
    setAccordionState({ ...accordionState, [type]: !accordionState[type] });
  };

  // 組織検索ダイアログ
  const [isOpenApprovalOrgSearchDialog, setOpenApprovalOrgSearchDialog] =
    useState(false);
  const [
    isOpenRepresentativeOrgSearchDialog,
    setOpenRepresentativeOrgSearchDialog,
  ] = useState(false);
  const [representativeOrgCheckedIds, setRepresentativeOrgCheckedIds] =
    useState<string[]>([]);
  const [approvalOrgCheckedIds, setApprovalOrgCheckedIds] = useState<string[]>(
    []
  );

  // 画面データ取得
  useEffect(() => {
    try {
      setLoading(true);
      (async () => {
        // プリセット取得
        const { presetItem } = await window.App.services.ui.getViewIdPreset(
          VIEW_ID
        );
        setPreset(presetItem);
      })();
      (async () => {
        // 組織フィルターボックスアイテム取得
        organizations.current = await window.App.services.ui.listOrganization();
        const organizationFilterItem = await getOrganizationFilterBoxItems();
        setRepresentativeOrganizationFilterBoxItems(organizationFilterItem);
        setApprovalOrganizationFilterBoxItems(organizationFilterItem);
        // 新規登録モード
        if (actionType === 'add') {
          setLastUpdateInfo({
            isVisibleUpdateInfo: false,
          });
          setEmailDisabled(false);
        }
        // 編集モード
        if (actionType === 'edit') {
          const userAttribute =
            (await window.App.services.tenantAdminService.getUserAttribute({
              userAttributeId:
                (sourcePageInfo.ids || []).length > 0
                  ? sourcePageInfo.ids![0] ?? ''
                  : '',
            })) as mtechnavi.api.tenantadmin.IUserAttribute;
          userItem.current = userAttribute;
          setEmail(userAttribute?.user?.email || '');
          setEmailDisabled(true);
          setAccountName(userAttribute?.user?.displayName || '');
          setRemarks(userAttribute?.remarks || '');
          setStaffCode(userAttribute?.staffCode || '');
          setRepresentativeOrganizationItem(
            [
              convertOrganizationStructureReferenceToFilterboxItem(
                userAttribute?.representativeOrganization
              ) || { value: '', displayName: '' },
            ] || []
          );
          setRepresentativeOrgCheckedIds([
            userAttribute?.representativeOrganization?.organizationId || '',
          ]);
          setApprovalOrganizationItem(
            [
              convertOrganizationStructureReferenceToFilterboxItem(
                userAttribute?.approvalOrganization
              ) || { value: '', displayName: '' },
            ] || []
          );
          setApprovalOrgCheckedIds([
            userAttribute?.approvalOrganization?.organizationId || '',
          ]);
          setLastUpdateInfo({
            isVisibleUpdateInfo: true,
            attributeAt: userAttribute?.userAttributeUpdatedAt ?? undefined,
            content: userAttribute?.userAttributeUpdatedBy?.displayName ?? '',
            attribute: userAttribute?.userAttributeUpdatedBy?.email ?? '',
          });
        }
      })();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      setLoading(false);
    }
    // 戻るボタンURLセット
    setBackPageUrl(
      getPathnameByViewId(sourcePageInfo.sourceViewId)?.path ?? ''
    );
    // 初回時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 保存
  const handleSaveAccount = async () => {
    setLoading(true);
    try {
      const request: mtechnavi.api.tenantadmin.IUserAttribute =
        userItem.current ?? {};
      const user = request.user ?? {
        displayName: null,
        email: null,
      };
      user.displayName = accountName;
      user.email = email;
      request.user = user;
      request.staffCode = staffCode;
      request.remarks = remarks;
      request.representativeOrganization =
        await convertOrganizationStructureReference(
          representativeOrganizationItem.length > 0
            ? representativeOrganizationItem[0].value
            : ''
        );
      request.approvalOrganization =
        await convertOrganizationStructureReference(
          approvalOrganizationItem.length > 0
            ? approvalOrganizationItem[0].value
            : ''
        );

      await window.App.services.ui.worker.apiCall({
        actionName: 'saveAdminAccount',
        request,
      });

      success([toastSuccess]);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
      setOpenSaveDialog(false);
    }
    // 遷移元に戻る
    backToPreviewPage();
  };

  // 戻るページ
  const backToPreviewPage = () => {
    const state: PageState = {
      ids: sourcePageInfo.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.naviFilters,
      beforeStateIds: sourcePageInfo.beforeStateIds,
      baseViewOption: sourcePageInfo.baseViewOption,
      confirmationViewOption: sourcePageInfo.confirmationViewOption,
    };
    navi(backPageUrl ?? '/admin-account-list', { state });
  };

  // emailチェック
  const handleEmailCheck = async () => {
    if (actionType === 'add') {
      const userList = await window.App.services.ui.listUserAttributes();

      const userEmailList = userList.map((v) => v.user?.email) as string[];
      if (userEmailList.includes(email)) {
        error([excessTargetMessage]);
        setOpenSaveDialog(false);
        return;
      }
    }
    handleSaveAccount();
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="AdminAccountInput">
          <div className="header">
            <PageNavigation
              backpagePath={backPageUrl}
              pageInfo={{
                isVisibleMoveNavi: false,
              }}
              infoOption={{
                lastUpdateInfo: lastUpdateInfo,
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => {
                setConfirmDialogInfo({
                  viewMessage: viewMessageCancel,
                  onDecision: backToPreviewPage,
                });
                setOpenConfirmDialog(true);
              }}
            />
          </div>
          <div>
            <div
              className="scroll-main-contents-area"
              style={{
                maxHeight: mainContentHeight,
              }}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-40" ref={requiredCodeArea}>
                    <Textbox
                      name="user.email"
                      className="field"
                      value={email}
                      type="text"
                      labelId="ADMIN_ACCOUNT_INPUT.user.email"
                      validateOption={{ required: true }}
                      columns={preset.columns}
                      onChangeState={setEmail}
                      disabled={isEmailTextBox}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="status.status.displayNameLang"
                      className="field"
                      value={
                        userItem.current?.status?.status?.displayNameLang?.ja ??
                        ''
                      }
                      type="text"
                      labelId="ADMIN_ACCOUNT_INPUT.status.status.displayNameLang"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="user.lastLoginAt"
                      className="field"
                      value={getDateFormat(
                        userItem.current?.user?.lastLoginAt ?? '',
                        'YYYY/MM/DD HH:mm'
                      )}
                      type="text"
                      labelId="ADMIN_ACCOUNT_INPUT.user.lastLoginAt"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-40" ref={requiredNameArea}>
                    <Textbox
                      name="user.displayName"
                      className="field"
                      value={accountName}
                      type="text"
                      labelId="ADMIN_ACCOUNT_INPUT.user.displayName"
                      columns={preset.columns}
                      onChangeState={setAccountName}
                      validateOption={{ required: true }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`input-blocktitle-outer ${
                  accordionState.attributes ? '' : 'close'
                }`}
              >
                <h3
                  className="input-blocktitle"
                  onClick={() => accordionAction('attributes')}
                >
                  {GetMessage({ id: 'attributes', prefixId: VIEW_ID })}
                </h3>
              </div>
              <div
                className={`input-blockbody ${
                  accordionState.attributes ? '' : 'close'
                }`}
              >
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-20">
                      <Textbox
                        name="staffCode"
                        className="field"
                        value={staffCode}
                        type="text"
                        labelId="ADMIN_ACCOUNT_INPUT.staffCode"
                        columns={preset.columns}
                        onChangeState={setStaffCode}
                      />
                    </div>
                    <div className="w-20">
                      <Textbox
                        name="approver"
                        className="field"
                        value={userItem.current?.approver ? '◯' : '-'}
                        type="text"
                        labelId="ADMIN_ACCOUNT_INPUT.approver"
                        columns={preset.columns}
                        disabled={true}
                      />
                    </div>
                    <div className="w-40">
                      <Textbox
                        name="remarks"
                        className="field"
                        value={remarks}
                        type="text"
                        labelId="ADMIN_ACCOUNT_INPUT.remarks"
                        columns={preset.columns}
                        onChangeState={setRemarks}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-line">
                  <div className="item-group-100">
                    <div className="w-40">
                      <div className="input-line no-space">
                        <div className="item-group item-group-100 no-space">
                          <DataFilterbox
                            name="representativeOrganization"
                            labelId="ADMIN_ACCOUNT_INPUT.representativeOrganization"
                            columns={preset.columns}
                            data={representativeOrganizationFilterBoxItems}
                            value={
                              representativeOrganizationItem ?? [
                                { value: '', displayName: '' },
                              ]
                            }
                            searchOption={{
                              targets: 'displayName',
                            }}
                            onChangeState={setRepresentativeOrganizationItem}
                          />
                          <div className="w-12">
                            <div className="side-search-icon">
                              <IconButton
                                name="search"
                                buttonType="basic"
                                className="button"
                                iconType={'search'}
                                onClick={() =>
                                  setOpenRepresentativeOrgSearchDialog(true)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-40">
                      <div className="input-line no-space">
                        <div className="item-group item-group-100 no-space">
                          <DataFilterbox
                            name="approvalOrganization"
                            labelId="ADMIN_ACCOUNT_INPUT.approvalOrganization"
                            columns={preset.columns}
                            data={approvalOrganizationFilterBoxItems}
                            value={
                              approvalOrganizationItem ?? [
                                { value: '', displayName: '' },
                              ]
                            }
                            searchOption={{
                              targets: 'displayName',
                            }}
                            onChangeState={setApprovalOrganizationItem}
                          />
                          <div className="w-12">
                            <div className="side-search-icon">
                              <IconButton
                                name="search"
                                buttonType="basic"
                                className="button"
                                iconType={'search'}
                                onClick={() =>
                                  setOpenApprovalOrgSearchDialog(true)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmationDialog
            isOpen={isOpenConfirmDialog}
            viewMessage={confirmDialogInfo.viewMessage}
            onDecision={confirmDialogInfo.onDecision}
            onCancel={() => {
              setOpenConfirmDialog(false);
            }}
          />
          <ConfirmationDialog
            isOpen={isOpenSaveDialog}
            viewMessage={viewMessageSave}
            onDecision={handleEmailCheck}
            onCancel={() => {
              setOpenSaveDialog(false);
            }}
          />
          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                <CaptionButton
                  buttonType="basic"
                  caption="保存"
                  name="savebtn"
                  onClick={() => {
                    // 入力エラーチェック
                    if (
                      includeInputValidateError(document, intl, [
                        {
                          value: email,
                          ref: requiredCodeArea,
                        },
                        {
                          value: accountName,
                          ref: requiredNameArea,
                        },
                      ])
                    ) {
                      return;
                    }
                    setOpenSaveDialog(true);
                  }}
                />
              </div>
            </div>
          </div>
          {/* 組織検索ダイアログ(代表所属組織) */}
          <OrganizationTreeSearchDialog
            isOpen={isOpenRepresentativeOrgSearchDialog}
            organizationList={organizations.current ?? []}
            checkedIds={representativeOrgCheckedIds}
            messageOption={{
              headerLabelId: {
                prefixId: 'DIALOG_TITLE',
                id: 'OrganizationTreeSearchDialog',
              },
            }}
            onCancel={() => setOpenRepresentativeOrgSearchDialog(false)}
            onDecision={(checks) => {
              if (checks.length > 1) {
                error([duplicated]);
                return;
              }
              setRepresentativeOrgCheckedIds(checks);
              setRepresentativeOrganizationItem(
                representativeOrganizationFilterBoxItems.filter(
                  (v) => checks && checks[0] === v.value
                )
              );
              setOpenRepresentativeOrgSearchDialog(false);
            }}
          />
          {/* 組織検索ダイアログ(代表承認組織) */}
          <OrganizationTreeSearchDialog
            isOpen={isOpenApprovalOrgSearchDialog}
            organizationList={organizations.current ?? []}
            checkedIds={approvalOrgCheckedIds}
            messageOption={{
              headerLabelId: {
                prefixId: 'DIALOG_TITLE',
                id: 'OrganizationTreeSearchDialog',
              },
            }}
            onCancel={() => setOpenApprovalOrgSearchDialog(false)}
            onDecision={(checks) => {
              if (checks.length > 1) {
                error([duplicated]);
                return;
              }
              setApprovalOrgCheckedIds(checks);
              setApprovalOrganizationItem(
                approvalOrganizationFilterBoxItems.filter(
                  (v) => checks && checks[0] === v.value
                )
              );
              setOpenApprovalOrgSearchDialog(false);
            }}
          />
          <Toast />
        </div>
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
