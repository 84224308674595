import {
  createContext,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { isBlueprintProcessing } from '../../utils';

type IBlueprintContent = mtechnavi.api.blueprint.IBlueprintContent;
type IBlueprintAttribute = mtechnavi.api.blueprint.IBlueprintAttribute;
type IBlueprintSegment = mtechnavi.api.blueprint.IBlueprintSegment;
type IBlueprintRevision = mtechnavi.api.blueprint.IBlueprintRevision;

type State = {
  /** 図面コンテンツ */
  content?: IBlueprintContent;
  /** 図面改訂履歴 */
  revisions: IBlueprintRevision[];
};

type ActionClear = {
  type: 'clear';
};

type ActionSetContent = {
  type: 'setContent';
  /** 新しい図面コンテンツ */
  content?: IBlueprintContent | null;
};

type ActionUpdateAttribute = {
  type: 'updateAttribute';
  /** 更新した図面属性 */
  attribute?: IBlueprintAttribute | null;
};

type ActionDeleteAttribute = {
  type: 'deleteAttribute';
  /** 削除した図面属性のID */
  blueprintAttributeId?: string | null;
};

type ActionSetAttributes = {
  type: 'setAttributes';
  /** 新しい図面属性のリスト */
  blueprintAttributes?: IBlueprintAttribute[] | null;
};

type ActionUpdateSegment = {
  type: 'updateSegment';
  /** 更新したセグメント情報 */
  blueprintSegment?: IBlueprintSegment | null;
};

type ActionSetRevisions = {
  type: 'setRevisions';
  /** 新しい改訂履歴のリスト */
  blueprintRevisions?: IBlueprintRevision[] | null;
};
type ActionAddRevision = {
  type: 'addRevision';
  /** 新しい改訂履歴を含む図面コンテンツ */
  content?: IBlueprintContent | null;
};
type ActionUpdateRevision = {
  type: 'updateRevision';
  /** 更新した改訂履歴 */
  blueprintRevision?: IBlueprintRevision | null;
};

type Actions =
  | ActionClear
  | ActionSetContent
  | ActionUpdateAttribute
  | ActionDeleteAttribute
  | ActionSetAttributes
  | ActionUpdateSegment
  | ActionSetRevisions
  | ActionAddRevision
  | ActionUpdateRevision;

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'clear':
      // データの初期化
      return { ...initialState };
    case 'setContent':
      // 図面コンテンツ全体のセット
      return {
        ...state,
        content: { ...action.content },
      };
    case 'updateAttribute': {
      // 抽出属性の更新
      const blueprintAttributes = (
        state.content?.blueprintAttributes ?? []
      ).map((item) =>
        item.blueprintAttributeId === action.attribute?.blueprintAttributeId
          ? { ...action.attribute }
          : item
      );
      return {
        ...state,
        content: {
          ...state.content,
          blueprintAttributes,
        },
      };
    }
    case 'deleteAttribute': {
      // 抽出属性の削除
      const blueprintAttributes = (
        state.content?.blueprintAttributes ?? []
      ).filter(
        (item) => item.blueprintAttributeId !== action.blueprintAttributeId
      );
      return {
        ...state,
        content: {
          ...state.content,
          blueprintAttributes,
        },
      };
    }
    case 'setAttributes':
      // 抽出属性リスト全体のセット
      return {
        ...state,
        content: {
          ...state.content,
          blueprintAttributes: [...(action.blueprintAttributes ?? [])],
        },
      };
    case 'updateSegment':
      // セグメント情報の更新
      return {
        ...state,
        content: {
          ...state.content,
          blueprintSegment: { ...action.blueprintSegment },
        },
      };
    case 'setRevisions':
      // 改訂履歴リスト全体のセット
      return {
        ...state,
        revisions: action.blueprintRevisions ?? [],
      };
    case 'addRevision': {
      // 改訂履歴の追加
      return {
        ...state,
        // 図面コンテンツを差し替える
        content: { ...action.content },
        // 改訂履歴リストの先頭に図面コンテンツの新しい改訂情報をセットする
        revisions: action.content?.blueprintRevision
          ? [action.content?.blueprintRevision, ...state.revisions]
          : [...state.revisions],
      };
    }
    case 'updateRevision': {
      // 改訂履歴の更新
      return {
        ...state,
        content: {
          ...state.content,
          // 図面コンテンツ内の改訂履歴を差し替える
          blueprintRevision: { ...action.blueprintRevision },
        },
        // 改訂履歴リスト中の該当履歴を差し替える
        revisions: (state.revisions ?? []).map((rev) =>
          rev.blueprintRevisionId ===
          action.blueprintRevision?.blueprintRevisionId
            ? { ...action.blueprintRevision }
            : rev
        ),
      };
    }
  }
};

const initialState: State = {
  revisions: [],
};

const Context = createContext<State>({
  ...initialState,
});
const DispatchContext = createContext<React.Dispatch<Actions>>(() => {});

/**
 * 閲覧中の図面コンテンツを管理するための Context Provider
 *
 * 部分更新するプロパティをいくつも持つため、図面コンテンツデータの状態管理を集約する。
 */
export const BlueprintConfirmationDataProvider = ({
  children,
}: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  return (
    <Context.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </Context.Provider>
  );
};

export const useBlueprintConfirmationData = () => {
  const { content: blueprintContent, revisions: blueprintRevisions } =
    useContext(Context);
  const dispatch = useContext(DispatchContext);
  const isProcessing = isBlueprintProcessing(blueprintContent);

  return {
    /** 管理中の図面コンテンツデータ */
    blueprintContent,
    /** 管理中の改訂履歴データ */
    blueprintRevisions,
    /** 現在管理中の図面コンテンツが抽出処理中かどうか */
    isProcessing,
    /** useReducer の dispatch */
    dispatch,
  };
};
