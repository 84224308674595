import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { sharelib } from '~/shared/libs/clientsdk';
import {
  FileExportRequestType,
  FileFormatName,
  FileImportRequestType,
  Property,
  fileExportApiCall,
  fileExportConfigMap,
  fileFormatApiCall,
  fileImportApiCall,
} from '~/shared/services';
import { FileItem, FileUploader } from '~/shared/components/file/FileUploader';
import {
  GetMessage,
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { error, success, warning } from '~/shared/components/parts/Toast/Toast';
import './ImportDialog.css';
import {
  getExceptionMessage,
  autoDownloadErrorFile,
  getPresetProperties,
  getProgramOptionFilterboxData,
} from '~/shared/utils';
import { CaptionButton } from '../Button';
import { DataFilterbox, DataFilterboxItem } from '../Filterbox';
export interface ImportDialogHandleFormat {
  name: FileFormatName;
  headerColumns: sharelib.IHeaderColumn[];
  searchCondition?: (ids: string[]) => Promise<string[]>;
}

export interface ImportDialogProps {
  isOpen: boolean;
  headerLabelId: MessageProps;
  allIds: string[];
  ids: string[];
  preset: Property[];
  /**
   * 非同期インポートかどうか
   */
  isAsyncImport?: boolean;
  /**
   * タイムアウト時間を外部から指定
   */
  sleepTime?: number;
  isFileTypeSelectBox?: boolean;
  isAllIdOnly?: boolean; // 全ページ出力しかしない制御(取込ファイル出力を「出力」ボタンのみとする)
  userMasterCategoryName?: string;
  handleExport: ImportDialogHandleFormat;
  handleImport: ImportDialogHandleFormat;
  onChangeState: (arg: boolean) => void;
  onHandleSuccess: (arg: boolean) => void;
  onChangeLoadingState: (arg: boolean) => void;
}

export function ImportDialog(props: ImportDialogProps) {
  const intl = useIntl();

  //ファイル種別セレクトボックス
  const FILE_TYPE_CATEGORY_NAME = props.userMasterCategoryName ?? '';
  const [fileType, setFileType] = useState<DataFilterboxItem[]>([]);
  const fileTypes = useMemo<DataFilterboxItem[]>(() => {
    const fileTypes = getProgramOptionFilterboxData(FILE_TYPE_CATEGORY_NAME);
    return fileTypes;

    // 初回時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  // オプショナルチェック
  const preset = props.preset ?? [
    { name: '', propertyName: '', propertyValue: '' },
  ];

  const [ids, setIds] = useState(props.ids);
  useEffect(() => {
    setIds(props.ids);
  }, [props.ids]);

  const [allIds, setAllIds] = useState(props.allIds);
  useEffect(() => {
    setAllIds(props.allIds);
  }, [props.allIds]);

  // 各種ボタン操作関係
  const [uploadFile, setUploadFile] = useState<FileItem[]>([]);
  const [isUploaded, setUploaded] = useState(false);

  // アップロード有無
  useEffect(() => {
    if (uploadFile.length === 0) {
      setUploaded(false);
    } else {
      setUploaded(true);
    }
  }, [uploadFile]);

  // ファイルアップロード処理（オブジェクトストレージのtmp領域）
  const handleUpload = (v: FileItem[]) => {
    if (!v.length) {
      return;
    }
    setUploadFile(v);
  };

  // 検査・取込処理
  const successMessage = GetMessage({ id: 'I0000001' });
  const errorMessage = GetMessage({
    id: 'E0000144',
    value: { $2: '取り込み', $1: 'CSV取り込み' },
  });

  // 選択したファイル種別をINameOption型にする
  const getFormat = (): sharelib.INameOption => {
    const format = window.App.services.ui.getNameOptionWithCode(
      FILE_TYPE_CATEGORY_NAME,
      fileType.length > 0 ? fileType[0].value : ''
    );
    return format;
  };

  const handleAutoDownload = async (ids: string[]) => {
    props.onChangeLoadingState(true);
    try {
      const formatNameOption = getFormat();
      const fileFormat = await fileFormatApiCall({
        fileType: props.handleExport.name,
        request: {
          systemCode: formatNameOption.systemName ?? '',
          intl: intl,
        },
      });

      if (props.handleExport.headerColumns.length > 0) {
        fileFormat!.headerColumns = props.handleExport.headerColumns;
      }

      if (props.isFileTypeSelectBox) {
        if (!fileType.length) {
          error([GetMessageWithIntl(intl, { id: 'E0000096' })]);
          return;
        }
        fileFormat.format = formatNameOption;
      }

      // 渡されたidから必要なidを取得する
      const searchCondition = props.handleExport.searchCondition ?? ((v) => v);
      ids = await searchCondition(ids);
      const fileExportApiParam: FileExportRequestType = {
        fileType: props.handleExport.name,
        systemCode: formatNameOption.systemName ?? '',
        intl: intl,
        request: {
          ids,
          fileFormat,
        },
      };
      await fileExportApiCall(fileExportApiParam);
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      props.onChangeLoadingState(false);
    }
  };

  // インポート処理
  const handleImportData = async (url: string, isDryRun: boolean) => {
    let result: any;
    setUploaded(false);
    props.onChangeLoadingState(true);
    try {
      const formatNameOption = getFormat();
      const fileFormat = await fileFormatApiCall({
        fileType: props.handleImport.name,
        request: {
          systemCode: formatNameOption.systemName ?? '',
          intl: intl,
        },
      });

      if (props.handleExport.headerColumns.length > 0) {
        fileFormat!.headerColumns = props.handleExport.headerColumns;
      }

      if (props.isFileTypeSelectBox) {
        if (!fileType.length) {
          error([GetMessageWithIntl(intl, { id: 'E0000096' })]);
          setUploaded(true);
          return;
        }
        fileFormat.format = getFormat();
      }
      const uploadRequest = {
        url,
        fileFormat: fileFormat,
        dryRun: isDryRun,
        exportError: true,
      };
      const fileImportApiParam: FileImportRequestType = {
        fileType: props.handleImport.name,
        systemCode: formatNameOption.systemName ?? '',
        intl: intl,
        request: uploadRequest,
      };
      result = await fileImportApiCall(fileImportApiParam);
    } catch (err) {
      // 例外エラーが発生した場合の処理
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      props.onChangeLoadingState(false);
    }
    if (result.errors?.length > 0) {
      const zeroErrors: sharelib.ImportError[] = result.errors.filter(
        (v: sharelib.ImportError) => v.rowNumber === 0
      );
      const nonZeroError: sharelib.ImportError = result.errors.find(
        (v: sharelib.ImportError) => v.rowNumber !== 0 && v.errorLevel !== 2
      );
      const warnings: sharelib.ImportError[] = result.errors.filter(
        (v: sharelib.ImportError) => v.rowNumber !== 0 && v.errorLevel === 2
      );

      zeroErrors.map(() => error([errorMessage])); // 該当するエラー分だけエラートーストを出す

      if (nonZeroError) {
        error([GetMessageWithIntl(intl, { id: 'E0000037' })]); // エラーファイルを確認するよう促すメッセージ
      }

      // エラーファイルのダウンロード
      if (nonZeroError || warnings.length) {
        // アップロードファイル名の拡張子部分を除いたものをファイル名とする
        const prefixMsg =
          fileExportConfigMap[props.handleImport.name]?.import?.prefixMsg ?? '';
        autoDownloadErrorFile(
          GetMessageWithIntl(intl, {
            prefixId: prefixMsg,
          }),
          'csv',
          result.assetId
        );
      }

      // エラーがある場合は処理終了
      if (zeroErrors.length || nonZeroError) {
        return;
      }

      // エラーがない場合は警告が存在すれば警告トースト
      if (warnings.length) {
        warning([GetMessageWithIntl(intl, { id: 'W0000001' })]);
      }
    }

    if (!props.isAsyncImport) {
      success([successMessage]);
    }
    const sleepTime = props.sleepTime ?? 1500;
    // 取込完了後は自動でダイアログを閉じる
    if (!isDryRun || props.isAsyncImport) {
      setTimeout(() => {
        if (!props.isAsyncImport) {
          // 非同期インポートでない場合は自発的に閉じる
          closeDialog();
        }
        props.onHandleSuccess(true); //成功時に呼び出し画面に連携
      }, sleepTime);
    } else {
      setUploaded(true);
    }
  };

  // ダイアログ関係
  const [dialogIsOpen, setDialogIsOpen] = useState(props.isOpen);
  useEffect(() => {
    setDialogIsOpen(props.isOpen);
  }, [props.isOpen]);

  // ダイアログを閉じる処理
  const closeDialog = () => {
    // 各種情報をクリア
    setUploadFile([]);
    setUploaded(false);
    props.onChangeState(false);
    props.onChangeLoadingState(false);
    setDialogIsOpen(false);
    setFileType([]);
  };

  const view = (
    <>
      <div className="ImportDialog">
        {/* 取込ファイル出力 */}
        {props.isFileTypeSelectBox && (
          <div className="group">
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-40">
                  <DataFilterbox
                    name="fileType"
                    labelId="ImportDialog.fileType"
                    data={fileTypes}
                    value={fileType}
                    onChangeState={setFileType}
                    columns={['fileType']}
                  ></DataFilterbox>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="group">
          <div className="label">取込ファイル出力</div>
          <div className="inputfields action-btns">
            {!props.isAllIdOnly && (
              <div>
                <CaptionButton
                  name="onlySelected"
                  caption="選択行のみ"
                  properties={getPresetProperties('onlySelected', preset)}
                  onClick={() => {
                    handleAutoDownload(ids);
                  }}
                  buttonType="basic"
                />
              </div>
            )}
            <div>
              <CaptionButton
                name="allItem"
                caption={props.isAllIdOnly ? '出力' : '全ページ'}
                properties={getPresetProperties('import', preset)}
                onClick={() => {
                  handleAutoDownload(allIds);
                }}
                buttonType="basic"
              />
            </div>
          </div>
        </div>
        {/* ファイルアップロードエリア */}
        <div className="group">
          <div className="label">取込ファイルアップロード</div>
          <div className="inputfields file-uploader">
            <div className="field-block long fileupload-button-margin">
              <FileUploader
                name="fileUploader"
                multiple={false}
                onUpload={handleUpload}
                onChangeLoadingState={props.onChangeLoadingState}
              />
            </div>
          </div>
        </div>
        {/* 処理ボタンエリア */}
        <div className="group">
          <div className="label">処理実行</div>
          <div className="inputfields action-btns">
            <div>
              <CaptionButton
                name="inspection"
                caption="検査のみ"
                properties={getPresetProperties('inspection', preset)}
                onClick={() => {
                  handleImportData(uploadFile[0].url ?? '', true);
                }}
                buttonType="basic"
                disabled={!isUploaded}
              />
            </div>
            <div>
              <CaptionButton
                name="import"
                caption="取込"
                properties={getPresetProperties('import', preset)}
                onClick={() => {
                  handleImportData(uploadFile[0].url ?? '', false);
                }}
                buttonType="basic"
                disabled={!isUploaded}
              />
            </div>
            <div className="right">
              <CaptionButton
                name="cancel"
                caption="キャンセル"
                properties={getPresetProperties('import', preset)}
                onClick={closeDialog}
                buttonType="cancel"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: closeDialog,
    send: () => {},
    modalIsOpen: dialogIsOpen,
    comment: '',
    headerLabelId: props.headerLabelId,
    messageLabelId: props.headerLabelId,
    elements: view,
    className: 'import-dialog',
  };

  return <ModalDialogComponent {...openModalProps} />;
}
