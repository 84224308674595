import React, { CSSProperties, useEffect, useRef } from 'react';
import { useRenderer } from './renderer';
import { realScaleShape, ViewerOverlayProperties } from './util';
import './CroppedViewer.css';
import { PartialLoading } from '../PartialLoading';

interface CroppedViewerProps {
  assetId: string;
  cropInfo?: ViewerOverlayProperties;
  controls?: (JSX.Element | null) | (JSX.Element | null)[];
  wrapperStyle?: CSSProperties;
  imageStyle?: CSSProperties;
}

/**
 * アセットIDと座標情報を元に、画像の一部を切り出して表示するコンポーネント
 */
export const CroppedViewer = ({
  assetId,
  cropInfo,
  controls,
  wrapperStyle,
  imageStyle,
}: CroppedViewerProps) => {
  const srcURL = `/mtechnavi.api.assetinventory.AssetProxy/${assetId}`;
  const [state, render] = useRenderer(srcURL);
  const isReady = state.status !== 'loading';

  const srcCanvasRef = useRef<HTMLCanvasElement>(null);
  const viewCanvasRef = useRef<HTMLCanvasElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isReady) {
      return;
    }
    if (!srcCanvasRef.current) {
      return;
    }

    const srcEl = document.createElement('canvas');
    const srcCtx = srcEl.getContext('2d');
    if (!srcCtx) {
      throw new Error('unsupported browser');
    }

    const viewEl = viewCanvasRef.current!;
    const viewCtx = viewEl.getContext('2d');
    if (!viewCtx) {
      throw new Error('unsupported browser');
    }

    if (!cropInfo) {
      viewCtx.clearRect(0, 0, viewEl.width, viewEl.height);
      return;
    }

    (async () => {
      await render(srcCtx, 1);

      const realScale = realScaleShape(cropInfo, {
        width: srcCtx.canvas.width,
        height: srcCtx.canvas.height,
      });

      viewEl.width = Math.abs(realScale.x2 - realScale.x1);
      viewEl.height = Math.abs(realScale.y2 - realScale.y1);

      viewCtx.drawImage(
        srcEl,
        realScale.x1 < realScale.x2 ? realScale.x1 : realScale.x2,
        realScale.y1 < realScale.y2 ? realScale.y1 : realScale.y2,
        viewEl.width,
        viewEl.height,
        0,
        0,
        viewEl.width,
        viewEl.height
      );
    })();
  }, [isReady, cropInfo, srcCanvasRef, render]);

  const renderControls = () => {
    if (controls instanceof Array) {
      return <>{controls}</>;
    }
    return controls && <>{controls}</>;
  };

  return (
    <div className="CroppedViewer">
      <div className="view-area" ref={wrapperRef} style={wrapperStyle}>
        <div className="viewer-control-area">{renderControls()}</div>
        <canvas className="src" ref={srcCanvasRef} />
        <canvas className="document" ref={viewCanvasRef} style={imageStyle} />
      </div>
      <PartialLoading isShow={!isReady} />
    </div>
  );
};
