import React, { PropsWithChildren } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import { BlueprintSearchResultItem } from './BlueprintSearchResultItem';
import { ViewId } from '~/shared/utils';
import { GetMessageWithIntl } from '~/shared/components';
import { useIntl } from 'react-intl';
import { mtechnavi } from '~/shared/libs/clientsdk';

interface BlueprintSearchResultListProps {
  viewId: ViewId;
  data: mtechnavi.api.blueprint.ISearchBlueprintContent[];
  height?: number | string;
  hasNextData?: boolean;
  onReadNext?: () => Promise<void> | void;
}
/**
 * 図面検索結果リスト
 */
export const BlueprintSearchResultList = ({
  viewId,
  data,
  height,
  hasNextData,
  onReadNext,
}: PropsWithChildren<BlueprintSearchResultListProps>) => {
  const intl = useIntl();

  const handleReadNext = async () => {
    onReadNext && (await onReadNext());
  };

  return (
    <section className="BlueprintSearchResultList">
      <div className="list-body" style={{ height }}>
        <div className="list-contents">
          <div className="items">
            {data.map((item, index) => (
              <BlueprintSearchResultItem
                viewId={viewId}
                key={index}
                data={item}
              />
            ))}
          </div>
          {hasNextData && (
            <CaptionButton
              name="readNext"
              buttonType="basic"
              onClick={handleReadNext}
              caption={GetMessageWithIntl(intl, { viewId, id: 'readNext' })}
            />
          )}
        </div>
      </div>
    </section>
  );
};
