import { useState } from 'react';
import { useIntl } from 'react-intl';
import { error } from '~/shared/components';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import {
  DataViewType,
  SelectPlanDialog,
} from '~/shared/components/ui/Dialog/SelectPlanDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { getDateFormat, getWorkerExceptionMessage } from '~/shared/utils';

// APIサービスが存在しないため、Dummyで対応
const dummyData: DataViewType[] = [
  {
    id: '12345',
    trackingCode: '12345',
    desiredReceiveDt: '2024/4/1',
    displayName: 'Dummy1',
    modelNumber: 'model123',
    quantityNumber: '12',
  },
  {
    id: '12346',
    trackingCode: '12346',
    desiredReceiveDt: '2024/4/1',
    displayName: 'Dummy1',
    modelNumber: 'model123',
    quantityNumber: '12',
  },
  {
    id: '12347',
    trackingCode: '12347',
    desiredReceiveDt: '2024/4/1',
    displayName: 'Dummy1',
    modelNumber: 'model123',
    quantityNumber: '12',
  },
];
export const UISampleSelectPlanDialogs = () => {
  const intl = useIntl();
  const [result, setResult] = useState('');
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [viewData, setViewData] = useState<DataViewType[]>(dummyData);
  const LIST_ESTIMATION_ORDER_FULL_METHOD_NAME =
    'mtechnavi.api.estimation.EstimateSenderService/ListEstimateRequestPlans';

  const createData = async () => {
    try {
      const listRes = (await window.App.services.ui.worker.filter({
        action: 'reload',
        fullMethodName: LIST_ESTIMATION_ORDER_FULL_METHOD_NAME,
        filter: {},
        sort: [],
      })) as mtechnavi.api.estimation.IListEstimateRequestPlansResponse;
      const listItems = listRes?.items;
      const viewData: DataViewType[] = [];
      if (!listItems || listItems.length === 0) {
        setViewData(dummyData);
        return;
      }
      listItems.map((item) => {
        const pusuItem: DataViewType = {
          id: item.estimateRequestPlanId ?? '',
          trackingCode: item.sapWorkOrder?.trackingCode ?? '',
          desiredReceiveDt: getDateFormat(
            item.sapWorkOrder?.desiredReceiveDt?.timestamp ?? '',
            'YYYY/MM/DD'
          ),
          displayName: item.displayNameLang?.ja ?? '',
          modelNumber: item.sapWorkOrder?.freeItem ?? '',
          quantityNumber: item.sapWorkOrder?.quantity?.amount ?? '',
        };
        viewData.push(pusuItem);
      });
      setViewData(viewData);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    }
  };

  return (
    <div className="categories">
      <h3 id="dialog">予定選択ダイアログ</h3>
      <h4>予定選択ダイアログ</h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                createData();
                setOpenDialog(true);
              }}
            />
            <SelectPlanDialog
              isOpen={isOpenDialog}
              data={viewData}
              onDecision={(v: string) => {
                setResult(v);
                setOpenDialog(false);
              }}
              onCancel={() => {
                setResult('キャンセルしました');
                setOpenDialog(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
