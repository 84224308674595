import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  MessageProps,
  ModalDialogComponent,
  ModalDialogComponentProps,
  Radio,
  RadioItem,
} from '~/shared/components';
import {
  DataFilterbox,
  DataFilterboxItem,
  FilterboxItem,
  SimpleListView,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { formatUserWIthEmail } from '~/shared/utils';
import './OrganizationMemberDialog.css';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import Long from 'long';

const prefixId = 'OrganizationMemberDialog';

export type OrganizationMemberDialogMode = 'edit' | 'show';

export interface OrganizationListMember {
  userId?: string | null;
  level?: Long.Long | null;
  levelName?: string;
  displayName?: string | null;
  email?: string | null;
}
interface OrganizationMemberDialogInputOption {
  organizations?: mtechnavi.api.company.IOrganization[]; // ルートから対象までの組織階層
  memberUserList?: mtechnavi.api.company.IOrganizationRelation[];
}
interface OrganizationMemberDialogMessageOption {
  headerLabelId: MessageProps;
}
export interface OrganizationMemberDialogProps {
  isOpen: boolean;
  mode: OrganizationMemberDialogMode;
  isProcurement: boolean;
  inputOption: OrganizationMemberDialogInputOption;
  messageOption: OrganizationMemberDialogMessageOption;
  onDecision: (result: mtechnavi.api.company.IOrganizationRelation[]) => void;
  onCancel: () => void;
}

export const OrganizationMemberDialog = ({
  isOpen,
  mode,
  isProcurement,
  inputOption,
  messageOption,
  onDecision,
  onCancel,
}: OrganizationMemberDialogProps) => {
  const intl = useIntl();
  const { showLoading, hideLoading } = useLoading();
  const organizationId = [...(inputOption?.organizations ?? [])].pop()
    ?.organizationId;
  const isEditMode = mode === 'edit';

  const [procurementOptions, initialLevel] = useMemo<
    [sharelib.INameOption[], string]
  >(() => {
    const procurementOptions = window.App.services.ui.getNameOption('A4000007');
    return [procurementOptions, procurementOptions.at(0)?.code ?? ''];
    // 初期起動時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ユーザーマスタ
  const [userList, setUserList] =
    useState<mtechnavi.api.tenantadmin.IUserAttribute[]>();

  const [level, setLevel] = useState(initialLevel);
  const [selectedUserItems, setSelectedUserItems] = useState<
    DataFilterboxItem[]
  >([]);

  const [memberUserList, setMemberUserList] = useState(
    inputOption.memberUserList || []
  );
  const originMemberUserList = useRef<
    mtechnavi.api.company.IOrganizationRelation[]
  >(inputOption.memberUserList || []);

  const filterItemList = useMemo(() => {
    if (!userList) {
      return [];
    }
    return userList.map(
      (v): FilterboxItem => ({
        displayName: formatUserWIthEmail(v.user?.displayName, v.user?.email),
        value: v.user?.userId ?? '',
      })
    );
  }, [userList]);

  // リスト表示用のユーザーデータ
  const memberUserViewList: OrganizationListMember[] = useMemo(() => {
    if (!memberUserList) {
      return [];
    }
    return memberUserList
      .map((v): OrganizationListMember => {
        const member = userList?.find((user) => user.user?.userId === v.userId);
        return {
          userId: v.userId ?? '',
          level: v.level,
          levelName:
            procurementOptions.find(
              (w) => v.level && w.code === Long.fromValue(v.level).toString()
            )?.displayNameLang?.ja ?? '',
          displayName: member?.user?.displayName,
          email: member?.user?.email,
        };
      })
      .sort((a, b) => {
        const aLevel = Long.fromValue(a.level ?? 0)?.toNumber();
        const bLevel = Long.fromValue(b.level ?? 0)?.toNumber();
        if (aLevel === bLevel) {
          return (a.email ?? '') > (b.email ?? '') ? 1 : -1;
        }
        return aLevel > bLevel ? 1 : -1;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberUserList, userList]);

  useEffect(() => {
    showLoading();
    (async () => {
      const listRes = await window.App.services.ui.listUserAttributes();

      setUserList(listRes);
      hideLoading();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMemberUserList(inputOption.memberUserList || []);
    originMemberUserList.current = inputOption.memberUserList || [];
  }, [inputOption.memberUserList]);

  const handleDecision = () => {
    const decisionList = memberUserList.map((user) => {
      const updateUser = originMemberUserList.current.find(
        (originUser) => originUser.userId === user.userId
      );
      if (!updateUser) {
        return user;
      }
      return {
        ...updateUser,
        organizationId: user.organizationId,
        level: user.level,
      };
    });
    onDecision(decisionList);
    setLevel(initialLevel);
    setSelectedUserItems([]);
  };

  const handleCancel = () => {
    onCancel();
    setLevel(initialLevel);
    setSelectedUserItems([]);
  };

  const handleAdd = () => {
    const selectedUserIds = (selectedUserItems || [])?.map(
      (user) => user.value
    );
    const selectedUsers = (userList ?? []).filter((user) =>
      selectedUserIds?.includes(user.user?.userId ?? '')
    );
    setMemberUserList([
      ...memberUserList.filter(
        (member) => !selectedUserIds.includes(member.userId ?? '')
      ),
      ...selectedUsers.map(
        (user): mtechnavi.api.company.IOrganizationRelation => ({
          organizationId,
          userId: user.user?.userId,
          level: isProcurement ? Long.fromString(level) : null,
        })
      ),
    ]);
    setSelectedUserItems([]);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDelete = (data: any) => {
    setMemberUserList(
      memberUserList.filter((item) => item.userId !== data.userId)
    );
  };

  const procurementOptionItems = (): RadioItem[] => {
    return procurementOptions.map((procurementOption) => ({
      value: procurementOption.code ?? '',
      displayName: procurementOption.displayNameLang?.ja ?? '',
    }));
  };

  const view = (
    <div className="OrganizationMemberDialog">
      <div className="detail-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="parents-breadcrumb">
                {(!inputOption.organizations ||
                  inputOption.organizations.length <= 1) && <span></span>}
                {(inputOption.organizations || []).map((org) => (
                  <span key={org.organizationId}>{org.displayName}</span>
                ))}
              </div>
            </div>
          </div>
        </div>
        {isEditMode && (
          <>
            {isProcurement && (
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-100">
                    <Radio
                      name="procurementOption"
                      items={procurementOptionItems()}
                      value={level}
                      onChangeState={setLevel}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-60">
                  <DataFilterbox
                    data={filterItemList}
                    name="account"
                    labelId="OrganizationMemberDialog.account"
                    columns={['account']}
                    searchOption={{ targets: 'displayName' }}
                    onChangeState={setSelectedUserItems}
                    value={selectedUserItems}
                  />
                </div>
                <div className="w-40 add-button">
                  <CaptionButton
                    name="add"
                    buttonType="basic"
                    className="button"
                    caption={GetMessageWithIntl(intl, { id: 'add' })}
                    onClick={() => {
                      handleAdd();
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <SimpleListView
                data={memberUserViewList}
                viewOptions={{
                  previewRowCount: 5,
                  keyColumn: 'userId',
                  columns: [
                    ...(isProcurement
                      ? [
                          {
                            propertyName: 'levelName',
                            header: { prefixId, id: 'level' },
                          },
                        ]
                      : []),
                    {
                      propertyName: 'displayName',
                      header: { prefixId, id: 'displayName' },
                    },
                    {
                      propertyName: 'email',
                      header: { prefixId, id: 'email' },
                    },
                  ],
                }}
                actionOptions={{
                  onDelete: isEditMode ? handleDelete : undefined,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={handleCancel}
        />
        {isEditMode && (
          <CaptionButton
            name="sendBtn"
            buttonType="basic"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'save' })}
            onClick={handleDecision}
          />
        )}
      </div>
    </div>
  );

  const modalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements: view,
  };

  return (
    <>
      <div className="OrganizationMemberDialog">
        <ModalDialogComponent {...modalProps} />
      </div>
    </>
  );
};
