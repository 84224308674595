import Long from 'long';
import { IntlShape } from 'react-intl';
import { GetMessageWithIntl } from '~/shared/components';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { getLocalStorageKey, ViewId } from '~/shared/utils';
import { BlueprintSegmentNames } from '../../utils';

export const BLUEPRINT_SEARCH_LIMIT = 1000;

const CHARACTER_TARGET_ATTRIBUTES = ['メッキコード', '品番', '仕様書', '客先'];

/** 検索条件入力値の型定義 */
export interface BlueprintSearchCondition {
  /** 図面ファイル */
  file?: {
    /** ファイル名 */
    filename?: string | null;
    /** 画像情報 */
    temporaryImage?: mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.ICreatedImage | null;
    /** セグメント情報 */
    segments?: mtechnavi.api.blueprint.ITemporarySegmentProperty[] | null;
  };
  /** 形状 */
  shape?: {
    /** 上位n */
    topX?: string;
    /**
     * 対象の面
     * チェックありのもののキーをセット
     */
    classNames?: BlueprintSegmentNames[];
  };
  /** キーワード */
  keyword?: {
    text?: string;
    matchType?: 'and' | 'or';
  };
  /** 数量 */
  amount?: {
    leadNumber?: SizeCondition;
  };
  /** サイズ */
  size?: {
    unit?: 'mm' | 'inch';
    outerX?: SizeCondition;
    outerY?: SizeCondition;
    topCV?: SizeCondition;
    secondCV?: SizeCondition;
    da?: SizeCondition;
    boardWarp?: SizeCondition;
    srWarp?: SizeCondition;
    daWarp?: SizeCondition;
  };
  /**
   * 表示属性
   * 順序をそのまま並び順とする
   */
  attribute?: string[];
}
interface SizeCondition {
  value?: string;
  tolerance?: Tolerance;
}
interface Tolerance {
  upper?: string;
  lower?: string;
}
type SizeKeyLabels = keyof Omit<
  Required<BlueprintSearchCondition>['size'],
  'unit'
>;
const sizeKeyMap: {
  [key in SizeKeyLabels]: string;
} = {
  outerX: '外径X(mm)',
  outerY: '外径Y(mm)',
  topCV: 'Top CV(mm)',
  secondCV: '2nd CV(mm)',
  da: 'D/A(mm)',
  boardWarp: '基板 反り(mm)',
  srWarp: 'S/R 反り(mm)',
  daWarp: 'D/A 反り(mm)',
};

/** フィルタ条件 */
export interface BlueprintSearchFilter {
  keyword?: string;
}
/** 並び順 */
export type BlueprintSearchSort = BlueprintSearchSortFragment[];
type BlueprintSearchSortFragment = {
  [key in BlueprintSearchSortKey]?: 'asc' | 'desc';
};
type BlueprintSearchSortKey =
  | 'distanceFront'
  | 'distanceRight'
  | 'distanceBottom'
  | 'distanceAverage';

/**
 * 検索条件入力値をラベルに変換する
 */
export const conditionsToLabels = (
  intl: IntlShape,
  viewId: ViewId,
  condition: BlueprintSearchCondition
): string[] => {
  const result: string[] = [];
  if (!condition) {
    return result;
  }

  const { amount, size, shape, keyword, file } = condition;

  // 形状
  if (file?.segments?.length && shape?.topX && shape?.classNames?.length) {
    result.push(
      ...(shape.classNames ?? []).map((name) =>
        GetMessageWithIntl(intl, { viewId, id: `image.shape.${name}` })
      )
    );
    result.push(
      `${GetMessageWithIntl(intl, { viewId, id: 'image.shape.topLevel' })}${
        shape.topX
      }`
    );
  }
  // キーワード
  if (keyword && keyword.text) {
    const matchTypeLabel = GetMessageWithIntl(intl, {
      viewId,
      id: `detail.keyword.${keyword.matchType}`,
    });
    result.push(
      GetMessageWithIntl(intl, {
        viewId,
        id: 'conditionKeywordFormat',
        value: {
          $1: matchTypeLabel,
          $2: keyword.text,
        },
      })
    );
  }
  // 数量
  if (amount) {
    Object.entries(amount).forEach(([k, v]) => {
      if (!v || !v.value) {
        return;
      }
      result.push(
        amountConditionToLabel({
          intl,
          viewId,
          labelId: `detail.amount.${k}`,
          condition: v,
        })
      );
    });
  }
  // サイズ
  if (size) {
    const { unit } = size;
    Object.entries(size).forEach(([k, v]) => {
      if (v === 'mm' || v === 'inch' || !v.value) {
        return;
      }
      result.push(
        sizeConditionToLabel({
          intl,
          viewId,
          labelId: `detail.size.${k}`,
          condition: v,
          unit,
        })
      );
    });
  }
  return result;
};

/** 数量条件を表示向けにフォーマットする */
const amountConditionToLabel = (params: {
  intl: IntlShape;
  viewId: ViewId;
  labelId: string;
  condition?: SizeCondition;
}) => {
  const { intl, viewId, labelId, condition } = params;
  return GetMessageWithIntl(intl, {
    viewId,
    id: 'conditionToleranceAmountFormat',
    value: {
      $1: GetMessageWithIntl(intl, { viewId, id: labelId }),
      $2: condition?.value || 0,
      $3: condition?.tolerance?.lower || 0,
      $4: condition?.tolerance?.upper || 0,
    },
  });
};

/** サイズ条件を表示向けにフォーマットする */
const sizeConditionToLabel = (params: {
  intl: IntlShape;
  viewId: ViewId;
  labelId: string;
  condition?: SizeCondition;
  unit?: string;
}) => {
  const { intl, viewId, labelId, condition, unit } = params;
  return GetMessageWithIntl(intl, {
    viewId,
    id: 'conditionToleranceSizeFormat',
    value: {
      $1: GetMessageWithIntl(intl, { viewId, id: labelId }),
      $2: unit ?? '',
      $3: condition?.value || 0,
      $4: condition?.tolerance?.lower || 0,
      $5: condition?.tolerance?.upper || 0,
    },
  });
};

/**
 * ユーザー入力のキーワードを正規表現に変換する
 */
export const keywordToRegExp = (keyword?: string) => {
  if (!keyword) {
    return;
  }
  // 入力文字列の `%` を「0以上の長さのワイルドカード指定」として置き換えた正規表現とする
  return new RegExp(
    `^${keyword
      .trim() // 前後の空白は取り除く
      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // 入力文字は文字列とみなすためにエスケープする 参考：https://developer.mozilla.org/ja/docs/Web/JavaScript/Guide/Regular_expressions#%E3%82%A8%E3%82%B9%E3%82%B1%E3%83%BC%E3%83%97
      .replaceAll('%', '.*')}$`
  );
};

/**
 * 検索条件入力値をエンドポイントのリクエスト型に変換する
 *
 * inch / mm の換算などもここで行う
 */
export const conditionsToRequest = (
  condition: BlueprintSearchCondition
): mtechnavi.api.blueprint.ISearchBlueprintsRequest => {
  const { unit } = condition.size ?? {};

  // 数量・サイズ
  const numericRangeConditions: mtechnavi.api.blueprint.SearchBlueprintsRequest.INumericRangeCondition[] =
    [];
  const leadNumber = convertToRangeCondition(
    'リード数',
    condition.amount?.leadNumber
  );
  leadNumber && numericRangeConditions.push(leadNumber);

  const sizeConditions = Object.entries(condition.size ?? {});
  numericRangeConditions.push(
    ...(sizeConditions
      .map(([key, condition]) => {
        if (typeof condition === 'string') {
          return;
        }
        return convertToRangeCondition(
          sizeKeyMap[key as SizeKeyLabels],
          condition,
          unit
        );
      })
      .filter(
        (condition) => !!condition
      ) as mtechnavi.api.blueprint.SearchBlueprintsRequest.INumericRangeCondition[])
  );

  // 面・セグメント・上位n の全てが揃わないとリクエストに乗せない
  const hasImageCondition =
    condition.file?.segments?.length &&
    condition?.shape?.topX &&
    condition?.shape?.classNames?.length;

  return {
    dataFilter: {
      // セグメント
      temporarySegmentPropertys: hasImageCondition
        ? condition.file?.segments
        : null,
      // 形状
      classTopX:
        hasImageCondition && condition?.shape?.topX
          ? Long.fromString(condition?.shape?.topX)
          : null,
      pClassNames: hasImageCondition
        ? condition?.shape?.classNames ?? []
        : null,
      // 詳細検索 - キーワード
      characterKeywords: condition.keyword?.text
        ? condition.keyword?.text?.split(' ')
        : null,
      characterAndSearch:
        condition.keyword?.text && condition.keyword?.matchType
          ? condition.keyword?.matchType === 'and'
          : null,
      characterTargetAttributes: condition.keyword?.text
        ? CHARACTER_TARGET_ATTRIBUTES
        : null,
      // 詳細検索 - 数量・サイズ
      numericRangeConditions:
        numericRangeConditions.length > 0 ? numericRangeConditions : null,
    },
    displayAttributes: condition.attribute,
  };
};

/** サイズ・数値条件の変換 */
const convertToRangeCondition = (
  attribute: string,
  condition?: SizeCondition,
  unit?: 'mm' | 'inch'
): mtechnavi.api.blueprint.SearchBlueprintsRequest.INumericRangeCondition | null => {
  if (!condition || !condition.value) {
    return null;
  }
  const coefficient = (unit === 'inch' ? 25.4 : 1) * 10; // 誤差を回避するため 10 倍して扱う
  const value = Number(condition.value || 0);
  const lower = Number(condition.tolerance?.lower || 0);
  const upper = Number(condition.tolerance?.upper || 0);
  return {
    attribute,
    from: (((value - lower) * coefficient) / 10).toString(),
    to: (((value + upper) * coefficient) / 10).toString(),
  };
};

// 検索条件とDataViewの情報（絞り込み条件）を localStorage に格納
export const saveBlueprintSearchState = (
  viewId: ViewId,
  data: BlueprintSearchCondition,
  myEmail: string
) => {
  const viewIdWithEmail = getLocalStorageKey(viewId, myEmail);
  localStorage.setItem(viewIdWithEmail, JSON.stringify(data));
};

// 検索条件とDataViewの情報（絞り込み条件）を localStorage から復元
export const getBlueprintSearchState = (
  sourceViewId: ViewId,
  myEmail: string
): BlueprintSearchCondition | null => {
  if (!sourceViewId) {
    return null;
  }
  const viewIdWithEmail = getLocalStorageKey(sourceViewId, myEmail);
  const item = localStorage.getItem(viewIdWithEmail);
  if (!item) {
    return null;
  }
  return JSON.parse(item) ?? null;
};
