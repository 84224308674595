import { useState } from 'react';
import { CaptionButton } from '~/shared/components/ui/Button';
import { BlueprintFileUploadDialog } from '~/shared/components/ui/Dialog/BlueprintFileUploadDialog';
import { useLoading } from '~/shared/contexts/LoadingProvider';

export const UISampleBlueprintFileUploadDialogs = () => {
  const [result, setResult] = useState('');
  const [isShow, setShow] = useState(false);
  const { showLoading, hideLoading } = useLoading();

  const handleLoading = (isLoading: boolean) => {
    isLoading ? showLoading() : hideLoading();
  };

  return (
    <div className="categories">
      <h3 id="dialog">図面ファイル取込ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setShow(true);
              }}
            />
            <BlueprintFileUploadDialog
              key={isShow ? 1 : 0}
              isOpen={isShow}
              displayOption={{ isDnd: true }}
              messageOption={{
                dialogTitle: {
                  prefixId: 'DIALOG_TITLE',
                  id: 'blueprintFileUpload',
                },
                buttonType: {
                  id: 'import',
                },
                simpleListViewHeader: {
                  prefixId: 'blueprintFileUploadDialog',
                  id: 'blueprintNumber',
                },
              }}
              onDecision={(result) => {
                setResult(JSON.stringify(result, null, 2));
                setShow(false);
              }}
              onCancel={() => {
                setResult('キャンセル');
                setShow(false);
              }}
              uploaderOption={{
                validateOption: { allowedFileExtensions: ['pdf'] },
              }}
              onChangeLoadingState={(v) => {
                handleLoading(v);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
