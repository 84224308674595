import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_CreateBlueprintRevision } from 'worker/fullMethodName/crud';

export const createBlueprintRevision = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.blueprint.IBlueprintRevision
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.blueprint.ICreateBlueprintRevisionRequest,
    mtechnavi.api.blueprint.IBlueprintContent
  >(FullMethodName_CreateBlueprintRevision, {
    blueprintRevision: request,
  });
};
