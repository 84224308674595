import { useEffect, useRef, useState } from 'react';
import { Radio } from '~/shared/components';
import { GetMessage } from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { MessageProps } from '../..';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import './base.css';
import { useIntl } from 'react-intl';

/** 調査依頼出力情報種別 */
export const OutputInfoType = {
  RequestManagementInfo: 'requestManagementInfo',
  Answers: 'answers',
  AllAnswers: 'allAnswers',
} as const;

/** 権限グループ出力種別 */
export const UserGroupOutputInfoType = {
  /** ロール出力 */
  Role: 'role',
  /** メニュー画面定義出力 */
  Menu: 'menu',
} as const;

/** 出力対象 */
export const OutputVolumeType = {
  /** 選択行のみ */
  SelectedOnly: 'selectedOnly',
  /** 全ページ */
  All: 'all',
} as const;
export type OutputVolume =
  typeof OutputVolumeType[keyof typeof OutputVolumeType];

interface PageTypeCase {
  initialRadioValue: string | null;
  generateItems: RadioOption[] | null;
}

export type pageType =
  | 'none'
  | 'supplierBase'
  | 'workTask'
  | 'form'
  | 'estimate'
  | 'userGroup';

type PageTypeCaseMapping = { [key in pageType]?: PageTypeCase };

export interface OutputDialogOutput {
  outputInfoType: string;
  outputVolume: OutputVolume;
}

interface OutputMessageOption {
  headerLabelId: MessageProps;
}

export interface OutputDialogProps {
  type?: pageType;
  isOpen: boolean;
  messageOption?: OutputMessageOption;
  onDecision: (v: OutputDialogOutput) => void;
  onCancel: () => void;
}

interface RadioOption {
  value: string;
  displayName: string;
}

export const OutputDialog = (props: OutputDialogProps) => {
  const pageTypeCases: PageTypeCaseMapping = {
    none: { initialRadioValue: '', generateItems: [] },
    supplierBase: {
      initialRadioValue: OutputInfoType.RequestManagementInfo,
      generateItems: Object.values(OutputInfoType).map((item) => ({
        value: item,
        displayName: GetMessage({
          id: `radio_${item}`,
          prefixId: 'OutputDialog',
        }),
      })),
    },
    workTask: {
      initialRadioValue: 'workTaskManagementInfo',
      generateItems: [
        {
          value: 'workTaskManagementInfo',
          displayName: GetMessage({
            id: 'radio_workTaskManagementInfo',
            prefixId: 'OutputDialog',
          }),
        },
      ],
    },
    form: {
      initialRadioValue: 'formInfo',
      generateItems: [
        {
          value: 'formInfo',
          displayName: GetMessage({
            id: 'radio_formInfo',
            prefixId: 'OutputDialog',
          }),
        },
      ],
    },
    estimate: {
      initialRadioValue: 'estimateOrder',
      generateItems: [
        {
          value: 'estimateOrders',
          displayName: GetMessage({
            id: 'radio_estimateOrders',
            prefixId: 'OutputDialog',
          }),
        },
      ],
    },
    userGroup: {
      initialRadioValue: UserGroupOutputInfoType.Role,
      generateItems: Object.values(UserGroupOutputInfoType).map((item) => ({
        value: item,
        displayName: GetMessage({
          id: `radio_${item}`,
          prefixId: 'OutputDialog',
        }),
      })),
    },
  };
  const intl = useIntl();
  const [radio, setRadio] = useState('');

  useEffect(() => {
    if (props.isOpen) {
      const pageTypeCase = pageTypeCases[props.type ?? 'none'];
      setRadio(pageTypeCase?.initialRadioValue ?? '');
    }
  }, [props.isOpen, intl, props.type]);

  const radioItems: RadioOption[] =
    pageTypeCases[props.type ?? 'none']?.generateItems ?? [];

  const elements = (
    <div className="output-dialog">
      <div className="contents-box">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Radio
                name="radio outputType"
                className="group"
                items={radioItems}
                value={radio}
                validateOption={{ required: true }}
                onChangeState={setRadio}
              ></Radio>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="selectedOnlyBtn"
            buttonType="basic"
            className="button"
            caption={GetMessage({
              id: 'selected_only',
              prefixId: 'OutputDialog',
            })}
            onClick={() =>
              props.onDecision({
                outputInfoType: radio,
                outputVolume: 'selectedOnly',
              })
            }
          />
          <CaptionButton
            name="allBtn"
            buttonType="basic"
            className="button"
            caption={GetMessage({
              id: 'all',
              prefixId: 'OutputDialog',
            })}
            onClick={() =>
              props.onDecision({
                outputInfoType: radio,
                outputVolume: 'all',
              })
            }
            disabled={
              radio === OutputInfoType.Answers ||
              radio === OutputInfoType.AllAnswers
            }
          />
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessage({ id: 'cancel' })}
            onClick={() => props.onCancel()}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: props.messageOption?.headerLabelId ?? {
      id: 'output',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
