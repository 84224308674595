import { useRef, useState } from 'react';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
  error,
} from '../..';
import {
  ExtendFileUploader,
  ExtendFileUploaderRef,
  FileItem,
} from '../../file';
import {
  FileUploadAppendListDialogDisplayOption,
  FileUploadAppendListDialogMessageOption,
  FileUploadAppendListDialogUploaderOption,
} from './FileUploadAppendListDialog';
import { useIntl } from 'react-intl';
import { SimpleListView } from '../SimpleListView';
import { useConfirmation } from '~/shared/hooks';
import { CaptionButton } from '../Button';
import { existsBlueprintNumber } from '~/tenant/blueprint/utils';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { getWorkerExceptionMessage } from '~/shared/utils';

export interface BlueprintFileUploadDialogOutputOption {
  isRegisteredItem: boolean;
  files?: BlueprintFile[];
}

export interface BlueprintFileUploadDialogProps {
  uploaderOption: FileUploadAppendListDialogUploaderOption;
  messageOption: FileUploadAppendListDialogMessageOption;
  displayOption?: FileUploadAppendListDialogDisplayOption;
  isOpen: boolean;
  onDecision: (v: BlueprintFileUploadDialogOutputOption) => void;
  onCancel: () => void;
  onChangeLoadingState?: (isLoading: boolean) => void;
}

export interface BlueprintFile {
  file: FileItem;
  blueprintNumber: string;
  isRegistered: boolean;
}

export const BlueprintFileUploadDialog = ({
  uploaderOption,
  messageOption,
  displayOption,
  isOpen,
  onDecision,
  onCancel,
}: BlueprintFileUploadDialogProps) => {
  const intl = useIntl();
  const uploaderRef = useRef<ExtendFileUploaderRef>(null);
  const [tempFileList, setTempFileList] = useState<FileItem[]>([]);
  const { isLoading, showLoading, hideLoading } = useLoading();

  const [fileList, setFileList] = useState<BlueprintFile[]>([]);

  const { confirmation, confirmationElement } = useConfirmation();

  const existsBlueprintNumbers = useRef<string[]>([]);

  const viewFileList = fileList
    .map((file, _, self): ViewListItem => {
      const isDuplicated =
        self.filter(
          (item) => removeExt(item.file.file.name) === file.blueprintNumber
        ).length > 1;
      const informationDisplayNames: string[] = [];
      if (isDuplicated) {
        informationDisplayNames.push('重複');
      }
      if (file.isRegistered) {
        informationDisplayNames.push('登録済');
      }
      return {
        ...file,
        filename: file.file.file.name,
        informationDisplayName: informationDisplayNames.join(' / '),
        duplicated: isDuplicated,
        isRegistered: file.isRegistered,
      };
    })
    .sort((a, b) => {
      const sort = getSortWeight(b) - getSortWeight(a);
      if (sort !== 0) {
        return sort;
      }
      return a.file.file.name > b.file.file.name ? 1 : -1;
    });

  const handleUploaded = async (fileList: FileItem[]) => {
    // アップロード対象の状態が変わるたびに発火されるので、一部のファイルがまだアップロード中の場合は処理しない
    if (
      fileList.length === 0 ||
      fileList.some((item) => item.status === 'uploading')
    ) {
      return;
    }
    setTempFileList(fileList);
    handleAddList(fileList);
  };

  const handleAddList = async (tempFileList: FileItem[]) => {
    if (tempFileList.some((item) => item.status === 'failure')) {
      return;
    }
    try {
      if (
        (fileList.length &&
          fileList.length +
            tempFileList.filter((item) => item.status === 'OK').length) >
        (uploaderOption.validateOption?.maxFileCount ?? 10)
      ) {
        error([
          GetMessageWithIntl(intl, { id: 'E0000077', value: { $1: 10 } }),
        ]);
        return;
      }
      showLoading();

      const newFiles = uploaderRef.current?.picksSuccessful() ?? [];

      const existsPromise = newFiles.map(async (file) => {
        const blueprintNumber = removeExt(file.file.name);
        return {
          blueprintNumber: blueprintNumber,
          exists: await existsBlueprintNumber(blueprintNumber),
        };
      });
      const newBlueprintNumberExists = (await Promise.all(existsPromise))
        .filter((item) => item.exists)
        .map((item) => item.blueprintNumber);

      existsBlueprintNumbers.current = [
        ...existsBlueprintNumbers.current,
        ...newBlueprintNumberExists,
      ];

      const newAttachmentFiles: BlueprintFile[] = newFiles.map((file) => {
        const blueprintNumber = removeExt(file.file.name);
        return {
          file,
          blueprintNumber,
          isRegistered:
            existsBlueprintNumbers.current.includes(blueprintNumber),
        };
      });

      setFileList([...fileList, ...newAttachmentFiles]);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    }
    hideLoading();
  };

  const handleRemove = (item: ViewListItem) => {
    (async () => {
      if (
        await confirmation({
          prefixId: 'FileCommentDialog',
          id: 'confirmationDialogMessageDelete',
        })
      ) {
        handleConfirmed(item);
      }
    })();
  };

  const handleAllRemove = () => {
    (async () => {
      if (
        await confirmation({
          prefixId: 'FileCommentDialog',
          id: 'confirmationDialogMessageDeleteAll',
        })
      ) {
        handleConfirmed();
      }
    })();
  };

  const handleConfirmed = (target?: ViewListItem) => {
    if (!target) {
      setFileList([]);
    } else {
      setFileList(fileList.filter((v) => v.file.url !== target?.file.url));
    }
  };

  const handleDecision = () => {
    if (tempFileList.some((item) => item.status === 'failure')) {
      error([GetMessageWithIntl(intl, { id: 'E0000081' })]);
      return;
    }
    const duplicateNameItems = viewFileList.filter((item) => !!item.duplicated);
    if (duplicateNameItems.length > 0) {
      error([GetMessageWithIntl(intl, { id: 'E0000174' })]);
      return;
    }
    onDecision({
      isRegisteredItem: fileList.some((v) => v.isRegistered),
      files: [...fileList],
    });
  };

  const elements = (
    <div className="file-upload-with-comment-dialog">
      <div className="contents-box">
        <div className="file-uploader-area">
          <ExtendFileUploader
            name="fileUploader"
            ref={uploaderRef}
            dndOption={{
              enabled: displayOption?.isDnd,
            }}
            multiple={true}
            validateOption={uploaderOption.validateOption}
            resultOption={{
              omitFooter: true,
            }}
            onUpload={handleUploaded}
            onChangeLoadingState={(isLoading) => {
              isLoading ? showLoading() : hideLoading();
            }}
          />
          <div className="halfway-action-area"></div>
          <SimpleListView
            data={viewFileList}
            viewOptions={{
              readonly: tempFileList.some(
                (item) => item.status === 'uploading'
              ),
              previewRowCount: 5,
              keyColumn: 'id',
              columns: [
                {
                  propertyName: 'filename',
                  header: {
                    id: messageOption.simpleListViewHeader.id,
                    prefixId: messageOption.simpleListViewHeader.prefixId,
                  },
                },
                {
                  propertyName: 'informationDisplayName',
                  header: {
                    id: 'informationDisplayName',
                    prefixId: messageOption.simpleListViewHeader.prefixId,
                  },
                  width: '8rem',
                },
              ],
            }}
            actionOptions={{
              onDelete: handleRemove,
              onDeleteAll: handleAllRemove,
            }}
          />
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => onCancel()}
          />
          <CaptionButton
            name="sendBtn"
            buttonType="basic"
            className="button"
            disabled={isLoading}
            caption={GetMessageWithIntl(intl, {
              id: messageOption.buttonType.id,
            })}
            onClick={handleDecision}
          />
        </div>
      </div>
      {confirmationElement}
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: onCancel,
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption?.dialogTitle,
    messageLabelId: {},
    elements,
  };

  return (
    <>
      <ModalDialogComponent {...openModalProps} />
    </>
  );
};

type ViewListItem = BlueprintFile & {
  filename: string;
  informationDisplayName: string;
  duplicated: boolean;
};
const getSortWeight = (item: ViewListItem) => {
  if (item.duplicated && !item.isRegistered) {
    // 重複のみを最優先
    return 100;
  }
  if (item.duplicated && item.isRegistered) {
    // 重複 / 登録済
    return 10;
  }
  if (!item.duplicated && item.isRegistered) {
    // 登録済
    return 1;
  }
  return 0;
};

const removeExt = (filename: string) => {
  return filename.split('.')?.at(0) ?? '';
};
