import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
} from '~/shared/components';
import {
  BusinessUnitStrengthDialog,
  BusinessUnitStrengthFormOption,
  ConfirmationDialog,
  PageNavigation,
  SimpleIconListView,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  FullMethodName_ListBusinessUnitBranchs,
  FullMethodName_ListBusinessUnitFacilitiess,
  FullMethodName_ListBusinessUnitStrengths,
  PageState,
  ViewId,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getPathnameByViewId,
  getWorkerExceptionMessage,
  includeInputValidateError,
} from '~/shared/utils';
import './OcpBusinessUnitProfileInput.css';

interface OcpStrengthListItem {
  id: string;
  businessUnitStrengthId?: string;
  companyId?: string;
  category?: sharelib.INameOption;
  categoryName?: string;
  content?: string;
  connection?: string;
  linkProperties?: mtechnavi.api.company.BusinessUnitStrength.ILinkProperties;
  updatedAt: Long.Long | null;
}

const VIEW_ID: ViewId = 'OCP_BUSINESS_UNIT_STRENGTH_INPUT';
export const OcpBusinessUnitStrengthInput = () => {
  const sourcePageInfo = useRef((useLocation().state as PageState) ?? []);

  const intl = useIntl();
  const navi = useNavigate();
  const backPageUrl = useMemo(
    () => getPathnameByViewId(sourcePageInfo.current.sourceViewId)?.path ?? '/',
    [sourcePageInfo]
  );
  const myCompanyInfo = useRef<mtechnavi.api.company.ICompany>();

  type TabName = 'strength';
  const tabs: TabName[] = ['strength'];
  const [tabPanel, setTabPanel] = useState<TabName>('strength');
  const isCurrentTab = (target: TabName) => {
    return tabPanel === target;
  };

  // ラベル
  const labelSave = GetMessageWithIntl(intl, {
    id: 'save',
  });

  // メッセージ
  const viewMessageBack: MessageProps = {
    id: 'confirmationDialogMessageBack',
    prefixId: VIEW_ID,
  };
  const viewMessageSave: MessageProps = {
    id: 'C0000003',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'save' }),
    },
  };
  const viewMessageDelete: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };

  const [isLoading, setLoading] = useState(false);
  const [isStrengthDialog, setStrengthDialog] = useState(false);
  const [selectId, setSelectId] = useState('');

  const [listData, setListData] = useState<OcpStrengthListItem[]>([]);
  const [ocpStrengthData, setOcpStrengthData] = useState<OcpStrengthListItem>();
  const [strengthData, setStrengthData] =
    useState<BusinessUnitStrengthFormOption>();
  const [updatedProperties, setUpdatedProperties] =
    useState<sharelib.IEmbeddedUpdatedProperties>();

  // 画面レイアウト
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);

  // 取得データ格納用 自社企業（拠点）マスタ
  const businessUnitBranchsData = useRef<
    mtechnavi.api.company.IBusinessUnitBranch[]
  >([]);

  // 取得データ格納用 自社企業（設備）マスタ
  const businessUnitFacilitiessData = useRef<
    mtechnavi.api.company.IBusinessUnitFacilities[]
  >([]);

  // 確認ダイアログ（戻る）
  const [isBackConfirmOpen, setBackConfirmOpen] = useState(false);
  // 確認ダイアログ（保存）
  const [isSaveConfirmOpen, setSaveConfirmOpen] = useState(false);
  // 確認ダイアログ（削除）
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState('');

  // 入力チェック
  const formAreaRef = useRef(null);

  const isInputError = (): boolean => {
    return includeInputValidateError(document, intl, []);
  };

  const handleDelete = () => {
    setListData(listData.filter((v) => v.id !== deleteTargetId));
    setDeleteTargetId('');
    setDeleteConfirmOpen(false);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await saveBusinessUnitStrength();
      setSaveConfirmOpen(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    backToPreviewPage();
  };

  const getBusinessUnitStrength = async () => {
    return await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: FullMethodName_ListBusinessUnitStrengths,
      filter: {},
      requestBody: {
        companyIds: [myCompanyInfo.current?.companyId as string],
      },
      sort: [], // ソートは画面項目セット時に行うため不要
    });
  };

  const getBusinessUnitBranch = async () => {
    return await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: FullMethodName_ListBusinessUnitBranchs,
      filter: {},
      requestBody: {
        companyIds: [myCompanyInfo.current?.companyId as string],
      },
      sort: [], // ソートは画面項目セット時に行うため不要
    });
  };

  const getBusinessUnitFacilities = async () => {
    return await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: FullMethodName_ListBusinessUnitFacilitiess,
      filter: {},
      requestBody: {
        companyIds: [myCompanyInfo.current?.companyId as string],
      },
      sort: [], // ソートは画面項目セット時に行うため不要
    });
  };

  const saveBusinessUnitStrength = async () => {
    const strengthList: mtechnavi.api.company.IBusinessUnitStrength[] = [];

    listData.forEach((strength) => {
      const request: mtechnavi.api.company.IBusinessUnitStrength = {
        companyId: myCompanyInfo.current?.companyId,
        businessUnitStrengthId: strength.businessUnitStrengthId,
        updatedAt: strength.updatedAt,
        category: strength.category,
        content: strength.content,
        linkProperties: strength.linkProperties,
      };
      strengthList.push(request);
    });

    await window.App.services.ui.worker.apiCall({
      actionName: 'saveOcpStrength',
      request: {
        businessUnitStrengths: strengthList,
        companyId: myCompanyInfo.current?.companyId ?? '',
      },
    });
  };

  // 戻るページ
  const backToPreviewPage = () => {
    const ids = [] as string[];
    sourcePageInfo.current.ids?.map((v) => ids.push(v));
    const state: PageState = {
      ids: sourcePageInfo.current.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.current.naviFilters,
      beforeStateIds: sourcePageInfo.current.beforeStateIds,
      baseViewOption: sourcePageInfo.current.baseViewOption,
      confirmationViewOption: sourcePageInfo.current.confirmationViewOption,
    };
    navi(backPageUrl, { state });
  };

  const saveStrengthList = async (
    v: mtechnavi.api.company.IBusinessUnitStrength
  ) => {
    const branchName =
      businessUnitBranchsData.current.find(
        (w) => w.businessUnitBranchId === v.linkProperties?.businessUnitBranchId
      )?.displayName ?? '';
    const facilityModelNumber =
      businessUnitFacilitiessData.current.find(
        (w) =>
          w.businessUnitFacilitiesId ===
          v.linkProperties?.businessUnitFacilitiesId
      )?.modelNumber ?? '';
    const ocpStrength: OcpStrengthListItem = {
      id: (listData.length + 1).toString(),
      category: v.category!,
      categoryName: v.category!.displayNameLang!.ja,
      content: v.content ?? '',
      linkProperties: v.linkProperties ?? undefined,
      connection:
        branchName || facilityModelNumber
          ? `${branchName}/${facilityModelNumber}`
          : '',
      companyId: myCompanyInfo.current?.companyId ?? '',
      businessUnitStrengthId: '',
      updatedAt: null,
    };
    console.info(ocpStrength);
    setOcpStrengthData(ocpStrength);
  };

  const listDataSort = (list: OcpStrengthListItem[]) => {
    // 並び替え
    return list.sort((val1, val2) =>
      val1.category!.displayNameLang!.ja!.localeCompare(
        val2.category!.displayNameLang!.ja!,
        'ja'
      )
    );
  };

  // 画面データ取得
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        // 自社情報を取得
        myCompanyInfo.current =
          await window.App.services.companyService.getCompany({});

        businessUnitBranchsData.current = (await getBusinessUnitBranch())
          .items as mtechnavi.api.company.IBusinessUnitBranch[];

        businessUnitFacilitiessData.current = (
          await getBusinessUnitFacilities()
        ).items as mtechnavi.api.company.IBusinessUnitFacilities[];

        const strengthList = (await getBusinessUnitStrength())
          .items as mtechnavi.api.company.IBusinessUnitStrength[];
        console.info(strengthList);
        if (strengthList) {
          const ocpList: OcpStrengthListItem[] = [];
          strengthList.forEach((list) => {
            const branchName =
              businessUnitBranchsData.current.find(
                (v) =>
                  v.businessUnitBranchId ===
                  list.linkProperties?.businessUnitBranchId
              )?.displayName ?? '';
            const modelNumber =
              businessUnitFacilitiessData.current.find(
                (v) =>
                  v.businessUnitFacilitiesId ===
                  list.linkProperties?.businessUnitFacilitiesId
              )?.modelNumber ?? '';
            const data: OcpStrengthListItem = {
              id:
                list.businessUnitStrengthId ?? (listData.length + 1).toString(),
              category: list.category!,
              categoryName: list.category!.displayNameLang!.ja,
              content: list.content ?? '',
              linkProperties: list.linkProperties ?? undefined,
              connection:
                branchName || modelNumber
                  ? [branchName, modelNumber].join('/')
                  : '',
              companyId: myCompanyInfo.current?.companyId ?? '',
              businessUnitStrengthId: list.businessUnitStrengthId ?? '',
              updatedAt: list.updatedAt as Long.Long,
            };
            setUpdatedProperties(list.updatedProperties!);
            ocpList.push(data);
          });
          listDataSort(ocpList);
          setListData(ocpList);
        }
        setLoading(false);
      })();
    } catch (err) {
      error(getExceptionMessage(intl, err));
    }

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  // リサイズ
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  useEffect(() => {
    if (!ocpStrengthData) {
      return;
    }
    (async () => {
      if (!selectId) {
        listData.push(ocpStrengthData);
        listDataSort(listData);
      }
      setOcpStrengthData(undefined);
    })();

    // ocpStrengthData変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocpStrengthData]);

  const renderStrengthComponent = () => {
    return (
      <>
        <div className="tab-info-body">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-80">
                <SimpleIconListView
                  data={listData}
                  viewOptions={{
                    previewRowCount: 10,
                    keyColumn: 'id',
                    columns: [
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_STRENGTH_INPUT.categoryName',
                        },
                        propertyName: 'categoryName',
                        width: '12rem',
                      },
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_STRENGTH_INPUT.content',
                        },
                        propertyName: 'content',
                      },
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_STRENGTH_INPUT.connection',
                        },
                        propertyName: 'connection',
                        width: '30%',
                      },
                    ],
                    omitFooter: true,
                  }}
                  actionOptions={{}}
                  iconMenuOptions={{
                    iconMenu: [
                      {
                        name: 'edit',
                        displayName: '編集',
                        func: (item: OcpStrengthListItem) => {
                          const selectData: BusinessUnitStrengthFormOption = {
                            companyId: myCompanyInfo.current?.companyId ?? '',
                            category: item.category,
                            content: item.content,
                            linkProperties: item.linkProperties,
                          };
                          setSelectId(item.id);
                          setStrengthData(selectData);
                          setStrengthDialog(true);
                        },
                      },
                      {
                        name: 'delete',
                        displayName: '削除',
                        func: (item: OcpStrengthListItem) => {
                          setDeleteTargetId(item.id);
                          setDeleteConfirmOpen(true);
                        },
                      },
                    ],
                  }}
                />
              </div>
              <div className="w-20">
                <CaptionButton
                  buttonType="basic"
                  caption="新規行追加"
                  name=""
                  onClick={() => {
                    setSelectId('');
                    setStrengthData({
                      companyId: myCompanyInfo.current?.companyId ?? '',
                    });
                    setStrengthDialog(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="OcpBusinessUnitStrengthInput">
          <div className="header">
            <PageNavigation
              backpagePath="/opc-businessunit-profile-confirmation"
              pageInfo={{
                isVisibleMoveNavi: false,
              }}
              infoOption={{
                lastUpdateInfo: {
                  isVisibleUpdateInfo: true,
                  attribute: updatedProperties?.updatedBy?.email ?? '',
                  attributeAt: updatedProperties?.updatedAt ?? undefined,
                  content: updatedProperties?.updatedBy?.displayName ?? '',
                },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => setBackConfirmOpen(true)}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
            ref={formAreaRef}
          >
            <div className="input">
              <div className="basic-info-body">
                <div className="tabPanelContents">
                  {tabs.map((v, index) => {
                    const selectPane = v === tabPanel ? 'current' : '';
                    return (
                      <div
                        key={`tab-${index}`}
                        className={`tabPanel ${selectPane}`}
                        onClick={() => setTabPanel(v)}
                      >
                        {GetMessageWithIntl(intl, { prefixId: VIEW_ID, id: v })}
                      </div>
                    );
                  })}
                </div>
                {isCurrentTab('strength') && renderStrengthComponent()}
              </div>
            </div>
          </div>
          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                <CaptionButton
                  name="saveButton"
                  buttonType="basic"
                  caption={labelSave}
                  onClick={() => {
                    if (isInputError()) {
                      return;
                    }
                    setSaveConfirmOpen(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Toast />
      </Container>
      {/* 戻る 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isBackConfirmOpen}
        viewMessage={viewMessageBack}
        onDecision={() => {
          backToPreviewPage();
        }}
        onCancel={() => {
          setBackConfirmOpen(false);
        }}
      />
      <BusinessUnitStrengthDialog
        isOpen={isStrengthDialog}
        inputMode={true}
        inputOption={strengthData}
        onCancel={() => {
          setStrengthDialog(false);
        }}
        onDecision={(v) => {
          if (selectId) {
            listData.map((e) => {
              if (e.id === selectId) {
                e.category = v.category;
                e.categoryName = v.category!.displayNameLang!.ja;
                e.content = v.content ?? '';
                e.linkProperties = v.linkProperties;
                const branchName =
                  businessUnitBranchsData.current.find(
                    (w) =>
                      w.businessUnitBranchId ===
                      v.linkProperties?.businessUnitBranchId
                  )?.displayName ?? '';
                const facilityModelNumber =
                  businessUnitFacilitiessData.current.find(
                    (w) =>
                      w.businessUnitFacilitiesId ===
                      v.linkProperties?.businessUnitFacilitiesId
                  )?.modelNumber ?? '';
                e.connection =
                  branchName || facilityModelNumber
                    ? `${branchName}/${facilityModelNumber}`
                    : '';
              }
            });
            listDataSort(listData);
          } else {
            saveStrengthList(v);
          }
          setStrengthDialog(false);
        }}
      />
      {/* 保存 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isSaveConfirmOpen}
        viewMessage={viewMessageSave}
        onDecision={() => {
          handleSave();
        }}
        onCancel={() => {
          setSaveConfirmOpen(false);
        }}
      />
      {/* 削除 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isDeleteConfirmOpen}
        viewMessage={viewMessageDelete}
        onDecision={() => {
          handleDelete();
        }}
        onCancel={() => {
          setDeleteConfirmOpen(false);
        }}
      />
      {isLoading && <LoadingIcon />}
    </>
  );
};
