import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import './TenantApplyRequest.css';
import { GetMessageWithIntl } from '~/shared/components';
import { ContentsType, getHtmlData } from '../services/staticContents';

export interface ContentsProps {
  contentsType: ContentsType;
}
export function PrivacyPolicy(props: ContentsProps) {
  const intl = useIntl();
  const contentsType = props.contentsType;
  const [contents, setContents] = useState<
    string | JSX.Element | JSX.Element[]
  >();

  const viewName = GetMessageWithIntl(intl, {
    viewId: contentsType === 'privacypolicy' ? 'PRIVACY_POLICY' : 'TERM',
    prefixId: 'HEADER_TITLE',
  });
  const mainRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (async () => {
      await displayContents();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentsType]);

  const displayContents = async () => {
    const result = await getHtmlData(contentsType);
    setContents(result);
  };

  return (
    <>
      <div id="back-ground-color-theme"></div>
      <article className="container">
        <header className="Header">
          <div>
            <div className="nav-btn"></div>
          </div>
          <div className="symbol">M-Tech Navi{` ${viewName}`}</div>
          <div className="header-btns"></div>
        </header>

        <div className="content">
          <div className="main" ref={mainRef}>
            <div className="TenantApplyRequestArea">{contents}</div>
          </div>
        </div>
      </article>
    </>
  );
}
