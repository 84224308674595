import { useState } from 'react';
import { Checkbox } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  FileUploadDialog,
  FileUploadDialogResult,
} from '~/shared/components/ui/Dialog/FileUploadDialog';

export const UISampleFileUploadDialogs = () => {
  const [singleResult, setSingleResult] =
    useState<FileUploadDialogResult | null>(null);
  const [isSingleShow, setSingleShow] = useState(false);

  const [multiResult, setMultiResult] = useState<FileUploadDialogResult | null>(
    null
  );
  const [isMultiShow, setMultiShow] = useState(false);

  const [isNonDndShow, setNonDndShow] = useState(false);
  const [nonDndResult, setNonDndResult] =
    useState<FileUploadDialogResult | null>(null);

  const [showNote, setShowNote] = useState(false);

  return (
    <div className="categories">
      <h3 id="dialog">ファイル追加ダイアログ</h3>
      <h4>
        単一ファイル D&D <small>(1MB以下、.pdf, .xlsx)</small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setSingleShow(true);
              }}
            />
            <FileUploadDialog
              isOpen={isSingleShow}
              messageOption={{
                headerLabelId: {
                  id: 'file_add',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              fileUploadOption={{
                multiple: false,
                validateOption: {
                  allowedFileExtensions: ['pdf', 'xlsx'],
                  maxFileSizeInMebis: 1,
                },
              }}
              onCancel={() => {
                setSingleShow(false);
              }}
              onDecision={(result) => {
                setSingleShow(false);
                setSingleResult(result);
              }}
              onChangeLoadingState={(isLoading) => {
                console.info('Loading Status', isLoading);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {singleResult && JSON.stringify(singleResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>

      <h4>
        複数ファイル D&D <small>(2MB以下、3つまで、.txt)</small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setMultiShow(true);
              }}
            />
            <FileUploadDialog
              isOpen={isMultiShow}
              messageOption={{
                headerLabelId: {
                  id: 'file_add',
                  prefixId: 'DIALOG_TITLE',
                },
              }}
              fileUploadOption={{
                multiple: true,
                validateOption: {
                  allowedFileExtensions: ['txt'],
                  maxFileSizeInMebis: 2,
                  maxFileCount: 3,
                },
              }}
              onCancel={() => {
                setMultiShow(false);
              }}
              onDecision={(result) => {
                setMultiShow(false);
                setMultiResult(result);
              }}
              onChangeLoadingState={(isLoading) => {
                console.info('Loading Status', isLoading);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {multiResult && JSON.stringify(multiResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>

      <h4>
        単一ファイル non-D&D <small>(1MB以下、.pdf, .xlsx)</small>
      </h4>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setNonDndShow(true);
              }}
            />
            <div style={{ marginTop: '1rem' }}>
              <Checkbox
                name=""
                columns={['']}
                value={showNote ? '1' : undefined}
                items={[{ displayName: '補足表示', value: '1' }]}
                onChangeState={(v) => {
                  setShowNote(!!v.length);
                }}
              />
            </div>
            <FileUploadDialog
              isOpen={isNonDndShow}
              messageOption={{
                headerLabelId: {
                  id: 'file_add',
                  prefixId: 'DIALOG_TITLE',
                },
                captionLabelId: {
                  id: 'upload_csv',
                  prefixId: 'BusinessUnitCsvAdditionDialog',
                },
                decisionLabelId: {
                  id: 'import',
                },
                noteContents: showNote && (
                  <div style={{ margin: '2rem 0', fontSize: '0.9rem' }}>
                    <h4>
                      注意事項 (追加のメッセージを DOM ごと追加するテスト)
                    </h4>
                    <ul>
                      <li>
                        属性情報：既存の確定明細以外を削除し、新しいファイルで抽出した値を追加します。
                      </li>
                      <li>
                        セグメント情報：既存情報を削除し、新しいファイルで抽出した値で更新します。
                      </li>
                      <li>
                        処理実行後は差し替え前の状態に戻すことはできません。
                      </li>
                    </ul>
                  </div>
                ),
              }}
              fileUploadOption={{
                multiple: false,
                validateOption: {
                  allowedFileExtensions: ['pdf', 'xlsx'],
                  maxFileSizeInMebis: 1,
                },
                isDnd: false,
              }}
              onCancel={() => {
                setNonDndShow(false);
              }}
              onDecision={(result) => {
                setNonDndShow(false);
                setNonDndResult(result);
              }}
              onChangeLoadingState={(isLoading) => {
                console.info('Loading Status', isLoading);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">
              {nonDndResult && JSON.stringify(nonDndResult, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
