import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components/ui/ModalDialog/ModalDialog';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import {
  FileItem,
  ExtendFileUploader,
  FileUploaderValidateOption,
  ExtendFileUploaderRef,
} from '~/shared/components/file';
import { SimpleListView } from '~/shared/components/ui/SimpleListView';
import { error } from '~/shared/components/parts/Toast/Toast';
import { ConfirmationDialog } from './ConfirmationDialog';
import './base.css';
import { AttachmentFile } from './CommonDialogTypes';
export interface SurveyReplyDelegationResult {
  files: AttachmentFile[];
}

export interface SurveyReplyDelegationDialogMessageOption {
  headerLabelId: MessageProps;
  messageLabelId?: MessageProps;
}
export interface SurveyReplyDelegationDialogInputOption {
  files: AttachmentFile[];
}

export interface SurveyReplyDelegationDialogUploadOption {
  validateOption?: FileUploaderValidateOption;
}

export interface SurveyReplyDelegationDialogProps {
  isOpen: boolean;
  messageOption: SurveyReplyDelegationDialogMessageOption;
  inputOption: SurveyReplyDelegationDialogInputOption;
  uploadOption: SurveyReplyDelegationDialogUploadOption;
  onDecision: (result: SurveyReplyDelegationResult) => void;
  onCancel: () => void;
  onChangeLoadingState?: (isLoading: boolean) => void;
}

type ConfirmType = 'all' | 'file';

export const SurveyReplyDelegationDialog = ({
  isOpen,
  messageOption,
  inputOption,
  uploadOption,
  onDecision,
  onCancel,
  onChangeLoadingState,
}: SurveyReplyDelegationDialogProps) => {
  const intl = useIntl();
  const uploaderRef = useRef<ExtendFileUploaderRef>(null);

  const [tempFileList, setTempFileList] = useState<FileItem[]>([]);

  const [fileList, setFileList] = useState<AttachmentFile[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [confirmAction, setConfirmAction] = useState<ConfirmType | null>(null);
  const [confirmTarget, setConfirmTarget] = useState<AttachmentFile | null>(
    null
  );

  const viewFileList = fileList
    .map((file, _, self) => {
      const isDuplicated =
        self.filter((item) => item.filename === file.filename).length > 1;
      const isAdd = !file.assetId; // assetId があれば保存済みとみなす
      return {
        ...file,
        duplicated: isDuplicated ? 'NG' : '',
        add: isAdd ? '◯' : '',
      };
    })
    .sort((a, b) => {
      const sortDuplicated = (a.duplicated ? 0 : 1) - (b.duplicated ? 0 : 1);
      if (sortDuplicated !== 0) {
        return sortDuplicated;
      }
      if (a.filename !== b.filename) {
        return a.filename > b.filename ? 1 : -1;
      }
      return (a.add ? 0 : 1) - (b.add ? 0 : 1);
    });

  useEffect(() => {
    setFileList(inputOption.files);
  }, [isOpen, inputOption.files]);

  const handleUploaded = (fileList: FileItem[]) => {
    // アップロード対象の状態が変わるたびに発火されるので、一部のファイルがまだアップロード中の場合は処理しない
    if (
      fileList.length === 0 ||
      fileList.some((item) => item.status === 'uploading')
    ) {
      return;
    }
    setTempFileList(fileList);
    handleAddList(fileList);
  };

  const handleAddList = (tempFileList: FileItem[]) => {
    if (tempFileList.some((item) => item.status === 'failure')) {
      return;
    }
    if (
      (fileList.length &&
        fileList.length +
          tempFileList.filter((item) => item.status === 'OK').length) >
      (uploadOption.validateOption?.maxFileCount ?? 10)
    ) {
      error([
        GetMessageWithIntl(intl, {
          id: 'E0000077',
          value: { $1: uploadOption.validateOption?.maxFileCount ?? 10 },
        }),
      ]);
      return;
    }

    const category = window.App.services.ui.getNameOptionWithSystemName(
      'A0000016',
      'B03'
    );
    const linkType = window.App.services.ui.getNameOptionWithSystemName(
      'A0000017',
      'B03'
    );
    const prefixName = '[代行]';
    const newFiles = uploaderRef.current?.picksSuccessful() ?? [];
    setFileList([
      ...fileList,
      ...newFiles.map((file) => ({
        id: `${file.url ?? ''}`,
        category: category.length > 0 ? category[0] : {},
        assetId: '',
        filename: `${prefixName}${file.file.name ?? ''}`,
        url: file.url,
        mimeType: '',
        remarks: '',
        linkType: linkType.length > 0 ? linkType[0] : {},
      })),
    ]);
    uploaderRef.current?.clear();
  };

  const handleRemove = (item: AttachmentFile) => {
    setConfirmAction('file');
    setConfirmTarget(item);
  };

  const handleAllRemove = () => {
    setConfirmAction('all');
  };

  const handleConfirmed = (
    type: ConfirmType | null,
    target: AttachmentFile | null
  ) => {
    if (!type) {
      return;
    }
    if (type === 'all') {
      setFileList([]);
    } else {
      if (target?.assetId) {
        setFileList(fileList.filter((v) => v.assetId !== target?.assetId));
      } else {
        setFileList(fileList.filter((v) => v.url !== target?.url));
      }
    }

    setConfirmAction(null);
    setConfirmTarget(null);
  };

  const handleDecision = () => {
    if (tempFileList.some((item) => item.status === 'failure')) {
      error([GetMessageWithIntl(intl, { id: 'E0000081' })]);
      return;
    }
    const duplicateNameItems = viewFileList.filter((item) => !!item.duplicated);
    if (duplicateNameItems.length > 0) {
      error([GetMessageWithIntl(intl, { id: 'E0000174' })]);
      return;
    }
    onDecision({
      files: [...fileList],
    });
  };

  const elements = (
    <div className="modal-area-container">
      <div className="description-area">
        <p>
          {messageOption.messageLabelId &&
            GetMessageWithIntl(intl, messageOption.messageLabelId)}
        </p>
      </div>
      <div className="detail-area">
        <ExtendFileUploader
          name="dnd"
          ref={uploaderRef}
          dndOption={{
            enabled: true,
          }}
          multiple={true}
          validateOption={uploadOption.validateOption}
          resultOption={{
            omitFooter: true,
          }}
          onUpload={handleUploaded}
          onChangeLoadingState={(v) => {
            onChangeLoadingState && onChangeLoadingState(v);
            setLoading(v);
          }}
        />

        <div className="halfway-action-area"></div>

        <SimpleListView
          data={viewFileList}
          viewOptions={{
            keyColumn: 'id',
            previewRowCount: 5,
            columns: [
              {
                propertyName: 'add',
                header: { id: 'add' },
                width: '5rem',
              },
              {
                propertyName: 'duplicated',
                header: { id: 'duplicated' },
                width: '5rem',
              },
              {
                propertyName: 'filename',
                header: {
                  id: 'fileName',
                  prefixId: 'SurveyReplyDelegationDialog',
                },
              },
            ],
          }}
          actionOptions={{
            onDelete: handleRemove,
            onDeleteAll: handleAllRemove,
          }}
        />
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={() => onCancel()}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          disabled={isLoading}
          caption={GetMessageWithIntl(intl, {
            id: 'save',
          })}
          onClick={handleDecision}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: messageOption.messageLabelId ?? {},
    elements,
  };

  return (
    <div className="SurveyReplyDelegationDialog">
      <ModalDialogComponent {...openModalProps} />
      <ConfirmationDialog
        isOpen={!!confirmAction}
        viewMessage={{
          id: 'C0000001',
          value: {
            $1: GetMessageWithIntl(intl, {
              id: confirmAction === 'all' ? 'remove_all' : 'remove',
              prefixId: 'SurveyReplyDelegationDialog',
            }),
          },
        }}
        onDecision={() => handleConfirmed(confirmAction, confirmTarget)}
        onCancel={() => setConfirmAction(null)}
      />
    </div>
  );
};
