import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_UpdateBlueprintRevision } from 'worker/fullMethodName/crud';

/** 図面の改訂情報更新 */
export const updateBlueprintRevision = async (
  worker: AppSaveWorker,
  request: mtechnavi.api.blueprint.IBlueprintRevision
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.blueprint.IUpdateBlueprintRevisionRequest,
    mtechnavi.api.blueprint.IBlueprintContent
  >(FullMethodName_UpdateBlueprintRevision, {
    blueprintRevision: request,
  });
};
