import { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './SideMenu.css';
import { useTheme } from '~/shared/contexts/ThemeProvider';
import { ReactComponent as Topic } from '@material-design-icons/svg/outlined/topic.svg';
import { ReactComponent as Feed } from '@material-design-icons/svg/outlined/feed.svg';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { ViewId, saveInitialLocalStorageData } from '~/shared/utils';
import { DebugParam, useDebug } from '~/shared/contexts/DebugProvider';
import { getMenuItems } from './util';

export interface SideMenuProps {
  open: boolean;
  close?: () => void;
}

export function SideMenu(props: SideMenuProps) {
  const { isDebugMode: debugMode } = useDebug();
  const { close, open } = props;
  const myEmail = useAuth().user?.email ?? '';
  const [menuItems, setMenuItems] = useState<SideMenuItem[]>([]);
  useEffect(() => {
    (async () => {
      const items = await getMenuItems(debugMode);
      setMenuItems(items);
    })();
  }, [debugMode]);

  const setIcon = (iconType: string | undefined): ReactNode | string => {
    switch (iconType) {
      case 'estimation':
        return <Feed />;
      case 'control':
        return <Topic />;
      default:
        return '';
    }
  };

  const renderMenuItem = (key: number[], v: SideMenuItem): JSX.Element => {
    if (!!v.pathname) {
      const search = new URLSearchParams({
        ...v.search,
        ...(debugMode ? DebugParam : {}),
      }).toString();
      return (
        <li key={key.join('-')}>
          <Link
            to={{
              pathname: v.pathname,
              search,
            }}
            onClick={() => {
              const convertViewId = v.viewId as ViewId;
              saveInitialLocalStorageData(convertViewId, myEmail);
              close && close();
            }}
          >
            <span>{v.label}</span>
          </Link>
        </li>
      );
    } else {
      return (
        <li key={key.join('-')}>
          <h3 className="menu-title">
            {setIcon(v.icon)}
            {v.label}
          </h3>
          <menu className="sidemenu-list">
            {(v.children || []).map((child, i) =>
              renderMenuItem([...key, i], child)
            )}
          </menu>
        </li>
      );
    }
  };

  // dark・lightテーマの切り替え画面が現状存在しないためここに定義
  const { setTheme } = useTheme();
  const tmpThemeSettingView = () => {
    if (debugMode) {
      return (
        <div>
          <button onClick={() => setTheme('dark')}>darkテーマ</button>
          <br />
          <button onClick={() => setTheme('')}>lightテーマ</button>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <menu className={`SideMenu ${open ? 'open' : 'close'}`}>
      <div className="company">&nbsp;</div>
      <ul>{menuItems.map((v, i) => renderMenuItem([i], v))}</ul>
      {tmpThemeSettingView()}
    </menu>
  );
}
