import { useState } from 'react';
import { useIntl } from 'react-intl';
import { getMessageId, GetMessageWithIntl } from '~/shared/components';
import { CaptionButton } from '~/shared/components/ui/Button';
import { ViewId } from '~/shared/utils';
import { Checkbox, SimpleListView, Textbox } from '~/shared/components/ui';
import { mtechnavi } from '~/shared/libs/clientsdk';
import './BlueprintAttributeConfirm.css';

interface BlueprintAttributeConfirmProps {
  viewId: ViewId;
  /** 表示する属性情報 */
  attribute?: mtechnavi.api.blueprint.IBlueprintAttribute;
  /** 保存せず次へ */
  onNextAttribute?: () => Promise<void> | void;
  /** 確定して保存 */
  onSaveAttribute?: (
    attr: mtechnavi.api.blueprint.IBlueprintAttribute,
    isDelete: boolean
  ) => Promise<void> | void;
}
export const BlueprintAttributeConfirm = ({
  viewId,
  attribute,
  onNextAttribute,
  onSaveAttribute,
}: BlueprintAttributeConfirmProps) => {
  const intl = useIntl();

  const [isDelete, setDelete] = useState(false);
  const [attributeName, setAttributeName] = useState(
    attribute?.attributeProperty?.attributeName
  );
  const [attributeValue, setAttributeValue] = useState(
    attribute?.attributeProperty?.attributeValue
  );

  /** 保存せず次へ */
  const handleNextAttribute = () => {
    onNextAttribute && onNextAttribute();
  };

  /** 確定して保存 */
  const handleSaveAttribute = () => {
    const attr: mtechnavi.api.blueprint.IBlueprintAttribute = {
      ...attribute,
      attributeProperty: {
        ...attribute?.attributeProperty,
        attributeName,
        attributeValue,
      },
      fixed: true,
    };
    onSaveAttribute && onSaveAttribute(attr, isDelete);
  };

  return (
    <div className="BlueprintAttributeConfirm">
      <div className="title">
        {GetMessageWithIntl(intl, { viewId, id: 'unfixedAttributes' })}
      </div>

      <div className="body">
        <span>{GetMessageWithIntl(intl, { viewId, id: 'extractResult' })}</span>

        <SimpleListView
          actionOptions={{}}
          data={[{ ...attribute?.attributeProperty }]}
          viewOptions={{
            columns: [
              {
                propertyName: 'title',
                header: { viewId, id: 'title' },
              },
              {
                propertyName: 'value',
                header: { viewId, id: 'value' },
              },
            ],
            previewRowCount: 1,
            omitFooter: true,
          }}
        />

        <Checkbox
          name="delete"
          columns={['delete']}
          value={isDelete ? '1' : undefined}
          items={[
            {
              value: '1',
              displayName: GetMessageWithIntl(intl, {
                viewId,
                id: 'deleteAttribute',
              }),
            },
          ]}
          onChangeState={(v) => {
            setDelete(!!v.length);
          }}
        />

        <Textbox
          name="attributeName"
          type="text"
          columns={['attributeName']}
          labelId={getMessageId({ viewId, id: 'attributeName' })}
          value={attributeName}
          onChangeState={setAttributeName}
        />
        <Textbox
          name="attributeValue"
          type="text"
          columns={['attributeValue']}
          labelId={getMessageId({ viewId, id: 'attributeValue' })}
          value={attributeValue}
          onChangeState={setAttributeValue}
        />

        <div className="actions">
          <CaptionButton
            name="unfixNext"
            buttonType="basic"
            caption={GetMessageWithIntl(intl, { viewId, id: 'unfixNext' })}
            onClick={handleNextAttribute}
          />
          <CaptionButton
            name="fixAndNext"
            buttonType="basic"
            caption={GetMessageWithIntl(intl, { viewId, id: 'fixAndNext' })}
            onClick={handleSaveAttribute}
          />
        </div>
      </div>
    </div>
  );
};
