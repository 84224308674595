import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_UpdateBlueprint } from 'worker/fullMethodName/crud';

/** 図面の更新 */
export const updateBlueprint = (
  worker: AppSaveWorker,
  req: mtechnavi.api.blueprint.IUpdateBlueprintRequest
) => {
  return worker.invokeListRequest<
    mtechnavi.api.blueprint.ICreateBlueprintAndBlueprintRevisionRequest,
    mtechnavi.api.blueprint.IBlueprintContent
  >(FullMethodName_UpdateBlueprint, req);
};
