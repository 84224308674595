import {
  FullMethodName,
  FullMethodName_ListOrganizationRelations,
  FullMethodName_ListOrganizations,
  FullMethodName_ListUserAttributes,
} from '~/worker';
import {
  FullMethodName_ListPresetMenus,
  FullMethodName_ListPresets,
} from '../utils/fullMethodName';

/** ログイン時に初期取得する API の FullMethodName */
export const InitialLoadMethods: FullMethodName[] = [
  FullMethodName_ListPresetMenus,
  FullMethodName_ListPresets,
  FullMethodName_ListOrganizations,
  FullMethodName_ListOrganizationRelations,
  FullMethodName_ListUserAttributes,
];
