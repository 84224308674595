/**
 * ページ遷移関連
 */

import { NavigateFunction } from 'react-router-dom';
import { ViewId } from './viewId';
import { getPathnameByViewId } from '../util';

export type actionType = 'add' | 'edit' | 'copy';

export interface PageStateFilter {
  ids?: string[];
  pageNumber?: number;
}
export interface NaviFilter {
  key: string;
  value: string[];
}

export interface BaseViewOption {
  sourceViewId?: ViewId;
  sourceTab?: string;
}
export interface ConfirmationViewOption {
  pageNumber?: number;
}

export interface PageState {
  sourceViewId?: ViewId;
  actionType?: actionType;
  ids?: string[];
  subIds?: string[];
  beforeStateIds?: string[];
  beforeStateSubIds?: string[];
  filter?: PageStateFilter; //フィルタ情報の保持（確認ページから一覧ページへ戻る際に使用）
  naviFilters?: NaviFilter[];
  baseViewOption?: BaseViewOption;
  confirmationViewOption?: ConfirmationViewOption;
}

// 遷移処理
export function movePage(
  state: PageState,
  navi: NavigateFunction,
  url: string
) {
  navi(url, {
    state,
  });
}

// 画面遷移アクション
export function backPageAction(
  navi: NavigateFunction,
  VIEW_ID: ViewId,
  initialPath: string,
  state?: PageState
) {
  const backPageUrl = getPathnameByViewId(VIEW_ID)?.path ?? initialPath;
  movePage(state ?? {}, navi, backPageUrl);
}

// 遷移先を新しいタブで開く
export function openWindowOrTab(
  moveTo: string,
  hashParam?: string,
  isExternalLink?: boolean
) {
  // 外部リンク
  if (isExternalLink) {
    window.open(moveTo, '_blank');
    return;
  }
  if (hashParam) {
    // サーバーにパラメータを送る必要はないので、ハッシュフラグメント形式とする
    const hashFragment = `#${hashParam}`;
    // ビューワーは別タブまたは別ウインドウで開く
    window.open(`/${moveTo}${hashFragment}`, '_blank');
  } else {
    window.open(`/${moveTo}`, '_blank');
  }
}

// 遷移元（一覧）に戻る
export const backToBaseViewPage = (
  navi: NavigateFunction,
  sourcePageInfo: PageState,
  viewId: ViewId
) => {
  const state: PageState = {
    ids: sourcePageInfo.beforeStateIds ?? [],
    sourceViewId: viewId,
    naviFilters: sourcePageInfo.naviFilters,
    beforeStateIds: sourcePageInfo.beforeStateIds,
    baseViewOption: sourcePageInfo.baseViewOption,
  };

  // 戻り先に遷移元(一覧)情報をセット
  const backPath =
    getPathnameByViewId(sourcePageInfo.baseViewOption?.sourceViewId)?.path ??
    '/';
  navi(backPath, { state });
};
