import { getPathnameByViewId, PageState, ViewId } from '~/shared/utils';
import { NavigateFunction } from 'react-router-dom';

export const getTargetId = (sourcePageInfo: PageState, viewId: ViewId) => {
  const urlPathName = window.location.pathname;
  const splitPath = urlPathName.split('/');
  if (sourcePageInfo.ids) {
    return sourcePageInfo.ids ?? [];
  } else {
    if (splitPath.length > 1) {
      const pagePath = getPathnameByViewId(viewId)?.path ?? '/';
      const idPath = splitPath[splitPath.length - 1];
      const endpointPath = splitPath[splitPath.length - 2];
      if (idPath !== pagePath && endpointPath === pagePath) {
        return [idPath];
      }
    }
  }
  return [];
};

// １つ前のページに戻る
export const backToPreviewPage = (
  navi: NavigateFunction,
  sourcePageInfo: PageState,
  viewId: ViewId
) => {
  const ids = [] as string[];
  sourcePageInfo.ids?.map((v) => ids.push(v));
  const state: PageState = {
    ids: sourcePageInfo.beforeStateIds ?? [],
    sourceViewId: viewId,
    naviFilters: sourcePageInfo.naviFilters,
    beforeStateIds: sourcePageInfo.beforeStateIds,
    baseViewOption: sourcePageInfo.baseViewOption,
    confirmationViewOption: sourcePageInfo.confirmationViewOption,
  };

  // 戻り先に遷移元情報をセット
  const backPath =
    getPathnameByViewId(sourcePageInfo.sourceViewId)?.path ?? '/';
  navi(backPath, { state });
};

// 登録画面に遷移する
export const moveInputPage = (
  navi: NavigateFunction,
  sourcePageInfo: PageState,
  viewId: ViewId,
  inputViewId: ViewId,
  currentId?: string,
  pageNumber?: number
) => {
  const state: PageState = {
    ids: [currentId ?? ''],
    sourceViewId: viewId,
    actionType: 'edit',
    naviFilters: sourcePageInfo.naviFilters,
    beforeStateIds: sourcePageInfo.ids,
    baseViewOption: sourcePageInfo.baseViewOption,
    confirmationViewOption: { pageNumber: pageNumber },
  };

  // 登録画面の遷移情報をセット
  const movePath = getPathnameByViewId(inputViewId)?.path ?? '/';
  navi(movePath, { state });
};
