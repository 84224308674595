import { ViewId } from '~/shared/utils';
import { Forum } from '~/shared/components/ui/Forum';
import { useCommentPane, useCommentPaneDispatch } from './CommentPaneProvider';
import { IconButton } from '../Button';
import { mtechnavi } from '~/shared/libs/clientsdk';
import './CommentPaneForum.css';

interface CommentPaneForumProps {
  viewId: ViewId;
  companyIds?: string[];
}
export const CommentPaneForum = ({
  viewId,
  companyIds,
}: CommentPaneForumProps) => {
  const { draft, threadSet, showOption, assetSet } = useCommentPane();
  const { baseThreadId } = assetSet;
  const { activeThreadId } = threadSet;
  const commentPaneDispatch = useCommentPaneDispatch();

  const handleToggleThreadList = (open: boolean) => {
    if (open) {
      commentPaneDispatch({ type: 'showCommentPane' });
    } else {
      commentPaneDispatch({ type: 'hideCommentPane' });
    }
  };

  const handleChangeActiveThread = (threadId?: string | null) => {
    commentPaneDispatch({ type: 'changeActiveThread', threadId });
  };

  const handleAddThread = (newThread?: mtechnavi.api.forum.IThread) => {
    commentPaneDispatch({ type: 'addThread', thread: newThread ?? {} });
  };

  const handleCancelAddThread = () => {
    commentPaneDispatch({ type: 'cancelThread' });
  };

  return (
    <div className="CommentPaneForum">
      <IconButton
        name="openMenu"
        iconType="forum"
        onClick={() => handleToggleThreadList(!showOption.isShowCommentPane)}
      />
      <div
        className={`list-container ${
          showOption.isShowCommentPane ? 'show' : ''
        }`}
      >
        <Forum
          baseThreadId={baseThreadId ?? undefined}
          viewId={viewId}
          companyIds={companyIds}
          isShowDraft={!!draft}
          currentThreadId={activeThreadId}
          draftOptionalData={{
            associations: [assetSet.activeAssetId ?? ''],
            attachedPoint: draft,
          }}
          controls={{
            hideCreateThread: true,
          }}
          onChangeActiveThread={handleChangeActiveThread}
          onAddThread={handleAddThread}
          onCancelAddThread={handleCancelAddThread}
        />
      </div>
    </div>
  );
};
