import React, { ComponentType } from 'react';
import { realScaleShape, ViewerInfo, ViewerOverlayProperties } from '../util';

/** 重ねて表示するコンポーネントのプロパティ */
export interface OverlayItemProps {
  /** 相対座標情報 */
  plotInfo: ViewerOverlayProperties;
  /** アクティブ表示(強調など)をするか */
  isActive?: boolean;
  /** 要素に追加できるclass属性(オプション) */
  className?: string;
  /** クリックイベント */
  onClick?: () => void;
}

export type OverlayItem = OverlayItemProps & {
  /** 重ねて表示するコンポーネント本体 */
  component: ComponentType<OverlayItemProps>;
};

interface ViewerOverlayProps {
  className?: string;
  /**
   * ビューワー情報
   *
   * 描画位置やサイズを合わせ、相対座標から実際の位置に変換するために使用。
   * */
  viewerInfo: ViewerInfo;
  /** 重ねて表示するコンポーネントとその設定 */
  overlayItems: OverlayItem[];
}

/**
 * ビューワー上に重ねて表示するコンポーネントを管理するコンポーネント
 *
 * 渡された overlayItems がビューワーの描画エリアに合わせて描画されるよう調整を行う。
 * ビューワーの描画エリアに追従して拡大・縮小される。
 */
export const ViewerOverlay = ({
  className,
  viewerInfo,
  overlayItems,
}: ViewerOverlayProps) => {
  const realScaleElements = overlayItems
    .filter(({ plotInfo }) => {
      return !(
        Math.abs(plotInfo.x2 - plotInfo.x1) < 1 &&
        Math.abs(plotInfo.y2 - plotInfo.y1) < 1
      );
    })
    .map(({ component: Component, ...properties }, index) => {
      const realScaleProt = realScaleShape(properties.plotInfo, viewerInfo);
      return (
        <Component
          key={index}
          {...{ ...properties, plotInfo: realScaleProt }}
        />
      );
    });

  return (
    <div
      className={`overlay ${className}`}
      style={{
        transform: `scale(${viewerInfo.scale}) translate(${viewerInfo.offset.x}px, ${viewerInfo.offset.y}px)`,
      }}
    >
      {realScaleElements}
    </div>
  );
};
