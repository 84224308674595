import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';

export interface SaveDisasterSurveyRequest {
  actionName: 'saveDisasterSurvey';
  request: mtechnavi.api.bcp.IDisasterSurvey;
}

export const saveDisasterSurvey = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.bcp.IDisasterSurvey
) => {
  const createFullMethodName =
    'mtechnavi.api.bcp.BcpService/CreateDisasterSurvey';
  const updateFullMethodName =
    'mtechnavi.api.bcp.BcpService/UpdateDisasterSurvey';

  if (req.disasterSurveyId) {
    return await worker.invokeListRequest<
      mtechnavi.api.bcp.IUpdateDisasterSurveyRequest,
      mtechnavi.api.bcp.IDisasterSurvey
    >(updateFullMethodName, {
      disasterSurvey: req,
    });
  }
  return await worker.invokeListRequest<
    mtechnavi.api.bcp.ICreateDisasterSurveyRequest,
    mtechnavi.api.bcp.IDisasterSurvey
  >(createFullMethodName, {
    disasterSurvey: req,
  });
};
