import { OffsetInfo } from './Viewer';

export type ViewerOverlayProperties = PlotInfo;

interface PlotInfo {
  /** 画像に対する相対値 */
  x1: number;
  /** 画像に対する相対値 */
  y1: number;
  /** 画像に対する相対値 */
  x2: number;
  /** 画像に対する相対値 */
  y2: number;
}

interface ShapeAttribute {
  /** 矩形種別 */
  attributes?: { selectionShape?: string };
}

export type ShapeInfo = PlotInfo & ShapeAttribute;

export interface ViewerInfo {
  offset: OffsetInfo;
  scale: number;
  width: number;
  height: number;
  isDragging: boolean;
}

/** 座標とサイズに対する相対値の変換精度 */
export const PROT_RATIO_ACCURACY = 10000;

/** 座標を保存用の相対値に変換する */
export const pixelToViewRatio = (pixel: number, viewSize: number): number => {
  if (viewSize === 0) {
    return 0;
  }
  return Math.floor((pixel * PROT_RATIO_ACCURACY) / viewSize);
};

/** 保存用の相対値を座標に戻す */
export const viewRatioToPixel = (ratio: number, viewSize: number): number => {
  return (ratio * viewSize) / PROT_RATIO_ACCURACY;
};

/** 描画用の座標を、ビューワーサイズ(縦・横)に対する相対値(保存用)へ変換する */
export const baseScaleShape = <T extends PlotInfo>(
  realScaleShape: T,
  viewerInfo: Pick<ViewerInfo, 'height' | 'width' | 'scale'>
): T => {
  const x1 = pixelToViewRatio(realScaleShape.x1, viewerInfo.width);
  const y1 = pixelToViewRatio(realScaleShape.y1, viewerInfo.height);
  const x2 = pixelToViewRatio(realScaleShape.x2, viewerInfo.width);
  const y2 = pixelToViewRatio(realScaleShape.y2, viewerInfo.height);
  return {
    ...realScaleShape,
    x1,
    y1,
    x2: x1 + (x2 - x1) / viewerInfo.scale,
    y2: y1 + (y2 - y1) / viewerInfo.scale,
  };
};

/** ビューワーサイズ(縦・横)に対する相対値(保存用)の座標を描画用の座標に変換する */
export const realScaleShape = <T extends PlotInfo>(
  relativeScaleShape: T,
  viewerInfo: Pick<ViewerInfo, 'height' | 'width'>
): T => {
  return {
    ...relativeScaleShape,
    x1: viewRatioToPixel(relativeScaleShape.x1, viewerInfo.width),
    y1: viewRatioToPixel(relativeScaleShape.y1, viewerInfo.height),
    x2: viewRatioToPixel(relativeScaleShape.x2, viewerInfo.width),
    y2: viewRatioToPixel(relativeScaleShape.y2, viewerInfo.height),
  };
};
