import { useState } from 'react';
import { Container, Toast } from '~/shared/components';
import { Tabs } from '~/shared/components/ui';
import { ViewerWIthOtherColumn } from './ViewerWIthOtherColumn';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { CroppedViewerSample } from './CroppedViewerSample';
import { AssetSelector } from '../../utils/AssetSelector';

export const BlueprintExperimental = () => {
  const [previewAsset, setPreviewAsset] =
    useState<mtechnavi.api.assetinventory.IAsset>();

  return (
    <Container>
      <div className="BlueprintExperimental">
        <AssetSelector
          selectedAsset={previewAsset}
          onSelectChange={setPreviewAsset}
        />
        <div style={{ marginTop: '1rem' }}>
          <Tabs
            tabItems={[
              {
                label: '図面',
                tabContent: (
                  <ViewerWIthOtherColumn previewAsset={previewAsset} />
                ),
              },
              {
                label: 'セグメント',
                tabContent: <CroppedViewerSample previewAsset={previewAsset} />,
              },
            ]}
          />
        </div>
        <Toast />
      </div>
    </Container>
  );
};
