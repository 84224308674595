import parse from 'html-react-parser';

export type ContentsType = 'term' | 'privacypolicy';

export interface ContentsItem {
  id: string;
  title: string;
  answer: string;
  htmlId: string;
  tag: string[];
}

export type JsonType = 'help' | 'manual';

export async function getHtmlData(
  htmlType: ContentsType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const src = `/resources/common/contents/${htmlType}.html`;

  return await fetch(src, {
    method: 'GET',
  }).then(async (response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const blob = await response.blob();
    const responseText = await blob.text();
    return parse(responseText);
  });
}

export async function getContentsHtmlData(
  htmlId: string,
  jsonType: JsonType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const splittedId = htmlId.split('/');
  let src = '';
  if (splittedId.length === 0) {
    return;
  }
  if (splittedId.length > 1) {
    src = `/resources/common/contents/${jsonType}/${splittedId[0]}/${splittedId[1]}.txt`;
  } else {
    src = `/resources/common/contents/${jsonType}/${splittedId[0]}.txt`;
  }

  return await fetch(src, {
    method: 'GET',
  }).then(async (response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const blob = await response.blob();
    const responseText = await blob.text();
    return parse(responseText);
  });
}

export async function getContentsJson(type: JsonType): Promise<ContentsItem[]> {
  let src = '';
  if (type === 'help') {
    src = `/resources/common/contents/help/Help.json`;
  } else {
    src = `/resources/common/contents/manual/Manual.json`;
  }

  return await fetch(src, {
    method: 'GET',
  }).then(async (response) => {
    // jsonがない時、空にする
    if (!response.ok) {
      if (response.status === 404) {
        console.error(`HTTP error! Status: ${response.status}`);
        return [];
      }
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const blob = await response.blob();
    const responseText = await blob.text();
    return JSON.parse(responseText);
  });
}
