import { useEffect, useState } from 'react';
import { Viewer } from '~/shared/components/ui';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { ReactComponent as KeyboardArrowDownIcon } from '@material-design-icons/svg/filled/expand_more.svg';
import { ReactComponent as KeyboardArrowUpIcon } from '@material-design-icons/svg/filled/expand_less.svg';
import './FileViewer.css';
import { FilterRequest } from '~/worker';
import { useLocation } from 'react-router-dom';
import { error } from '~/shared/components/parts/Toast/Toast';
import { useIntl } from 'react-intl';
import {
  getLocalStorageFromHashFragment,
  getExceptionMessage,
  FullMethodName_ListAssets,
} from '~/shared/utils';

export const FILE_VIEWER_LOCALE_STORAGE_PREFIX = 'file_viewer';

export function FileViewer() {
  const intl = useIntl();
  // 遷移元から値を受け取る
  const hashFragment = useLocation().hash ?? '';
  const ids = getLocalStorageFromHashFragment(hashFragment);

  const [items, setItems] = useState<mtechnavi.api.assetinventory.IAsset[]>([]);
  const [selectedItemNum, setSelectedItemNum] = useState(0);
  const [maxItemNum, setMaxItemNum] = useState(0);

  // アセット数の最大値
  useEffect(() => {
    const l = Math.max(items.length - 1, 0);
    setMaxItemNum(l);
  }, [items]);

  // ListAssetsを最新にする
  const option: FilterRequest = {
    action: 'reload',
    fullMethodName: FullMethodName_ListAssets,
    requestBody: {
      ids: ids,
    },
    filter: {
      assetId: {
        $in: ids,
      },
    },
    sort: [],
  };

  // MongoDBからassetIDを元に該当情報を引っ張ってくる
  useEffect(() => {
    (async () => {
      try {
        const result = (await window.App.services.ui.worker.filter(
          option
        )) as mtechnavi.api.assetinventory.IListAssetsResponse;
        const items = result.items ?? [];
        setItems(items);

        // 一番最初のファイルを自動プレビュー
        handlePreview(items[selectedItemNum] ?? {});
        setSelectedItemNum(0);
      } catch (err) {
        error(getExceptionMessage(intl, err));
      }
    })();
    // 初回時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [previewAsset, setPreviewAsset] = useState({
    assetId: '',
    fileName: '',
  });
  const handlePreview = (v: mtechnavi.api.assetinventory.IAsset) => {
    if (!v.assetId) {
      return;
    }

    setPreviewAsset({
      assetId: v.assetId,
      fileName: v.displayName ?? '',
    });
    // 現在セレクトされているitemを特定
    const index = items.findIndex((item) => item.assetId === v.assetId);
    setSelectedItemNum(index);
  };

  // 選択ファイルを変更
  const moveFile = (moveNum: number) => {
    // 最大値を超える場合
    if (moveNum > maxItemNum) {
      return;
    }
    // 最小値より小さい場合
    if (moveNum < 0) {
      return;
    }
    handlePreview(items[moveNum]);
    setSelectedItemNum(moveNum);
  };

  return (
    <div className="FileViewer">
      <div className="file-menu">
        <ul className="menu-list">
          <li>
            <div className="file-header">
              <span className="margin">ファイル名</span>
              <div className="right-align">
                <KeyboardArrowUpIcon
                  className={selectedItemNum === 0 ? 'disabled-svg-icon' : ''}
                  onClick={() => {
                    moveFile(selectedItemNum - 1);
                  }}
                />
                <KeyboardArrowDownIcon
                  className={
                    selectedItemNum === maxItemNum ? 'disabled-svg-icon' : ''
                  }
                  onClick={() => {
                    moveFile(selectedItemNum + 1);
                  }}
                />
              </div>
            </div>
          </li>
          {items.map((item, i) => {
            return (
              <li
                key={item.assetId}
                className={`contents ${
                  i === selectedItemNum ? 'selected' : ''
                }`}
              >
                <a
                  onClick={() => {
                    handlePreview(item);
                  }}
                >
                  {item.displayName}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <div>
        <Viewer
          assetId={previewAsset.assetId}
          fileName={previewAsset.fileName}
          controls={{
            downloading: true,
            pager: true,
            scaling: true,
          }}
          width="99%"
          height="100%"
        />
      </div>
    </div>
  );
}
