import {
  ViewId,
  getExceptionMessage,
  getLocalStorageCheckboxData,
  getPresetAndSchema,
  saveFilterAndSortData,
  saveLocalStorageCheckboxData,
} from '~/shared/utils';
import {
  GetMessage,
  GetMessageWithIntl,
  MessageProps,
  ModalDialogComponent,
  ModalDialogComponentProps,
  error,
} from '../..';
import {
  ListView,
  InitialFilter,
  InitialFilterItem,
  Preset,
  Property,
  Schema,
} from '../ListView';
import { CaptionButton } from '../Button/CaptionButton';
import { useIntl } from 'react-intl';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { useEffect, useState } from 'react';
import { PresetItem } from '~/shared/services';
import { AggregateStage, FullMethodName } from '~/worker';
import { getSchemaByTypeName } from '../Util';
import './ConnectingDialog.css';

interface MessageOption {
  headerOption: MessageProps;
  connectingCategoryLabel?: MessageProps;
}

export interface AggregateStageOption {
  aggregateStages: AggregateStage[];
  aggregateType?: string;
  mergedShema?: boolean;
}

export interface InitialFilterOption {
  info: InitialFilterItem[];
}

export interface ValidateOption {
  maxSelectItem: {
    selectLimit: number;
    errorId: string;
  };
}

interface SurveyRequestConnectingDialogProps {
  isOpen: boolean;
  FullMethodNames: string[];
  FullMethodNameForExtendListView: FullMethodName;
  initialFilterOption?: InitialFilterOption;
  viewId: ViewId;
  keyId: string;
  messageOption: MessageOption;
  aggregateStagesOption?: AggregateStageOption;
  onDecision: (result: string[]) => void;
  onCancel: () => void;
  formatSchemaInfo?: (schema: Schema) => Schema;
  validateOption?: ValidateOption;
  selectedIds?: string[];
}
export const ConnectingDialog = (props: SurveyRequestConnectingDialogProps) => {
  const { viewId, FullMethodNames, isOpen, keyId, selectedIds } = props;
  const aggregateStages = props.aggregateStagesOption?.aggregateStages ?? [];
  const aggregatedType = props.aggregateStagesOption?.aggregateType ?? '';
  const mergedShema = props.aggregateStagesOption?.mergedShema ?? false;
  const connectingCategoryLabel =
    props.messageOption.connectingCategoryLabel ?? null;

  const intl = useIntl();
  const myEmail = useAuth().user?.email ?? '';
  const [schema, setSchema] = useState<Property[]>([]);
  const [presetItem, setPresetItem] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const selectLimit = props.validateOption?.maxSelectItem.selectLimit;
  const msg = GetMessage({
    id: props.validateOption?.maxSelectItem?.errorId ?? 'E0000001',
    value: {
      $1: selectLimit,
    },
  });
  const [presetChildrenItems, setPresetChildrenItems] =
    useState<PresetItem[]>();
  // 初期フィルタ設定
  const [initialFilter, setInitialFilter] = useState<InitialFilter>({
    info: [],
  });

  useEffect(() => {
    saveLocalStorageCheckboxData(viewId, [], myEmail);
    (async () => {
      try {
        if (!viewId || !isOpen) {
          return;
        }
        if (presetChildrenItems) {
          resetPreset();
        }
        const filter: InitialFilter = {
          info: [],
        };

        if (props.initialFilterOption?.info) {
          filter.info.push(...props.initialFilterOption.info);
        }
        setInitialFilter(filter);

        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(viewId, FullMethodNames);
        const tmpSchema = schemas[0];
        if (mergedShema) {
          const schema2 = schemas[1];
          const mergedSchemas = schema2.map((v) => {
            return {
              name: `mergedContents.${v.name}`,
              typeName: v.typeName,
            };
          });
          tmpSchema.push(...mergedSchemas);
        }
        const schema = aggregatedType
          ? getSchemaByTypeName(aggregatedType)
          : tmpSchema;

        const resultSchema = props.formatSchemaInfo
          ? props.formatSchemaInfo(schema)
          : schema;
        setPresetChildrenItems(childrenPresetItem);
        setSchema(resultSchema);
        setPresetItem(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();

    // 初回起動時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewId, intl, isOpen]);

  const resetPreset = () => {
    setSchema([]);
    setPresetItem({
      filter: {},
      propertyNames: [],
    });
    setPresetChildrenItems([]);
    saveFilterAndSortData(viewId, { sort: [] }, myEmail);
  };

  const handleCancel = () => {
    resetPreset();
    props.onCancel();
  };

  const handleDecision = () => {
    const checkedIds = getLocalStorageCheckboxData(viewId, myEmail);
    // 指定された件数を超える場合、エラーを表示する
    if (selectLimit && selectLimit < checkedIds.length) {
      error([msg]);
      return;
    }
    props.onDecision(checkedIds);
  };

  const elements = (
    <div className="connecting-dialog">
      <div className="connecting-area">
        {connectingCategoryLabel && (
          <p className="connectingCategoryLabel">
            紐付け分類：{GetMessageWithIntl(intl, connectingCategoryLabel)}
          </p>
        )}
        <ListView
          fullMethodName={props.FullMethodNameForExtendListView}
          pageInfo={{
            preset: presetItem,
            schema: schema,
            menuTarget: keyId,
            presetItems: presetChildrenItems,
            headerTitle: { viewId: viewId },
            listSkipType: {
              isHeaderIconMenu: true,
              isTotal: true,
              isListActionMenu: true,
              isListIconMenu: true,
              isOutput: true,
            },
          }}
          filterItemOption={{
            aggregateStages: aggregateStages,
            isRequestBodyFilter: true,
            initialFilterItems: initialFilter,
          }}
          selectedItemIds={selectedIds}
        />
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={handleCancel}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'save' })}
          onClick={() => handleDecision()}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      handleCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: props.messageOption.headerOption,
    messageLabelId: {},
    elements,
  };

  return (
    <div className="ConnectingDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
