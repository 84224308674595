import { useState } from 'react';
import { Container, Toast } from '~/shared/components';
import { SearchMenuListViewDialog } from '~/shared/components/ui/SearchListViewDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { Schema, Textbox, getAltDisplaySchema } from '~/shared/components/ui';
import './TransactionUnitSearchList.css';
import { FullMethodName_ListTransactionUnits, ViewId } from '~/shared/utils';
import { IconButton } from '~/shared/components/ui/Button/IconButton';

const VIEW_ID: ViewId = 'MASTER_TRANSACTION_UNIT_SEARCH';

// export type ViewItems = mtechnavi.api.blueprint.TransactionUnit[];
const ITEM_NAME = 'transactionUnit';

export function TransactionUnitSearchList() {
  const [limitNumber, setLimitNumber] = useState(0);
  const [searchTextValue, setSearchTextValue] = useState('');
  const [baseModalIsOpen, setModalIsOpen] = useState(false);
  const handleClose = () => {
    setModalIsOpen(!baseModalIsOpen);
  };
  // const isSelectAction = (items: ViewItems) => {
  //   let text = '';
  //   items.forEach((item) => {
  //     text = `${text}${text === '' ? '' : ','}${item.displayNameLang.ja}`;
  //   });
  //   setSearchTextValue(text);
  //   setModalIsOpen(!baseModalIsOpen);
  // };

  // カラムの読み替え処理
  const onHandleFormatSchema = (schema: Schema) => {
    // ja表示のみする処理
    const jaColumn = ['displayNameLang', 'processs.displayNameLang'];
    return getAltDisplaySchema(schema, jaColumn, 'ja');
  };

  return (
    <Container viewId={VIEW_ID}>
      <div className="TransactionUnitSearchList">
        <div className="search-text-box">
          <Textbox
            name={ITEM_NAME}
            className="field"
            type="text"
            value={searchTextValue}
            labelId={ITEM_NAME}
            onChangeState={() => {}}
            columns={[ITEM_NAME]}
          />
          <div className="btn-box">
            <IconButton
              name="searchIcon"
              className="btn btn-basic"
              properties={[
                {
                  name: 'searchIcon',
                  propertyName: 'searchIcon',
                  propertyValue: 'searchIcon',
                },
              ]}
              buttonType="basic"
              onClick={handleClose}
              iconType="search"
            />
          </div>
        </div>
        {/* demo */}
        <div className="limit">
          選択個数の制限
          <input
            type="number"
            value={limitNumber}
            onChange={(e) => {
              setLimitNumber(Number(e.target.value));
            }}
          />
        </div>
        <SearchMenuListViewDialog
          viewId={VIEW_ID}
          fullMethodName={FullMethodName_ListTransactionUnits}
          isOpen={baseModalIsOpen}
          headerLabelId={{
            prefixId: 'DIALOG',
            viewId: VIEW_ID,
          }}
          selectLimit={limitNumber}
          onCloseChange={() => handleClose()}
          // onSelectChange={(items) =>
          // isSelectAction(items as unknown as ViewItems)
          // }
          onHandleFormatSchema={onHandleFormatSchema}
        />
      </div>
      <Toast />
    </Container>
  );
}
