import {
  ViewId,
  getWorkerExceptionMessage,
  getExceptionMessage,
  toMonetaryAmount,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useIntl } from 'react-intl';
import { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import {
  GetMessage,
  success,
  error,
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components';
import {
  Textbox,
  Textarea,
  SimpleListView,
  ListColumnSetting,
  DataFilterbox,
  DataFilterboxItem,
  Checkbox,
} from '~/shared/components/ui';
import { CommentDialog } from '~/shared/components/ui/Dialog';
import { EstimateSelectionDialog } from '~/shared/components/ui/Dialog/EstimateSelectionDialog';
import { ConfirmationDialog } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { Amountbox } from '~/shared/components/ui/Amountbox';
import { convertLongToString } from '~/shared/utils/converter';
import {
  getDateFormatWithTimezone,
  getDateFormat,
  toCommaTypeString,
  formatUserWIthEmail,
} from '~/shared/utils/formatter';
import { totalAmounts } from '~/shared/utils/calculator';
import {
  listSharedEstimateResults,
  listSharedEstimateResultDetails,
  listEstimateSelections,
} from '~/tenant/estimate/utils';

interface EstimateResultTabItemProps {
  viewId: ViewId;
  estimateRequestUnits?: mtechnavi.api.estimation.IEstimateRequestUnit[];
  onChangeLoadingState?: (arg: boolean) => void;
  handleReload?: () => void;
}

/* 明細内訳
 *  シンプルリストダイアログのカラム情報
 */
interface EstimateResultDetailViewItem {
  id: string;
  autoName: string;
  displayName: string;
  amount: string;
  price: string;
  materialCost: string;
  processingCost: string;
  administrativeCost: string;
  freightCost: string;
  otherCost: string;
}

const VIEW_ID: ViewId = 'EST_ESTIMATE_REQUEST_CONFIRMATION';

const estimateResultDetailColumns = [
  {
    header: { id: 'estimateResultDetail.autoName', viewId: VIEW_ID },
    propertyName: 'autoName',
    width: '7%',
  },
  {
    header: { id: 'estimateResultDetail.displayName', viewId: VIEW_ID },
    propertyName: 'displayName',
    width: '20%',
  },
  {
    header: { id: 'estimateResultDetail.amount', viewId: VIEW_ID },
    propertyName: 'amount',
    align: 'right',
    width: '10%',
  },
  {
    header: { id: 'estimateResultDetail.price', viewId: VIEW_ID },
    propertyName: 'price',
    align: 'right',
    width: '18%',
  },
  {
    header: { id: 'estimateResultDetail.materialCost', viewId: VIEW_ID },
    propertyName: 'materialCost',
    align: 'right',
    width: '9%',
  },
  {
    header: { id: 'estimateResultDetail.processingCost', viewId: VIEW_ID },
    propertyName: 'processingCost',
    align: 'right',
    width: '9%',
  },
  {
    header: { id: 'estimateResultDetail.administrativeCost', viewId: VIEW_ID },
    propertyName: 'administrativeCost',
    align: 'right',
    width: '9%',
  },
  {
    header: { id: 'estimateResultDetail.freightCost', viewId: VIEW_ID },
    propertyName: 'freightCost',
    align: 'right',
    width: '9%',
  },
  {
    header: { id: 'estimateResultDetail.otherCost', viewId: VIEW_ID },
    propertyName: 'otherCost',
    align: 'right',
    width: '9%',
  },
] as ListColumnSetting[];

export const EstimateResultTabItem = ({
  viewId,
  estimateRequestUnits,
  onChangeLoadingState,
  handleReload,
}: EstimateResultTabItemProps) => {
  const intl = useIntl();
  const successMessage = GetMessage({ id: 'I0000001' });
  const viewMessageSend: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'send' }),
    },
  };

  const estimateRequestUnitsData = useMemo<DataFilterboxItem[]>(() => {
    return estimateRequestUnits
      ? estimateRequestUnits
          .sort((val1, val2) =>
            (val1.code ?? '').localeCompare(val2.code ?? '')
          )
          .map((v) => ({
            displayName: v.displayName ?? '',
            value: v.estimateRequestUnitId ?? '',
          }))
      : [];
  }, [estimateRequestUnits]);
  const [filterboxEstimateRequestUnit, setFilterboxEstimateRequestUnit] =
    useState<DataFilterboxItem[]>([]);
  const [estimateRequestUnit, setEstimateRequestUnit] =
    useState<mtechnavi.api.estimation.IEstimateRequestUnit>();
  const [estimateResult, setEstimateResult] =
    useState<mtechnavi.api.estimation.IEstimateResult>();
  const [estimateSelection, setEstimateSelection] =
    useState<mtechnavi.api.estimation.IEstimateSelection>();

  // 回答データ
  const estimateResultsData = useRef<
    mtechnavi.api.estimation.IEstimateResult[] | null
  >();
  // 回答明細データ
  const estimateResultDetailsData = useRef<
    mtechnavi.api.estimation.IEstimateResultDetail[] | null
  >();
  // 選考データ
  const estimateSelectionData = useRef<
    mtechnavi.api.estimation.IEstimateSelection[] | null
  >();

  // 初期セット
  useEffect(() => {
    (async () => {
      onParentLoadingState(true);
      try {
        const [
          listSharedEstimateResultsRes,
          listSharedEstimateResultDetailsRes,
          listEstimateSelectionsRes,
        ] = await Promise.all([
          listSharedEstimateResults(
            [estimateRequestUnits?.at(0)?.estimateRequestId ?? ''] ?? [],
            true
          ),
          listSharedEstimateResultDetails(
            [estimateRequestUnits?.at(0)?.estimateRequestId ?? ''] ?? [],
            true
          ),
          listEstimateSelections(
            estimateRequestUnits?.map((w) => {
              return w.estimateRequestUnitId ?? '';
            }) ?? [],
            true
          ),
        ]);
        // 非表示項目のセット
        estimateResultsData.current = listSharedEstimateResultsRes.items;
        estimateResultDetailsData.current =
          listSharedEstimateResultDetailsRes.items;
        estimateSelectionData.current = listEstimateSelectionsRes.items;
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        onParentLoadingState(false);
      }
    })();

    setFilterboxEstimateRequestUnit([
      {
        displayName:
          estimateRequestUnits?.length === 1
            ? estimateRequestUnits?.at(0)?.displayName ?? ''
            : '',
        value:
          estimateRequestUnits?.length === 1
            ? estimateRequestUnits?.at(0)?.estimateRequestUnitId ?? ''
            : '',
      },
    ]);
    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // フィルタボックス値変更時
  useEffect(() => {
    const unit = estimateRequestUnits?.find(
      (v) =>
        v.estimateRequestUnitId === filterboxEstimateRequestUnit.at(0)?.value
    );
    if (unit) {
      setEstimateRequestUnit(unit);
    } else {
      setEstimateRequestUnit(undefined);
    }

    const result = estimateResultsData.current?.find(
      (v) => v.estimateResultId === filterboxEstimateRequestUnit.at(0)?.value
    );
    if (result) {
      setEstimateResult(result);
    } else {
      setEstimateResult(undefined);
    }

    const selection = estimateSelectionData.current?.find(
      (v) => v.estimateSelectionId === filterboxEstimateRequestUnit.at(0)?.value
    );
    if (selection) {
      setEstimateSelection(selection);
    } else {
      setEstimateSelection(undefined);
    }
    // 想定通りの挙動なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterboxEstimateRequestUnit,
    estimateResultsData.current,
    estimateRequestUnits,
  ]);

  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState<MessageProps>({});
  const confirmPromiseRef =
    useRef<(value: boolean | PromiseLike<boolean>) => void>();

  const [isOpenEstimateSelectionDialog, setOpenEstimateSelectionDialog] =
    useState(false);
  const [
    isOpenEstimateResultChangeRequestDialog,
    setOpenEstimateResultChangeRequestDialog,
  ] = useState(false);
  const [changeRequestComment, setChangeRequestComment] = useState('');

  /**
   * 修正依頼ダイアログ確定処理
   */
  const handleSurveyRequest = async () => {
    if (!(await confirmation(viewMessageSend))) {
      return;
    }
    onParentLoadingState(true);
    try {
      const req: mtechnavi.api.estimation.IEstimateResultChangeRequest = {
        estimateResultChangeRequestId: null,
        estimateResultId: estimateResult?.estimateResultId,
        requestedAt: null,
        comment: changeRequestComment,
        sharedProperties: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'execEstimateResultChangeRequest',
        request: req,
      });
      setOpenEstimateResultChangeRequestDialog(false);
      success([successMessage]);
      setChangeRequestComment('');
      handleReload && handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      onParentLoadingState(false);
    }
  };

  const onParentLoadingState = useCallback(
    (arg: boolean) => {
      onChangeLoadingState && onChangeLoadingState(arg);
    },
    [onChangeLoadingState]
  );

  /**
   * 確認ダイアログ処理
   * Promise で結果を制御する。
   * 確定: true / キャンセル: false
   */
  const confirmation = (viewMessage: MessageProps): Promise<boolean> => {
    setOpenConfirmDialog(true);
    setConfirmMessage(viewMessage);
    return new Promise((resolve) => {
      confirmPromiseRef.current = resolve;
    });
  };

  /**
   * 確認ダイアログの確認処理
   */
  const handleConfirmed = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(true);
    }
    setOpenConfirmDialog(false);
  };

  /**
   * 確認ダイアログのキャンセル処理
   */
  const handleCancel = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(false);
    }
    setOpenConfirmDialog(false);
  };

  const estimateResultDetailList = useMemo<
    EstimateResultDetailViewItem[]
  >(() => {
    const details = estimateResultDetailsData.current?.filter(
      (v) => v.estimateResultId === estimateResult?.estimateResultId
    );
    if (!details) {
      return [];
    }
    const vals: EstimateResultDetailViewItem[] = [];
    details.map((v) => {
      vals.push({
        id: v.estimateRequestDetailId ?? '',
        autoName: convertLongToString(v.estimateRequestDetail?.detailAutoName),
        displayName: v.estimateRequestDetail?.displayName ?? '',
        amount: [
          toCommaTypeString(v.estimateRequestDetail?.quantity?.amountNum),
          v.estimateRequestDetail?.quantity?.unit?.displayNameLang?.ja ?? '',
        ].join(''),
        price: `${toCommaTypeString(v.amount?.amountNum)}${
          v.amount?.amountNum && v.unitPrice?.amountNum
            ? '(@' + toCommaTypeString(v.unitPrice?.amountNum) + ')'
            : ''
        }`,
        materialCost: toCommaTypeString(v.materialCost?.amountNum),
        processingCost: toCommaTypeString(v.processingCost?.amountNum),
        administrativeCost: toCommaTypeString(v.administrativeCost?.amountNum),
        freightCost: toCommaTypeString(v.freightCost?.amountNum),
        otherCost: toCommaTypeString(v.otherCost?.amountNum),
      });
    });
    return vals;
    // 想定通りの挙動なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateResultDetailsData.current, estimateResult]);

  return (
    <>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-40">
            <DataFilterbox
              data={estimateRequestUnitsData}
              name="estimateRequestUnits"
              labelId="EST_ESTIMATE_REQUEST_CONFIRMATION.result.estimateRequestUnits"
              columns={['estimateRequestUnits']}
              searchOption={{ targets: 'displayName' }}
              onChangeState={setFilterboxEstimateRequestUnit}
              value={filterboxEstimateRequestUnit}
            />
          </div>
          <div className="w-20">
            <Textbox
              name="status"
              className="field"
              value={estimateRequestUnit?.status?.displayNameLang?.ja}
              type="text"
              labelId="EST_ESTIMATE_REQUEST_CONFIRMATION.result.status"
              columns={['status']}
              disabled={true}
            />
          </div>
          {estimateSelection?.estimateSelectionId && (
            <div className="w-40">
              <div className="selection-area">
                <div className="input-line no-space">
                  <div className="item-group-100 no-space">
                    <CaptionButton
                      name="selection"
                      className="selection"
                      caption={GetMessageWithIntl(intl, {
                        prefixId: viewId,
                        id: 'result.selection',
                      })}
                      buttonType="basic"
                      onClick={() => {
                        setOpenEstimateSelectionDialog(true);
                      }}
                    />
                    <div className="adoption-checkbox">
                      <Checkbox
                        name="adoption"
                        items={[
                          {
                            value: '1',
                            displayName: GetMessageWithIntl(intl, {
                              prefixId: viewId,
                              id: 'result.adoption',
                            }),
                          },
                        ]}
                        columns={['adoption']}
                        value={estimateSelection?.adoption ? ['1'] : []}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-100">
            <span className="label">
              {GetMessageWithIntl(intl, {
                prefixId: viewId,
                id: 'result.to',
              })}{' '}
              ：
            </span>
            <span>
              {estimateRequestUnit?.contactUsers
                ?.map((w) => {
                  return formatUserWIthEmail(w.displayName, w.email);
                })
                .join(',') ?? ''}
            </span>
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-33">
            <span className="label">
              {GetMessageWithIntl(intl, {
                prefixId: viewId,
                id: 'result.estimateRequestUnitAutoName',
              })}{' '}
              ：
            </span>
            <span>
              {convertLongToString(
                estimateRequestUnit?.estimateRequestUnitAutoName
              )}
            </span>
          </div>
          <div className="w-33">
            <span className="label">
              {GetMessageWithIntl(intl, {
                prefixId: viewId,
                id: 'result.estimateExpireDt',
              })}{' '}
              ：
            </span>
            <span>
              {getDateFormatWithTimezone(estimateResult?.estimateExpireDt)}
            </span>
          </div>
          <div className="w-33">
            <span className="label">
              {GetMessageWithIntl(intl, {
                prefixId: viewId,
                id: 'result.replyedAt',
              })}{' '}
              ：
            </span>
            <span>
              {getDateFormat(
                estimateResult?.sharedProperties?.sharedAt ?? '',
                'YYYY/MM/DD'
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-33">
            <span className="label">
              {GetMessageWithIntl(intl, {
                prefixId: viewId,
                id: 'result.deliveryTimeDt',
              })}{' '}
              ：
            </span>
            <span>
              {getDateFormatWithTimezone(estimateResult?.deliveryTimeDt)
                ? getDateFormatWithTimezone(estimateResult?.deliveryTimeDt) +
                  (estimateResult?.deliveryTimeRemarks
                    ? ' (' + estimateResult?.deliveryTimeRemarks + ')'
                    : '')
                : estimateResult?.deliveryTimeRemarks ?? ''}
            </span>
          </div>
          <div className="w-33">
            <span className="label">
              {GetMessageWithIntl(intl, {
                prefixId: viewId,
                id: 'result.leadTime',
              })}{' '}
              ：
            </span>
            <span>
              {(estimateResult?.leadTimeNum ?? '') +
                (estimateResult?.leadTimeUnit?.displayNameLang?.ja ?? '')}
            </span>
          </div>
          <div className="w-33">
            <span className="label">
              {GetMessageWithIntl(intl, {
                prefixId: viewId,
                id: 'result.orderExportedAt',
              })}{' '}
              ：
            </span>
            <span>
              {getDateFormat(
                estimateSelection?.orderExportedAt ?? '',
                'YYYY/MM/DD'
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-20">
            <Amountbox
              name="totalAmount"
              className="totalAmount"
              value={totalAmounts(
                ...(estimateResultDetailsData.current
                  ?.filter(
                    (v) =>
                      v.estimateResultId === estimateResult?.estimateResultId
                  )
                  .map((w) => toMonetaryAmount(w.amount)) ?? [])
              )}
              labelId="EST_ESTIMATE_REQUEST_CONFIRMATION.result.totalAmount"
              columns={['totalAmount']}
              disabled={true}
              displayOption={{ isCommaFormat: true }}
            />
          </div>
          {['B06', 'B11'].includes(
            estimateRequestUnit?.status?.systemName ?? ''
          ) && (
            <>
              <div className="w-20">
                <div className="change-area">
                  <CaptionButton
                    name="changeRequest"
                    className="changeRequest"
                    caption={GetMessageWithIntl(intl, {
                      prefixId: viewId,
                      id: 'result.changeRequest',
                    })}
                    buttonType="basic"
                    onClick={() => {
                      setOpenEstimateResultChangeRequestDialog(true);
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="result-detail-list">
        <SimpleListView
          data={estimateResultDetailList}
          viewOptions={{
            keyColumn: 'id',
            columns: estimateResultDetailColumns,
            omitFooter: true,
            previewRowCount: 3,
          }}
          actionOptions={{}}
        />
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-60">
            <Textarea
              name="estimateRemarks"
              labelId="EST_ESTIMATE_REQUEST_CONFIRMATION.result.estimateRemarks"
              className="w-100  mh-middle"
              value={estimateResult?.estimateRemarks ?? ''}
              columns={['estimateRemarks']}
              disabled={true}
            />
          </div>
        </div>
      </div>

      {/* 選考確認ダイアログ */}
      <EstimateSelectionDialog
        isOpen={isOpenEstimateSelectionDialog}
        inputOption={{
          isReadOnly: true,
          request: { ...estimateResult?.estimateRequest },
          requestUnit: estimateRequestUnit,
          data: {
            ...estimateSelection,
            estimateRequestId:
              estimateResult?.estimateRequest?.estimateRequestId,
          },
        }}
        onDecision={() => {}}
        onCancel={() => {
          setOpenEstimateSelectionDialog(false);
        }}
      />
      {/* 修正依頼 */}
      <CommentDialog
        isOpen={isOpenEstimateResultChangeRequestDialog}
        inputOption={{ comment: changeRequestComment, butonType: 'high' }}
        inputStateOption={{ onChangeComment: setChangeRequestComment }}
        messageOption={{
          headerLabelId: {
            id: 'modification_request',
            prefixId: 'DIALOG_TITLE',
          },
          messageLabelId: {
            id: 'modification_request',
            prefixId: 'DIALOG_DESCRIPTION',
          },
        }}
        onDecision={handleSurveyRequest}
        onCancel={() => {
          setOpenEstimateResultChangeRequestDialog(false);
          setChangeRequestComment('');
        }}
      />
      {/* 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isOpenConfirmDialog}
        viewMessage={confirmMessage}
        onDecision={handleConfirmed}
        onCancel={handleCancel}
      />
    </>
  );
};
