/**
 * ファイル操作とアセット関連
 */

import { IntlShape } from 'react-intl';
import { ViewId } from './viewId';
import { GetMessageWithIntl, error } from '~/shared/components';
import { AttachmentFile } from '~/shared/components/ui/Dialog/CommonDialogTypes';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import dayjs from 'dayjs';
import { getRandomStringId } from '../util';
import { FileUploadDialogResult } from '~/shared/components/ui';
import { getExceptionMessage, getWorkerExceptionMessage } from './error';
import { replaceProhibitedCharacters } from '../converter';

export interface AttachmentItems {
  id?: string;
  category?: sharelib.INameOption;
  assetId?: string;
  filename: string;
  url?: string;
  mimeType?: string;
  remarks?: string;
  linkType?: sharelib.INameOption;
}

export type CommmonAttachedFileSystemCode =
  | 'B01'
  | 'B02'
  | 'B03'
  | 'B04'
  | 'B05'
  | 'B06'
  | 'B07'
  | 'B08'
  | 'B09'
  | 'B10'
  | 'B11';
type CategorySystemCode = 'B01' | 'B02' | 'B03';
type LinkTypeSystemName = 'B01' | 'B02' | 'B03';

// 一括ダウンロード
export async function autoBulkDownload(
  assetIds: string[],
  intl: IntlShape,
  viewId: ViewId,
  filename?: string
) {
  const fileName =
    filename ||
    GetMessageWithIntl(intl, {
      prefixId: 'HEADER_TITLE',
      viewId: viewId,
    });
  return autoBulkDownloadWithName(assetIds, intl, fileName);
}
// 一括ダウンロード (ファイル名指定)
export const autoBulkDownloadWithName = async (
  assetIds: string[],
  intl: IntlShape,
  bulkFileName: string
) => {
  try {
    const result: mtechnavi.api.assetinventory.CreateZippedAssetResponse =
      await window.App.services.assetInventory.createZippedAsset({
        assetIds,
      });
    const assetId = result.assetId;
    autoDownloadFileWithDate(bulkFileName, 'zip', assetId);
  } catch (err) {
    error(getExceptionMessage(intl, err));
    throw err;
  }
};

const ASSET_BASE_URL = '/mtechnavi.api.assetinventory.AssetProxy/';

// 日付付加を行ったファイル自動ダウンロード
export async function autoDownloadFileWithDate(
  name: string,
  extensionType: string,
  assetId: string
) {
  // 付加日時取得
  const appendNowDate = dayjs().format('YYYYMMDDHHmmss');

  const fileName = `${replaceProhibitedCharacters(
    name
  )}_${appendNowDate}.${extensionType}`;

  await autoDownloadFile(`${ASSET_BASE_URL}${assetId}`, fileName);
}

// ファイル自動ダウンロード
export async function autoDownloadFileOnlyName(name: string, assetId: string) {
  if (name === '' || name === undefined) {
    return;
  }

  await autoDownloadFile(
    `${ASSET_BASE_URL}${assetId}`,
    replaceProhibitedCharacters(name)
  );
}

// エラーファイル自動ダウンロード
export async function autoDownloadErrorFile(
  name: string,
  extensionType: string,
  assetId: string
) {
  const fileName = `${replaceProhibitedCharacters(
    name
  )}_error.${extensionType}`;

  await autoDownloadFile(`${ASSET_BASE_URL}${assetId}`, fileName);
}

export function convertFileType(mimeType: string) {
  // 'jpg', 'jpeg', 'png'
  if (mimeType.includes('jpg') || mimeType.includes('jpeg')) {
    return 'jpg';
  }
  if (mimeType.includes('png')) {
    return 'png';
  }
  if (mimeType.includes('pdf')) {
    return 'pdf';
  }

  return '';
}

// ファイル自動ダウンロード
// ファイル名の指定がなければ、レスポンスヘッダ Content-Disposition: inline; filename=XXX からファイル名を取得する。
// Content-Disposition からファイル名を取得しないとURL末尾から自動推定される。
export async function autoDownloadFile(url: string, fileName: string) {
  const src = fileName ? `${url}/${fileName}` : url;
  fetch(src).then((response) => {
    const downloadFileName = fileName
      ? fileName
      : getFileNameFromResponse(response);
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);
      const alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = downloadFileName ?? '_blank';
      alink.click();
    });
  });
}

function getFileNameFromResponse(response: Response) {
  const contentDisposition = response.headers.get('content-disposition');
  if (!contentDisposition) return '';
  return getFileNameFromContentDisposition(contentDisposition);
}

function getFileNameFromContentDisposition(disposition: string): string | null {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/; // 正規表現でfilenameを抜き出す
  const matches = filenameRegex.exec(disposition);
  if (matches != null && matches[1]) {
    const fileName = matches[1].replace(/['"]/g, '');
    console.debug('fileName: ', fileName);
    return decodeURI(fileName); // 日本語対応
  } else {
    return null;
  }
}

/*
 添付ファイルのアセット作成処理
  AttachmentFile の型を扱うダイアログの場合に利用
  e.g.
   FileUploadWithCommentDialog
 */
// 添付ファイルのアセット作成(AttachmentFile)
export async function createAttachmentFiles(
  files: AttachmentFile[],
  systemCode: CommmonAttachedFileSystemCode
) {
  return await createAttachmentFilesWithCodes(files, systemCode, 'B03', 'B03');
}

/*
 添付ファイルのアセット作成処理
   ファイル分類/ファイル種別を指定しなければならない場合に使用
 */
export async function createAttachmentFilesWithCodes(
  files: AttachmentFile[],
  purposeUnitsSystemCode: CommmonAttachedFileSystemCode,
  categorySystemCode: CategorySystemCode,
  linkTypeSystemName: LinkTypeSystemName
) {
  const purposeUnits = window.App.services.ui.getNameOptionWithSystemName(
    'A0000033',
    purposeUnitsSystemCode
  );

  const reqs: mtechnavi.api.assetinventory.ICreateAssetRequest[] = [];
  files.map((file) => {
    if (!file.assetId) {
      reqs.push({
        asset: {
          displayName: file.filename ?? '',
          filename: file.filename ?? '',
          purposeUnits,
        },
        sourceUrl: file.url ?? '',
      });
    }
  });
  const assetRes = await window.App.services.ui.worker.apiCall({
    actionName: 'createAsset',
    request: reqs,
  });

  if (assetRes) {
    const tmpData = files.filter((v) => v.assetId) ?? [];
    const category = window.App.services.ui.getNameOptionWithSystemName(
      'A0000016',
      categorySystemCode
    );
    const linkType = window.App.services.ui.getNameOptionWithSystemName(
      'A0000017',
      linkTypeSystemName
    );
    assetRes.map((asset) => {
      let resultAsset: mtechnavi.api.assetinventory.IAsset = {};
      // Promise.allで複数件のアセットを追加する時、配列で返却される時があるのでその対応
      if (Array.isArray(asset)) {
        if (asset.length > 0) {
          resultAsset = asset[0];
        }
      } else {
        resultAsset = asset;
      }
      const id = getRandomStringId();
      tmpData.push({
        id: id,
        assetId: resultAsset.assetId ?? '',
        filename: resultAsset.filename ?? '',
        mimeType: resultAsset.mimeType ?? '',
        category: category.length > 0 ? category[0] : {},
        linkType: linkType.length > 0 ? linkType[0] : {},
      });
    });

    return tmpData;
  }
  return [] as AttachmentFile[];
}

/*
 添付ファイルのアセット作成処理
  FileUploadDialogを使用する場合に利用
  通常は共通処理 handleCommonFIleUpload を推奨
  処理内容を個別に実装する場合はアセット作成部分だけこちらを利用する想定
 */
export async function createAttachmentAsset(
  result: FileUploadDialogResult,
  systemCode: CommmonAttachedFileSystemCode
) {
  const purposeUnits = window.App.services.ui.getNameOptionWithSystemName(
    'A0000033',
    systemCode
  );

  const reqs: mtechnavi.api.assetinventory.ICreateAssetRequest[] = [];
  result.files.map((file) => {
    reqs.push({
      asset: {
        displayName: file.file.name ?? '',
        filename: file.file.name ?? '',
        purposeUnits,
      },
      sourceUrl: file.url ?? '',
    });
  });
  return await window.App.services.ui.worker.apiCall({
    actionName: 'createAsset',
    request: reqs,
  });
}

// ファイルダイアログアップロード処理(共通)
export async function handleCommonFIleUpload(
  currentData: AttachmentItems[],
  result: FileUploadDialogResult,
  systemCode: CommmonAttachedFileSystemCode,
  intl: IntlShape
) {
  return await handleFIleUpload(
    currentData,
    result,
    systemCode,
    'B03',
    'B03',
    intl
  );
}

/*
 ファイルダイアログアップロード処理
  ファイル分類/ファイル種別を指定しなければならない場合に使用
 */
export async function handleFIleUpload(
  currentData: AttachmentItems[],
  result: FileUploadDialogResult,
  purposeUnitsSystemCode: CommmonAttachedFileSystemCode,
  categorySystemCode: CategorySystemCode,
  linkTypeSystemName: LinkTypeSystemName,
  intl: IntlShape
) {
  try {
    const assetRes = await createAttachmentAsset(
      result,
      purposeUnitsSystemCode
    );

    if (assetRes) {
      const tmpData = currentData ?? [];
      const category = window.App.services.ui.getNameOptionWithSystemName(
        'A0000016',
        categorySystemCode
      );
      const linkType = window.App.services.ui.getNameOptionWithSystemName(
        'A0000017',
        linkTypeSystemName
      );
      assetRes.map((asset) => {
        let resultAsset: mtechnavi.api.assetinventory.IAsset = {};
        // Promise.allで複数件のアセットを追加する時、配列で返却される時があるのでその対応
        if (Array.isArray(asset)) {
          if (asset.length > 0) {
            resultAsset = asset[0];
          }
        } else {
          resultAsset = asset;
        }
        const id = getRandomStringId();
        tmpData.push({
          id: id,
          assetId: resultAsset.assetId ?? '',
          filename: resultAsset.filename ?? '',
          mimeType: resultAsset.mimeType ?? '',
          category: category.length > 0 ? category[0] : {},
          linkType: linkType.length > 0 ? linkType[0] : {},
        });
      });
      return tmpData;
    }
    return currentData;
  } catch (err) {
    error(getWorkerExceptionMessage(intl, err));
    throw err;
  }
}
