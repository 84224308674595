import { mtechnavi } from '~/shared/libs/clientsdk';
import { BlueprintExtractStatus } from './config';

/** 処理中判定 */
export const isBlueprintProcessing = (
  blueprintContent?: mtechnavi.api.blueprint.IBlueprintContent | null
) => {
  return (
    blueprintContent?.blueprint?.extractStatus ===
    BlueprintExtractStatus.Processing
  );
};
