import { createAssetImages } from './createAssetImages';
import { createAssetInventry } from './createAssetInventry';
import { createTemporaryImage } from './createTemporaryImage';

// actionName と function のマッピング
export const assetInventoryCallMap = {
  createAsset: createAssetInventry,
  createTemporaryImage: createTemporaryImage,
  createAssetImages: createAssetImages,
};
