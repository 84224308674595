import { useEffect, useState } from 'react';
import {
  Container,
  error,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
} from '~/shared/components';
import {
  ViewId,
  FullMethodName_ListCompanyContents,
  getWorkerExceptionMessage,
} from '~/shared/utils';
import { FullMethodName_ListAnalysisSettingItems } from '~/worker';
import { Tabs } from '~/shared/components/ui';
import './CompanyConfirmation.css';
import { CompanyConfirmationBasic } from './parts/CompanyConfirmationBasic';
import { CompanyConfirmationSkill } from './parts/CompanyConfirmationSkill';
import { CompanyConfirmationBranch } from './parts/CompanyConfirmationBranch';
import { CompanyConfirmationStrength } from './parts/CompanyConfirmationStrength';
import { CaptionButton } from '~/shared/components/ui/Button';
import { useIntl } from 'react-intl';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useLocation } from 'react-router-dom';
import { BusinessUnitAttributes } from '../converter';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { ConfirmationPagination } from './parts/ConfirmationPagination';
import { CompanySearchLocationState } from '../util';
import { CompanyConfirmationOrderAnalysis } from './parts/CompanyConfirmationOrderAnalysis';
import { CompanyConfirmationDetailAnalysis } from './parts/CompanyConfirmationDetailAnalysis';

const VIEW_ID: ViewId = 'CIF_COMPANY_CONFIRMATION';
const LIST_VIEW_ID: ViewId = 'CIF_COMPANY_LIST';
export const CompanyConfirmation = () => {
  const intl = useIntl();
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  // 遷移元から値を受け取る
  const location = (useLocation().state as CompanySearchLocationState) ?? [];

  const [isLoading, setLoading] = useState(false);

  const [currentCompany, setCurrentCompany] =
    useState<mtechnavi.api.analysis.IViewCompany>();
  const [skillTreeData, setSkillTreeData] = useState<BusinessUnitAttributes>();
  const [analysisSettingItems, setAnalysisSettingItems] = useState<
    mtechnavi.api.analysis.IAnalysisSettingItem[]
  >([]);

  const labelContact = GetMessageWithIntl(intl, {
    viewId: VIEW_ID,
    id: 'contact',
  });

  useEffect(() => {
    (async () => {
      const attributesRawData = await fetch(
        '/assets/conditions/BusinessUnitAttribute.json'
      );
      const attributes =
        (await attributesRawData.json()) as BusinessUnitAttributes;
      setSkillTreeData(attributes);
    })();
  }, []);

  // ページング処理
  const handleChangeCurrentCompany = async (companies: unknown) => {
    const company = (companies as mtechnavi.api.analysis.IViewCompany[]).at(0);
    setCurrentCompany(company);
    await fetchAnalysisSettingItems(company?.companyId);
  };

  // 分析系タブ向けの分析項目を取得
  const fetchAnalysisSettingItems = async (companyId?: string | null) => {
    if (!companyId) {
      return;
    }
    try {
      const results =
        await window.App.services.ui.worker.filter<mtechnavi.api.analysis.IAnalysisSettingItem>(
          {
            action: 'reload',
            fullMethodName: FullMethodName_ListAnalysisSettingItems,
            requestBody: { dataFilter: { companyId } },
            filter: {},
            sort: [{ id: 'asc' }],
          }
        );
      setAnalysisSettingItems(results.items ?? []);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    }
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="CompanyConfirmation">
          <div className="header">
            <ConfirmationPagination
              viewId={VIEW_ID}
              listViewId={LIST_VIEW_ID}
              fullMethodName={FullMethodName_ListCompanyContents}
              requestBody={{
                companyIds: location.pageSetting?.ids ?? [],
              }}
              filter={{
                companyId: { $in: location.pageSetting?.ids ?? [] },
              }}
              myEmail={myEmail}
              currentItemId={currentCompany?.companyId ?? ''}
              onChangeItem={handleChangeCurrentCompany}
              onChangeLoadingState={setLoading}
            />
            <CaptionButton
              buttonType="basic"
              name="contact"
              className="contact"
              caption={labelContact}
              onClick={() => {}}
            />
          </div>
          <div className="company-name">
            {currentCompany?.profile?.displayNameLangJa}
          </div>
          <Tabs
            tabItems={[
              {
                label: '基本情報',
                tabContent: (
                  <CompanyConfirmationBasic
                    viewId={VIEW_ID}
                    profile={currentCompany?.profile ?? {}}
                    certificates={currentCompany?.certificates ?? []}
                    financials={currentCompany?.financials ?? []}
                  />
                ),
              },
              {
                label: '拠点 / 設備',
                tabContent: (
                  <CompanyConfirmationBranch
                    viewId={VIEW_ID}
                    facilitiess={currentCompany?.facilities ?? []}
                    branches={currentCompany?.branchs ?? []}
                  />
                ),
              },
              {
                label: '強み',
                tabContent: (
                  <CompanyConfirmationStrength
                    viewId={VIEW_ID}
                    strengths={currentCompany?.strengths ?? []}
                    facilitiess={currentCompany?.facilities ?? []}
                    branches={currentCompany?.branchs ?? []}
                  />
                ),
              },
              {
                label: '技術情報',
                tabContent: (
                  <CompanyConfirmationSkill
                    viewId={VIEW_ID}
                    skills={currentCompany?.skills ?? []}
                    skillTree={skillTreeData}
                  />
                ),
              },
              {
                label: '発注分析',
                tabContent: (
                  <CompanyConfirmationOrderAnalysis
                    viewId={VIEW_ID}
                    companyId={currentCompany?.companyId ?? ''}
                    analysisSettingItems={analysisSettingItems}
                    mode="placement"
                  />
                ),
              },
              {
                label: '受注分析',
                tabContent: (
                  <CompanyConfirmationOrderAnalysis
                    viewId={VIEW_ID}
                    companyId={currentCompany?.companyId ?? ''}
                    analysisSettingItems={analysisSettingItems}
                    mode="receipt"
                  />
                ),
              },
              {
                label: '明細分析',
                tabContent: (
                  <CompanyConfirmationDetailAnalysis
                    viewId={VIEW_ID}
                    companyId={currentCompany?.companyId ?? ''}
                    analysisSettingItems={analysisSettingItems}
                  />
                ),
              },
            ]}
          />
        </div>
      </Container>
      <Toast />
      {isLoading && <LoadingIcon />}
    </>
  );
};
