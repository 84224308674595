import { useEffect, useRef, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Container, error } from '~/shared/components';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Preset,
  Textbox,
  SimplePageNavigation,
  MenuDataView,
  HeaderInfo,
} from '~/shared/components/ui';
import {
  GetMessage,
  GetMessageWithIntl,
} from '~/shared/components/parts/Message/Message';
import './CompanyConfirmation.css';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import './AssetConfirmation.css';
import { FILE_VIEWER_LOCALE_STORAGE_PREFIX } from '~/shared/components/ui/Viewer/FileViewer';

import { useAuth } from '~/shared/contexts/AuthProvider';
import {
  ViewId,
  PageState,
  getSortSettingFromLocalStorage,
  getExceptionMessage,
  getPresetAndSchema,
  getMaxMainContentsHeight,
  setLocalStorage,
  openWindowOrTab,
  FullMethodName_ListAssets,
  getWorkerExceptionMessage,
} from '~/shared/utils';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
const VIEW_ID: ViewId = 'ASSET_ASSET_CONFIRMATION';

type Asset = mtechnavi.api.assetinventory.Asset;

export interface DataViewItem {
  index: string;
  purpose: string;
  itemName: string;
  value: string;
}

export function AssetConfirmation() {
  // ログイン者の情報特定用email
  const myEmail = useAuth().user?.email ?? '';
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const [item, setItem] = useState<Asset>();
  const intl = useIntl();

  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);
  const _location = useLocation();
  const location = _location.state as PageState;
  const [dataViewItems, setDataViewItems] = useState<DataViewItem[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [accordionState, setAccordionState] = useState<{
    [k: string]: boolean;
  }>({
    confirmation: true,
    area1: true,
  });

  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListAssets,
    pageNumber: 1,
    pageSize: 1,
    maxPageNumber: 1,
    filter: {
      assetId: { $in: location.ids ?? [] },
    },
    sort: getSortSettingFromLocalStorage('ASSET_ASSET_LIST', myEmail),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListAssets,
      pageNumber: n,
    });
  };

  const handleAccordion = (type: string) => {
    setAccordionState({ ...accordionState, [type]: !accordionState[type] });
  };

  // 以下のフォーマットで指定されたキーを展開する
  // [識別コード]_[用途コード]_[CSVフォーマット名].[CSVフィールド名]
  const parsePurposeUnitPropertiesKey = (key: string): string[] => {
    const l = key.split('.');
    if (l.length != 2) {
      return [];
    }
    const [prop, name] = [l[0], l[1]];
    const pl = prop.split('_');
    if (pl.length != 3) {
      return [];
    }
    const properties = [pl[0], pl[1], pl[2]];
    return [...properties, name];
  };

  const viweDataitems = async (item: Asset, intl: IntlShape) => {
    let purposeUnits: sharelib.INameOption[] = [];
    try {
      purposeUnits = await window.App.services.ui.getNameOption('A0000033');
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    }

    const dataList: DataViewItem[] = [];
    if (!item || !item.purposeUnitProperties) return [];
    for (const [key, val] of Object.entries(item.purposeUnitProperties)) {
      const ks = parsePurposeUnitPropertiesKey(key);
      if (!ks.length) {
        continue;
      }
      const [indexCode, purposeCode, format, fieldName] = [
        ks[0],
        ks[1],
        ks[2],
        ks[3],
      ];
      const purposeUnit = purposeUnits.find(
        (v: sharelib.INameOption) => v.systemName === purposeCode
      );
      const purpose = purposeUnit?.displayNameLang?.ja ?? '';
      const itemName = GetMessageWithIntl(intl, {
        id: `${format}.${fieldName}`,
      });
      dataList.push({
        index: indexCode,
        purpose: purpose,
        itemName: itemName,
        value: val,
      });
    }
    // 識別コード順にソートして表示
    setDataViewItems(dataList.sort((a, b) => (a.index < b.index ? -1 : 1)));
  };
  useEffect(() => {
    const items = page.originalItems as unknown as Asset[];
    setItem(items[0]);
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
    (async () => {
      await viweDataitems(items[0], intl);
    })();

    // page 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // 初回画面用データ
  useEffect(() => {
    (async () => {
      try {
        // スキーマ情報、preset関係の情報を取得
        const { preset } = await getPresetAndSchema(VIEW_ID, [
          FullMethodName_ListAssets,
        ]);
        const items = page.originalItems as unknown as Asset[];
        (async () => {
          await viweDataitems(items[0], intl);
        })();
        // 取得した情報をセット
        setPreset(preset);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      }
    })();
    dispatch({
      type: 'reload',
      fullMethodName: FullMethodName_ListAssets,
    });

    // 初回時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  const setPurposeUnits = (list: sharelib.INameOption[]) => {
    const purposeItems: string[] = [];
    list.forEach((item) => {
      if (item?.displayNameLang?.ja) {
        purposeItems.push(item?.displayNameLang?.ja);
      }
    });
    return purposeItems.join(',');
  };

  const dataViewHeaderList: HeaderInfo[] = [
    { message: { id: 'purpose' }, width: '15%' },
    { message: { id: 'itemName' }, width: '25%' },
    { message: { id: 'value' }, width: '40%' },
  ];

  const viewerAction = async () => {
    const key = setLocalStorage(
      FILE_VIEWER_LOCALE_STORAGE_PREFIX,
      ([item?.assetId] ?? []) as string[]
    );
    openWindowOrTab('file-viewer', key);
  };

  return (
    <Container viewId={VIEW_ID}>
      <div className="AssetConfirmation">
        <div className="header"></div>
        <div
          className="scroll-main-contents-area"
          style={{
            maxHeight: mainContentHeight,
          }}
        >
          <div className="status-box">
            <div className="status-result">
              <Textbox
                name="assetStatus"
                className="field"
                disabled={true}
                type="text"
                // TODO 図面管理対応 2024/10/18
                // value={item?.status?.status?.displayNameLang?.ja ?? ''}
                value=""
                labelId="assetStatus"
                columns={preset.propertyNames}
              />
            </div>
          </div>
          <div className="asset-info">
            <div
              className={`input-blocktitle-outer ${
                accordionState.confirmation ? '' : 'close'
              }`}
            >
              <h3
                className="input-blocktitle"
                onClick={() => handleAccordion('confirmation')}
              >
                {GetMessage({ id: 'basicInfoTitle' })}
              </h3>
            </div>
            {accordionState.confirmation && (
              <div className="asset-body">
                <div className="inputfields">
                  <div className="field-block middle">
                    <Textbox
                      name="assetDisplayName"
                      className="field"
                      disabled={true}
                      value={item?.displayName ?? ''}
                      type="text"
                      labelId="assetDisplayName"
                      columns={preset.propertyNames}
                    />
                  </div>
                  <div className="field-block middle">
                    <Textbox
                      name="assetId"
                      disabled={true}
                      value={item?.assetId ?? ''}
                      type="text"
                      labelId="assetId"
                      columns={preset.propertyNames}
                    />
                  </div>
                </div>
                <div className="inputfields">
                  <div className="field-block  middle">
                    <Textbox
                      name="purposeUnits"
                      disabled={true}
                      value={setPurposeUnits(item?.purposeUnits ?? [])}
                      type="text"
                      labelId="purposeUnits"
                      columns={preset.propertyNames}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={`input-blocktitle-outer ${
              accordionState.area1 ? '' : 'close'
            }`}
          >
            <h3
              className="input-blocktitle"
              onClick={() => handleAccordion('area1')}
            >
              {GetMessage({ id: 'otherInfoTitle' })}
            </h3>
          </div>
          {accordionState.area1 && (
            <MenuDataView
              pageSizeOption={{ pageSize: 10, isDisplay: true }}
              header={dataViewHeaderList}
              data={dataViewItems}
            />
          )}
        </div>
        <div className="footer" ref={footerRef}>
          <div className="footer-contents">
            <div className="blockBtn">
              <CaptionButton
                name="backBtn"
                caption="戻る"
                className="button-margin"
                properties={[
                  {
                    name: 'backBtn',
                    propertyName: 'backBtn',
                    propertyValue: 'backBtn',
                  },
                ]}
                onClick={() => navigate('/assets-unit-list')}
                buttonType="basic"
              />
              <CaptionButton
                name="viewerBtn"
                caption="ビューワ"
                className="button-margin"
                properties={[
                  {
                    name: 'viewerBtn',
                    propertyName: 'viewerBtn',
                    propertyValue: 'viewerBtn',
                  },
                ]}
                onClick={() => viewerAction()}
                buttonType="basic"
              />
            </div>
            <SimplePageNavigation
              pageInfo={{ isFirstPage, isMaxPage, pageNumber: page.pageNumber }}
              handleMovePage={handleMovePage}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
