import { Textarea } from '~/shared/components/ui';
import {
  DataFilterbox,
  DataFilterboxItem,
  Filterbox,
  FilterboxItem,
} from '../Filterbox';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import {
  GetMessage,
  GetMessageWithIntl,
} from '~/shared/components/parts/Message/Message';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { FilterExpression, FullMethodName } from '~/worker';
import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  convertOrganizationStructureReferenceToFilterboxItem,
  convertOrganizationToDataFilterBox,
} from '~/shared/utils';
import { includeInputValidateError } from '~/shared/utils';
import { FullMethodName_ListUserAttributes } from '~/worker';
import { useAuth } from '~/shared/contexts/AuthProvider';

export interface ApprovalRequestInputOption {
  FullMethodName_ListUserAttributes: FullMethodName;
  organizations: mtechnavi.api.company.IOrganization[];
  organizationRelations: mtechnavi.api.company.IOrganizationRelation[];
  requestReciptId: string;
  isHighAffect?: boolean;
}

export interface OutputOption {
  requestReciptId: string;
  approvalOrganizationUnit: DataFilterboxItem[];
  approvalOrganizationStaff: FilterboxItem[];
  comment: string;
}

export interface ApprovalRequestDialogProps {
  isOpen: boolean;
  inputOption: ApprovalRequestInputOption;
  onDecision: (result: OutputOption) => void;
  onCancel: () => void;
}

export const ApprovalRequestDialog = (props: ApprovalRequestDialogProps) => {
  const approvalOrganaizationStaffItemType = {
    value: 'user.email',
    displayName: 'user.displayName',
  };
  const intl = useIntl();
  const myEmail = useAuth().user?.email ?? '';
  const { isOpen, inputOption } = props;
  const [approvalOrganizationItems, setApprovalOrganizationItems] = useState<
    DataFilterboxItem[]
  >([]);
  const [approvalOrganization, setApprovalOrganization] = useState<
    DataFilterboxItem[]
  >([]);
  const [approvalOrganizationStaff, setApprovalOrganizationStaff] = useState<
    FilterboxItem[]
  >([]);

  // 必須チェック用にBlurするための変数
  const [workingBlurOrganization, setWorkingBlurOrganization] =
    useState<Date>();
  const [workingBlurStaff, setWorkingBlurStaff] = useState<Date>();
  const [comment, setComment] = useState<string>('');
  const [organizationStaffCustomQuery, setOrganizationStaffCustomQuery] =
    useState<FilterExpression>({});
  const requiredInputArea = useRef(null);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const { organizations } = props.inputOption;
    if (!organizations) {
      return;
    }
    const filterBoxItems = convertOrganizationToDataFilterBox(organizations);
    setApprovalOrganizationItems(filterBoxItems);
    (async () => {
      const userApprovalOrg =
        await window.App.services.ui.getUserApprovalOrganization(myEmail);

      // ログインユーザーの代表承認組織をセットする
      const initialOrganization = filterBoxItems.filter(
        (item) => item.value === userApprovalOrg?.organizationId
      );
      setApprovalOrganization(initialOrganization);
    })();
    // props.inputOption.organizations, myEmail変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.inputOption.organizations, myEmail, isOpen]);

  const handleDecision = (result: OutputOption) => {
    if (isInputError()) {
      return;
    }

    setWorkingBlurOrganization(undefined);
    setWorkingBlurStaff(undefined);
    props.onDecision(result);
  };

  const isInputError = (): boolean => {
    const targetElm = document.querySelector('.approval-request-dialog');
    // フィルター項目を強制的にBlurする
    setWorkingBlurOrganization(new Date());
    setWorkingBlurStaff(new Date());
    const organizationValue =
      approvalOrganization.length > 0 ? approvalOrganization[0].value : '';
    const staffValue =
      approvalOrganizationStaff.length > 0
        ? approvalOrganizationStaff[0].value
        : '';
    if (
      includeInputValidateError(targetElm, intl, [
        {
          value: organizationValue,
          ref: requiredInputArea,
        },
        {
          value: staffValue,
          ref: requiredInputArea,
        },
      ])
    ) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (approvalOrganizationStaff.length > 0) {
      (async () => {
        const userRepresentativeOrg =
          await window.App.services.ui.getUserRepresentativeOrganization(
            approvalOrganizationStaff[0].value
          );
        // ユーザーの代表所属組織が存在するかつ、承認組織が空の場合承認組織をセット
        if (approvalOrganization.length === 0 && userRepresentativeOrg) {
          const setItem =
            convertOrganizationStructureReferenceToFilterboxItem(
              userRepresentativeOrg
            ) ?? '';
          setApprovalOrganization(setItem ? [setItem] : []);
        }
      })();
    }
    // approvalOrganizationStaff変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalOrganizationStaff]);

  useEffect(() => {
    const orgRelations = approvalOrganization.length
      ? props.inputOption.organizationRelations.filter(
          (item) => item.organizationId === approvalOrganization[0].value
        )
      : [];
    const userIds: string[] = [];
    orgRelations?.map((item) => {
      if (item.userId) {
        userIds.push(item.userId);
      }
    });
    approvalOrganization.length
      ? setOrganizationStaffCustomQuery({
          'user.userId': { $in: userIds },
          approver: { $eq: true },
        })
      : setOrganizationStaffCustomQuery({
          approver: { $eq: true },
        });
    // approvalOrganization変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalOrganization]);

  const view = (
    <div className="approval-request-dialog dialog-with-description">
      <div className="description-area">
        <p className="text-box">
          <span className="text">
            {GetMessage({
              prefixId: 'DIALOG_DESCRIPTION',
              id: 'approval_request',
            })}
          </span>
        </p>
      </div>
      <div className="detail-area">
        <div className="comment-area">
          <div className="input-line" ref={requiredInputArea}>
            <div className="item-group-100">
              <div className="w-100">
                <DataFilterbox
                  name="approvalOrganizationUnits"
                  labelId="approvalRequestDialog.approval_organization_unit"
                  columns={['approvalOrganizationUnits']}
                  data={approvalOrganizationItems}
                  value={
                    approvalOrganization ?? [{ value: '', displayName: '' }]
                  }
                  validateOption={{ required: true }}
                  searchOption={{
                    targets: 'displayName',
                  }}
                  onChangeState={(v) => {
                    setApprovalOrganizationStaff([]);
                    setApprovalOrganization(v);
                  }}
                  workingBlur={workingBlurOrganization}
                />
              </div>
            </div>
          </div>
          <div className="input-line" ref={requiredInputArea}>
            <div className="item-group-100">
              <div className="w-50">
                <Filterbox
                  name="approvalOrganizationStaff"
                  fullMethodName={
                    inputOption.FullMethodName_ListUserAttributes ??
                    FullMethodName_ListUserAttributes
                  }
                  labelId="approvalRequestDialog.approval_organization_staff"
                  columns={['approvalOrganizationStaff']}
                  value={
                    approvalOrganizationStaff ?? [
                      { value: '', displayName: '' },
                    ]
                  }
                  itemType={approvalOrganaizationStaffItemType}
                  validateOption={{ required: true }}
                  searchOption={{
                    targets: 'displayName',
                    customQuery: organizationStaffCustomQuery,
                  }}
                  onChangeState={setApprovalOrganizationStaff}
                  workingBlur={workingBlurStaff}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <Textarea
                  name="comment"
                  labelId="approvalRequestDialog.comment"
                  value=""
                  columns={['comment']}
                  className="w-100 mh-middle"
                  onChangeState={setComment}
                />
              </div>
            </div>
          </div>
          <div className="button-area">
            <CaptionButton
              name="cancelBtn"
              buttonType="cancel"
              className="button"
              caption={GetMessageWithIntl(intl, { id: 'cancel' })}
              onClick={() => props.onCancel()}
            />
            <CaptionButton
              name="sendBtn"
              buttonType={inputOption.isHighAffect ? 'high' : 'basic'}
              className="button"
              caption={GetMessage({
                prefixId: 'DIALOG_CAPTION',
                id: 'send_approval_request',
              })}
              onClick={() =>
                handleDecision({
                  requestReciptId: inputOption.requestReciptId,
                  approvalOrganizationUnit: approvalOrganization,
                  approvalOrganizationStaff,
                  comment,
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: 'approval_request',
    },
    messageLabelId: {},
    elements: view,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
