import Long from 'long';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, RadioButton } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import {
  SegmentConfirmationDialog,
  SegmentConfirmationDialogProps,
  SegmentConfirmationDialogResult,
} from '~/shared/components/ui/Dialog/SegmentConfirmationDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { BlueprintSegmentNames } from '~/tenant/blueprint/utils';
import { AssetSelector } from '~/tenant/debug/utils/AssetSelector';

type Mode = SegmentConfirmationDialogProps['inputOption']['mode'];

export const UISampleSegmentConfirmationDialogs = () => {
  const [isShow, setShow] = useState(false);
  const [result, setResult] = useState<SegmentConfirmationDialogResult>(null);

  const [useFront, setUseFront] = useState(true);
  const [useRight, setUseRight] = useState(true);
  const [useBottom, setUseBottom] = useState(true);
  const [mode, setMode] = useState<Mode>('edit');

  const [previewAsset, setPreviewAsset] =
    useState<mtechnavi.api.assetinventory.IAsset>();

  return (
    <>
      <div className="categories">
        <h3 id="dialog">セグメント確認ダイアログ</h3>
        <h4>事前準備</h4>
        <AssetSelector
          selectedAsset={previewAsset}
          onSelectChange={setPreviewAsset}
        />
        <h4>本体</h4>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-33">
              <CaptionButton
                buttonType="basic"
                caption="確認する"
                name=""
                disabled={!previewAsset}
                onClick={() => {
                  setShow(true);
                }}
              />
              <h5>設定済の面のセット(最大３つを詰めて渡す)</h5>
              <Checkbox
                name=""
                columns={['']}
                value={useFront ? '1' : undefined}
                items={[{ displayName: '正面', value: '1' }]}
                onChangeState={(v) => {
                  setUseFront(!!v.length);
                }}
              />
              <Checkbox
                name=""
                columns={['']}
                value={useRight ? '1' : undefined}
                items={[{ displayName: '右面', value: '1' }]}
                onChangeState={(v) => {
                  setUseRight(!!v.length);
                }}
              />
              <Checkbox
                name=""
                columns={['']}
                value={useBottom ? '1' : undefined}
                items={[{ displayName: '下面', value: '1' }]}
                onChangeState={(v) => {
                  setUseBottom(!!v.length);
                }}
              />
              <RadioButton
                name=""
                columns={['']}
                value={mode}
                items={[
                  { displayName: 'セグメント確認', value: 'confirm' },
                  { displayName: 'セグメント編集', value: 'edit' },
                  { displayName: '検索条件', value: 'searchCondition' },
                ]}
                onChangeState={(v) => setMode(v as Mode)}
              />
            </div>
            <div className="w-66">
              <pre className="output">
                {result && JSON.stringify(result, null, 2)}
              </pre>
            </div>
          </div>
        </div>
        <SegmentConfirmationDialog
          key={isShow ? 1 : 0}
          isOpen={isShow}
          messageOption={{
            headerLabel: {
              prefixId: 'DIALOG_TITLE',
              id: 'SegmentConfirmationDialog',
            },
          }}
          inputOption={{
            mode,
            assetId: previewAsset?.assetId ?? '',
            segment: result ?? {
              imageProperty: {
                imageHeight: Long.fromValue(1000),
                imageWidth: Long.fromValue(1000),
              },
              segmentPropertys: [
                ...(useFront
                  ? [
                      {
                        fixed: false,
                        left: Long.fromValue(0),
                        top: Long.fromValue(0),
                        width: Long.fromValue(500),
                        height: Long.fromValue(500),
                        segmentName: BlueprintSegmentNames.Front,
                      },
                    ]
                  : []),
                ...(useRight
                  ? [
                      {
                        fixed: false,
                        left: Long.fromValue(500),
                        top: Long.fromValue(500),
                        width: Long.fromValue(500),
                        height: Long.fromValue(500),
                        segmentName: BlueprintSegmentNames.Right,
                      },
                    ]
                  : []),
                ...(useBottom
                  ? [
                      {
                        fixed: false,
                        left: Long.fromValue(250),
                        top: Long.fromValue(250),
                        width: Long.fromValue(500),
                        height: Long.fromValue(500),
                        segmentName: BlueprintSegmentNames.Bottom,
                      },
                    ]
                  : []),
              ],
            },
          }}
          onDecision={async (result) => {
            setResult(result);
            setShow(false);
          }}
          onCancel={() => {
            setShow(false);
          }}
        />
      </div>
    </>
  );
};
