import { GridValidRowModel, DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import {
  DataGridPropsWithDefaultValues,
  DataGridPropsWithoutDefaultValue,
} from '@mui/x-data-grid/internals';
import { useRef } from 'react';
import './DataGrid.css';

export interface DataGridProps<T extends GridValidRowModel>
  extends Pick<DataGridPropsWithoutDefaultValue<T>, 'columns'>,
    Pick<DataGridPropsWithDefaultValues<T>, 'rows'> {
  // 固有のプロパティを渡したければ追記する
}

const PAGE_SIZE = 100;

/**
 * MUI の x-data-grid のラッパー
 */
export const DataGrid = <T extends GridValidRowModel>({
  columns,
  rows,
}: DataGridProps<T>) => {
  const gridRef = useRef<HTMLDivElement | null>(null);
  const gridWidth = gridRef.current?.clientWidth ?? 0;

  const styledColumns = columns.map((col, _, all) => {
    const width = Math.floor(gridWidth / all.length);
    return {
      ...col,
      // ヘッダの class 属性と横幅をセットする
      headerClassName: `header ${col.headerClassName ?? ''}`,
      width: col.width ?? width,
    };
  });

  const identifiedRows = rows.map((row, index) => {
    return Object.hasOwn(row, 'id')
      ? row
      : {
          ...row,
          // id 列を持っていなかったら index を id とする
          id: index,
        };
  });

  return (
    <div className="DataGrid">
      <MuiDataGrid
        ref={gridRef}
        columns={styledColumns}
        rows={identifiedRows}
        disableColumnSorting
        disableColumnFilter
        disableColumnMenu
        initialState={{
          pagination: { paginationModel: { pageSize: PAGE_SIZE, page: 0 } },
        }}
        pageSizeOptions={[PAGE_SIZE]}
        hideFooter={rows.length <= PAGE_SIZE}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
      />
    </div>
  );
};
