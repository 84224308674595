import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../..';
import { CaptionButton } from '../Button/CaptionButton';
import './DetailConfirmationDialog.css';
import { Checkbox } from '../Checkbox';
import { useEffect, useState } from 'react';
import {
  openWindowOrTab,
  setLocalStorage,
  convertLongToString,
  getDateFormatWithTimezone,
  getExceptionMessage,
} from '~/shared/utils';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';

import { Textarea } from '../Textarea';
import { FILE_VIEWER_LOCALE_STORAGE_PREFIX } from '~/shared/components/ui';
import { error } from '~/shared/components/parts/Toast/Toast';
import { getBlueprintContent } from '~/tenant/blueprint/utils';

interface DetailConfirmationDialogInputOption {
  detailInfo: mtechnavi.api.estimation.IEstimateRequestDetail;
}

interface DetailConfirmationDialogProps {
  isOpen: boolean;
  inputOption: DetailConfirmationDialogInputOption;
  onCancel: () => void;
}

export const DetailConfirmationDialog = ({
  isOpen,
  inputOption,
  onCancel,
}: DetailConfirmationDialogProps) => {
  const intl = useIntl();
  const detailInfo = inputOption.detailInfo;
  const handleCancel = () => {
    onCancel();
  };
  const [attachmentFile, setAttachmentFile] = useState<sharelib.IAttachment>();

  useEffect(() => {
    if (isOpen) {
      (async () => {
        // 図面マスタから必要であればアセットを取得する
        if (detailInfo.blueprint?.blueprintId) {
          try {
            const blueprintContent = await getBlueprintContent(
              detailInfo.blueprint?.blueprintId,
              detailInfo.blueprint?.revision?.toString()
            );
            if (
              blueprintContent?.blueprintRevision?.blueprintFile?.attachment
            ) {
              setAttachmentFile(
                blueprintContent.blueprintRevision.blueprintFile.attachment
              );
            }
          } catch (err) {
            error(getExceptionMessage(intl, err));
            throw err;
          }
        } else if (detailInfo.attachment) {
          setAttachmentFile(detailInfo.attachment);
        }
      })();
    }
    // isOpen 変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // ビューワー処理
  const handleFileViewer = (assetId: string) => {
    const key = setLocalStorage(FILE_VIEWER_LOCALE_STORAGE_PREFIX, [assetId]);
    openWindowOrTab('file-viewer', key);
  };

  const viewLabelName = (id: string) => {
    const label = GetMessageWithIntl(intl, {
      id: id,
      prefixId: 'detailConfirmationDialog',
    });
    return `${label}:`;
  };

  const getRequiredAnswerItem = () => {
    const displayItem: string[] = [];
    // 材料費
    if (detailInfo.requiredMaterialCost) {
      displayItem.push(
        GetMessageWithIntl(intl, {
          id: 'requiredAnswerItem.materialFee',
          prefixId: 'detailConfirmationDialog',
        })
      );
    }
    // 加工費
    if (detailInfo.requiredProcessingCost) {
      displayItem.push(
        GetMessageWithIntl(intl, {
          id: 'requiredAnswerItem.processingFee',
          prefixId: 'detailConfirmationDialog',
        })
      );
    }
    // 管理費
    if (detailInfo.requiredAdministrativeCost) {
      displayItem.push(
        GetMessageWithIntl(intl, {
          id: 'requiredAnswerItem.managementFee',
          prefixId: 'detailConfirmationDialog',
        })
      );
    }
    // 運送費
    if (detailInfo.requiredFreightCost) {
      displayItem.push(
        GetMessageWithIntl(intl, {
          id: 'requiredAnswerItem.transportationFee',
          prefixId: 'detailConfirmationDialog',
        })
      );
    }
    return displayItem.join(',');
  };

  const elements = (
    <div className="detail-confirmation-dialog">
      <div className="detail-area">
        <p className="detail-number">
          {GetMessageWithIntl(intl, {
            id: 'detailNumber',
            prefixId: 'detailConfirmationDialog',
          })}
          {convertLongToString(detailInfo.detailAutoName)}
        </p>
        <p className="transunit-name">
          {viewLabelName('transactionUnit')}
          {detailInfo.displayName ?? ''}
        </p>

        <div className="input-line confirmation-line">
          <div className="item-group-100">
            <div className="w-25">
              {/* 数量 */}
              {viewLabelName('quantityFractionalAmount')}
              {detailInfo.quantity?.amountNum ?? ''}
              {detailInfo.quantity?.unit?.displayNameLang?.ja ?? ''}
            </div>
            <div className="w-25">
              {/* 検査証必要 */}
              <Checkbox
                name="inspection"
                items={[
                  {
                    value: '1',
                    displayName: GetMessageWithIntl(intl, {
                      prefixId: 'detailConfirmationDialog',
                      id: 'inspection',
                    }),
                  },
                ]}
                columns={['inspection']}
                value={detailInfo.inspection ? ['1'] : []}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="input-line confirmation-line">
          <div className="item-group-100">
            <div className="w-25">
              {/* 図番 */}
              {viewLabelName('blueprintNumber')}
              {detailInfo.blueprint?.blueprintNumber ?? ''}
            </div>
            <div className="w-25">
              {/* 図面 */}
              {attachmentFile?.assetId && (
                <a
                  onClick={() => {
                    handleFileViewer(attachmentFile.assetId ?? '');
                  }}
                  className="file-name"
                >
                  {attachmentFile.filename ?? ''}
                </a>
              )}
            </div>
            <div className="w-25">
              {/* 更新番号 */}
              {viewLabelName('revision')}
              {detailInfo.blueprint?.revision ?? ''}
            </div>
          </div>
        </div>

        <div className="input-line confirmation-line">
          <div className="item-group-100">
            <div className="w-25">
              {/* 工程 */}
              {viewLabelName('process')}
              {detailInfo.processs
                ?.map((v) => v.displayNameLang?.ja)
                .join(',') ?? ''}
            </div>
            <div className="w-25">
              {/* 型式 */}
              {viewLabelName('modelNumber')}
              {detailInfo.modelNumber ?? ''}
            </div>
            <div className="w-25">
              {/* メーカー */}
              {viewLabelName('manufacture')}
              {detailInfo.manufacture ?? ''}
            </div>
          </div>
        </div>

        <div className="input-line confirmation-line">
          <div className="item-group-100">
            <div className="w-50">
              {/* 必須回答費目 */}
              {viewLabelName('requiredAnswerItem')}
              {getRequiredAnswerItem()}
            </div>
          </div>
        </div>

        <div className="input-line">
          <div className="item-group-100">
            <div className="w-75">
              {/* 連絡事項 */}
              <Textarea
                name="contactMatter"
                labelId="detailConfirmationDialog.contactMatter"
                value={detailInfo.contactMatter ?? ''}
                columns={['contactMatter']}
                className="w-100 mh-middle"
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="input-line confirmation-line">
          <div className="item-group-100">
            <div className="w-25">
              {/* 支給品有り */}
              <Checkbox
                name="suppliedParts"
                labelId=""
                items={[
                  {
                    displayName: GetMessageWithIntl(intl, {
                      id: 'suppliedParts',
                      prefixId: 'detailConfirmationDialog',
                    }),
                    value: '1',
                  },
                ]}
                columns={['suppliedParts']}
                value={detailInfo.suppliedParts ? ['1'] : []}
                disabled={true}
              />
            </div>
            <div className="w-25">
              {/* 支給日 */}
              {viewLabelName('suppliedDate')}
              {getDateFormatWithTimezone(detailInfo.suppliesDt)}
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-75">
              {/* 支給方法/場所 */}
              <Textarea
                name="contactMatter"
                labelId="detailConfirmationDialog.suppliesRemarks"
                value={detailInfo.suppliesRemarks ?? ''}
                columns={['contactMatter']}
                className="w-100 mh-middle"
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={handleCancel}
        />
      </div>
    </div>
  );
  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      id: 'detailConfirmation',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <div className="DetailConfirmationDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
