export const SURVEY_REQUEST_SENDER_CONFIRMATION_URL =
  '/supplier-base/survey-request-sender-confirmation';
export const SURVEY_REQUEST_RECEIVER_CONFIRMATION_URL =
  '/supplier-base/survey-request-receiver-confirmation';
export const WORKTASK_CONFIRMATION_URL = '/work-task/wt-worktask-confirmation';
export const ESTIMATE_REQUEST_CONFIRMATION_URL =
  '/estimate/est-estimate-request-confirmation';
export const ESTIMATE_RESULT_CONFIRMATION_URL =
  '/estimate/est-estimate-result-confirmation';
export const BLUEPRINT_CONFIRMATION_URL = '/blueprint/blueprint-confirmation';
