import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  OverlayItem,
  OverlayItemProps,
} from '~/shared/components/ui/Viewer/parts/ViewerOverlay';
import {
  Arrow,
  Rect,
  SHAPE_TYPE_ARROW,
  SHAPE_TYPE_RECT,
} from '~/shared/components/ui/Viewer/parts/Shapes';
import { ShapeInfo, ViewerInfo } from '~/shared/components/ui';

interface OverlayOptions {
  threadList?: mtechnavi.api.forum.IThread[] | null;
  activeThreadId?: string | null;
  draft?: ShapeInfo | null;
  viewerInfo?: ViewerInfo;
  onClickSelection?: (threadId?: string | null) => void;
}

/**
 * Viewer に重ねるスレッドの範囲選択描画
 */
export const renderOverlayShapes = ({
  threadList,
  activeThreadId,
  draft,
  viewerInfo,
  onClickSelection,
}: OverlayOptions): OverlayItem[] => {
  if (!viewerInfo) {
    return [];
  }
  const threadShapes = (threadList ?? []).map((thread): OverlayItem | null => {
    if (!thread.attachedPoint || !viewerInfo) {
      return null;
    }
    return getOverlayContent(
      thread.attachedPoint.attributes?.selectionShape ?? '',
      {
        plotInfo: {
          x1: thread.attachedPoint.x1 ?? 0,
          y1: thread.attachedPoint.y1 ?? 0,
          x2: thread.attachedPoint.x2 ?? 0,
          y2: thread.attachedPoint.y2 ?? 0,
        },
        isActive: thread.threadId === activeThreadId,
        onClick: () => {
          onClickSelection && onClickSelection(thread.threadId);
        },
      }
    );
  });
  if (draft && draft?.attributes?.selectionShape) {
    threadShapes.push(
      getOverlayContent(draft.attributes.selectionShape, {
        plotInfo: draft,
        isActive: true,
      })
    );
  }
  return threadShapes.filter((item) => item !== null) as OverlayItem[];
};

const getOverlayContent = (
  shapeType: string,
  overlayItemProps: OverlayItemProps
): OverlayItem | null => {
  switch (shapeType) {
    case SHAPE_TYPE_RECT:
      return { ...overlayItemProps, component: Rect };
    case SHAPE_TYPE_ARROW:
      return { ...overlayItemProps, component: Arrow };
    default:
      return null;
  }
};
