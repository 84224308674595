import {
  ViewId,
  AttachmentItems,
  getWorkerExceptionMessage,
  getDateFormatWithTimezone,
  autoBulkDownload,
  autoDownloadFileOnlyName,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useIntl } from 'react-intl';
import { useState, useCallback } from 'react';
import {
  GetMessage,
  success,
  error,
  GetMessageWithIntl,
} from '~/shared/components';
import {
  Textarea,
  SimpleListView,
  Accordion,
  Image,
} from '~/shared/components/ui';
import { CommentDialog } from '~/shared/components/ui/Dialog';
import { SimpleListDialog } from '~/shared/components/ui/Dialog/SimpleListDialog';
import { isCanBeCompanyMemo } from 'tenant/src/supplierBase/utils/validator';
import { IconButton } from '~/shared/components/ui/Button/IconButton';
import { CaptionButton } from '~/shared/components/ui/Button';
import { formatUserWIthEmail } from '~/shared/utils/formatter';

interface EstimateRequestTabItemProps {
  viewId: ViewId;
  sharedContactContents?: mtechnavi.api.company.ISharedContactContent[];
  estimateManagement?: mtechnavi.api.estimation.IEstimateManagement;
  estimateRequest?: mtechnavi.api.estimation.IEstimateRequest;
  estimateRequestUnits?: mtechnavi.api.estimation.IEstimateRequestUnit[];
  onChangeLoadingState?: (arg: boolean) => void;
  handleReload?: () => void;
}

/**
 * 依頼先情報
 *  シンプルリストダイアログのカラム情報
 */
interface EstimateRequestUnitViewItem {
  id: string;
  code: string;
  displayName: string;
  to: string;
}

const VIEW_ID: ViewId = 'EST_ESTIMATE_REQUEST_CONFIRMATION';

const attachmentColumns = [
  {
    header: { id: 'attachment.filename', viewId: VIEW_ID },
    propertyName: 'filename',
    width: '15rem',
  },
  {
    header: { id: 'attachment.remarks', viewId: VIEW_ID },
    propertyName: 'remarks',
    readonly: true,
  },
];

const estimateRequestUnitsColumns = [
  {
    header: {
      id: 'code',
      prefixId: 'estimateUnitDialog',
    },
    propertyName: 'code',
    width: '10%',
  },
  {
    header: {
      id: 'request_unit_display_name',
      prefixId: 'estimateUnitDialog',
    },
    propertyName: 'displayName',
    width: '40%',
  },
  {
    header: {
      id: 'to',
      prefixId: 'estimateUnitDialog',
    },
    propertyName: 'to',
    width: '50%',
  },
];

export const EstimateRequestTabItem = ({
  viewId,
  sharedContactContents,
  estimateManagement,
  estimateRequest,
  estimateRequestUnits,
  onChangeLoadingState,
  handleReload,
}: EstimateRequestTabItemProps) => {
  const intl = useIntl();
  // メッセージ
  const successMessage = GetMessage({ id: 'I0000001' });

  const [isOpenEstimateUnitDialog, setOpenEstimateUnitDialog] = useState(false);
  const [isOpenCompanyMemoDialog, setOpenCompanyMemoDialog] = useState(false);
  const [companyMemo, setCompanyMemo] = useState('');

  const estimateUnitItems = (): EstimateRequestUnitViewItem[] => {
    if (!estimateRequestUnits) {
      return [];
    }
    const vals: EstimateRequestUnitViewItem[] = [];
    estimateRequestUnits.map((v) => {
      vals.push({
        id: v.estimateRequestUnitId ?? '',
        code: v.code ?? '',
        displayName:
          sharedContactContents
            ?.filter((w) =>
              v.businessUnitContactIds?.includes(
                w.businessUnitContact?.businessUnitContactId ?? ''
              )
            )
            .map((x) => x.displayName)
            .join('\n') ?? '',
        to:
          v.contactUsers
            ?.map((w) => {
              return formatUserWIthEmail(w.displayName, w.email);
            })
            .join(',') ?? '',
      });
    });
    vals.sort((val1, val2) => val1.code.localeCompare(val2.code, 'ja'));
    return vals;
  };

  const onParentLoadingState = useCallback(
    (arg: boolean) => {
      onChangeLoadingState && onChangeLoadingState(arg);
    },
    [onChangeLoadingState]
  );

  /**
   * 社内メモ更新ダイアログ確定処理
   */
  const handleRemarksSave = async () => {
    onParentLoadingState(true);
    try {
      const req: mtechnavi.api.estimation.IEstimateManagement = {
        ...estimateManagement,
        remarks: companyMemo,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'updateEstimateManagement',
        request: req,
      });
      success([successMessage]);
      handleReload && handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      onParentLoadingState(false);
    }
  };

  return (
    <>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-66 trackingCode">
            <span className="label">
              {GetMessageWithIntl(intl, {
                prefixId: viewId,
                id: 'request.trackingCode',
              })}{' '}
              ：
            </span>
            <span>{estimateRequest?.trackingCode}</span>
          </div>
          <div className="w-33">
            <CaptionButton
              name="requestUnit"
              className="requestUnit"
              caption={GetMessageWithIntl(intl, {
                prefixId: viewId,
                id: 'request.requestUnit',
              })}
              buttonType="basic"
              onClick={() => {
                setOpenEstimateUnitDialog(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-66">
            <span className="label">
              {GetMessageWithIntl(intl, {
                prefixId: viewId,
                id: 'request.orderPlan',
              })}{' '}
              ：
            </span>
            <span>
              {estimateRequest?.orderPlanDt?.accuracy
                ? `${getDateFormatWithTimezone(estimateRequest?.orderPlanDt)} ${
                    estimateRequest?.orderPlanRemarks
                      ? '(' + estimateRequest?.orderPlanRemarks + ')'
                      : ''
                  }`
                : estimateRequest?.orderPlanRemarks ?? ''}
            </span>
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-66">
            <span className="label">
              {GetMessageWithIntl(intl, {
                prefixId: viewId,
                id: 'request.requester',
              })}{' '}
              ：
            </span>
            <span>
              {(estimateRequest?.requesterDepartment
                ? estimateRequest?.requesterDepartment + ' '
                : '') + (estimateRequest?.requesterResponsible ?? '')}
            </span>
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-50">
            <span className="label">
              {GetMessageWithIntl(intl, {
                prefixId: viewId,
                id: 'request.topPriorityContent',
              })}{' '}
              ：
            </span>
            <span>
              {estimateRequest?.topPriorityContent?.displayNameLang?.ja}
            </span>
          </div>
          <div className="w-50">
            <span className="label">
              {GetMessageWithIntl(intl, {
                prefixId: viewId,
                id: 'request.purpose',
              })}{' '}
              ：
            </span>
            <span>{estimateRequest?.purpose?.displayNameLang?.ja}</span>
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-100">
            <Textarea
              name="notice"
              labelId="EST_ESTIMATE_REQUEST_CONFIRMATION.request.notice"
              className="w-100  mh-middle"
              value={estimateRequest?.notice ?? ''}
              columns={['notice']}
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-50">
            <span className="label">
              {GetMessageWithIntl(intl, {
                prefixId: viewId,
                id: 'request.deliveryPlace',
              })}{' '}
              ：
            </span>
            <span>{estimateRequest?.deliveryPlace}</span>
          </div>
        </div>
      </div>
      <div className="input-line label-margin">
        <div className="item-group-100">
          <div className="w-50">
            <span className="label">
              {GetMessageWithIntl(intl, {
                prefixId: viewId,
                id: 'request.deliveryPhoneNumber',
              })}{' '}
              ：
            </span>
            <span>{estimateRequest?.deliveryPhoneNumber}</span>
          </div>
          <div className="w-25">
            {/* 納入場所（地図） */}
            <div className="map-upload">
              <Image
                name="deliveryPlaceImage"
                src={{
                  assetId:
                    estimateRequest?.deliveryImage?.assetId ??
                    estimateRequest?.deliveryAttachment?.assetId ??
                    '',
                }}
                columns={['deliveryPlaceImage']}
                labelId={{
                  prefixId: viewId,
                  id: 'request.deliveryImage',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="attachment-list">
        <SimpleListView
          data={estimateRequest?.attachments ?? []}
          viewOptions={{
            keyColumn: 'id',
            columns: attachmentColumns,
          }}
          actionOptions={{
            onRowClick: (item: AttachmentItems) => {
              autoDownloadFileOnlyName(item.filename ?? '', item.assetId ?? '');
            },
            onFullDownLoad: () => {
              autoBulkDownload(
                (estimateRequest?.attachments ?? []).map(
                  (item) => item.assetId ?? ''
                ),
                intl,
                viewId
              );
            },
          }}
        />
      </div>
      {/* ////社内管理情報//// */}
      <Accordion
        title={GetMessageWithIntl(intl, {
          id: 'request.managementInfo',
          viewId: viewId,
        })}
      >
        <div className="input-line label-margin">
          <div className="item-group-100">
            <div className="w-25-icon">
              <IconButton
                name=""
                iconType="edit"
                buttonType="basic"
                onClick={() => {
                  setOpenCompanyMemoDialog(true);
                }}
                disabled={!isCanBeCompanyMemo(estimateManagement?.status)}
              />
            </div>
          </div>
        </div>
        <div className="input-line label-margin">
          <div className="item-group-100">
            <div className="w-100">
              <Textarea
                name="remarks"
                labelId="EST_ESTIMATE_REQUEST_CONFIRMATION.request.remarks"
                className="w-100  mh-middle"
                value={estimateManagement?.remarks ?? ''}
                columns={['remarks']}
                disabled
              />
            </div>
          </div>
        </div>
      </Accordion>
      {/* 依頼先情報ダイアログ */}
      <SimpleListDialog
        isOpen={isOpenEstimateUnitDialog}
        messageOption={{
          headerLabelId: {
            id: 'estimateUnit',
            prefixId: 'DIALOG_TITLE',
          },
        }}
        data={estimateUnitItems()}
        actionOption={{}}
        viewOption={{
          readonly: true,
          previewRowCount: 6,
          keyColumn: 'id',
          columns: estimateRequestUnitsColumns,
        }}
        onCancel={() => {
          setOpenEstimateUnitDialog(false);
        }}
      />

      {/* 社内メモ */}
      <CommentDialog
        isOpen={isOpenCompanyMemoDialog}
        inputOption={{ comment: estimateManagement?.remarks ?? '' }}
        inputStateOption={{ onChangeComment: setCompanyMemo }}
        messageOption={{
          headerLabelId: {
            id: 'estimate_company_memo',
            prefixId: 'DIALOG_TITLE',
          },
          captionLabelId: {
            id: 'company_memo',
            prefixId: 'DIALOG_CAPTION',
          },
          decisionLabelId: { id: 'save' },
        }}
        onDecision={() => {
          handleRemarksSave();
          setOpenCompanyMemoDialog(false);
        }}
        onCancel={() => {
          setOpenCompanyMemoDialog(false);
        }}
      />
    </>
  );
};
