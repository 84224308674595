import { useRef, useState } from 'react';
import {
  error,
  GetMessageWithIntl,
  MessageProps,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../..';
import { ExtendFileUploader, FileItem } from '../../file';
import { Textarea } from '../Textarea';
import { useIntl } from 'react-intl';
import { CaptionButton } from '../Button';
import { DateSuggest } from '../Dates';
import { validateFreeDate } from '../../parts/validator';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { includeInputValidateError } from '~/shared/utils';

type Mode = 'add' | 'edit';

interface InputOption {
  mode: Mode;
  revisionDate?: Date;
  comment?: string;
}

interface MessageOption {
  headerLabel: MessageProps;
}

export interface BlueprintRevisionDialogResult {
  mode: Mode;
  file?: FileItem;
  revisionDate?: Date | null;
  comment?: string;
}

export interface BlueprintRevisionDialogProps {
  isOpen: boolean;
  inputOption: InputOption;
  messageOption: MessageOption;
  onDecision: (result: BlueprintRevisionDialogResult) => void;
  onCancel: () => void;
}

export const BlueprintRevisionDialog = ({
  isOpen,
  inputOption,
  messageOption,
  onDecision,
  onCancel,
}: BlueprintRevisionDialogProps) => {
  const intl = useIntl();
  const { showLoading, hideLoading } = useLoading();
  const [comment, setComment] = useState(inputOption?.comment);
  const [revisionDate, setRevisionDate] = useState<Date | null>(
    inputOption?.revisionDate ?? null
  );
  const [file, setFile] = useState<FileItem>();
  const requiredInputArea = useRef(null);

  const isInputError = () => {
    const targetElm = document.querySelector('.blueprint-revision-dialog');
    if (
      includeInputValidateError(targetElm, intl, [
        {
          value: revisionDate?.toString() ?? '',
          ref: requiredInputArea,
        },
      ])
    ) {
      return true;
    }
  };

  const handleDecision = () => {
    if (inputOption.mode === 'add' && (!file || file.status !== 'OK')) {
      error([GetMessageWithIntl(intl, { id: 'E0000186' })]);
      return;
    }
    if (isInputError()) {
      return;
    }
    onDecision({
      mode: inputOption.mode,
      comment,
      file,
      revisionDate,
    });
  };

  const view = (
    <div className="comment-dialog blueprint-revision-dialog">
      {inputOption.mode === 'add' && (
        <>
          <div className="description-area">
            <label>
              {GetMessageWithIntl(intl, {
                prefixId: 'blueprintRevisionRegistration',
                id: 'blueprintFile',
              })}
            </label>
          </div>
          <div className="description-area">
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-100">
                  <ExtendFileUploader
                    name="uploader"
                    resultOption={{ omitFooter: true }}
                    validateOption={{
                      maxFileCount: 1,
                      maxFileSizeInMebis: 50,
                    }}
                    onChangeLoadingState={(isLoading) =>
                      isLoading ? showLoading() : hideLoading()
                    }
                    onUpload={(result) => {
                      setFile(result.at(0));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="description-area">
        <div className="input-line" ref={requiredInputArea}>
          <div className="item-group-100">
            <div className="w-40">
              <DateSuggest
                name="revisionDate"
                labelId="blueprintRevisionRegistration.revisionDate"
                value={revisionDate}
                validator={validateFreeDate(intl)}
                onChangeState={setRevisionDate}
                validateOption={{ required: true }}
                columns={['revisionDate']}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textarea
                name="comment"
                labelId="blueprintRevisionRegistration.comment"
                value={comment}
                columns={['comment']}
                className="w-100 mh-middle"
                onChangeState={setComment}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={onCancel}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'save' })}
          onClick={handleDecision}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: onCancel,
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption?.headerLabel,
    messageLabelId: {},
    elements: view,
  };

  return (
    <>
      <ModalDialogComponent {...openModalProps} />
    </>
  );
};
