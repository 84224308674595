import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessage,
  GetMessageWithIntl,
  Toast,
  error,
  success,
} from '~/shared/components';
import {
  Checkbox,
  DataFilterbox,
  DataFilterboxItem,
  PageNavigation,
  Textbox,
} from '~/shared/components/ui';
import { CaptionButton, IconButton } from '~/shared/components/ui/Button';
import {
  PageState,
  ViewId,
  convertOrganizationStructureReference,
  convertOrganizationToDataFilterBox,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getPathnameByViewId,
  getViewIdPreset,
  getWorkerExceptionMessage,
  includeInputValidateError,
  nameOptionToLocaleString,
} from '~/shared/utils';
import { PresetItem } from '~/shared/services';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { useConfirmation } from '~/shared/hooks';
import './BlueprintBlueprintInput.css';
import { OrganizationTreeSearchDialog } from '~/shared/components/ui/Dialog/OrganizationTreeSearchDialog';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { ExtendFileUploader, FileItem } from '~/shared/components/file';
import {
  BLUEPRINT_FILE_SIZE,
  BLUEPRINT_FILE_TYPES,
  BlueprintUsableType,
  existsBlueprintNumber,
  getBlueprintContent,
  uploadBlueprintFile,
} from '../utils';
import { AsyncTaskAcceptDialog } from '~/shared/components/ui/Dialog/AsyncTaskAcceptDialog';

const viewId: ViewId = 'BLP_BLUEPRINT_INPUT';
export function BlueprintBlueprintInput() {
  const sourcePageInfo = (useLocation().state as PageState) ?? [];
  const actionType = sourcePageInfo.actionType ?? 'add';
  const blueprintId = sourcePageInfo.ids?.at(0);
  const createBlueprintId = useRef<string | null>();

  const intl = useIntl();
  const navi = useNavigate();
  const { showLoading, hideLoading } = useLoading();

  const [preset, setPreset] = useState<PresetItem>({ name: '' });

  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);

  const [blueprintContent, setBlueprintContent] =
    useState<mtechnavi.api.blueprint.IBlueprintContent | null>();

  // 入力項目
  const [blueprintNumber, setBlueprintNumber] = useState('');
  const [usedSystems, setUsedSystems] = useState<string[]>([
    BlueprintUsableType.Blueprint,
  ]);
  const [usable, setUsable] = useState(true);
  const [displayName, setDisplayName] = useState('');
  const [analysesItem1, setAnalysesItem1] = useState('');
  const [analysesItem2, setAnalysesItem2] = useState('');
  const [analysesItem3, setAnalysesItem3] = useState('');
  const [analysesItem4, setAnalysesItem4] = useState('');
  const [analysesItem5, setAnalysesItem5] = useState('');
  const [files, setFiles] = useState<FileItem[]>();
  const [selectedCategory, setSelectedCategory] = useState<DataFilterboxItem[]>(
    []
  );
  const [selectedBlueprintContentType, setSelectedBlueprintContentType] =
    useState<DataFilterboxItem[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<
    DataFilterboxItem[]
  >([]);

  // フィルタボックス元データ
  const blueprintContentTypes = useMemo(
    () => window.App.services.ui.getNameOption('A0000010'),
    []
  );
  const categories = useMemo(
    () => window.App.services.ui.getNameOption('A0000011'),
    []
  );
  const [organizations, setOrganizations] = useState<
    mtechnavi.api.company.IOrganization[]
  >([]);

  // フィルタボックス・チェックボックス選択肢
  const blueprintContentTypeItems: DataFilterboxItem[] =
    blueprintContentTypes.map((item) => ({
      displayName: nameOptionToLocaleString(intl, item),
      value: item.systemName ?? '',
    }));
  const categoryItems: DataFilterboxItem[] = categories.map((item) => ({
    displayName: nameOptionToLocaleString(intl, item),
    value: item.code ?? '',
  }));
  const [managementOrganizationItems, setManagementOrganizationItems] =
    useState<DataFilterboxItem[]>([]);
  const usedSystemItems = [
    {
      displayName: GetMessageWithIntl(intl, {
        viewId,
        id: BlueprintUsableType.Blueprint,
      }),
      value: BlueprintUsableType.Blueprint,
    },
    {
      displayName: GetMessageWithIntl(intl, {
        viewId,
        id: BlueprintUsableType.Estimate,
      }),
      value: BlueprintUsableType.Estimate,
    },
  ];

  // 組織選択ダイアログ用
  const managementOrgCheckedIds = selectedOrganization.map((v) => v.value);
  const [isOpenManagementOrgSearchDialog, setOpenManagementOrgSearchDialog] =
    useState(false);
  const [isOpenAsyncTask, setOpenAsyncTask] = useState(false);

  const backPageUrl = useMemo(
    () =>
      getPathnameByViewId(sourcePageInfo.sourceViewId)?.path ??
      '/blueprint/blueprint-list',
    [sourcePageInfo.sourceViewId]
  );
  // 登録確認ダイアログ
  const { confirmation, confirmationElement } = useConfirmation();

  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });
  const saveMessage = {
    id: 'C0000001',
    value: { $1: GetMessageWithIntl(intl, { id: 'save' }) },
  };

  // エラーチェック
  const [workingBlur, setWorkingBlur] = useState<Date>();
  const inputAreaRef = useRef(null);

  /** 初期データ取得 */
  const fetchInitialData = async () => {
    // プリセット
    const { presetItem } = await getViewIdPreset(intl, viewId);
    setPreset(presetItem);

    // 組織リスト
    const organizationList = await window.App.services.ui.listOrganization();
    setOrganizations(organizationList);
    const organizationItems =
      convertOrganizationToDataFilterBox(organizationList);
    setManagementOrganizationItems(organizationItems);
    if (actionType !== 'edit' || !blueprintId) {
      return;
    }

    // 図面(編集時)
    const blueprintContent = await getBlueprintContent(blueprintId);
    const blueprint = blueprintContent?.blueprint;
    setBlueprintContent(blueprintContent);

    setInputs(blueprint, organizationItems);
  };

  /** 入力項目初期値のセット(編集時) */
  const setInputs = (
    blueprint: mtechnavi.api.blueprint.IBlueprint | null | undefined,
    organizationItems: DataFilterboxItem[]
  ) => {
    setBlueprintNumber(blueprint?.blueprintNumber ?? '');
    setUsable(blueprint?.usable ?? false);

    setDisplayName(blueprint?.displayName ?? '');

    setSelectedBlueprintContentType(
      blueprintContentTypeItems.filter(
        (item) => item.value === blueprint?.blueprintContentType?.systemName
      )
    );
    setSelectedCategory(
      categoryItems.filter((item) => item.value === blueprint?.category?.code)
    );
    setSelectedOrganization(
      organizationItems.filter(
        (item) =>
          item.value === blueprint?.managementOrganization?.organizationId
      )
    );

    setAnalysesItem1(blueprint?.analysisSettingItems?.at(0) ?? '');
    setAnalysesItem2(blueprint?.analysisSettingItems?.at(1) ?? '');
    setAnalysesItem3(blueprint?.analysisSettingItems?.at(2) ?? '');
    setAnalysesItem4(blueprint?.analysisSettingItems?.at(3) ?? '');
    setAnalysesItem5(blueprint?.analysisSettingItems?.at(4) ?? '');
    setUsedSystems(blueprint?.usedSystems ?? []);
  };

  useEffect(() => {
    (async () => {
      showLoading();
      try {
        await fetchInitialData();
      } catch (err) {
        error(getExceptionMessage(intl, err));
      } finally {
        hideLoading();
      }
    })();

    // intl, sourcePageInfo のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, sourcePageInfo.actionType]);

  const isInputError = (): boolean => {
    setWorkingBlur(new Date());
    return includeInputValidateError(document, intl, [
      { value: blueprintNumber ?? '', ref: inputAreaRef },
    ]);
  };

  const handleSave = async () => {
    if (isInputError()) {
      return;
    }
    if (!(await confirmation(saveMessage))) {
      return;
    }

    if (actionType === 'edit') {
      await updateAction();
      return;
    }
    await createAction();
  };

  /** 更新処理 */
  const updateAction = async () => {
    try {
      showLoading();
      if (
        blueprintContent?.blueprint?.blueprintNumber !== blueprintNumber &&
        (await existsBlueprintNumber(blueprintNumber))
      ) {
        error([GetMessageWithIntl(intl, { id: 'E0000185' })]);
        return;
      }
      const blueprint = await getBlueprintParam();
      await window.App.services.ui.worker.apiCall({
        actionName: 'updateBlueprint',
        request: {
          blueprint: {
            ...blueprint,
            blueprintId: blueprintId,
            createdAt: blueprintContent?.blueprint?.createdAt,
            updatedAt: blueprintContent?.blueprint?.updatedAt,
          },
        },
      });
      success([toastSuccess]);
      naviToConfirmationPage();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      hideLoading();
    }
  };

  /** 登録処理 */
  const createAction = async () => {
    try {
      showLoading();
      if (await existsBlueprintNumber(blueprintNumber)) {
        error([GetMessageWithIntl(intl, { id: 'E0000185' })]);
        return;
      }
      const file = files?.at(0);
      if (!file) {
        error([GetMessageWithIntl(intl, { id: 'E0000186' })]);
        return;
      }
      const blueprint = await getBlueprintParam();
      const attachment = await uploadBlueprintFile(file);
      const result = await window.App.services.ui.worker.apiCall({
        actionName: 'createBlueprint',
        request: {
          blueprint,
          blueprintRevision: {
            blueprintFile: { attachment },
          },
        },
      });
      success([toastSuccess]);
      createBlueprintId.current = result?.at(0)?.blueprint?.blueprintId;
      setOpenAsyncTask(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    } finally {
      hideLoading();
    }
  };

  /** 非同期処理受け付けダイアログを閉じた時の処理 */
  const handleAsyncTaskClose = () => {
    naviToConfirmationPage(createBlueprintId.current);
  };

  const getBlueprintParam = async () => {
    const category = categories.find(
      (item) => selectedCategory.at(0)?.value === item.code
    );
    const blueprintContentType = blueprintContentTypes.find(
      (item) => selectedBlueprintContentType.at(0)?.value === item.systemName
    );
    const managementOrganization = await convertOrganizationStructureReference(
      selectedOrganization.at(0)?.value
    );

    const blueprint: mtechnavi.api.blueprint.IBlueprint = {
      blueprintNumber,
      displayName,
      usable,
      analysisSettingItems: [
        analysesItem1,
        analysesItem2,
        analysesItem3,
        analysesItem4,
        analysesItem5,
      ],
      category,
      blueprintContentType,
      managementOrganization,
      usedSystems,
    };
    return blueprint;
  };

  // 戻るページ
  const backToPreviewPage = () => {
    const state: PageState = {
      ids: sourcePageInfo.beforeStateIds ?? [],
      subIds: sourcePageInfo.beforeStateSubIds,
      sourceViewId: viewId,
      naviFilters: sourcePageInfo.naviFilters,
      beforeStateIds: sourcePageInfo.ids,
      beforeStateSubIds: sourcePageInfo.subIds,
      baseViewOption: sourcePageInfo.baseViewOption,
      confirmationViewOption: sourcePageInfo.confirmationViewOption,
    };
    navi(backPageUrl, { state });
  };

  //  どの画面から遷移していても保存時は確認画面へ遷移する
  const naviToConfirmationPage = (addId?: string | null) => {
    const ids = addId ? [addId] : sourcePageInfo.beforeStateIds ?? [];
    const state: PageState = {
      ids,
      subIds: sourcePageInfo.beforeStateSubIds,
      sourceViewId: viewId,
      naviFilters: sourcePageInfo.naviFilters,
      beforeStateIds: sourcePageInfo.ids,
      beforeStateSubIds: sourcePageInfo.subIds,
      baseViewOption: sourcePageInfo.baseViewOption,
      confirmationViewOption: sourcePageInfo.confirmationViewOption,
    };

    navi('/blueprint/blueprint-confirmation', { state });
  };

  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  return (
    <>
      <Container viewId={viewId}>
        <div className="BlueprintBlueprintInput">
          <div className="header">
            <PageNavigation
              backpagePath="/blueprint-blueprint-list"
              pageInfo={{ isVisibleMoveNavi: false }}
              infoOption={{
                lastUpdateInfo: {
                  isVisibleUpdateInfo: true,
                  attribute:
                    blueprintContent?.blueprint?.updatedProperties?.updatedBy
                      ?.email ?? '',
                  attributeAt:
                    blueprintContent?.blueprint?.updatedProperties?.updatedAt ??
                    undefined,
                  content:
                    blueprintContent?.blueprint?.updatedProperties?.updatedBy
                      ?.displayName ?? '',
                },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={async () => {
                if (!(await confirmation({ id: 'discardMessage' }))) {
                  return;
                }
                backToPreviewPage();
              }}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{ maxHeight: mainContentHeight }}
            ref={inputAreaRef}
          >
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-40">
                  {/* 図番 */}
                  <Textbox
                    name="blueprintNumber"
                    type="text"
                    value={blueprintNumber}
                    labelId={`${viewId}.blueprintNumber`}
                    onChangeState={setBlueprintNumber}
                    columns={preset.columns}
                    validateOption={{ required: actionType !== 'edit' }}
                    disabled={actionType === 'edit'}
                  />
                </div>
                {/* 使用可能 */}
                <div className="w-20 usable">
                  <Checkbox
                    name="usable"
                    items={[
                      {
                        displayName: GetMessageWithIntl(intl, {
                          viewId,
                          id: 'usable',
                        }),
                        value: '1',
                      },
                    ]}
                    columns={preset.columns}
                    value={usable ? '1' : ''}
                    onChangeState={(v) => setUsable(v.length > 0)}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-40">
                  {/* 管理用名称 */}
                  <Textbox
                    name="displayName"
                    type="text"
                    value={displayName}
                    labelId={`${viewId}.displayName`}
                    onChangeState={setDisplayName}
                    columns={preset.columns}
                  />
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-20">
                  {/* 図面種類 */}
                  <DataFilterbox
                    name="blueprintContentType"
                    data={blueprintContentTypeItems}
                    value={selectedBlueprintContentType}
                    labelId={`${viewId}.blueprintContentType`}
                    onChangeState={setSelectedBlueprintContentType}
                    columns={preset.columns}
                    workingBlur={workingBlur}
                  />
                </div>
                <div className="w-20">
                  {/* 図面分類 */}
                  <DataFilterbox
                    name="category"
                    data={categoryItems}
                    value={selectedCategory}
                    labelId={`${viewId}.category`}
                    onChangeState={setSelectedCategory}
                    columns={preset.columns}
                    workingBlur={workingBlur}
                  />
                </div>
                <div className="w-40">
                  <div className="input-line no-space">
                    <div className="item-group-100 no-space">
                      <div className="w-100">
                        <DataFilterbox
                          name="managementOrganization"
                          labelId={`${viewId}.managementOrganization`}
                          columns={['managementOrganization']}
                          data={managementOrganizationItems}
                          value={selectedOrganization ?? [{ value: '' }]}
                          searchOption={{ targets: 'displayName' }}
                          onChangeState={(item) =>
                            setSelectedOrganization(item)
                          }
                          workingBlur={workingBlur}
                        />
                      </div>
                      <div className="w-16">
                        <div className="side-search-icon">
                          <IconButton
                            name="search"
                            buttonType="basic"
                            className="button"
                            iconType={'search'}
                            onClick={() =>
                              setOpenManagementOrgSearchDialog(true)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="input-line">
              <div className="item-group-100">
                <div className="w-20">
                  {/* 分析項目１ */}
                  <Textbox
                    name="analysisItem1"
                    type="text"
                    value={analysesItem1}
                    labelId={`${viewId}.analysisItem1`}
                    onChangeState={setAnalysesItem1}
                    columns={preset.columns}
                  />
                </div>
                <div className="w-20">
                  {/* 分析項目２ */}
                  <Textbox
                    name="analysisItem2"
                    type="text"
                    value={analysesItem2}
                    labelId={`${viewId}.analysisItem2`}
                    onChangeState={setAnalysesItem2}
                    columns={preset.columns}
                  />
                </div>
                <div className="w-20">
                  {/* 分析項目３ */}
                  <Textbox
                    name="analysisItem3"
                    type="text"
                    value={analysesItem3}
                    labelId={`${viewId}.analysisItem3`}
                    onChangeState={setAnalysesItem3}
                    columns={preset.columns}
                  />
                </div>
                <div className="w-20">
                  {/* 分析項目４ */}
                  <Textbox
                    name="analysisItem4"
                    type="text"
                    value={analysesItem4}
                    labelId={`${viewId}.analysisItem4`}
                    onChangeState={setAnalysesItem4}
                    columns={preset.columns}
                  />
                </div>
                <div>
                  {/* 分析項目５ */}
                  <Textbox
                    name="analysisItem5"
                    type="text"
                    value={analysesItem5}
                    labelId={`${viewId}.analysisItem5`}
                    onChangeState={setAnalysesItem5}
                    columns={preset.columns}
                  />
                </div>
              </div>
            </div>
            {/* ファイルアップロードエリア */}
            <div className="input-line label">
              <div className="item-group-100">
                {actionType === 'add' && (
                  <div className="w-60">
                    <p>
                      {GetMessageWithIntl(intl, {
                        id: 'fileAreaLabel',
                        prefixId: viewId,
                      })}
                    </p>
                    <div className="upload-file-area">
                      <ExtendFileUploader
                        name="uploader"
                        resultOption={{ omitFooter: true }}
                        validateOption={{
                          maxFileCount: 1,
                          maxFileSizeInMebis: BLUEPRINT_FILE_SIZE,
                          allowedFileExtensions: BLUEPRINT_FILE_TYPES,
                        }}
                        onChangeLoadingState={(isLoading) =>
                          isLoading ? showLoading() : hideLoading()
                        }
                        onUpload={setFiles}
                      />
                    </div>
                  </div>
                )}
                <div className="w-40">
                  <p>
                    {GetMessageWithIntl(intl, {
                      viewId,
                      id: 'usedSystems',
                    })}
                  </p>
                  <div className="system-usage">
                    <Checkbox
                      name="usedSystems"
                      items={usedSystemItems}
                      columns={preset.columns}
                      value={usedSystems}
                      onChangeState={setUsedSystems}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer" ref={footerRef}>
          <div className="footer-contents">
            <div className="input-line">
              <CaptionButton
                name="saveBtn"
                caption={GetMessageWithIntl(intl, { id: 'save' })}
                className="btn"
                onClick={() => handleSave()}
                buttonType="basic"
              />
            </div>
          </div>
        </div>
        {confirmationElement}
        <>
          {/* 非同期処理受付ダイアログ */}
          <AsyncTaskAcceptDialog
            isOpen={isOpenAsyncTask}
            targetActionName={{
              prefixId: 'BLUEPRINT',
              id: 'blueprintFileImport',
            }}
            onClose={handleAsyncTaskClose}
          />
        </>
        <>
          {/* 組織ツリー検索ダイアログ */}
          <OrganizationTreeSearchDialog
            isOpen={isOpenManagementOrgSearchDialog}
            organizationList={organizations}
            checkedIds={managementOrgCheckedIds}
            messageOption={{
              headerLabelId: {
                prefixId: 'DIALOG_TITLE',
                id: 'OrganizationTreeSearchDialog',
              },
            }}
            onCancel={() => setOpenManagementOrgSearchDialog(false)}
            onDecision={(checks) => {
              if (checks.length > 1) {
                error([GetMessage({ id: 'E0000073' })]);
                return;
              }
              setSelectedOrganization(
                managementOrganizationItems.filter(
                  (v) => checks && checks[0] === v.value
                )
              );
              setOpenManagementOrgSearchDialog(false);
            }}
          />
        </>
        <Toast />
      </Container>
    </>
  );
}
