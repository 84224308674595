import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { LoadingOverlay } from '../components/ui/LoadingOverlay/LoadingOverlay';

interface LoadingState {
  /**
   * ローディング中のカウント
   *
   * showLoading するたびに +1 され、
   * hideLoading するたびに -1 される。
   * 値が 0 でない間、ローディングを表示する。
   * 余剰に hideLoading しても 0 より小さくはしない。
   */
  loadingCount: number;
  /**
   * 現在は React が Popover API に対応していない ( issue: https://github.com/facebook/react/issues/27479 )
   *
   * そのため次善策として、ページルートとダイアログ内の両方にローディング要素(<Loading>)を配置して、
   * currentTarget によりどちらでローディングを表示するかを制御している。
   *
   * Popover API が利用できるようになれば、これらのプロパティは不要になる予定。
   * isLoading の変化を検知し、Popover 要素となったローディング表示コンポーネントを制御できるはず。
   */
  currentTarget: string;
  loadingTargetStack: string[];
}
const initialState: LoadingState = {
  loadingCount: 0,
  currentTarget: '',
  loadingTargetStack: [],
};

interface ActionShowLoading {
  type: 'showLoading';
}
interface ActionHideLoading {
  type: 'hideLoading';
}
interface PushTargetStack {
  type: 'pushTargetStack';
  targetId: string;
}
interface PopTargetStack {
  type: 'popTargetStack';
}
type LoadingActions =
  | ActionShowLoading
  | ActionHideLoading
  | PushTargetStack
  | PopTargetStack;

const reducer = (state: LoadingState, action: LoadingActions): LoadingState => {
  switch (action.type) {
    case 'showLoading': {
      return { ...state, loadingCount: state.loadingCount + 1 };
    }
    case 'hideLoading': {
      return {
        ...state,
        loadingCount: state.loadingCount > 0 ? state.loadingCount - 1 : 0,
      };
    }
    case 'pushTargetStack':
      return {
        ...state,
        currentTarget: action.targetId,
        loadingTargetStack: [...state.loadingTargetStack, action.targetId],
      };
    case 'popTargetStack': {
      const newStack = [...state.loadingTargetStack];
      newStack.pop();
      return {
        ...state,
        currentTarget: newStack.at(newStack.length - 1) || '',
        loadingTargetStack: [...newStack],
      };
    }
  }
};

const LoadingContext = createContext<LoadingState>(initialState);
const LoadingDispatchContext = createContext<React.Dispatch<LoadingActions>>(
  () => {}
);

/**
 * ローディング状況を共有するための Provider
 */
export const LoadingProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  return (
    <LoadingContext.Provider value={state}>
      <LoadingDispatchContext.Provider value={dispatch}>
        {children}
        <Loading />
      </LoadingDispatchContext.Provider>
    </LoadingContext.Provider>
  );
};

/**
 * ローディング表示制御要素
 */
export interface LoadingProps {
  loadingContextId?: string;
}
export const Loading = ({ loadingContextId = '' }: LoadingProps) => {
  const { loadingCount, currentTarget } = useContext(LoadingContext);
  const dispatch = useContext(LoadingDispatchContext);

  const isLoading = loadingCount !== 0;

  useEffect(() => {
    dispatch({ type: 'pushTargetStack', targetId: loadingContextId });
    return () => {
      dispatch({ type: 'popTargetStack' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>{isLoading && currentTarget === loadingContextId && <LoadingOverlay />}</>
  );
};

/**
 * ローディング表示利用側向けの hooks
 */
export const useLoading = () => {
  const { loadingCount } = useContext(LoadingContext);
  const dispatch = useContext(LoadingDispatchContext);

  const isLoading = loadingCount !== 0;

  const showLoading = useCallback(
    () => dispatch({ type: 'showLoading' }),
    [dispatch]
  );

  const hideLoading = useCallback(
    () => dispatch({ type: 'hideLoading' }),
    [dispatch]
  );

  return {
    /** ローディング表示中かどうか */
    isLoading,
    /** ローディングを表示する */
    showLoading,
    /** ローディングを非表示にする */
    hideLoading,
  };
};
