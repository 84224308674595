import { useEffect, useRef, useState } from 'react';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  Checkbox,
  GetMessageWithIntl,
  MessageProps,
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../..';
import { CaptionButton, IconButton } from '../Button';
import { useIntl } from 'react-intl';
import { Filterbox, FilterboxItem } from '../Filterbox';
import {
  FullMethodName_ListBaseForms,
  includeInputValidateError,
} from '~/shared/utils';
import { Textbox } from '../Textbox';
import { Textarea } from '../Textarea';
import './FormSettingDialog.css';
import { SearchListViewDialog } from '../SearchListViewDialog';
import {
  Schema,
  getAltDisplaySchema,
  getJoinedAndAltColumnSchema,
} from '../ListView';
import { useAuth } from '~/shared/contexts/AuthProvider';

export interface FormSettingDialogResult {
  baseFormId: string;
  baseDisplayName: string;
  displayName: string;
  required: boolean;
  notice: string;
  attribute1: string;
  attribute2: string;
  attribute3: string;
}

interface FormSettingDialogMessageOption {
  headerLabelId: MessageProps;
}

interface FormSettingDialogInputOption {
  /**
   * 入力必須を選択可能とするかどうか。
   * 選択不可の場合はチェックON固定とする。
   */
  isDisabledRequired?: boolean;
  /**
   * ダイアログ内の各項目にセットするための入力値。
   */
  dialogData?: FormSettingDialogResult | null;
  /**
   * 利用できるフォームを絞り込むための用途区分
   */
  purposes?: string[];
}

interface FormSettingDialogProps {
  /**
   * ダイアログ表示状態。
   */
  isOpen: boolean;
  messageOption: FormSettingDialogMessageOption;
  inputOption?: FormSettingDialogInputOption;
  /**
   * キャンセル時のコールバック関数。
   */
  onCancel?: () => void;
  /**
   * 決定時のコールバック関数。
   */
  onDecision?: (result: FormSettingDialogResult) => void | Promise<void>;
}
const DIALOG_NAME = 'FormSettingDialog';
export const FormSettingDialog = ({
  isOpen,
  messageOption,
  inputOption,
  onCancel,
  onDecision,
}: FormSettingDialogProps) => {
  const intl = useIntl();
  const auth = useAuth();
  const [isDisabledFormItem, setDisabledFormItem] = useState(false);
  const [isOpenSearchDialog, setOpenSearchDialog] = useState(false);
  const [organization, setOrganization] =
    useState<sharelib.IOrganizationStructureReference | null>();
  const [workingBlur, setWorkingBlur] = useState<Date>();
  const [selectedFormItem, setSelectedFormItem] = useState<FilterboxItem[]>([]);
  const [displayName, setDisplayName] = useState('');
  const [requiredCheck, setRequiredCheck] = useState<string[]>([]);
  const [notice, setNotice] = useState('');
  const [attribute1, setAttribute1] = useState('');
  const [attribute2, setAttribute2] = useState('');
  const [attribute3, setAttribute3] = useState('');

  const initialFilterItems = generateInitialFilter(
    organization ? organization?.organizationId || '' : '',
    inputOption?.purposes && inputOption?.purposes
  );

  const formAreaRef = useRef(null);

  useEffect(() => {
    (async () => {
      const result =
        await window.App.services.ui.getUserRepresentativeOrganization(
          auth.user?.email || ''
        );
      setOrganization(result);
    })();
  }, [intl, auth.user]);

  const handleFormatSchema = (schema: Schema) => {
    let resultSchema = [...schema];
    resultSchema = getAltDisplaySchema(
      resultSchema,
      [
        `formProperties.purposes.displayNameLang`,
        `formProperties.formType1.displayNameLang`,
        `formProperties.formType2.displayNameLang`,
        `formProperties.formType3.displayNameLang`,
      ],
      intl.locale
    );
    resultSchema = getJoinedAndAltColumnSchema(
      resultSchema,
      [
        'formProperties.formType1.displayNameLang',
        'formProperties.formType2.displayNameLang',
        'formProperties.formType3.displayNameLang',
      ],
      [
        `formProperties.formType1.displayNameLang.${intl.locale}`,
        `formProperties.formType2.displayNameLang.${intl.locale}`,
        `formProperties.formType3.displayNameLang.${intl.locale}`,
      ],
      ' / ',
      true
    );
    return resultSchema;
  };

  const handleChangeFormTemplate = (form: FilterboxItem[]) => {
    setSelectedFormItem(form);
    setDisplayName(form.at(0)?.displayName || '');
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    clearForm();
    setWorkingBlur(undefined);
  };

  const handleDecision = async () => {
    if (isInputError()) {
      return;
    }
    const result: FormSettingDialogResult = {
      baseFormId: selectedFormItem.at(0)?.value || '',
      baseDisplayName: selectedFormItem.at(0)?.displayName || '',
      displayName,
      required: requiredCheck.length > 0,
      notice,
      attribute1: attribute1,
      attribute2: attribute2,
      attribute3: attribute3,
    };
    if (onDecision) {
      // 完了コールバックを待ってからフォーム入力値をクリアする
      await onDecision(result);
      clearForm();
    } else {
      clearForm();
    }
    setWorkingBlur(undefined);
  };

  const isInputError = (): boolean => {
    setWorkingBlur(new Date());
    return includeInputValidateError(formAreaRef.current, intl, [
      {
        value: selectedFormItem.length > 0 ? selectedFormItem[0].value : '',
        ref: formAreaRef,
      },
    ]);
  };

  const handleSearch = () => {
    setOpenSearchDialog(true);
  };

  const handleSearchSelect = (selections: mtechnavi.api.form.IBaseForm[]) => {
    setSelectedFormItem(
      selections.map((item) => ({
        value: item.baseFormId || '',
        displayName: item.formProperties?.displayName || '',
      }))
    );
    setDisplayName(selections.at(0)?.formProperties?.displayName || '');
    setOpenSearchDialog(false);
  };

  const clearForm = () => {
    setDisabledFormItem(false);
    setSelectedFormItem([]);
    setDisplayName('');
    setRequiredCheck([]);
    setNotice('');
    setAttribute1('');
    setAttribute2('');
    setAttribute3('');
  };

  useEffect(() => {
    if (inputOption?.dialogData) {
      setDisabledFormItem(true);
      setSelectedFormItem([
        {
          value: inputOption.dialogData.baseFormId,
          displayName: inputOption.dialogData.baseDisplayName,
        },
      ]);
      setDisplayName(inputOption.dialogData.displayName);
      setRequiredCheck(inputOption.dialogData.required ? ['1'] : []);
      setNotice(inputOption.dialogData.notice);
      setAttribute1(inputOption.dialogData.attribute1);
      setAttribute2(inputOption.dialogData.attribute2);
      setAttribute3(inputOption.dialogData.attribute3);
    } else {
      clearForm();
    }
  }, [inputOption?.dialogData]);

  const elements = (
    <div className="FormSettingDialog">
      <div className="detail-area" ref={formAreaRef}>
        <div className="input-line">
          <div className="item-group-100 no-space">
            <div className="w-66">
              <Filterbox
                name="form"
                labelId={`${DIALOG_NAME}.form`}
                columns={['form']}
                itemType={{
                  displayName: 'formProperties.displayName',
                  value: 'baseFormId',
                }}
                fullMethodName={FullMethodName_ListBaseForms}
                searchOption={{
                  targets: 'displayName',
                  isLatestData: true,
                  customQuery: {
                    usable: { $eq: true },
                    ...(inputOption?.purposes && {
                      'formProperties.purposes.code': {
                        $in: inputOption.purposes,
                      },
                    }),
                  },
                }}
                disabled={isDisabledFormItem}
                value={selectedFormItem}
                validateOption={{ required: true }}
                onChangeState={handleChangeFormTemplate}
                workingBlur={workingBlur}
              />
            </div>
            {!isDisabledFormItem && (
              <div className="search-container">
                <IconButton
                  name="search"
                  iconType="search"
                  buttonType="basic"
                  onClick={handleSearch}
                />
              </div>
            )}
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-66">
              <Textbox
                name="displayName"
                columns={['displayName']}
                type="text"
                labelId={`${DIALOG_NAME}.displayName`}
                value={displayName}
                validateOption={{ required: true }}
                onChangeState={setDisplayName}
              />
            </div>
            <div>
              <Checkbox
                name="required"
                items={[
                  {
                    displayName: GetMessageWithIntl(intl, {
                      prefixId: DIALOG_NAME,
                      id: 'required',
                    }),
                    value: '1',
                  },
                ]}
                value={inputOption?.isDisabledRequired ? ['1'] : requiredCheck}
                disabled={inputOption?.isDisabledRequired}
                onChangeState={setRequiredCheck}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <Textarea
                name="notice"
                columns={['notice']}
                className="w-100 mh-middle"
                labelId={`${DIALOG_NAME}.notice`}
                value={notice}
                onChangeState={setNotice}
              />
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-33">
              <Textbox
                name="attributes1"
                columns={['attributes1']}
                type="text"
                labelId={`${DIALOG_NAME}.attributes1`}
                value={attribute1}
                onChangeState={setAttribute1}
              />
            </div>
            <div className="w-33">
              <Textbox
                name="attributes2"
                columns={['attributes2']}
                type="text"
                labelId={`${DIALOG_NAME}.attributes2`}
                value={attribute2}
                onChangeState={setAttribute2}
              />
            </div>
            <div className="w-33">
              <Textbox
                name="attributes3"
                columns={['attributes3']}
                type="text"
                labelId={`${DIALOG_NAME}.attributes3`}
                value={attribute3}
                onChangeState={setAttribute3}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-area">
        <CaptionButton
          name="cancel"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, {
            id: 'cancel',
          })}
          onClick={handleCancel}
        />
        <CaptionButton
          name="decision"
          buttonType="basic"
          className="button"
          caption={GetMessageWithIntl(intl, {
            id: 'decision',
          })}
          onClick={handleDecision}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: handleCancel,
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements,
  };

  return (
    <>
      <ModalDialogComponent {...openModalProps} />
      <SearchListViewDialog
        viewId="IFM_FORM_DEFINITION_SEARCH"
        fullMethodName={FullMethodName_ListBaseForms}
        isOpen={isOpenSearchDialog}
        headerLabelId={{
          prefixId: 'DIALOG_TITLE',
          id: 'form-search',
        }}
        selectLimit={1}
        selectedItemIds={selectedFormItem.map((form) => form.value)}
        onCloseChange={() => {
          setOpenSearchDialog(false);
        }}
        onSelectChange={(items) =>
          handleSearchSelect(items as unknown as mtechnavi.api.form.IBaseForm[])
        }
        initialFilterItems={{
          info: initialFilterItems,
          type: '',
        }}
        menuTarget="baseFormId"
        onHandleFormatSchema={handleFormatSchema}
      />
    </>
  );
};

const generateInitialFilter = (
  organizationId?: string,
  purposesCodes?: string[]
) => {
  const initialFilter = [];
  if (organizationId) {
    initialFilter.push({
      targetKey: 'formProperties.managementOrganization.organizationId',
      targetValue: organizationId,
    });
  }
  if (purposesCodes) {
    initialFilter.push(
      ...purposesCodes.map((purposesCode) => ({
        targetKey: 'formProperties.purposes.code',
        targetValue: purposesCode,
      }))
    );
  }
  return initialFilter;
};
