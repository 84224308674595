import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { BlueprintSearchCondition } from './BlueprintBlueprintSearchResult/util';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { BlueprintSearchParam, searchBlueprint } from '../utils';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import { error } from '~/shared/components';
import { getExceptionMessage } from '~/shared/utils';
import { useIntl } from 'react-intl';

type State = {
  result: mtechnavi.api.blueprint.ISearchBlueprintContent[];
  condition?: BlueprintSearchCondition;
  isProcessing?: boolean;
};
const initialState: State = { result: [] };
const Context = createContext<State>({ ...initialState });
const DispatchContext = createContext<
  (param: BlueprintSearchParam) => Promise<void>
>(() => Promise.resolve());

/**
 * 図面検索の条件と結果を画面を跨いで保持するための provider
 */
export const BlueprintSearchProvider = ({ children }: PropsWithChildren) => {
  const intl = useIntl();
  const { showLoading, hideLoading } = useLoading();
  const [result, setResult] = useState<
    mtechnavi.api.blueprint.ISearchBlueprintContent[]
  >([]);
  const [condition, setCondition] = useState<BlueprintSearchCondition>();
  const [isProcessing, setProcessing] = useState<boolean>();

  const state: State = { result, condition, isProcessing };

  const search = useCallback(async (param: BlueprintSearchParam) => {
    try {
      showLoading();
      setProcessing(true);
      setCondition(param.condition);
      const result = await searchBlueprint(param);
      setResult(result);
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
      setProcessing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Context.Provider value={state}>
      <DispatchContext.Provider value={search}>
        {children}
      </DispatchContext.Provider>
    </Context.Provider>
  );
};

/**
 * 図面検索の条件と結果を画面を跨いで保持するための hook
 */
export const useBlueprintSearch = () => {
  const { condition, result, isProcessing } = useContext(Context);
  const search = useContext(DispatchContext);

  return {
    result,
    condition,
    isProcessing,
    search,
  };
};
