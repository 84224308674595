import './ModalDialog.css';
import { Textarea } from '../Textarea';
import {
  GetMessageComponent,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import { ReactComponent as Close } from '@material-design-icons/svg/filled/close.svg';
import { ReactNode, useEffect, useId, useRef } from 'react';
import { IconWithCaptionButton } from '../Button';
import { Toast } from '../../parts/Toast/Toast';
import { Loading } from '~/shared/contexts/LoadingProvider';
import { TooltipAreaProvider } from '../../parts/Tooltip';

export interface ModalDialogControls {
  showCloseHeaderButton: boolean;
}

export interface ModalDialogComponentProps {
  send: () => void;
  cancel: () => void;
  modalIsOpen: boolean;
  comment?: string;
  onChangeState?: (arg: string) => void;
  headerLabelId: MessageProps;
  titleLabelId?: MessageProps;
  messageLabelId: MessageProps;
  elements?: ReactNode;
  controls?: ModalDialogControls;
  className?: string;
  footerElements?: ReactNode;
}

const ModalDialogControlsDefault: ModalDialogControls = {
  showCloseHeaderButton: false,
};

export function ModalDialogComponent(props: ModalDialogComponentProps) {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const control = props.controls ?? ModalDialogControlsDefault;
  // 表示画面中で一意になるようIDを振る
  const contextId = useId();

  const handleDialogCancel = (e: React.SyntheticEvent<HTMLElement, Event>) => {
    // input[type=file] のキャンセルなどでも発火してしまうため、ダイアログ以外の場合は無視する
    if (!(e.target instanceof HTMLDialogElement)) {
      return;
    }
    props.cancel();
  };

  // ダイアログ内で mouseDown イベントが発火していたら、次のダイアログクリックをキャンセルする
  const cancelNextDialogClick = useRef(false);
  const handleDialogMouseDown = (
    e: React.MouseEvent<HTMLDialogElement, MouseEvent>
  ) => {
    cancelNextDialogClick.current = !(e.target instanceof HTMLDialogElement);
  };

  const handleDialogClick = (
    e: React.MouseEvent<HTMLDialogElement, MouseEvent>
  ) => {
    // ダイアログ内から始まったクリック動作の場合はスキップする
    if (cancelNextDialogClick.current) {
      cancelNextDialogClick.current = false;
      return;
    }
    // ダイアログ要素(≒バックドロップ)そのもののクリックに限定する
    if (!(e.target instanceof HTMLDialogElement)) {
      return;
    }
    e.stopPropagation(); // 重なったダイアログを一気に閉じさせない
    props.cancel();
  };

  useEffect(() => {
    if (props.modalIsOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [props.modalIsOpen]);

  return (
    <div className="Dialog">
      <dialog
        className={`modal-content ${props.className ?? ''}`}
        ref={dialogRef}
        onMouseDown={handleDialogMouseDown}
        onClick={handleDialogClick}
        onCancel={handleDialogCancel}
      >
        {props.modalIsOpen && (
          <TooltipAreaProvider>
            <div className="modal-header" onClick={(e) => e.stopPropagation()}>
              <GetMessageComponent {...props.headerLabelId} />
              {control.showCloseHeaderButton && (
                <button className="closeBtn" onClick={props.cancel}>
                  <Close />
                </button>
              )}
            </div>
            <div
              className="modal-body"
              // TODO: 現時点では dialog への autofocus 属性が効かないため、modal-body に初期フォーカスをセットする
              // 参考：https://zenn.dev/yusukehirao/articles/e5df3d60c99e91, https://github.com/whatwg/html/pull/8199
              autoFocus={true}
              tabIndex={-1}
            >
              {props.elements ? (
                props.elements
              ) : (
                <>
                  <p className="modal-title">
                    {props.titleLabelId && (
                      <GetMessageComponent {...props.titleLabelId} />
                    )}
                  </p>
                  <div className="normal-content">
                    <GetMessageComponent {...props.messageLabelId} />
                    <Textarea
                      name="comment"
                      className="width-fit"
                      labelId="comment"
                      columns={['comment']}
                      onChangeState={props.onChangeState}
                    />
                  </div>
                  <div className="btn-area">
                    <IconWithCaptionButton
                      name="cancelBtn"
                      caption="キャンセル"
                      className="btn btn-normal"
                      properties={[
                        {
                          name: 'cancelBtn',
                          propertyName: 'cancelBtn',
                          propertyValue: 'cancelBtn',
                        },
                      ]}
                      onClick={props.cancel}
                      buttonType="cancel"
                      iconType="close"
                    />

                    <IconWithCaptionButton
                      name="filterAddBtn"
                      caption="追加"
                      className="btn btn-normal"
                      properties={[
                        {
                          name: 'filterAddBtn',
                          propertyName: 'filterAddBtn',
                          propertyValue: 'filterAddBtn',
                        },
                      ]}
                      onClick={props.send}
                      buttonType="basic"
                      iconType="add"
                    />
                  </div>
                </>
              )}
            </div>
            {props.footerElements ? (
              <div className="modal-footer">{props.footerElements}</div>
            ) : (
              <></>
            )}
            {/* TODO: Popover API が利用できるようになるまでの間、ダイアログ上のローディング表示はダイアログ内で管理する */}
            <Loading loadingContextId={contextId} />
            <Toast containerId={contextId} />
          </TooltipAreaProvider>
        )}
      </dialog>
    </div>
  );
}
