import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_UpdateBlueprintAttribute } from 'worker/fullMethodName/crud';

/** 図面属性の更新 */
export const updateBlueprintAttribute = (
  worker: AppSaveWorker,
  req: mtechnavi.api.blueprint.IUpdateBlueprintAttributeRequest
) => {
  return worker.invokeListRequest<
    mtechnavi.api.blueprint.IUpdateBlueprintAttributeRequest,
    mtechnavi.api.blueprint.IBlueprintAttribute
  >(FullMethodName_UpdateBlueprintAttribute, req);
};
