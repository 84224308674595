import { useMemo, CSSProperties, useState, useEffect } from 'react';
import { Property } from '~/shared/services';
import {
  GetMessageComponent,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
  ModalDialogControls,
} from '../ModalDialog/ModalDialog';
import { Viewer, ViewerProps } from '../Viewer';
import { getProperty } from '../common';

type ViewerControls = Pick<
  Required<ViewerProps>['controls'],
  'pager' | 'scaling' | 'downloading'
>;

const viewerControlsDefault: ViewerControls = {
  pager: false,
  scaling: true,
  downloading: false,
};

export interface ImageProps {
  name: string;
  className?: string;
  labelId?: MessageProps;
  src: ImageSrcInfo;
  disabled?: boolean;
  properties?: Property[];
  columns?: string[];
  viewerControls?: ViewerControls;
  modalDialogControls?: ModalDialogControls;
}

interface ImageSrcInfo {
  assetId: string;
  fileName?: string;
}

export function Image(props: ImageProps) {
  const noImage = '/assets/image/NoImage.png';
  // commonStyleは使用しないが、戻り値にあるためlint除外
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const [componentStyle, viewStyle, _disabled] = useMemo<
    [CSSProperties, CSSProperties, boolean]
  >(() => {
    return getProperty(props.name, props.columns, props.properties);
  }, [props.properties, props.columns, props.name]);
  const disabled = _disabled ? _disabled : props.disabled ?? false;

  // モーダル関係
  const [baseModalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    if (imageSrc == noImage) {
      return;
    }
    setModalIsOpen(true);
  };
  const closeModalDialog = () => {
    setModalIsOpen(false);
  };
  const sendModalDialog = () => {
    setModalIsOpen(false);
  };

  const [imageSrc, setImageSrc] = useState('');
  useEffect(() => {
    setImageSrc(
      props.src.assetId
        ? `/mtechnavi.api.assetinventory.AssetProxy/${props.src.assetId}`
        : noImage
    );
  }, [props.src]);

  const viewer = (
    <Viewer
      assetId={props.src.assetId}
      fileName={props.src.fileName ?? ''}
      controls={props.viewerControls ?? viewerControlsDefault}
      height="100%"
      width="99%"
    ></Viewer>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: closeModalDialog,
    send: sendModalDialog,
    modalIsOpen: baseModalIsOpen,
    comment: '',
    headerLabelId: props.labelId ?? {},
    messageLabelId: props.labelId ?? {},
    elements: viewer,
    controls: props.modalDialogControls,
  };

  return (
    <div style={viewStyle}>
      <span className="caption">
        {props.labelId && (
          <div
            className={`topping-label ${
              disabled ? 'disabled-topping-label' : ''
            }`}
          >
            <GetMessageComponent {...props.labelId} />
          </div>
        )}
      </span>
      <img src={imageSrc} onClick={openModal}></img>
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
}
