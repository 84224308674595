import { useState } from 'react';
import { Checkbox, Textbox } from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { TextInputDialog } from '~/shared/components/ui/Dialog/TextInputDialog';

export const UISampleTextInputDialogs = () => {
  const [result, setResult] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [required, setRequired] = useState(false);
  const [text, setText] = useState('');

  return (
    <div className="categories">
      <h3 id="dialog">テキスト入力ダイアログ</h3>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-33">
            <Textbox
              type="text"
              name="text"
              value={text}
              columns={['text']}
              className="w-100 mh-middle"
              onChangeState={setText}
            />
            <Checkbox
              name=""
              columns={['']}
              items={[{ displayName: '入力必須', value: '1' }]}
              onChangeState={(v) => {
                setRequired(!!v.length);
              }}
            />
            <CaptionButton
              buttonType="basic"
              caption="確認する"
              name=""
              onClick={() => {
                setResult('');
                setOpen(true);
              }}
            />
            <TextInputDialog
              key={isOpen ? '1' : ''}
              isOpen={isOpen}
              inputOption={{
                initialText: text,
                validateOption: { required, maxLength: 20 },
              }}
              messageOption={{
                headerLabel: {
                  id: 'modification_request',
                  prefixId: 'DIALOG_TITLE',
                },
                messageLabel: {
                  id: 'modification_request',
                  prefixId: 'DIALOG_DESCRIPTION',
                },
              }}
              onDecision={(text) => {
                setResult(text);
                setText(text);
                setOpen(false);
              }}
              onCancel={() => {
                setResult('キャンセル');
                setOpen(false);
              }}
            />
          </div>
          <div className="w-66">
            <pre className="output">{result}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
