import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
} from '~/shared/components';
import {
  ConfirmationDialog,
  FinancialRegistrationDialog,
  FinancialRegistrationDialogRef,
  InitAccuracyFormatStyle,
  PageNavigation,
  SimpleIconListView,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import {
  BASE_DIGIT_THOUSAND,
  FullMethodName_ListBusinessUnitFinancials,
  PageState,
  ViewId,
  convertBaseDigitOnceTo,
  getDateFormatWithTimezone,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getPathnameByViewId,
  getWorkerExceptionMessage,
  includeInputValidateError,
  mergeAmountNumber,
} from '~/shared/utils';

import Long from 'long';
import './OcpBusinessUnitProfileInput.css';

interface OcpFinancialListItem {
  id: string;
  period: string;
  netSales: string;
  operatingProfit: string;
  ordinaryIncome: string;
  rawData: mtechnavi.api.company.IBusinessUnitFinancial;
}

const YM_ACCURACY = InitAccuracyFormatStyle.find(
  (v) => v.formatStyle === 'YYYY/MM'
)?.accuracy as sharelib.Datetime.Accuracy;

// API の型から画面用の型に変換する
const rawApiDataToViewData = (
  rawData: mtechnavi.api.company.IBusinessUnitFinancial
): OcpFinancialListItem => {
  return {
    // businessUnitFinancialId がなければ新規追加データとみなす。
    // その場合、登録するまでの間の一意な id として現在時刻を持つ。
    id: rawData.businessUnitFinancialId || new Date().getTime().toString(),
    netSales: rawData.netSales
      ? mergeAmountNumber(
          convertBaseDigitOnceTo(rawData.netSales, BASE_DIGIT_THOUSAND)
        )
      : '',
    operatingProfit: rawData.operatingProfit
      ? mergeAmountNumber(
          convertBaseDigitOnceTo(rawData.operatingProfit, BASE_DIGIT_THOUSAND)
        )
      : '',
    ordinaryIncome: rawData.ordinaryIncome
      ? mergeAmountNumber(
          convertBaseDigitOnceTo(rawData.ordinaryIncome, BASE_DIGIT_THOUSAND)
        )
      : '',
    period: `${getDateFormatWithTimezone(
      rawData.periodStartDt
    )}-${getDateFormatWithTimezone(rawData.periodEndDt)}`,
    rawData,
  };
};

const VIEW_ID: ViewId = 'OCP_BUSINESS_UNIT_FINANCIAL_INPUT';
export const OcpBusinessUnitFinancialInput = () => {
  const sourcePageInfo = useRef((useLocation().state as PageState) ?? []);

  const intl = useIntl();
  const navi = useNavigate();
  const backPageUrl = useMemo(
    () => getPathnameByViewId(sourcePageInfo.current.sourceViewId)?.path ?? '/',
    [sourcePageInfo]
  );
  const myCompanyInfo = useRef<mtechnavi.api.company.ICompany>();

  type TabName = 'financial';
  const tabs: TabName[] = ['financial'];
  const [tabPanel, setTabPanel] = useState<TabName>('financial');
  const isCurrentTab = (target: TabName) => {
    return tabPanel === target;
  };

  // ラベル
  const labelSave = GetMessageWithIntl(intl, {
    id: 'save',
  });

  // メッセージ
  const viewMessageBack: MessageProps = {
    id: 'confirmationDialogMessageBack',
    prefixId: VIEW_ID,
  };
  const viewMessageSave: MessageProps = {
    id: 'C0000003',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'save' }),
    },
  };
  const viewMessageDelete: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };

  const [isLoading, setLoading] = useState(false);
  const [updatedProperties, setUpdatedProperties] =
    useState<sharelib.IEmbeddedUpdatedProperties>();
  const [listData, setListData] = useState<OcpFinancialListItem[]>([]);
  const financialRegistrationDialogRef =
    useRef<FinancialRegistrationDialogRef>(null);

  // 画面レイアウト
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);

  const [selectId, setSelectId] = useState('');

  // 確認ダイアログ（戻る）
  const [isBackConfirmOpen, setBackConfirmOpen] = useState(false);
  // 確認ダイアログ（保存）
  const [isSaveConfirmOpen, setSaveConfirmOpen] = useState(false);
  // 確認ダイアログ（削除）
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState('');

  // 入力チェック
  const formAreaRef = useRef(null);

  const isInputError = (): boolean => {
    return includeInputValidateError(document, intl, []);
  };

  const handleDelete = () => {
    setListData(listData.filter((v) => v.id !== deleteTargetId));
    setDeleteTargetId('');
    setDeleteConfirmOpen(false);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await saveBusinessUnitFinancial();
      setSaveConfirmOpen(false);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
    backToPreviewPage();
  };

  const getBusinessUnitFinancialList = async () => {
    return await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: FullMethodName_ListBusinessUnitFinancials,
      filter: {},
      requestBody: {
        companyIds: [myCompanyInfo.current?.companyId as string],
      },
      sort: [], // ソートは画面項目セット時に行うため不要
    });
  };

  const saveBusinessUnitFinancial = async () => {
    const financialList: mtechnavi.api.company.IBusinessUnitFinancial[] =
      listData.map(({ rawData: originalData }) => ({
        companyId: myCompanyInfo.current?.companyId,
        ...originalData,
      }));

    await window.App.services.ui.worker.apiCall({
      actionName: 'saveOcpFinancial',
      request: {
        businessUnitFinancials: financialList,
        companyId: myCompanyInfo.current?.companyId ?? '',
      },
    });
  };

  // 戻るページ
  const backToPreviewPage = () => {
    const ids = [] as string[];
    sourcePageInfo.current.ids?.map((v) => ids.push(v));
    const state: PageState = {
      ids: sourcePageInfo.current.beforeStateIds ?? [],
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.current.naviFilters,
      beforeStateIds: sourcePageInfo.current.beforeStateIds,
      baseViewOption: sourcePageInfo.current.baseViewOption,
      confirmationViewOption: sourcePageInfo.current.confirmationViewOption,
    };
    navi(backPageUrl, { state });
  };

  const listDataSort = (list: OcpFinancialListItem[]) => {
    // 並び替え
    return list.sort((a, b) => {
      const start = Long.fromValue(
        a.rawData.periodStartDt?.timestamp || 0
      ).toNumber();
      const end = Long.fromValue(
        b.rawData.periodStartDt?.timestamp || 0
      ).toNumber();
      return start < end ? 1 : -1;
    });
  };

  // 画面データ取得
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        // 自社情報を取得
        myCompanyInfo.current =
          await window.App.services.companyService.getCompany({});

        const financialList = (await getBusinessUnitFinancialList())
          .items as mtechnavi.api.company.IBusinessUnitFinancial[];
        if (!financialList) {
          setLoading(false);
          return;
        }
        const ocpList: OcpFinancialListItem[] = financialList.map((item) => {
          setUpdatedProperties(item.updatedProperties!);
          item.periodStartDt!.accuracy = YM_ACCURACY;
          item.periodEndDt!.accuracy = YM_ACCURACY;
          return rawApiDataToViewData(item);
        });
        setListData(listDataSort(ocpList));
        setLoading(false);
      })();
    } catch (err) {
      error(getExceptionMessage(intl, err));
    }

    // 初回だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  // リサイズ
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  const renderFinancialComponent = () => {
    return (
      <>
        <div className="tab-info-body">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-80">
                <SimpleIconListView
                  data={listData}
                  viewOptions={{
                    previewRowCount: 10,
                    keyColumn: 'id',
                    columns: [
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_FINANCIAL_INPUT.fiscalYear',
                        },
                        propertyName: 'period',
                        width: '10rem',
                      },
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_FINANCIAL_INPUT.netSales',
                        },
                        propertyName: 'netSales',
                        align: 'right',
                        width: '12rem',
                      },
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_FINANCIAL_INPUT.operatingProfit',
                        },
                        propertyName: 'operatingProfit',
                        align: 'right',
                        width: '12rem',
                      },
                      {
                        header: {
                          id: 'OCP_BUSINESS_UNIT_FINANCIAL_INPUT.ordinaryIncome',
                        },
                        propertyName: 'ordinaryIncome',
                        align: 'right',
                        width: '12rem',
                      },
                    ],
                    omitFooter: true,
                  }}
                  actionOptions={{}}
                  iconMenuOptions={{
                    iconMenu: [
                      {
                        name: 'edit',
                        displayName: '編集',
                        func: (item: OcpFinancialListItem) => {
                          setSelectId(item.id);
                          financialRegistrationDialogRef.current?.open(
                            item.rawData
                          );
                        },
                      },
                      {
                        name: 'delete',
                        displayName: '削除',
                        func: (item: OcpFinancialListItem) => {
                          setDeleteTargetId(item.id);
                          setDeleteConfirmOpen(true);
                        },
                      },
                    ],
                  }}
                />
              </div>
              <div className="w-20">
                <CaptionButton
                  buttonType="basic"
                  caption="新規行追加"
                  name=""
                  onClick={() => {
                    setSelectId('');
                    financialRegistrationDialogRef.current?.open({});
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="OcpBusinessUnitFinancialInput">
          <div className="header">
            <PageNavigation
              backpagePath="/opc-businessunit-profile-confirmation"
              pageInfo={{
                isVisibleMoveNavi: false,
              }}
              infoOption={{
                lastUpdateInfo: {
                  isVisibleUpdateInfo: true,
                  attribute: updatedProperties?.updatedBy?.email ?? '',
                  attributeAt: updatedProperties?.updatedAt ?? undefined,
                  content: updatedProperties?.updatedBy?.displayName ?? '',
                },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
              handleBackPage={() => setBackConfirmOpen(true)}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
            ref={formAreaRef}
          >
            <div className="input">
              <div className="basic-info-body">
                <div className="tabPanelContents">
                  {tabs.map((v, index) => {
                    const selectPane = v === tabPanel ? 'current' : '';
                    return (
                      <div
                        key={`tab-${index}`}
                        className={`tabPanel ${selectPane}`}
                        onClick={() => setTabPanel(v)}
                      >
                        {GetMessageWithIntl(intl, { prefixId: VIEW_ID, id: v })}
                      </div>
                    );
                  })}
                </div>
                {isCurrentTab('financial') && renderFinancialComponent()}
              </div>
            </div>
          </div>
          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                <CaptionButton
                  name="saveButton"
                  buttonType="basic"
                  caption={labelSave}
                  onClick={() => {
                    if (isInputError()) {
                      return;
                    }
                    setSaveConfirmOpen(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Toast />
      </Container>
      {/* 戻る 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isBackConfirmOpen}
        viewMessage={viewMessageBack}
        onDecision={() => {
          backToPreviewPage();
        }}
        onCancel={() => {
          setBackConfirmOpen(false);
        }}
      />
      <FinancialRegistrationDialog
        ref={financialRegistrationDialogRef}
        onDecision={(v) => {
          if (selectId) {
            const newList = listData.map((item) => {
              if (item.id !== selectId) {
                return item;
              }
              return rawApiDataToViewData({
                ...item.rawData,
                ...v,
              });
            });
            setListData(newList);
          } else {
            const data = rawApiDataToViewData({
              ...v,
              businessUnitFinancialId: '',
              updatedAt: null,
            });
            const newList = listDataSort([...listData, data]);
            setListData(newList);
          }
        }}
      />
      {/* 保存 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isSaveConfirmOpen}
        viewMessage={viewMessageSave}
        onDecision={() => {
          handleSave();
        }}
        onCancel={() => {
          setSaveConfirmOpen(false);
        }}
      />
      {/* 削除 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isDeleteConfirmOpen}
        viewMessage={viewMessageDelete}
        onDecision={() => {
          handleDelete();
        }}
        onCancel={() => {
          setDeleteConfirmOpen(false);
        }}
      />
      {isLoading && <LoadingIcon />}
    </>
  );
};
