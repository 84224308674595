import { useState } from 'react';
import {
  Rect,
  SimpleListView,
  Viewer,
  ViewerControlButton,
} from '~/shared/components/ui';
import { mtechnavi } from '~/shared/libs/clientsdk';

interface Props {
  previewAsset?: mtechnavi.api.assetinventory.IAsset;
}
export const ViewerWIthOtherColumn = ({ previewAsset }: Props) => {
  const [mode, setMode] = useState<'comment' | 'attributes'>('comment');

  return (
    <>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-66">
            <Viewer
              assetId={previewAsset?.assetId ?? ''}
              fileName={previewAsset?.filename ?? ''}
              controls={{
                downloading: true,
                pager: true,
                scaling: true,
                cropping: mode === 'comment',
                croppingOption: {
                  shapeList: {
                    Rectangle: { iconType: 'square', component: Rect },
                  },
                },
                extraControlsPosition: 'top',
                extraControls: [
                  <>
                    <ViewerControlButton
                      name="attributes"
                      isActive={mode === 'attributes'}
                      iconType="attributes"
                      onClick={() => setMode('attributes')}
                    />
                    <ViewerControlButton
                      name="comment"
                      isActive={mode === 'comment'}
                      iconType="comment"
                      onClick={() => setMode('comment')}
                    />
                  </>,
                  mode === 'attributes' ? (
                    <>
                      <ViewerControlButton
                        name="crop"
                        iconType="crop"
                        onClick={() => console.log('edit Segment')}
                      />
                      <ViewerControlButton
                        name="edit"
                        iconType="edit"
                        onClick={() => console.log('update File')}
                      />
                    </>
                  ) : null,
                ],
              }}
              onSelectionEnd={(selection) =>
                console.log('onSelection', selection)
              }
              width="100%"
              height="100%"
            />
          </div>
          <div className="w-33">
            <SimpleListView
              actionOptions={{}}
              viewOptions={{
                columns: [
                  {
                    propertyName: 'label',
                    width: '6rem',
                    header: { id: '属性名' },
                  },
                  { propertyName: 'data', header: { id: '属性値' } },
                  {
                    propertyName: 'decision',
                    width: '4rem',
                    header: { id: '確定' },
                  },
                ],
              }}
              data={[
                {
                  label: 'assetId',
                  data: previewAsset?.assetId,
                  decision: '◯',
                },
                {
                  label: 'ファイル名',
                  data: previewAsset?.filename,
                  decision: '◯',
                },
                {
                  label: 'mimeType',
                  data: previewAsset?.mimeType,
                  decision: '◯',
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};
