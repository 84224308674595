import { SimpleListView } from '~/shared/components/ui';
import { ViewId } from '~/shared/utils';
import { useBlueprintConfirmationData } from './BlueprintConfirmationDataProvider';
import { useMemo } from 'react';
import Long from 'long';

interface BlueprintBlueprintConfirmationLinkProps {
  viewId: ViewId;
}

interface BlueprintLinkViewItem {
  category: string;
  linkedDisplayName: JSX.Element;
}
/** 関連リンクタブ */
export const BlueprintBlueprintConfirmationLink = ({
  viewId,
}: BlueprintBlueprintConfirmationLinkProps) => {
  const { blueprintContent } = useBlueprintConfirmationData();

  const viewLinkItems = useMemo(() => {
    const sortedItem = (blueprintContent?.blueprintLinkItems ?? []).sort(
      (a, b) => {
        const aOrder = Long.fromValue(a.order ?? 0)?.toNumber();
        const bOrder = Long.fromValue(b.order ?? 0)?.toNumber();
        if (aOrder === bOrder) {
          return (a.displayName ?? '').localeCompare(b.displayName ?? '', 'ja');
        }
        return aOrder > bOrder ? 1 : -1;
      }
    );
    const viewItem: BlueprintLinkViewItem[] = sortedItem.map((item) => ({
      category: item.category ?? '',
      linkedDisplayName: (
        <a href={item.linkUrl ?? '#'} target="_blank" rel="noreferrer">
          {item.displayName ?? ''}
        </a>
      ),
    }));
    return viewItem;
  }, [blueprintContent?.blueprintLinkItems]);

  return (
    <div className="input-line">
      <div className="item-group-100">
        <div className="w-80">
          <SimpleListView
            actionOptions={{}}
            data={viewLinkItems}
            viewOptions={{
              columns: [
                {
                  propertyName: 'category',
                  width: '30%',
                  header: { viewId, id: 'link.category' },
                },
                {
                  propertyName: 'linkedDisplayName',
                  header: { viewId, id: 'link.linkedDisplayName' },
                },
              ],
              previewRowCount: 10,
            }}
          />
        </div>
      </div>
    </div>
  );
};
