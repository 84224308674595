import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_DeleteBlueprintRevisionWithRelatedItems } from 'worker/fullMethodName/crud';

type DeleteRevisionPram = Pick<
  mtechnavi.api.blueprint.IBlueprintRevision,
  'blueprintRevisionId' | 'updatedAt'
>;

/** 図面の改訂情報削除 */
export const deleteBlueprintRevision = async (
  worker: AppSaveWorker,
  request: DeleteRevisionPram
) => {
  return await worker.invokeListRequest<
    mtechnavi.api.blueprint.IDeleteBlueprintRevisionWithRelatedItemsRequest,
    void
  >(FullMethodName_DeleteBlueprintRevisionWithRelatedItems, {
    blueprintRevision: request,
  });
};
