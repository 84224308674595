import { mtechnavi } from '~/shared/libs/clientsdk';
import {
  GetMessageWithIntl,
  ModalDialogComponent,
  ModalDialogComponentProps,
  MessageProps,
} from '../..';
import { CaptionButton } from '~/shared/components/ui/Button';
import { SimpleListView } from '~/shared/components/ui/SimpleListView';
import { IconButton } from '~/shared/components/ui/Button';
import { useIntl } from 'react-intl';
import { useState, useEffect } from 'react';
import { getRandomStringId } from '~/shared/utils';
import { formatUserWIthEmail } from '~/shared/utils/formatter';

const DialogId = 'ownStaffDialog';

interface OwnStaffMessageOption {
  headerLabelId: MessageProps;
}

export interface OwnStaffInputOption {
  isDisplayButton: boolean;
  code: string;
  displayName: string;
  notificationUsers: mtechnavi.api.company.BusinessUnitContact.INotificationUser[];
}
export interface OwnStaffDialogProps {
  isOpen: boolean;
  messageOption?: OwnStaffMessageOption;
  inputOption?: OwnStaffInputOption;
  onCancel: () => void;
  onNotificationSetting: () => void;
}

interface NotificationUserType {
  id: string; // key RandomStringId
  userId: string; // user.userId
  category: string; // category.displayName.ja
  user: string; // user.displayName ,user.email
  categorySort: string; // category.systemName
}

/**
 * 自社担当ダイアログ
 */
export const OwnStaffDialog = (props: OwnStaffDialogProps) => {
  const { isOpen, inputOption, onCancel, onNotificationSetting } = props;
  const { code, displayName, notificationUsers, isDisplayButton } =
    inputOption ?? {};
  const intl = useIntl();

  const [notificationUserItems, setNotificationUserItems] = useState<
    NotificationUserType[]
  >([]);

  const formReset = () => {
    setNotificationUserItems([]);
  };

  // フォームに初期値をセットする
  useEffect(() => {
    if (!isOpen) {
      formReset();
      return;
    }
    const list = notificationUsers?.map((v) => {
      return {
        id: getRandomStringId(),
        userId: v.user?.userId ?? '',
        category: v.category?.displayNameLang?.ja ?? '',
        user: formatUserWIthEmail(v.user?.displayName, v.user?.email),
        categorySort: v.category?.systemName ?? '',
      } as NotificationUserType;
    });

    // アカウントでソート
    list?.sort((val1, val2) => val1.user.localeCompare(val2.user));

    // 分類でソート
    list?.sort((val1, val2) =>
      val1.categorySort.localeCompare(val2.categorySort)
    );

    setNotificationUserItems(list ?? []);
    // ダイアログ起動時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleCancel = () => {
    onCancel();
  };

  const handleNotificationSetting = () => {
    onNotificationSetting();
  };

  const elements = (
    <div className="detail-area">
      <div className="description-area">
        <h4>
          {code || ''}:{displayName || ''}
        </h4>
      </div>
      <div className="input-line">
        <div className="item-group-100">
          <div className="w-100">
            <div className="notification-user-list-area">
              <SimpleListView
                data={notificationUserItems ?? []}
                viewOptions={{
                  readonly: true,
                  omitFooter: true,
                  previewRowCount: 3,
                  keyColumn: 'id',
                  columns: [
                    {
                      propertyName: 'category',
                      width: '8rem',
                      header: {
                        id: 'category',
                        prefixId: DialogId,
                      },
                    },
                    {
                      propertyName: 'user',
                      header: {
                        id: 'user',
                        prefixId: DialogId,
                      },
                    },
                  ],
                }}
                actionOptions={{}}
              />
            </div>
          </div>
        </div>
      </div>
      {isDisplayButton && (
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="input-line no-space">
                <div className="item-group-100 no-space">
                  <IconButton
                    name="NotificationSettingIcon"
                    className="btn btn-normal"
                    caption={GetMessageWithIntl(intl, {
                      prefixId: DialogId,
                      id: 'notificationSetting',
                    })}
                    properties={[]}
                    buttonType="basic"
                    onClick={handleNotificationSetting}
                    iconType="recipients"
                  />
                  <p>
                    {GetMessageWithIntl(intl, {
                      prefixId: DialogId,
                      id: 'notificationSetting.description',
                    })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={onCancel}
        />
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: handleCancel,
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: 'OwnStaff',
    },
    messageLabelId: {},
    elements,
  };

  return (
    <div className="OwnStaffDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
