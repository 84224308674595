import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { GetMessageWithIntl } from '~/shared/components';
import { CaptionButton, IconButton } from '~/shared/components/ui/Button';
import {
  convertOrganizationStructureReferenceToFilterboxItem,
  formatBool,
  nameOptionToLocaleString,
  ViewId,
} from '~/shared/utils';
import './BlueprintBlueprintConfirmationHeader.css';
import {
  BlueprintRevisionDialog,
  BlueprintRevisionDialogResult,
} from '~/shared/components/ui/Dialog/BlueprintRevisionDialog';
import { useBlueprintConfirmationData } from './BlueprintConfirmationDataProvider';

export interface BlueprintConfirmationHeaderProps {
  viewId: ViewId;
  onRevisionAdd?: (
    result: BlueprintRevisionDialogResult
  ) => Promise<void> | void;
  handleHeaderEdit: () => void;
  isEditable: boolean;
}

export const BlueprintBlueprintConfirmationHeader = ({
  viewId,
  onRevisionAdd,
  handleHeaderEdit,
  isEditable,
}: BlueprintConfirmationHeaderProps) => {
  const intl = useIntl();
  const { blueprintContent, isProcessing: buttonDisabled } =
    useBlueprintConfirmationData();

  const [isOpenRevision, setOpenRevision] = useState(false);

  return (
    <>
      <div className="blueprint-confirmation-header-information">
        <div className="edit-icon">
          <IconButton
            name="edit"
            iconType="edit"
            buttonType="basic"
            onClick={() => handleHeaderEdit && handleHeaderEdit()}
            disabled={buttonDisabled || !isEditable}
          />
        </div>
        <div className="information">
          <div className="input-line header-contents-line">
            <div className="item-group-100">
              <div className="w-100">
                <span className="displayNameLang">
                  {`${blueprintContent?.blueprint?.displayName ?? ''}[${
                    blueprintContent?.blueprint?.blueprintNumber ?? ''
                  }]`}
                </span>
              </div>
            </div>
          </div>
          <div className="input-line header-contents-line">
            <div className="item-group-100">
              <div className="w-20 usable">
                {GetMessageWithIntl(intl, { id: 'usable', viewId })}：
                {formatBool(blueprintContent?.blueprint?.usable)}
              </div>
              <div className="w-20 usable">
                {GetMessageWithIntl(intl, { id: 'usedSystem', viewId })}：
                {blueprintContent?.blueprint?.usedSystems
                  ?.map((system) =>
                    GetMessageWithIntl(intl, {
                      id: `usedSystem.${system}`,
                      viewId,
                    })
                  )
                  ?.join(',')}
              </div>
              <div className="w-20">
                <CaptionButton
                  name={''}
                  buttonType="basic"
                  caption="改訂登録"
                  onClick={() => setOpenRevision(true)}
                  disabled={buttonDisabled || !isEditable}
                />
              </div>
            </div>
          </div>
          <div className="input-line header-contents-line">
            <div className="item-group-100">
              <div className="w-20">
                {GetMessageWithIntl(intl, {
                  id: 'blueprintContentType',
                  viewId,
                })}
                ：
                {nameOptionToLocaleString(
                  intl,
                  blueprintContent?.blueprint?.blueprintContentType
                )}
              </div>
              <div className="w-20">
                {GetMessageWithIntl(intl, {
                  id: 'category',
                  viewId,
                })}
                ：
                {nameOptionToLocaleString(
                  intl,
                  blueprintContent?.blueprint?.category
                )}
              </div>
              <div className="w-40">
                {GetMessageWithIntl(intl, {
                  id: 'managementOrganization',
                  viewId,
                })}
                ：
                {convertOrganizationStructureReferenceToFilterboxItem(
                  blueprintContent?.blueprint?.managementOrganization
                )?.displayName ?? ''}
              </div>
            </div>
          </div>
          <div className="input-line header-contents-line">
            <div className="item-group-100">
              <div className="w-20">
                {GetMessageWithIntl(intl, { viewId, id: 'analysisItem1' })}：
                {blueprintContent?.blueprint?.analysisSettingItems?.at(0) ?? ''}
              </div>
              <div className="w-20">
                {GetMessageWithIntl(intl, { viewId, id: 'analysisItem2' })}：
                {blueprintContent?.blueprint?.analysisSettingItems?.at(1) ?? ''}
              </div>
              <div className="w-20">
                {GetMessageWithIntl(intl, { viewId, id: 'analysisItem3' })}：
                {blueprintContent?.blueprint?.analysisSettingItems?.at(2) ?? ''}
              </div>
              <div className="w-20">
                {GetMessageWithIntl(intl, { viewId, id: 'analysisItem4' })}：
                {blueprintContent?.blueprint?.analysisSettingItems?.at(3) ?? ''}
              </div>
              <div className="w-20">
                {GetMessageWithIntl(intl, { viewId, id: 'analysisItem5' })}：
                {blueprintContent?.blueprint?.analysisSettingItems?.at(4) ?? ''}
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        {/* 改訂登録ダイアログ(編集) */}
        <BlueprintRevisionDialog
          key={isOpenRevision ? 1 : 0}
          isOpen={isOpenRevision}
          inputOption={{ mode: 'add' }}
          messageOption={{
            headerLabel: {
              prefixId: 'DIALOG_TITLE',
              id: 'blueprintRevisionRegistration',
            },
          }}
          onDecision={async (result) => {
            if (onRevisionAdd) {
              await onRevisionAdd(result);
              setOpenRevision(false);
            } else {
              setOpenRevision(false);
            }
          }}
          onCancel={() => setOpenRevision(false)}
        />
      </>
    </>
  );
};
