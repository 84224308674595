import { useToolContext } from '~/shared/contexts/ToolMenuProvider';
import { ForumTypeName } from '../../ui/Forum';
import { useMemo, useState } from 'react';
import { getControlCompletionByTypeName } from '../../ui/Forum/utils/util';

export interface ForumInfo {
  /** フォーラムのレコードタイプをセット */
  typeName: ForumTypeName;
  /** 紐付ける業務データの ID をセット */
  resourceId: string;
  /** スレッドタイプが DM の場合の対象企業ID(送信先として選択できるもの)をセット */
  companyIds?: string[];
}

/**
 * フォーラムツール機能(右メニュー)を利用するためのカスタムフック
 */
export const useForumTool = () => {
  const {
    viewId,
    typeName,
    companyIds,
    resourceId,
    setTypeName,
    setCompanyIds,
    setResourceId,
    setActiveToolType,
  } = useToolContext();

  const [isControlCompletion, setControlCompletion] = useState(false);

  const setForumInfo = useMemo(
    () =>
      ({ typeName, resourceId, companyIds }: ForumInfo) => {
        setTypeName(typeName);
        setResourceId(resourceId);
        setCompanyIds(companyIds ?? []);
        setControlCompletion(getControlCompletionByTypeName(typeName));
      },
    [setTypeName, setResourceId, setCompanyIds]
  );

  const closeForum = () => {
    setActiveToolType('None');
  };

  return {
    viewId,
    typeName,
    companyIds,
    resourceId,
    isControlCompletion,
    /** フォーラム利用に必要な情報をセットする */
    setForumInfo,
    /** フォーラム UI を閉じる処理 */
    closeForum,
  };
};
