import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_DeleteBlueprintAttribute } from 'worker/fullMethodName/crud';

/** 図面属性の削除 */
export const deleteBlueprintAttribute = (
  worker: AppSaveWorker,
  req: mtechnavi.api.blueprint.IDeleteBlueprintAttributeRequest
) => {
  return worker.invokeListRequest<
    mtechnavi.api.blueprint.IDeleteBlueprintAttributeRequest,
    void
  >(FullMethodName_DeleteBlueprintAttribute, req);
};
