import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_UpdateBlueprintSegment } from 'worker/fullMethodName/crud';

/** 図面セグメント情報の更新 */
export const updateBlueprintSegment = (
  worker: AppSaveWorker,
  req: mtechnavi.api.blueprint.IUpdateBlueprintSegmentRequest
) => {
  return worker.invokeListRequest<
    mtechnavi.api.blueprint.IUpdateBlueprintSegmentRequest,
    mtechnavi.api.blueprint.IBlueprintSegment
  >(FullMethodName_UpdateBlueprintSegment, req);
};
