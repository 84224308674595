import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import './SurveyRequestSenderList.css';
import {
  PageState,
  FullMethodName_ListSurveyRequestContents,
  FullMethodName_ListSurveyBaseRequestContents,
  ViewId,
  convertDate,
  getExceptionMessage,
  getWorkerExceptionMessage,
  getPresetAndSchema,
  convertDatetime,
  movePage,
  saveLocalStorageCheckboxData,
  saveInitialLocalStorageData,
  autoDownloadFileWithDate,
  convertUserReferenceToFilterboxItem,
  convertOrganizationStructureReferenceToFilterboxItem,
} from '~/shared/utils';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  error,
  success,
  MessageProps,
} from '~/shared/components';
import {
  ApprovalRejectionDialog,
  ApprovalRejectionDialogMessageOption,
  ApprovalRejectionInputOption,
  CommentDialog,
  ConfirmationDialog,
  DeadlineChangeDialog,
  DeadlineChangeDialogOutputOption,
  DeadlineChangeResult,
  ListView,
  ViewMenu,
  IconMenuName,
  Formatter,
  FormatterOptions,
  InitialFilter,
  InitialFilterItem,
  MenuActionItem,
  OutputDialog,
  OutputDialogOutput,
  Preset,
  Property,
  Schema,
  dayFormatter,
  getAltDisplaySchema,
  getCommaTypeNumberSchema,
  getDayFormetterDisplaySchema,
  getJoinedColumnSchema,
  getSchemaByTypeName,
  OutputVolumeType,
  OutputInfoType,
} from '~/shared/components/ui';
import { AggregateStage } from '~/worker';
import { PresetItem } from '~/shared/services';
import { mtechnavi, sharelib } from '~/shared/libs/clientsdk';
import Long from 'long';
import { useAuth } from '~/shared/contexts/AuthProvider';
import {
  deletePlanResultFormData,
  deleteRequestFormData,
  listLatestSurveyReplyDueDateChangeRequest,
} from '../utils';
import { BqListDelayTime } from '~/shared/config';
import { useHelp } from '~/shared/components/tool/Help';

type SurveyRequestContentAggregated =
  mtechnavi.ui.survey.SurveyRequestContentAggregated;
const aggregatedType = 'SurveyRequestContentAggregated';
const aggregateStages: AggregateStage[] = [
  {
    // 実績データをグループ化する
    $group: {
      _id: {
        surveyBaseRequestId: 'surveyRequest.surveyBaseRequestId',
        'status.code': 'status.code',
      },
      displayName: { $first: 'surveyRequest.displayName' },
      surveyBaseRequestId: {
        $first: 'surveyRequest.surveyBaseRequestId',
      },
      surveyRequestAutoName: {
        $first: 'surveyRequest.surveyRequestAutoName',
      },
      requesters: { $first: 'surveyRequest.requesters' },
      maxReplyDueDateDt: {
        $max: 'replyDueDateDt',
      },
      minReplyDueDateDt: {
        $min: 'replyDueDateDt',
      },
      replyDueDateDt: {
        $min: 'replyDueDateDt',
      },
      sendedAt: { $first: 'surveyRequest.sendedAt' },
      surveyRequestCatalog: {
        $first: 'surveyRequest.surveyRequestCatalog',
      },
      totalRequestUnit: { $count: {} },
      status: { $first: 'status' },
      guidance: { $first: 'surveyRequest.guidance' },
      issuerEmail: {
        $first: 'surveyRequest.issuerProperties.updatedBy.email',
      },
      // 実績データのみのプロパティ
      surveyRequestIds: {
        $addToSet: 'surveyRequest.surveyRequestId',
      },
      maxFlagActive: {
        $max: 'surveyRequest.flagProperties.active',
      },
      requesterManagementOrganization: {
        $first: 'surveyRequest.managementOrganization',
      },
    },
  },
  {
    // 予定データの名前をグループ化した実績データに合わせる
    $union: {
      from: FullMethodName_ListSurveyBaseRequestContents,
      fields: [
        {
          name: 'surveyBaseRequest.surveyBaseRequestId',
          as: '_id',
        },
        {
          name: 'surveyBaseRequest.displayName',
          as: 'displayName',
        },
        {
          name: 'surveyBaseRequest.surveyBaseRequestId',
          as: 'surveyBaseRequestId',
        },
        {
          name: 'surveyBaseRequest.surveyRequestAutoName',
          as: 'surveyRequestAutoName',
        },
        {
          name: 'surveyBaseRequest.requesters',
          as: 'requesters',
        },
        {
          name: 'surveyBaseRequest.replyDueDateDt',
          as: 'maxReplyDueDateDt',
        },
        {
          name: 'surveyBaseRequest.replyDueDateDt',
          as: 'minReplyDueDateDt',
        },
        {
          name: 'surveyBaseRequest.replyDueDateDt',
          as: 'replyDueDateDt',
        },
        {
          name: 'surveyBaseRequest.sendedAt',
          as: 'sendedAt',
        },
        {
          name: 'surveyBaseRequest.surveyRequestCatalog',
          as: 'surveyRequestCatalog',
        },
        'totalRequestUnit',
        'status',
        {
          name: 'surveyBaseRequest.guidance',
          as: 'guidance',
        },
        {
          name: 'surveyBaseRequest.managementOrganization',
          as: 'requesterManagementOrganization',
        },
        // 予定データのみのプロパティ
        {
          name: 'surveyBaseRequest.approvalRequest.approvalPlanStaff',
          as: 'approvalPlanStaffRef',
        },
        {
          name: 'surveyBaseRequest.approvalRequest.approvalPlanStaff.userId',
          as: 'approvalPlanStaffId',
        },
        {
          name: 'surveyBaseRequest.approvalRequest.approvalPlanOrganization',
          as: 'approvalPlanOrganizationUnitRef',
        },
        {
          name: 'surveyBaseRequest.approvalRequest.approvalPlanOrganization.organizationId',
          as: 'approvalPlanOrganizationUnitId',
        },
        {
          name: 'surveyBaseRequest.approvalRequest.requestedBy',
          as: 'approvalRequestUpdatedBy',
        },
        {
          name: 'surveyBaseRequest.approvalRequest.requestedComment',
          as: 'approvalRequestComment',
        },
        {
          name: 'surveyBaseRequest.requestUnits.businessUnitManagementId',
          as: 'businessUnitIds',
        },
        {
          name: 'surveyBaseRequest.issuerProperties.updatedBy.email',
          as: 'issuerEmail',
        },
        // 予定データ削除時用
        {
          name: 'surveyBaseRequest.updatedAt',
          as: 'updatedAt',
        },
        {
          name: 'surveyBaseRequest.requestFormIds',
          as: 'requestFormIds',
        },
        {
          name: 'surveyBaseRequest.surveyResultWorkFormIds',
          as: 'surveyResultWorkFormIds',
        },
      ],
      option: {
        useQuery: false,
      },
    },
  },
];

export const SurveyRequestSenderList = () => {
  const listItemRef = useRef<SurveyRequestContentAggregated[]>([]);
  const listAllItemRef = useRef<SurveyRequestContentAggregated[]>([]);
  const [isReload, setReload] = useState(false);
  const { pathname } = useLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sourcePageInfo = (useLocation().state as PageState) ?? [];
  // コミュニケーション機能設定
  const { setViewId } = useHelp();

  const [isLoading, setLoading] = useState(false);
  // 確認ダイアログ(削除)
  const [isShowDelete, setShowDelete] = useState(false);
  // 破棄ダイアログ
  const [isShowDiscard, setShowDiscard] = useState(false);
  const [discardComment, setDiscardComment] = useState('');
  // 出力ダイアログ
  const [isShowOutput, setShowOutput] = useState(false);
  // 回答期日変更ダイアログ
  const [isShowDeadlineChange, setShowDeadlineChange] = useState(false);
  const [deadlineChangeOption, setDeadlineChangeOption] =
    useState<DeadlineChangeDialogOutputOption>({ currentDeadline: null });
  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState<MessageProps>({});
  const confirmPromiseRef =
    useRef<(value: boolean | PromiseLike<boolean>) => void>();
  // 操作対象ID
  const targetId = useRef<string | null>(null);
  // 操作対象IDs
  const [selectedTargetIds, setSelectedTargetIds] = useState<string[]>([]);

  const intl = useIntl();
  const navigate = useNavigate();
  const myEmail = useAuth().user?.email ?? '';
  const [presetViewId, setPresetViewId] = useState<ViewId>();
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [presetItems, setPresetItems] = useState<PresetItem[]>();

  const [VIEW_ID, PathToReadMore, viewIdFilter] = useMemo<
    [ViewId, string, InitialFilter]
  >(() => {
    const filter: InitialFilter = {
      info: [],
    };

    let viewId: ViewId = '';
    let pathToReadMore = '';
    switch (pathname) {
      case '/supplier-base/survey-request-list':
        viewId = 'SURVEY_REQUEST_SENDER_LIST';
        pathToReadMore = '/supplier-base/survey-request-sender-detail-list';
        filter.info.push({ targetKey: 'issuerEmail', targetValue: myEmail });
        break;
      case '/supplier-base/survey-request-approval-list':
        viewId = 'SURVEY_REQUEST_SENDER_APPROVAL_LIST';
        pathToReadMore =
          '/supplier-base/survey-request-sender-approval-detail-list';
        filter.info.push({
          targetKey: 'approvalPlanStaffRef.email',
          targetValue: myEmail,
        });
        break;
      default:
        return ['', '', filter];
    }
    return [viewId, pathToReadMore, filter];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });
  const unselectedMessage = GetMessageWithIntl(intl, { id: 'E0000023' });
  const excessTargetMessage = GetMessageWithIntl(intl, { id: 'E0000073' });
  const invalidStatusMessage = GetMessageWithIntl(intl, { id: 'E0000020' });
  const invalidDueDateMessage = GetMessageWithIntl(intl, { id: 'E0000071' });
  const notFoundOutputMessage = GetMessageWithIntl(intl, { id: 'I0000004' });
  const sendMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'send' }),
    },
  };
  const sendWithDeleteFlagMessage = {
    prefixId: VIEW_ID,
    id: 'sendWithDeleteFlag',
  };
  const completeMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, {
        prefixId: VIEW_ID,
        id: 'complete',
      }),
    },
  };
  const completeWithDeleteFlagMessage = {
    prefixId: VIEW_ID,
    id: 'completeWithDeleteFlag',
  };

  // 承認却下ダイアログ
  const [isShowApprovalRejectionDialog, setShowApprovalRejectionDialog] =
    useState(false);
  // 承認却下ダイアログオプション
  const [approvalRejectionInputOption, setApprovalRejectionInputOption] =
    useState<ApprovalRejectionInputOption>({
      approveOrganizationStaff: { value: '', displayName: '' },
      approveOrganizationUnit: { value: '', displayName: '' },
      requestComment: '',
      dialogType: 'approval',
    });
  const [
    approvalRejectionDialogMessageOption,
    setApprovalRejectionDialogMessageOption,
  ] = useState<ApprovalRejectionDialogMessageOption>({
    headerLabelId: { id: 'approval', prefixId: 'DIALOG_TITLE' },
    messageLabelId: { id: 'approval', prefixId: 'DIALOG_DESCRIPTION' },
  });
  // 承認却下判定
  const [decline, setDecline] = useState(false);

  const naviFilterItems = useMemo(() => {
    if (!sourcePageInfo?.naviFilters) {
      return;
    }
    const naviFilter = sourcePageInfo.naviFilters.reduce(
      (filter, param) => {
        const initialFilterItems: InitialFilterItem[] = param.value.map((v) => {
          return {
            targetKey: param.key,
            targetValue: v,
          };
        });
        filter.info.push(...initialFilterItems);
        return filter;
      },
      { type: 'or', info: [] } as InitialFilter
    );
    if (naviFilter.info.length === 0) {
      return;
    }
    return naviFilter;
    // useMemoを使用しているためwarningが発生するが期待通りのためlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourcePageInfo]);

  const getTargetIds = (v: string[]): PageState => {
    const targetIds: string[] = [];
    const targets = listItemRef.current.filter((item) => v.includes(item._id));
    targets.map((item) => {
      // 実績データの場合
      if (item.surveyRequestIds && item.surveyRequestIds.length > 0) {
        item.surveyRequestIds.map((id) => {
          targetIds.push(id);
        });
        // 予定データの場合
      } else {
        item.businessUnitIds.map((id) => {
          const aa: string[] = [];
          aa.push(item.surveyBaseRequestId);
          aa.push(id);
          // aa.join('-');
          targetIds.push(aa.join('-'));
        });
      }
    });
    const state: PageState = {
      ids: targetIds,
      sourceViewId: VIEW_ID,
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    return state;
  };

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'headerIconMenu',
      menu: headerIconEvent(),
      maxMenuColumn: 5,
    });
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'footerMenu',
      menu: footerMenuEvent(),
    });
    return menuActionItems;
  };

  const headerIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 作成
    menuItems.push({
      name: 'noteadd',
      func: () => {
        const state: PageState = {
          sourceViewId: VIEW_ID,
          actionType: 'add',
          baseViewOption: { sourceViewId: VIEW_ID },
        };
        navigate('/supplier-base/survey-request-sender-input', { state });
      },
    });
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        const state = getTargetIds(v);
        state.subIds = v;
        navigate('/supplier-base/survey-request-sender-confirmation', {
          state,
        });
      },
    });
    // ダウンロード
    menuItems.push({
      name: 'export',
      func: (v?: string[]) => {
        setSelectedTargetIds(v ?? []);
        setShowOutput(true);
      },
    });
    // 破棄 (削除 / 破棄)
    menuItems.push({
      name: 'delete',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }

        const target = getRecordData(v[0]);
        if (!target) {
          error([unselectedMessage]);
          return;
        }
        // 一時保存・公開却下なら削除
        if (['1', '3'].some((code) => target.status?.code === code)) {
          targetId.current = v[0];
          setShowDelete(true);
          return;
        }
        // 公開中、部分回答、未開封なら破棄
        if (['5', '6', '11'].some((code) => target.status?.code === code)) {
          targetId.current = v[0];
          setDiscardComment('');
          setShowDiscard(true);
          return;
        }
        // それ以外はエラー
        error([invalidStatusMessage]);
      },
    });
    return menuItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        const state = getTargetIds(v);
        state.subIds = v;
        navigate('/supplier-base/survey-request-sender-confirmation', {
          state,
        });
      },
    });
    // 詳細一覧
    menuItems.push({
      name: 'read_more',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }
        const target = getRecordData(v[0]);
        if (!target) {
          error([unselectedMessage]);
          return;
        }

        // 依頼管理詳細一覧の保持しているキャッシュ情報をクリアする
        saveInitialLocalStorageData(
          'SURVEY_REQUEST_SENDER_DETAIL_LIST',
          myEmail
        );

        const state: PageState = {
          sourceViewId: VIEW_ID,
          ids: v ?? [],
          naviFilters: [
            {
              key: 'surveyRequest.surveyRequestAutoName',
              value: [Long.fromValue(target.surveyRequestAutoName).toString()],
            },
            {
              key: 'statusCodes',
              value: target?.status?.code ? [target.status.code] : [],
            },
          ],
        };
        movePage(state, navigate, PathToReadMore);
      },
    });
    // 確認フラグ
    menuItems.push({
      name: 'flag_off',
      func: () => {},
      viewMenuOption: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        iconChangeFn: (v: any): IconMenuName => {
          if (v['maxFlagActive']) {
            return 'flag_on';
          }
          return 'flag_off';
        },
      },
    });

    return menuItems;
  };

  const footerMenuEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 依頼終了
    menuItems.push({
      name: 'complete',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }
        const target = getRecordData(v[0]);
        if (!target) {
          error([unselectedMessage]);
          return;
        }

        // 公開中、部分回答、回答済【完了】、回答済【辞退】、未開封
        if (
          !['5', '6', '7', '8', '11'].some(
            (code) => target.status?.code === code
          )
        ) {
          error([invalidStatusMessage]);
          return;
        }

        targetId.current = v[0];
        handleComplete();
      },
    });
    // 回答期日変更
    menuItems.push({
      name: 'deadline_change',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }

        const target = getRecordData(v[0]);
        if (!target) {
          return;
        }

        // 公開中、部分回答、未開封
        if (!['5', '6', '11'].some((code) => target.status?.code === code)) {
          error([invalidStatusMessage]);
          return;
        }

        if (
          target.maxReplyDueDateDt?.timestamp !==
          target.minReplyDueDateDt?.timestamp
        ) {
          error([invalidDueDateMessage]);
          return;
        }

        targetId.current = v[0];
        setDeadlineChangeOption({
          currentDeadline: convertDate(
            target.maxReplyDueDateDt as sharelib.IDatetime | null
          ),
        });
        setShowDeadlineChange(true);
      },
    });

    menuItems.push({
      name: 'approval',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }
        const target = getRecordData(v[0]);
        if (!target) {
          error([unselectedMessage]);
          return;
        }
        targetId.current = v[0];
        setDecline(false);

        setApprovalRejectionInputOption({
          approveOrganizationStaff: convertUserReferenceToFilterboxItem(
            target.approvalPlanStaffRef
          ) ?? { value: '', displayName: '' },
          approveOrganizationUnit:
            convertOrganizationStructureReferenceToFilterboxItem(
              target.approvalPlanOrganizationUnitRef
            ) ?? { value: '', displayName: '' },
          requestComment: target.approvalRequestComment,
          dialogType: 'approval',
          isHighAffect: true,
        });
        setApprovalRejectionDialogMessageOption({
          headerLabelId: { id: 'approval', prefixId: 'DIALOG_TITLE' },
          messageLabelId: { id: 'approval', prefixId: 'DIALOG_DESCRIPTION' },
        });
        setShowApprovalRejectionDialog(true);
      },
    });
    menuItems.push({
      name: 'decline',
      func: (v?: string[]) => {
        if (!v) {
          return;
        }
        if (v.length !== 1) {
          error([excessTargetMessage]);
          return;
        }
        const target = getRecordData(v[0]);
        if (!target) {
          error([unselectedMessage]);
          return;
        }
        targetId.current = v[0];
        setDecline(true);

        setApprovalRejectionInputOption({
          approveOrganizationStaff: convertUserReferenceToFilterboxItem(
            target.approvalPlanStaffRef
          ) ?? { value: '', displayName: '' },
          approveOrganizationUnit:
            convertOrganizationStructureReferenceToFilterboxItem(
              target.approvalPlanOrganizationUnitRef
            ) ?? { value: '', displayName: '' },
          requestComment: target.approvalRequestComment,
          dialogType: 'rejection',
        });
        setApprovalRejectionDialogMessageOption({
          headerLabelId: { id: 'rejection', prefixId: 'DIALOG_TITLE' },
          messageLabelId: { id: 'rejection', prefixId: 'DIALOG_DESCRIPTION' },
        });
        setShowApprovalRejectionDialog(true);
      },
    });
    return menuItems;
  };

  const getRecordData = (
    id: string | null
  ): SurveyRequestContentAggregated | undefined => {
    return listItemRef?.current.find((item) => item._id === id);
  };

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [VIEW_ID, myEmail]);

  const handleDownload = async (result: OutputDialogOutput) => {
    setLoading(true);
    if (
      result.outputInfoType === OutputInfoType.Answers ||
      result.outputInfoType === OutputInfoType.AllAnswers
    ) {
      // 回答ファイル
      await exportSurveyResults(result);
    } else {
      // 依頼管理情報出力
      await exportSurveyRequests(result);
    }

    setShowOutput(false);
  };

  const exportSurveyResults = async (result: OutputDialogOutput) => {
    // 出力対象取得
    const selectedTargets: mtechnavi.api.survey.ExportSurveyResultFilesRequest.ITarget[] =
      [];
    if (result.outputVolume === OutputVolumeType.SelectedOnly) {
      if (selectedTargetIds.length === 0) {
        error([unselectedMessage]);
        setLoading(false);
        return;
      }
      listItemRef.current?.map((v) => {
        if (selectedTargetIds.includes(v._id)) {
          selectedTargets.push({
            surveyBaseRequestId: v.surveyBaseRequestId,
            statusCode: v.status?.code,
          });
        }
      });
    }

    try {
      const res =
        await window.App.services.surveyIOService.exportSurveyResultFiles({
          targets: selectedTargets,
          includeExported: result.outputInfoType === OutputInfoType.AllAnswers,
        });
      if (res) {
        if (!res?.assetId) {
          success([notFoundOutputMessage]);
        } else {
          success([successMessage]);
          autoDownloadFileWithDate(
            GetMessageWithIntl(intl, {
              viewId: VIEW_ID,
              id: 'exportSurveyResult',
            }),
            'zip',
            res?.assetId ?? ''
          );
        }
        setReload(true);
      }
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const exportSurveyRequests = async (result: OutputDialogOutput) => {
    // 出力対象取得
    const selectedSurveyRequestIds: string[] = [];
    const selectedBaseSurveyRequestIds: string[] = [];
    const existBaseStatus: string[] = ['B00', 'B01', 'B02', 'B03'];
    if (result.outputVolume === OutputVolumeType.SelectedOnly) {
      if (selectedTargetIds.length === 0) {
        error([unselectedMessage]);
        setLoading(false);
        return;
      }
      listItemRef.current?.map((v) => {
        if (selectedTargetIds.includes(v._id)) {
          if (existBaseStatus.includes(v.status?.systemName ?? '')) {
            selectedBaseSurveyRequestIds.push(v.surveyBaseRequestId);
          } else {
            selectedSurveyRequestIds.push(...v.surveyRequestIds);
          }
        }
      });
    } else {
      // 全ページの場合
      listAllItemRef.current?.map((v) => {
        if (
          existBaseStatus.includes(v.status?.systemName ?? '') ||
          !v.surveyRequestIds
        ) {
          selectedBaseSurveyRequestIds.push(v.surveyBaseRequestId);
        } else {
          selectedSurveyRequestIds.push(...v.surveyRequestIds);
        }
      });
    }

    try {
      const fileFormat = await window.App.services.ui.getFileFormat(
        'surveyRequest',
        intl
      );
      const res =
        await window.App.services.surveySenderService.exportSurveyRequests({
          surveyBaseRequestIds: selectedBaseSurveyRequestIds,
          surveyRequestIds: selectedSurveyRequestIds,
          fileFormat: fileFormat,
        });
      if (res) {
        success([successMessage]);
        autoDownloadFileWithDate(
          GetMessageWithIntl(intl, {
            viewId: VIEW_ID,
            id: 'exportSurveyRequest',
          }),
          'csv',
          res?.assetId ?? ''
        );
        setReload(true);
      }
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 依頼終了
  const handleComplete = async () => {
    const target = getRecordData(targetId.current);
    if (!target) {
      error([unselectedMessage]);
      return;
    }

    if (target.maxFlagActive) {
      if (!(await confirmation(completeWithDeleteFlagMessage))) {
        return;
      }
    } else {
      if (!(await confirmation(completeMessage))) {
        return;
      }
    }

    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'completeSurveyRequest',
        request: {
          surveyRequestIds: target.surveyRequestIds,
        },
      });
      if (target.maxFlagActive) {
        await window.App.services.ui.worker.apiCall({
          actionName: 'deleteFlagInSurveyRequest',
          request: {
            surveyRequestIds: target.surveyRequestIds,
          },
        });
      }
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 依頼削除
  const handleDelete = async () => {
    const target = getRecordData(targetId.current);
    if (!target) {
      error([unselectedMessage]);
      return;
    }
    setLoading(true);
    setReload(false);
    try {
      await Promise.all([
        // 依頼フォームデータの削除
        deleteRequestFormData(target.requestFormIds),
        // 回答フォームデータの削除
        deletePlanResultFormData(target.surveyResultWorkFormIds),
        // 依頼データの削除
        window.App.services.ui.worker.apiCall({
          actionName: 'deleteSurveyRequest',
          request: {
            surveyBaseRequestId: target.surveyBaseRequestId,
            updatedAt: target.updatedAt,
          },
        }),
      ]);

      setShowDelete(false);
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 依頼破棄
  const handleDiscard = async () => {
    const target = getRecordData(targetId.current);
    if (!target) {
      error([unselectedMessage]);
      return;
    }
    if (target.maxFlagActive) {
      if (!(await confirmation(sendWithDeleteFlagMessage))) {
        return;
      }
    } else {
      if (!(await confirmation(sendMessage))) {
        return;
      }
    }

    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'discardSurveyRequest',
        request: {
          surveyRequestIds: target.surveyRequestIds,
          comment: discardComment,
        },
      });
      if (target.maxFlagActive) {
        await window.App.services.ui.worker.apiCall({
          actionName: 'deleteFlagInSurveyRequest',
          request: {
            surveyRequestIds: target.surveyRequestIds,
          },
        });
      }
      setShowDiscard(false);
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 回答期日変更
  const handleDeadlineChange = async (result: DeadlineChangeResult) => {
    const target = getRecordData(targetId.current);
    if (!target) {
      error([unselectedMessage]);
      return;
    }
    setLoading(true);
    setReload(false);
    try {
      const changeRequests = await listLatestSurveyReplyDueDateChangeRequest(
        target.surveyRequestIds
      );

      await window.App.services.ui.worker.apiCall({
        actionName: 'changeSurveyRequestDeadline',
        request: target.surveyRequestIds.map((surveyRequestId) => ({
          surveyRequestId,
          replyDueDateDt: convertDatetime(result.deadline, 'YYYY/MM/DD'),
          comment: result.comment,
          surveyReplyDueDateChangeRequestId: changeRequests.items
            ?.filter((item) => item.surveyRequestId === surveyRequestId)
            .sort((a, b) =>
              Long.fromValue(a.requestedAt || 0).lt(
                Long.fromValue(b.requestedAt || 0)
              )
                ? 1
                : -1
            )
            .at(0)?.surveyReplyDueDateChangeRequestId,
        })),
      });
      setShowDeadlineChange(false);
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const handleApproveRejection = async (result: string) => {
    const target = getRecordData(targetId.current);
    if (!target) {
      error([unselectedMessage]);
      return;
    }
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'execSurveyApproveResult',
        request: {
          surveyBaseRequestId: target.surveyBaseRequestId,
          comment: result,
          decline: decline,
        },
      });
      // 承認の場合、依頼展開を行う
      if (!decline) {
        await window.App.services.ui.worker.apiCall({
          actionName: 'sharetoSurveyRequest',
          request: {
            surveyBaseRequestId: target.surveyBaseRequestId,
          },
        });
      }
      setShowApprovalRejectionDialog(false);
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    setViewId(VIEW_ID);
    (async () => {
      try {
        // preset関係の情報を取得
        const { childrenPresetItem, preset } = await getPresetAndSchema(
          VIEW_ID,
          [
            FullMethodName_ListSurveyBaseRequestContents,
            FullMethodName_ListSurveyRequestContents,
          ]
        );
        // 集計後の型名でスキーマ情報を取得
        const schema = getSchemaByTypeName(aggregatedType);

        // ja表示のみする処理
        const jaColumn: string[] = ['status.displayNameLang'];
        const formatterSch = getAltDisplaySchema(schema, jaColumn, 'ja');
        const dateColumn = ['sendedAt'];
        const dateSch = getDayFormetterDisplaySchema(formatterSch, dateColumn, {
          dayOpts: {
            formatType: 'YYYY/MM/DD',
          },
        });
        const commaSch = getCommaTypeNumberSchema(dateSch, [
          'totalRequestUnit',
        ]);
        const dueDateSch = getDueDateFormatterSchema(
          commaSch,
          ['replyDueDateDt'],
          {
            dayOpts: {
              formatType: 'YYYY/MM/DD',
              isAccuracy: true,
            },
          }
        );
        const joinedSch = getJoinedColumnSchema(
          dueDateSch,
          ['requesters'],
          '',
          true
        );
        // 取得した情報をセット
        setPresetItems(childrenPresetItem);
        setSchema(joinedSch);
        setPreset(preset);
        setPresetViewId(VIEW_ID);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  }, [VIEW_ID, intl]);

  /**
   * 確認ダイアログ処理
   * Promise で結果を制御する。
   * 確定: true / キャンセル: false
   */
  const confirmation = (viewMessage: MessageProps): Promise<boolean> => {
    setOpenConfirmDialog(true);
    setConfirmMessage(viewMessage);
    return new Promise((resolve) => {
      confirmPromiseRef.current = resolve;
    });
  };

  /**
   * 確認ダイアログの確認処理
   */
  const handleConfirmed = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(true);
    }
    setOpenConfirmDialog(false);
  };

  /**
   * 確認ダイアログのキャンセル処理
   */
  const handleCancel = () => {
    if (confirmPromiseRef.current) {
      confirmPromiseRef.current(false);
    }
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="SurveyRequestSenderList">
          <ListView
            isReload={isReload}
            fullMethodName={FullMethodName_ListSurveyRequestContents}
            pageInfo={{
              preset,
              schema,
              menuItem: setMenuActionItem(),
              menuTarget: '_id',
              presetItems,
              headerTitle: { viewId: VIEW_ID },
              listSkipType: {
                isTotal: true,
                isOutput: true,
                isListActionMenu: true,
              },
              reloadDelayTime: BqListDelayTime,
            }}
            filterItemOption={{
              aggregateStages: [...aggregateStages],
              naviFilterItems,
              isRequestBodyFilter: true,
              // initialFilterItems: initialDinamicFilter(VIEW_ID, presetViewId),
              initialFilterItems: viewIdFilter,
            }}
            stateOption={{
              onOriginalItemState: (items: unknown[]) =>
                (listItemRef.current =
                  items as SurveyRequestContentAggregated[]),
              onAllItemState: (items: unknown[]) =>
                (listAllItemRef.current =
                  items as SurveyRequestContentAggregated[]),
            }}
            isUseFilterSave={VIEW_ID === 'SURVEY_REQUEST_SENDER_LIST'}
          />

          <ConfirmationDialog
            isOpen={isShowDelete}
            viewMessage={{
              id: 'C0000001',
              value: {
                $1: GetMessageWithIntl(intl, {
                  id: 'delete',
                  prefixId: VIEW_ID,
                }),
              },
            }}
            onDecision={handleDelete}
            onCancel={() => setShowDelete(false)}
          />

          <CommentDialog
            isOpen={isShowDiscard}
            inputOption={{
              comment: discardComment,
              butonType: 'high',
            }}
            inputStateOption={{ onChangeComment: setDiscardComment }}
            messageOption={{
              headerLabelId: {
                id: 'survey_discard',
                prefixId: 'DIALOG_TITLE',
              },
              messageLabelId: {
                id: 'survey_discard',
                prefixId: 'DIALOG_DESCRIPTION',
              },
              decisionLabelId: {
                id: 'send',
              },
            }}
            onDecision={handleDiscard}
            onCancel={() => {
              setShowDiscard(false);
            }}
          />
          <div className="SurveyOutputDialog">
            <OutputDialog
              type={'supplierBase'}
              isOpen={isShowOutput}
              onDecision={(v) => {
                handleDownload(v);
              }}
              onCancel={() => {
                setShowOutput(false);
              }}
            />
          </div>
          <DeadlineChangeDialog
            isOpen={isShowDeadlineChange}
            outputOption={deadlineChangeOption}
            messageOption={{
              headerLabelId: {
                id: 'deadline_change',
                prefixId: 'DIALOG_TITLE',
              },
              messageLabelId: {
                id: 'deadline_change',
                prefixId: 'DIALOG_DESCRIPTION',
              },
            }}
            onDecision={handleDeadlineChange}
            onCancel={() => {
              setShowDeadlineChange(false);
            }}
          />
          <ApprovalRejectionDialog
            isOpen={isShowApprovalRejectionDialog}
            inputOption={approvalRejectionInputOption}
            onDecision={(v) => {
              handleApproveRejection(v);
            }}
            onCancel={() => setShowApprovalRejectionDialog(false)}
            messageOption={approvalRejectionDialogMessageOption}
          />
        </div>
        {/* 確認ダイアログ */}
        <ConfirmationDialog
          isOpen={isOpenConfirmDialog}
          viewMessage={confirmMessage}
          onDecision={handleConfirmed}
          onCancel={handleCancel}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
};

const dueDateFormatter = (opts: FormatterOptions, name: string): Formatter => {
  return (value: object): string => {
    const min = value[
      'minReplyDueDateDt.timestamp' as keyof typeof value
    ] as object;
    const max = value[
      'maxReplyDueDateDt.timestamp' as keyof typeof value
    ] as object;
    return min === max ? dayFormatter(opts, name)(value) : '-';
  };
};
const getDueDateFormatterSchema = (
  sch: Schema,
  targetColumn: string[],
  opts: FormatterOptions
) => {
  return sch.map((v) => {
    const setName = v.name.replace('.timestamp', '');
    if (targetColumn.includes(setName) && opts!) {
      return {
        ...v,
        formatter: dueDateFormatter(opts, setName),
      };
    } else {
      return v;
    }
  });
};
