import { useRef, useState } from 'react';
import { Container } from '~/shared/components';
import { FileUploader, FileItem } from '~/shared/components/file';
import { Viewer } from '~/shared/components/ui';
import { mtechnavi } from '~/shared/libs/clientsdk';
import Long from 'long';

export function File() {
  const assetIdRef = useRef<HTMLInputElement>(null);
  const assetDataIdRef = useRef<HTMLInputElement>(null);
  const displayNameRef = useRef<HTMLInputElement>(null);
  const createdAtRef = useRef<HTMLInputElement>(null);
  const updatedAtRef = useRef<HTMLInputElement>(null);
  const sourceUrlRef = useRef<HTMLInputElement>(null);

  const handleEdit = (v: mtechnavi.api.assetinventory.IAsset) => {
    assetIdRef.current!.value = v.assetId!;
    assetDataIdRef.current!.value = v.assetDataId!;
    displayNameRef.current!.value = v.displayName!;
    createdAtRef.current!.value = v.createdAt!.toString(10);
    updatedAtRef.current!.value = v.updatedAt!.toString(10);
  };

  const [items, setItems] = useState<mtechnavi.api.assetinventory.IAsset[]>([]);
  const handleList = () => {
    (async () => {
      const res = await window.App.services.assetInventory.listAssets({});
      setItems(res.items);
    })();
  };
  const handleCreate = () => {
    (async () => {
      await window.App.services.assetInventory.createAsset({
        asset: {
          displayName: displayNameRef.current!.value,
        },
        sourceUrl: sourceUrlRef.current!.value,
      });
    })();
  };
  const handleUpdate = () => {
    (async () => {
      await window.App.services.assetInventory.updateAsset({
        asset: {
          assetId: assetIdRef.current!.value,
          assetDataId: assetDataIdRef.current!.value,
          displayName: displayNameRef.current!.value,
          createdAt: Long.fromString(createdAtRef.current!.value),
          updatedAt: Long.fromString(updatedAtRef.current!.value),
        },
        sourceUrl: sourceUrlRef.current!.value,
      });
    })();
  };
  const handleDelete = () => {
    (async () => {
      await window.App.services.assetInventory.deleteAsset({
        asset: {
          assetId: assetIdRef.current!.value,
          assetDataId: assetDataIdRef.current!.value,
          displayName: displayNameRef.current!.value,
          createdAt: Long.fromString(createdAtRef.current!.value),
          updatedAt: Long.fromString(updatedAtRef.current!.value),
        },
      });
    })();
  };
  const handleUpload = (v: FileItem[]) => {
    console.info(v);
    if (!v.length) {
      return;
    }
    sourceUrlRef.current!.value = v[0].url!;
  };
  const [previewAsset, setPreviewAsset] = useState({
    assetId: '',
    fileName: '',
  });
  const handlePreview = (v: mtechnavi.api.assetinventory.IAsset) => {
    setPreviewAsset({
      assetId: v.assetId ?? '',
      fileName: v.displayName ?? '',
    });
  };
  const handleDebug1 = () => {
    fetch('/mtechnavi.api.idp.Identity/ListRoles', {
      method: 'POST',
      body: JSON.stringify({}),
    });
  };
  const handleDebug2 = () => {
    fetch('/mtechnavi.api.idp.Identity/GetRole', {
      method: 'POST',
      body: JSON.stringify({
        roleName: 'tenantAdmin',
      }),
    });
  };
  const [selectNumberList, setSelectNumberList] = useState<string[]>([]);
  const handlePreviewSelect = (v: mtechnavi.api.assetinventory.IAsset) => {
    items.forEach((item) => {
      if (
        v.assetId &&
        item.assetId === v.assetId &&
        !selectNumberList.includes(item.assetId!)
      ) {
        setSelectNumberList([...selectNumberList, item.assetId!]);
      } else if (
        v.assetId &&
        item.assetId === v.assetId &&
        selectNumberList.includes(item.assetId!)
      ) {
        setSelectNumberList(selectNumberList.filter((n) => n !== v.assetId));
      }
    });
  };
  const changeCanvas = (
    v: mtechnavi.api.assetinventory.IAsset,
    evt_: React.MouseEvent
  ) => {
    handlePreview(v);
    const evt = JSON.parse(JSON.stringify(evt_.nativeEvent));
    evt.clientX = 0;
    evt.clientY = 0;
  };
  return (
    <Container>
      <div>
        ファイル編集
        <button type="button" onClick={handleList}>
          List
        </button>
        <button type="button" onClick={handleCreate}>
          Create
        </button>
        <button type="button" onClick={handleUpdate}>
          Update
        </button>
        <button type="button" onClick={handleDelete}>
          Delete
        </button>
        <button type="button" onClick={handleDebug1}>
          ListRoles
        </button>
        <button type="button" onClick={handleDebug2}>
          GetRole
        </button>
        <fieldset>
          <legend>ファイル情報</legend>
          <label>
            assetId: <input type="text" disabled ref={assetIdRef} />
          </label>
          <br />
          <label>
            assetDataId: <input type="text" disabled ref={assetDataIdRef} />
          </label>
          <br />
          <label>
            sourceUrl: <input type="text" disabled ref={sourceUrlRef} />
          </label>
          <br />
          <label>
            displayName: <input type="text" ref={displayNameRef} />
          </label>
          <br />
          <label>
            createdAt: <input type="text" disabled ref={createdAtRef} />
          </label>
          <br />
          <label>
            updatedAt: <input type="text" disabled ref={updatedAtRef} />
          </label>
          <br />
        </fieldset>
      </div>
      <div>
        {items.map(
          (v) =>
            selectNumberList.includes(v.assetId!) && (
              <div key={v.assetId} onClick={(evt) => changeCanvas(v, evt)}>
                {v.displayName}
              </div>
            )
        )}
      </div>
      <FileUploader
        name="fileUploader"
        multiple={false}
        onUpload={handleUpload}
      />

      <hr />
      <Viewer
        assetId={previewAsset.assetId}
        fileName={previewAsset.fileName}
        controls={{
          downloading: true,
          pager: true,
          scaling: true,
        }}
        width="100%"
        height="100%"
      />
      <hr />
      <div>
        <div>
          {items.map((v) => (
            <div
              style={{ overflow: 'scroll', display: 'flex' }}
              key={v.assetId}
            >
              <button type="button" onClick={() => handleEdit(v)}>
                Edit
              </button>
              <button type="button" onClick={() => handlePreview(v)}>
                Preview
              </button>
              <input
                type="checkbox"
                name=""
                onChange={() => {
                  handlePreviewSelect(v);
                }}
              />
              <pre style={{ marginLeft: '1em' }}>
                {JSON.stringify(v, null, 2)}
              </pre>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}
