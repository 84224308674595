import { useIntl } from 'react-intl';
import { GetMessageWithIntl, MessageProps } from '../../parts/Message/Message';
import { MessageDialog } from './MessageDialog';

interface ProcessingDialogProps {
  isOpen: boolean;
  processName: MessageProps;
  onClose?: () => void;
}

/**
 * 処理中ダイアログ
 *
 * MessageDialog を特定の文言に限定したラッパー
 */
export const ProcessingDialog = ({
  isOpen,
  processName,
  onClose,
}: ProcessingDialogProps) => {
  const intl = useIntl();

  return (
    <>
      <MessageDialog
        isOpen={isOpen}
        messageOption={{
          headerLabelId: {
            prefixId: 'DIALOG_TITLE',
            id: 'processingDialog',
          },
          messageContents: (
            <>
              <h4>{GetMessageWithIntl(intl, processName)}</h4>
              <ul>
                <li>
                  {GetMessageWithIntl(intl, {
                    prefixId: 'processingDialog',
                    id: 'itMightNotBeCorrect',
                  })}
                </li>
                <li>
                  {GetMessageWithIntl(intl, {
                    prefixId: 'processingDialog',
                    id: 'pleaseWait',
                  })}
                </li>
              </ul>
            </>
          ),
        }}
        onClose={() => onClose && onClose()}
      />
    </>
  );
};
