import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  AttachmentItems,
  autoBulkDownload,
  autoDownloadFileOnlyName,
  convertDate,
  convertDatetime,
  handleCommonFIleUpload,
  includeInputValidateError,
} from '~/shared/utils';
import { GetMessage, GetMessageWithIntl } from '../../parts/Message/Message';
import { CaptionButton } from '../Button';
import { ModalDialogComponent, ModalDialogComponentProps, error } from '../..';
import { NavigationIconMenu, PageNavigation } from '../PageNavigation';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { Textbox } from '../Textbox';
import { Textarea } from '../Textarea';
import { SimpleListView } from '../SimpleListView';
import { DateSuggest } from '../Dates';
import { FileUploadAppendDialog } from './FileUploadAppendDialog';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import './PublicDocumentRegistrationDialog.css';
import { useConfirmation } from '~/shared/hooks';

const DIALOG_TITLE = 'publicDocumentRegistrationDialog';

export type PublicDocumentRegistrationDialogInputType =
  | 'add'
  | 'edit'
  | 'display';

export type PublicDocument = Pick<
  mtechnavi.api.company.IBusinessUnitPublicDocument,
  | 'statusSystemName'
  | 'displayName'
  | 'contents'
  | 'attachments'
  | 'releaseStartDt'
  | 'releaseEndDt'
  | 'updatedProperties'
>;

interface PublicDocumentRegistrationDialogInputOption {
  inputType: PublicDocumentRegistrationDialogInputType;
  publicDocument: PublicDocument | null;
}

export interface PublicDocumentRegistrationDialogOutputOption {
  publicDocument: PublicDocument;
  deleteFlag: boolean;
}

export interface PublicDocumentRegistrationDialogProps {
  isOpen: boolean;
  inputOption: PublicDocumentRegistrationDialogInputOption;
  onDecision: (result: PublicDocumentRegistrationDialogOutputOption) => void;
  onCancel: () => void;
}

export const PublicDocumentRegistrationDialog = (
  props: PublicDocumentRegistrationDialogProps
) => {
  const intl = useIntl();
  const { isOpen, inputOption } = props;
  const { inputType } = inputOption;
  const { publicDocument } = inputOption;
  const [disabledInput, setDisabledInput] = useState(false);
  const deleteFlag = useRef(false);
  const [displayName, setDisplayName] = useState('');
  const [contents, setContents] = useState('');
  const [attachments, setAttachments] = useState<AttachmentItems[]>([]);
  const [releaseStartDt, setReleaseStartDt] = useState<Date | null>();
  const [releaseEndDt, setReleaseEndDt] = useState<Date | null>();
  const requiredAreaAccount = useRef(null);
  const [isOpenFileUploadDialog, setOpenFileUploadDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { showLoading, hideLoading } = useLoading();
  // 確認ダイアログ
  // 公開資料情報保存メッセージ定義
  const saveMessageWithMessage = {
    id: 'C0000003',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'save' }),
    },
  };
  const saveMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'save' }),
    },
  };
  // 添付ファイル削除メッセージ定義
  const attachmentDeleteMessage = {
    id: 'confirmationDialogMessageDelete',
    prefixId: DIALOG_TITLE,
  };
  // 添付ファイル削除メッセージ定義
  const attachmentDeleteAllMessage = {
    id: 'confirmationDialogMessageDeleteAll',
    prefixId: DIALOG_TITLE,
  };
  const discardMessage = { id: 'discardMessage' };

  // 確認ダイアログ
  const { confirmation, confirmationElement } = useConfirmation();

  const setValues = (publicDocument: PublicDocument | null) => {
    setDisplayName(publicDocument?.displayName || '');
    setContents(publicDocument?.contents || '');
    setAttachments((publicDocument?.attachments || []) as AttachmentItems[]);
    setReleaseStartDt(convertDate(publicDocument?.releaseStartDt || null));
    setReleaseEndDt(convertDate(publicDocument?.releaseEndDt || null));
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
      return;
    }
    if (inputType === 'display') {
      setDisabledInput(true);
    }
    if (inputType === 'add') {
      setDisabledInput(false);
    } else {
      setDisabledInput(true);
      setValues(publicDocument);
    }
    // isOpen, inputType変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, inputType]);

  const isInputError = (): boolean => {
    if (releaseStartDt && releaseEndDt && releaseStartDt > releaseEndDt) {
      error([GetMessageWithIntl(intl, { id: 'E0000168' })]);
      return true;
    }
    const inputValidationCheckList = [
      {
        value: displayName || '',
        ref: requiredAreaAccount,
      },
      {
        value: releaseStartDt?.toString() || '',
        ref: requiredAreaAccount,
      },
      {
        value: releaseEndDt?.toString() || '',
        ref: requiredAreaAccount,
      },
    ];

    const targetElm = document.querySelector(
      '.public-document-registration-dialog'
    );
    if (includeInputValidateError(targetElm, intl, inputValidationCheckList)) {
      return true;
    }
    return false;
  };

  const reset = () => {
    setDisplayName('');
    setContents('');
    setAttachments([]);
    setReleaseStartDt(null);
    setReleaseEndDt(null);
    deleteFlag.current = false;
  };

  // ヘッダアイコン設定
  const getPageNavigationIconItems = (): NavigationIconMenu[] => {
    const iconItems: NavigationIconMenu[] = [];

    if (inputType === 'display' && disabledInput) {
      // 編集
      iconItems.push({
        name: 'edit',
        displayName: '編集',
        func: () => {
          setDisabledInput(false);
          setValues(publicDocument);
        },
      });
      // 削除
      iconItems.push({
        name: 'delete',
        displayName: '削除',
        func: () => {
          deleteFlag.current = true;
          handleDecision();
        },
      });
    }

    return iconItems;
  };

  const handleDecision = () => {
    props.onDecision({
      deleteFlag: deleteFlag.current,
      publicDocument: {
        displayName,
        contents,
        attachments,
        releaseStartDt: convertDatetime(releaseStartDt || null, 'YYYY/MM/DD'),
        releaseEndDt: convertDatetime(releaseEndDt || null, 'YYYY/MM/DD'),
      },
    });
  };

  // 添付ファイル削除
  const handleDeleteAttachment = async (deleteAttachmentId: string) => {
    if (!(await confirmation(attachmentDeleteMessage))) {
      return;
    }
    setAttachments(attachments.filter((v) => v.assetId !== deleteAttachmentId));
  };

  // 添付ファイル全件削除
  const handleDeleteAllAttachments = async () => {
    if (!(await confirmation(attachmentDeleteAllMessage))) {
      return;
    }
    setAttachments([]);
  };

  const handleDiscard = async () => {
    if (!(await confirmation(discardMessage))) {
      return;
    }
    setDisabledInput(true);
    setValues(publicDocument);
  };

  useEffect(() => {
    isLoading ? showLoading() : hideLoading();
    // isLoading変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const view = (
    <div className="public-document-registration-dialog dialog-with-description">
      <div className="header-navigation">
        <PageNavigation
          backpagePath="/"
          iconItems={getPageNavigationIconItems()}
          pageInfo={{
            isVisibleMoveNavi: false,
            isUnvisibleBackNavi:
              disabledInput || inputType === 'add' ? true : false,
          }}
          infoOption={{
            lastUpdateInfo: {
              isVisibleUpdateInfo: true,
              attribute:
                inputOption.publicDocument?.updatedProperties?.updatedBy
                  ?.email || '',
              attributeAt:
                inputOption.publicDocument?.updatedProperties?.updatedAt ||
                undefined,
              content:
                inputOption.publicDocument?.updatedProperties?.updatedBy
                  ?.displayName || '',
            },
            issuerInfo: { isVisibleIssuerInfo: false },
          }}
          handleBackPage={handleDiscard}
        />
      </div>
      <div className="detail-area">
        <div className="comment-area">
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-50">
                <Textbox
                  name="displayName"
                  labelId={`${DIALOG_TITLE}.displayName`}
                  columns={['displayName']}
                  type="text"
                  value={displayName}
                  onChangeState={setDisplayName}
                  validateOption={{ required: true }}
                  disabled={disabledInput}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-100">
                <Textarea
                  name="contents"
                  labelId={`${DIALOG_TITLE}.contents`}
                  className="w-100 mh-middle"
                  columns={['contents']}
                  value={contents}
                  onChangeState={setContents}
                  disabled={disabledInput}
                />
              </div>
            </div>
          </div>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-50">
                <SimpleListView
                  data={attachments}
                  actionOptions={{
                    onDelete: (item: AttachmentItems) => {
                      handleDeleteAttachment(item.assetId || '');
                    },
                    onDeleteAll: () => {
                      confirmation(attachmentDeleteAllMessage);
                      handleDeleteAllAttachments();
                    },
                    onRowClick: (item: AttachmentItems) => {
                      autoDownloadFileOnlyName(
                        item.filename ?? '',
                        item.assetId ?? ''
                      );
                    },
                    onFullDownLoad: () => {
                      const fileName = GetMessageWithIntl(intl, {
                        id: disabledInput ? 'confirmation' : 'registration',
                        prefixId: 'publicDocumentDialogDownloadFileName',
                      });
                      autoBulkDownload(
                        attachments.map((item) => item.assetId ?? ''),
                        intl,
                        'BUM_BUSINESS_UNIT_PUBLIC_DOCUMENTS_LIST',
                        fileName
                      );
                    },
                  }}
                  viewOptions={{
                    previewRowCount: 5,
                    columns: [
                      {
                        propertyName: 'filename',
                        header: {
                          id: 'simpleListView.header',
                          prefixId: DIALOG_TITLE,
                        },
                      },
                    ],
                    readonly: disabledInput,
                  }}
                />
              </div>
              {!disabledInput && (
                <div className="w-20">
                  <CaptionButton
                    buttonType="basic"
                    caption="ファイル追加"
                    name=""
                    onClick={() => {
                      if (attachments && attachments.length >= 20) {
                        error([
                          GetMessageWithIntl(intl, {
                            id: 'E0000077',
                            value: { $1: 20 },
                          }),
                        ]);
                        return;
                      }
                      setOpenFileUploadDialog(true);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <p className="label-autoPublicSetting">
            {GetMessage({ id: 'autoPublicSetting', prefixId: DIALOG_TITLE })}
          </p>
          <div className="input-line">
            <div className="item-group-100">
              <div className="w-25">
                <DateSuggest
                  name="releaseStartDt"
                  labelId={`${DIALOG_TITLE}.releaseStartDt`}
                  value={releaseStartDt}
                  columns={['releaseStartDt']}
                  onChangeState={setReleaseStartDt}
                  validateOption={{ required: true }}
                  disabled={disabledInput}
                />
              </div>
              <div className="w-25">
                <DateSuggest
                  name="releaseEndDt"
                  labelId={`${DIALOG_TITLE}.releaseEndDt`}
                  value={releaseEndDt}
                  columns={['releaseEndDt']}
                  onChangeState={setReleaseEndDt}
                  validateOption={{ required: true }}
                  disabled={disabledInput}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessage({ id: 'cancel' })}
            onClick={() => {
              props.onCancel();
              reset();
            }}
          />
          {!disabledInput && (
            <CaptionButton
              name="sendBtn"
              buttonType="basic"
              className="button"
              caption={GetMessage({
                id: 'save',
              })}
              onClick={async () => {
                if (isInputError()) {
                  return;
                }
                const message =
                  inputOption.publicDocument?.statusSystemName === 'B01'
                    ? saveMessageWithMessage
                    : saveMessage;
                if (!(await confirmation(message))) {
                  return;
                } else {
                  handleDecision();
                }
              }}
            />
          )}
        </div>
      </div>
      {/* ファイル追加ダイアログ */}
      <FileUploadAppendDialog
        isOpen={isOpenFileUploadDialog}
        inputOption={{
          fineNames: attachments.map((v) => {
            return v.filename;
          }),
        }}
        messageOption={{
          headerLabelId: {
            id: 'file_add',
            prefixId: 'DIALOG_TITLE',
          },
        }}
        fileUploadOption={{
          multiple: true,
          validateOption: {
            maxFileSizeInMebis: 50,
            maxFileCount: 20,
          },
        }}
        onCancel={() => {
          setOpenFileUploadDialog(false);
        }}
        onDecision={async (result) => {
          if (attachments && attachments.length + result.files.length > 20) {
            error([
              GetMessageWithIntl(intl, {
                id: 'E0000077',
                value: { $1: 20 },
              }),
            ]);
            return;
          }
          const attachmentFiles = await handleCommonFIleUpload(
            // 重複データがある場合、上書きするため元のデータから削除する
            attachments.filter(
              (v) => !result.files.some((i) => i.file.name === v.filename)
            ),
            result,
            'B05',
            intl
          );
          setAttachments(attachmentFiles);
          setOpenFileUploadDialog(false);
        }}
        onChangeLoadingState={setLoading}
      />
      {confirmationElement}
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      reset();
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: {
      prefixId: 'DIALOG_TITLE',
      id: disabledInput
        ? 'public_document_confirmation'
        : 'public_document_registration',
    },
    messageLabelId: {},
    elements: view,
  };

  return (
    <div className="PublicDocumentRegistrationDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
