import { useCallback, useMemo } from 'react';
import { PresetItem } from '~/shared/services';
import { FilterViewQuery } from './index';
import {
  FilterInputAPIFilterboxMultiple,
  FilterInputFilterboxMultiple,
  FilterInputTextbox,
  FilterInputFromToDate,
  FilterInputFromToNumber,
  FilterInputFilterboxBoolean,
  FilterInputFilterboxCustom,
} from './FilterInputItems';
import { getPresetPropertyValue } from './preset';
import { FilterTerm } from '~/worker';
import { ViewId } from '~/shared/utils';

interface FilterInputViewProps {
  viewId?: ViewId;
  column: string;
  presetItem?: PresetItem;
  filterValue?: FilterViewQuery;
  onChangeFilter?: (arg: FilterViewQuery) => void;
}

export function FilterInputView(props: FilterInputViewProps) {
  const {
    viewId,
    column,
    presetItem,
    filterValue = {
      filterTerms: {},
    } as FilterViewQuery,
    onChangeFilter,
  } = props;

  const type = useMemo<string>(() => {
    return getPresetPropertyValue(presetItem?.property, column, 'type');
  }, [presetItem, column]);

  const labelId = useMemo(() => {
    if (!viewId) return '';
    return `${viewId}.${column}`;
  }, [column, viewId]);

  const onChangeFilterInputValue = useCallback(
    (terms: FilterTerm[]) => {
      const newFilterValue = { ...filterValue.filterTerms };
      newFilterValue[column] = terms;
      onChangeFilter &&
        onChangeFilter({ ...filterValue, filterTerms: newFilterValue });
    },
    [column, onChangeFilter, filterValue]
  );

  // タイプに応じて内容を表示
  switch (type) {
    case 'text':
      return (
        <FilterInputTextbox
          labelId={labelId}
          column={column}
          presetItem={presetItem}
          filterValue={filterValue}
          onChangeFilter={onChangeFilterInputValue}
        />
      );
    case 'fromtodate':
      return (
        <FilterInputFromToDate
          labelId={labelId}
          column={column}
          presetItem={presetItem}
          filterValue={filterValue}
          onChangeFilter={onChangeFilterInputValue}
        />
      );
    case 'fromtonumber':
      return (
        <FilterInputFromToNumber
          labelId={labelId}
          column={column}
          presetItem={presetItem}
          filterValue={filterValue}
          onChangeFilter={onChangeFilterInputValue}
        />
      );
    case 'filterboxBoolean': {
      return (
        <FilterInputFilterboxBoolean
          labelId={labelId}
          column={column}
          presetItem={presetItem}
          filterValue={filterValue}
          onChangeFilter={onChangeFilterInputValue}
        />
      );
    }
    case 'filterboxMultiple': {
      return (
        <FilterInputFilterboxMultiple
          labelId={labelId}
          column={column}
          presetItem={presetItem}
          filterValue={filterValue}
          onChangeFilter={onChangeFilterInputValue}
        />
      );
    }
    case 'apiFilterboxMultiple': {
      return (
        <FilterInputAPIFilterboxMultiple
          labelId={labelId}
          column={column}
          presetItem={presetItem}
          filterValue={filterValue}
          onChangeFilter={onChangeFilterInputValue}
        />
      );
    }
    case 'filterboxCustom': {
      return (
        <FilterInputFilterboxCustom
          labelId={labelId}
          column={column}
          presetItem={presetItem}
          filterValue={filterValue}
          onChangeFilter={onChangeFilterInputValue}
        />
      );
    }
    default:
      return <></>;
  }
}
