import { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components/ui/ModalDialog/ModalDialog';
import { error } from '~/shared/components/parts/Toast/Toast';
import { CaptionButton } from '~/shared/components/parts/Button/CaptionButton';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ExtendFileUploader,
  ExtendFileUploaderRef,
  FileItem,
  FileUploaderValidateOption,
  ViewFileItem,
  fileStatusToViewFileStatus,
} from '~/shared/components/file';
import './base.css';
import './FileUploadDialog.css';
import { SimpleListView } from '../SimpleListView';
import { getExceptionMessage } from '~/shared/utils';
import { useConfirmation } from '~/shared/hooks';

export interface FileUploadAppendDialogMessageOption {
  headerLabelId: MessageProps;
  captionLabelId?: MessageProps;
  decisionLabelId?: MessageProps;
}

export interface FileUploadAppendDialogUploadOption {
  multiple?: boolean;
  validateOption?: FileUploaderValidateOption;
  isDnd?: boolean;
}

export interface FileUploadAppendDialogResult {
  files: FileItem[];
}

export interface FileUploadAppendDialogInputOption {
  fineNames: string[];
}

export interface FileUploadAppendDialogProps {
  isOpen: boolean;
  messageOption: FileUploadAppendDialogMessageOption;
  fileUploadOption: FileUploadAppendDialogUploadOption;
  inputOption: FileUploadAppendDialogInputOption;
  onDecision: (result: FileUploadAppendDialogResult) => Promise<void>;
  onCancel: () => void;
  onChangeLoadingState?: (isLoading: boolean) => void;
}

export const FileUploadAppendDialog = ({
  isOpen,
  messageOption,
  fileUploadOption,
  inputOption,
  onDecision,
  onCancel,
  onChangeLoadingState,
}: FileUploadAppendDialogProps) => {
  const intl = useIntl();
  const uploaderRef = useRef<ExtendFileUploaderRef>(null);
  const [tempFileList, setTempFileList] = useState<FileItem[]>([]);
  const [fileList, setFileList] = useState<FileItem[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [viewFileItems, setViewFileItems] = useState<ViewFileItem[]>([]);
  const [isErrorContain, setErrorContain] = useState(false);
  const [isClickable, setClickable] = useState(false);
  // 確認ダイアログ
  const { confirmation, confirmationElement } = useConfirmation();

  const confirmationMessage: MessageProps = {
    prefixId: 'fileUploadAppendDialog',
    id: 'overWriteConfirmationMessage',
  };

  // ダイアログのリセット
  useEffect(() => {
    setTempFileList([]);
    setFileList([]);
    uploaderRef.current?.clear();
  }, [isOpen]);

  const handleDecision = async () => {
    setClickable(false);
    if (isErrorContain) {
      error([GetMessageWithIntl(intl, { id: 'E0000081' })]);
      return;
    }
    try {
      await onDecision({
        files: [...fileList],
      });
    } catch (err) {
      error(getExceptionMessage(intl, err));
      throw err;
    } finally {
      setClickable(true);
    }
  };

  const isDuplicated = async () => {
    if (
      fileList.some((v) =>
        inputOption.fineNames.some((fileName) => fileName === v.file.name)
      )
    ) {
      if (await confirmation(confirmationMessage)) {
        handleDecision();
      }
    } else {
      handleDecision();
    }
  };

  const handleUploaded = (fileList: FileItem[]) => {
    setErrorContain(fileList.some((item) => item.status !== 'OK'));
    setTempFileList(fileList);
  };

  useEffect(() => {
    setClickable(true);
    setViewFileItems(
      fileList
        .sort((a, b) => {
          // uploading > failure > OK の順でソートする
          return b.status.charCodeAt(0) - a.status.charCodeAt(0);
        })
        .map(
          (item): ViewFileItem => ({
            key: item.file.name,
            status: fileStatusToViewFileStatus(item.status),
            filename: item.file.name,
            file: item.file,
            url: item.url,
          })
        )
    );
  }, [fileList]);

  useEffect(() => {
    setFileList((prev) => {
      const tmp = prev.filter(
        (v) => !tempFileList.some((i) => i.file.name === v.file.name)
      );
      return [...tmp, ...tempFileList];
    });
  }, [tempFileList]);

  const handleRemove = (item: ViewFileItem) => {
    setFileList(fileList.filter((v) => v.file !== item.file));
  };

  const elements = (
    <div className="file-upload-dialog">
      {messageOption.captionLabelId && (
        <p className="caption">
          {GetMessageWithIntl(intl, messageOption.captionLabelId)}
        </p>
      )}
      <ExtendFileUploader
        name="uploader"
        multiple={fileUploadOption.multiple}
        ref={uploaderRef}
        dndOption={{
          enabled: fileUploadOption.isDnd !== false,
        }}
        resultOption={{
          previewRowCount: 0,
          omitFooter: true,
        }}
        validateOption={fileUploadOption.validateOption}
        onChangeLoadingState={(v) => {
          onChangeLoadingState && onChangeLoadingState(v);
          setLoading(v);
        }}
        onUpload={handleUploaded}
      />
      <SimpleListView
        data={viewFileItems}
        viewOptions={{
          readonly: fileList.some((file) => file.status === 'uploading'),
          omitHeader: true,
          keyColumn: 'key',
          previewRowCount: 4,
          columns: [
            {
              propertyName: 'status',
              width: '5.5rem',
            },
            {
              propertyName: 'filename',
            },
          ],
        }}
        actionOptions={{
          onDelete: handleRemove,
        }}
      />
      <div className="button-area">
        <CaptionButton
          name="cancelBtn"
          buttonType="cancel"
          className="button"
          caption={GetMessageWithIntl(intl, { id: 'cancel' })}
          onClick={() => {
            onCancel();
          }}
        />
        <CaptionButton
          name="sendBtn"
          buttonType="basic"
          className="button"
          disabled={isLoading || fileList.length <= 0 || !isClickable}
          caption={GetMessageWithIntl(
            intl,
            messageOption.decisionLabelId
              ? messageOption.decisionLabelId
              : { id: 'decision' }
          )}
          onClick={isDuplicated}
        />
      </div>
      {confirmationElement}
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      onCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId: messageOption.headerLabelId,
    messageLabelId: {},
    elements,
  };

  return (
    <div className="FileUploadDialog">
      <ModalDialogComponent {...openModalProps} />
    </div>
  );
};
