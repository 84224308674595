import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessageWithIntl,
  LoadingIcon,
  Toast,
  error,
  success,
} from '~/shared/components';
import {
  ConfirmationDialog,
  ListView,
  ViewMenu,
  MenuActionItem,
  Preset,
  Property,
  getAltDisplaySchema,
  getBooleanDataFormetterSchema,
  getCommaTypeNumberSchema,
  getDayFormetterDisplaySchema,
} from '~/shared/components/ui';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { PresetItem } from '~/shared/services';
import {
  FullMethodName_ListEstimateResultSummarys,
  PageState,
  ViewId,
  getExceptionMessage,
  getPresetAndSchema,
  getWorkerExceptionMessage,
  saveLocalStorageCheckboxData,
} from '~/shared/utils';

const VIEW_ID: ViewId = 'EST_ESTIMATE_RESULT_LIST';
type EstimateResultSumaarys = mtechnavi.api.estimation.EstimateResultSummary;
export function EstEstimateResultList() {
  const intl = useIntl();
  const navigate = useNavigate();
  const myEmail = useAuth().user?.email ?? '';
  const [presetViewId, setPresetViewId] = useState<ViewId>();
  const [schema, setSchema] = useState<Property[]>([]);
  const [preset, setPreset] = useState<Preset>({
    filter: {},
    propertyNames: [],
  });
  const [presetItems, setPresetItems] = useState<PresetItem[]>();
  const [isReload, setReload] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const listItemRef = useRef<EstimateResultSumaarys[]>([]);
  // 操作対象ID
  const [targetId, setTargetId] = useState<string | null>(null);

  // ダイアログ
  const [isShowComplete, setShowComplete] = useState(false);

  const successMessage = GetMessageWithIntl(intl, { id: 'I0000001' });
  const unselectedMessage = GetMessageWithIntl(intl, { id: 'E0000023' });
  const excessTargetMessage = GetMessageWithIntl(intl, { id: 'E0000073' });
  const invalidStatusMessage = GetMessageWithIntl(intl, { id: 'E0000020' });

  const clearCheckBox = useCallback(() => {
    saveLocalStorageCheckboxData(VIEW_ID, [], myEmail);
  }, [myEmail]);

  const getRecordData = (
    id: string | null
  ): EstimateResultSumaarys | undefined => {
    return listItemRef?.current.find(
      (item) => id === item.estimateResultSummaryId
    );
  };

  const setMenuActionItem = (): MenuActionItem[] => {
    const menuActionItems: MenuActionItem[] = [];
    menuActionItems.push({
      menuActionType: 'listIconMenu',
      menu: listIconEvent(),
    });
    menuActionItems.push({
      menuActionType: 'footerMenu',
      menu: footerMenuEvent(),
    });
    return menuActionItems;
  };

  const listIconEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 確認
    menuItems.push({
      name: 'description',
      func: (v?: string[]) => {
        handleMoveToConfirmation(v);
      },
    });
    return menuItems;
  };

  const footerMenuEvent = (): ViewMenu[] => {
    const menuItems: ViewMenu[] = [];
    // 完了
    menuItems.push({
      name: 'complete',
      func: (v?: string[]) => {
        if (!v || v.length === 0) {
          error([unselectedMessage]);
          return;
        }
        if (v && v.length > 1) {
          error([excessTargetMessage]);
          return;
        }
        if (v) {
          const target = getRecordData(v[0]);
          if (['B06', 'B07'].includes(target?.status?.systemName ?? '')) {
            error([invalidStatusMessage]);
            return;
          }
          setTargetId(v[0]);
        }
        setShowComplete(true);
      },
    });
    return menuItems;
  };

  const handleMoveToConfirmation = (prop?: string[]) => {
    const state: PageState = {
      sourceViewId: VIEW_ID,
      ids: prop ?? [],
      baseViewOption: { sourceViewId: VIEW_ID },
    };
    navigate('/estimate/est-estimate-result-confirmation', { state });
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        // preset関係の情報を取得
        const { childrenPresetItem, schemas, preset } =
          await getPresetAndSchema(VIEW_ID, [
            FullMethodName_ListEstimateResultSummarys,
          ]);

        // ja表示のみする処理
        const jaColumn: string[] = [
          'status.displayNameLang',
          'requestingCompany.displayNameLang',
        ];
        const formatterSch = getAltDisplaySchema(schemas[0], jaColumn, 'ja');
        const accuracyDateColumn = [
          'estimateDeadlineDt',
          'desiredReceiveDt',
          'deliveryTimeDt',
        ];
        const accuracyDateSch = getDayFormetterDisplaySchema(
          formatterSch,
          accuracyDateColumn,
          {
            dayOpts: {
              formatType: 'YYYY/MM/DD',
              isAccuracy: true,
            },
          }
        );

        const commaSch = getCommaTypeNumberSchema(accuracyDateSch, [
          'amount.amountNum',
        ]);

        const booleanSch = getBooleanDataFormetterSchema(commaSch, [
          'adoption',
        ]);

        // 取得した情報をセット
        setPresetItems(childrenPresetItem);
        setSchema(booleanSch);
        setPreset(preset);
        setPresetViewId(VIEW_ID);
      } catch (err) {
        error(getExceptionMessage(intl, err));
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  }, [intl]);

  const handleComplete = async () => {
    const target = getRecordData(targetId);
    if (!target) {
      error([unselectedMessage]);
      return;
    }
    setLoading(true);
    setReload(false);
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'completeEstimateResult',
        request: target.estimateResultSummaryId,
      });

      setShowComplete(false);
      clearCheckBox();
      success([successMessage]);
      setReload(true);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="EstimateResultList">
          <ListView
            isReload={isReload}
            fullMethodName={FullMethodName_ListEstimateResultSummarys}
            pageInfo={{
              preset,
              schema,
              menuItem: setMenuActionItem(),
              menuTarget: 'estimateResultSummaryId',
              presetItems,
              headerTitle: { viewId: VIEW_ID },
              listSkipType: {
                isTotal: true,
                isOutput: true,
                isListActionMenu: true,
              },
            }}
            filterItemOption={{
              isRequestBodyFilter: true,
            }}
            stateOption={{
              onOriginalItemState: (items: unknown[]) =>
                (listItemRef.current = items as EstimateResultSumaarys[]),
            }}
          />

          <ConfirmationDialog
            isOpen={isShowComplete}
            viewMessage={{
              id: 'C0000001',
              value: {
                $1: GetMessageWithIntl(intl, {
                  id: 'complete',
                }),
              },
            }}
            onDecision={handleComplete}
            onCancel={() => setShowComplete(false)}
          />
        </div>
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
}
