import { FilterRequest } from 'worker/worker';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { FullMethodName_UncompletedListThreads } from '~/worker';
import { SectionStateProps } from '../ui/TaskListView';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PageState, movePage } from '~/shared/utils';
import { GetMessageWithIntl } from '../parts/Message/Message';

const BLUEPRINT_CONFIRMATION_URL = '/blueprint/blueprint-confirmation';

// 未完了作業の取得
const uncompletedListThreads =
  async (): Promise<mtechnavi.api.blueprint.UncompletedListThreadsResponse> => {
    const option: FilterRequest = {
      action: 'reload',
      fullMethodName: FullMethodName_UncompletedListThreads,
      filter: {},
      sort: [],
    };
    option.requestBody = {};
    try {
      return (await window.App.services.ui.worker.filter(
        option
      )) as mtechnavi.api.blueprint.UncompletedListThreadsResponse;
    } catch (err) {
      console.error('Error uncompletedListThreads', err);
      return (async () =>
        new mtechnavi.api.blueprint.UncompletedListThreadsResponse())();
    }
  };

export interface TaskBlueprintRequestProps extends SectionStateProps {}

export const TaskBlueprint = (props: TaskBlueprintRequestProps) => {
  const intl = useIntl();
  const navi = useNavigate();
  const { onChangeLoadingState } = props;

  const [targetUncompletedListThreadIds, setTargetUncompletedListThreadIds] =
    useState<string[]>([]);
  const [targetUncompletedRevision, setTargetUncompletedRevision] = useState<
    string[]
  >([]);

  // 遷移処理
  const handleMovePage = (
    e: React.MouseEvent,
    ids: string[],
    subIds: string[],
    url: string
  ) => {
    e.preventDefault();
    if (!ids.length) return;
    const state: PageState = {
      ids: ids,
      subIds: subIds,
    };
    movePage(state, navi, url);
  };

  // 未完了スレッド
  const callUncompletedThreadList = async () => {
    const { items } = await uncompletedListThreads();
    if (!items.length) {
      setTargetUncompletedListThreadIds([]);
      return;
    }
    setTargetUncompletedListThreadIds(
      items.map((item) => item.blueprintId ?? '')
    );
    setTargetUncompletedRevision(
      items.map((item) => item.revision?.toString() ?? '')
    );
  };

  useEffect(() => {
    onChangeLoadingState && onChangeLoadingState(true);
    Promise.all([
      callUncompletedThreadList(), // 未完了スレッド
    ]).finally(() => {
      onChangeLoadingState && onChangeLoadingState(false);
    });
    // 正確にローディング状況を伝えることが困難なため、依存関係に含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(targetUncompletedListThreadIds.length > 0 ||
        targetUncompletedListThreadIds.length > 0) && (
        <div className="TaskList">
          <dl>
            <dt className="title">
              {GetMessageWithIntl(intl, {
                id: 'TaskList.title.blueprintConfirmation',
              })}
            </dt>
            {targetUncompletedListThreadIds.length > 0 && (
              <dd>
                <a
                  href="#"
                  className="link"
                  onClick={(e) => {
                    handleMovePage(
                      e,
                      targetUncompletedListThreadIds,
                      targetUncompletedRevision,
                      BLUEPRINT_CONFIRMATION_URL
                    );
                  }}
                >
                  {GetMessageWithIntl(intl, {
                    id: 'TaskList.taskItem.uncompletedBlueprint',
                  })}{' '}
                  {`（${targetUncompletedListThreadIds.length}）`}
                </a>
              </dd>
            )}
          </dl>
        </div>
      )}
    </>
  );
};
