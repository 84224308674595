/**
 * page内で表示に関する処理を定義
 *
 * 変換処理のうち、主に画面表示を目的として文字列への不可逆な変換を行うもの
 */
import dayjs from 'dayjs';
import { mtechnavi, sharelib } from '../libs/clientsdk';
import Long from 'long';
import {
  Amounts,
  DisplayNameLang,
  checkEmptyObject,
  convertAmountToString,
} from '.';
import { InitAccuracyFormatStyle } from '../components/ui';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { IntlShape } from 'react-intl';
import { convertStringToNumber } from './converter/numeric';

export type FormatStyle =
  | 'YYYY年MM月'
  | 'YYYY/MM/DD HH:mm:ss'
  | 'YYYY/MM/DD'
  | 'YYYY/MM'
  | 'YYYY/MM/DD HH:mm';
// 日付を取得して変更するUtil
export function getDateFormat(
  dayInfo: Long.Long | string,
  formatStyle: FormatStyle
) {
  if (!dayInfo) return '';
  let num = 0;
  if (typeof dayInfo !== 'string') {
    // Long.LongをLong型に変換後にNumberにする
    num = Long.fromValue(dayInfo).toNumber();
  } else {
    num = Number(dayInfo);
  }
  const day = new Date(num / 1000);

  return dayjs(day).format(formatStyle);
}

// datetime型（API）のTimeZoneを考慮した日付を取得
export function getDateFormatWithTimezone(
  datetime: sharelib.IDatetime | null | undefined
): string {
  if (checkEmptyObject(datetime)) {
    return '';
  }
  const formatStyle = InitAccuracyFormatStyle.find(
    (v) => v.accuracy === datetime!.accuracy
  );
  const dayformatStyle = formatStyle?.formatStyle ?? 'YYYY/MM/DD';

  // time zoneの設定
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const date = dayjs
    .tz(Long.fromValue(datetime!.timestamp!).toNumber() / 1000)
    .tz(datetime!.timezoneName ?? 'Asia/Tokyo')
    .format(dayformatStyle);

  return date;
}
// Amount系の結合処理
export function mergeAmountNumber(item: Amounts) {
  if (!item?.integralAmount && !item?.fractionalAmount) {
    return '';
  }
  const integralAmount = item?.integralAmount
    ? Long.fromValue(item.integralAmount).toString()
    : '0';
  const fractionalAmount = item?.fractionalAmount
    ? `.${String(item.fractionalAmount).padStart(4, '0')}`
    : '';
  return Number(`${integralAmount}${fractionalAmount}`).toLocaleString(
    undefined,
    { maximumFractionDigits: 20 }
  );
}

// 文字列を3桁区切りの文字列に変換
export function toCommaTypeString(value: string | undefined | null) {
  if (!value) {
    return '';
  }

  const num = Number(value);

  if (Number.isNaN(num)) {
    return '';
  } else {
    return num.toLocaleString(undefined, {
      maximumFractionDigits: 20,
    });
  }
}

type Rounding = 'none' | 'RoundDown'; // 切り上げ、四捨五入が必要になったら追加
interface RoundingType {
  rounding: Rounding;
  digits: number;
}
// Amount系の結合処理(小数点指定、切り上げタイプなどの指定含む)
export function mergeAmountNumberWithRounding(
  item: Amounts,
  divideBy: number,
  roundingType: RoundingType
) {
  if (!item?.integralAmount && !item?.fractionalAmount) {
    return '';
  }
  const integralAmount = item?.integralAmount
    ? Long.fromValue(item.integralAmount).toString()
    : '0';
  const fractionalAmount = item?.fractionalAmount
    ? `.${String(item.fractionalAmount).padStart(roundingType.digits, '0')}`
    : '';
  let num = Number(`${integralAmount}${fractionalAmount}`);
  if (divideBy !== 0) {
    num = num / divideBy;
    if (roundingType.rounding === 'RoundDown') {
      num = floorNumber(num, roundingType.digits);
    }
  }
  return num.toLocaleString(undefined, { maximumFractionDigits: 20 });
}
// 切り捨て処理
function floorNumber(num: number, digits: number) {
  const pow = Math.pow(10, digits);
  const conditionNum = num < 0 ? -1 : 1;

  // マイナス数値の場合は、 -5.6 => -5 に切り捨てられるように調整
  return Math.floor(Math.abs(num) * pow) / pow / conditionNum;
}

// INameOption から現在の locale に基づく名称を取得する
export const nameOptionToLocaleString = <
  T extends { displayNameLang?: { [k: string]: string } | null }
>(
  intl: IntlShape,
  value?: T | null
): string => {
  return displayNameLangToLocaleString(intl, value?.displayNameLang);
};

export const displayNameLangToLocaleString = (
  intl: IntlShape,
  value?: DisplayNameLang | null
): string => {
  if (!value) {
    return '';
  }
  return Object.hasOwn(value, intl.locale) ? value[intl.locale] : '';
};

/**
 * QuantityAmount を単位を含む文字列に変換する
 */
export const toQuantityString = (
  intl: IntlShape,
  value:
    | sharelib.INullableQuantityAmount
    | sharelib.IQuantityAmount
    | undefined
    | null,
  isComma?: boolean
) => {
  const amount = convertAmountToString(value, isComma);
  const unit = nameOptionToLocaleString(intl, value?.unit);
  return `${amount}${unit}`;
};

/**
 * アドレスを結合した文字列に変換する
 * @parama address:mtechnavi.api.company.IAddress
 * @return 都道府県＋市区町村以下＋建物、号室
 */
export function joinAddress(address: mtechnavi.api.company.IAddress) {
  if (!address) return '';
  const ary: string[] = [];
  ary.push(address.region?.displayNameLang?.ja ?? '');
  if (address.addressLines && address.addressLines?.length > 0) {
    address.addressLines.map((v) => ary.push(v));
  }
  return ary.join('');
}

/* 年月をフォーマットする
 * @param date: Date
 * @return string: YYYY年MM月
 */
export function formatDate(
  date: Date | null | undefined,
  formatStyle: FormatStyle
) {
  if (!date) return '';
  return dayjs(date).format(formatStyle);
}

/**
 * 文字列の数値をカンマ付きの文字列数値に変換する
 * @param value: string
 * @return string: ###,###
 */
export function formatStringNumWithComma(
  value: string | null | undefined
): string {
  const num = convertStringToNumber(value);
  if (!num) {
    return '';
  }
  const commmaValue = num.toLocaleString();
  return commmaValue ? commmaValue : '';
}

/**
 * ユーザー名称にメールアドレスを付与する
 * @param userName: string
 * @param email: string
 * @return string: ユーザー名(メールアドレス)
 */
export function formatUserWIthEmail(
  userName: string | null | undefined,
  email: string | null | undefined
) {
  return userName && email ? `${userName}(${email})` : '';
}

/**
 * IDate型をフォーマットされた文字列日付に変換
 * @param 日付:sharelib.IDate
 * @param フォーマットスタイル:FormatStyle(日付まで)
 * @returns 日付:string
 */
export function formatIDateToString(
  value: sharelib.IDate | null | undefined,
  formatStyle: FormatStyle
) {
  if (value === null || value === undefined) {
    return '';
  }
  let invalidData = false;
  switch (formatStyle) {
    case 'YYYY/MM/DD':
      if (!value.year || !value.month || !value.day) {
        invalidData = true;
      }
      break;
    case 'YYYY/MM':
      if (!value.year || !value.month) {
        invalidData = true;
      }
  }
  if (invalidData) {
    return '';
  }

  const date = new Date();
  date.setFullYear(value.year ?? 0);
  date.setMonth((value.month ?? 1) - 1);
  date.setDate(value.day ?? 0);

  return isNaN(date.getDate()) ? '' : dayjs(date).format(formatStyle);
}

interface BoolStyleOption {
  trueSymbol?: string;
  falseSymbol?: string;
}
/** 値の真偽を文字で表現する */
export const formatBool = (
  value: unknown,
  styleOption?: BoolStyleOption
): string => {
  const style: Required<BoolStyleOption> = {
    trueSymbol: styleOption?.trueSymbol ?? '◯',
    falseSymbol: styleOption?.falseSymbol ?? '-',
  };
  return !!value ? style.trueSymbol : style.falseSymbol;
};
