import { useCallback, useEffect, useState } from 'react';
import { Radio } from '~/shared/components';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { GetMessage } from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '../ModalDialog/ModalDialog';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { sharelib } from '~/shared/libs/clientsdk';
import './base.css';
import { formatUserWIthEmail } from '~/shared/utils';

export interface SystemNotificationSettingDialogProps {
  isOpen: boolean;
  inputData?: sharelib.IUserReference[];
  onDecision: (result: string) => void;
  onCancel: () => void;
}

export type SystemNotificationSettingType =
  | 'setAsNotificationDestination'
  | 'unsetAsNotificationDestination';

interface UserItem {
  userName: string;
  email: string;
}

interface RadioItem {
  value: SystemNotificationSettingType;
  displayName: string;
}

export const SystemNotificationSettingDialog = (
  props: SystemNotificationSettingDialogProps
) => {
  const [radio, setRadio] = useState('');
  const radioItems: RadioItem[] = [
    {
      value: 'setAsNotificationDestination',
      displayName: GetMessage({
        id: 'radio_setAsNotificationDestination',
        prefixId: 'SystemNotificationSettingDialog',
      }),
    },
    {
      value: 'unsetAsNotificationDestination',
      displayName: GetMessage({
        id: 'radio_unsetAsNotificationDestination',
        prefixId: 'SystemNotificationSettingDialog',
      }),
    },
  ];
  const userName = useAuth().user?.displayName;
  const userEmail = useAuth().user?.email;

  const getNotificationUsers = useCallback((): UserItem[] => {
    if (props.inputData) {
      const itemRemovedDuplicated: UserItem[] = [];
      props.inputData.map((user) => {
        if (!itemRemovedDuplicated.some((v) => v.email === user.email)) {
          itemRemovedDuplicated.push({
            userName: user.displayName ?? '',
            email: user.email ?? '',
          });
        }
      });
      return itemRemovedDuplicated;
    } else {
      return [];
    }
  }, [props.inputData]);

  const [notificationUsers, setNotificationUsers] = useState<UserItem[]>([]);
  useEffect(() => {
    setNotificationUsers(getNotificationUsers);
  }, [props.inputData, getNotificationUsers]);

  useEffect(() => {
    if (props.isOpen) {
      const initialbvalue = notificationUsers.some((v) => v.email === userEmail)
        ? 'unsetAsNotificationDestination'
        : 'setAsNotificationDestination';
      setRadio(initialbvalue);
    }
    // ダイアログオープン時、システム通知先ユーザー変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen, notificationUsers]);

  const systemNotificationDestinationItems = () => {
    return (
      <div className="items">
        {notificationUsers.map(({ userName, email }, i) => (
          <p key={i} className="user-label">
            {formatUserWIthEmail(userName, email)}
          </p>
        ))}
      </div>
    );
  };

  const elements = (
    <div className="system-notification-dialog">
      <div className="contents-box">
        <div className="notification-destination-list-area">
          <p className="label">システム通知先</p>
          <div className="notification-destination-list">
            {systemNotificationDestinationItems()}
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <p className="label">
                {formatUserWIthEmail(userName, userEmail)}
              </p>
              <Radio
                name="radio setting"
                className="group"
                items={radioItems}
                value={radio}
                validateOption={{ required: true }}
                onChangeState={setRadio}
              ></Radio>
            </div>
          </div>
        </div>
        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessage({
              id: 'cancel',
            })}
            onClick={() => props.onCancel()}
          />
          <CaptionButton
            name="saveBtn"
            buttonType="basic"
            className="button"
            caption={GetMessage({
              id: 'save',
            })}
            onClick={() => {
              props.onDecision(radio);
            }}
          />
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      props.onCancel();
    },
    send: () => {},
    modalIsOpen: props.isOpen,
    headerLabelId: {
      id: 'system_notification_setting',
      prefixId: 'DIALOG_TITLE',
    },
    messageLabelId: {},
    elements,
  };

  return <ModalDialogComponent {...openModalProps} />;
};
