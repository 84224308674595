/** 図面として扱うファイル種別 */
export const BLUEPRINT_FILE_TYPES = ['pdf'];

/** 図面として許容される最大ファイルサイズ(Mib) */
export const BLUEPRINT_FILE_SIZE = 50;

/** 図面の利用サブシステム */
export const BlueprintUsableType = {
  /** 図面検索 */
  Blueprint: 'blueprint_search',
  /** 見積管理 */
  Estimate: 'estimate',
};
export type BlueprintUsableType =
  typeof BlueprintUsableType[keyof typeof BlueprintUsableType];

/** 図面抽出処理ステータス */
export const BlueprintExtractStatus = {
  /** 未処理 */
  None: 0,
  /** 処理中 */
  Processing: 1,
  /** 処理済 */
  Processed: 2,
  /** 処理エラー */
  ProcessError: 3,
};
export type BlueprintExtractStatus =
  typeof BlueprintExtractStatus[keyof typeof BlueprintExtractStatus];

/** 図面抽出セグメント名 */
export const BlueprintSegmentNames = {
  /** 正面 */
  Front: 'front',
  /** 右面 */
  Right: 'right',
  /** 下面 */
  Bottom: 'bottom',
};
export type BlueprintSegmentNames =
  typeof BlueprintSegmentNames[keyof typeof BlueprintSegmentNames];
