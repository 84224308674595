import {
  convertLongToString,
  convertBaseDigitOnceTo,
  BASE_DIGIT_THOUSAND,
  toCommaTypeString,
  formatIDateToString,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { Textbox } from '~/shared/components/ui/Textbox';
import { Textarea } from '~/shared/components/ui';
import { Amountbox } from '~/shared/components/ui/Amountbox';
import { LinkTextbox } from '~/shared/components/ui/LinkTextbox';
import { PresetItem } from '~/shared/services';

interface ProfileTabItemProps {
  preset: PresetItem;
  businessUnitProfile?: mtechnavi.api.company.IBusinessUnitProfile;
}

export const ProfileTabItem = ({
  preset,
  businessUnitProfile,
}: ProfileTabItemProps) => {
  return (
    <>
      <div className="tab-info-body">
        {/* 1段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-40">
              {/* 企業名 */}
              <Textbox
                name="profile.displayNameLang.ja"
                className="companyName"
                type="text"
                disabled={true}
                value={businessUnitProfile?.profile?.displayNameLang?.ja}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.displayNameLang.ja"
                columns={preset.columns}
              />
            </div>
            <div className="w-40">
              {/* 英語名 */}
              <Textbox
                name="profile.displayNameLang.en"
                className="companyNameEn"
                type="text"
                disabled={true}
                value={businessUnitProfile?.profile?.displayNameLang?.en}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.displayNameLang.en"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 法人番号 */}
              <Textbox
                name="profile.companyNumber"
                className="companyNumber"
                type="text"
                disabled={true}
                value={
                  businessUnitProfile?.profile?.companyLegalProperties
                    ?.companyNumber
                }
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.companyNumber"
                columns={preset.columns}
              />
            </div>
          </div>
        </div>
        {/* 2段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-40">
              {/* ふりがな */}
              <Textbox
                name="profile.displayNameLang.ja.kana"
                className="companyNameKana"
                type="text"
                disabled={true}
                value={
                  businessUnitProfile?.profile?.displayNameLang?.['ja-kana']
                }
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.displayNameLang.ja.kana"
                columns={preset.columns}
              />
            </div>
            <div className="w-40">
              {/* ホームページURL */}
              <LinkTextbox
                name="profile.websiteUrl"
                className="websiteUrl"
                value={businessUnitProfile?.profile?.websiteUrl}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.websiteUrl"
                columns={preset.columns}
                isExternalLink={true}
              />
            </div>
            <div className="w-20">
              {/* 適格請求書番号 */}
              <Textbox
                name="profile.eligibleInvoiceAuthorizationNumber"
                className="eligibleInvoiceAuthorizationNumber"
                type="text"
                disabled={true}
                value={
                  businessUnitProfile?.profile?.companyLegalProperties
                    ?.eligibleInvoiceAuthorizationNumber
                }
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.eligibleInvoiceAuthorizationNumber"
                columns={preset.columns}
              />
            </div>
          </div>
        </div>
        {/* 3段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-20">
              {/* 代表者名 */}
              <Textbox
                name="profile.representative.ja"
                className="representative"
                type="text"
                disabled={true}
                value={
                  businessUnitProfile?.profile?.representative?.displayNameLang
                    ?.ja
                }
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.representative.ja"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 代表者名（ふりがな） */}
              <Textbox
                name="profile.representative.ja.kana"
                className="representativeKana"
                type="text"
                disabled={true}
                value={
                  businessUnitProfile?.profile?.representative
                    ?.displayNameLang?.['ja-kana']
                }
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.representative.ja.kana"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 代表者（役職） */}
              <Textbox
                name="profile.position"
                className="position"
                type="text"
                disabled={true}
                value={businessUnitProfile?.profile?.representative?.position}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.position"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 代表者（生年月日） */}
              <Textbox
                name="profile.birthDate"
                type="text"
                value={formatIDateToString(
                  businessUnitProfile?.profile?.representative?.birthDate,
                  'YYYY/MM/DD'
                )}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.birthDate"
                columns={preset.columns}
                disabled={true}
              />
            </div>
          </div>
        </div>
        {/* 4段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-20">
              {/* 本社（郵便番号） */}
              <Textbox
                name="profile.postalCode"
                className="postalCode"
                type="text"
                disabled={true}
                value={businessUnitProfile?.profile?.address?.postalCode}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.postalCode"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 本社（都道府県） */}
              <Textbox
                name="profile.region"
                type="text"
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.region"
                columns={preset.columns}
                disabled={true}
                value={
                  businessUnitProfile?.profile?.address?.region?.displayNameLang
                    ?.ja
                }
              />
            </div>
            <div className="w-40">
              {/* 本社（住所） */}
              <Textbox
                name="profile.addressLine"
                className="addressLine"
                type="text"
                disabled={true}
                value={businessUnitProfile?.profile?.address?.addressLines?.join()}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.addressLine"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 本社（電話番号） */}
              <Textbox
                name="profile.phoneNumber"
                className="phoneNumber"
                type="text"
                disabled={true}
                value={businessUnitProfile?.profile?.phoneNumber}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.phoneNumber"
                columns={preset.columns}
              />
            </div>
          </div>
        </div>
        {/* 5段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-20">
              {/* 従業員（人数） */}
              <Textbox
                name="profile.employees.size"
                className="employees right"
                type="text"
                disabled={true}
                value={convertLongToString(
                  businessUnitProfile?.profile?.employees?.size
                )}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.employees.size"
                columns={preset.columns}
                formatter={toCommaTypeString}
              />
            </div>
            <div className="w-20">
              {/* 従業員（平均年齢） */}
              <Amountbox
                name="profile.employees.averageAge"
                className="averageAge"
                value={businessUnitProfile?.profile?.employees?.averageAge}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.employees.averageAge"
                columns={preset.columns}
                displayOption={{ isCommaFormat: true }}
                disabled={true}
              />
            </div>
            <div className="w-20">
              {/* 資本金（千円） */}
              <Amountbox
                name="profile.capitalStock"
                className="capitalStock"
                value={convertBaseDigitOnceTo(
                  businessUnitProfile?.profile?.capitalStock,
                  BASE_DIGIT_THOUSAND
                )}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.capitalStock"
                columns={preset.columns}
                displayOption={{ isCommaFormat: true }}
                disabled={true}
              />
            </div>
          </div>
        </div>
        {/* 6段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-20">
              {/* 主要株主 */}
              <Textbox
                name="profile.mainShareholder"
                className="mainShareholder"
                type="text"
                disabled={true}
                value={businessUnitProfile?.profile?.mainShareholder}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.mainShareholder"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 主要取引銀行 */}
              <Textbox
                name="profile.mainBank"
                className="mainBank"
                type="text"
                disabled={true}
                value={businessUnitProfile?.profile?.mainBank}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.mainBank"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 主要仕入先 */}
              <Textbox
                name="profile.mainSupplier"
                className="mainSupplier"
                type="text"
                disabled={true}
                value={businessUnitProfile?.profile?.mainSupplier}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.mainSupplier"
                columns={preset.columns}
              />
            </div>
            <div className="w-20">
              {/* 主要納入先 */}
              <Textbox
                name="profile.mainCustomer"
                className="mainCustomer"
                type="text"
                disabled={true}
                value={businessUnitProfile?.profile?.mainCustomer}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.mainCustomer"
                columns={preset.columns}
              />
            </div>
          </div>
        </div>
        {/* 7段目 */}
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-80">
              {/* 事業内容 */}
              <Textarea
                name="profile.mainBusiness"
                className="w-100 mh-middle"
                disabled={true}
                value={businessUnitProfile?.profile?.mainBusiness ?? ''}
                labelId="BUM_BUSINESS_UNIT_MANAGEMENT_CONFIRMATION.profile.mainBusiness"
                columns={preset.columns}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
