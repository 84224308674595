import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessage,
  GetMessageWithIntl,
  LoadingIcon,
  MessageProps,
  Toast,
  error,
  success,
} from '~/shared/components';
import {
  Accordion,
  CommentDialog,
  ConfirmationDialog,
  NavigationIconMenu,
  PageNavigation,
  Schema,
  SimpleIconListView,
  SimpleListView,
  Textarea,
  Textbox,
  getAltDisplaySchema,
  getCommaTypeNumberSchema,
} from '~/shared/components/ui';
import {
  ConnectingDialog,
  InitialFilterOption,
} from '~/shared/components/ui/Dialog/ConnectingDialog';
import { PresetItem } from '~/shared/services';
import {
  FullMethodName_ListDisasterSurveys,
  FullMethodName_ListSurveyBaseRequestContents,
  FullMethodName_ListSurveyRequestContents,
  PageState,
  ViewId,
  convertLongToNumber,
  convertLongToString,
  getDateFormat,
  getMaxMainContentsHeight,
  getSortSettingFromLocalStorage,
  getViewIdPreset,
  getWorkerExceptionMessage,
  movePage,
  saveInitialLocalStorageData,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { CaptionButton, IconButton } from '~/shared/components/ui/Button';
import { SurveyRequestRegistrationDialog } from '~/shared/components/ui/Dialog/SurveyRequestRegistrationDialog';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { AggregateStage, AggregateStageUnion } from '~/worker';
import { usePagenator } from '~/shared/components/ui/ListView/pagenator';
import { useErrorHandler } from '~/shared/components/error/ErrorBoundary';
import './BcpDisasterSurveyConfirmation.css';

interface surveyRequestListViewType {
  surveyBaseRequestId: string;
  surveyRequestAutoName: string;
  displayName: string;
  totalUnit: string;
}

type saveItemType = 'surveyRemarks' | 'addIds' | 'deleteIds';
type SurveyRequestContentAggregated =
  mtechnavi.ui.survey.SurveyRequestContentAggregated;
const aggregatedType = 'SurveyRequestContentAggregated';
const aggregateStages: AggregateStage[] = [
  {
    // 実績データをグループ化する
    $group: {
      _id: {
        surveyBaseRequestId: 'surveyRequest.surveyBaseRequestId',
      },
      displayName: { $first: 'surveyRequest.displayName' },
      surveyBaseRequestId: {
        $first: 'surveyRequest.surveyBaseRequestId',
      },
      surveyRequestAutoName: {
        $first: 'surveyRequest.surveyRequestAutoName',
      },
      replyDueDateDt: {
        $min: 'replyDueDateDt',
      },
      sendedAt: { $first: 'surveyRequest.sendedAt' },
      surveyRequestCatalog: {
        $first: 'surveyRequest.surveyRequestCatalog',
      },
      totalRequestUnit: { $count: {} },
      status: { $first: 'status' },
      issuerEmail: {
        $first: 'surveyRequest.issuerProperties.updatedBy.email',
      },
    },
  },
  {
    // 予定データの名前をグループ化した実績データに合わせる
    $union: {
      from: FullMethodName_ListSurveyBaseRequestContents,
      fields: [
        {
          name: 'surveyBaseRequest.surveyBaseRequestId',
          as: '_id',
        },
        {
          name: 'surveyBaseRequest.displayName',
          as: 'displayName',
        },
        {
          name: 'surveyBaseRequest.surveyBaseRequestId',
          as: 'surveyBaseRequestId',
        },
        {
          name: 'surveyBaseRequest.surveyRequestAutoName',
          as: 'surveyRequestAutoName',
        },
        {
          name: 'surveyBaseRequest.replyDueDateDt',
          as: 'replyDueDateDt',
        },
        {
          name: 'surveyBaseRequest.sendedAt',
          as: 'sendedAt',
        },
        {
          name: 'surveyBaseRequest.surveyRequestCatalog',
          as: 'surveyRequestCatalog',
        },
        'totalRequestUnit',
        'status',
        {
          name: 'surveyBaseRequest.issuerProperties.updatedBy.email',
          as: 'issuerEmail',
        },
      ],
      option: {
        useQuery: false,
      },
    },
  },
];

type SurveyDisaster = mtechnavi.api.bcp.IDisasterSurvey;
const VIEW_ID: ViewId = 'BCP_DISASTER_SURVEY_CONFIRMATION';
export const BcpDisasterSurveyConfirmation = () => {
  //基本情報
  const myEmail = useAuth().user?.email ?? '';
  const [isLoading, setLoading] = useState(false);
  const sourcePageInfo = useRef((useLocation().state as PageState) ?? []);
  const intl = useIntl();
  const navi = useNavigate();

  const getFormmaterSchema = (schema: Schema): Schema => {
    const jaColumn: string[] = ['status.displayNameLang'];
    const jaSch = getAltDisplaySchema(schema, jaColumn, 'ja');
    const commaSch = getCommaTypeNumberSchema(jaSch, ['totalRequestUnit']);
    return commaSch;
  };
  const initialfilterItems: InitialFilterOption = {
    info: [{ targetKey: 'issuerEmail', targetValue: myEmail }],
  };

  const handleError = useErrorHandler();
  // preset
  const [preset, setPreset] = useState<PresetItem>({ name: '' });

  // 画面レイアウト
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);
  // ナヴィゲーションアイテム
  const [item, setItem] = useState<mtechnavi.api.bcp.IDisasterSurvey>();
  const [listViewItem, setListViewItem] = useState<surveyRequestListViewType[]>(
    []
  );
  const [surveyRemarks, setSurveyRemarks] = useState('');
  const [displaySurveyRemarks, setDisplaySurveyRemarks] = useState('');
  const [isVisibledFooterBtn, setVisibledFooterBtn] = useState(false);
  const [isDisabledIconBtn, setDisabledIconBtn] = useState(true);
  const [isFirstPage, setFirstPage] = useState(false);
  const [isMaxPage, setMaxPage] = useState(false);
  const resumeSurvey = GetMessageWithIntl(intl, {
    id: 'resumeSurvey',
    prefixId: VIEW_ID,
  });
  const completeSurvey = GetMessageWithIntl(intl, {
    id: 'completeSurvey',
    prefixId: VIEW_ID,
  });

  // 確認ダイアログ削除
  const viewMessageDelete: MessageProps = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };
  // 確認ダイアログ調査終了
  const disasterSurveyCompleteConfMessage = {
    id: 'C0000001',
    value: { $1: completeSurvey },
  };
  // 確認ダイアログ調査再開
  const disasterSurveyResumeConfMessage = {
    id: 'C0000001',
    value: { $1: resumeSurvey },
  };
  const toastSuccess = GetMessageWithIntl(intl, { id: 'I0000001' });
  // ダイアログ
  // 確認ダイアログ
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isOpenRegistrationDialog, setOpenRegistrationDialog] = useState(false);
  const [isOpenConnectingdialog, setOpenConnectingDialog] = useState(false);
  const [isOpenCommentDialog, setOpenCommentDialog] = useState(false);
  // 確認ダイアログInfo
  const [confirmDialogInfo, setConfirmDialogInfo] = useState({
    viewMessage: viewMessageDelete,
    onDecision: () => {},
  });

  const getButtonName = (): string => {
    const status = item?.status?.systemName;
    if (status === 'B00') {
      return `${completeSurvey}/${resumeSurvey}`;
    }
    if (status === 'B01') {
      return completeSurvey;
    }
    if (status === 'B02') {
      return resumeSurvey;
    }
    return `${completeSurvey}/${resumeSurvey}`;
  };

  // 例外処理用にエラー情報を取得する様修正
  const [isException, setException] = useState<boolean>(false);
  const [pagenateError, setPagenateError] = useState<unknown>({});

  // ページ戻し・送り処理
  const handleMovePage = (pageNumber: number) => {
    const n = Math.min(Math.max(1, pageNumber), page.maxPageNumber);
    dispatch({
      type: 'query',
      fullMethodName: FullMethodName_ListDisasterSurveys,
      pageNumber: n,
    });
  };

  // pagenatorに基本情報をセット
  const [page, dispatch] = usePagenator({
    fullMethodName: FullMethodName_ListDisasterSurveys,
    pageNumber: sourcePageInfo.current.confirmationViewOption?.pageNumber ?? 1,
    pageSize: 1,
    maxPageNumber: 1,
    filter: {
      $or: [{ disasterSurveyId: { $in: sourcePageInfo.current.ids ?? [] } }],
    },
    requestBody: {
      disasterSurveyIds: sourcePageInfo.current.ids ?? [],
    },
    sort: getSortSettingFromLocalStorage('BCP_DISASTER_SURVEY_LIST', myEmail),
    items: [],
    originalItems: [],
    onError(err) {
      setException(true);
      setPagenateError(err);
      handleError(err);
    },
  });

  // pageデータ変更時の処理
  useEffect(() => {
    const items = page.originalItems as unknown as SurveyDisaster[];
    setItem(items.pop());
    setFirstPage(page.pageNumber === 1);
    setMaxPage(page.pageNumber === page.maxPageNumber);
  }, [page]);

  useEffect(() => {
    if (isException) {
      error(getWorkerExceptionMessage(intl, pagenateError));
    }
  }, [intl, isException, pagenateError]);

  const handleReload = () => {
    dispatch({
      type: 'reload',
      fullMethodName: FullMethodName_ListDisasterSurveys,
      onChangeLoadingState: (v) => {
        setLoading(v);
      },
    });
  };
  // 初回画面用データ
  useEffect(() => {
    // viewIDに紐付くpresetを取得
    (async () => {
      const { presetItem } = await getViewIdPreset(intl, VIEW_ID);
      setPreset(presetItem);
    })();

    handleReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ナビゲーションメニュー
  const navigationIconItems = (): NavigationIconMenu[] => {
    const iconItems: NavigationIconMenu[] = [];
    // 編集
    iconItems.push({
      name: 'edit',
      displayName: '編集',
      func: () => {
        handleMenuInput();
      },
      disabled: item?.status?.systemName === 'B02' ? true : false,
    });
    // 削除
    iconItems.push({
      name: 'delete',
      displayName: '削除',
      func: () => {
        setConfirmDialogInfo({
          viewMessage: viewMessageDelete,
          onDecision: () => {
            handleDelete();
          },
        });
        setOpenConfirmDialog(true);
      },
      disabled: item?.status?.systemName === 'B00' ? false : true,
    });

    return iconItems;
  };

  useEffect(() => {
    setSurveyRemarks(item?.surveyRemarks ?? '');
    setDisplaySurveyRemarks(item?.surveyRemarks ?? '');
  }, [item]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSurveyRequestItems = async () => {
    if (!item?.suveyBaseRequestIds || item?.suveyBaseRequestIds.length === 0) {
      setListViewItem([]);
      return;
    }
    setLoading(true);
    const surveyrequestListRes = await window.App.services.ui.worker.filter({
      action: 'reload',
      fullMethodName: FullMethodName_ListSurveyRequestContents,
      filter: {
        $or: [
          { surveyBaseRequestId: { $in: item?.suveyBaseRequestIds ?? [] } },
        ],
      },
      sort: [],
      requestBody: {
        dataFilter: { surveyBaseRequestIds: item?.suveyBaseRequestIds ?? [] },
      },
      aggregate: [
        aggregateStages[0],
        {
          $union: {
            ...(aggregateStages[1].$union as AggregateStageUnion),
            option: {
              useQuery: false,
              requestBody: {
                surveyBaseRequestIds: item?.suveyBaseRequestIds ?? [],
              },
            },
          },
        },
      ],
    });
    const surveyRequestAggreatedList: SurveyRequestContentAggregated[] =
      surveyrequestListRes.items as SurveyRequestContentAggregated[];
    const listViewItems: surveyRequestListViewType[] = [];
    surveyRequestAggreatedList.map((item) => {
      if (!item.surveyBaseRequestId) {
        return;
      }
      const num = item.totalRequestUnit
        ? convertLongToNumber(item.totalRequestUnit) ?? 0
        : 0;
      const listViewItem: surveyRequestListViewType = {
        surveyBaseRequestId: item.surveyBaseRequestId,
        displayName: item.displayName,
        surveyRequestAutoName: item.surveyRequestAutoName.low.toString(),
        totalUnit:
          num.toLocaleString(undefined, {
            maximumFractionDigits: 20,
          }) ?? '',
      };
      listViewItems.push(listViewItem);
    });
    setListViewItem(listViewItems);
    setLoading(false);
  };

  useEffect(() => {
    switch (item?.status?.systemName) {
      case 'B00':
        setVisibledFooterBtn(false);
        setDisabledIconBtn(false);
        break;
      case 'B01':
        setVisibledFooterBtn(true);
        setDisabledIconBtn(false);
        break;
      case 'B02':
        setVisibledFooterBtn(true);
        setDisabledIconBtn(true);
    }

    getSurveyRequestItems();

    // 関数定義の関係でlint除外
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.status?.systemName, intl]);

  useEffect(() => {
    getSurveyRequestItems;

    // 関数定義の関係でlint除外
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.suveyBaseRequestIds, intl]);

  const handleMenuInput = () => {
    const ids = [] as string[];
    ids.push(item?.disasterSurveyId ?? '');
    const state: PageState = {
      ids: ids ?? [],
      actionType: 'edit',
      sourceViewId: VIEW_ID,
      naviFilters: sourcePageInfo.current.naviFilters,
      beforeStateIds: sourcePageInfo.current.ids,
      baseViewOption: sourcePageInfo.current.baseViewOption,
      confirmationViewOption: { pageNumber: page.pageNumber },
    };
    navi('/bcp/bcp-disaster-survey-input', { state });
  };

  const handleMovetoSurveyRequestList = () => {
    saveInitialLocalStorageData('SURVEY_REQUEST_SENDER_LIST', myEmail);
    const autoName = [] as string[];
    autoName.push(...listViewItem.map((item) => item.surveyRequestAutoName));

    const state: PageState = {
      sourceViewId: VIEW_ID,
      ids: [],
      naviFilters: [
        {
          key: 'surveyRequestAutoName',
          value: autoName,
        },
        {
          key: 'statusCodes',
          value: ['1', '2', '3', '4', '5', '6', '7', '8', '11'],
        },
      ],
    };
    movePage(state, navi, '/supplier-base/survey-request-list');
  };

  const handleDelete = async () => {
    setLoading(true);
    const deleteRequest: mtechnavi.api.bcp.IDeleteDisasterSurveyRequest = {
      disasterSurveyId: item?.disasterSurveyId,
    };
    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'deleteDisasterSurvey',
        request: deleteRequest,
      });
      success([toastSuccess]);
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    }
    setLoading(false);
    setOpenConfirmDialog(false);
    navi('/bcp/bcp-disaster-survey-list');
  };

  // APIの方でステータス計算がうまくいっていない可能性。APIを叩いても切り替わらない場合がある。要確認
  const handleComplete = async () => {
    setLoading(true);
    const completeRequest: mtechnavi.api.bcp.ICompleteDisasterSurveyRequest = {
      disasterSurveyId: item?.disasterSurveyId,
    };
    try {
      await window.App.services.ui.worker
        .apiCall({
          actionName: 'completeDisasterSurvey',
          request: completeRequest,
        })
        .then(() => {
          success([toastSuccess]);
          handleReload();
        });
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    }
    setLoading(false);
  };

  // APIの方でステータス計算がうまくいっていない可能性。APIを叩いても切り替わらない場合がある。要確認
  const handleResume = async () => {
    setLoading(true);
    const uncompleteRequest: mtechnavi.api.bcp.IUncompleteDisasterSurveyRequest =
      {
        disasterSurveyId: item?.disasterSurveyId,
      };
    try {
      await window.App.services.ui.worker
        .apiCall({
          actionName: 'uncompleteDisasterSurvey',
          request: uncompleteRequest,
        })
        .then(() => {
          success([toastSuccess]);
          handleReload();
        });
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
    }
    setLoading(false);
  };

  const handleSaveDisasterSurvey = async (
    saveItemType: saveItemType,
    surveyRequestBaseIds?: string[]
  ) => {
    setLoading(true);
    const request: mtechnavi.api.bcp.IDisasterSurvey = {
      disasterSurveyId: item?.disasterSurveyId,
      disasterId: item?.disasterId,
      disasterSurveyAutoName: item?.disasterSurveyAutoName,
      category: item?.category,
      occurredAt: item?.occurredAt,
      disasterDisplayName: item?.disasterDisplayName,
      regions: item?.regions,
      externalSourceCategory: item?.externalSourceCategory,
      externalSourceName: item?.externalSourceName,
      remarks: item?.remarks,
      surveyRemarks: item?.surveyRemarks,
      suveyBaseRequestIds: item?.suveyBaseRequestIds,
      completed: item?.completed,
      updatedAt: item?.updatedAt,
    };

    if (saveItemType === 'surveyRemarks') {
      request.surveyRemarks = surveyRemarks;
    }
    if (saveItemType === 'addIds') {
      const ids: string[] = [];
      // 調査依頼情報一覧に表示されている調査依頼IDを登録
      (listViewItem ?? []).forEach((viewItem) => {
        ids.push(viewItem.surveyBaseRequestId);
      });
      if (surveyRequestBaseIds) {
        ids.push(...surveyRequestBaseIds);
      }
      const uniqueIds: string[] = ids.filter((id, index) => {
        return ids.indexOf(id) === index;
      });
      request.suveyBaseRequestIds = uniqueIds;
    }
    if (saveItemType === 'deleteIds') {
      const ids: string[] = item?.suveyBaseRequestIds ?? [];
      const saveIds = ids.filter((id) => !surveyRequestBaseIds?.includes(id));
      request.suveyBaseRequestIds = saveIds;
    }

    try {
      await window.App.services.ui.worker.apiCall({
        actionName: 'saveDisasterSurvey',
        request,
      });
      success([toastSuccess]);
      handleReload();
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="bcpDisasterSurveyConfirmation">
          <div className="header">
            <PageNavigation
              backpagePath="/bcp/bcp-disaster-survey-list"
              pageInfo={{
                isVisibleMoveNavi: true,
                isFirstPage,
                isMaxPage,
                pageNumber: page.pageNumber,
              }}
              iconItems={navigationIconItems()}
              handleMovePage={(n) => handleMovePage(n)}
              infoOption={{
                lastUpdateInfo: {
                  isVisibleUpdateInfo: true,
                  attribute: item?.updatedProperties?.updatedBy?.email ?? '',
                  attributeAt: item?.updatedProperties?.updatedAt ?? undefined,
                  content:
                    item?.updatedProperties?.updatedBy?.displayName ?? '',
                },
                issuerInfo: { isVisibleIssuerInfo: false },
              }}
            />
          </div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <Accordion
              title={GetMessage({ id: 'disaster', prefixId: VIEW_ID })}
            >
              <div className="input-line">
                <div className="item-group-100">
                  {/* 災害調査番号、災害名 */}
                  <div className="w-33 mb-1">
                    <span className="doubleColon">
                      {convertLongToString(item?.disasterSurveyAutoName)}
                    </span>
                    <span>{item?.disasterDisplayName}</span>
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  {/* 発生日 */}
                  <div className="w-33  mb-1">
                    <span className="doubleColon">
                      {GetMessage({
                        prefixId: 'BCP_DISASTER_SURVEY_CONFIRMATION',
                        id: 'occurredAt',
                      })}
                    </span>
                    <span>
                      {getDateFormat(item?.occurredAt ?? '', 'YYYY/MM/DD')}
                    </span>
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  {/* 災害調査ステータス */}
                  <div className="w-25 status">
                    {/* {ステータス} */}
                    <span>{item?.status?.displayNameLang?.ja}</span>
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  {/* 災害分類 */}
                  <div className="w-20">
                    <Textbox
                      name="category"
                      className="field"
                      labelId="BCP_DISASTER_SURVEY_CONFIRMATION.category"
                      columns={preset.columns}
                      value={item?.category?.displayNameLang?.ja}
                      disabled={true}
                      type="text"
                    />
                  </div>
                  {/* 外部連携情報種類 */}
                  <div className="w-20">
                    <Textbox
                      name="externalSourceCategory"
                      className="field"
                      value={item?.externalSourceCategory}
                      type="text"
                      labelId="BCP_DISASTER_SURVEY_CONFIRMATION.externalSourceCategory"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  {/* 外部連携情報データ */}
                  <div className="w-40">
                    <Textbox
                      name="externalSourceName"
                      className="field"
                      value={item?.externalSourceName}
                      type="text"
                      labelId="BCP_DISASTER_SURVEY_CONFIRMATION.externalSourceName"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  {/* 備考 */}
                  <div className="w-80">
                    <Textbox
                      name="remarks"
                      className="field"
                      value={item?.remarks}
                      type="text"
                      labelId="BCP_DISASTER_SURVEY_CONFIRMATION.remarks"
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100 region-area">
                  <div className="w-50">
                    <SimpleListView
                      data={
                        item?.regions?.map((item) => {
                          return { regionName: item.displayNameLang?.ja };
                        }) ?? []
                      }
                      viewOptions={{
                        previewRowCount: 5,
                        columns: [
                          {
                            header: { id: 'regionName' },
                            propertyName: 'regionName',
                          },
                        ],
                        omitFooter: true,
                      }}
                      actionOptions={{}}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion
              title={GetMessage({ id: 'surveyRequest', prefixId: VIEW_ID })}
            >
              <div className="input-line iconButtonArea">
                <div className="item-group-100">
                  <div className="surveyRequestIconButton">
                    <IconButton
                      name="addSurveyRequest"
                      iconType="noteadd"
                      caption="追加"
                      buttonType="basic"
                      onClick={() => {
                        setOpenRegistrationDialog(true);
                      }}
                      disabled={isDisabledIconBtn}
                    />
                  </div>
                  <div className="surveyRequestIconButton">
                    <IconButton
                      name="editSurveyRequest"
                      iconType="description"
                      caption="確認"
                      buttonType="basic"
                      onClick={() => {
                        handleMovetoSurveyRequestList();
                      }}
                      disabled={isDisabledIconBtn}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line surveyRequestListArea">
                <div className="item-group-100">
                  <div className="w-66">
                    <SimpleIconListView
                      data={listViewItem}
                      viewOptions={{
                        previewRowCount: 5,
                        keyColumn: 'surveyBaseRequestId',
                        columns: [
                          {
                            header: {
                              id: 'surveyRequestAutoName',
                              prefixId: VIEW_ID,
                            },
                            propertyName: 'surveyRequestAutoName',
                          },
                          {
                            header: { id: 'displayName', prefixId: VIEW_ID },
                            propertyName: 'displayName',
                          },
                          {
                            header: { id: 'totalUnit', prefixId: VIEW_ID },
                            propertyName: 'totalUnit',
                          },
                        ],
                        omitFooter: true,
                      }}
                      actionOptions={{}}
                      iconMenuOptions={{
                        iconMenu: [
                          {
                            name: 'delete',
                            displayName: '削除',
                            disabled: isDisabledIconBtn,
                            func: (item: surveyRequestListViewType) => {
                              setConfirmDialogInfo({
                                viewMessage: viewMessageDelete,
                                onDecision: () => {
                                  handleSaveDisasterSurvey('deleteIds', [
                                    item.surveyBaseRequestId,
                                  ]);
                                  setOpenConfirmDialog(false);
                                },
                              });
                              setOpenConfirmDialog(true);
                            },
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line surveyRemarksArea">
                <div className="item-group-100">
                  <IconButton
                    name="editSurveyRemarks"
                    iconType="edit"
                    caption="編集"
                    className="memoEdit"
                    buttonType="basic"
                    onClick={() => {
                      setOpenCommentDialog(true);
                    }}
                  />
                  <div className="w-60">
                    <Textarea
                      name="surveyRemarks"
                      className="w-100 mh-middle"
                      labelId="BCP_DISASTER_SURVEY_CONFIRMATION.surveyRemarks"
                      value={displaySurveyRemarks}
                      disabled={true}
                      columns={preset.columns}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                {isVisibledFooterBtn && (
                  <CaptionButton
                    name="footerBtn"
                    caption={getButtonName()}
                    className="btn"
                    onClick={() => {
                      if (item?.status?.systemName === 'B01') {
                        setConfirmDialogInfo({
                          viewMessage: disasterSurveyCompleteConfMessage,
                          onDecision: () => {
                            handleComplete();
                            setOpenConfirmDialog(false);
                          },
                        });
                      }
                      if (item?.status?.systemName === 'B02') {
                        setConfirmDialogInfo({
                          viewMessage: disasterSurveyResumeConfMessage,
                          onDecision: () => {
                            handleResume();
                            setOpenConfirmDialog(false);
                          },
                        });
                      }
                      setOpenConfirmDialog(true);
                    }}
                    buttonType="basic"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <ConfirmationDialog
          isOpen={isOpenConfirmDialog}
          viewMessage={confirmDialogInfo.viewMessage}
          onDecision={confirmDialogInfo.onDecision}
          onCancel={() => {
            setOpenConfirmDialog(false);
          }}
        ></ConfirmationDialog>
        <SurveyRequestRegistrationDialog
          isOpen={isOpenRegistrationDialog}
          surveyCount={listViewItem.length}
          onDecision={(v) => {
            if (v === 'newSurvey') {
              const state: PageState = {
                sourceViewId: VIEW_ID,
                ids: [item?.disasterSurveyId ?? ''],
                beforeStateIds: [item?.disasterSurveyId ?? ''],
                actionType: 'add',
              };
              navi('/supplier-base/survey-request-sender-input', { state });
            } else {
              setOpenRegistrationDialog(false);
              saveInitialLocalStorageData('DISASTER_SURVEY_SEARCH', myEmail);
              setOpenConnectingDialog(true);
            }
          }}
          onCancel={() => {
            setOpenRegistrationDialog(false);
          }}
        />
        <ConnectingDialog
          isOpen={isOpenConnectingdialog}
          FullMethodNameForExtendListView={
            FullMethodName_ListSurveyRequestContents
          }
          FullMethodNames={[
            FullMethodName_ListSurveyBaseRequestContents,
            FullMethodName_ListSurveyRequestContents,
          ]}
          aggregateStagesOption={{
            aggregateStages: aggregateStages,
            aggregateType: aggregatedType,
          }}
          initialFilterOption={initialfilterItems}
          selectedIds={listViewItem.map((item) => item.surveyBaseRequestId)}
          keyId={'surveyBaseRequestId'}
          messageOption={{
            headerOption: {
              id: 'disasterSurveySearch',
              prefixId: 'DIALOG_TITLE',
            },
          }}
          viewId={'DISASTER_SURVEY_SEARCH'}
          formatSchemaInfo={(schema: Schema) => getFormmaterSchema(schema)}
          onDecision={(ids: string[]) => {
            handleSaveDisasterSurvey('addIds', ids);
            setOpenConnectingDialog(false);
          }}
          onCancel={() => {
            setOpenConnectingDialog(false);
          }}
          validateOption={{
            maxSelectItem: { selectLimit: 10, errorId: 'E0000123' },
          }}
        />
        <CommentDialog
          isOpen={isOpenCommentDialog}
          inputOption={{ comment: displaySurveyRemarks }}
          inputStateOption={{ onChangeComment: setSurveyRemarks }}
          messageOption={{
            headerLabelId: {
              id: 'survey_memo',
              prefixId: 'DIALOG_TITLE',
            },
            captionLabelId: {
              id: 'memo',
              prefixId: 'caption',
            },
            decisionLabelId: {
              id: 'save',
            },
          }}
          onDecision={() => {
            handleSaveDisasterSurvey('surveyRemarks');
            setOpenCommentDialog(false);
          }}
          onCancel={() => {
            setOpenCommentDialog(false);
          }}
        />
        <Toast />
      </Container>
      {isLoading && <LoadingIcon />}
    </>
  );
};
