import { useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as ExpandMore } from '@material-design-icons/svg/filled/expand_more.svg';
import { ReactComponent as FilterAlt } from '@material-design-icons/svg/outlined/filter_alt.svg';
import {
  FilterExpression,
  FilterPrimitive,
  FilterTerm,
  FilterViewQuery,
  FilterViewQueryTerm,
  FullMethodName,
} from '~/worker';
import { PresetItem } from '~/shared/services';

import { GetMessageWithIntl } from '~/shared/components/parts/Message/Message';
import { IntlShape, useIntl } from 'react-intl';
import { FilterAreaView } from './FilterAreaView';

import './FilterView.css';
import {
  getPresetItemColums,
  getPresetProperty,
  getPresetPropertyValue,
  getPresetItem,
  getMultiplePresetItem,
  getPresetPropertysByColumnName,
} from './preset';
import { FilterDrawer } from './FilterDrawer';
import {
  getColumnFilterTypeConverters,
  toEqualFilter,
  toFilterOr,
  toInFilter,
  isFilterLike,
  isFilterEq,
  isString,
  isFilterGte,
  isFilterLte,
  isFilterIn,
  isStringArray,
  isObject,
} from './filter';
import Long from 'long';
import {
  getFilteringFilterboxBooleanValues,
  getFilteringFilterboxMultipleValues,
  getFilteringFromToDateValues,
  getFilteringFromToNumberValues,
  getFilteringTextboxValues,
  toBoolFilters,
  toFromToDateFilters,
  toFromToNumberFilters,
  CustomDisplayNameType,
  getFilteringFilterboxCustomValues,
} from './FilterInputItems';
import { ViewId } from '~/shared/utils';
import { getPresetPropertysByPropertyName } from './preset';
import { Schema } from '../ListView';
import { sharelib } from '~/shared/libs/clientsdk';
import { FilterSave } from './FilterSave';

export type { FilterViewQuery } from '~/worker';

const toLocaleDateString = (microSec: string): string => {
  const miliSec = Long.fromString(microSec).divide(1000);
  const ut = new Date(miliSec.toNumber());
  return ut.toLocaleDateString();
};

const toDateString = (microSec?: string): string => {
  return microSec ? toLocaleDateString(microSec) : '';
};

const setApiDisplayName = async (
  fullMethodName: string,
  filterKey: string,
  filterValue: string[],
  displayColumn: string,
  filterboxCustomDisplayName: string,
  filterboxCustomDisplayNameType: CustomDisplayNameType,
  setFilterApiDisplayValues: (result: string[]) => void
) => {
  try {
    const query: FilterExpression = {};
    query[filterKey] = { $in: filterValue };
    const result = await window.App.services.ui.worker.filter(
      {
        action: 'reload',
        fullMethodName: fullMethodName as FullMethodName,
        filter: query,
        sort: [],
      },
      true
    );
    const displayValues: string[] = [];
    for (const v of result.items) {
      if (!isObject(v)) continue;
      let displayName = v[displayColumn] as string;
      // 表示制御が発生する場合
      if (filterboxCustomDisplayName) {
        if (filterboxCustomDisplayNameType === 'brackets') {
          displayName = `${displayName}(${
            v[filterboxCustomDisplayName] as string
          })`;
        }
      }
      displayValues.push(displayName);
    }
    setFilterApiDisplayValues(displayValues);
    return displayValues;
  } catch (err) {
    setFilterApiDisplayValues([]);
    return [];
  }
};

const toDisplayFilterTerms = (
  intl: IntlShape,
  viewId: ViewId,
  preset: PresetItem,
  query: FilterViewQuery,
  setFilterApiDisplayValues: (result: string[]) => void,
  setFilterApiMapDisplayValues: (result: Map<string, string[]>) => void
): Map<string, string[]> => {
  const displayFilterTerm = new Map<string, string[]>();
  const apiDisplayValues = new Map<string, string[]>();
  for (const [columnName, terms] of Object.entries(query.filterTerms)) {
    if (terms.length === 0) continue;
    const type = getPresetPropertyValue(
      preset.property ?? [],
      columnName,
      'type'
    );
    const keyName = GetMessageWithIntl(intl, {
      id: columnName,
      viewId: viewId,
    });
    (async () => {
      switch (type) {
        case 'filterboxMultiple':
          {
            const filterboxCategoryName = getPresetPropertyValue(
              preset.property ?? [],
              columnName,
              'categoryName'
            );
            const nameOptions = window.App.services.ui.getNameOption(
              filterboxCategoryName
            );
            const displayNames = getFilteringFilterboxMultipleValues(terms).map(
              (code) => {
                const displayNameLangs = nameOptions.find(
                  (opt) => opt.code === code
                )?.displayNameLang;
                return displayNameLangs
                  ? displayNameLangs[window.App.config.langName]
                  : '';
              }
            );
            displayFilterTerm.set(keyName, displayNames);
          }
          break;
        case 'apiFilterboxMultiple':
          {
            const filterboxValue = getPresetPropertyValue(
              preset.property ?? [],
              columnName,
              'filterboxValue'
            );
            const filterboxDisplayName = getPresetPropertyValue(
              preset.property ?? [],
              columnName,
              'filterboxDisplayName'
            );

            // 表示名称の場合、そのまま
            const displayValues = getFilteringFilterboxMultipleValues(terms);
            if (filterboxValue.indexOf('displayName') >= 0) {
              displayFilterTerm.set(keyName, displayValues);
              // IDの場合はIDから名称を取得する
            } else {
              const fullmethodName = getPresetPropertyValue(
                preset.property ?? [],
                columnName,
                'fullmethodName'
              );
              displayFilterTerm.set(keyName, [
                `${keyName}:filterboxDisplayName`,
              ]);
              const filterboxCustomDisplayName = getPresetPropertyValue(
                preset.property ?? [],
                columnName,
                'filterboxCustomDisplayName'
              );
              const filterboxCustomDisplayNameType = getPresetPropertyValue(
                preset.property ?? [],
                columnName,
                'filterboxCustomDisplayNameType'
              ) as CustomDisplayNameType;
              apiDisplayValues.set(keyName, ['']);
              const apiDisplayName = await setApiDisplayName(
                fullmethodName,
                filterboxValue,
                displayValues,
                filterboxDisplayName,
                filterboxCustomDisplayName,
                filterboxCustomDisplayNameType,
                setFilterApiDisplayValues
              );
              apiDisplayValues.set(keyName, apiDisplayName ?? []);
              setFilterApiMapDisplayValues(apiDisplayValues);
            }
          }
          break;
        case 'filterboxBoolean':
          {
            const filterBoxViewType = getPresetPropertyValue(
              preset.property ?? [],
              columnName,
              'viewType'
            );
            const booleanValues = getFilteringFilterboxBooleanValues(terms);
            let trueDisplayName = '○';
            let falseDisplayName = 'ー';

            switch (filterBoxViewType) {
              case 'completed': {
                trueDisplayName = '完了';
                falseDisplayName = '未完了';
                break;
              }
              default:
                break;
            }
            displayFilterTerm.set(
              keyName,
              booleanValues.map((v) => {
                switch (v) {
                  case true:
                    return trueDisplayName;
                  case false:
                    return falseDisplayName;
                  default:
                    return '';
                }
              })
            );
          }
          break;
        case 'filterboxCustom':
          {
            const children = preset?.children?.find(
              (children) => children.name === 'filterboxCustomItem'
            );
            const filterboxCustomItems = getPresetPropertysByColumnName(
              children?.property ?? [],
              columnName
            );
            const displayNames = getFilteringFilterboxCustomValues(terms).map(
              (value) => {
                return (
                  filterboxCustomItems.find(
                    (property) => property.propertyName === value
                  )?.propertyValue ?? ''
                );
              }
            );
            displayFilterTerm.set(keyName, displayNames);
          }
          break;
        case 'fromtodate':
          {
            const fromToDateValue = getFilteringFromToDateValues(terms);
            displayFilterTerm.set(keyName, [
              `${toDateString(fromToDateValue.from)} 〜 ${toDateString(
                fromToDateValue.to
              )}`,
            ]);
          }
          break;
        case 'fromtonumber':
          {
            const fromToNumberValue = getFilteringFromToNumberValues(terms);
            displayFilterTerm.set(keyName, [
              `${fromToNumberValue.from ?? ''} 〜 ${
                fromToNumberValue.to ?? ''
              }`,
            ]);
          }
          break;
        case 'text':
          {
            const displayValues = getFilteringTextboxValues(terms);
            displayFilterTerm.set(keyName, displayValues);
          }
          break;
        default:
          break;
      }
    })();
  }
  setFilterApiMapDisplayValues(apiDisplayValues);
  return displayFilterTerm;
};

export type FilterObject = {
  [k: string]: FilterPrimitive[];
};

export const toFilterViewQuery = (
  preset: PresetItem | undefined,
  filterObj: FilterObject
): FilterViewQuery => {
  const filterPresetItemPropertys = preset?.property ?? [];
  const filterTerms: FilterViewQueryTerm = {};
  for (const [column, values] of Object.entries(filterObj)) {
    const type = getPresetPropertyValue(
      filterPresetItemPropertys,
      column,
      'type'
    );
    switch (type) {
      case 'filterboxMultiple':
      case 'apiFilterboxMultiple':
      case 'filterboxCustom':
        filterTerms[column] = [toInFilter(column, values)];
        break;
      case 'filterboxBoolean':
        filterTerms[column] = toBoolFilters(column, values);
        break;
      case 'fromtodate':
        filterTerms[column] = toFromToDateFilters(column, values);
        break;
      case 'fromtonumber':
        filterTerms[column] = toFromToNumberFilters(column, values);
        break;
      case 'text':
        filterTerms[column] = [
          toEqualFilter(column, values.map(String).join(' ')),
        ];
        break;
      default:
        filterTerms[column] = values.map((v) => toEqualFilter(column, v));
    }
  }
  return { filterTerms: filterTerms };
};

function formatFilter(
  intl: IntlShape,
  viewId: ViewId,
  filterValue: FilterViewQuery,
  preset: PresetItem | undefined,
  setFilterApiDisplayValues: (result: string[]) => void,
  setFilterApiMapDisplayValues: (result: Map<string, string[]>) => void
): string {
  if (!preset) return '';
  const displayTerms = toDisplayFilterTerms(
    intl,
    viewId,
    preset,
    filterValue,
    setFilterApiDisplayValues,
    setFilterApiMapDisplayValues
  );
  const messages = Array.from(displayTerms.entries()).map(([key, v]) => {
    return `${key}: ${v}`;
  });
  return messages.join('\n');
}

export const toJoinColomnFilters = (
  terms: FilterTerm[],
  joinColumns: string[]
): FilterTerm[] => {
  if (terms.length === 0) return [];
  type columnTerm = typeof terms[0][keyof FilterTerm];
  const columnTerms: columnTerm[] = [];
  for (const term of terms) {
    for (const v of Array.from(Object.values(term))) {
      columnTerms.push(v);
    }
  }
  const filterTerms: FilterTerm[] = [];
  for (const columnName of joinColumns) {
    filterTerms.push(
      ...columnTerms.map((v) => {
        return { [columnName]: v };
      })
    );
  }
  return filterTerms;
};

export const toConvertColomnFilters = (
  terms: FilterTerm[],
  convertColomn: string
): FilterTerm[] => {
  if (terms.length === 0) return [];
  type columnTerm = typeof terms[0][keyof FilterTerm];
  const columnTerms: columnTerm[] = [];
  for (const term of terms) {
    for (const v of Array.from(Object.values(term))) {
      columnTerms.push(v);
    }
  }
  const filterTerms: FilterTerm[] = [];
  filterTerms.push(
    ...columnTerms.map((v) => {
      return { [convertColomn]: v };
    })
  );
  return filterTerms;
};

/**
 * FilterViewQuery型から FilterExpression型 に変換
 * isNotEqFilterの使い方としては見積依頼入力の様に一覧表示用のフィルタをセットする前に
 * 無条件で検索されてしまうのでプリセットフィルタ条件がセットする前に0件で検索するようにした
 */
export interface RequestBodyItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any;
}
export function toRequestBodyRequest(
  query: FilterViewQuery,
  filterPresetItem: PresetItem | undefined,
  filterRequestBodyPresetItem?: PresetItem | undefined
): RequestBodyItem {
  const filterTypePropertys = getPresetPropertysByPropertyName(
    filterPresetItem?.property ?? [],
    'type'
  );
  // NameOptionに変換する対象を取得する
  const convertCodeToNameOptionColumns = getPresetItemColums(
    'convertCodeToNameOption',
    filterPresetItem?.children
  );
  const filterCategoryNamePropertys = getPresetPropertysByPropertyName(
    filterPresetItem?.property ?? [],
    'categoryName'
  );
  const dataFilterItem: RequestBodyItem = {};
  const requestBodyItem: RequestBodyItem = {};
  const requestBodyColumns = filterRequestBodyPresetItem?.columns ?? [];
  for (const [columnName, filterTerms] of Object.entries(query.filterTerms)) {
    if (filterTerms.length === 0) continue;

    const typeProperty = filterTypePropertys.filter(
      (p) => p.name === columnName
    );
    const categoryNameProperty = filterCategoryNamePropertys.filter(
      (p) => p.name === columnName
    );
    const type = typeProperty.length > 0 ? typeProperty[0].propertyValue : '';
    if (requestBodyColumns.includes(columnName)) {
      filterTerms.map((terms) => {
        for (const [column, term] of Object.entries(terms)) {
          switch (type) {
            case 'text': {
              const body: string[] = [];
              if (isFilterLike(term) && isString(term.$like)) {
                body.push(term.$like);
              }
              if (isFilterEq(term) && isString(term.$eq)) {
                body.push(term.$eq);
              }
              requestBodyItem[column] = body.join(',');
              break;
            }
            case 'apiFilterboxMultiple':
            case 'filterboxMultiple':
            case 'filterboxCustom': {
              // NameOptionに変換する項目の場合
              if (convertCodeToNameOptionColumns.indexOf(columnName) >= 0) {
                // 名称マスタの種別を特定
                const categoryName = categoryNameProperty.at(0)?.propertyValue;
                const resNameOption: sharelib.INameOption[] = [];
                if (isFilterIn(term) && isStringArray(term.$in)) {
                  term.$in.forEach((code) => {
                    resNameOption.push(
                      window.App.services.ui.getNameOptionWithCode(
                        categoryName,
                        code
                      )
                    );
                  });
                }
                requestBodyItem[column] = resNameOption;
              } else {
                const selectedValues: string[] = [];
                if (isFilterIn(term) && isStringArray(term.$in)) {
                  selectedValues.push(...term.$in);
                }
                requestBodyItem[column] = selectedValues;
              }
              break;
            }
            case 'filterboxBoolean': {
              if (isFilterEq(term) && isString(term.$eq)) {
                requestBodyItem[column] = term.$eq;
              }
              break;
            }
            case 'fromtodate': {
              if (isFilterGte(term) && isString(term.$gte)) {
                requestBodyItem[`${column}From`] = term.$gte;
              }
              if (isFilterLte(term) && isString(term.$lte)) {
                requestBodyItem[`${column}To`] = term.$lte;
              }
              break;
            }
            case 'fromtonumber': {
              if (isFilterGte(term) && isString(term.$gte)) {
                requestBodyItem[`${column}From`] = term.$gte;
              }
              if (isFilterLte(term) && isString(term.$lte)) {
                requestBodyItem[`${column}To`] = term.$lte;
              }
              break;
            }
            default: {
              break;
            }
          }
        }
      });
    }
  }

  dataFilterItem['dataFilter'] = requestBodyItem;
  return dataFilterItem;
}

/**
 * 一覧用フィルタークエリを発行する
 *
 * @param query
 * @param filterPresetItem
 * @param isNotEqFilter
 * @param schema
 * @returns
 */
export function toFilterExpression(
  query: FilterViewQuery,
  filterPresetItem: PresetItem | undefined,
  isNotEqFilter = false, // 一覧表示時に必ず0件にしたい時のためのフラグ
  schema?: Schema
): FilterExpression {
  // フィルタタイプ別の変換関数
  const converters = getColumnFilterTypeConverters(
    filterPresetItem?.property ?? []
  );
  // 結合カラム
  const joinPresetItems: PresetItem[] = getMultiplePresetItem(
    'joinColumns',
    filterPresetItem?.children
  );
  // データ抽出→フィルタカラム
  const convertPresetItem = getPresetItem(
    'convertRequestBodyToQuery',
    filterPresetItem?.children
  );
  const filterExpressions: FilterExpression[] = [];
  for (const [columnName, filterTerms] of Object.entries(query.filterTerms)) {
    if (filterTerms.length === 0) continue;
    const convertFn = converters.get(columnName) ?? toFilterOr;
    const convertColumns = getPresetPropertyValue(
      convertPresetItem.property ?? [],
      columnName,
      'convertPresetItem'
    );
    const columnSchema = schema?.find((v) => v.name === columnName);

    const joinColumns = joinPresetItems.find((j: PresetItem) => {
      const joinTargetColumnProperty = getPresetProperty(
        j.property ?? [],
        columnName,
        'joinKey'
      );
      const targetColumn = joinTargetColumnProperty
        ? joinTargetColumnProperty.name
        : '';
      return (
        (j.columns ?? []).includes(columnName) && targetColumn === columnName
      );
    });
    if (joinColumns) {
      // 結合カラムの場合
      filterExpressions.push(
        convertFn(
          toJoinColomnFilters(filterTerms, joinColumns.columns!),
          columnSchema?.typeName
        )
      );
    } else if (convertColumns) {
      filterExpressions.push(
        convertFn(
          toConvertColomnFilters(filterTerms, convertColumns),
          columnSchema?.typeName
        )
      );
    } else {
      filterExpressions.push(convertFn(filterTerms, columnSchema?.typeName));
    }
  }
  if (filterExpressions.length == 0) {
    // 初期表示時に0件
    if (isNotEqFilter) {
      return { $and: [{ ['createdAt']: { $eq: '1' } }] };
    }
    return {};
  }
  return { $and: filterExpressions };
}

export interface FilterViewProps {
  viewId?: ViewId;
  filterPresetItem?: PresetItem;
  initialFilter?: FilterViewQuery;
  initialRequestBodyFilter?: FilterViewQuery;
  filterValue: FilterViewQuery;
  isRequestBodyFilter?: boolean;
  isUseFilterSave?: boolean;
  onSubmitFilter?: (v: FilterViewQuery) => void;
}

export function FilterView(props: FilterViewProps) {
  const {
    viewId,
    filterValue = { filterTerms: {} } as FilterViewQuery,
    initialFilter = { filterTerms: {} } as FilterViewQuery,
    initialRequestBodyFilter = { filterTerms: {} } as FilterViewQuery,
    filterPresetItem,
    isRequestBodyFilter: isRequestBodyFilter,
    onSubmitFilter,
    isUseFilterSave,
  } = props;

  const [filterAreaIsOpen, setFilterAreaIsOpen] = useState(false);
  const [filterViewValue, setFilterViewValue] = useState<FilterViewQuery>({
    filterTerms: {},
  });
  const [filterInitialValue, setFilterInitialValue] = useState<FilterViewQuery>(
    {
      filterTerms: {},
    }
  );

  const [filterInitialRequestBodyValue, setFilterInitialRequestBodyValue] =
    useState<FilterViewQuery>({
      filterTerms: {},
    });

  const [isFoldStatusView, setFoldStatusView] = useState(false);

  useEffect(() => {
    if (!filterAreaIsOpen) {
      setFilterInitialRequestBodyValue({ filterTerms: {} });
      setFilterViewValue({ filterTerms: {} });
      setFilterInitialValue({ filterTerms: {} });
      return;
    }

    // 初期フィルターのカラム
    const initialFilterColumns = getPresetItemColums(
      'initialFilter',
      filterPresetItem?.children
    );
    const filterTerms: FilterViewQueryTerm = { ...filterValue.filterTerms };
    for (const columnName of initialFilterColumns) {
      filterTerms[columnName] = filterTerms[columnName] ?? [];
    }
    const initialTerms: FilterViewQueryTerm = { ...initialFilter.filterTerms };
    for (const columnName of initialFilterColumns) {
      initialTerms[columnName] = initialTerms[columnName] ?? [];
    }

    // 初期固定フィルターのカラム
    if (isRequestBodyFilter) {
      const initialFilterRequestBodyColumns = getPresetItemColums(
        'initialRequestBodyFilter',
        filterPresetItem?.children
      );
      for (const columnName of initialFilterRequestBodyColumns) {
        filterTerms[columnName] = filterTerms[columnName] ?? [];
        initialTerms[columnName] = initialTerms[columnName] ?? [];
      }
      const initialRequestBodyTerms: FilterViewQueryTerm = {
        ...initialRequestBodyFilter.filterTerms,
      };
      for (const columnName of initialFilterRequestBodyColumns) {
        initialRequestBodyTerms[columnName] =
          initialRequestBodyTerms[columnName] ?? [];
      }

      setFilterInitialRequestBodyValue({
        ...filterValue,
        filterTerms: initialRequestBodyTerms,
      });
    }

    setFilterViewValue({ ...filterValue, filterTerms: filterTerms });
    setFilterInitialValue({ ...filterValue, filterTerms: initialTerms });
    // filterAreaIsOpen 変更時のみ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterAreaIsOpen]);

  const openFilterAreaView = () => {
    setFilterAreaIsOpen(true);
  };

  const closeFilterAreaView = () => {
    setFilterAreaIsOpen(false);
  };

  const handleSubmitFilter = () => {
    for (const [filterTerms] of Object.entries(filterViewValue.filterTerms)) {
      for (const term of filterTerms) {
        for (const fv of Array.from(Object.values(term))) {
          if (isFilterGte(fv) && isString(fv.$gte)) {
            fv.$gte = Number(fv.$gte);
          } else if (isFilterLte(fv) && isString(fv.$lte)) {
            fv.$lte = Number(fv.$lte);
          }
        }
      }
    }
    onSubmitFilter && onSubmitFilter(filterViewValue);
  };

  const handleChangeFilter = (v: FilterViewQuery) => {
    setFilterViewValue(v);
  };

  const handleSetSaveFilter = (v: FilterViewQuery) => {
    onSubmitFilter && onSubmitFilter(v);
    setFoldStatusView(false);
  };

  return (
    <div className="FilterView">
      <FilterButton
        onClick={() => {
          filterAreaIsOpen ? closeFilterAreaView() : openFilterAreaView();
        }}
      />
      <FilterDrawer
        modalIsOpen={filterAreaIsOpen}
        onClickOuter={() => closeFilterAreaView()}
      >
        <FilterAreaView
          viewId={viewId}
          filterValue={filterViewValue}
          initialFilter={filterInitialValue}
          initialRequestBodyFilter={filterInitialRequestBodyValue}
          presetItem={filterPresetItem}
          close={() => closeFilterAreaView()}
          send={() => handleSubmitFilter()}
          onChangeFilter={(v) => handleChangeFilter(v)}
          isFilterAreaOpen={filterAreaIsOpen}
          isRequestBodyFilter={isRequestBodyFilter}
        />
      </FilterDrawer>
      <FilterStatusView
        viewId={viewId}
        filterValue={filterValue}
        filterPresetItem={filterPresetItem}
        isFold={isFoldStatusView}
        onToggleFold={setFoldStatusView}
      />
      {isUseFilterSave && (
        <FilterSave
          viewId={viewId}
          filterValue={filterValue}
          onSubmitFilter={handleSetSaveFilter}
        />
      )}
    </div>
  );
}

export interface FilterButtonProps {
  onClick: () => void;
}

export function FilterButton(props: FilterButtonProps) {
  const { onClick } = props;
  return (
    <div
      className="FilterButton"
      onClick={(event) => {
        onClick();
        event.stopPropagation();
      }}
    >
      <FilterAlt className="filter-btn-color" />
      フィルタ
    </div>
  );
}

export interface FilterStatusViewProps {
  viewId?: ViewId;
  filterPresetItem?: PresetItem;
  filterValue: FilterViewQuery;
  isFold?: boolean;
  onToggleFold?: (isFold: boolean) => void;
}

export function FilterStatusView(props: FilterStatusViewProps) {
  const intl = useIntl();
  const { isFold, onToggleFold } = props;
  const [filterApiDisplayValues, setFilterApiDisplayValues] = useState<
    string[]
  >(['']);
  const [filterApiMapDisplayValues, setFilterApiMapDisplayValues] =
    useState<Map<string, string[]>>();

  const { viewId, filterPresetItem, filterValue } = props;
  const prevViewId = usePreviousViewId(viewId ?? '');
  const tmpFilterStatusText = useMemo(() => {
    // viewId が変更された場合は、以前の検索状態を初期化する
    if (prevViewId !== viewId) return '';
    return viewId
      ? formatFilter(
          intl,
          viewId,
          filterValue,
          filterPresetItem,
          setFilterApiDisplayValues,
          setFilterApiMapDisplayValues
        )
      : '';
  }, [intl, viewId, prevViewId, filterValue, filterPresetItem]);

  const [filterStatusText, setFilterStatusText] = useState<string>('');
  useEffect(() => {
    setFilterStatusText(tmpFilterStatusText);
  }, [tmpFilterStatusText]);

  useEffect(() => {
    if (!filterApiMapDisplayValues) {
      return;
    }
    let tmpText = tmpFilterStatusText;

    filterApiMapDisplayValues.forEach((value, key) => {
      tmpText = tmpText.replace(`${key}:filterboxDisplayName`, value.join(','));
    });
    setFilterStatusText(tmpText);
  }, [tmpFilterStatusText, filterApiDisplayValues, filterApiMapDisplayValues]);

  return (
    <div className={`FilterStatusView ${isFold ? '' : 'on'}`}>
      <div className="text">{filterStatusText}</div>
      <span
        className="aid"
        onClick={() => onToggleFold && onToggleFold(!isFold)}
      >
        <ExpandMore />
      </span>
    </div>
  );
}

export function usePreviousViewId(viewId: ViewId): ViewId {
  const ref = useRef<ViewId>('');
  useEffect(() => {
    ref.current = viewId;
  }, [viewId]);
  return ref.current;
}
