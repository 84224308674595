import { mtechnavi } from '~/shared/libs/clientsdk';
import { AppSaveWorker } from '..';
import { FullMethodName_CreateAssetImages } from 'worker/fullMethodName/crud';

/**
 * アセットIDで指定された画像を加工して、新しいアセットを返すエンドポイント
 */
export const createAssetImages = async (
  worker: AppSaveWorker,
  req: mtechnavi.api.assetinventory.ICreateAssetImagesRequest
) => {
  return worker.invokeListRequest<
    mtechnavi.api.assetinventory.ICreateAssetImagesRequest,
    mtechnavi.api.assetinventory.ICreateAssetImagesResponse
  >(FullMethodName_CreateAssetImages, req);
};
