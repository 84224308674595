import React, { useState } from 'react';
import { ViewerControlButton } from './ViewerControlButton';
import './DragModeSelector.css';
import { OverlayItemProps } from './ViewerOverlay';
import { GenerallyIconType } from '~/shared/components/parts/Button/GenerallyIcons';

export type DragMode = 'move' | 'selection';

interface CroppingShapeMode {
  /** メニューアイコン */
  iconType: GenerallyIconType;
  /** シェイプ本体のコンポーネント */
  component: React.ComponentType<OverlayItemProps>;
}

interface CroppingOption {
  /** 初期選択するシェイプの key */
  defaultShape?: string;
  /** 選択可能なシェイプリスト key:value */
  shapeList: { [key: string]: CroppingShapeMode };
}

export interface DragModeSelectorProps {
  mode?: DragMode;
  /** 選択中のシェイプ */
  selectionShape?: string;
  /** 範囲選択のオプション */
  croppingOption: CroppingOption;
  onChangeMode?: (mode: DragMode, shape?: string) => void;
}

/**
 * ビューワー上のドラッグ&ドロップモード選択メニューコンポーネント
 */
export const DragModeSelector = ({
  mode,
  selectionShape,
  croppingOption,
  onChangeMode,
}: DragModeSelectorProps) => {
  const [isShowSelector, setShowSelector] = useState(false);
  const shapeTypeItems = Object.entries(croppingOption.shapeList);

  const handleChangeMode = (mode: DragMode, shape?: string) => {
    onChangeMode && onChangeMode(mode, shape);
    setShowSelector(false);
  };

  const handleChangeShape = (shapeType: string) => {
    handleChangeMode('selection', shapeType);
  };

  const getCommentModeIcon = () => {
    const defaultIcon = 'comment';
    if (!selectionShape) {
      return defaultIcon;
    }
    return croppingOption.shapeList[selectionShape].iconType;
  };

  return (
    <div className="DragModeSelector">
      <ViewerControlButton
        name="dragMode-Move"
        isActive={mode === 'move'}
        iconType="move_area"
        onClick={() => handleChangeMode('move')}
      />
      {/* 選択できるシェイプが１つしかない場合はそのままモードを切り替える */}
      {shapeTypeItems.length == 1 && (
        <ViewerControlButton
          name={`viewerSelection-${shapeTypeItems[0][0]}`}
          isActive={
            mode === 'selection' && selectionShape === shapeTypeItems[0][0]
          }
          iconType={shapeTypeItems[0][1].iconType}
          onClick={() => handleChangeShape(shapeTypeItems[0][0])}
        />
      )}
      {/* 選択できるシェイプが複数の場合はサブメニューで選択させる */}
      {shapeTypeItems.length > 1 && (
        <div className="selector-wrapper">
          <ViewerControlButton
            name="dragMode-Selection"
            isActive={mode === 'selection'}
            iconType={getCommentModeIcon()}
            onClick={() => setShowSelector(!isShowSelector)}
          />
          {isShowSelector && (
            <div
              className="selector-bg"
              onClick={() => setShowSelector(false)}
            ></div>
          )}
          <div
            className={`selector ${isShowSelector ? 'show' : ''}`}
            style={{
              width: isShowSelector ? `${shapeTypeItems.length * 1.5}rem` : 0,
            }}
          >
            {shapeTypeItems.map(([name, modeItem]) => (
              <ViewerControlButton
                key={name}
                name={`viewerSelection-${name}`}
                isActive={mode === 'selection' && selectionShape === name}
                iconType={modeItem.iconType}
                onClick={() => handleChangeShape(name)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
