import { useState } from 'react';
import dayjs from 'dayjs';
import { CaptionButton } from '~/shared/components/ui/Button';
import { HistoryDialog } from '~/shared/components/ui/Dialog/HistoryDialog';

const sampleHistory = new Array(15)
  .fill(null)
  .map((v, i) => ({
    history_id: i,
    slip_category: ['回答催促', '回答期日変更依頼', '回答期日変更確定'][i % 3],
    occurred_at: dayjs(new Date()).format('YYYY/MM/DD HH:mm'),
    content: `${
      i + 1
    }件目の履歴です。\n期日が迫っています。\n今度こそは間に合うように何卒よろしくお願いします。`,
  }))
  .reverse();

const sampleRevision = new Array(15)
  .fill(null)
  .map((_, i) => ({
    revisionId: i,
    revisionCount: i + 1,
    revisionDate: dayjs(new Date())
      .subtract(1, 'month')
      .add(i, 'day')
      .format('YYYY/MM/DD'),
    comment: ` ${i + 1}回目の 改訂です`,
    updatedAt: dayjs(new Date()).format('YYYY/MM/DD HH:mm'),
  }))
  .reverse();

export const UISampleHistoryDialogs = () => {
  return (
    <div className="categories">
      <h3 id="dialog">履歴ダイアログ</h3>
      <HistoryDemo />
      <h4>アイコンボタン付き</h4>
      <RevisionDemo />
    </div>
  );
};

const HistoryDemo = () => {
  const [isShow, setShow] = useState(false);

  return (
    <div className="input-line">
      <div className="item-group-100">
        <div className="w-33">
          <CaptionButton
            buttonType="basic"
            caption="確認する"
            name=""
            onClick={() => {
              setShow(true);
            }}
          />
          <HistoryDialog
            isOpen={isShow}
            messageOption={{
              headerLabelId: {
                id: 'history',
                prefixId: 'DIALOG_TITLE',
              },
            }}
            inputOption={{
              data: sampleHistory,
              keyColumn: 'history_id',
              columns: [
                {
                  propertyName: 'slip_category',
                  width: '12rem',
                  header: {
                    prefixId: 'HistoryDialog',
                    id: 'slip_category',
                  },
                },
                {
                  propertyName: 'occurred_at',
                  width: '12rem',
                  header: {
                    prefixId: 'HistoryDialog',
                    id: 'occurred_at',
                  },
                },
                {
                  propertyName: 'content',
                  header: {
                    prefixId: 'HistoryDialog',
                    id: 'content',
                  },
                },
              ],
            }}
            onCancel={() => {
              setShow(false);
            }}
          />
        </div>
        <div className="w-66">
          <pre className="output"></pre>
        </div>
      </div>
    </div>
  );
};

const RevisionDemo = () => {
  const [isShow, setShow] = useState(false);

  return (
    <div className="input-line">
      <div className="item-group-100">
        <div className="w-33">
          <CaptionButton
            buttonType="basic"
            caption="確認する"
            name=""
            onClick={() => {
              setShow(true);
            }}
          />
          <HistoryDialog
            isOpen={isShow}
            messageOption={{
              headerLabelId: {
                prefixId: 'DIALOG_TITLE',
                id: 'BlueprintRevisionHistory',
              },
            }}
            inputOption={{
              data: sampleRevision,
              keyColumn: 'revisionId',
              rowAction: {
                iconType: 'description',
                buttonType: 'basic',
                action: (revisionId) => {
                  if (
                    typeof revisionId !== 'string' &&
                    typeof revisionId !== 'number'
                  ) {
                    return;
                  }
                  window.open(`/?revisionId=${revisionId}`);
                },
                disabledFn: (revisionId) =>
                  typeof revisionId === 'number' && (revisionId + 1) % 5 === 0,
              },
              columns: [
                {
                  propertyName: 'revisionCount',
                  width: '8rem',
                  header: {
                    prefixId: 'BlueprintRevisionHistory',
                    id: 'revisionCount',
                  },
                },
                {
                  propertyName: 'revisionDate',
                  width: '12rem',
                  header: {
                    prefixId: 'BlueprintRevisionHistory',
                    id: 'revisionDate',
                  },
                },
                {
                  propertyName: 'comment',
                  header: {
                    prefixId: 'BlueprintRevisionHistory',
                    id: 'comment',
                  },
                },
                {
                  propertyName: 'updatedAt',
                  width: '12rem',
                  header: {
                    prefixId: 'BlueprintRevisionHistory',
                    id: 'lastUpdatedAt',
                  },
                },
              ],
            }}
            onCancel={() => {
              setShow(false);
            }}
          />
        </div>
        <div className="w-66">
          <pre className="output"></pre>
        </div>
      </div>
    </div>
  );
};
