import {
  FilterRequest,
  FilterExpression,
  SortTerm,
  AggregateStage,
  ApiResultByMethod,
  ResultItem,
  ApiRequestByMethod,
  FullMethodName,
  ResultItems,
} from '../';
import { tenantAdminCallMap } from './tenantAdmin';
import { assetInventoryCallMap } from './assetInventory';
import { surveyCallMap } from './survey';
import { companyCallMap } from './company';
import { workTaskCallMap } from './workTask';
import { formCallMap } from './form';
import { bcpCallMap } from './bcp';
import { estimateCallMap } from './estimate';
import { forumCallMap } from './forum';
import { programOptionCallMap } from './programoption';
import { optCallMap } from './opt';
import { uicontrollerCallMap } from './uicontroller';
import { tenantCallMap } from './tenant';
import { blueprintCallMap } from './blueprint';

// RequestType を抽出するユーティリティ型
type ExtractRequestType<T> = T extends (
  worker: AppSaveWorker,
  req: infer R
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any
  ? R
  : never;

// saveWorker に渡される RequestType を callMap から抽出して定義
export type SaveRequestType = {
  [K in keyof typeof callMap]: {
    actionName: K;
    request: ExtractRequestType<typeof callMap[K]>;
  };
}[keyof typeof callMap];

// actionName の定数定義
type SaveWorkerRequestAction = SaveRequestType['actionName'];

// 戻り値の型を callMap から抽出して定義
type ResultTypeMap = {
  [K in keyof typeof callMap]: ReturnType<typeof callMap[K]>;
};
type SafeResultTypeMap = { [K in SaveWorkerRequestAction]: ResultTypeMap[K] };
export type SaveResultType<T> = T extends SaveRequestType
  ? SafeResultTypeMap[T['actionName']]
  : never;

// すべての actionName と function のマッピング
const callMap = {
  ...assetInventoryCallMap,
  ...bcpCallMap,
  ...companyCallMap,
  ...estimateCallMap,
  ...formCallMap,
  ...forumCallMap,
  ...optCallMap,
  ...programOptionCallMap,
  ...surveyCallMap,
  ...tenantAdminCallMap,
  ...workTaskCallMap,
  ...uicontrollerCallMap,
  ...tenantCallMap,
  ...blueprintCallMap,
  // 機能を追加した際はここに追記する
};

export class AppSaveWorker {
  constructor(
    public invokeListRequest: <
      T = undefined,
      U = undefined,
      P extends FullMethodName = FullMethodName
    >(
      fullMethodName: P,
      requestBody?: T extends undefined ? ApiRequestByMethod<P> : T,
      skipPageToken?: boolean
    ) => Promise<ResultItem<T, U, P>[]>,
    public filter: <R, P extends FullMethodName>(
      req: FilterRequest<P>,
      isFlat?: boolean
    ) => Promise<ResultItems<R, P>>,
    public query: (
      a: string,
      b: FilterExpression,
      c: SortTerm[],
      d?: AggregateStage[]
    ) => Promise<unknown>,
    public checkDummyAPI: (a: string) => boolean
  ) {}

  async apiCall<T extends SaveRequestType>(req: T): Promise<SaveResultType<T>> {
    const func = Object.entries(callMap).find(
      ([actionName]) => actionName === req.actionName
    );
    if (!func) {
      throw new Error(`unknown save action: ${req.actionName}`);
    }
    const [, callFunction] = func;
    return callFunction(
      this,
      // スカラ型の場合にここだけ型解釈がうまくいかないので any にキャストしている
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      req.request as any
    ) as SaveResultType<T>;
  }
}
