import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  GetMessage,
  GetMessageWithIntl,
  Toast,
  error,
} from '~/shared/components';
import {
  Accordion,
  DataFilterbox,
  DataFilterboxItem,
  PageNavigation,
  SamlSettingDialog,
  SamlSettingItem,
  Textbox,
} from '~/shared/components/ui';
import { CaptionButton } from '~/shared/components/ui/Button';
import { useAuth } from '~/shared/contexts/AuthProvider';
import { useLoading } from '~/shared/contexts/LoadingProvider';
import {
  PageState,
  ViewId,
  getExceptionMessage,
  getMaxMainContentsHeight,
  getProgramOptionFilterboxData,
  getProgramOptionFilterboxDataWithSystemName,
  getViewIdPreset,
  getWorkerExceptionMessage,
  includeInputValidateError,
} from '~/shared/utils';
import { PresetItem } from '~/shared/services';
import { FullMethodName_ListBillingCompanys } from '~/worker';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { useConfirmation } from '~/shared/hooks';

const VIEW_ID: ViewId = 'ADMIN_TENANT_SETTING_INPUT';

export interface LicenseDataType {
  licenseEnabled: string;
  licenseName: string;
  licenseStartDt: string;
  licenseEndDt: string;
}

type BillingCompany = mtechnavi.api.company.IBillingCompany;
type SamlProviderSetting = mtechnavi.api.idp.ISamlProviderSetting;

const SamlDialogFixedText =
  'IDPメタデータをダウンロードし、「メタデータアップロード」ボタンからアップロードしてください。';

export function AdminTenantSettingInput() {
  // ログイン者の情報特定用email
  const auth = useAuth();
  const intl = useIntl();
  const navi = useNavigate();
  const sourcePageInfo = (useLocation().state as PageState) ?? [];
  const { showLoading, hideLoading } = useLoading();
  const [countries, setCountries] = useState<DataFilterboxItem[]>([]);
  const [regions, setRegions] = useState<DataFilterboxItem[]>([]);
  const [logins, setLogins] = useState<DataFilterboxItem[]>([]);

  const [companyName, setCompanyName] = useState('');
  const [adminName, setAdminName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [companyCountry, setCompanyCountry] = useState<DataFilterboxItem[]>([]);
  const [companyRegion, setCompanyRegion] = useState<DataFilterboxItem[]>([]);
  const [companyAddress, setCompanyAddress] = useState('');
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');
  const [tenantName, setTenantName] = useState('');
  const [certificateWork, setCertificateWork] = useState<DataFilterboxItem[]>(
    []
  );

  const [isDisabledSamlButton, setDisabledSamlButton] = useState(false);

  // 必須チェック用
  const [workingBlurCertificateWork, setWorkingBlurCertificateWork] =
    useState<Date>();

  // 遷移元から値を受け取る
  const [isOpenSamlSettingDialog, setOpenSamlSettingDialog] = useState(false);

  // データ管理
  const billingCompanyInfo = useRef<BillingCompany>({});
  const samlProviderInfo = useRef<SamlProviderSetting>({});

  // 確認ダイアログ
  const { confirmation, confirmationElement } = useConfirmation();

  // preset
  const [preset, setPreset] = useState<PresetItem>({ name: '' });
  const [mainContentHeight, setMainContentHeight] = useState('');
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setMainContentHeight(
      getMaxMainContentsHeight(footerRef.current?.clientHeight ?? 0)
    );
  }, [footerRef.current?.clientHeight]);

  const formRef = useRef(null);

  // 初回画面用データ
  useEffect(() => {
    try {
      (async () => {
        showLoading();
        const { presetItem } = await getViewIdPreset(intl, VIEW_ID);
        setPreset(presetItem);

        const myCompany = await window.App.services.ui.getMyCompany();
        setTenantName(myCompany.displayNameLang?.ja ?? '');
        const billingCompanyRes = (await window.App.services.ui.worker.filter({
          action: 'reload',
          fullMethodName: FullMethodName_ListBillingCompanys,
          filter: {},
          sort: [],
        })) as mtechnavi.api.company.ListBillingCompanysResponse;
        billingCompanyInfo.current = billingCompanyRes?.items.length
          ? billingCompanyRes?.items[0]
          : {};
        const listSamlProviderSettingRes =
          (await window.App.services.ui.worker.filter({
            action: 'reload',
            fullMethodName:
              'mtechnavi.api.idp.Identity/ListSamlProviderSettings',
            filter: {},
            sort: [],
          })) as mtechnavi.api.idp.ListSamlProviderSettingsResponse;
        samlProviderInfo.current = listSamlProviderSettingRes?.items.find(
          (v) => v.samlProviderId === 'saml.default' && v.enabled
        ) ?? {
          samlProviderId: 'saml.default',
          enabled: false,
        };

        // 値セット
        setCountries(getProgramOptionFilterboxData('A0000041'));
        const loginFilterboxItems = getProgramOptionFilterboxData('A0000043');
        setLogins(loginFilterboxItems);
        const regionsFilterBoxData =
          getProgramOptionFilterboxDataWithSystemName(
            'A0000042',
            getCountrySystemName(
              billingCompanyInfo.current?.country?.code ?? '1'
            )
          );
        setRegions(regionsFilterBoxData);

        setCompanyName(billingCompanyInfo?.current.displayName ?? '');
        setAdminName(billingCompanyInfo?.current.adminDisplayName ?? '');
        setAdminEmail(billingCompanyInfo.current.adminEmail ?? '');
        setCompanyCountry([
          {
            value: billingCompanyInfo.current.country?.code ?? '',
            displayName:
              billingCompanyInfo?.current.country?.displayNameLang?.ja ?? '',
          },
        ]);
        setCompanyRegion([
          {
            value: billingCompanyInfo.current.region?.code ?? '',
            displayName:
              billingCompanyInfo?.current.region?.displayNameLang?.ja ?? '',
          },
        ]);
        setCompanyAddress(
          billingCompanyInfo?.current.addressLines?.join('') ?? ''
        );
        setCompanyPhoneNumber(billingCompanyInfo?.current.phoneNumber ?? '');
        setCertificateWork(
          samlProviderInfo.current.enabled
            ? loginFilterboxItems.filter((v) => v.value === '1')
            : loginFilterboxItems.filter((v) => v.value === '0')
        );
        setDisabledSamlButton(samlProviderInfo.current.enabled ? false : true);
        hideLoading();
      })();
    } catch (err) {
      error(getExceptionMessage(intl, err));
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourcePageInfo.actionType, intl]);

  // 国が変更された時、国に一致する地域のみ表示する
  const resetRegions = (countryValue: DataFilterboxItem[]) => {
    const systemName = getCountrySystemName(
      countryValue.length ? countryValue[0].value : ''
    );
    setRegions(
      getProgramOptionFilterboxDataWithSystemName('A0000042', systemName)
    );
    setCompanyRegion([]);
  };

  const getLabelId = (column: string): string => {
    return `${VIEW_ID}.${column}`;
  };

  const getCountrySystemName = (countryCode: string) => {
    return (
      window.App.services.ui.getNameOptionWithCode('A0000041', countryCode)
        .systemName ?? ''
    );
  };

  const isInputError = () => {
    setWorkingBlurCertificateWork(new Date());
    const certificateWorkValue =
      certificateWork.length > 0 ? certificateWork[0].value : '';
    return includeInputValidateError(document, intl, [
      { ref: formRef, value: adminName },
      { ref: formRef, value: adminEmail },
      { ref: formRef, value: certificateWorkValue },
    ]);
  };

  const isSamlSettingNotProvided = () => {
    const certificateBody =
      samlProviderInfo.current.certificates &&
      samlProviderInfo.current.certificates.length
        ? samlProviderInfo.current.certificates[0]
        : '';
    if (
      !samlProviderInfo.current.entityId ||
      !samlProviderInfo.current.ssoUrl ||
      !certificateBody
    ) {
      return true;
    }
    return false;
  };
  const handleSaveTenantSettingAndSamlSetting = async () => {
    if (
      certificateWork.length &&
      certificateWork[0].value === '1' &&
      isSamlSettingNotProvided()
    ) {
      error([GetMessageWithIntl(intl, { id: 'E0000182' })]);
      return;
    }
    if (isInputError()) {
      return;
    }

    const confirm = confirmation({
      id: 'C0000001',
      value: { $1: GetMessageWithIntl(intl, { id: 'save' }) },
    });
    if (!(await confirm)) {
      return;
    }
    showLoading();
    try {
      // テナント設定
      const country = window.App.services.ui.getNameOptionWithCode(
        'A0000041',
        companyCountry && companyCountry.length ? companyCountry[0].value : ''
      );
      const region = window.App.services.ui.getNameOptionWithCode(
        'A0000042',
        companyRegion && companyRegion.length ? companyRegion[0].value : ''
      );
      const billingCompanyRequest: BillingCompany = {
        ...billingCompanyInfo.current,
        displayName: companyName,
        adminDisplayName: adminName,
        adminEmail: adminEmail,
        country,
        region,
        addressLines: [companyAddress],
        phoneNumber: companyPhoneNumber,
      };
      await window.App.services.ui.worker.apiCall({
        actionName: 'updateBillingCompany',
        request: billingCompanyRequest,
      });
      await window.App.services.ui.worker.apiCall({
        actionName: 'saveSamlProviderSetting',
        request: samlProviderInfo.current,
      });
      window.App.config.singleSignOn.enabled =
        samlProviderInfo.current.enabled ?? false;
      navi('/admin-tenant-setting-confirmation');
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      hideLoading();
    }
  };

  const handleSamlSettingDialog = (result: SamlSettingItem) => {
    samlProviderInfo.current = {
      samlProviderId: 'saml.default',
      displayName: 'SAML',
      enabled: true,
      entityId: result.entityId,
      ssoUrl: result.ssourl,
      certificates: [result.certificateBody ?? ''],
    };
    setWorkingBlurCertificateWork(undefined);
  };

  return (
    <>
      <Container viewId={VIEW_ID}>
        <div className="header">
          <PageNavigation
            backpagePath="/admin-tenant-setting-input"
            pageInfo={{
              isUnvisibleBackNavi: false,
              isVisibleMoveNavi: false,
            }}
            iconItems={[]}
            infoOption={{
              lastUpdateInfo: {
                isVisibleUpdateInfo: true,
                attributeAt:
                  billingCompanyInfo.current.updatedProperties?.updatedAt ??
                  undefined,
                content:
                  billingCompanyInfo.current.updatedProperties?.updatedBy
                    ?.displayName ?? '',
                attribute:
                  billingCompanyInfo.current.updatedProperties?.updatedBy
                    ?.email ?? '',
              },
              issuerInfo: { isVisibleIssuerInfo: false },
            }}
            handleBackPage={async () => {
              const confirm = confirmation({
                id: 'discardMessage',
              });
              if (!(await confirm)) {
                return;
              }
              navi('/admin-tenant-setting-confirmation');
            }}
          />
        </div>
        <div>
          <div
            className="scroll-main-contents-area"
            style={{
              maxHeight: mainContentHeight,
            }}
          >
            <Accordion
              title={GetMessage({ id: 'companyTitle', prefixId: VIEW_ID })}
            >
              <div className="input-line" ref={formRef}>
                <div className="item-group-100">
                  <div className="w-40">
                    <Textbox
                      name="companyName"
                      className="field"
                      value={companyName}
                      onChangeState={setCompanyName}
                      type="text"
                      labelId={getLabelId('companyName')}
                      columns={preset.columns}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="adminName"
                      className="field"
                      value={adminName}
                      onChangeState={setAdminName}
                      type="text"
                      labelId={getLabelId('adminName')}
                      columns={preset.columns}
                      validateOption={{ required: true }}
                    />
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="adminEmail"
                      className="field"
                      value={adminEmail}
                      onChangeState={setAdminEmail}
                      type="text"
                      labelId={getLabelId('adminEmail')}
                      columns={preset.columns}
                      validateOption={{ required: true }}
                    />
                  </div>
                </div>
              </div>
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <DataFilterbox
                      name="companyCountry"
                      className="field"
                      data={countries}
                      value={companyCountry}
                      onChangeState={(v) => {
                        setCompanyCountry(v);
                        resetRegions(v);
                      }}
                      labelId={getLabelId('companyCountry')}
                      columns={preset.columns}
                    />
                  </div>
                  <div className="w-20">
                    <DataFilterbox
                      name="companyRegion"
                      className="field"
                      data={regions}
                      value={companyRegion}
                      onChangeState={setCompanyRegion}
                      labelId={getLabelId('companyRegion')}
                      columns={preset.columns}
                    />
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="companyAddress"
                      className="field"
                      value={companyAddress}
                      onChangeState={setCompanyAddress}
                      type="text"
                      labelId={getLabelId('companyAddress')}
                      columns={preset.columns}
                    />
                  </div>
                  <div className="w-20">
                    <Textbox
                      name="companyPhoneNumber"
                      className="field"
                      value={companyPhoneNumber}
                      onChangeState={setCompanyPhoneNumber}
                      type="text"
                      labelId={getLabelId('companyPhoneNumber')}
                      columns={preset.columns}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion
              title={GetMessage({ id: 'tenantTitle', prefixId: VIEW_ID })}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-40">
                    <Textbox
                      name="tenantName"
                      className="field"
                      value={tenantName}
                      type="text"
                      labelId={getLabelId('tenantName')}
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>

              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-20">
                    <Textbox
                      name="tenantCode"
                      className="field"
                      value={auth.tenant?.tenantCode ?? ''}
                      type="text"
                      labelId={getLabelId('tenantCode')}
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                  <div className="w-40">
                    <Textbox
                      name="tenantUrl"
                      className="field"
                      value={auth.tenant?.domain}
                      type="text"
                      labelId={getLabelId('tenantUrl')}
                      columns={preset.columns}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion
              title={GetMessage({ id: 'certificateTitle', prefixId: VIEW_ID })}
            >
              <div className="input-line">
                <div className="item-group-100">
                  <div className="w-25">
                    <DataFilterbox
                      name="certificateWork"
                      className="field"
                      data={logins}
                      value={certificateWork}
                      onChangeState={(v) => {
                        setCertificateWork(v);
                        if (!v || v.length === 0) {
                          setDisabledSamlButton(true);
                          return;
                        }

                        if (v[0].value === '1') {
                          setDisabledSamlButton(false);
                        } else if (v[0].value === '0') {
                          setDisabledSamlButton(true);
                          samlProviderInfo.current = {
                            samlProviderId: 'saml.default',
                            enabled: false,
                          };
                        }
                      }}
                      labelId={getLabelId('certificateWork')}
                      validateOption={{ required: true }}
                      workingBlur={workingBlurCertificateWork}
                      columns={preset.columns}
                    />
                  </div>
                  <div className="w-20 attachment-area">
                    <div className="upload-button">
                      <CaptionButton
                        buttonType="basic"
                        caption={GetMessageWithIntl(intl, {
                          id: 'samlSettingBtn',
                          viewId: VIEW_ID,
                        })}
                        name="add"
                        disabled={isDisabledSamlButton}
                        onClick={() => {
                          setOpenSamlSettingDialog(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
          <div className="footer" ref={footerRef}>
            <div className="footer-contents">
              <div className="input-line">
                <CaptionButton
                  name="footerBtn"
                  caption={GetMessageWithIntl(intl, { id: 'save' })}
                  className="btn"
                  onClick={() => handleSaveTenantSettingAndSamlSetting()}
                  buttonType="basic"
                />
              </div>
            </div>
          </div>
        </div>
        {confirmationElement}
        <SamlSettingDialog
          isOpen={isOpenSamlSettingDialog}
          inputOption={{
            info: {
              ssosetting: SamlDialogFixedText,
              ssourl: samlProviderInfo?.current?.ssoUrl ?? '',
              entityId: samlProviderInfo?.current?.entityId ?? '',
              certificateBody:
                samlProviderInfo?.current?.certificates &&
                samlProviderInfo?.current.certificates.length
                  ? samlProviderInfo?.current.certificates[0]
                  : '',
            },
            displayMode: 'setting',
          }}
          onCancel={() => {
            setOpenSamlSettingDialog(false);
          }}
          onDecision={(result) => {
            handleSamlSettingDialog(result);
            setOpenSamlSettingDialog(false);
          }}
        />
        <Toast />
      </Container>
    </>
  );
}
